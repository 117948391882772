import React, {Component} from 'react';
import {
  Navbar,
  Nav,
  Form,
  NavDropdown,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import{
  Link
} from "react-router-dom";
class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (
      
        <nav className="navbar navbar-expand-lg navbar-dark bg-blue" >
            <Container>
        <a className="navbar-brand" href="#"><img className="wow-logo"
          src="IMAGES/download.png"/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"><img src="IMAGES/toggle.jpg"/></span>
        </button>
      
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <Link className="nav-link" style={{color:"black"}} to="home">HOME</Link>
            </li>
            <li class="nav-item">
            <Link className="nav-link" style={{color:"black"}} to="aboutus">ABOUT US</Link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" style={{color:"black"}} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                RIDE
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      
                
                <div class="container">
                  <div class="row">
                    <div class="col-md-4">
                      <span class="text-uppercase text-white">ff</span>
                      <ul class="nav flex-column">
                      <li class="nav-item">
                        <a class="nav-link active" href="#">Active</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                    </ul>
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                    </div>
                    <div class="col-md-4">
                      <ul class="nav flex-column">
                      <li class="nav-item">
                        <a class="nav-link active" href="#">Active</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                    </ul>
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                    <div class="col-md-4">
                      <a href="">
                        <img src="https://dummyimage.com/200x100/ccc/000&text=MF" alt="" class="img-fluid"/>
                      </a>
                      <p class="text-white">Short image call to action</p>
      
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                  </div>
                </div>
                {/* <!--  /.container  --> */}
      
      
              
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" style={{color:"black"}} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                LEADER
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      
      
                <div class="container">
                  <div class="row">
                    <div class="col-md-4">
                      <span class="text-uppercase text-white">Category 2</span>
                      <ul class="nav flex-column">
                      <li class="nav-item">
                        <a class="nav-link active" href="#">Active</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                    </ul>
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                    <div class="col-md-4">
                      <ul class="nav flex-column">
                      <li class="nav-item">
                        <a class="nav-link active" href="#">Active</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                    </ul>
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                    <div class="col-md-4">
                      <a href="">
                        <img src="https://dummyimage.com/200x100/ccc/000&text=image+link" alt="" class="img-fluid"/>
                      </a>
                      <p class="text-white">Short image call to action</p>
      
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                  </div>
                </div>
                {/* <!--  /.container  --> */}
      
      
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" style={{color:"black" }} href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                GUIDE
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      
      
                <div class="container">
                  <div class="row">
                    <div class="col-md-4">
                     <span class="text-uppercase text-white">Category 3</span>
                      <ul class="nav flex-column">
                      <li class="nav-item">
                        <a class="nav-link active" href="#">Active</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                    </ul>
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                    <div class="col-md-4">
                      <ul class="nav flex-column">
                      <li class="nav-item">
                        <a class="nav-link active" href="#">Active</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#">Link item</a>
                      </li>
                    </ul>
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                    <div class="col-md-4">
      
                      <a href="">
                        <img src="https://dummyimage.com/200x100/ccc/000&text=image+link" alt="" class="img-fluid"/>
                      </a>
                      <p class="text-white">Short image call to action</p>
                      
                    </div>
                    {/* <!-- /.col-md-4  --> */}
                  </div>
                </div>
                {/* <!--  /.container  --> */}
      
      
              </div>
            </li>
      
          </ul>


          
          <form class="form-inline">
              <select class="goog-te-combo" aria-label="Language Translate Widget">
                  <option value="">Select Language</option>
                  <option value="en">English</option>
                  <option value="af">Afrikaans</option>
                  <option value="sq">Albanian</option><option value="am">Amharic</option>
                  <option value="ar">Arabic</option><option value="hy">Armenian</option>
                  <option value="az">Azerbaijani</option><option value="eu">Basque</option>
                  <option value="be">Belarusian</option><option value="bn">Bengali</option>
                  <option value="bs">Bosnian</option><option value="bg">Bulgarian</option>
                  <option value="ca">Catalan</option><option value="ceb">Cebuano</option>
                  <option value="ny">Chichewa</option><option value="zh-CN">Chinese (Simplified)</option>
                  <option value="zh-TW">Chinese (Traditional)</option><option value="co">Corsican</option>
                  <option value="hr">Croatian</option>
                  <option value="cs">Czech</option>
                  <option value="da">Danish</option>
                  <option value="nl">Dutch</option><option value="eo">Esperanto</option>
                  <option value="et">Estonian</option><option value="tl">Filipino</option>
                  <option value="fi">Finnish</option><option value="fr">French</option>
                  <option value="fy">Frisian</option><option value="gl">Galician</option>
                  <option value="ka">Georgian</option><option value="de">German</option>
                  <option value="el">Greek</option><option value="gu">Gujarati</option>
                  <option value="ht">Haitian Creole</option><option value="ha">Hausa</option>
                  <option value="haw">Hawaiian</option><option value="iw">Hebrew</option>
                  <option value="hi">Hindi</option><option value="hmn">Hmong</option>
                  <option value="hu">Hungarian</option><option value="is">Icelandic</option>
                  <option value="ig">Igbo</option><option value="id">Indonesian</option>
                  <option value="ga">Irish</option><option value="it">Italian</option>
                  <option value="ja">Japanese</option><option value="jw">Javanese</option>
                  <option value="kn">Kannada</option><option value="kk">Kazakh</option>
                  <option value="km">Khmer</option><option value="ko">Korean</option>
                  <option value="ku">Kurdish (Kurmanji)</option><option value="ky">Kyrgyz</option>
                  <option value="lo">Lao</option><option value="la">Latin</option>
                  <option value="lv">Latvian</option><option value="lt">Lithuanian</option>
                  <option value="lb">Luxembourgish</option><option value="mk">Macedonian</option>
                  <option value="mg">Malagasy</option><option value="ms">Malay</option>
                  <option value="ml">Malayalam</option><option value="mt">Maltese</option>
                  <option value="mi">Maori</option><option value="mr">Marathi</option>
                  <option value="mn">Mongolian</option><option value="my">Myanmar (Burmese)</option>
                  <option value="ne">Nepali</option><option value="no">Norwegian</option>
                  <option value="ps">Pashto</option><option value="fa">Persian</option>
                  <option value="pl">Polish</option><option value="pt">Portuguese</option>
                  <option value="pa">Punjabi</option><option value="ro">Romanian</option>
                  <option value="ru">Russian</option><option value="sm">Samoan</option>
                  <option value="gd">Scots Gaelic</option><option value="sr">Serbian</option>
                  <option value="st">Sesotho</option><option value="sn">Shona</option>
                  <option value="sd">Sindhi</option><option value="si">Sinhala</option>
                  <option value="sk">Slovak</option><option value="sl">Slovenian</option>
                  <option value="so">Somali</option><option value="es">Spanish</option>
                  <option value="su">Sundanese</option><option value="sw">Swahili</option>
                  <option value="sv">Swedish</option><option value="tg">Tajik</option>
                  <option value="ta">Tamil</option><option value="te">Telugu</option>
                  <option value="th">Thai</option><option value="tr">Turkish</option>
                  <option value="uk">Ukrainian</option><option value="ur">Urdu</option>
                  <option value="uz">Uzbek</option><option value="vi">Vietnamese</option>
                  <option value="cy">Welsh</option><option value="xh">Xhosa</option>
                  <option value="yi">Yiddish</option><option value="yo">Yoruba</option>
                  <option value="zu">Zulu</option></select>
           
          </form>
        </div>
      
        </Container>
      </nav>


   
    );
  }
}
export default Header;