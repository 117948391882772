import React, { Component } from "react";
import {
  Accordion,
  Card,
  Container,
  ListGroup,
  Row,
  Col
} from "react-bootstrap";

class Home extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (
      <div className="home-page-margin-top15">
      <h1>HOME PAGE</h1>
      </div>
    );
  }
}
export default Home;