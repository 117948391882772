import React, { Component, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { 
TwitterTimelineEmbed, 
TwitterShareButton, 
TwitterFollowButton, 
TwitterHashtagButton, 
TwitterMentionButton, 
TwitterTweetEmbed, 
TwitterMomentShare, 
TwitterDMButton, 
TwitterVideoEmbed, 
TwitterOnAirButton }
from 'react-twitter-embed';

import { Visible, Hidden, setConfiguration } from 'react-grid-system';

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  
  Col
} from "react-bootstrap";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};






class Community extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date
    });
  }





openNavnewsroom=() => {
  document.getElementById("mySidenav-newsroom").style.width = "300px";
  document.getElementById("close-button-newsroom").style.display = "";
  
}


closeNavnewsroom = () => {
  document.getElementById("mySidenav-newsroom").style.width = "0px";
  document.getElementById("close-button-newsroom").style.display = "none";
}








  render() {
    return (
        <div>



<div className="wow-community-top-content" >
              <div className="wow-community-container-top">
                <div className="wow-community-top-content-area">
              
                <Row className="wow-community-grid">
                  <Col xl={8} md={8} sm={12} xs={12} lg={8}>
                  <div className="wow-community12">
                 <h2 
                 style={{color:'#ffffff',paddingLeft:'3%',textAlign:'left' }} 
                 className="wow-community-banner-top-heading" >Wow Community</h2>

                 <h3 
                 className="text-in-h3-wow-community" 
                 style={{color:'#ffffff',paddingLeft:'3%',textAlign:'left',fontSize:'20px' }}>
                   Worldwide Social Responsibility</h3>
                  <h5 
                  style={{color:'#ffffff',paddingLeft:'3%',fontSize:'17px'  }}
                   className="wow-community-banner-top-co">
                     WOW Provides We contribute to vibrant communities through our support of cultural
                      and sporting events around the world</h5> 
                 </div>
                  </Col>
                  <Col xl={4} md={4}  lg={4} className="simplify-wow-community-text-col-tab">
                  <Hidden sm xs xl>  
                  </Hidden>
                  </Col>
                </Row>
                </div>
              </div>
              </div>















<Container>
            <center>
              <h3 className="wow_community">WOW Community</h3>
              <div className="wow_community-heading-text">
              Wow provides resources to grow your community to monetize your ideas.
              </div>
            </center>
            </Container>




<Container>

              <h3 className="wow_community-our-people">Our People & Community</h3>
              <div className="wow_community-our-people-heading-text">
              We believe it’s the passion, energy and expertise of our local guides that truly sets
               Wow Holiday Tours and people apart. They’re our heroes and they get a huge buzz out of helping
                you discover all the highlights and hidden gems and in delivering a 100% authentic
              Travel experience. When you join a Wow Worldwode Tour you become part of the 
                 Wow(family).
              </div>

</Container>




<Container>
<div class="community-page-top-image">
  <img src="IMAGES/city-images/wow-community/top-image.jpg" style={{width: '100%'}}/>

  </div>
  </Container>


            <div className="wowcommunity-page-right-back-gradiant">
  <Container>
  
    <Row>
    
      <Col xl={6} lg={6} md={12} sm={12} xs={12}> 

      <div className="padding-in-wowcommunity-grid-text">
        <div className="wowcommunity-page-araea-sub-headings110">
        OUR OBLIGATION TO CIVIC
        </div>

        <div className="wowcommunity-page-text">
        The present networks face an extraordinary arrangement of difficulties. Moreover, keeping
         in mind that innovation without anyone else is not the arrangement, when it has
          done well and joined with great organizations it can possibly add to a superior world for all.

        </div>
      </div>


    </Col>


      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
       <img
       className="image-community-grid-section"
        src="IMAGES/city-images/trustsafety/blog-col3.jpg">

       </img>
      </Col>
      </Row>

  


   
  </Container>
  </div>







  <div className="wowcommunity-page-left-back-gradiant">
  <Container>
  
    <Row>

    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col4.jpg"></img>
      </Col>


    
      <Col xl={6} lg={6} md={12} sm={12} xs={12}> 

      <div className="padding-in-wowcommunity-grid-text">
        <div className="wowcommunity-page-araea-sub-headings110">
        SUPPORTING URBAN COMMUNITIES
        </div>

        <div className="wowcommunity-page-text">
        Our kin, procedure, and innovation can help change urban communities into more
         secure, progressively effective, and increasingly delightful spots.

        </div>
      </div>


    </Col>


      
      </Row>


   
  </Container>
  </div>













{/* 
<Col lg={5}>
<a class="twitter-timeline" data-width="1024" data-theme="dark" href="https://twitter.com/DXBMediaOffice?ref_src=twsrc%5Etfw">Tweets by DXBMediaOffice</a>

</Col> */}










<br/>




<section className="section-features-nature-wow-community-holiday">

<Container>

  <Row>

    <Col lg={4} xs={4} md={4} xs={12}>

      <div className="feature-box-in-wow-community-holiday">



     
      <i className="feature-box__icon-wow-community icon-basic-world"></i>

      <div  className="header-inside-grid-wow-community-features-box u-margin-bottom-small">
      Travel Guide
      </div>


      <p className="feature__box-text-wow-community">
      A wonderful serenity has taken to the possession of my entire soul.
      You'll be able to explore
       new places and cultures, and discover
        different ways how people live around the globe.

      </p>

      </div>

    </Col>




    <Col lg={4} xs={4} md={4} xs={12}>

    <div className="feature-box-in-wow-community-holiday">



     
<i className="feature-box__icon-wow-community icon-basic-compass"></i>

<div  className="header-inside-grid-wow-community-features-box u-margin-bottom-small">
Comfortable Journey
</div>


<p className="feature__box-text-wow-community">
Wherever you go, always bring your own sunshine.
A wonderful serenity has taken to the possession of my entire soul.
In walk with nature one receives more he seeks.


</p>

</div>
    </Col>

    <Col lg={4} xs={4} md={4} xs={12}>


    <div className="feature-box-in-wow-community-holiday">



     
<i className="feature-box__icon-wow-community icon-basic-map"></i>

<div  className="header-inside-grid-wow-community-features-box u-margin-bottom-small">
Luxuries Hotel
</div>


<p className="feature__box-text-wow-community">
Find motivation in moments of doubt or confusion by finding your own way to 
peace, happiness and prosperity in this world.

</p>

</div>
    </Col>






  </Row>



</Container>








</section>












<div className="wowcommunity-page-right-back-gradiant">
  <Container>
  
    <Row>
    
      <Col xl={6} lg={6} md={12} sm={12} xs={12}> 

      <div className="padding-in-wowcommunity-grid-text">
        <div className="wowcommunity-page-araea-sub-headings110">
        OUR OBLIGATION TO CIVIC
        </div>

        <div className="wowcommunity-page-text">
        The present networks face an extraordinary arrangement of difficulties. Moreover, keeping
         in mind that innovation without anyone else is not the arrangement, when it has
          done well and joined with great organizations it can possibly add to a superior world for all.

        </div>
      </div>


    </Col>


      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col3.jpg">

       </img>
      </Col>
      </Row>

  


   
  </Container>
  </div>







  <div className="wowcommunity-page-left-back-gradiant">
  <Container>
  
    <Row>

    <Col xl={6} lg={6} md={12} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col4.jpg"></img>
      </Col>


    
      <Col xl={6} lg={6} md={12} sm={12} xs={12}> 

      <div className="padding-in-wowcommunity-grid-text">
        <div className="wowcommunity-page-araea-sub-headings110">
        SUPPORTING URBAN COMMUNITIES
        </div>

        <div className="wowcommunity-page-text">
        Our kin, procedure, and innovation can help change urban communities into more
         secure, progressively effective, and increasingly delightful spots.

        </div>
      </div>


    </Col>


      
      </Row>


   
  </Container>
  </div>









  <div className="wowcommunity-page-right-back-gradiant">
  <Container>
  
    <Row>
    
      <Col xl={6} lg={6} md={12} sm={12} xs={12}> 

      <div className="padding-in-wowcommunity-grid-text">
        <div className="wowcommunity-page-araea-sub-headings110">
        OUR OBLIGATION TO CIVIC
        </div>

        <div className="wowcommunity-page-text">
        The present networks face an extraordinary arrangement of difficulties. Moreover, keeping
         in mind that innovation without anyone else is not the arrangement, when it has
          done well and joined with great organizations it can possibly add to a superior world for all.

        </div>
      </div>


    </Col>


      <Col xl={6} lg={6} md={12} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col3.jpg">

       </img>
      </Col>
      </Row>

  


   
  </Container>
  </div>










  <Container>
            <center>
              <h3 className="wow_community-collaborating">WOW COLLABORATING WITH CHARITIES</h3>
              <div className="wow_community-collaborating-heading-text">
              Banding together with charities can help have the most important 
              effect on everybody. Get familiar with our needs beneath:-
              </div>
            </center>
            </Container>










            <Container>
<div class="community-page-top-image">
  <img src="IMAGES/city-images/wow-community/icon-grid/charities-collaborating1.jpg" style={{width: '100%'}}/>

  </div>
  </Container>
















<Container>



<Row style={{marginTop:'2rem'}}>
<Col xl={4} lg={4} md={4} sm={12} xs={12}>
    <div class="communtiy-page-additional-features-area-items">
      <div class="communtiy-page-additional-features-area-items-image">
      <img src="IMAGES/city-images/wow-community/icon-grid/icon1.png"/>
          </div>
          <h2 class="how-to-get-started-communtiy-area-heading4"
           style={{textAlign: 'left', marginTop: '20px'}}>  Decent variety and consideration </h2>
           <p class="how-to-get-started-communtiy-area-text4">
             We help bolster associations that work with teachers, schools, and youth so the up and 
             coming age of individuals entering the workforce mirrors the networks we serve.
             </p>
             </div>
    </Col>




    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
    <div class="communtiy-page-additional-features-area-items">
      <div class="communtiy-page-additional-features-area-items-image">
      <img src="IMAGES/city-images/wow-community/icon-grid/icon1.png"/>
          </div>
          <h2 class="how-to-get-started-communtiy-area-heading4"
           style={{textAlign: 'left', marginTop: '20px'}}>  Decent variety and consideration </h2>
           <p class="how-to-get-started-communtiy-area-text4">
             We help bolster associations that work with teachers, schools, and youth so the up and 
             coming age of individuals entering the workforce mirrors the networks we serve.
             </p>
             </div>
    </Col>




             <Col xl={4} lg={4} md={4} sm={12} xs={12}>
    <div class="communtiy-page-additional-features-area-items">
      <div class="communtiy-page-additional-features-area-items-image">
      <img src="IMAGES/city-images/wow-community/icon-grid/icon1.png"/>
          </div>
          <h2 class="how-to-get-started-communtiy-area-heading4"
           style={{textAlign: 'left', marginTop: '20px'}}>  Decent variety and consideration </h2>
           <p class="how-to-get-started-communtiy-area-text4">
             We help bolster associations that work with teachers, schools, and youth so the up and 
             coming age of individuals entering the workforce mirrors the networks we serve.
             </p>
             </div>
    </Col>





    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
    <div class="communtiy-page-additional-features-area-items">
      <div class="communtiy-page-additional-features-area-items-image">
      <img src="IMAGES/city-images/wow-community/icon-grid/icon1.png"/>
          </div>
          <h2 class="how-to-get-started-communtiy-area-heading4"
           style={{textAlign: 'left', marginTop: '20px'}}>  Decent variety and consideration </h2>
           <p class="how-to-get-started-communtiy-area-text4">
             We help bolster associations that work with teachers, schools, and youth so the up and 
             coming age of individuals entering the workforce mirrors the networks we serve.
             </p>
             </div>
    </Col>




    {/* <Col xl={4} lg={4} md={4} sm={12} xs={12}>
    <div class="communtiy-page-additional-features-area-items">
      <div class="communtiy-page-additional-features-area-items-image">
      <img src="IMAGES/city-images/wow-community/icon-grid/icon1.png"/>
          </div>
          <h2 class="how-to-get-started-communtiy-area-heading4"
           style={{textAlign: 'left', marginTop: '20px'}}>  Decent variety and consideration </h2>
           <p class="how-to-get-started-communtiy-area-text4">
             We help bolster associations that work with teachers, schools, and youth so the up and 
             coming age of individuals entering the workforce mirrors the networks we serve.
             </p>
             </div>
    </Col>




    <Col xl={4} lg={4} md={4} sm={12} xs={12}>
    <div class="communtiy-page-additional-features-area-items">
      <div class="communtiy-page-additional-features-area-items-image">
      <img src="IMAGES/city-images/wow-community/icon-grid/icon1.png"/>
          </div>
          <h2 class="how-to-get-started-communtiy-area-heading4"
           style={{textAlign: 'left', marginTop: '20px'}}>  Decent variety and consideration </h2>
           <p class="how-to-get-started-communtiy-area-text4">
             We help bolster associations that work with teachers, schools, and youth so the up and 
             coming age of individuals entering the workforce mirrors the networks we serve.
             </p>
             </div>
    </Col> */}

    


 </Row>


 </Container>




<br/>



















 
</div>


    );
  }
}
export default Community;