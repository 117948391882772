import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import {
//   ComponentToPrint,
//   ReactToPrint,
//   PrintContextConsumer
// } from 'react-to-print';

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
  Media
} from "react-bootstrap";



class ComponentToPrint extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  render() {
    return (
      <div>


        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="invoice-le-view_area">
                
                  <div className="new-invoice-header-text-area" role="alert">
                    <h2>NEW INVOICE</h2>

                    <div className="two-button-edi-save">
                      <Button className="le-edit-button" variant="outline-info">
                        Edit
                      </Button>

                      <div className="le-button--with-actions--primary">
                        <button className="le-button">Save and continue</button>
                        <div
                          className="le-dropdown is-open"
                          id="accordion-5ed01b058660a-card-0"
                          data-target="#accordion-5ed01b058660a-collapse-0"
                          data-toggle="collapse"
                          role="tab"
                          aria-expanded="false"
                        >
                          <button
                            className="le-dropdown__toggle le-button"
                            data-controls="accordion-5ed01b058660a-collapse-0"
                          >
                            <i className="	fa fa-caret-down">
                              <use></use>
                            </i>
                          </button>
                          <ul
                            class="le-dropdown__menu--right"
                            role="menu"
                            aria-labelledby="accordion-5ed01b058660a-card-0"
                            className="d-print-block collapse"
                            collapsed=""
                            id="accordion-5ed01b058660a-collapse-0"
                            role="tabpanel"
                          >
                            <li class="le-dropdown__menu__item" role="menuitem">
                              <a
                                class="le-dropdown__menu__link"
                                rel=""
                                target="_self"
                              >
                                Save and send...
                              </a>
                            </li>
                            <li class="le-dropdown__menu__item" role="menuitem">
                              <a
                                class="le-dropdown__menu__link"
                                rel=""
                                target="_self"
                              >
                                Save and record a payment
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>

                  <div class="invoice-view__topmessage">
                    <div class="le-notify--info" role="alert">
                      <div class="le-notify__icon-holder">
                        <svg
                          className="le-svg-icon"
                          viewBox="0 0 20 20"
                          id="notification-info"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 19a9 9 0 110-18 9 9 0 010 18zM9 6a1 1 0 112 0 1 1 0 01-2 0zm0 2a1 1 0 000 2v3a1 1 0 100 2h2a1 1 0 100-2V9a1 1 0 00-1-1H9z"
                          ></path>
                        </svg>
                      </div>
                      <div class="le-notify__content-wrapper">
                        <div class="le-notify__content">
                          <div class="le-notify__message">
                            <span>
                              This is a preview of your invoice. Switch back to
                              Edit if you need to make changes.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="invoice-preview__warning-message le-text--body"></div>



                  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">




                      <section className="invoice-contemplary-body-inside__header">

<Row>
  <Col lg={6} xl={6} md={5} sm={5} xs={5}>
  <div className="invoice-contemplary-body-inside__header__logo-invoice">
    <img src="https://drive.google.com/uc?export=view&id=1-_drJvm5qH_2kdvRpPvcFhhOEm3vOn8F"/>
   </div>
</Col>


                        <Col lg={6} xl={6} md={7} sm={7} xs={7}>

                        





<Media
                          as="li"
                          className="media-ride-list-invoice"
                          
                        >
                          <div className="list-steps-of-ride-invoice">
                              <div className="invoice-contemplary-body-inside__header__logo">
                                <img src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png" />
                              </div>
                          </div>
                          <Media.Body className="ride-body-content">
                          <div className="contemporary-template__header__info">
                          <div className="le-heading--title">BADER HAMAD AL ZAABI
                           </div>
                          <strong className="le-text--strong">
                            ADVOCATES & LEGAL CONSULTANTS
                          </strong>
                          <div className="address-le-view-invoice fs-exclude">
                            <div className="address__field-invoice-view">
                              <p className="le-text le-text--body">
                                Office No. 3403 - 34th Floor - Burj Al Salam Tower
                                 - Trade Centre 1 - Sheikh Zayed Road -
                                Dubai - United Arab Emirates - P.O. Box No.
                                89498, Dubai. TRN: 10037287460003
                                Tel: +9714 2555111, Fax: +9714 2368888
                                E-MAIL: info@legalemirates.com
                              </p>
                            </div>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>
                        </div>

                          </Media.Body>
                        </Media>
                        </Col>
                        </Row>
                      </section>

                   
                   
                   
                   


                   
                      <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top"></div>
                      </div>


<div class="description_of_card_of_invoicetable">
 <div class="description_of_card_of_invoicetable_area">


                      <div className="contemporary-template__header__logo-invoice-view">
                      <Row style={{width:'100%'}}>
                        <Col lg={5} xl={5} md={5} sm={12} xs={5}>
                        

                        <div className="date-info-invoice-le" >
                          <h2 className="le-table-customer-data-heading">
<span><i class="fas fa-square box-gradient-icon-invoice-view"></i>DATE INFORMATION</span>
                          </h2>

                          <div>
<table className="le-table--plain">
                            <tbody className="le-table__body">

                            <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--issuedate-invoice">Issue date : 12/05/2020</strong>
                                  </td>
                              </tr>
                              <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--issuedate-invoice">Due date : 15/05/2020</strong>
                                  </td>
                              </tr>


                                  </tbody>
                                  </table>
                                  </div>

                        </div>

                        </Col>



                        <Col lg={5} xl={5} md={5} sm={12} sm={5} xs={5}>
    
                        <div style={{marginLeft:'0px'}}>
                          <h2 className="le-table-customer-data-heading">
<span><i class="fas fa-square box-gradient-icon-invoice-view"></i>CUSTOMER</span>
                          </h2>

                          <div>
<table className="le-table--plain">
                            <tbody className="le-table__body">

                            <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--strong-invoice">Muahmmad Tahir</strong>
                                  </td>
                              </tr>
                              <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--invoice">+971 55 888 9544</strong>
                                  </td>
                              </tr>


                              <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--invoice">Downtown, Dubai</strong>
                                  </td>
                                </tr>


                                <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--invoice">United Arab Emirates</strong>
                                  </td>
                                </tr>


                                <tr className="le-table__row">
                                <td className="le-table__cell" colspan="1">
                                  <strong className="le-text--invoice">ceo@wowets.com</strong>
                                  </td>
                                </tr>


                                  </tbody>
                                  </table>
                                  </div>

                        </div>


                        </Col>

                        <Col lg={2} xl={2} md={2} sm={12} xs={2}>
                        <div className="invoice-contemplary-body-inside__header__logo-inside-tale-customer">
                          <img src="IMAGES/dsdssdds.png" />
                        </div>
                        
                        </Col>

                      </Row>
                      </div>







<Col xl={12} md={12} lg={12}>
                      <div class="contemporary-template__items-table">
                        <table class="le-table">

                          <thead class="le-table__header" 
                          style={{backgroundColor: 'rgb(189, 155, 62)'}}>
                            <tr class="le-table__row">
                              <th class="le-table__cell--amount" colspan="1" 
                            style={{color: 'rgb(255, 255, 255)'}}>Sr. No</th>


                   <th class="le-table__cell--amount class-table-particular" colspan="5" 
                  style={{color: 'rgb(255, 255, 255)', width:'335px'}}>Description of Services</th>

<th class="le-table__cell--amount" colspan="1" 
                            style={{color: 'rgb(255, 255, 255)'}}>Amount</th>



            </tr>
            </thead>

            
            
              <tr class="le-table__row">
              <td class="le-table__cell" colspan="1">
                <div class="no-items-message">
                  <em class="le-text--emphasized">1.</em>
                  </div>
                  </td>

                  <td class="le-table__cell" colspan="5">
                <div class="no-items-message">
                  <em class="le-text--emphasized">Open a File/File No 19</em>
                  </div>
                  </td>
                  
                  <td class="le-table__cell" colspan="1">
                <div class="no-items-message">
                  <em class="le-text--emphasized">AED 100</em>
                  </div>
                  </td>
                  
                  
                  </tr>





                  <tr class="le-table__row">
              <td class="le-table__cell" colspan="1">
                <div class="no-items-message">
                  <em class="le-text--emphasized">1.</em>
                  </div>
                  </td>

                  <td class="le-table__cell" colspan="5">
                <div class="no-items-message">
                  <em class="le-text--emphasized">Appeal to case number 12345 services include court fee</em>
                  </div>
                  </td>
                  
                  <td class="le-table__cell" colspan="1">
                <div class="no-items-message">
                  <em class="le-text--emphasized">AED 100</em>
                  </div>
                  </td>
                  
                  
                  </tr>
                  
                  </table>
                  </div>
                  </Col>


          
                  <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                    <div class="le-divider-below"></div>
                    </div>







                    <Row className="how-to-work-grid">
                <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                <div style={{marginLeft:'20px', marginBottom:'20px'}}>
                          <h2 className="le-table-customer-data-heading-behind-table">
                          <span><i class="fas fa-square box-gradient-icon-invoice-view"></i>Payment Method</span>
                          </h2>

                          <div>
<table className="le-table--plain-behind-table">
                            <tbody className="le-table__body-behind-table">

                            <tr className="le-table__row-behind-table">
                                <td className="le-table__cell-behind-table">
                              <strong className="le-text--strong-invoice-behind-table">
                               
                                By Cash
                                  </strong>
                                  </td>
                              </tr>


                                  </tbody>
                                  </table>
                                  </div>

                        </div>


                  
                </Col>
                <Col xl={6} md={6} sm={12} xs={6} lg={6}>
                  


                <div class="contemporary-template__totals__amounts">
                  <div class="contemporary-template__totals__amounts__line">
                    <div class="contemporary-template__totals__amounts__line__label">
                      <strong class="le-text--strong-totals__amounts">Sub Total:</strong>
                </div>
                <div class="contemporary-template__totals__amounts__line__amount">
                  <span class="le-text-totals__amounts le-text--body-totals__amounts">AED 2,600</span>
                  </div>
                  </div>
                  <div>
                    </div>
              
                  <div>
                    <div class="contemporary-template__totals__amounts__line">
                      <div class="contemporary-template__totals__amounts__line__label">
                        <strong class="le-text--strong-totals__amounts">VAT (5%):</strong>
                        </div>
                <div class="contemporary-template__totals__amounts__line__amount">
                  <strong class="le-text-totals__amounts le-text--body-totals__amounts">AED 0
                  </strong>
                  </div>
                  </div>
                  </div>


                  <div>
                    <div class="contemporary-template__totals__amounts__line">
                      <div class="contemporary-template__totals__amounts__line__label">
                        <strong class="le-text--strong-totals__amounts">Total:</strong>
                        </div>
                <div class="contemporary-template__totals__amounts__line__amount">
                  <strong class="le-text-totals__amounts le-text--body-totals__amounts">AED 2,600
                  </strong>
                  </div>
                  </div>
                  </div>


              </div>

              <div class="contemporary-template__divider--full-width-sign contemporary-template__divider--bold-sign">
                    <div class="le-divider-amount-small"></div>
                    </div>


              
<div className="le-text-al-payment-amount-usingcash">
              <div className="le-text-al-payment-amount-usingcash">
                Payment on july 07, 2020 using cash:</div>
 <div className="text-authorized-payment-amount-usingcash">AED 500</div>
    

    </div>

    <div className="le-text-al-payment-amount-usingcash">
              <div className="le-text-al-payment-amount-usingcash">
                Payment on july 09, 2020 using cash:</div>
 <div className="text-authorized-payment-amount-usingcash">AED 1,000</div>
    

    </div>


                  <div className="amount-padding-color-price">
                    Amount Due : &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; AED 1,100.00
                  </div>

                
                

                




              







              {/* <div class="contemporary-template__divider--bold contemporary-template__divider--small-margin">
                <div class="le-divider">
                  </div>
                  </div> */}
                </Col>
              </Row>





              <div class="contemporary-template__divider--full-width contemporary-template__divider--bold">
                    <div class="le-divider"></div>
                    </div>







              <div class="description_of_card_of_blogpost2">
  <div class="description_of_card_of_newsroom_area-blogpost2">

  <h2 class="blogs-description-area-of-main-headings-blogpost2">
     Terms & Conditions
  </h2>

  <p class="invoice-description-area-of-preview--text">
  1. This a computer generated invoice to open a file payment. 
  This is an electronic receipt; any signature or stamp on it is not required.
   </p>


   
   
   <p class="invoice-description-area-of-preview--text">
   2. The acknowledgment of this opening a file receipt by the customer of the paid amount is shown as a
    NON-REFUNDABLE payment. The customer has no right to claim the refund of opening a file payment.
    </p>

      
  <p className="invoice-description-area-of-preview--text">
  3. BADER HAMAD AL ZAABI’s ADVOCATES & LEGAL CONSULTANTS reserve the right to review and aim
   the above terms as per the judge's fit and as per the applicable laws.
         </p>


  


         <p className="invoice-description-area-of-preview---belowfooter-text">
  For Questions concerning this invoice, please contact info@legalemirates.com | www.legalemirates.com
         </p>

    

        
   
        </div>
        </div>





        </div>
        </div>








                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>




        









      </div>
    );
  }
}

class Legalemiratesinvoiceview extends React.Component {
  render() {
    return (
      <div>
        <Container>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <Button 
            style={{borderRadius:'20px'}}
            variant="outline-dark">Print this out!</Button>;
          }}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={el => (this.componentRef = el)} />
        </Container>
      </div>
    );
  }
}

export default Legalemiratesinvoiceview;
