import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { compose, withProps } from "recompose";
// import GoogleMapReact from 'google-map-react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import {
  Container,
  Card,
  Row,
  Slider,
  Navbar,
  Col,
  Nav,
  FormControl,
  Form,
  Button,
  NavDropdown,
  Toggle

} from "react-bootstrap";



  
class New extends Component {

 
    state = {
        isMarkerShown: false,
      }
    
      componentDidMount() {
        this.delayedShowMarker()
      }
    
      delayedShowMarker = () => {
        setTimeout(() => {
          this.setState({ isMarkerShown: true })
        }, 3000)
      }
    
      handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
      }

     
      
   
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
    // this.handleClick = this.handleClick.bind(this);
  }

  // handleClick() {
    
  //   this.setState(state => ({
  //     isToggleOn: !state.isToggleOn
      
  //   }));
  // }

 
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString().trimLeft };

    this.setState({
      date: date
    });
  }

  
 

  render() {
    return (
      
        <div>













         <div>
          <header className="header-image-in-new-design">


   <div className="logo-inside-header-of-new-design-page">
     <img className="image-logo-inside-header-of-new-design-page" src="IMAGES/Newdesign/logo-white.png"></img>
   </div>


   <div className="text-box-inside-background-image-of-new-design">
  <h1 className="heading-primary">
    <span className="heading-primary-main">
      Outdoors

    </span>
    <span className="heading-primary-sub">
      is where life happens

    </span>
  </h1>
  <button  className="button-in-new-design" > Discover Our Tours</button>

  </div>
  {/* <script>
  function myFunction() {
  document.getElementById("demo").innerHTML = "Hello World"
}
  </script> */}
  <toggle>

  </toggle>

{/* <div style={{textAlign:'center'}}>
    <img style={{height:'15vh'}} src="IMAGES/wowboard.png"></img>
</div> */}


          </header></div>






            

        
            
      






        
        


        









</div>


    );
  }
}
export default New;