


import * as Actions from '../../actions/CustomerSignUp';

const initialState = {
    isLoading: true,
    errMsg: null,
    data: []
    };


// get active categories
export const customerSignUpReducer = function(state = initialState, action) {
switch (action.type) {
case Actions.CUSTOMER_SIGN_ACTION:
return { ...state, isLoading: false, errMsg: null, data: action.payload };
case Actions.CUSTOMER_SIGN_ACTION_LOADING:
return { ...state, isLoading: true, errMsg: null, data: [] };
case Actions.CUSTOMER_SIGN_ACTION_LOADING_FAILED:
return { ...state, isLoading: false, errMsg: action.payload, data: [] };
default:
return state;
}
};
