import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { FaBeer } from "react-icons/fa";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  Button,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost9/blogpost9.scss";

class Blogpost9 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
    };
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    return (
      <div>
        <div className="td-post-header-holder-blogcard9 td-image-gradient-blogcard9">
          <div className="td-post-featured-image-blogcard9">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard9"
              src="IMAGES/city-images/wow-blog/additional-cards/blog9/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard9">
              <ul className="td-category-blogcard9">
                <li className="entry-category-blogcard9">
                  <a
                    target="blank"
                    href="https://www.nationalgeographic.com/travel/top-10/places-to-stay-tree-house-hotels/"
                  >
                    Tree Houses Stay
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard9">
                WOW Events & Travel Stories
              </h1>

              <h4 className="entry-title-two-blogcard9">
                No matter your age, spending the night among the treetops is
                sure to rekindle fond childhood memories of imagination and
                adventure.
              </h4>

              <div className="td-module-meta-info-blogcard9">
                <span className="td-post-date-blogcard9">
                  <time
                    className="entry-date-blogcard9 updated td-module-date-blogcard9"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    23 July 2020
                  </time>
                </span>

                <div className="td-post-views-blogcard9">
                  <i className="fa fa-eye view-icon-views-blogcard9"></i>
                  <span className="td-nr-views-10968">22235</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard9">
            <div className="heading-secondary-uk-section-wow-blogscard9 ">
              WOW Events & Travel Stories
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard9-description-area-of-main--text-above">
            No matter your age, spending the night among the treetops is sure to
            rekindle fond childhood memories of imagination, exploration and
            adventure. With 40% of global travelers* planning to stay in a
            unique accommodation, like a treehouse, at least once this year, we
            have delved into our more than 29 millions listings in more than
            155,000 destinations around the world to present six incredible tree
            houses guaranteed to reawaken that childlike sense of adventure and
            help travelers reconnect with nature. From sleeping high among the
            treetops in the Costa Rican rainforest to relaxing at a luxurious
            retreat in Thailand, there is a tree house to thrill every type of
            traveler.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard9-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog9/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard9-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog9/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard9-description-area-of-main--text-above">
            With tree houses and luxurious safari tents that will appeal to a
            wide range of travelers, this gorgeous glamping getaway is located
            near Glacier National Park in Montana's Rocky Mountains, known for
            its breathtaking glacier-carved peaks and more than 700 miles (1,126
            km) of hiking trails. Surrounded by dramatic vistas and pristine
            natural beauty, this part of northwestern Montana is still a bit off
            the beaten track and ideal for those in the mood for an aspirational
            travel adventure. The campground provides all of the necessary
            essentials to ensure an authentic and comfortable experience in the
            forest, including camp cots, sleeping bags, safari chairs and even
            lanterns. Just a short drive away, the Hungry Horse Reservoir is a
            hidden gem, perfect for recreational water activities in the summer
            or simply to enjoy stunning views of the rugged terrain and the
            snow-capped peaks to the east in the winter.
          </p>

          <p class="blogscard9-description-area-of-main--text-above">
            Think back to your childhood years... Playing hide and seek, hosting
            glamorous tea parties with your cuddly friends, and building forts
            you imagined were fairy tale castles in a land far, far away...
            Bring those dreams to life when you stay on a magical treehouse
            holiday. The Golden Oak Treehouse benefits from all the luxuries of
            our main Golden Oak cabins and sleeps up to 10 people.
          </p>
        </Container>
        <br />

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogcard9">
            <div className="heading-secondary-section-wow-blogcard9 ">
              Treehouses Travel Info, What you should Know:
            </div>
          </div>
        </Container>

        <Container>
          <div
            id="accordion-5ed01b058660a"
            className="accordion indicator-plus accordion-white mb-3"
            role="tablist"
          >
            <div className="card-blogcard9 bar">
              <div className="card-blogcard9 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-0"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard9-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-0"
                  >
                    <span role="heading" aria-level="0">
                      Where can you vacation in a treehouse?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-0"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-0"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard9">
                    <Row>
                      <Col lg={6}>
                        <p style={{ padding: "3rem" }}>
                          With tree houses and luxurious safari tents that will
                          appeal to a wide range of travelers, this gorgeous
                          glamping getaway is located near Glacier National Park
                          in Montana's Rocky Mountains, known for its
                          breathtaking glacier-carved peaks and more than 700
                          miles (1,126 km) of hiking trails. The campground
                          provides all of the necessary essentials to ensure an
                          authentic and comfortable experience in the forest,
                          including camp cots, sleeping bags, safari chairs and
                          even lanterns. Just a short drive away, Treehouse
                          Hotels That Will Make You Feel Like a Kid Again
                          <ul>
                            <li>
                              Out 'n' About Treehouse Treesort - Cave Junction,
                              Oregon.
                            </li>

                            <li>
                              River's Edge Treehouse Resort - Robbinsville,
                              North Carolina.
                            </li>

                            <li>
                              The Original Treehouse Cottages - Eureka Springs,
                              Arkansas.
                            </li>

                            <li>
                              The Treehouse at Winvian Farm - Morris,
                              Connecticut.
                            </li>
                          </ul>
                        </p>
                      </Col>

                      <Col lg={6}>
                        <div className="wow-news-card1-work-page-top-image-blogscard9-aboveimage">
                          <img
                            style={{ width: "100%" }}
                            src="IMAGES/city-images/wow-blog/additional-cards/blog9/collapse1.jpg"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard9 bar">
              <div className="card-blogcard9 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-1"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard9-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-1"
                  >
                    <span role="heading" aria-level="0">
                      Do you need building permit for treehouse?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-1"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-1"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard9">
                    <p style={{ marginBottom: "1rem" }}>
                      Though building a small tree house in your backyard for a
                      child normally does not require a permit, the procedures
                      differ from area to area, as well as upon the size and
                      scope of the tree house plans.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard9 bar">
              <div className="card-blogcard9 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-2"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard9-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-2"
                  >
                    <span role="heading" aria-level="0">
                      WHY VISIT Tree House?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-2"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-2"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard9">
                    <p>
                      A tree house, tree fort or treeshed is a platform or
                      building constructed around, next to or among the trunk or
                      branches of one or more mature trees while above ground
                      level. Tree houses can be used for recreation, work space,
                      habitation, a hangout space and observation.
                      <br />
                      Although it may not be the most common living style, if
                      you own the land that your treehouse is built on and own
                      the treehouse itself then yes, you can live in a
                      treehouse. Otherwise, so long as you have an agreement
                      with the owner of the treehouse, you can live there
                      without issue.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard9 bar">
              <div className="card-blogcard9 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-5"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard9-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-5"
                  >
                    <span role="heading" aria-level="0">
                      What is the best tree for a treehouse?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-5"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-5"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard9">
                    <p>
                      What is a good tree to build in? Almost any mature,
                      healthy deciduous or coniferous tree can be used to
                      support a treehouse. Examples of particularly good species
                      include; oak, beech, maple, ash, cedar, hemlock and
                      Douglas-fir. If building in a very windy site, see the
                      question below about strong winds.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="gradient-back-travel-blogscard9">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard9-grid-text">
                  <div className="travel-blogscard9-page-araea-sub-headings110">
                    Teniqua Treetops, Western Cape, SA
                  </div>

                  <div className="travel-blogscard9-page-page-text">
                    Almost three quarters (73%) of global travelers** intend to
                    stay in an eco-friendly accommodation at some point in 2020.
                    With that in mind, this eco-friendly tented treetop resort
                    near Seven Passes Road, the oldest mountain pass in South
                    Africa’s Western Cape, is not only sustainable but also
                    quite literally surrounded by greenery. These treetop suites
                    make for a relaxing and whimsical road trip pitstop for
                    those exploring South Africa’s iconic Garden Route by car.
                    Stretching from the Western to the Eastern Cape, it’s one of
                    the most scenic coastal routes in the world, with stunning
                    mountain vistas and sparkling lagoons to discover around
                    every scenic turn.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog9/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard9">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog9/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard9-grid-text">
                  <div className="travel-blogscard9-page-araea-sub-headings110">
                    TREEHOTEL, SWEDEN
                  </div>

                  <div className="travel-blogscard9-page-page-text">
                    Treehotel in the pine forest of Swedish Lapland, where seven
                    rooms are suspended 4-6 meters (13-20 ft) above ground with
                    spectacular views of the Lule River valley, miles of forest
                    and a powerful river. The Mirrorcube is the hotel’s most
                    exciting accommodation, camouflaged by mirrored walls that
                    reflect the Northern Lights and the surrounding boreal
                    fores. Guests here indulge in meals made with the freshest
                    local produce and enjoy excursions such as fat-biking
                    through the taiga, dog sledding, and family ice-fishing
                    trips. Treehotel was inspired by the film ”The Tree Lover”
                    by Jonas Selberg Augustsen.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard9">
            <div className="heading-secondary-help-community-section-wow-blogscard9 ">
              Treehouse Hotels Travel Trips
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard9">
            <div class="description_of_card_of_blogscard9_area">
              <h2 class="blogscard9-description-area-of-main-headings">
                Sanya Nanshan Treehouse Resort and Beach Club …
              </h2>
              <p class="blogscard9-description-area-of-main--text">
                Built into old tamarind trees near a "new 5,000 acre Buddhist
                and ecological theme park complete with temples, pagodas, (and)
                botanical gardens," these treehouses offer beach access, views
                of the South China Sea, and can sleep up to 20 guests over three
                levels.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog9/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog9/help-tips-grid2.jpg" />
                </Col>
              </Row>

              <h2 class="blogscard9-description-area-of-main-headings">
                Finca Bellavista, Piedras Blancas, Costa Rica....
              </h2>
              <p class="blogscard9-description-area-of-main--text">
                Accessible only by 4x4 vehicles and a 15-minute hike, the tree
                houses at Finca Bellavista offer a spectacular off-the-grid
                experience in the heart of the Costa Rican jungle. Located high
                among the treetops, this remote escape is Ideal for those
                looking for a little digital detox or wanting to reconnect with
                nature. If napping to the sounds of tropical birds ever gets
                old, there are also plenty of activities available nearby to
                cater to more adventurous tastes, including hiking, mountain
                biking and zipline tours. Thanks to the property’s farm-to-table
                philosophy and commitment to support local farmers, guests can
                also enjoy plenty of fresh organic fruit and vegetables during
                their stay.
              </p>

              <h2 class="blogscard9-description-area-of-main-headings">
                TreeHouse Villas, Ko Yao Noi, Thailand....
              </h2>
              <p class="blogscard9-description-area-of-main--text">
                Nestled in the middle of an eco reserve in the Patagonia region
                at the southernmost tip of Chile, the wooden bungalows at this
                forest escape offer intimate views of the surrounding landscape
                and an amazing opportunity to observe the area’s unique wildlife
                in their natural environment. While guests do need to provide
                their own sleeping bags at this rustic retreat, these beautiful
                shelters make for a convenient homebase for trekking through the
                majestic peaks, valleys, lakes and rivers the area is known for,
                including the massive nearby Lake Pirihueico, which is ideal for
                kayaking and other water sports.
              </p>

              <p class="blogscard9-description-area-of-main--text">
                Surrounded by lush tropical forest, with dramatic cliffs as a
                backdrop and a nearby private beach, the tree house villas at
                this exclusive, adults-only resort on the island of Ko Yao Noi
                in Thailand are anything but rustic. With a secluded location
                between the popular destinations of Phuket and Krabi, it’s all
                about attention to detail and pampering, as each villa comes
                with its own private plunge pool and exclusive hammock-shaped
                bathtub. Regardless of whether or not you want to indulge in a
                soothing massage, practice your yoga or trek through the
                surrounding rainforest, at this island escape you can
                personalize your tree house experience for maximum relaxation
                and rejuvenation.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard9">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog9/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard9">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog9/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 className="blogscard9-description-area-of-main-headings">
                Visit Treehouse, Lion Sands...
              </h2>
              <p className="blogscard9-description-area-of-main--text">
                Though this South African wilderness resort has lodges with
                equally luxurious rooms, the way to go is a night in one of the
                treehouses you can book above those rooms, accessible via raised
                wooden walkways. When you’re perched above the savannah in a
                king-sized bed, it’s hard to imagine staying anywhere else.
              </p>

              <p className="blogscard9-description-area-of-main--text">
                No one is a foodie. A buffet with lots of choices is good
                enough. Bonus: Their package included airfare. No: Mary and
                Edward spend their weekends exploring wineries and hitting the
                newest restaurants. They don’t like crowds and still talk about
                that trip they took hiking the Alaskan backcountry.
              </p>

              <h2 className="blogscard9-description-area-of-main-headings">
                Most amazing treehouse hotels in the world.....
              </h2>
              <p className="blogscard9-description-area-of-main--text">
                Most of us have had that everlasting longing for a treehouse
                ever since we were children. There is something about hiding up
                in the trees surrounded by the blissful sounds of nature that
                has called to all of us at least once or twice. Make all of your
                treehouse dreams come true in my selection of 10 stunning
                treehouse hotels across the globe.
              </p>

              <ul className="list-circle-type-blogscard">
                <li
                  class="blogscard9-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      POST RANCH INN, USA:{" "}
                    </span>
                    Nestled on the cliffs of California’s Big Sur, Post Ranch
                    Inn offers a perfect palette that balances its stunning
                    natural setting with uniquely designed rooms, award-winning
                    dining, and a signature spa experience. The luxury resort’s
                    organic architecture embraces the dramatic beauty of the Big
                    Sur coastline, seeming to grow out of the ground beside the
                    hotel’s surrounding redwoods. The lodge features 39 rooms,
                    including several free-standing tree houses built on stilts
                    3 m (9 ft) off the forest ground to protect the delicate
                    root balls below. Triangular in shape, each tree house
                    features a king-sized bed, window seat, desk, fireplace, and
                    skylight to watch the stars from your bed each evening. From
                    Post Ranch Inn, you can explore all the spectacular scenery
                    that Big Sur has to offer, or take a short drive to the
                    picturesque towns of Monterey and Carmel.{" "}
                  </p>
                </li>
                <li
                  class="blogscard9-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Nothofagus Hotel & Spa:{" "}
                  </span>
                  Floor-to-ceiling windows and private balconies offer leafy
                  views of both the Patagonian Andes and Mocho Choshuenco
                  Volcano. Premium suites come with their own living room,
                  whirlpool tub, and, appropriately, rainforest shower. There
                  are also two restaurants, and a heated swimming pool and full
                  spa below.
                  <ul className="list-circle-type-blogscard-inherited-list">
                    <li
                      class="blogscard9-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Neltume, Chile
                    </li>

                    <li
                      class="blogscard9-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Number of rooms: 55
                    </li>

                    <li
                      class="blogscard9-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Cost per night: From $315
                    </li>
                  </ul>
                </li>
                <li class="blogscard9-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>
                    Canopy Treehouses:{" "}
                  </span>
                  These cabins sit in the tree canopy above 100 acres of
                  rainforest along the Ithaca River. The land surrounding the
                  resort produces quadruple the amount of fruit as the areas
                  around it, which attracts extra-rare wildlife like Lumholtz
                  Tree Kangaroos and Green Possums -- who often come wandering
                  across your balcony to say hello.
                  <ul className="list-circle-type-blogscard-inherited-list">
                    <li
                      class="blogscard9-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Tarzali, Queensland, Australia
                    </li>

                    <li
                      class="blogscard9-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Number of rooms: 7
                    </li>

                    <li
                      class="blogscard9-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Cost per night: From $310
                    </li>
                  </ul>
                </li>
              </ul>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard9">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog9/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard9">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog9/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard9-description-area-of-main-headings">
                How will the treehouse affect the tree in strong winds?
              </h2>

              <p class="blogscard9-description-area-of-main--text">
                A treehouse will act as a sail in strong winds, which can add a
                large load to the tree's roots. Trees normally withstand
                excessive wind speeds by shedding parts of their structure,
                first leaves, then small branches, then large branches. This
                means that the area that catches the wind and could potentially
                push the tree over is limited to the large branches and trunk,
                as smaller parts will be blown off before wind speeds are high
                enough to push the tree over. A treehouse can therefore present
                a considerable extra wind catchment area. A tree in a forest
                will experience much lower wind speeds than one in the open. A
                treehouse low in the tree will experience lower wind speeds and
                will produce lower forces on the roots because the leverage
                effect of the trunk will be less. A treehouse with open walls
                that allow air through will cause much lower sail effects. A
                tree will react to the presence of a treehouse within a few
                years by adding extra thickness to supporting roots. This means
                the tree is most vulnerable during large storms in the first few
                years after a treehouse is built, but will subsequently be more
                able to cope.{" "}
              </p>

              <div class="description_of_card_of_blogscard9_bottom_area">
                <div class="description_of_card_of_blogscard9_bottom_area_previous">
                  <h3>
                    <Link 
                    onClick={scrollToblogforward}
                    to="/holidayblogs">
                    <i class="fas fa-reply" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard9_bottom_area_next">
                  <h3>
                  <Link 
                    onClick={scrollToblogforward}
                    to="/holidayblogs">
                    <i class="fa fa-mail-forward" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost9;
