import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../Lenooneinvoice/nooneinvoice.scss';
import '../Legalemiratesinvoiceview/invoiceview';

import { Visible, Hidden, setConfiguration } from "react-grid-system";
import ReactToPrint from 'react-to-print-advanced';
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
  Media,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

class ComponentToPrint extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  render() {
    return (
      <div>







        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                
                  <div className="new-invoice-payment-voucher-header-text-area" role="alert">
                    <h2>Invoice#1</h2>

                    <div className="two-button-edi-save-new-invoice-payment-voucher">

                   

                      <div className="le-button--with-actions--savecontinue-newinvoice-paymentvoucher">
                        <button className="le-button">
                            Create another invoice</button>
                        <div
                          className="le-dropdown-newinvoice-paymentvoucher is-open"
                          id="accordion-5ed01b058660a-card-0"
                          data-target="#accordion-5ed01b058660a-collapse-0"
                          data-toggle="collapse"
                          role="tab"
                          aria-expanded="false"
                        >
                          <button
                            className="le-dropdown__toggle-newinvoice-paymentvoucher le-button"
                            data-controls="accordion-5ed01b058660a-collapse-0"
                          >
                            <i className="	fa fa-caret-down">
                              <use></use>
                            </i>
                          </button>
                          <ul
                            class="le-dropdown__menu--right-newinvoice-paymentvoucher"
                            role="menu"
                            aria-labelledby="accordion-5ed01b058660a-card-0"
                            className="d-print-block collapse"
                            collapsed=""
                            id="accordion-5ed01b058660a-collapse-0"
                            role="tabpanel"
                          >
                            <li class="le-dropdown__menu__item-newinvoice-paymentvoucher" role="menuitem">
                              <a
                                class="le-dropdown__menu__link-newinvoice-paymentvoucher"
                                rel=""
                                target="_self"
                              >
                                Save and send...
                              </a>
                            </li>
                            <li class="le-dropdown__menu__item-newinvoice-paymentvoucher" role="menuitem">
                              <a
                                class="le-dropdown__menu__link-newinvoice-paymentvoucher"
                                rel=""
                                target="_self"
                              >
                                Save and record a payment
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>


                  <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top-invoiceone"></div>
                      </div>

                  <div class="invoice-preview__warning-message le-text--body"></div>
































                  <div className="new-invoice-noone-div-body-le">
                    <div className="invoice-contemplary-body-inside-new-noone">






                      

                      <Container>


<div className="status-cust-invoice">
<div>
<div className="status-cust-headings-invoice">Status</div>
<p className="status-cust-draft-invoice">Draft</p>
</div>

<div>
      <div className="status-cust-headings-invoice">Customer</div>
      <p className="status-cust-name-invoice">Muhammad Tahir
      <span><i class="fas fa-exclamation-circle exclamation-icon" aria-hidden="true"></i></span></p>
</div>

<div>
      <div className="status-cust-headings-invoice">Amount due</div>
      <p className="status-cust-amount-invoice">AED137.00</p>
</div>

<div>
      <div className="status-cust-headings-invoice">due</div>
      <p className="status-cust-dueday-invoice">Today</p>
</div>
</div>
</Container>
                














<div className="invoice-previe-div-body-le-small-box">
<div className="invoice-contemplary-body-inside-small-box">

<Row>
                        <Col lg={12} md={12} xl={12} >

                          {/* <div style={{marginTop:'2rem'}}>
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <img src="IMAGES/circle-doc.png"></img>
                              <div style={{marginTop:'2rem'}}>
                                Creative Invoice
                              </div>

                            </div>

                          </div> */}
<div className="invoice-one-border-box">
<Media
                          as="li"
                          className="media-ride-list-invoice-small-box"
                          
                        >
                          <div className="list-steps-of-ride-invoice-small-box">
                              <div className="invoice-contemplary-body-inside__header__logo-small-box">
                                <img src="IMAGES/circle-doc.png" />
                              </div>
                          </div>
                          <Media.Body>
                          <div className="contemporary-template__header__info-small-box">
                          <div className="le-heading--title-small-box">Creative Invoice
                           </div>
                          <strong className="le-text--strong-small-box">
                            Created: <span style={{color:'#aaaaaa'}}>
                              on july 1, 2020 at 11:41 AM GMT+5</span>
                          </strong>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>

                          </Media.Body>
                        </Media>

{/* </Col>


      <Col lg={6} md={6} xl={6}> */}

             <div>

             <Button className="le-newinvoice-payment-voucher-edit-button" variant="dark">
                  Approve draft
            </Button>


            <Button className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
                Edit draft
             </Button>

              

  
            </div>
            </div>
</Col>

</Row>

</div>

</div>        
                   
                   









<div className="invoice-previe-div-body-le-small-box-invoicegetpaid">
<div className="invoice-contemplary-body-inside-small-box-invoicegetpaid">

<Row>
                        <Col lg={12} md={12} xl={12} >

<div className="invoice-one-border-box-invoicegetpaid">
<Media
                          as="li"
                          className="media-ride-list-invoice-small-box-invoicegetpaid"
                          
                        >
                          <div className="list-steps-of-ride-invoice-small-box-invoicegetpaid">
                            <div className="invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid">
                                <img src="IMAGES/circle-cursor.png" />
                              </div>
                          </div>
                          <Media.Body>
                          <div className="contemporary-template__header__info-small-box-invoicegetpaid">
                          <div className="le-heading--title-small-box-invoicegetpaid">Send Invoice
                           </div>
                          <strong className="le-text--strong-small-box-invoicegetpaid">
                            Last Seen: Never
                          </strong>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>

                          </Media.Body>
                        </Media>


            </div>
</Col>

</Row>

</div>

</div>    










<div className="invoice-previe-div-body-le-small-box-invoicegetpaid">
<div className="invoice-contemplary-body-inside-small-box-invoicegetpaid">

<Row>
                        <Col lg={12} md={12} xl={12} >

<div className="invoice-one-border-box-invoicegetpaid">
<Media
                          as="li"
                          className="media-ride-list-invoice-small-box-invoicegetpaid"
                          
                        >
                          <div className="list-steps-of-ride-invoice-small-box-invoicegetpaid">
                          <div className="invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid">
                                <img src="IMAGES/circle-credit.png" />
                              </div>
                          </div>
                          <Media.Body>
                          <div className="contemporary-template__header__info-small-box-invoicegetpaid">
                          <div className="le-heading--title-small-box-invoicegetpaid">Get Paid
                           </div>
                          <strong className="le-text--strong-small-box-invoicegetpaid">
                            Amount Due: AED137.00
                          </strong>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>

                          </Media.Body>
                        </Media>


            </div>
</Col>

</Row>

</div>

</div>    












                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>







        



















      </div>
    );
  }
}

class Lenooneinvoice extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
        bodyClass={'largeScreen'}
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <a href="#">Print this out!</a>;
          }}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default Lenooneinvoice;