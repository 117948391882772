import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col
} from "react-bootstrap";

import{
  Link
} from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};




class News extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date
    });
  }
  render() {
    return (
      <div>


<Container>

    






<Container>
<div className="text-box-inside-background-image-of-new-design carousel-in-mobile-view-wow-holiday">
  <h1 className="heading-primary">
    <span className="heading-primary-main">
    TOP DESTINATIONS

    </span>
    <span className="heading-primary-sub">
    Discover tours, attractions and activities for your next adventure

    </span>
  </h1>
 

  </div>
  </Container>
















        <Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1}
  keyBoardControl={true}
  customTransition="all 2.5s linear"
  transitionDuration={3500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  pause={true}
  className="carousel-in-mobile-view-wow-holiday"

>
  
<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/atlantis.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Atlantis the Palm</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">Dubai, UAE</div>
      </div>
      
      </Link>
      
      </div>
</Col>


<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/wildwadi.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Wild Wadi Park</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">Dubai, UAE</div>
      </div>
      
      </Link>
      
      </div>
</Col>

<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/eiffel.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Eiffel Tower</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">Paris, France</div>
      </div>
      
      </Link>
      
      </div>
</Col>




<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/skidubai.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Ski Dubai</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">Dubai, UAE</div>
      </div>
      
      </Link>
      
      </div>
</Col>




<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/burjalarab.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Burj Al Arab</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">Dubai, UAE</div>
      </div>
      
      </Link>
      
      </div>
</Col>


<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/liberty.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Statue of Liberty</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">New York, US</div>
      </div>
      
      </Link>
      
      </div>
</Col>

<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/empirestate.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Empire State Building</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">New York, US</div>
      </div>
      
      </Link>
      
      </div>
</Col>


<Col xs={12} sm={12} lg={3} md={3}>
<div className="box-around-image-2">
    <Link>  
    <img 
    className="images-in-carousel-in-wow-holiday-2"
     src="IMAGES/city-images/holiday-cards/places/atlantis.jpg"></img>
      <div className="centered-text-cards-locations-holidaycards-2">Atlantis the Palm</div>
      <div className="new-cards-wow-holiday-places">
        <i className="fa fa-map-marker-alt holiday-cards-hot-places-icons" aria-hidden="true"></i>
        <div className="country-name-holiday-cards-places">Dubai, UAE</div>
      </div>
      
      </Link>
      
      </div>
</Col>



  
</Carousel>


















       

  

<Container>
<div className="text-box-inside-background-image-of-new-design carousel-in-mobile-view-wow-holiday">
  <h1 className="heading-primary">
    <span className="heading-primary-main">
    WOW PREFERRED

    </span>
    <span className="heading-primary-sub">
    Activities from trusted partners, recommended by fellow travelers

    </span>
  </h1>
 

  </div>

  </Container>
        
        


        


       

        



<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={10}
  keyBoardControl={true}
  customTransition="all 2s linear"
  transitionDuration={2000}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  pause={true}
  className="carousel-in-mobile-view-wow-holiday"

>
  
<Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card1.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>




    <Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card2.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>



    <Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card3.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>

    <Col xs={12} sm={12} lg={12} md={12}>
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card4.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>



    <Col xs={12} sm={12} lg={12} md={12}>
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card5.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>



<Col xs={12} sm={12} lg={12} md={12}>
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card6.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>




<Col xs={12} sm={12} lg={12} md={12}  >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card7.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>



<Col xs={12} sm={12} lg={12} md={12}  >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card8.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>




<Col xs={12} sm={12} lg={12} md={12} >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card8.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>



<Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card9.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>








    <Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card10.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>




    <Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card11.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>





    <Col xs={12} sm={12} lg={12} md={12}  >
    <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card12.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
    </Col>





    <Col xs={12} sm={12} lg={12} md={12}  >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card13.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>



<Col xs={12} sm={12} lg={12} md={12}  >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card14.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>





<Col xs={12} sm={12} lg={12} md={12}  >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card15.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>




<Col xs={12} sm={12} lg={12} md={12}  >
<div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/city-images/slider-card-images/card16.png" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                {/* <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/> */}
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div>
</Col>



  
</Carousel>















 















</Container>





</div>



    );
  }
}
export default News;












