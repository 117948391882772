import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory
} from "react-router-dom";
import AOS from "aos";
AOS.init();

 class inspirationalcard1 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }


  featuredmaal = () => {
    document.getElementById("featured-cards").style.display = 'block';
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("beach").style.display = "none";
    document.getElementById("safari").style.display = "none";
    document.getElementById("nature").style.display = "none";
    document.getElementById("city").style.display = "none";
    document.getElementById("family").style.display = "none";
    

  };


  backpacking = () => {
   
    document.getElementById("featured-cards").style.display = "none";
    document.getElementById("backpacking").style.display = "fadeIn";
    document.getElementById("backpacking").style.display = "block";
    document.getElementById("beach").style.display = "none";
    document.getElementById("family").style.display = "none";
    document.getElementById("city").style.display = "none";
    document.getElementById("nature").style.display = "none";
    document.getElementById("safari").style.display = "none";
  };


  beach = () => {
   
    document.getElementById("featured-cards").style.display = "none";
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("beach").style.display = "fadeIn";
    document.getElementById("beach").style.display = "block";
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("family").style.display = "none";
    document.getElementById("city").style.display = "none";
    document.getElementById("nature").style.display = "none";
  };





  safari = () => {
    document.getElementById("safari").style.display = "block";
    document.getElementById("featured-cards").style.display = "none";
    document.getElementById("beach").style.display = "none";
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("family").style.display = "none";
    document.getElementById("city").style.display = "none";
    document.getElementById("nature").style.display = "none";
  };

  
  nature = () => {
    document.getElementById("nature").style.display = "block";
    document.getElementById("featured-cards").style.display = "none";
    document.getElementById("beach").style.display = "none";
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("safari").style.display = "none";
    document.getElementById("family").style.display = "none";
    document.getElementById("city").style.display = "none";
  };
  

  city = () => {
    document.getElementById("city").style.display = "block";
    document.getElementById("featured-cards").style.display = "none";
    document.getElementById("beach").style.display = "none";
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("safari").style.display = "none";
    document.getElementById("nature").style.display = "none";
    document.getElementById("family").style.display = "none";
  };
  

  family = () => {
    document.getElementById("family").style.display = "block";
    document.getElementById("featured-cards").style.display = "none";
    document.getElementById("beach").style.display = "none";
    document.getElementById("backpacking").style.display = "none";
    document.getElementById("safari").style.display = "none";
    document.getElementById("nature").style.display = "none";
    document.getElementById("city").style.display = "none";
  };




  

  render() {
    return (
      <div>
        <br />

        {/* <div 
            class="bg-displaced bg-image background-image-inholiday-inspiration-cols">

        <Container>


          <div className="background-image-in-cols-inspiration">

          
            <Row>
           

              <Col lg={6} md={6}>
             
              <div className="class-around-text-inpiration-cols">
  <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
      <div style={{padding:'0px 15px'}}>
    <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
    Hiking Adventures in Japan now bay

    </span>
    <span className="heading-primary-sub-inspiration-inside-cols">
    Inside Japan Tours organise small group tours & self-guided trips which include 
     some of Japan’s best multi-day hikes. The sacred pilgrimage trails of the Kumano
      Kodo and the ancient Nakasendo Way samurai route are just some of the epic
       hikes included on this tour.

    </span></div>
  </div>
 
  </div>
  
  
              </Col>





              <Col lg={6} md={6}>

              <div className="padding-in-images">
                  <img className="img-fluid image-in-inspirations-cols " src="IMAGES/city-images/holiday-inspiration/locations/japan.jpg">
                  
                  </img>
              </div>
              
              
              </Col>


              
            </Row>

            
            


            

           



          </div>
        </Container>
        </div> */}


        <ScrollAnimation animateIn="fadeIn" style={{marginTop:'20px'}} >
          <div>
            <center>
            <Button 
            variant="outline-dark"
            className="button-featured-holiday-inspiration"
            onClick={this.featuredmaal}
            style={{height:'45px'}}

            >
              Featured

            </Button>
            </center>
          </div>
        </ScrollAnimation>

        <ScrollAnimation 
        animateIn="fadeIn"
        style={{marginBottom:'20px'}}
        
        >



          <div style={{ textAlign: "center" }}>




            <Button
              variant="outline-dark"
              onClick={this.backpacking}
              style={{ borderRadius: "5px" }}
            >
              BackPacking
            </Button>


            <Button
              variant="outline-dark"
              onClick={this.beach}
              style={{ borderRadius: "5px" }}
            >
              Beach
            </Button>

            <Button
              variant="outline-dark"
              onClick={this.safari}
              style={{ borderRadius: "5px",
            borderBlockColor:'#f87370' }}
            >
              Safari
            </Button>

          




          </div>


          <div style={{textAlign:'center'}}>

          <Button
              variant="outline-dark"
              onClick={this.nature}
              style={{ borderRadius: "5px" }}
            >
              Nature and Scenery
            </Button>

            <Button
              variant="outline-dark"
              onClick={this.city}
              style={{ borderRadius: "5px" }}
            >
              City- Shop & Indulge
            </Button>

          

            <Button
              variant="outline-dark"
              onClick={this.family}
              style={{ borderRadius: "5px" }}
            >
              Theme Park/ Family
            </Button>

          </div>





        </ScrollAnimation>












        <Container id="featured-cards" >
          <Row>
            <div
              data-aos="zoom-out-up"
              className="col-md-6 col-sm-6 col-xs-6 text-center ha-featured"
              id="card1"
            >
              <Link
                
                class="featureddestinationshortname card-holiday-inspiration-aukland"
                aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                data-label="Auckland"
              >
                <div class="card-dest" aria-hidden="true">
                  <div class="card-dest-image">
                    <div class="card-tag">
                      <span class="text">Featured</span>
                    </div>
                    <img
                      class="deffer-image"
                      data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                      src="IMAGES/city-images/holiday-inspiration/cards-location/featured/Auckland.jpg"
                      alt="City of Auckland"
                    />
                  </div>
                  <div class="card-dest-lable">New Zealand</div>
                  <div class="card-dest-heading">
                    <h3>Auckland</h3>
                  </div>
                  <div class="card-dest-info">
                    A paradise for those who love the great outdoors, Auckland’s
                    unique geography makes it a must-visit for any traveller.
                  </div>
                </div>
              </Link>
            </div>

            <div
              data-aos="zoom-out-up"
              className="col-md-6 col-sm-6 col-xs-6 text-center ha-featured"
              id="card2"
            >
              <a
                 
                class="featureddestinationshortname card-holiday-inspiration-aukland"
                aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                data-label="Auckland"
              >
                <div class="card-dest" aria-hidden="true">
                  <div class="card-dest-image">
                    <div class="card-tag">
                      <span class="text">Featured</span>
                    </div>
                    <img
                      class="deffer-image"
                      data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                      src="IMAGES/city-images/holiday-inspiration/cards-location/featured/cape-town.jpg"
                      alt="City of Auckland"
                    />
                  </div>
                  <div class="card-dest-lable">South Africa</div>
                  <div class="card-dest-heading">
                    <h3>Cape Town</h3>
                  </div>
                  <div class="card-dest-info">
                    From awe-inspiring beaches to five-star hotels, and gourmet
                    cuisines to thrilling adventures, a visit to Cape Town won’t
                    disappoint.
                  </div>
                </div>
              </a>
            </div>
          </Row>

          <Row>
            <div
              data-aos="zoom-out-up"
              className="col-md-6 col-sm-6 col-xs-6 text-center ha-featured"
              id="card3"
            >
              <a
                 
                class="featureddestinationshortname card-holiday-inspiration-aukland"
                aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                data-label="Auckland"
              >
                <div class="card-dest" aria-hidden="true">
                  <div class="card-dest-image">
                    <div class="card-tag">
                      <span class="text">Featured</span>
                    </div>
                    <img
                      class="deffer-image"
                      data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                      src="IMAGES/city-images/holiday-inspiration/cards-location/featured/Colombo.jpg"
                      alt="City of Auckland"
                    />
                  </div>
                  <div class="card-dest-lable">Sri Lanka</div>
                  <div class="card-dest-heading">
                    <h3>Colombo</h3>
                  </div>
                  <div class="card-dest-info">
                    There’s a lot to love about Sri Lanka’s capital city,
                    Colombo.
                  </div>
                </div>
              </a>
            </div>

            <div
              data-aos="zoom-out-up"
              className="col-md-6 col-sm-6 col-xs-6 text-center ha-featured"
              id="card4"
            >
              <a
                 
                class="featureddestinationshortname card-holiday-inspiration-aukland"
                aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                data-label="Auckland"
              >
                <div class="card-dest" aria-hidden="true">
                  <div class="card-dest-image">
                    <div class="card-tag">
                      <span class="text">Featured</span>
                    </div>
                    <img
                      class="deffer-image"
                      data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                      src="IMAGES/city-images/holiday-inspiration/cards-location/featured/kuala.jpg"
                      alt="City of Auckland"
                    />
                  </div>
                  <div class="card-dest-lable">MALAYSIA</div>
                  <div class="card-dest-heading">
                    <h3>Kuala Lumpur</h3>
                  </div>
                  <div class="card-dest-info">
                    Kuala Lumpur isn't only the largest city in Malaysia, but
                    one of the most exciting in the world.
                  </div>
                </div>
              </a>
            </div>
          </Row>

          <Row>
            <div
              data-aos="zoom-out-up"
              className="col-md-6 col-sm-6 col-xs-6 text-center ha-featured"
              id="card5"
            >
              <a
                 
                class="featureddestinationshortname card-holiday-inspiration-aukland"
                aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                data-label="Auckland"
              >
                <div class="card-dest" aria-hidden="true">
                  <div class="card-dest-image">
                    <div class="card-tag">
                      <span class="text">Featured</span>
                    </div>
                    <img
                      class="deffer-image"
                      data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                      src="IMAGES/city-images/holiday-inspiration/cards-location/featured/mala.jpg"
                      alt="City of Auckland"
                    />
                  </div>
                  <div class="card-dest-lable">Maldives</div>
                  <div class="card-dest-heading">
                    <h3>Male</h3>
                  </div>
                  <div class="card-dest-info">
                    Whilst Malé is just a speedboat ride away from many
                    picturesque islands, don’t ignore the opportunity to explore
                    this tiny, modern metropolis.
                  </div>
                </div>
              </a>
            </div>

            <div
              data-aos="zoom-out-up"
              className="col-md-6 col-sm-6 col-xs-6 text-center ha-featured "
              id="card6"
            >
              <a
                 
                class="featureddestinationshortname card-holiday-inspiration-aukland"
                aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                data-label="Auckland"
              >
                <div class="card-dest" aria-hidden="true">
                  <div class="card-dest-image">
                    <div class="card-tag">
                      <span class="text">Featured</span>
                    </div>
                    <img
                      class="deffer-image"
                      data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                      src="IMAGES/city-images/holiday-inspiration/cards-location/featured/seychelles.jpg"
                      alt="City of Auckland"
                    />
                  </div>
                  <div class="card-dest-lable">SEYCHELLES</div>
                  <div class="card-dest-heading">
                    <h3>Seychelles</h3>
                  </div>
                  <div class="card-dest-info">
                    Mahé is a true slice of paradise. Visit to explore verdant
                    mountains, bustling markets and – perhaps most famously –
                    postcard-perfect natural beaches.
                  </div>
                </div>
              </a>
            </div>
          </Row>
        </Container>

































































{/* cards of backpacking button */}



        <Container className="faisla-kr-be" id="backpacking">
          <div
          
            >
            <Row>
              

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card2"
                data-aos="zoom-out-up"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="THAILAND, Bangkok. Travellers on flights to Bangkok can expect a city of contrasts;
                  where skyscrapers and five-star hotels tower over ancient temples."
                  data-label="THAILAND"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/bangkok.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">THAILAND</div>
                    <div class="card-dest-heading">
                      <h3>Bangkok</h3>
                    </div>
                    <div class="card-dest-info">
                      Travellers on flights to Bangkok can expect a city of contrasts;
                      where skyscrapers and five-star hotels tower over ancient temples.
                    </div>
                  </div>
                </a>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card1"
                data-aos="zoom-out-up"
              >
                <a
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/featured/Auckland.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">New Zealand</div>
                    <div class="card-dest-heading">
                      <h3>Auckland</h3>
                    </div>
                    <div class="card-dest-info">
                      A paradise for those who love the great outdoors,
                      Auckland’s unique geography makes it a must-visit for any
                      traveller.
                    </div>
                  </div>
                </a>
              </div>









            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card3"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        // data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/barca.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">SPAIN</div>
                    <div class="card-dest-heading">
                      <h3>Barcelona</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Barcelona are the perfect
 solution if you can’t choose between a beach holiday and a European city break.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card4"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/cairo.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">EGYPT</div>
                    <div class="card-dest-heading">
                      <h3>Cairo </h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Cairo exhibit an elegant fusion of old and new:
 historical landmarks and modern architecture.
                    </div>
                  </div>
                </a>
              </div>
            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/dublin.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">IRELAND</div>
                    <div class="card-dest-heading">
                      <h3>Dublin</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Dublin win over travellers seeking a city 
that really embraces its history. Even the restaurants date back centuries.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/frankfurt.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">GERMANY</div>
                    <div class="card-dest-heading">
                      <h3>Frankfurt</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Frankfurt won't leave you at a loss for
 holiday activities. Visit this city for culture, commerce and cuisine.
                    </div>
                  </div>
                </a>
              </div>
            </Row>






            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/istanbol.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Turkey</div>
                    <div class="card-dest-heading">
                      <h3>Istanbul</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    Book flights to Istanbul to experience a culturally rich 
                    destination – where age-old sites sit amidst bustling bazars and high-end restaurants.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/washington.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED STATES</div>
                    <div class="card-dest-heading">
                      <h3>Washington</h3>
                    </div>
                    <div class="card-dest-info">
                    
   

Flights to Washington DC let you delve into
 one of America’s most important historic destinations – but don’t bypass the hip areas.
                    </div>
                  </div>
                </a>
              </div>
            </Row>








            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/venice.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">ITALY</div>
                    <div class="card-dest-heading">
                      <h3>Venice</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Venice’s palazzo-lined canals, romantic bridges,
 maze-like streets and, of course, singing gondoliers make your getaway truly magical.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/backpacking/rome.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">ITALY</div>
                    <div class="card-dest-heading">
                      <h3>Rome</h3>
                    </div>
                    <div class="card-dest-info">
           
Flights to Rome will whet your appetite for la dolce vita – whether you go for the paintings or the pizza.
                    </div>
                  </div>
                </a>
              </div>
            </Row>















          </div>
        </Container>
























































{/* cards of beach button */}



<Container  id="beach" className="faisla-kr-be">
          <div>

            <Row>
              

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card2"
                data-aos="zoom-out-up"
              >
                <Link
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="THAILAND, Bangkok. Travellers on flights to Bangkok can expect a city of contrasts;
                  where skyscrapers and five-star hotels tower over ancient temples."
                  data-label="THAILAND"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location=""
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/australia.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">AUSTRALIA</div>
                    <div class="card-dest-heading">
                      <h3>Adelaide</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Adelaide unlock a packed cultural calendar and a
 contagiously laid-back approach to life in one of Australia’s most liveable cities.
                    </div>
                  </div>
                </Link>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card1"
                data-aos="zoom-out-up"
              >
                <a
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/california.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">USA</div>
                    <div class="card-dest-heading">
                      <h3>California</h3>
                    </div>
                    <div class="card-dest-info">
                      A paradise for those who love the great outdoors,
                      California's unique geography makes it a must-visit for any
                      traveller.
                    </div>
                  </div>
                </a>
              </div>









            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card3"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        // data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/bali.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">INDONESIA</div>
                    <div class="card-dest-heading">
                      <h3>Bali</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Bali are tickets to an island paradise.
 Pristine beaches, famous paddy terraces and charming towns await your arrival.
                    </div>
                  </div>
                </a>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card4"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/chennai.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">India</div>
                    <div class="card-dest-heading">
                      <h3>Chennai </h3>
                    </div>
                    <div class="card-dest-info">
                    

                    Flights to Chennai
                     land you in one of India's most touristy cities, where bold cuisine is the order of the day.
                    </div>
                  </div>
                </a>
              </div>
            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/featured/mala.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Maldives</div>
                    <div class="card-dest-heading">
                      <h3>Male</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    Whilst Malé is just a speedboat ride away from many
                    picturesque islands, don’t ignore the opportunity to explore
                    this tiny, modern metropolis.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/dar.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">TANZANIA</div>
                    <div class="card-dest-heading">
                      <h3>Dar Es Salaam</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Dar Es Salam transport you
 to stunning landscapes, bustling markets and pristine beaches in Tanzania’s ‘House of Peace’.
                    </div>
                  </div>
                </a>
              </div>
            </Row>






            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/cyprus.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">CYPRUS</div>
                    <div class="card-dest-heading">
                      <h3>Larnaca</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Larnaca reveal fascinating historical
 attractions for those willing to explore beyond the sandy beaches and cheerful taverns.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/beach/greece.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Greece</div>
                    <div class="card-dest-heading">
                      <h3>Navagio</h3>
                    </div>
                    <div class="card-dest-info">
                    
   

Flights to Washington DC let you delve into
 one of America’s most important historic destinations – but don’t bypass the hip areas.
                    </div>
                  </div>
                </a>
              </div>
            </Row>








           







          </div>
        </Container>























































        {/* cards of safari button */}



<Container  id="safari" className="faisla-kr-be" >
          <div>

            <Row>
              

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card2"
                data-aos="zoom-out-up"
              >
                <Link
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="THAILAND, Bangkok. Travellers on flights to Bangkok can expect a city of contrasts;
                  where skyscrapers and five-star hotels tower over ancient temples."
                  data-label="THAILAND"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location=""
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/accra.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">GHANA</div>
                    <div class="card-dest-heading">
                      <h3>Accra</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Accra are the best way to visit Ghana. The capital has plenty to offer, from history to beaches.
                    </div>
                  </div>
                </Link>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card1"
                data-aos="zoom-out-up"
              >
                <a
                 
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/dakar.jpg"                                        
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">SENEGAL</div>
                    <div class="card-dest-heading">
                      <h3>Dakar</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Dakar deliver you to Senegal’s capital 
city – one of Africa’s most vibrant and varied destinations.
                    </div>
                  </div>
                </a>
              </div>









            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card3"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        // data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/uganda.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UGANDA</div>
                    <div class="card-dest-heading">
                      <h3>Entebbe</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

From Entebbe, you can easily get to Kampala, the capital of Uganda.
                    </div>
                  </div>
                </a>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card4"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/Johannesburg.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">SOUTH AFRICA</div>
                    <div class="card-dest-heading">
                      <h3>Johannesburg </h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Choose flights to Johannesburg if you’re looking for 
exceptional restaurants, world-class museums and buzzing nightlife venues.
                    </div>
                  </div>
                </a>
              </div>
            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/Nairobi.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">KENYA</div>
                    <div class="card-dest-heading">
                      <h3>Nairobi</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Most people take flights to Nairobi to see Kenya’s savannah, but city life here is just as intriguing.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/Lusaka.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">ZAMBIA</div>
                    <div class="card-dest-heading">
                      <h3>Lusaka</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Lusaka in Zambia will let you experience a modern African city poised for greatness.
                    </div>
                  </div>
                </a>
              </div>
            </Row>






            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/Harare.jpg"  
                        alt="City of Harare"
                      />
                    </div>
                    <div class="card-dest-lable">ZIMBABWE</div>
                    <div class="card-dest-heading">
                      <h3>Harare</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Book flights to Harare in Zimbabwe for a taste of
 modern Africa, stunning architecture and amazing wildlife.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/safari/Kruger.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Greece</div>
                    <div class="card-dest-heading">
                      <h3>Kruger National Park</h3>
                    </div>
                    <div class="card-dest-info">
                    
   

Flights to Washington DC let you delve into
 one of America’s most important historic destinations – but don’t bypass the hip areas.
                    </div>
                  </div>
                </a>
              </div>
            </Row>








           







          </div>
        </Container>






















































{/* nature cards */}

        <Container  id="nature" className="faisla-kr-be" >
          <div>

            <Row>
              

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card2"
                data-aos="zoom-out-up"
              >
                <Link
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="THAILAND, Bangkok. Travellers on flights to Bangkok can expect a city of contrasts;
                  where skyscrapers and five-star hotels tower over ancient temples."
                  data-label="THAILAND"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location=""
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Beijing.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">CHINA</div>
                    <div class="card-dest-heading">
                      <h3>Beijing</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    
                    

Flights to Beijing will leave you 
wanting more of China's cuisine, history and modernity. There’s almost too much to take in.
                    </div>
                  </div>
                </Link>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card1"
                data-aos="zoom-out-up"
              >
                <a
                  
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Brisbane.jpg"                                        
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">AUSTRALIA</div>
                    <div class="card-dest-heading">
                      <h3>Brisbane</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Brisbane, one of Australia’s hippest destinations,
 let you delve into a thriving arts and nightlife scene.
                    </div>
                  </div>
                </a>
              </div>









            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card3"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        // data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Brussels.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">BELGIUM</div>
                    <div class="card-dest-heading">
                      <h3>Brussels</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Brussels transport you to a city that’s
 heaving with distinguished architecture, legendary art museums and gorgeous eateries.
                    </div>
                  </div>
                </a>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card4"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Buenos.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">ARGENTINA</div>
                    <div class="card-dest-heading">
                      <h3>Buenos Aires </h3>
                    </div>
                    <div class="card-dest-info">
                    

                    
                    

Pack your dancing shoes for flights to Buenos Aires.
 History buffs, tango aficionados, art connoisseurs and architecture lovers won’t be disappointed.
                    </div>
                  </div>
                </a>
              </div>
            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Christchurch.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable"> NEW ZEALAND</div>
                    <div class="card-dest-heading">
                      <h3>Christchurch</h3>
                    </div>
                    <div class="card-dest-info">
                   

Flights to Christchurch are the gateway to New Zealand’s 
breath-taking South Island. Stop and smell the flowers in the botanical gardens.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/VIETNAM.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">VIETNAM</div>
                    <div class="card-dest-heading">
                      <h3>Hanoi</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Hanoi throw you into a
 chaotic city with quaint café culture, where French influence is visible at every turn.
                    </div>
                  </div>
                </a>
              </div>
            </Row>






            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Osaka.jpg"  
                        alt="City of Harare"
                      />
                    </div>
                    <div class="card-dest-lable">JAPAN</div>
                    <div class="card-dest-heading">
                      <h3>Osaka</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Osaka will keep the most restless travellers entertained,
 with luxury hotels, restaurants and countless attractions to choose from.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Banff.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Canada</div>
                    <div class="card-dest-heading">
                      <h3>Banff National Park</h3>
                    </div>
                    <div class="card-dest-info">
                    
   

                    Banff National Park is Canada's oldest national park and was established
                     in 1885. Located in Rocky Mountains of Alberta, 110–180 kilometres west of Calgary.
                    </div>
                  </div>
                </a>
              </div>
            </Row>



            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/Hạ-Long.jpg"  
                        alt="City of Vietnam"
                      />
                    </div>
                    <div class="card-dest-lable">Vietnam</div>
                    <div class="card-dest-heading">
                      <h3>Hạ Long Bay</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    Hạ Long Bay, in northeast Vietnam, is known for its emerald waters 
                    and thousands of towering limestone islands topped by rainforests.
                     Junk boat tours and sea kayak expeditions take visitors past islands named for their shapes
         
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/nature/ankor.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Cambodia</div>
                    <div class="card-dest-heading">
                      <h3>Angkor Wat</h3>
                    </div>
                    <div class="card-dest-info">
                    It is worth visiting if you're already traveling in Cambodia. The crowds make it a bit unpleasant at 
                    times, but there's nowhere to the same scale as Angkor, and it's a World Heritage site for a reason.
                    </div>
                  </div>
                </a>
              </div>
            </Row>








           







          </div>
        </Container>

































          {/* cards of city-shop button */}



<Container  id="city" className="faisla-kr-be" >
          <div>

            <Row>
              

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card2"
                data-aos="zoom-out-up"
              >
                <Link
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="THAILAND, Bangkok. Travellers on flights to Bangkok can expect a city of contrasts;
                  where skyscrapers and five-star hotels tower over ancient temples."
                  data-label="THAILAND"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location=""
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/Beirut.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">LEBANON</div>
                    <div class="card-dest-heading">
                      <h3>Beirut</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Beirut mean you can
 spend the morning skiing and then relax on a beautiful beach in the afternoon sun.
                    </div>
                  </div>
                </Link>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card1"
                data-aos="zoom-out-up"
              >
                <a
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/boston.jpg"                                        
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED STATES</div>
                    <div class="card-dest-heading">
                      <h3>Boston</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Book flights to Boston and immerse yourself in 
a potent mix of American history, working-class culture and cutting-edge chic.
                    </div>
                  </div>
                </a>
              </div>









            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card3"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        // data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/Dallas.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED STATES</div>
                    <div class="card-dest-heading">
                      <h3>Dallas</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Dallas-Fort Worth tell a tale of two cities.
 This bustling metropolis shows off cowboy culture, southern hospitality and dazzling skyscrapers.
                    </div>
                  </div>
                </a>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card4"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/Geneva.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">SWITZERLAND</div>
                    <div class="card-dest-heading">
                      <h3>Geneva </h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Geneva attract tourists
 who want to explore a diplomatic powerhouse of a city and its picturesque surroundings.
                    </div>
                  </div>
                </a>
              </div>
            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/london.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED KINGDOM</div>
                    <div class="card-dest-heading">
                      <h3>London</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

                    Notable alternatives include the British Museum,
                     The National Gallery, The Tate, The
                     Science Museum, The Natural History Museum and more! London is one the cultural hotspots of the world
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/Manchester.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED KINGDOM</div>
                    <div class="card-dest-heading">
                      <h3>Manchester</h3>
                    </div>
                    <div class="card-dest-info">
                    

                    

Flights to Manchester are tickets
 to the British music scene. Don’t miss sampling culinary delights in the city’s hip restaurants.
                    </div>
                  </div>
                </a>
              </div>
            </Row>






            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/Madrid.jpg"  
                        alt="City of Harare"
                      />
                    </div>
                    <div class="card-dest-lable">SPAIN</div>
                    <div class="card-dest-heading">
                      <h3>Madrid</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    
                    

If you’re catching flights to Madrid,
 bring your appetite – it’s filled with art, architecture, atmosphere and amazing food.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/miami.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">Florida</div>
                    <div class="card-dest-heading">
                      <h3>Miami</h3>
                    </div>
                    <div class="card-dest-info">
                    
   

South Beach, Miami is rightly famous for its Art Deco architecture.
 Stroll the streets and feast on the creamy yellow, pink, blue, and purple walls around you
                    </div>
                  </div>
                </a>
              </div>
            </Row>





            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/malta.jpg"  
                        alt="City of Harare"
                      />
                    </div>
                    <div class="card-dest-lable">MALTA</div>
                    <div class="card-dest-heading">
                      <h3>Malta</h3>
                    </div>
                    <div class="card-dest-info">
                    

Choose flights to Malta if you’re
 looking for a diverse travel destination boasting both history and amazing natural beauty.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/Muscat.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">OMAN</div>
                    <div class="card-dest-heading">
                      <h3>Muscat</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Muscat satisfy 
cravings for beaches and high-end dining. Make your way to the port for a culture fix.
                    </div>
                  </div>
                </a>
              </div>
            </Row>





          </div>
        </Container>



































        {/* cards of theme-park/family button */}



<Container  id="family"  className="faisla-kr-be" >
          <div>

            <Row>
              

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card2"
                data-aos="zoom-out-up"
              >
                <Link
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="THAILAND, Bangkok. Travellers on flights to Bangkok can expect a city of contrasts;
                  where skyscrapers and five-star hotels tower over ancient temples."
                  data-label="THAILAND"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location=""
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/MEXICO.jpg"
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">MEXICO</div>
                    <div class="card-dest-heading">
                      <h3>Mexico City</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Mexico City take 
you on an adventure for the senses, with sights, sounds and tastes, both familiar and new.
                    </div>
                  </div>
                </Link>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card1"
                data-aos="zoom-out-up"
              >
                <a
                  
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/Orlando.jpg"                                        
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED STATES</div>
                    <div class="card-dest-heading">
                      <h3>Orlando</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Orlando are your
 ticket to some of the world’s most iconic theme parks, but they're not the only attraction.
                    </div>
                  </div>
                </a>
              </div>









            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card3"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        // data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/Dallas.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED STATES</div>
                    <div class="card-dest-heading">
                      <h3>Dallas</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Dallas-Fort Worth tell a tale of two cities.
 This bustling metropolis shows off cowboy culture, southern hospitality and dazzling skyscrapers.
                    </div>
                  </div>
                </a>
              </div>





              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card4"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/Singapore.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">SINGAPORE</div>
                    <div class="card-dest-heading">
                      <h3>Singapore </h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Having reinvented itself as a buzzing metropolis,
 get ready for Singapore’s stunning architecture, natural beauty and countless culinary offerings.
                    </div>
                  </div>
                </a>
              </div>
            </Row>

            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/city/london.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">UNITED KINGDOM</div>
                    <div class="card-dest-heading">
                      <h3>London</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

                    Notable alternatives include the British Museum,
                     The National Gallery, The Tate, The
                     Science Museum, The Natural History Museum and more! London is one the cultural hotspots of the world
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/Amman.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">JORDAN</div>
                    <div class="card-dest-heading">
                      <h3>Amman</h3>
                    </div>
                    <div class="card-dest-info">
                    

Flights to Amman transport you
 to one of the Middle East’s friendliest cities. It offers both historical sites and high-end hotels.
                    </div>
                  </div>
                </a>
              </div>
            </Row>






            <Row>
              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha"
                id="card5"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/malta.jpg"  
                        alt="City of Harare"
                      />
                    </div>
                    <div class="card-dest-lable">MALTA</div>
                    <div class="card-dest-heading">
                      <h3>Malta</h3>
                    </div>
                    <div class="card-dest-info">
                    

Choose flights to Malta if you’re
 looking for a diverse travel destination boasting both history and amazing natural beauty.
                    </div>
                  </div>
                </a>
              </div>

              <div
                className="col-md-6 col-sm-6 col-xs-6 text-center ha "
                id="card6"
              >
                <a
                   
                  class="featureddestinationshortname card-holiday-inspiration-aukland"
                  aria-label="New Zealand, Auckland. A paradise for those who love the great outdoors, Auckland’s unique geography makes it a must-visit for any traveller."
                  data-label="Auckland"
                >
                  <div class="card-dest" aria-hidden="true">
                    <div class="card-dest-image">
                      {/* <div class="card-tag">
                        <span class="text">Featured</span>
                      </div> */}
                      <img
                        class="deffer-image"
                        data-image-location="https://c.ekstatic.net/shared/images/destination/v1/airports/AKL/810x270.jpg"
                        src="IMAGES/city-images/holiday-inspiration/cards-location/family/Muscat.jpg"  
                        alt="City of Auckland"
                      />
                    </div>
                    <div class="card-dest-lable">OMAN</div>
                    <div class="card-dest-heading">
                      <h3>Muscat</h3>
                    </div>
                    <div class="card-dest-info">
                    
                    

Flights to Muscat satisfy 
cravings for beaches and high-end dining. Make your way to the port for a culture fix.
                    </div>
                  </div>
                </a>
              </div>
            </Row>




          </div>
        </Container>





























      </div>
    );
  }
}
export default inspirationalcard1;
