import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Figure,
  Col,
} from "react-bootstrap";
import { Rate, Descriptions } from "antd";
import "antd/dist/antd.css";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../HolidayAirportTaxi/13PassengersTaxi.scss";

import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

class Airport_taxi13passengers extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      showHide13passengerscardsearch1: false,
      showHide13passengerscardsearch2: false,
      showHide13passengerscardsearch3: false,
      HideTaxiSectionCars: false,

      cars_1: [
        {
          name: "Audi",
          country: "Germany",
          currency: "Dirham",
          price: "128.67$",
          img:
            "https://www.elegantthemes.com/layouts/wp-content/uploads/2018/06/car-dealer-33.jpg",
          time: "2h 30min",
        },
        {
          name: "BMW",
          country: "Germany",
          currency: "Dirham",
          price: "435.67$",
          img:
            "https://www.elegantthemes.com/layouts/wp-content/uploads/2018/06/car-dealer-32.jpg",
          time: "2h 30min",
        },
        {
          name: "Chevrolet",
          country: "USA",
          currency: "Dirham",
          price: "234.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/1-Honda.png",
          time: "2h 30min",
        },
        {
          name: "Citroen",
          country: "France",
          currency: "Dirham",
          price: "423.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/6-Mercedes-(V-Class).png",
          time: "2h 30min",
        },
        {
          name: "Hyundai",
          country: "South Korea",
          currency: "Dirham",
          price: "234.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/3-BMW-(7-SERIES).png",
          time: "2h 30min",
        },
        {
          name: "Mercedes-Benz",
          country: "Germany",
          currency: "Dirham",
          price: "532.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/5 Maserati Ghibli.png",
          time: "2h 30min",
        },
      ],

      cars_2: [
        {
          name: "Renault",
          country: "France",
          currency: "Dirham",
          price: "123.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/8-Mercedes-(E-CLASS).png",
          time: "2h 30min",
        },
        {
          name: "Seat",
          country: "Spain",
          currency: "Dirham",
          price: "321.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/7-Mercedes-S-CLASS.png",
          time: "2h 30min",
        },
        {
          name: "Dodge",
          country: "USA",
          currency: "Dirham",
          price: "231.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/5 Maserati Ghibli.png",
          time: "2h 30min",
        },
        {
          name: "BMW",
          country: "Germany",
          currency: "Dirham",
          price: "534.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/4-Jaguar-(XJ.png",
          time: "2h 30min",
        },
        {
          name: "Tesla",
          country: "USA",
          currency: "Dirham",
          price: "234.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/2-Audi-(A8).png",
          time: "2h 30min",
        },
      ],

      cars_3: [
        {
          name: "Volkswagen",
          country: "Germany",
          currency: "Dirham",
          price: "422.67$",
          img:
          "IMAGES/city-images/Airport-taxi/wow-car-categories/5-WOW-Long-Drive/1-Cadillac-(ESCALADE).png",
          time: "2h 30min",
        },
        {
          name: "Hyundai",
          country: "South Korea",
          currency: "Dirham",
          price: "333.67$",
          img:
          "IMAGES/city-images/Airport-taxi/wow-car-categories/5-WOW-Long-Drive/4-BMW-(ALL-SERIES).png",
          time: "2h 30min",
        },
        {
          name: "Jaguar",
          country: "United Kingdom",
          currency: "Dirham",
          price: "244.67$",
          img:
          "IMAGES/city-images/Airport-taxi/wow-car-categories/5-WOW-Long-Drive/5-Mercedes-(S-CLASS).png",
          time: "2h 30min",
        },
        {
          name: "GMC",
          country: "USA",
          currency: "Dirham",
          price: "423.67$",
          img:
          "IMAGES/city-images/Airport-taxi/wow-car-categories/5-WOW-Long-Drive/6-Lexus-(ES-350-L460).png",
          time: "2h 30min",
        },
        {
          name: "Bentley",
          country: "United Kingdom",
          currency: "Dirham",
          price: "128.67$",
          img:
          "IMAGES/city-images/Airport-taxi/wow-car-categories/5-WOW-Long-Drive/7-Cadillac(CTS-CT6-XTS).png",
          time: "2h 30min",
        },
      ],
      numberOfitemsShown: 1,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  hideComponent(name) {
    switch (name) {
      case "showHide13passengerscardsearch1":
        this.setState({
          // showHide13passengerscardsearch1: !this.state.showHide13passengerscardsearch1,
          showHide13passengerscardsearch1: true,
          showHide13passengerscardsearch2: false,
          showHide13passengerscardsearch3: false,
        });
        break;

      case "showHide13passengerscardsearch2":
        this.setState({
          showHide13passengerscardsearch1: false,
          showHide13passengerscardsearch2: true,
          showHide13passengerscardsearch3: false,
        });
        break;

      case "showHide13passengerscardsearch3":
        this.setState({
          showHide13passengerscardsearch1: false,
          showHide13passengerscardsearch2: false,
          showHide13passengerscardsearch3: true,
        });
        break;
      case "HideTaxiSectionCars":
        this.setState({
          showHide13passengerscardsearch1: false,
          showHide13passengerscardsearch2: false,
          showHide13passengerscardsearch3: false,
        });
      default:
      // null;
    }
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const {
      showHide13passengers,
      showHide13passengerscardsearch1,
      showHide13passengerscardsearch2,
      showHide13passengerscardsearch3,
    } = this.state;
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>
        <div>
          <Container>
            <div>
              <div class="seo_link_content">
                <Row>
                  <Col
                    lg={6}
                    xl={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="cols-section-image-thumb-section-airporttaxi"
                  >
                    <div className="cols-section-bg-color-section-airporttaxi">
                      <h3>Standard</h3>
                      <h5>Volkswagen Jetta or similar</h5>

                      <div>
                        <i
                          className="fa fa-user"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        <span>1 passenger</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-eye"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        <span>WOW For Family</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-user"
                          style={{ paddingRight: "10px", color: "#f87370" }}
                        ></i>
                        <span style={{ color: "#f87370" }}>asasdd</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-check"
                          style={{ paddingRight: "10px", color: "green" }}
                        ></i>
                        <span style={{ color: "green" }}>Meet & Greet</span>
                      </div>

                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            this.hideComponent(
                              "showHide13passengerscardsearch1"
                            )
                          }
                          className={
                            "" +
                            (this.state.showHide13passengerscardsearch1
                              ? "selected-button"
                              : "")
                          }
                        >
                          Search Taxis
                        </Button>
                      </div>
                    </div>
                  </Col>

                  <Col
                    lg={6}
                    xl={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="cols-section-image-thumb-section-airporttaxi"
                  >
                    <div className="cols-section-bg-color-section-airporttaxi">
                      <h3>Executive</h3>
                      <h5>Mercedes-Benz E-Class or similar</h5>

                      <div>
                        <i
                          className="fa fa-user"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        <span>3 passengers</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-eye"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        <span>WOW VIP</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-user"
                          style={{ paddingRight: "10px", color: "#f87370" }}
                        ></i>
                        <span style={{ color: "#f87370" }}>asasdd</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-check"
                          style={{ paddingRight: "10px", color: "green" }}
                        ></i>
                        <span style={{ color: "green" }}>Meet & Greet</span>
                      </div>

                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            this.hideComponent(
                              "showHide13passengerscardsearch2"
                            )
                          }
                          className={
                            "" +
                            (this.state.showHide13passengerscardsearch2
                              ? "selected-button"
                              : "")
                          }
                        >
                          Search Taxis
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    xl={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="cols-section-image-thumb-section-airporttaxi"
                  >
                    <div className="cols-section-bg-color-section-airporttaxi">
                      <h3>Full-size sedan</h3>
                      <h5>Mercedes-Benz E-Class or similar</h5>

                      <div>
                        <i
                          className="fa fa-user"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        <span>3 passengers</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-eye"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        <span>WOW Long Drive</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-user"
                          style={{ paddingRight: "10px", color: "#f87370" }}
                        ></i>
                        <span style={{ color: "#f87370" }}>asasdd</span>
                      </div>

                      <div>
                        <i
                          className="fa fa-check"
                          style={{ paddingRight: "10px", color: "green" }}
                        ></i>
                        <span style={{ color: "green" }}>Meet & Greet</span>
                      </div>

                      <div>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() =>
                            this.hideComponent(
                              "showHide13passengerscardsearch3"
                            )
                          }
                          className={
                            "" +
                            (this.state.showHide13passengerscardsearch3
                              ? "selected-button"
                              : "")
                          }
                        >
                          Search Taxis
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
           
          </Container>

          <center>
              <Button
                variant="outlined"
                style={{margin: '1rem 0'}}
                color="primary"
                onClick={() => this.hideComponent("HideTaxiSectionCars")}
                className={
                  "" + (this.state.HideTaxiSectionCars ? "selected-button" : "")
                }
              >
                Hide Taxis Sections
              </Button>
            </center>


          {showHide13passengerscardsearch1 && (
            <Container>
              <Row>
                {this.state.cars_1.map((car1) => {
                  const { name, country, time, price, img } = car1;
                  return (
                    <Col xl={6} lg={6} md={6}>
                      <div className="cars-section-passenger13-taxi-practice">
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough">
                          <div class="et_pb_module et_pb_blurb et_pb_blurb_8  et_pb_text_align_center  et_pb_blurb_position_top et_pb_bg_layout_light">
                            <div class="et_pb_blurb_content">
                              <div class="et_pb_main_blurb_image">
                                <span class="et_pb_image_wrap">
                                  <img src={img}></img>
                                </span>
                              </div>
                              <div class="et_pb_blurb_container">
                                <h4 class="et_pb_module_header">
                                  <span>{name}</span>
                                </h4>
                                <div class="et_pb_blurb_description">
                                  <p>{price}</p>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div class="et_pb_module et_pb_toggle et_pb_toggle_0 et_pb_toggle_item  et_pb_text_align_left et_pb_toggle_close">
                            {/* <h5 class="et_pb_toggle_title">Details</h5> */}
                            <div class="et_pb_toggle_content clearfix">
                              <Collapse
                                bordered={false}
                                defaultActiveKey={["1"]}
                                expandIcon={({ isActive }) => (
                                  <CaretRightOutlined
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                                className="site-collapse-custom-collapse"
                              >
                                <Panel
                                  header="See Details"
                                  key="0"
                                  className="site-collapse-custom-panel"
                                >
                                  {/* <p>{text}</p> */}
                                  <div>
                                    <ul>
                                      <li>Vivamus suscipit tortor</li>
                                      <li>Sed porttitor lectus nibh.</li>
                                      <li>Curabitur arcu erat</li>
                                      <li>Curabitur aliquet quam id dui</li>
                                      <li>
                                        Vestibulum ante ipsum primis in faucibus
                                      </li>
                                    </ul>
                                  </div>
                                </Panel>
                              </Collapse>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          )}



          {showHide13passengerscardsearch2 && (
            <Container>
              <Row>
                {this.state.cars_2.map((car2) => {
                  const { name, country, time, price, img } = car2;
                  return (
                    <Col xl={6} lg={6} md={6}>
                      <div className="cars-section-passenger13-taxi-practice">
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough">
                          <div class="et_pb_module et_pb_blurb et_pb_blurb_8  et_pb_text_align_center  et_pb_blurb_position_top et_pb_bg_layout_light">
                            <div class="et_pb_blurb_content">
                              <div class="et_pb_main_blurb_image">
                                <span class="et_pb_image_wrap">
                                  <img src={img}></img>
                                </span>
                              </div>
                              <div class="et_pb_blurb_container">
                                <h4 class="et_pb_module_header">
                                  <span>{name}</span>
                                </h4>
                                <div class="et_pb_blurb_description">
                                  <p>{price}</p>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div class="et_pb_module et_pb_toggle et_pb_toggle_0 et_pb_toggle_item  et_pb_text_align_left et_pb_toggle_close">
                            {/* <h5 class="et_pb_toggle_title">Details</h5> */}
                            <div class="et_pb_toggle_content clearfix">
                              <Collapse
                                bordered={false}
                                defaultActiveKey={["1"]}
                                expandIcon={({ isActive }) => (
                                  <CaretRightOutlined
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                                className="site-collapse-custom-collapse"
                              >
                                <Panel
                                  header="See Details"
                                  key="0"
                                  className="site-collapse-custom-panel"
                                >
                                  {/* <p>{text}</p> */}
                                  <div>
                                    <ul>
                                      <li>Vivamus suscipit tortor</li>
                                      <li>Sed porttitor lectus nibh.</li>
                                      <li>Curabitur arcu erat</li>
                                      <li>Curabitur aliquet quam id dui</li>
                                      <li>
                                        Vestibulum ante ipsum primis in faucibus
                                      </li>
                                    </ul>
                                  </div>
                                </Panel>
                              </Collapse>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          )}




          {showHide13passengerscardsearch3 && (
            <Container>
              <Row>
                {this.state.cars_3.map((car3) => {
                  const { name, country, time, price, img } = car3;
                  return (
                    <Col xl={6} lg={6} md={6}>
                      <div className="cars-section-passenger13-taxi-practice">
                        <div class="et_pb_column et_pb_column_1_3 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough">
                          <div class="et_pb_module et_pb_blurb et_pb_blurb_8  et_pb_text_align_center  et_pb_blurb_position_top et_pb_bg_layout_light">
                            <div class="et_pb_blurb_content">
                              <div class="et_pb_main_blurb_image">
                                <span class="et_pb_image_wrap">
                                  <img src={img}></img>
                                </span>
                              </div>
                              <div class="et_pb_blurb_container">
                                <h4 class="et_pb_module_header">
                                  <span>{name}</span>
                                </h4>
                                <div class="et_pb_blurb_description">
                                  <p>{price}</p>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div class="et_pb_module et_pb_toggle et_pb_toggle_0 et_pb_toggle_item  et_pb_text_align_left et_pb_toggle_close">
                            {/* <h5 class="et_pb_toggle_title">Details</h5> */}
                            <div class="et_pb_toggle_content clearfix">
                              <Collapse
                                bordered={false}
                                defaultActiveKey={["1"]}
                                expandIcon={({ isActive }) => (
                                  <CaretRightOutlined
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                                className="site-collapse-custom-collapse"
                              >
                                <Panel
                                  header="See Details"
                                  key="0"
                                  className="site-collapse-custom-panel"
                                >
                                  {/* <p>{text}</p> */}
                                  <div>
                                    <ul>
                                      <li>Vivamus suscipit tortor</li>
                                      <li>Sed porttitor lectus nibh.</li>
                                      <li>Curabitur arcu erat</li>
                                      <li>Curabitur aliquet quam id dui</li>
                                      <li>
                                        Vestibulum ante ipsum primis in faucibus
                                      </li>
                                    </ul>
                                  </div>
                                </Panel>
                              </Collapse>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          )}
        </div>
      </div>
    );
  }
}
export default Airport_taxi13passengers;
