import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory
} from "react-router-dom";

class Wowholidaytransportservices extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false
    };
  }



  guidance = () => {
    document.getElementById("guidance-team").style.display = 'block';
    document.getElementById("cooperative-team").style.display = "none";
    document.getElementById("makethings-team").style.display = "none";
    
  };


  cooperative = () => {
    document.getElementById("guidance-team").style.display = 'none';
    document.getElementById("cooperative-team").style.display = "block";
    document.getElementById("makethings-team").style.display = "none";
  };
  

  makethings = () => {
    document.getElementById("guidance-team").style.display = 'none';
    document.getElementById("cooperative-team").style.display = "none";
    document.getElementById("makethings-team").style.display = "block";
  };



  ourteam = () => {
    document.getElementById("our-team").style.display = 'block';
    document.getElementById("transportaion-services").style.display = "none";
  };
  

  transportationservices = () => {
    document.getElementById("our-team").style.display = 'none';
    document.getElementById("transportaion-services").style.display = "block";
  };


  ourservices = () => {
    document.getElementById("our-team").style.display = 'none';
    document.getElementById("transportaion").style.display = "none";
    document.getElementById("our-services").style.display = "block";
  };

  allyear = () => {
    document.getElementById("cities-cards").style.display = 'none';
    document.getElementById("beaches-cards").style.display = "none";
    document.getElementById("ski-cards").style.display = "none";
    document.getElementById("year-cards").style.display = "block";
    document.getElementById("nature-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "none";

  };
  

 

 
  render() {
    return (
      <div>






        {/* <div className="holiday-inspiration-wow-holiday-our-container">
          <div class="holiday-inspiration-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-inspiration-wow-holiday-home-our-story">
                <div class="holiday-inspiration-wow-holiday-our-team-heading">
                  Holiday inspiration
                </div>
                <div class="holiday-inspiration-wow-holiday-our-team-heading-below-text">
                  If you need ideas for where to go on your next trip, let us
                  inspire you with our list of the best holiday destinations for
                  every type of vacation.
                </div>
                <div class="holiday-credit-wow-holiday-our-team-heading">
                  <Button
                    variant="outline-light"
                    className="holiday-credit-wow-holiday-our-team-button"
                  >
                    Check My Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        

       

        <div>
          <header className="header-image-in-new-design-transport">


   <div className="logo-inside-header-of-new-design-page-transport">
     <img className="image-logo-inside-header-of-new-design-page-wow-transport" 
     src="IMAGES/WOWholiday-iconlogo.png">
     </img>
   </div>


   {/* <div className="logo-inside-header-of-new-design-page--wow-transport">
     <img className="image-logo-inside-header-of-new-design-page-transport" src="IMAGES/city-images/wow-gallery/tour-logo.png"></img>
   </div> */}


   <div className="text-box-inside-background-image-of-new-design-transport">
  <h1 className="heading-primary-transport">
 



    <span className="heading-primary-main-transport">
      WOW Holiday

    </span>
    <span className="heading-primary-sub-transport">
      Transport,Services & Team

    </span>
  </h1>

  {/* <div class="holiday-transport-wow-holiday-our-team-heading-below-text">
                  We provide the best transport &services that you need during your trip. Our team is very
                   cooperative, provide good hospitality and keep you entertain all the way. 
                </div> */}
  {/* <Button variant="outline-light" id="scrolltoup" onClick={this.toscrolltoup} className="button-in-new-design-gallery" > Discover Our Tours</Button> */}

  </div>
  
  <toggle>

  </toggle>



          </header></div>












          <Container>

          <div class="u-center-text u-margin-bottom-second-heading-transport">
                  <div  className="heading-secondary-section-transport-holiday ">
                      WOW Holiday Transport & Services
                  </div>
                  </div>

<div className="border-line-bw-transport-images"></div>


          </Container>

       





          <Container>

          <Row>
                      <Col lg={4} md={4} sm={12} xs={12}>
                      <div className="header-inside-grid-wow-transport-onetwo u-margin-bottom-small">
                      Safe and Affordable Transportation

                      </div>

                      <p className="header-context-inside-grid-wow-transport">
                      You will be happy you gave us a call. Our licensed drivers are friendly
                       and accommodating to your needs. They receive extra training in working
                        with wheelchairs and other medical equipment transportation you need.

                      </p>

                    {/* <div style={{textAlign:'center'}}>
                      <i className="fas fa-plane-departure jet-transport" style={{textAlign:'center !important'}}></i>
                      </div>

                      <div className="header-inside-grid-wow-transport u-margin-bottom-small">
                      Best Prices with No Hidden Costs

                      </div>

                      <p className="header-context-inside-grid-wow-transport">
                      We have very affordable rates that make us accessible, 
                      even for those on a fixed income.
                      </p> */}




                      <div className="feature-box-in-wow-transport-holiday">



     
<i className="feature-box__icon-wow-transport fas fa-plane-departure"></i>

<div  className="header-inside-grid-wow-transport-features-box u-margin-bottom-small">
Best Prices with No Hidden Costs
</div>


<p className="feature__box-text-wow-transport">
We have very affordable rates that make us accessible, 
                      even for those on a fixed income.
</p>

</div>

                    



                      </Col>








                      <Col lg={4} md={4} sm={12} xs={12}>
                      <div className="header-inside-grid-wow-transport-onetwo u-margin-bottom-small">
                      Best Services and Finest Hospitality

                      </div>

                      <p className="header-context-inside-grid-wow-transport-center">
                      Our three founding members graduated from best hotel management
                       in the world. Since then, a passion for
                        hospitality provided to others has been nurtured 
                        and animates our company's identity on day to day basis.

                      </p>

                      {/* <div style={{textAlign:'center'}}>
                      <i className="fa fa-umbrella-beach jet-transport" style={{textAlign:'center !important'}}></i>
                      </div>
                   

                      <div className="header-inside-grid-wow-transport u-margin-bottom-small">
                        Live adventours life

                      </div>

                      <p className="header-context-inside-grid-wow-transport">
                      If you feel yourself settling into
                       a down mood, push yourself to take on
                        a new adventure. Let the rewards boost your mood. 
                      </p> */}



                      <div className="feature-box-in-wow-transport-holiday">



     
<i className="feature-box__icon-wow-transport fa fa-umbrella-beach"></i>

<div  className="header-inside-grid-wow-transport-features-box u-margin-bottom-small">
Live &nbsp; adventours &nbsp; life
</div>


<p className="feature__box-text-wow-transport">
If you feel yourself settling into
                       a down mood, push yourself to take on
                        a new adventure. Let the rewards boost your mood. 
</p>

</div>
                    



                      </Col>









                      <Col lg={4} md={4} sm={12} xs={12}>
                      <div className="header-inside-grid-wow-transport u-margin-bottom-small">
                      Why Choose Us

                      </div>

                      <p className="header-context-inside-grid-wow-transport">
                      We provide the best transport &services that you need during your trip.
                       Our team is
                   cooperative, provide good hospitality and keep you entertain all the way. 
                   This passion, combined with our experience in private aviation,
                    leads us to constant perfection.
                      </p>

                      {/* <div style={{textAlign:'center'}}>
                      <i className="far fa-handshake-o jet-transport" style={{textAlign:'center !important'}}></i>
                      </div>


                      <div className="header-inside-grid-wow-transport u-margin-bottom-small">
                      24/7 Customer Support

                      </div>

                      <p className="header-context-inside-grid-wow-transport">
                      Get assistance whenever and wherever you are, We are there for you 
                      all the time.
                      </p> */}





                      <div className="feature-box-in-wow-transport-holiday">



     
<i className="feature-box__icon-wow-transport far fa-handshake-o"></i>

<div  className="header-inside-grid-wow-transport-features-box u-margin-bottom-small">
24/7 Customer Support
</div>


<p className="feature__box-text-wow-transport">
Get assistance whenever and wherever you are, We are there for you 
                      all the time. You'll be able to explore
 new places.
</p>

</div>

                    



                      </Col>

<Container>

 <div className="border-line-bw-transport-images-three"></div>
 </Container>
                    
                      <Col
                       lg={12} md={12} sm={12} xs={12}
                        className="three-overlapperd-images-transport-below-features-holiday">
                      <div className="composition-transport">
                        
                        <img src="IMAGES/city-images/wow-transport/transport-three-images/jet.jpg" alt="" className="composition__photo-transport composition__photo--p1-transport"></img>
                        <img src="IMAGES/city-images/wow-transport/transport-three-images/services.jpg" alt="" className="composition__photo-transport composition__photo--p2-transport"></img>
                        <img src="IMAGES/city-images/wow-transport/transport-three-images/hospitality.jpg" alt="" className="composition__photo-transport composition__photo--p3-transport"></img>
                      </div>
                      </Col>
</Row>


          </Container>











          <Container>

<div className="border-line-bw-transport-images-three-buttons"></div>
</Container>







<Container>

<div class="u-center-text u-margin-bottom-second-heading-transport" id="ourtourid">
                  <div  className="heading-secondary-section-transport-holiday">
                      Transport, Services & Team
                  </div>
                  </div>
                  </Container>



                  <Container>
            <div style={{textAlign:'center'}}>
              <img src="IMAGES/city-images/wow-transport/tourism.jpg" className="image-tourism-wow-transport"></img>
            </div>

            
          </Container>


<Container>




<ScrollAnimation animateIn="fadeIn" style={{marginTop:'20px'}} >
          <div>
            <center>
           


            <Button 
            variant="dark"
            className="button-featured-holiday-transport"
            onClick={this.transportationservices}
            style={{height:'45px'}}

            >
              transport & Services

            </Button>

            <Button 
            variant="dark"
            className="button-featured-holiday-transport"
            onClick={this.ourteam}
            style={{height:'45px'}}

            >
              Our Team

            </Button>

{/* 
            <Button 
            variant="black"
            className="button-featured-holiday-transport"
            onClick={this.ourservices}
            style={{height:'45px'}}

            >
              our services

            </Button> */}
            </center>
          </div>
        </ScrollAnimation>

        </Container>

        

 



{/* <div class="u-center-text u-margin-bottom-second-heading-transport">
                  <div  className="heading-our-team-section-transport-holiday">
                  OUR TEAM
                  </div>
                  </div> */}

<div id="our-team" style={{display:'none'}}>
<div className="holiday-transport-our-team-wow-holiday-our-container">
          <div class="holiday-transport-our-team-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-transport-our-team-wow-holiday-home-our-story">
                <div class="holiday-transport-our-team-wow-holiday-our-team-heading">
                OUR TEAM
                </div>
              </div>
            </div>
          </div>
        </div> 


                  <Container >
                  <Row style={{marginBottom:'5rem'}}>
        <Col lg={6} sm={12} md={6} xs={12} >
          <div className="professional-team-heading-transport-holiday">
                        
            We're a Team of professional people who loves what the do.</div>
       </Col>


      <Col lg={6} sm={12} md={6} xs={12}>
<div className="professional-team-context-transport-holiday">
Lorem ipsum dolor sit amet consectetur adipisicing elit.
                         Dolorem perspiciatis repudiandae asperiores cupiditate rem sunt velit
                          a possimus laborum labore at, in officia dolorum, dignissimos rerum temporibus
                           enim impedit voluptas!<br></br>
                           Lorem ipsum dolor sit amet consectetur adipisicing elit.
                         Dolorem perspiciatis repudiandae asperiores cupiditate rem sunt velit
                          a possimus laborum labore at, in officia dolorum, dignissimos rerum temporibus
                           enim impedit voluptas!
</div>


          </Col>


          
                      </Row>
                  </Container>


                  













<Container>
  <Row>
    <Col lg={5} xl={5} md={5} sm={12} xs={12} className="border-right-column-transport-team">

      <div className="our-team-heading-transport">
        Get To Know Our Team
      </div>





    
      <center>
      <div className="button-section-team-transport-holiday">
       
                  <Link 
                  onClick={this.guidance}
                   className="button-text-wow-transport-grid-grid-section-pictures active show">
                        01
                        

                      </Link>
                      {/* <span className="slash-in-gallery-grid-section-pictures"> &nbsp; |  &nbsp;</span> */}


                      <Link 
                      onClick={this.cooperative}
                       className="button-text-wow-transport-grid-grid-section-pictures ">
                        02
                        

                      </Link>


                      <Link 
                      onClick={this.makethings}
                       className="button-text-wow-transport-grid-grid-section-pictures">
                        03 
                        

                      </Link>

                      
                      </div></center>





  





                      <div className="box-containing-data-team" id="guidance-team">
                        <div className="header-in-our-team-transport">
                       Guidance Team

                        </div>


                        <div className="header-context-in-our-team-transport">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                         Dolorem perspiciatis repudiandae asperiores cupiditate rem sunt velit
                          a possimus laborum labore at, in officia dolorum, dignissimos rerum temporibus
                           enim impedit voluptas!
                      
</div>

                      </div>




                      <div
                       className="box-containing-data-team" 
                       id="cooperative-team"
                        style={{display:'none'}}>


                        <div className="header-in-our-team-transport">
                       Very Cooperative

                        </div>


                        <div className="header-context-in-our-team-transport">
                        In order to be a great team player, it’s crucial to have an unbiased awareness of 
                        what you’re good at and what you suck at. As a legendary entrepreneur and YouTube
                         star, Gary Vaynerchuk, says: “Self-awareness is being able to accept your weaknesses
                          while focusing all of your attention on your strengths.”
                      
</div>

                      </div>



                      <div className="box-containing-data-team" id="makethings-team" style={{display:'none'}}>
                        <div className="header-in-our-team-transport">
                       Make things Easier for you

                        </div>


                        <div className="header-context-in-our-team-transport">
                        You have to build a team of people who want to talk to customers and 
                        are personable, friendly, caring and thoughtful. These are things you can't
                         always teach on-the-job.” Smart hiring is one among many tenets of
                          customer service the company focuses on during its annual conference series
                      
</div>

                      </div>
                      
                     

                      
                     

    </Col>


    <Col lg={7} xl={7} md={8} sm={12} xs={12} >
                      <div className="composition-team-transport">
                        <img src="IMAGES/city-images/wow-transport/our-team/pic2.jpg" alt="" className="composition__photo-team-transport composition__photo--p1-team-transport"></img>
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-3-large.jpg" alt="" className="composition__photo-team-transport composition__photo--p2-team-transport"></img>
                        <img src="IMAGES/city-images/wow-transport/our-team/pic1.jpg" alt="" className="composition__photo-team-transport composition__photo--p3-team-transport"></img>
                      </div>
                      </Col>

  </Row>
</Container>




<Container >


<div class="u-center-text u-margin-bottom-second-heading-transport" >
                  <div  className="heading-meet-the-team-section-transport-holiday">
                      Meet The Team
                  </div>
                  </div>









                  {/* <Row  style={{display:'none'}}> */}
                    <Col lg={12} md={4} sm={6} xs={12} className="margin-in-team-and-ceo-transport-holiday">

                    <figure className="meet-the-team-ceo-transport__shape">
      <img 
      src="IMAGES/city-images/wow-gallery/stories/nat-8.jpg"
       alt="person on tour"
       className="meet-the-team-ceo-transport-holiday-image"
       >
       </img>
     <figcaption className="meet-the-team-ceo-transport__image-caption">
Tahir
     </figcaption>
     
    </figure>

    <div className="content-meet-team-ceo-transport-holiday">
       <div className="occupation-of-team-member-ceo-transport-holiday">
CEO
       </div>

       {/* <div className="text-wriiten-of-team-member-ceo-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div> */}

     


     </div>

     <div className="social-media-button-in-wow-holidays-transport-team-ceo-center">

     <ul className="social-media-button-in-wow-holidays-transport-team-ceo">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon-ceo"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon-ceo"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon-ceo"></i>
           </li></a>
       </ul></div>
                    </Col>
                    {/* </Row> */}


















                  <Row >
                    <Col lg={4} md={4} sm={6} xs={12}>

                    <figure className="meet-the-team-transport__shape">
      <img 
      src="IMAGES/city-images/wow-transport/our-team/members/member1.jpg"
       alt="person on tour"
       className="meet-the-team-transport-holiday-image-member1"
       >
       </img>
     <figcaption className="meet-the-team-transport__image-caption">
Scott Adkins
     </figcaption>
     
    </figure>

    <div className="content-meet-team-transport-holiday">
       <div className="occupation-of-team-member-transport-holiday">
Job Title
       </div>

       <div className="text-wriiten-of-team-member-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div>

     


     </div>

     <ul className="social-media-button-in-wow-holidays-transport-team">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>
       </ul>
                    </Col>


                    <Col lg={4} md={4} sm={6} xs={12} >
                    <figure className="meet-the-team-transport__shape">
      <img 
      src="IMAGES/city-images/wow-transport/our-team/members/member2.jpg"
       alt="person on tour"
       className="meet-the-team-transport-holiday-image"
       >
       </img>
     <figcaption className="meet-the-team-transport__image-caption">
James
     </figcaption>
     
    </figure>

    <div className="content-meet-team-transport-holiday">
       <div className="occupation-of-team-member-transport-holiday">
       Job Title
       </div>

       <div className="text-wriiten-of-team-member-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div>

     


     </div>

     <ul className="social-media-button-in-wow-holidays-transport-team">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>
       </ul>

</Col>


<Col lg={4} xl={4} md={4} sm={4} xs={12} >
<figure className="meet-the-team-transport__shape">
      <img 
      src="IMAGES/city-images/wow-transport/our-team/members/member3.jpg"
       alt="person on tour"
       className="meet-the-team-transport-holiday-image"
       >
       </img>
     <figcaption className="meet-the-team-transport__image-caption">
Mary Jaimes
     </figcaption>
     
    </figure>

    <div className="content-meet-team-transport-holiday">
       <div className="occupation-of-team-member-transport-holiday">
       Job Title
       </div>

       <div className="text-wriiten-of-team-member-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div>

       <ul className="social-media-button-in-wow-holidays-transport-team">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>
       </ul>


     </div>

   

</Col>
                  </Row>



                  <Container>

<div className="border-line-bw-team-members"></div>
</Container>


                  <Row >
                    <Col lg={4} md={4} sm={6} xs={12}>

                    <figure className="meet-the-team-transport__shape">
      <img 
      src="IMAGES/city-images/wow-transport/our-team/members/member4.jpg"
       alt="person on tour"
       className="meet-the-team-transport-holiday-image-member1"
       >
       </img>
     <figcaption className="meet-the-team-transport__image-caption">
Scott Adkins
     </figcaption>
     
    </figure>

    <div className="content-meet-team-transport-holiday">
       <div className="occupation-of-team-member-transport-holiday">
       Job Title
       </div>

       <div className="text-wriiten-of-team-member-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div>

     


     </div>

     <ul className="social-media-button-in-wow-holidays-transport-team">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>
       </ul>
                    </Col>


                    <Col lg={4} md={4} sm={6} xs={12}>
                    <figure className="meet-the-team-transport__shape">
      <img 
      src="IMAGES/city-images/wow-transport/our-team/members/member2.jpg"
       alt="person on tour"
       className="meet-the-team-transport-holiday-image"
       >
       </img>
     <figcaption className="meet-the-team-transport__image-caption">
Mary Jaimes
     </figcaption>
     
    </figure>

    <div className="content-meet-team-transport-holiday">
       <div className="occupation-of-team-member-transport-holiday">
       Job Title
       </div>

       <div className="text-wriiten-of-team-member-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div>

     


     </div>

     <ul className="social-media-button-in-wow-holidays-transport-team">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>
       </ul>

</Col>


<Col lg={4} md={4} sm={6} xs={12}>
<figure className="meet-the-team-transport__shape">
      <img 
      src="IMAGES/city-images/wow-transport/our-team/members/member2.jpg"
       alt="person on tour"
       className="meet-the-team-transport-holiday-image"
       >
       </img>
     <figcaption className="meet-the-team-transport__image-caption">
Mary Jaimes
     </figcaption>
     
    </figure>

    <div className="content-meet-team-transport-holiday">
       <div className="occupation-of-team-member-transport-holiday">
       Job Title
       </div>

       <div className="text-wriiten-of-team-member-transport-holiday">
       Team Leaders play extremely important role in motivating company's teams and ensuring 
       their success. Some of their duties include communicating company goals, safety practices,
        and deadlines with designated teams. 
       </div>

       <ul className="social-media-button-in-wow-holidays-transport-team">
       <a>
         <li>
         <i class="fab fa-facebook  social-media-button-in-wow-holidays-transport-team-icon"></i>
         </li></a>

        <a> <li>
         <i class="fab fa-twitter social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>

          <a> <li>
           <i class="fab fa-linkedin social-media-button-in-wow-holidays-transport-team-icon"></i>
           </li></a>
       </ul>


     </div>

   

</Col>
                  </Row>









  
</Container></div>




<div id="transportaion-services">


<div className="holiday-transport-our-services-wow-holiday-our-container">
          <div class="holiday-transport-our-services-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-transport-our-services-wow-holiday-home-our-story">
                <div class="holiday-transport-our-services-wow-holiday-our-team-heading">
               Transport & Services
                </div>
              </div>
            </div>
          </div>
        </div> 











        <section class="section-transport-services-wow-holiday">
          <div class="container wow fadeInLeft" data-wow-delay=".3s" data-wow-duration="1s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.3s', animationName: 'fadeInLeft'}}>
          <div class="box-lg bg-contrast-variant-1">
            <div class="box-lg_left">
              <img
               src="IMAGES/city-images/wow-transport/transportservices/grid-bus-image.jpg" 
               alt="bus-image-transport"
               className="bus-image-transport-service-wow-holiday"
               />
            </div>
            <div class="box-lg_body inset-3 text-center text-md-left">
              <div class="text-default-transport-services-holiday">Offering great expertise</div>
              <div class="text-primary-transport-services-holiday normal">in all your land Travel needs</div>
              <p className="text-paragrapgh-primary-transport-services-holiday">
                We are the leading transportation travels Tour company, offering bus,
                 private jet and coaches hire services to Holiday
               Trips and tour  and the general public. For more than 10 years, we have focused on 
               providing the  best bus charter and hire services that allow people to explore
                the country in comfort and
                style.
              </p>
            </div>
          </div>
        </div>
      </section>






      <div class="u-center-text u-margin-bottom-second-heading-transport-we-offer" >
                  <div  className="heading-secondary-section-we-offer-transport-holiday ">
                     What We Offer
                  </div>
                  </div>



<div className="u-center-text text-written-below-we-offer-transport-holiday" >
Few reasons why we are one of the Trusted & most effective Company :
</div>





<Container>

<Row>
  <Col xl={4} lg={4} md={4} sm={12} xs={12}>

  <div className="services-box-transport">



     
<i 
className="
lg-icon
  circle-icon primary-icon-filled fas fa-users"></i>

<div  className="header-inside-grid-wow-transport-our-services-features-box u-margin-bottom-small">
Best Team Spirit
</div>


<p className="feature__box-text-wow-transport-our-services">
 We help each other, wherever we are. This makes work easier.   
 Our team is very caring and make their travelling worthy.                  
</p>

</div>



  </Col>

  <Col xl={4} lg={4} md={4} sm={12} xs={12}>
  <div className="services-box-transport">



     
<i className="lg-icon
  circle-icon primary-icon-filled fas fa-map"></i>

<div  className="header-inside-grid-wow-transport-our-services-features-box u-margin-bottom-small">
Find the best place to Visit
</div>


<p className="feature__box-text-wow-transport-our-services">
You can freely use our interactive map to find the best Place. You can find info about the best place
to travel and spend time with your family.
</p>

</div>
  </Col>


  <Col xl={4} lg={4} md={4} sm={12} xs={12}>

  <div className="services-box-transport">



     
<i className="lg-icon
  circle-icon primary-icon-filled fas fa-plane-departure"></i>

<div  className="header-inside-grid-wow-transport-our-services-features-box u-margin-bottom-small">
Affordable Transport
</div>


<p className="feature__box-text-wow-transport-our-services">
We have very affordable rates that make us accessible, 
                      even for those on a fixed income.
</p>

</div>
  </Col>

</Row>




<Row>
  <Col xl={4} lg={4} md={4} sm={12} xs={12}>

  <div className="services-box-transport">



     
<i 
className="
lg-icon
  circle-icon primary-icon-filled fas fa-cubes"></i>

<div  className="header-inside-grid-wow-transport-our-services-features-box u-margin-bottom-small">
Friendly Enviroment
</div>


<p className="feature__box-text-wow-transport-our-services">
Our Expert Team are friendly and accommodating to your needs. They receive
 extra training in working  and other medical equipment you need.              
</p>

</div>



  </Col>

  <Col xl={4} lg={4} md={4} sm={12} xs={12}>
  <div className="services-box-transport">



     
<i className="lg-icon
  circle-icon primary-icon-filled fas fa-map"></i>

<div  className="header-inside-grid-wow-transport-our-services-features-box u-margin-bottom-small">
Find the best place to visit
</div>


<p className="feature__box-text-wow-transport-our-services">
You can freely use our interactive map to find the best Place. You can find info about the best place
to travel and spend time with your family.
</p>

</div>
  </Col>


  <Col xl={4} lg={4} md={4} sm={12} xs={12}>

  <div className="services-box-transport">



     
<i className="lg-icon
  circle-icon primary-icon-filled fas fa-comments"></i>

<div  className="header-inside-grid-wow-transport-our-services-features-box u-margin-bottom-small">
24/7 Support
</div>


<p className="feature__box-text-wow-transport-our-services">
Get assistance whenever and wherever you are, We are there for you all the time.</p>

</div>
  </Col>

</Row>



</Container>


{/* <div class="u-center-text u-margin-bottom-second-heading-transport-we-offer">
                  <div  className="heading-secondary-section-our-we-services-transport-holiday ">
                     OUR SERVICES
                  </div>
                  </div> */}



<br/><br/><br/>








<section className="section-md-top-transportservices section-md-bottom-90 bg-dark-blue context-dark section-skew section-skew-wide">
          <div className="skew-block"></div>
          <Container>
          <div className="shell-transport-holiday text-md-left">
            <div className="services-overview-heading-transport-holiday">services overview</div>

            <hr className="divider divider-lg bg-primary hr-md-left-0"/>

            <div className="range text-md-left offset-top-50 range-xs-center">
              <div className="cell-sm-8 cell-md-6">
                <div className="unit unit-md unit-md-horizontal text-md-left">
                  <div className="unit-left"><span class=" lg-icon circle-icon primary-icon-filled fas fa-plane"></span></div>
                  <div className="unit-body">
                    <div className="text-bold headings-inside-shell-transport-holiday">Sea &amp; Air Services</div>
                    <p className="text-inside-shell-our-services-transport-holiday">We have contracts with world's major ocean and air carriers. No matter what type of transportation you choose - your travel will arrive in due time to any city. We will take care of all the paperwork, leaving you only satisfaction by our services.</p>
                  </div>
                </div>
              </div>
              <div className="cell-sm-8 cell-md-6 offset-md-top-0">
                <div className="unit unit-md unit-md-horizontal text-md-left">
                  <div className="unit-left"><span class="lg-icon circle-icon primary-icon-filled fas fa-thumbs-up"></span></div>
                  <div className="unit-body">
                    <div className="text-bold headings-inside-shell-transport-holiday">Explore Your Favourite Destination</div>
                    <p className="text-inside-shell-our-services-transport-holiday">Our staff is on hand with a friendly and professional service for hiring coaches for any event. We have years of experience, built on offering our customers a courteous and professional service regarding all aspects of coach hire.</p>
                  </div>
                </div>
              </div>
              <div class="cell-sm-8 cell-md-6 offset-top-50">
                <div class="unit unit-md unit-md-horizontal text-md-left">
                  <div class="unit-left"><span class="lg-icon circle-icon primary-icon-filled mdi fas fa-users"></span></div>
                  <div class="unit-body">
                    <div class="text-bold headings-inside-shell-transport-holiday">Open for Partnerships</div>
                    <p className="text-inside-shell-our-services-transport-holiday">Our company is always looking for new partners. If you are a professional with experience in logistics, customer support or marketing, we would like to work with you. Talk to our representative or fill a form to learn more.</p>
                  </div>
                </div>
              </div>
              <div class="cell-sm-8 cell-md-6 offset-top-50">
                <div class="unit unit-md unit-md-horizontal text-md-left">
                  <div class="unit-left"><span class="lg-icon circle-icon primary-icon-filled mdi fas fa-inbox"></span></div>
                  <div class="unit-body">
                    <div class="text-bold headings-inside-shell-transport-holiday">Finest &amp; Hospitality</div>
                    <p className="text-inside-shell-our-services-transport-holiday">All of our storage facilities are equipped with climate control and are certified for food storage. We offer both short-term and long-term storage options, and guarantee the best conditions for your wares.</p>
                  </div>
                </div>
              </div>
            
            </div>
            
          </div>
          </Container>
        </section>

        <section class="section-90 section-skew bg-white">
          <div class="skew-block"></div>
        
        </section>




<Container>



<div class="u-center-text u-margin-bottom-second-heading-transport-we-offer">
                  <div  className="heading1-transport-section-transport-services-holiday">
                     Private jet Transport
                  </div>
                  </div>




<Row>
<Col xl={6} lg={6} md={6} sm={12} xs={12}
                        className="three-overlapperd-images-heading1-transport-section-holiday">
                      <div className="composition-transport-heading1-transport-section">
                        
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading1/p1.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p1-transport-heading1-transport-section"></img>
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading1/p2.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p2-transport-heading1-transport-section"></img>
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading1/p3.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p3-transport-heading1-transport-section"></img>
                      </div>
                      </Col>

  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
<div className="context-written-in-heading1-transport-section">

  <div className="context-heading-in-heading1-transport-section">
  Take off wherever you want, whenever you want, in all simplicity.
  </div>


<div className="context-text-written-in-heading1-transport-section">
Booking your flight with Simply Jet does not require you to ever commit to any long term engagement.
 There is no account to open, no hours packages or aircraft shares to buy. You only pay the trips you
  actually do, be it once every two years or three times a week, all of it without fuel or peak hour
   surcharges.
   </div>


<div className="context-text-written-in-heading1-transport-section">
Available round the clock, every day of the week, your Simply Jet advisors are there to help you plan
 your travel arrangements, months in advance or two hours before departure. With access to 5'000 aircrafts
  worldwide, we guarantee that we will source the best solution for your flight.
  </div>
    

  </div>

  </Col>
</Row>





<div class="u-center-text u-margin-bottom-second-heading-transport-we-offer">
                  <div  className="heading1-transport-section-transport-services-holiday">
                     Finest Hospitality
                  </div>
                  </div>





<Row>


<Col xl={6} lg={6} md={6} sm={12} xs={12}>
<div className="context-written-in-heading1-transport-section">

  <div className="context-heading-in-heading1-transport-section">
  At WOW we understand the importance of personal contact.
  </div>


<div className="context-text-written-in-heading1-transport-section">
You would like to go to the Monaco Grand Prix? An helicopter will be waiting next to your private
 jet upon arrival in Nice or Cannes to take your directly at the heart of the race. Want to enjoy
  your stay as soon as you land? A Viano will take your luggage to your hotel while your golf clubs
   accompany you in an other vehicle to start an 18-holes straight away.
   </div>


<div className="context-text-written-in-heading1-transport-section">
Available round the clock, every day of the week, your Simply Jet advisors are there to help you plan
 your travel arrangements, months in advance or two hours before departure. With access to 5'000 aircrafts
  worldwide, we guarantee that we will source the best solution for your flight.
  </div>
    

  </div>

  </Col>




<Col xl={6} lg={6} md={6} sm={12} xs={12}
                        className="three-overlapperd-images-heading1-transport-section-holiday">
                      <div className="composition-transport-heading1-transport-section">
                        
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading2/p1.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p1-transport-heading1-transport-section"></img>
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading2/p2.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p2-transport-heading1-transport-section"></img>
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading2/p3.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p3-transport-heading1-transport-section"></img>
                      </div>
                      </Col>

  
</Row>






<div class="u-center-text u-margin-bottom-second-heading-transport-we-offer">
                  <div  className="heading1-transport-section-transport-services-holiday">
                     Private Yacht Rentals
                  </div>
                  </div>




<Row>
<Col xl={6} lg={6} md={6} sm={12} xs={12}
                        className="three-overlapperd-images-heading1-transport-section-holiday">
                      <div className="composition-transport-heading1-transport-section">
                        
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading3/p1.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p1-transport-heading1-transport-section"></img>
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading3/p2.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p2-transport-heading1-transport-section"></img>
                        <img src="IMAGES/city-images/wow-transport/transportservices/heading3/p3.jpg" alt="" className="composition__photo-transport-heading1-transport-section composition__photo--p3-transport-heading1-transport-section"></img>
                      </div>
                      </Col>



  <Col xl={6} lg={6} md={6} sm={12} xs={12}>
<div className="context-written-in-heading1-transport-section">

  <div className="context-heading-in-heading1-transport-section">
  Yacht Rentals and Hotel stays.
  </div>


<div className="context-text-written-in-heading1-transport-section">
Sail with a group in the world's best destinations guided by our expert lead boat.
Let a Sunsail skipper take control; perfect for those new to sailing.We’ll support your 
journey to becoming an independent sailor.The freedom and flexibility to set
 your own itinerary and sail independently.
   </div>


<div className="context-text-written-in-heading1-transport-section">
Available round the clock, every day of the week, your Simply Jet advisors are there to help you plan
 your travel arrangements, months in advance or two hours before departure. With access to 5'000 aircrafts
  worldwide, we guarantee that we will source the best solution for your flight.
  </div>
    

  </div>

  </Col>
</Row>

</Container>





<br/>




</div>








{/* <div id="our-services" style={{display:'none'}}>
  <h3>dasdasfasf</h3>
</div> */}


                      




{/* services */}

 {/* We offering unforgettable experience with affordable budget. Lets enjoy the journey with us!


  WOW that offers escored groups world wide. We can organize groups
                         for your organization or you and your friends and family
                         can join a tour that we have already planned. We offer only quality tours with reputable companies.





       

         

                      <Col lg={6} md={6} sm={12} xs={12}>
                      <div className="composition">
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-1-large.jpg" alt="" className="composition__photo composition__photo--p1"></img>
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-2-large.jpg" alt="" className="composition__photo composition__photo--p2"></img>
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-3-large.jpg" alt="" className="composition__photo composition__photo--p3"></img>
                      </div>
                      </Col>
                  </Row>


                  </Container>

              </section>




             






          </main>  */}







{/* <Container>

<div className="border-line-bw-transport-images-three-buttons-below"></div>
</Container> */}











      </div>
    );
  }
}
export default Wowholidaytransportservices;
