import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Figure,
  Media,
  OverlayTrigger,
  Tooltip,
  Col,
} from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import { Tabs } from "antd";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import Hire_Form_Stepper1 from "./HireFormStepper1";
import "./StepperPagesStyle.scss";
import { Collapse, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import CustomerReviews_RentalCars from './CustomerReviewsSectionRentalCarsSearch';
import ImportantInfo_RentalCars from './ImportantInfoRentalCarsSearch';

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Basic cover consists of a Collision Damage Waiver (CDW) with an excess of
    US$820.02 and Theft Protection (TP) with an excess of US$820.02. If the car
    is damaged or stolen you would be liable for costs up to the excess amount.
  </Tooltip>
);

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const { Panel } = Collapse;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;

// const customIcons = {
//   1: <FrownOutlined />,
//   2: <FrownOutlined />,
//   3: <MehOutlined />,
// };

class Stepper1_Additional_ChargesDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      //   showHide47passengerscards: true,
      //   showHide47passengerscars1: false,
      //   showHide47passengerscars2: false,
      HideCarsSectionFilterPage: false,
      expandIconPosition: "right",
      expandIconPosition2: "left",
      cars_5: true,
      cars_4: [
        {
          name: "Audi",
          country: "UAE, Dubai",
          currency: "US$",
          price: 128.67,
          price_1: 0,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/2-WOW-Family/4-GMC.png",
          time: "2h 30min",
        },
      ],
      numberOfitemsShown: 1,
    };
    // this.hideComponent = this.hideComponent.bind(this);
    // this.showMore = this.showMore.bind(this);
  }

  onPositionChange = (expandIconPosition) => {
    this.setState({ expandIconPosition });
  };

  hideComponent(name) {
    switch (name) {
      case "HideCarsSectionFilterPage":
        this.setState({
          cars_5: !this.state.cars_5,
        });
        break;
      //   case "showHide47passengerscardsimg":
      //     this.setState({
      //       showHide47passengerscardsimg: !this.state.showHide47passengerscardsimg,
      //     });
      default:
      // null;
    }
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { cars_4, cars_5, expandIconPosition, expandIconPosition2 } = this.state;
    return (
      <div>
        <Container>
          <Row>
            <Col lg={3} xl={3}>
              <div>
                <div
                  style={{
                    border: "1px solid #000",
                    padding: "2rem 1.5rem",
                  }}
                >
                  <Media>
                    <img
                      width={34}
                      height={34}
                      className="mr-3"
                      src="https://www.iconfinder.com/data/icons/everyday-objects-1/128/hourglass-512.png"
                      alt="Generic placeholder"
                    />
                    <Media.Body>
                      {/* <h5>Media Heading</h5> */}
                      <p style={{ fontFamily: "wow-bold", color: "#565656" }}>
                        To secure this price, just Book Now.
                      </p>
                    </Media.Body>
                  </Media>
                </div>

                <div
                  style={{
                    padding: "1rem 0rem",
                  }}
                >
                  <Hire_Form_Stepper1 />
                </div>

                <Collapse
                  defaultActiveKey={["1"]}
                  onChange={callback}
                  expandIconPosition={expandIconPosition}
                >
                  <Panel
                    header="HELP"
                    key="1"
                    style={{ fontFamily: "wow-bold" }}
                  >
                    <div>Got a question or need to talk to us?</div>
                  </Panel>
                </Collapse>
              </div>
            </Col>

            <Col lg={9} xl={9}>
              <div className="cars-search-resultsection-col8-RentalCarsBookingDetailsForm">
                <div class="cancellation-banner-rentalcars-RentalCarsBookingDetailsForm">
                  <div class="cancellation-icon-rentalcars-RentalCarsBookingDetailsForm">
                    {/* <span class="free-cancellation-icon"></span> */}
                    <span class="far fa-edit cancellation-rentalcars-RentalCarsBookingDetailsForm "></span>
                  </div>
                  <div class="cancellation-message-rentalcars-RentalCarsBookingDetailsForm">
                    <strong class="cancellation-header-rentalcars-RentalCarsBookingDetailsForm">
                      It's busy in Palma de Mallorca Airport (PMI) on your
                      dates, prices are likely to rise
                    </strong>
                    <p class="cancellation-bodytext-rentalcars-RentalCarsBookingDetailsForm">
                      Tip:
                      <span>
                        {" "}
                        Don't wait until it's too late! Book now so you don't
                        miss out on today's price.
                      </span>
                    </p>
                  </div>
                </div>

                {/* free cancelation small border box content /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                <div class="cancellation-banner-rentalcars-RentalCarsBookingDetailsForm-freecancelation">
                  <div class="cancellation-icon-rentalcars-RentalCarsBookingDetailsForm-freecancelation">
                    {/* <span class="free-cancellation-icon"></span> */}
                    <span class="far fa-edit cancellation-rentalcars-RentalCarsBookingDetailsForm-freecancelation "></span>
                  </div>
                  <div class="cancellation-message-rentalcars-RentalCarsBookingDetailsForm-freecancelation">
                    <strong class="cancellation-header-rentalcars-RentalCarsBookingDetailsForm-freecancelation">
                      FREE cancellation up to 48 hours before your pick-up
                    </strong>
                  </div>
                </div>

                {/* /////////////////////////////////////////////////////////////////////// */}

                <Tabs
                  defaultActiveKey="1"
                  // tabPosition="right"
                  className="tabs-mainClass-Top"
                  centered
                >
                  <TabPane
                    tab={
                      <span>
                        <AppleOutlined />
                        Car Details
                      </span>
                    }
                    key="1"
                  >
                    <div style={{ padding: "3rem 0" }}>
                      <Row>
                        {cars_5 && (
                          <Container>
                            {this.state.cars_4.map((car4) => {
                              const {
                                name,
                                country,
                                time,
                                price,
                                img,
                                price_1,
                                currency,
                              } = car4;
                              return (
                                <div
                                  style={{
                                    borderBottom: "1px solid black",
                                  }}
                                >
                                  <Row>
                                    <Col xl={4} lg={4}>
                                      <div>
                                        <Figure>
                                          <Figure.Caption
                                            style={{ textAlign: "center" }}
                                          >
                                            {name}
                                          </Figure.Caption>
                                          <Figure.Image
                                            width={171}
                                            height={180}
                                            alt="171x180"
                                            src={img}
                                          />
                                          <Figure.Caption
                                            style={{ textAlign: "center" }}
                                          >
                                            {country}
                                          </Figure.Caption>
                                        </Figure>
                                      </div>
                                    </Col>

                                    <Col xl={8} lg={8}>
                                      <div className="cars-data-section-RentalCarsBookingDetailsForm-mainClass">
                                        <div className="cars-data-section-RentalCarsBookingDetailsForm">
                                          <div className="cars-data-section-textArea-RentalCarsBookingDetailsForm">
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              {" "}
                                              <i className="fa fa-eye"></i>{" "}
                                              <h6>Seats</h6>{" "}
                                            </p>
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              <i className="fa fa-car"></i>{" "}
                                              <h6>Doors</h6>
                                            </p>
                                          </div>
                                          <div className="cars-data-section-textArea-RentalCarsBookingDetailsForm">
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              {" "}
                                              <i className="fa fa-eye"></i>{" "}
                                              <h6>Air Conditioning</h6>
                                            </p>
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              {" "}
                                              <i className="fa fa-eye"></i>{" "}
                                              <h6>Gearbox</h6>{" "}
                                            </p>
                                          </div>

                                          <div className="cars-data-section-PriceArea-RentalCarsBookingDetailsForm">
                                            <h3 style={{ textAlign: "right" }}>
                                              {price} {currency}
                                            </h3>
                                            <div
                                              style={{
                                                fontFamily: "wow-regular",
                                              }}
                                            >
                                              Credit card Charge {price_1}{" "}
                                              {currency}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="cars-data-section-IncludeInPrice-RentalCarsBookingDetailsForm-mainClass">
                                          <h4>Included in the price:</h4>

                                          <Row>
                                            <Col>
                                              <ul class="list-unstyled-RentalCarsBookingDetailsForm list--chevron-RentalCarsBookingDetailsForm">
                                                {" "}
                                                <li class="mb-2 ">
                                                  {/* <i className="fas fa-angle-right"></i> */}
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm tac">
                                                    Free cancellation
                                                  </p>
                                                </li>
                                                <li class="mb-2">
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm">
                                                    Theft Protection
                                                  </p>
                                                </li>
                                                <li class="mb-2">
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm">
                                                    Airport Surcharge
                                                  </p>
                                                </li>
                                                <li class="mb-2">
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm">
                                                    Road Fee
                                                  </p>
                                                </li>
                                              </ul>{" "}
                                            </Col>

                                            <Col>
                                              <ul class="list-unstyled-RentalCarsBookingDetailsForm list--chevron-RentalCarsBookingDetailsForm">
                                                {" "}
                                                <li class="mb-2 ">
                                                  {/* <i className="fas fa-angle-right"></i> */}
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm tac">
                                                    Amendments
                                                  </p>
                                                </li>
                                                <li class="mb-2">
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm">
                                                    Local Taxes
                                                  </p>
                                                </li>
                                                <li class="mb-2">
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm">
                                                    Collision Damage Waiver
                                                  </p>
                                                </li>
                                                <li class="mb-2">
                                                  <p className="content-written-in-footer-of-wow-holiday-page-RentalCarsBookingDetailsForm">
                                                    Your rental includes
                                                    unlimited free kilometres.
                                                  </p>
                                                </li>
                                              </ul>{" "}
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </Container>
                        )}
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <AndroidOutlined />
                        Customer Reviews
                      </span>
                    }
                    key="2"
                  >
                   <CustomerReviews_RentalCars/>
                  </TabPane>

                  <TabPane
                    tab={
                      <span>
                        <FrownOutlined />
                        Important Information about your Rental
                      </span>
                    }
                    key="3"
                  >
                    <ImportantInfo_RentalCars />
                  </TabPane>
                </Tabs>

                {/* below section of tabs /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                <div class="cancellation-banner3-rentalcars-RentalCarsBookingDetailsForm">
                  <div class="cancellation-icon-rentalcars-RentalCarsBookingDetailsForm">
                    {/* <span class="free-cancellation-icon"></span> */}
                    <span class="far fa-edit cancellation-rentalcars-RentalCarsBookingDetailsForm "></span>
                  </div>
                  <div class="cancellation-message-rentalcars-RentalCarsBookingDetailsForm">
                    <strong class="cancellation-header-rentalcars-RentalCarsBookingDetailsForm">
                      Good news, Full Insurance is available
                    </strong>
                    <p class="cancellation-bodytext-rentalcars-RentalCarsBookingDetailsForm">
                      Cover any bumps or scrapes and have a hassle-free rental.
                      Book everything in one place quickly and easily.
                    </p>
                  </div>
                </div>

                {/* ///////////////////////////////////////////////////////////////////////////////////////// */}

                <div
                  className="supplier-location-RentalCarsBookingDetailsForm-mainClass"
                  style={{
                    border: "1px solid #000",
                    padding: "2rem 1.5rem",
                  }}
                >
                  {/* <h2>Supplier Location</h2> */}
                  <div class="supplier-info-RentalCarsBookingDetailsForm__column">
                    <div class="supplier-info-RentalCarsBookingDetailsForm__content supplier-info-RentalCarsBookingDetailsForm__content--alpha">
                      <div>
                        <h2 class="section__supplier-title-RentalCarsBookingDetailsForm__content">
                          Supplier Location
                        </h2>
                        <h3 class="supplier-info-RentalCarsBookingDetailsForm__content__heading">
                          Dubai International Airport
                        </h3>
                        <div class="supplier-information__address">
                          41, Cami Vell de Llucmajor, Mallorca, Spain - Balearic
                          Islands, 07007
                        </div>
                        <div class="supplier_info_area_row">
                          <h4 class="supplier-info-RentalCarsBookingDetailsForm__content__heading__title">
                            Opening hours
                          </h4>
                          <table>
                            <tbody>
                              <tr>
                                <td class="supplier-info-RentalCarsBookingDetailsForm__day">
                                  Mon - Sun
                                </td>
                                <td class="supplier-info-RentalCarsBookingDetailsForm__time">
                                  7am - 11pm
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="supplier_info_area_row">
                          <h4 class="supplier-info-RentalCarsBookingDetailsForm__content__heading__title">
                            Pick-up instructions
                          </h4>
                          <div className="supplier-info-exit-text-RentalCarsBookingDetailsForm">
                            Exit the airport, walk straight ahead on the moving
                            walkway towards the multi-story car park, then wait
                            at the minibus waiting area on the right for the
                            Wiber shuttle bus. Flight number mandatory.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* below section of tabs /////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                <div class="cancellation-banner3-rentalcars-RentalCarsBookingDetailsForm">
                  <div class="cancellation-icon-rentalcars-RentalCarsBookingDetailsForm">
                    {/* <span class="free-cancellation-icon"></span> */}
                    <span class="far fa-edit cancellation-rentalcars-RentalCarsBookingDetailsForm "></span>
                  </div>
                  <div class="cancellation-message-rentalcars-RentalCarsBookingDetailsForm">
                    <strong class="cancellation-header-rentalcars-RentalCarsBookingDetailsForm">
                      This car is costing you just US$15.03 – a real bargain…
                    </strong>
                    <p class="cancellation-bodytext-rentalcars-RentalCarsBookingDetailsForm">
                      At that time of year, the average Mini at Palma de
                      Mallorca Airport (PMI) costs US$50.85!
                    </p>
                  </div>
                </div>

                {/* ///////////////////////////////////////////////////////////////////////////////////////// */}

                {/* chooice Section ////////////////////////////////////////////////////////////////////////// */}

                <div
                  className="choices-section-RentalCarsBookingDetailsForm-mainClass"
                  style={{
                    border: "1px solid #666666",
                    padding: "1rem 1.5rem 1.2rem 1.5rem",
                  }}
                >
                  <div className="choices-section-heading-RentalCarsBookingDetailsForm">
                    <h2>Good Choice</h2>
                  </div>
                  <div className="media-section-mainCLass-RentalCarsBookingDetailsForm">
                    <Row>
                      <Col xl={4} lg={4}>
                        <Media>
                          <img
                            width={45}
                            height={45}
                            className="align-self-center mr-3"
                            src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/icons/fuel-policy.png"
                            alt="Generic placeholder"
                          />
                          <Media.Body>
                            <h5 style={{ lineHeight: "3.5" }}>
                              Popular Fuel Policy
                            </h5>
                          </Media.Body>
                        </Media>
                      </Col>

                      <Col xl={4} lg={4}>
                        <Media>
                          <img
                            width={45}
                            height={45}
                            className="align-self-center mr-3"
                            src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/icons/short-queue.png"
                            alt="Generic placeholder"
                          />
                          <Media.Body>
                            <h5 style={{ lineHeight: "3.5" }}>Short Queues</h5>
                          </Media.Body>
                        </Media>
                      </Col>

                      <Col xl={4} lg={4}>
                        <Media>
                          <img
                            width={45}
                            height={45}
                            className="align-self-center mr-3"
                            src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/icons/easy-to-find.png"
                            alt="Generic placeholder"
                          />
                          <Media.Body>
                            <h5 style={{ lineHeight: "3.5" }}>Easy To Find</h5>
                          </Media.Body>
                        </Media>
                      </Col>

                      <Col xl={6} lg={6}>
                        <Media>
                          <img
                            width={45}
                            height={45}
                            className="align-self-center mr-3"
                            src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/icons/service-24h.png"
                            alt="Generic placeholder"
                          />
                          <Media.Body>
                            <h5 style={{ lineHeight: "3.5" }}>
                              {/* Very Helpful Staff */}
                              24/7 Customer Support Available
                            </h5>
                          </Media.Body>
                        </Media>
                      </Col>

                      <Col xl={6} lg={6}>
                        <Media>
                          <img
                            width={45}
                            height={45}
                            className="align-self-center mr-3"
                            src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/icons/maintained-cars.png"
                            alt="Generic placeholder"
                          />
                          <Media.Body>
                            <h5 style={{ lineHeight: "3.5" }}>
                              Fully Well-maintained Cars
                            </h5>
                          </Media.Body>
                        </Media>
                      </Col>
                    </Row>
                  </div>
                </div>

                {/* ////////////////////////////////////////////////////////////////////////////// */}

                {/* Extras Payable now ////////////////////////////////////////////////////////////////////////// */}

                <div
                  className="ExtraCharges-section-RentalCarsBookingDetailsForm-mainClass"
                  style={{
                    border: "1px solid #000",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <div className="ExtraCharges-section-heading-RentalCarsBookingDetailsForm">
                    <h2>Extra Charges</h2>
                  </div>

                  <div className="ExtraCharges-insidePayable-section-RentalCarsBookingDetailsForm-mainClass">
                    <div className="ExtraCharges-insidePayable-heading-RentalCarsBookingDetailsForm">
                      <h4>Payable Now</h4>
                      <p>
                        If you order any of these extras, you'll pay for them
                        right now. The extras will be ready when you pick your
                        car up.
                      </p>
                    </div>

                    <div className="border-line-ExtraCharges-insidePayable-heading-RentalCarsBookingDetailsForm"></div>

                    <div className="ExtraCharges-insidePayable-insideSection-MainClass-RentalCarsBookingDetailsForm">
                      <Row>
                        <Col xl={6} lg={6}>
                          <Media>
                            <img
                              width={64}
                              height={64}
                              className="align-self-start mr-3"
                              src="IMAGES/circle-credit.png"
                              alt="Generic placeholder"
                            />
                            <Media.Body>
                              <h5>
                                Additional Driver{" "}
                                <span className="price-mentioned-ExtraCharges-insidePayable-insideSection-MainClass-RentalCarsBookingDetailsForm">
                                  US$9.66 each per day
                                </span>
                              </h5>
                            </Media.Body>
                          </Media>

                          <Media>
                            <img
                              width={64}
                              height={64}
                              className="align-self-start mr-3"
                              src="IMAGES/circle-credit.png"
                              alt="Generic placeholder"
                            />
                            <Media.Body>
                              <h5>
                                Seat For Child{" "}
                                <span className="price-mentioned-ExtraCharges-insidePayable-insideSection-MainClass-RentalCarsBookingDetailsForm">
                                  US$9.66 each per day
                                </span>
                              </h5>
                            </Media.Body>
                          </Media>

                          <Media>
                            <img
                              width={64}
                              height={64}
                              className="align-self-start mr-3"
                              src="IMAGES/circle-credit.png"
                              alt="Generic placeholder"
                            />
                            <Media.Body>
                              <h5>
                                Booster Seat{" "}
                                <span className="price-mentioned-ExtraCharges-insidePayable-insideSection-MainClass-RentalCarsBookingDetailsForm">
                                  US$9.66 each per day
                                </span>
                              </h5>
                            </Media.Body>
                          </Media>
                        </Col>

                        <Col xl={6} lg={6}>
                          <div className="price-mentioned-ExtraCharges-insidePayable-rightSelectionSection-MainClass-RentalCarsBookingDetailsForm">
                            <div className="price-mentioned-ExtraCharges-insidePayable-rightSelectionSection-width-MainClass-RentalCarsBookingDetailsForm">
                              <Form.Group controlId="formBasicCheckbox">
                                <Row>
                                  <Col>
                                    <Form.Control as="select" size="sm" custom>
                                      <option>0</option>
                                      <option>1</option>
                                      <option>2</option>
                                      <option>3</option>
                                    </Form.Control>
                                  </Col>
                                  <Col>
                                    <Form.Check type="checkbox" />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="price-mentioned-ExtraCharges-insidePayable-rightSelectionSection-MainClass-RentalCarsBookingDetailsForm">
                            <div className="price-mentioned-ExtraCharges-insidePayable-rightSelectionSection-width-MainClass-RentalCarsBookingDetailsForm">
                              <Form.Group controlId="formBasicCheckbox">
                                <Row>
                                  <Col>
                                    <Form.Control as="select" size="sm" custom>
                                      <option>0</option>
                                      <option>1</option>
                                      <option>2</option>
                                      <option>3</option>
                                    </Form.Control>
                                  </Col>
                                  <Col>
                                    <Form.Check type="checkbox" />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="price-mentioned-ExtraCharges-insidePayable-rightSelectionSection-MainClass-RentalCarsBookingDetailsForm">
                            <div className="price-mentioned-ExtraCharges-insidePayable-rightSelectionSection-width-MainClass-RentalCarsBookingDetailsForm">
                              <Form.Group controlId="formBasicCheckbox">
                                <Row>
                                  <Col>
                                    <Form.Control as="select" size="sm" custom>
                                      <option>0</option>
                                      <option>1</option>
                                      <option>2</option>
                                    </Form.Control>
                                  </Col>
                                  <Col>
                                    <Form.Check type="checkbox" />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                {/* ////////////////////////////////////////////////////////////////////////////// */}

                {/* Extras Payable now ////////////////////////////////////////////////////////////////////////// */}

                <div
                  className="Fullprotection-section-RentalCarsBookingDetailsForm-mainClass"
                  style={{
                    border: "1px solid #000",
                    padding: "1rem 1.5rem",
                  }}
                >
                  <div className="Fullprotection-section-heading-RentalCarsBookingDetailsForm">
                    <h2>
                      Full Protection{" "}
                      <span className="Fullprotection-section-headingText-RentalCarsBookingDetailsForm">
                        ...for complete peace of mind
                      </span>
                    </h2>

                    <p>
                      At the counter, the car hire company will block a deposit
                      on your credit card. You could lose your whole deposit if
                      the car is damaged or stolen, but as long as you have Full
                      Protection, RentalCover.com will refund you! Why pay more
                      for cover at the car hire counter?
                    </p>

                    <p>
                      T&Cs and standard exclusions apply. Please read:{" "}
                      <Link>Policy Terms</Link>
                    </p>
                  </div>

                  <div className="Fullprotection-section-whatsCoveredCol-RentalCarsBookingDetailsForm-mainClass">
                    <Row style={{borderBottom:'1px solid #666666'}}>
                      <Col xl={4} lg={4} xs={4}>
                        <h6>What is covered:</h6>
                      </Col>


                      <Col xl={4} lg={4} xs={4}>
                        <div className="Noprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                          No Protection{" "}
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >
                            <i class="fas fa-exclamation-circle"></i>
                          </OverlayTrigger>
                        </div>
                      </Col>

                      <Col xl={4} lg={4} xs={4}>
                        <div className="Fullprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                          <h5>Full Protection</h5>
                          <p>Total cover price US$51.86</p>
                        </div>
                      </Col>
                    </Row>

                    <Row style={{borderBottom:'1px solid #666666', borderBottomStyle:'dotted'}}>
                    <Col xl={4} lg={4} xs={4}>
                        <div>
                        <Collapse
          // defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition={expandIconPosition2}
          ghost
        >
          <Panel header="This is panel header 1" key="1">
            <div>{text}</div>
          </Panel>
        </Collapse>
                        </div>
                      </Col>


                      <Col xl={4} lg={4} xs={4}>
                        <div className="Noprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>{" "}
                        </div>
                      </Col>

                      <Col xl={4} lg={4} xs={4}>
                        <div className="Fullprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                      </Col>
                    </Row>


                    <Row style={{borderBottom:'1px solid #666666', borderBottomStyle:'dotted'}}>
                    <Col xl={4} lg={4}>
                        <div>
                        <Collapse
          // defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition={expandIconPosition2}
          ghost
        >
          <Panel header="This is panel header 1" key="1">
            <div>{text}</div>
          </Panel>
        </Collapse>
                        </div>
                      </Col>


                      <Col xl={4} lg={4}>
                        <div className="Noprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>{" "}
                        </div>
                      </Col>

                      <Col xl={4} lg={4}>
                        <div className="Fullprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                      </Col>
                    </Row>




                    <Row style={{borderBottom:'1px solid #666666', borderBottomStyle:'dotted'}}>
                    <Col xl={4} lg={4}>
                        <div>
                        <Collapse
          // defaultActiveKey={['1']}
          onChange={callback}
          expandIconPosition={expandIconPosition2}
          ghost
        >
          <Panel header="This is panel header 1" key="1">
            <div>{text}</div>
          </Panel>
        </Collapse>
                        </div>
                      </Col>
 

                      <Col xl={4} lg={4}>
                        <div className="Noprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>{" "}
                        </div>
                      </Col>

                      <Col xl={4} lg={4}>
                        <div className="Fullprotection-section-heading-RentalCarsBookingDetailsForm-mainClass">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                      </Col>
                    </Row>


                  </div>


                </div>

                {/* ////////////////////////////////////////////////////////////////////////////// */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Stepper1_Additional_ChargesDetails;
