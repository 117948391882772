import React, { Component } from "react";
// import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Button,
  Card,
  Row,
  Slider,
  Col,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
  NavLink
} from "react-router-dom";
import "./StepperPagesStyle.scss";

class Hire_Form_Stepper1 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      adultSeat: 0,
      childSeat: 0,
      flyingTo: "",
      flyingFrom: "",
      travelClass: "",
      showHidePassengerFlyingFrom: false,
      showHidePassengerFlyingTo: false,
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "showHidePassengerFlyingFrom":
        this.setState({
            showHidePassengerFlyingFrom: !this.state.showHidePassengerFlyingFrom,
        //   showHidePassengerFlyingFrom: true,
        });
        break;
        case "showHidePassengerFlyingTo":
        this.setState({
            showHidePassengerFlyingTo: !this.state.showHidePassengerFlyingTo,
        //   showHidePassengerFlyingFrom: true,
        });
        break;
      default:
      // null;
    }
  }

  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  //   changeVisibility = () => {
  //     this.setState({
  //       visible: !this.state.visible,
  //     });
  //     window.scrollTo({ top: 700 });
  //   };
  
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  addAdultSeat = () => {
    this.setState((prevState) => ({
      adultSeat: parseInt(prevState.adultSeat) + 1,
    }));
  };
  removeAdultSeat = () => {
    this.setState((prevState) => ({
      adultSeat: parseInt(prevState.adultSeat) - 1,
    }));
  };
  resetAdultSeat = () => {
    this.setState({
      adultSeat: 0,
    });
  };
  addChildSeat = () => {
    this.setState((prevState) => ({
      childSeat: parseInt(prevState.childSeat) + 1,
    }));
  };
  removeChildSeat = () => {
    this.setState((prevState) => ({
      childSeat: parseInt(prevState.childSeat) - 1,
    }));
  };
  resetChildSeat = () => {
    this.setState({
      childSeat: 0,
    });
  };

  flightsData_1 = [
    {
      flight_name: "DUB, Dublin Airport",
      currency: "Dirham",
      city_name: "Dublin",
      price: 128.67,
      carrier: "KL",
      img:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T09:35+02:00 AMS 2017-05-30T11:15+02:00"],
    },
    {
      flight_name: "Lyon-Bron Airport",
      city_name: "Lyon",
      currency: "ZAR",
      price: 151.41,
      carrier: "BA",
      img:
        "https://blog.alaskaair.com/wp-content/uploads/2016/01/logo-for-blog1.jpg",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
    {
      flight_name: "Marseille Provence Airport",
      city_name: "Marseille",
      currency: "Dirham",
      price: 138.7,
      carrier: "AF",
      img:
        "https://cf.bstatic.com/xdata/images/hotel/square200/234576175.webp?k=976c2aec88481bafbfd91f8af97fdfc9459b373c6fd4d4f8a5ddd9393f3d76c3&o=",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T12:35+02:00 AMS 2017-05-30T13:50+02:00"],
    },
    {
      flight_name: "Charles de Gaulle International",
      city_name: "Charles de Gaulle",
      currency: "Dirham",
      price: 151.41,
      carrier: "BA",
      img:
        "https://cf.bstatic.com/xdata/images/hotel/square200/255542055.webp?k=2b318819e32d8a85bdd3d062fb8c80f5311d2c213b2b46a37205b929d9c2c3e3&o=",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
    {
      flight_name: "Frankfurt am Main International",
      city_name: "Frankfurt",
      currency: "Dirham",
      price: 174.7,
      carrier: "KL",
      img: "IMAGES/city-images/book_holiday/flights/dublin.jpg",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T18:35+02:00 AMS 2017-05-30T19:50+02:00"],
    },
    {
      flight_name: "Paris-Orly Airport",
      city_name: "Paris",
      currency: "Dirham",
      price: 204.7,
      carrier: "AF",
      img:
        "https://cf.bstatic.com/xdata/images/hotel/square200/183065428.webp?k=23123f8db7249214d0796fdd0b446afa52e6ba89b5e9a5de1bb6899b00b876ff&o=",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
    {
      flight_name: "Le Mans-Arnage Airport",
      city_name: "Le Mans-Arnage",
      currency: "Dirham",
      price: 204.7,
      carrier: "AF",
      img: "https://wowholiday-fffa4.web.app/IMAGES/wow-holiday.png",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
  ];

  carrierData = {
    AF: "Air France",
    KL: "KLM Royal Dutch Airlines",
    BA: "British Airways",
  };

  flight_name_shown = [
      {
        name: "DUB, Dublin Airport",
      },
  ];

  flight_name_shown_DropOff = [
    {
      name: "London City Airport",
    },
];

  airportsData_1 = [
    {
      name: "DUB, Dublin Airport",
      city: "Dubai",
      country: "UAE",
      IATA: "UAE",
    },
    {
      name: "Vichy-Charmeil Airport",
      city: "Vichy",
      country: "France",
      IATA: "VHY",
    },
    {
      name: "Lyon-Bron Airport",
      city: "Lyon",
      country: "France",
      IATA: "LYN",
    },
    {
      name: "Cannes-Mandelieu Airport",
      city: "Cannes",
      country: "France",
      IATA: "CEQ",
    },
    {
      name: "Marseille Provence Airport",
      city: "Marseille",
      country: "France",
      IATA: "MRS",
    },
    {
      name: "Charles de Gaulle International",
      city: "Paris",
      country: "France",
      IATA: "CDG",
    },
    {
      name: "Toussus-le-Noble Airport",
      city: "Toussous-le-noble",
      country: "France",
      IATA: "TNF",
    },
    {
      name: "Paris-Orly Airport",
      city: "Paris",
      country: "France",
      IATA: "ORY",
    },
    {
      name: "Le Mans-Arnage Airport",
      city: "Le Mans",
      country: "France",
      IATA: "LME",
    },
    {
      name: "Nantes Atlantique Airport",
      city: "Nantes",
      country: "France",
      IATA: "NTE",
    },
    {
      name: "Nancy-Essey Airport",
      city: "Nancy",
      country: "France",
      IATA: "ENC",
    },
    {
      name: "Frankfurt am Main International",
      city: "Frankfurt",
      country: "Germany",
      IATA: "FRA",
    },
    {
      name: "Hamburg Airport",
      city: "Hamburg",
      country: "Germany",
      IATA: "HAM",
    },
    {
      name: "Cologne Bonn Airport",
      city: "Cologne",
      country: "Germany",
      IATA: "CGN",
    },
    {
      name: "Munich International Airport",
      city: "Munich",
      country: "Germany",
      IATA: "MUC",
    },
    {
      name: "Stuttgart Airport",
      city: "Stuttgart",
      country: "Germany",
      IATA: "STR",
    },
    {
      name: "Berlin-Tegel International Airport",
      city: "Berlin",
      country: "Germany",
      IATA: "TXL",
    },
    {
      name: "Hannover Airport",
      city: "Hannover",
      country: "Germany",
      IATA: "HAJ",
    },
    { name: "Bremen Airport", city: "Bremen", country: "Germany", IATA: "BRE" },
    {
      name: "Frankfurt-Hahn Airport",
      city: "Hahn",
      country: "Germany",
      IATA: "HHN",
    },
    {
      name: "Dortmund Airport",
      city: "Dortmund",
      country: "Germany",
      IATA: "DTM",
    },
    { name: "Cork Airport", city: "Cork", country: "Ireland", IATA: "ORK" },
    { name: "Galway Airport", city: "Galway", country: "Ireland", IATA: "GWY" },
    { name: "Dublin Airport", city: "Dublin", country: "Ireland", IATA: "DUB" },
    {
      name: "Waterford Airport",
      city: "Waterford",
      country: "Ireland",
      IATA: "WAT",
    },
    {
      name: "Amsterdam Airport Schiphol",
      city: "Amsterdam",
      country: "Netherlands",
      IATA: "AMS",
    },
    {
      name: "Maastricht Aachen Airport",
      city: "Maastricht",
      country: "Netherlands",
      IATA: "MST",
    },
    {
      name: "Eindhoven Airport",
      city: "Eindhoven",
      country: "Netherlands",
      IATA: "EIN",
    },
    {
      name: "Rotterdam The Hague Airport",
      city: "Rotterdam",
      country: "Netherlands",
      IATA: "RTM",
    },
    {
      name: "Belfast International Airport",
      city: "Belfast",
      country: "United Kingdom",
      IATA: "BFS",
    },
    {
      name: "Manchester Airport",
      city: "Manchester",
      country: "United Kingdom",
      IATA: "MAN",
    },
    {
      name: "Southampton Airport",
      city: "Southampton",
      country: "United Kingdom",
      IATA: "SOU",
    },
    {
      name: "London Heathrow Airport",
      city: "London",
      country: "United Kingdom",
      IATA: "LHR",
    },
    {
      name: "Blackpool International Airport",
      city: "Blackpool",
      country: "United Kingdom",
      IATA: "BLK",
    },
    {
      name: "Newcastle Airport",
      city: "Newcastle",
      country: "United Kingdom",
      IATA: "NCL",
    },
    {
      name: "London Stansted Airport",
      city: "London",
      country: "United Kingdom",
      IATA: "STN",
    },
    {
      name: "Miami International Airport",
      city: "Miami",
      country: "United States",
      IATA: "MIA",
    },
    {
      name: "John F Kennedy International Airport",
      city: "New York",
      country: "United States",
      IATA: "JFK",
    },
    {
      name: "Piedmont Triad International Airport",
      city: "Greensboro",
      country: "United States",
      IATA: "GSO",
    },
    {
      name: "Wings Field",
      city: "Philadelphia",
      country: "United States",
      IATA: "BBX",
    },
    {
      name: "Hardwick Field",
      city: "Cleveland",
      country: "United States",
      IATA: "HDI",
    },
    {
      name: "Warren Field",
      city: "Washington",
      country: "United States",
      IATA: "OCW",
    },
  ];

  render() {
    const scrollTorentalcarsform = () => window.scrollTo({ top: 750 });
    const {
        showHidePassengerFlyingFrom,
        showHidePassengerFlyingTo
      } = this.state;
    return (
      <div>
        <div className="book-holiday-Stepper1">
          <div id="booking" className="section">
            <div className="section-center">
              <div className="container">
                <div className="row">
                  <div className="booking-form">
                  <div class="rentalcars-form-top-heading">
                      <div>
                        <h1
                          class="ui-bui-largest gb-u-m0"
                          data-test="tx-hero-title__title"
                        >
                          Find Your Car
                        </h1>
                        <p
                          class="ui-bui-medium gb-u-m0"
                          data-test="tx-hero-title__subtitle"
                        >
                          Pick Up 
                          <span
                          style={{
                            //   backgroundColor:'rgba(0, 102, 255, 0.363)',
                           padding:'.4rem',
                           color:'rgba(0, 102, 255, 0.563)',
                           cursor:'pointer'
                        }}
                        //   variant="outline-secondary"
                          variant="link"
                          onClick={() =>
                            this.hideComponent(
                              "showHidePassengerFlyingFrom"
                            )
                          }
                        >
                          Change
                        </span>
                        </p>

                        <div
                          class="ui-bui-largest gb-u-m0"
                          data-test="tx-hero-title__title"
                        >
                          {this.flight_name_shown.map((flight_shown) => {
                                const { name } = flight_shown;
                                return <h3>{name}</h3>;
                              })}
                        </div>
                      </div>
                    </div>
                    <form>
                      <Row>
                      {showHidePassengerFlyingFrom && (
                        <Col xl={12} lg={12} md={6} sm={12} xs={12}>
                          <div className="form-group">
                            <span className="form-label">Flying From</span>
                            <Form.Control
                              as="select"
                              name="flyingTo"
                              onChange={this.changeHandler}
                              required
                            >
                              <option value="">Select destination</option>
                              {this.airportsData_1.map((airport) => {
                                const { name, city, country } = airport;
                                return <option value={name}>{name}</option>;
                              })}
                            </Form.Control>
                          </div>
                        </Col>
                      )}
                      </Row>
                      <Row>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <div className="form-group">
                            <span className="form-label">Departing</span>
                            <Form.Control
                              className="form-control"
                              type="date"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <div class="rentalcars-form-top-heading">
                      <div>
                        <p
                          class="ui-bui-medium gb-u-m0"
                          data-test="tx-hero-title__subtitle"
                        >
                         Drop Off
                          <span
                          style={{
                            //   backgroundColor:'rgba(0, 102, 255, 0.363)',
                           padding:'.4rem',
                           color:'rgba(0, 102, 255, 0.563)',
                           cursor:'pointer'
                        }}
                        //   variant="outline-secondary"
                          variant="link"
                          onClick={() =>
                            this.hideComponent(
                              "showHidePassengerFlyingTo"
                            )
                          }
                        >
                          Change
                        </span>
                        </p>
                        <div
                          class="ui-bui-largest gb-u-m0"
                          data-test="tx-hero-title__title"
                        >
                          {this.flight_name_shown_DropOff.map((flight_shown2) => {
                                const { name } = flight_shown2;
                                return <h3>{name}</h3>;
                              })}
                        </div>
                      </div>
                    </div>

                    {showHidePassengerFlyingTo && (
                        <Col xl={12} lg={12} md={6} sm={12} xs={12}>
                          <div className="form-group">
                            <span className="form-label">Flying To</span>
                            <Form.Control
                              as="select"
                              name="flyingTo"
                              onChange={this.changeHandler}
                              required
                            >
                              <option value="">Select destination</option>
                              {this.airportsData_1.map((airport) => {
                                const { name, city, country } = airport;
                                return <option value={name}>{name}</option>;
                              })}
                            </Form.Control>
                          </div>
                        </Col>
                      )}
                      <Row>
                      <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                        >
                          <div className="form-group">
                            <span className="form-label">Departing</span>
                            <Form.Control
                              className="form-control"
                              name="DepartingDroppoff"
                              type="date"
                              required
                            />
                          </div>
                        </Col>
                        <div className="col-md-12">
                          <div className="form-btn">
                            {/* this.state.flyingFrom===""&&this.state.flyingTo===""&& */}
                              <Link
                                type="submit"
                                className="submit-btn-search-flights submit-link"
                                // onClick={scrollTorentalcarsform}
                              >
                                Search
                              </Link>
                          </div>
                        </div>
                      </Row>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}
export default Hire_Form_Stepper1;
