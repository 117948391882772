import React, { Component } from "react";


class Otac extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

    

    
<div >

<div class="otac-email-wow-header">
	<center>
		<img class="wow-image-in-otac-email"   src="IMAGES/wowboard.png"/>
	</center></div>
  
    
 

     
<div class="container">

      <div class="text-written-in-otac-email">
		<p class="text" >
			OTAC Email
		</p>
		<p class="text" >
			Dear Muhammad Tahir,
		</p>

        <p class="text" >
			The part of your OTAC(One-Time Authorization Code is)
		</p>

        
            <div class="code-in-otac-email underline-in-otac-code ">
                9040
            </div>
        

        <p class="text" >
        Please enter the OTAC in the relevant fields to successfully Log-In to your WOW's Leader Account. 
		</p>


		<p class="text" >
        In order to protect your account from misuse/fraudulent activity, 
        never share this email or OTAC with anyone. For any queries or 
        further details, please contact your WOW representative or
         call us at given contact numbers.<br/>
           Regards,       <br/>
           WOW &nbsp; (ETS) Team.

		</p>
		
		<p class="text" >
        This email is confidential. If you are not the addressee
         you may not copy, forward, disclose or use any part of it.
          If you have received this email in error, please delete
           all copies from your system.
		</p>

        <p class="text" >
        Internet communications cannot be guaranteed to be timely,
         secure, error or virus-free. We do not accept liability
          for any errors or omissions.
		</p>

        <p class="text" >
        Please do not reply to this message via e-mail. 
        This address is automat-ed, unattended, and cannot
         help with questions or requests.
		</p>
        <br/>
	
		<p   class="text-thank-you-otac" >
			THANK YOU 
		</p>

	</div></div>
	<br/>
    

	<div class="container">

	<div class="statement-header-line-in-otac-email"> </div>

   




	<div class="otac-email-footer-content">
  <div class="infoCons-in-otac-email">
     

       {/* <!-- row  --> */}


      <div class="horizontalRow-in-otac-email">

        <div class="otac-email-ones otac-email-inside-texts"><div class="itemCons-in-otac-email">
             <div class="iconCon-in-otac-email">
              <a> <img class="footer-baseline-image-otac-email" src="IMAGES/calllogo.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-otac-email">
               Phone: +971 45 217500
             </span>
            </div></div>


        <div class="otac-email-twos otac-email-inside-texts"><div class="itemCons-in-otac-email">
             <div class="iconCon-in-otac-email">
             <a> <img  class="footer-baseline-image-otac-email" src="IMAGES/msg.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-otac-email">
               info@wowets.com
             </span>
            </div></div>

        <div class="otac-email-threes otac-email-inside-texts"><div class="itemCons-in-otac-email">
             <div class="iconCon-in-otac-email">
             <a> <img class="footer-baseline-image-otac-email" src="IMAGES/browser.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-otac-email">
               wowets.com
             </span>
            </div></div>


        <div class="otac-email-four otac-email-inside-texts"><div class="itemCons-in-otac-email">
             <div class="iconCon-in-otac-email">
             <a> <img class="footer-baseline-image-otac-email" src="IMAGES/location.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-otac-email">
               WOW Electronic Transport Services, <br/>
                34th floor Burj Al Salam Tower, Trade <br/>
                Centrel, Sheikh Zayed Road Dubai, UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div>





</div>

      


      
  


    




    

       
        
       

    
   
   

    


 
     
    
    
    );
  }
}
export default Otac;