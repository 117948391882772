import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Adminsignuppractice/HeaderBottomstyle.scss";
import {
  Form,
  ButtonToolbar,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Col,
  Row,
  ProgressBar,
  Container,
  InputGroup,
  Alert,
  Toast,
} from "react-bootstrap";
class CookiesNotify extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: true,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  componentDidMount() {
    var user = this.getCookie("username1");

    if (!(user === "Guest1")) {
      setTimeout(() => this.handleShow(), 3000);
    }
  }
  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };
  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  handleCookies = () => {
    this.setState({ show: false });
    let user = "Guest1";
    this.setCookie("username1", user, 30);
  };
  render() {
    return (
      <div
        aria-live="polite"
        aria-atomic="true"
        className={this.state.show ? "showCookies" : "hideCookies"}
      >
        <div className="toast-postion-of-notify">
          <Toast /* onClose={this.handleClose} */
            style={{ backgroundColor: "#f5f5f5" }}
            show={this.state.show}
            /* delay={30000}  autohide */
          >
            <Toast.Header
              style={{ backgroundColor: "#f5f5f5" }}
              closeButton={false}
            >
              <img
                src="IMAGES/wow-holiday-192.png"
                className="rounded mr-2"
                alt=""
                style={{ width: "25px" }}
              />
              <strong className="mr-auto website-heading-notify">
                Wow Worldwide Holiday
              </strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body className="website-text-notify">
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                  This website uses cookies to make the website work properly
                  and to provide the most relevant products and services to our
                  members and site visitors.
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className="adjust-the-cookies-policies-button"
                >
                  <center>
                    <p>
                      {" "}
                      <Button
                        onClick={this.handleCookies}
                        style={{
                          borderColor: "#C0B596",
                          fontSize: "12px",
                          backgroundColor: "#f87370",
                          outline: "none",
                        }}
                      >
                        Accept & close
                      </Button>
                    </p>
                  </center>
                </Col>
              </Row>
            </Toast.Body>
          </Toast>
        </div>
      </div>
    );
  }
}
export default CookiesNotify;
