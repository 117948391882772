import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";




import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  NavLink,
  Route,
  browserhistory
} from "react-router-dom";
// import Termsconditions from "../Trustsafetywowholiday/trustsafety";

class Termsconditions extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false
    };
  }



  headings1termsconditions = () => {
    document.getElementById("headings1-termsconditions").style.display = 'block';
    document.getElementById("headings2-termsconditions").style.display = "none";
    document.getElementById("headings3-termsconditions").style.display = 'none';
    document.getElementById("headings4-termsconditions").style.display = "none";
    document.getElementById("headings5-termsconditions").style.display = 'none';
    document.getElementById("headings6-termsconditions").style.display = "none";



    document.getElementById("margin-right-outline1").style.display = "block";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";

    document.getElementById("mySidenav-termscondition").style.width = "0px";
    
  };


  headings2termsconditions = () => {
    document.getElementById("headings1-termsconditions").style.display = 'none';
    document.getElementById("headings2-termsconditions").style.display = "block";
    document.getElementById("headings3-termsconditions").style.display = 'none';
    document.getElementById("headings4-termsconditions").style.display = "none";
    document.getElementById("headings5-termsconditions").style.display = 'none';
    document.getElementById("headings6-termsconditions").style.display = "none";



    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "block";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    
    document.getElementById("mySidenav-termscondition").style.width = "0px";
  };
  

  headings3termsconditions = () => {
    document.getElementById("headings1-termsconditions").style.display = 'none';
    document.getElementById("headings2-termsconditions").style.display = "none";
    document.getElementById("headings3-termsconditions").style.display = 'block';
    document.getElementById("headings4-termsconditions").style.display = "none";
    document.getElementById("headings5-termsconditions").style.display = 'none';
    document.getElementById("headings6-termsconditions").style.display = "none";



    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "block";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";

    document.getElementById("mySidenav-termscondition").style.width = "0px";
  };



  headings4termsconditions = () => {
    document.getElementById("headings1-termsconditions").style.display = 'none';
    document.getElementById("headings2-termsconditions").style.display = "none";
    document.getElementById("headings3-termsconditions").style.display = 'none';
    document.getElementById("headings4-termsconditions").style.display = "block";
    document.getElementById("headings5-termsconditions").style.display = 'none';
    document.getElementById("headings6-termsconditions").style.display = "none";



    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "block";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";

    document.getElementById("mySidenav-termscondition").style.width = "0px";
  };


  headings5termsconditions = () => {
    document.getElementById("headings1-termsconditions").style.display = 'none';
    document.getElementById("headings2-termsconditions").style.display = "none";
    document.getElementById("headings3-termsconditions").style.display = 'none';
    document.getElementById("headings4-termsconditions").style.display = "none";
    document.getElementById("headings5-termsconditions").style.display = 'block';
    document.getElementById("headings6-termsconditions").style.display = "none";



    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "block";
    document.getElementById("margin-right-outline6").style.display = "none";

    document.getElementById("mySidenav-termscondition").style.width = "0px";
  };


  headings6termsconditions = () => {
    document.getElementById("headings1-termsconditions").style.display = 'none';
    document.getElementById("headings2-termsconditions").style.display = "none";
    document.getElementById("headings3-termsconditions").style.display = 'none';
    document.getElementById("headings4-termsconditions").style.display = "none";
    document.getElementById("headings5-termsconditions").style.display = 'none';
    document.getElementById("headings6-termsconditions").style.display = "block";



    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "block";

    document.getElementById("mySidenav-termscondition").style.width = "0px";
  };


  openNavtermscondition=() => {
    document.getElementById("mySidenav-termscondition").style.width = "300px";
    document.getElementById("close-button-termscondition").style.display = "";
    
  }


  closeNavtermscondition = () => {
    document.getElementById("mySidenav-termscondition").style.width = "0px";
    document.getElementById("close-button-termscondition").style.display = "none";
  }



  

 

 
  render() {
    return (
      <div>






        {/* <div className="holiday-inspiration-wow-holiday-our-container">
          <div class="holiday-inspiration-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-inspiration-wow-holiday-home-our-story">
                <div class="holiday-inspiration-wow-holiday-our-team-heading">
                  Holiday inspiration
                </div>
                <div class="holiday-inspiration-wow-holiday-our-team-heading-below-text">
                  If you need ideas for where to go on your next trip, let us
                  inspire you with our list of the best holiday destinations for
                  every type of vacation.
                </div>
                <div class="holiday-credit-wow-holiday-our-team-heading">
                  <Button
                    variant="outline-light"
                    className="holiday-credit-wow-holiday-our-team-button"
                  >
                    Check My Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        




                      












<div class="privacy_and_policy1">
<Container>
<center>
<h3 class="privacy_and_policy">
WOW Worldwide Holiday Terms and Conditions</h3></center>
<div class="onPCMode">
    <div class="jss8">






<Row>
<Col xl={3} lg={3} md={3} sm={3} className="muitab-in-pc-view-terms-conditions">
<div className="MuiTabs-root jss9 MuiTabs-vertical" aria-label="Vertical tabs example">

<div className="MuiTabs-scrollable" style={{width: '99px', height: '99px', position: 'absolute', top: '-9999px', overflow: 'scroll'}}>
</div>

<div className="MuiTabs-scroller MuiTabs-scrollable" style={{marginBottom: '0px'}}>
<div className="MuiTabs-flexContainer MuiTabs-flexContainerVertical" role="tablist">
<button 
onClick={this.headings1termsconditions}
activeClassName="active-privacy-policy"
 className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit yo terms-and-conditions-tab-name Mui-selected" tabindex="0" type="button" role="tab" aria-selected="true" id="vertical-tab-0" aria-controls="vertical-tabpanel-0">
   <span class="MuiTab-wrapper">
   <span>
   <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 1 </span></span>
    <span class="MuiTouchRipple-root">
</span>
</button>


<button onClick={this.headings2termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-1" aria-controls="vertical-tabpanel-1">
<span className="MuiTab-wrapper"> <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading"></i> Headings 2 </span></span>
<span className="MuiTouchRipple-root"></span>
</button>



<button onClick={this.headings3termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-2" aria-controls="vertical-tabpanel-2">
<span className="MuiTab-wrapper"> <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 3 </span></span>

<span className="MuiTouchRipple-root"></span>
</button>

<button onClick={this.headings4termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-3" aria-controls="vertical-tabpanel-3">
<span className="MuiTab-wrapper"> <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading"></i> Headings 4 </span></span>
<span className="MuiTouchRipple-root"></span></button>



<button onClick={this.headings5termsconditions} className="MuiButtonBase-root MuiTab-root on-focus-button-termcondition MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-4" aria-controls="vertical-tabpanel-4">
    <span class="MuiTab-wrapper"> <span>
    <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 5 </span></span><span class="MuiTouchRipple-root"></span>
</button>



<button onClick={this.headings6termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" 
tabindex="0" type="button"
 role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
     <span class="MuiTab-wrapper"> <span>
     <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 6 </span></span>
<span className="MuiTouchRipple-root"></span>
</button>
</div>


<span style={{top:'0'}} id="margin-right-outline1" className="jss34-termsconditions jss36-termsconditions MuiTabs-indicator jss37-termsconditions" >
</span>

<span style={{top:'48px', display:'none'}} id="margin-right-outline2" className="jss34-termsconditions jss36-termsconditions MuiTabs-indicator jss37-termsconditions" >
</span>

<span style={{top:'96px', display:'none'}} id="margin-right-outline3" className="jss34-termsconditions jss36-termsconditions MuiTabs-indicator jss37-termsconditions" >
</span>

<span style={{top:'144px', display:'none'}} id="margin-right-outline4" className="jss34-termsconditions jss36-termsconditions MuiTabs-indicator jss37-termsconditions" >
</span>

<span style={{top:'192px', display:'none'}} id="margin-right-outline5" className="jss34-termsconditions jss36-termsconditions MuiTabs-indicator jss37-termsconditions" >
</span>

<span style={{top:'240px', display:'none'}} id="margin-right-outline6" className="jss34-termsconditions jss36-termsconditions MuiTabs-indicator jss37-termsconditions" >
</span>
</div>
</div>




</Col>
















<Container className="sidenav-termscondition-mobile-view termscondition-menu-button-border">
                          <div id="mySidenav-termscondition" className="sidenav-termscondition" >
  <a href="javascript:void(0)"
   className="closebtn-termscondition" 
   id="close-button-termscondition"
   onClick={this.closeNavtermscondition}>&times;</a>
  {/* <a onClick={this.headings1privacypolicy}>Privacy policy</a>

  <a onClick={this.headings2privacypolicy}> Cookie policy</a>
  <a href="#">Clients</a>
  <a href="#">Contact</a> */}

<button 
onClick={this.headings1termsconditions}
activeClassName="active-privacy-policy"
 className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit yo terms-and-conditions-tab-name Mui-selected" tabindex="0" type="button" role="tab" aria-selected="true" id="vertical-tab-0" aria-controls="vertical-tabpanel-0">
   <span class="MuiTab-wrapper">
   <span>
   <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 1 </span></span>
    <span class="MuiTouchRipple-root">
</span>
</button>


<button onClick={this.headings2termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-1" aria-controls="vertical-tabpanel-1">
<span className="MuiTab-wrapper"> <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading"></i> Headings 2 </span></span>
<span className="MuiTouchRipple-root"></span>
</button>



<button onClick={this.headings3termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-2" aria-controls="vertical-tabpanel-2">
<span className="MuiTab-wrapper"> <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 3 </span></span>

<span className="MuiTouchRipple-root"></span>
</button>

<button onClick={this.headings4termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-3" aria-controls="vertical-tabpanel-3">
<span className="MuiTab-wrapper"> <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading"></i> Headings 4 </span></span>
<span className="MuiTouchRipple-root"></span></button>



<button onClick={this.headings5termsconditions} className="MuiButtonBase-root MuiTab-root on-focus-button-termcondition MuiTab-textColorInherit terms-and-conditions-tab-name" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-4" aria-controls="vertical-tabpanel-4">
    <span class="MuiTab-wrapper"> <span>
    <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 5 </span></span><span class="MuiTouchRipple-root"></span>
</button>



<button onClick={this.headings6termsconditions} className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name" 
tabindex="0" type="button"
 role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
     <span class="MuiTab-wrapper"> <span>
     <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>  Headings 6 </span></span>
<span className="MuiTouchRipple-root"></span>
</button>


                        
</div>

{/* <h2>Animated Sidenav Example</h2> */}
<span
className="termscondition-menu-button"
 onClick={this.openNavtermscondition}
 >&#9776; Terms & Conditions</span>
                          </Container>











<Col xl={9} lg={9} md={9} sm={9} id="headings1-termsconditions">
<div class="MuiTypography-root termsTabs MuiTypography-body1" role="tabpanel" id="vertical-tabpanel-0" aria-labelledby="vertical-tab-0">
     <div class="MuiBox-root jss69">
         
<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.</p></div>
</div>

<div class="termsandcondtions_detail">
    <h4>Context Heading</h4>
<div class="sub_details">
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.</p></div>
</div>


<div class="termsandcondtions_detail">


<h4>Context Heading</h4>
<div class="sub_details">
<p>You can visit the Mobile Application without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Mobile Application's features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you create an account, make a purchase,  or fill any online forms in the Mobile Application.  When required, this information may include your email address, name, phone number, address, credit card information, bank information,  or other Personal Information. You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the Mobile Application's features. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
</div></div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Mobile Application or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.</p>
</div></div>

<div class="termsandcondtions_detail">
    <h4>Context Heading</h4>
    <div class="sub_details">
        <p>Any of the information we collect from you may be used to personalize your experience; improve our Mobile Application; improve customer service and respond to queries and emails of our customers; process transactions; send newsletters; send notification emails such as password reminders, updates, etc;  run and operate our Mobile Application and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Mobile Application traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.</p><p>We may process Personal Information related to you if one of the following applies:</p>
        <ol className="ordered-list-in-privacy-policy" type="i">
            <li class="terms-and-conditions-tab-name">You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; </li>
            <li class="terms-and-conditions-tab-name">Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for compliance with a legal obligation to which you are subject;</li>
            <li class="terms-and-conditions-tab-name">Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</li>
            </ol>
</div>
</div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>Depending on your location, data transfers may involve transferring and storing your information in a country other than your own. You are entitled to learn about the legal basis of information transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by us to safeguard your information. If any such transfer takes place, you can find out more by checking the relevant sections of this document or inquire with us using the information provided in the contact section.</p>
</div></div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>You may exercise certain rights regarding your information processed by us. In particular, you have the right to do the following:</p>
<ol type="i" className="ordered-list-in-privacy-policy">
<li class="terms-and-conditions-tab-name">you have the right to withdraw consent where you have previously given your consent to the processing of your information;</li>
<li class="terms-and-conditions-tab-name">you have the right to object to the processing of your information if the processing is carried out on a legal basis other than consent;</li>
<li class="terms-and-conditions-tab-name"> you have the right to learn if information is being processed by us, obtain disclosure regarding certain aspects of the processing and obtain a copy of the information undergoing processing; </li>
<li class="terms-and-conditions-tab-name">you have the right to verify the accuracy of your information and ask for it to be updated or corrected; </li>
<li class="terms-and-conditions-tab-name">you have the right, under certain circumstances, to restrict the processing of your information, in which case, we will not process your information for any purpose other than storing it; </li>
<li class="terms-and-conditions-tab-name">you have the right, under certain circumstances, to obtain the erasure of your Personal Information from us; </li>
<li class="terms-and-conditions-tab-name">you have the right to receive your information in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that your information is processed by automated means and that the processing is based on your consent, on a contract which you are part of or on pre-contractual obligations thereof. </li>
</ol>
</div>
</div>








</div>
</div>



</Col>












<Col xl={9} lg={9} md={9} sm={9} id="headings2-termsconditions" style={{display:'none'}}>

<div class="MuiTypography-root termsTabs MuiTypography-body1" role="tabpanel" id="vertical-tabpanel-0" aria-labelledby="vertical-tab-0">
     <div class="MuiBox-root jss69">



<div class="termsandcondtions_detail">
<h4>Heading 2</h4>
<div class="sub_details">
<p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Mobile Application and its Services.</p>
</div></div>


<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>


<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>



<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>



<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>



<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>

</div>
</div>
</Col>











<Col xl={9} lg={9} md={9} sm={9} id="headings3-termsconditions" style={{display:'none'}}>

<div class="MuiTypography-root termsTabs MuiTypography-body1" role="tabpanel" id="vertical-tabpanel-0" aria-labelledby="vertical-tab-0">
     <div class="MuiBox-root jss69">


<div class="termsandcondtions_detail">
<h4>heading 3</h4>
<div class="sub_details">
<p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Mobile Application and its Services.</p>
</div></div>




<div class="termsandcondtions_detail">


<h4>Context Heading</h4>
<div class="sub_details">
<p>You can visit the Mobile Application without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Mobile Application's features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you create an account, make a purchase,  or fill any online forms in the Mobile Application.  When required, this information may include your email address, name, phone number, address, credit card information, bank information,  or other Personal Information. You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the Mobile Application's features. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
</div></div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Mobile Application or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.</p>
</div></div>

<div class="termsandcondtions_detail">
    <h4>Context Heading</h4>
    <div class="sub_details">
        <p>Any of the information we collect from you may be used to personalize your experience; improve our Mobile Application; improve customer service and respond to queries and emails of our customers; process transactions; send newsletters; send notification emails such as password reminders, updates, etc;  run and operate our Mobile Application and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Mobile Application traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.</p><p>We may process Personal Information related to you if one of the following applies:</p>
        <ol className="ordered-list-in-privacy-policy" type="i">
            <li class="terms-and-conditions-tab-name">You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; </li>
            <li class="terms-and-conditions-tab-name">Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for compliance with a legal obligation to which you are subject;</li>
            <li class="terms-and-conditions-tab-name">Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</li>
            </ol>
</div>
</div>

</div>
</div>
</Col>


















<Col xl={9} lg={9} md={9} sm={9} id="headings4-termsconditions" style={{display:'none'}}>

<div class="MuiTypography-root termsTabs MuiTypography-body1" role="tabpanel" id="vertical-tabpanel-0" aria-labelledby="vertical-tab-0">
     <div class="MuiBox-root jss69">


<div class="termsandcondtions_detail">
<h4> headings 4</h4>
<div class="sub_details">
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.</p>
</div></div>


<div class="termsandcondtions_detail">
<h4>Contacting us</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>



<div class="termsandcondtions_detail">


<h4>Context Heading</h4>
<div class="sub_details">
<p>You can visit the Mobile Application without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Mobile Application's features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you create an account, make a purchase,  or fill any online forms in the Mobile Application.  When required, this information may include your email address, name, phone number, address, credit card information, bank information,  or other Personal Information. You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the Mobile Application's features. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
</div></div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Mobile Application or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.</p>
</div></div>

<div class="termsandcondtions_detail">
    <h4>Context Heading</h4>
    <div class="sub_details">
        <p>Any of the information we collect from you may be used to personalize your experience; improve our Mobile Application; improve customer service and respond to queries and emails of our customers; process transactions; send newsletters; send notification emails such as password reminders, updates, etc;  run and operate our Mobile Application and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Mobile Application traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.</p><p>We may process Personal Information related to you if one of the following applies:</p>
        <ol className="ordered-list-in-privacy-policy" type="i">
            <li class="terms-and-conditions-tab-name">You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; </li>
            <li class="terms-and-conditions-tab-name">Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for compliance with a legal obligation to which you are subject;</li>
            <li class="terms-and-conditions-tab-name">Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</li>
            </ol>
</div>
</div>

</div>
</div>
</Col>
















<Col xl={9} lg={9} md={9} sm={9} id="headings5-termsconditions" style={{display:'none'}}>

<div class="MuiTypography-root termsTabs MuiTypography-body1" role="tabpanel" id="vertical-tabpanel-0" aria-labelledby="vertical-tab-0">
     <div class="MuiBox-root jss69">


<div class="termsandcondtions_detail">
<h4>heading 5</h4>
<div class="sub_details">
<p>You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Mobile Application or its Services you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Mobile Application and its Services.</p>
</div></div>


<div class="termsandcondtions_detail">
<h4>Contacting us</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>


<div class="termsandcondtions_detail">


<h4>Context Heading</h4>
<div class="sub_details">
<p>You can visit the Mobile Application without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Mobile Application's features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you create an account, make a purchase,  or fill any online forms in the Mobile Application.  When required, this information may include your email address, name, phone number, address, credit card information, bank information,  or other Personal Information. You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the Mobile Application's features. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
</div></div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Mobile Application or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.</p>
</div></div>

<div class="termsandcondtions_detail">
    <h4>Context Heading</h4>
    <div class="sub_details">
        <p>Any of the information we collect from you may be used to personalize your experience; improve our Mobile Application; improve customer service and respond to queries and emails of our customers; process transactions; send newsletters; send notification emails such as password reminders, updates, etc;  run and operate our Mobile Application and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Mobile Application traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.</p><p>We may process Personal Information related to you if one of the following applies:</p>
        <ol className="ordered-list-in-privacy-policy" type="i">
            <li class="terms-and-conditions-tab-name">You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; </li>
            <li class="terms-and-conditions-tab-name">Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for compliance with a legal obligation to which you are subject;</li>
            <li class="terms-and-conditions-tab-name">Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</li>
            </ol>
</div>
</div>

</div>
</div>
</Col>























<Col xl={9} lg={9} md={9} sm={9} id="headings6-termsconditions" style={{display:'none'}}>

<div class="MuiTypography-root termsTabs MuiTypography-body1" role="tabpanel" id="vertical-tabpanel-0" aria-labelledby="vertical-tab-0">
     <div class="MuiBox-root jss69">

<div class="termsandcondtions_detail">
<h4>heading 6</h4>
<div class="sub_details">
<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, id saepe sit hic repellendus voluptatem ea delectus nostrum ipsa veritatis impedit adipisci quo culpa quos deserunt laboriosam doloribus quisquam ullam.</p>
</div></div>


<div class="termsandcondtions_detail">
<h4>Contacting us</h4>
<div class="sub_details">
<p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to <span style={{Color: 'rgb(16, 54, 252)'}}>info@wowets.com</span>
</p></div></div>


<div class="termsandcondtions_detail">


<h4>Context Heading</h4>
<div class="sub_details">
<p>You can visit the Mobile Application without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the Mobile Application's features, you will be asked to provide certain Personal Information (for example, your name and e-mail address). We receive and store any information you knowingly provide to us when you create an account, make a purchase,  or fill any online forms in the Mobile Application.  When required, this information may include your email address, name, phone number, address, credit card information, bank information,  or other Personal Information. You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the Mobile Application's features. Users who are uncertain about what information is mandatory are welcome to contact us.</p>
</div></div>

<div class="termsandcondtions_detail">
<h4>Context Heading</h4>
<div class="sub_details">
<p>You are able to access, add to, update and delete certain Personal Information about you. The information you can view, update, and delete may change as the Mobile Application or Services change. When you update information, however, we may maintain a copy of the unrevised information in our records. Some information may remain in our private records after your deletion of such information from your account. We will retain and use your Personal Information for the period necessary to comply with our legal obligations, resolve disputes, and enforce our agreements unless a longer retention period is required or permitted by law. We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.</p>
</div></div>

<div class="termsandcondtions_detail">
    <h4>Context Heading</h4>
    <div class="sub_details">
        <p>Any of the information we collect from you may be used to personalize your experience; improve our Mobile Application; improve customer service and respond to queries and emails of our customers; process transactions; send newsletters; send notification emails such as password reminders, updates, etc;  run and operate our Mobile Application and Services. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding Mobile Application traffic and usage. This statistical information is not otherwise aggregated in such a way that would identify any particular user of the system.</p><p>We may process Personal Information related to you if one of the following applies:</p>
        <ol className="ordered-list-in-privacy-policy" type="i">
            <li class="terms-and-conditions-tab-name">You have given your consent for one or more specific purposes. Note that under some legislations we may be allowed to process information until you object to such processing (by opting out), without having to rely on consent or any other of the following legal bases below. This, however, does not apply, whenever the processing of Personal Information is subject to European data protection law; </li>
            <li class="terms-and-conditions-tab-name">Provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for compliance with a legal obligation to which you are subject;</li>
            <li class="terms-and-conditions-tab-name">Processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; </li>
            <li class="terms-and-conditions-tab-name">Processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</li>
            </ol>
</div>
</div>

</div>
</div>
</Col>









</Row>





</div>
</div>
</Container>
</div>































</div>



    );
  }
}
export default Termsconditions;
