import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Clock from 'react-live-clock';

import {
  Form,
  Card,
  Container,
  Carousel,
  Button,
  Header,
  FormLabel,
  FormControl,
  Col,
  Row,
  View
} from "react-bootstrap";


import{
  Link,
  NavLink
} from "react-router-dom";
class Wowholidayfooter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  scrolltoupprivacypolicy = () =>{
    const scrollingup = document.querySelector("#scrollingup");
    // alert("asdasd")
  
    scrollingup.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }
  

  


  scrolltoupholidayblog = () =>{
    const scrollingupblogpost = document.querySelector("#scrollingupblogpost");
    // alert("asdasd")
  
    scrollingupblogpost.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }

  scrolltouptrustsafety = () =>{
    const scrolltouptrustsafety = document.querySelector("#scrollinguptrustsafety");
    // alert("asdasd")
  
    scrolltouptrustsafety.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }

  scrolltouphowitworks = () =>{
    const scrolltouphowitworks = document.querySelector("#scrollinguphowitworks");
    // alert("asdasd")
  
    scrolltouphowitworks.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }


  scrolltouptermscondition = () =>{
    const scrollinguptermscondition = document.querySelector("#scrollinguptermscondition");
    // alert("asdasd")
  
    scrollinguptermscondition.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }

  scrolltouphelpcenter = () =>{
    const scrolltouphelpcenter = document.querySelector("#scrollinguphelpcenter");
    // alert("asdasd")
  
    scrolltouphelpcenter.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }

  scrolltoupnewsroom = () =>{
    const scrolltoupnewsroom = document.querySelector("#scrollingupnewsroom");
    // alert("asdasd")
  
    scrolltoupnewsroom.addEventListener("click" , function(){
      // window.scrollTo(0 , 0);

    
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'

      });


      // $("html, body").animate({scrollTo: 0}, "slow");
  
    });
  
  }





  render() {
    const scrollToTopcommunity = () => window.scrollTo({ top: 0});
    return (
      <div>
        <div className="footer-above-section-in-wow-holiday-background">
          <Container>
            <div className="padding-in-footer-section">
              <Row>
                <Col xs={12} sm={12} lg={2} md={2}>
                  <Row>
                    <div className="t_v_mid_box class-for-mobile-view-wow-holiday-footer-section">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Facebook"
                        target="_blank"
                        className="t_v_mid icons-footer-section-in-tab-view-wow-holiday"
                        href="#"
                      >
                        {/* klook-icon-fb */}
                        <i className=" fab fa-facebook-f  t22 "></i>
                      </a>
                    </div>

                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Twitter"
                        target="_blank"
                        href="#"
                        className="t_v_mid t_mid"
                      >
                        {/* klook-icon-twi */}
                        <i className=" fab fa-twitter t22 padding-in-icon-in-footer-aboe-section-wow-holiday"></i>
                      </a>
                    </div>

                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Wechat"
                        className="t_v_mid t_mid g_rel"
                      >
                        {/* klook-icon-wc */}
                        <i className="t22  fab fa-instagram padding-in-icon-in-footer-aboe-section-wow-holiday"></i>
                        {/* style={{display: 'none'}} */}
                        <i className="i_wechat_qr"></i>
                      </a>
                    </div>

                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Instagram"
                        target="_blank"
                        href="#"
                        className="t_v_mid t_mid"
                      >
                        {/* klook-icon-ins2 */}
                        <i className=" fab fa-youtube t22 t23 padding-in-icon-in-footer-aboe-section-wow-holiday"></i>
                      </a>
                    </div>
                  </Row>
                </Col>

                <Col xs={12} sm={12} lg={6} md={4}>
                  <div className="m_justify_list_footer">
                    <div>
                      <span className="t14-footer-above t_white t69">
                        Travel discounts up to 60% off sent straight to your
                        inbox
                      </span>
                      <p className="t12-footer-above terms t69">
                        By clicking Subscribe, you have agreed to our{" "}
                        <Link
                        to="/termsconditions"
                        onClick={scrollToTopcommunity}
                          className="links-written-footer-in-wow-holiday t69"
                          
                        >
                          Terms &amp; Conditions
                        </Link>{" "}
                        and{" "}
                        <Link
                          to="/privacypolicy"
                          onClick={scrollToTopcommunity}
                          className="links-written-footer-in-wow-holiday t69"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </div>
                  </div>
          </Col>

                <Col xs={12} sm={12} lg={4} md={6}>
                  <Row style={{ flexWrap: "nowrap !important" }}>
                    <div className="m_justify_list_footer ">
                      <input
                        type="text"
                        name="email_subscribe"
                        className="g_left t14 input-color-in-wow-holiday"
                        placeholder="Enter your email address"
                      />
                      <a
                        id="subscription"
                        className="subscribe footer-in-wow-holiday-subscribe g_left m_btn_main g_ib t_mid t14-footer-above-button-subscribe"
                        data-track-event="Email Subscription|Newsletter Subscribed"
                        data-gatag="Main Page|Subcribe"
                      >
                        Subscribe
                      </a>
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* <div className="footer-above-section-in-wow-holiday-background">

          <Container>

          <div className="padding-in-footer-section">

            <Row>

              <Col>
              sadadsad
              
              
              </Col>

              <Col>
              sadasdas
              
              
              </Col>

              <Col>
              
              asdasdsa
              
              </Col>







            </Row>



            </div>



          </Container>





        </div> */}

        {/* <div className="footer-in-wow-holiday">
          
            <div className="g-main-in-wow-holiday-leader t_white g_rel m-grid-in-wow-holiday-leader">
              <div className="m_justify_list_footer m_external_links">
			    
                <div className="m-grid-in-wow-holiday-leader">
                  <div className="m_justify_list_footer">
					  
                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Facebook"
                        target="_blank"
                        className="t_v_mid"
                        href="https://www.facebook.com/klookglobal/"
                      >
                        <i className="klook-icon-fb fab fa-facebook-f t22"></i>
                      </a>
                    </div>
                  </div>
                  <div className="m_justify_list_footer">
                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Twitter"
                        target="_blank"
                        href="https://twitter.com/klooktravel"
                        className="t_v_mid t_mid"
                      >
						  klook-icon-twi
                        <i className=" fab fa-twitter t22"></i>
                      </a>
                    </div>
                  </div>
                  <div className="m_justify_list_footer">
                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Wechat"
                        className="t_v_mid t_mid g_rel"
                      >
                        <i className="t22 klook-icon-wc fab fa-instagram"></i>
                        style={{display: 'none'}}
                        <i className="i_wechat_qr"></i>
                      </a>
                    </div>
                  </div>
                  <div className="m_justify_list_footer">
                    <div className="t_v_mid_box">
                      <a
                        data-track-event="Social Media|Social Media Clicked|Instagram"
                        target="_blank"
                        href="https://instagram.com/klooktravel"
                        className="t_v_mid t_mid"
                      >
                        <i className="klook-icon-ins2 fab fa-youtube t22 t23"></i>
                      </a>
                    </div>
                  </div>
                  <div className="m_justify_fix"></div>
                </div>
			
              </div> 
			 

               
              <div className="m_justify_list_footer_footer g_rel">
                <div className="m-grid-in-wow-holiday-leader">
			
                  <div className="m_justify_list_footer_footer">
                    <div>
                      <span className="t14 t_white">
                        Travel discounts up to 60% off sent straight to your
                        inbox
                      </span>
                      <p className="t12 terms">
                        By clicking Subscribe, you have agreed to our{" "}
                        <a
                          href="/conditions/"
                          className="links-written-footer-in-wow-holiday"
                          target="_blank"
                        >
                          Terms &amp; Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="/policy/"
                          className="links-written-footer-in-wow-holiday"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </div>
                  </div>
				  
                  <div className="m_justify_list_footer_footer">
                    <input
                      type="text"
                      name="email_subscribe"
                      className="g_left t14 input-color-in-wow-holiday"
                      placeholder="Enter your email address"
                    />
                    <a
                      id="subscription"
                      className="subscription g_left m_btn_main g_ib t_mid t14"
                      data-track-event="Email Subscription|Newsletter Subscribed"
                      data-gatag="Main Page|Subcribe"
                    >
                      Subscribe
                    </a>
                  </div>
                  <div className="thanks j_subscribe_tips"></div>
                  <span className="m_justify_fix"></span>
                </div>
              </div>
              <span className="m_justify_fix"></span>
            </div>
         
        </div> */}

        {/* <div className="b">
        			<div className="g_main m_grid">
        				<div className="m_justify_list_footer_footer">
        					<p className="t12 copyright ">© 2014-2020 Klook. All Rights Reserved.</p>
        					<div id="view_mobile" className="view_mobile">
        						<a href="/" className="color-of-footer-content">Visit Mobile Site</a>
        					</div>
        				</div>
        				<div className="m_justify_list_footer_footer">
        					<ul>
                                <li className="t_white bold-content">ABOUT KLOOK</li>
        						<li><a href="/about/" className="color-of-footer-content">About Us</a></li>
        						<li><a href="/newsroom/" className="color-of-footer-content">Press Releases</a></li>
                                    <li><a href="https://www.google.com/" className="color-of-footer-content" target="_blank">Klook Blog</a></li>
        
                                <li><a href="/partner/" className="color-of-footer-content">Partner With Us</a></li>
                                <li><a href="/careers/" className="color-of-footer-content">Careers</a></li>
        
        					</ul>
        				</div>
        
        				<div className="m_justify_list_footer">
        					<ul>
                                <li className="t_white bold-content">PARTNERSHIPS</li>
                                <li><a href="https://merchant.klook.com/" className="color-of-footer-content">Partner Login </a></li>
                                <li><a href="http://affiliate.klook.com/" className="color-of-footer-content">Affiliate Partnership</a></li>
                                    <li><a href="https://affiliate.klook.com/" className="color-of-footer-content">Influencer Program</a></li>
        						<li><a href="https://www.klook.com/agent/" className="color-of-footer-content">Agent Marketplace</a></li>
        						<li style={{display:'none'}}><a href="/business/" className="color-of-footer-content">Corporate Discount</a></li>
        					</ul>
        				</div>
        
        				<div className="m_justify_list_footer">
        					<ul>
        						<li className="t_white bold-content">TERMS OF USE</li>
                                <li><a href="/conditions/" className="color-of-footer-content">Terms And Conditions</a></li>
        						<li><a href="/policy/" className="color-of-footer-content">Privacy Policy</a></li>
        						<li><a href="/cookiepolicy/" className="color-of-footer-content">Cookie Policy</a></li>
        						<li><a href="/bugbounty/" className="color-of-footer-content">Bug Bounty Program</a></li>
        					</ul>
        				</div>
        				<div className="m_justify_list_footer" style={{overflow: 'initial'}}>
        					<ul>
        						<li className="t_white t16 foot-askus-btn t_mid"><a href="/ask_klook/" className="color-of-footer-content" target="_blank">Ask Klook</a></li>
        					</ul>
        					<ul>
        						<li className="t_gray">PAYMENT CHANNEL</li>
        						<li className="ma_0 g_rel m_grid">
                                            <span ><img className="i_unionpay m_justify_list_footer" src="IMAGES/city-images/Visa-icon.png"></img></span>
                                            <span className="i_newVisa m_justify_list_footer"></span>
                                            <span className="i_newMaster m_justify_list_footer"></span>
                                            <span className="i_jcb m_justify_list_footer"></span>
                                            <span className="i_newPaypal m_justify_list_footer"></span>
                                            <span className="i_newAE m_justify_list_footer"></span>
                                            <span className="i_applepay m_justify_list_footer"></span>
                                            <span className="i_googlepay m_justify_list_footer"></span>
                                            <span className="i_alipay m_justify_list_footer"></span>
        						</li>
        					</ul>
        				</div>
        				<span className="m_justify_fix"></span>
        			</div>

                    <br/><br/>
        		</div>


                <i class="fa fa-arrow-up klook-icon-Top"></i> */}

        <footer class="bg-color--black py-5">
          <div class="container">
            <Row>
              {/* <div class="col-auto col-md-6 col-lg-2 order-lg-first text-right text-lg-left"> */}
              <Col xs={12} sm={12} xl={2} lg={2} md={2}>
                <a class="navbar-brand custom-logo-link" rel="home">
                  <img
                    src="IMAGES/wow-holiday.png"
                    class="img-fluid"
                    alt="WOW Worldwide Holiday"
                    srcset="IMAGES/wow-holiday.png 194w, IMAGES/wow-holiday.png-150x150.png 150w, IMAGES/wow-holiday.png-100x100.png 100w"
                    sizes="(max-width: 194px) 100vw, 194px"
                  />
                </a>{" "}
              </Col>
              {/* </div> */}
              {/* <div class="col col-lg-3 mb-3 mb-lg-0"> */}
              <Col xs={12} sm={12} xl={3} lg={3} md={12}>
                <div class="mb-3">
                  {/* <img
                    className="wow-logo-in-footer"
                    src="IMAGES/WOWholiday-iconlogo3.png"
                  ></img> */}
                  <h4 class="mb-3">WOW Worldwide Holiday</h4>
                </div>
                <ul class="list-unstyled list--chevron">
                  {" "}
                  <li class="mb-2 ">
                    {/* <i className="fas fa-angle-right"></i> */}
                    <Link
                      to="/termsconditions"
                      onClick={scrollToTopcommunity}
                      id="scrollinguptermscondition"
                      activeClassName="active-button-terms"
                      className="content-written-in-footer-of-wow-holiday-page tac"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                    id="scrollingup" onClick={scrollToTopcommunity}
                      to="/privacypolicy"
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                    id="scrollinguptrustsafety" onClick={scrollToTopcommunity}
                      to="/trustsafety"
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Trust and Safety
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                      to="/helpcenter"
                      onClick={scrollToTopcommunity}
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Help
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                      to="/transportservices"
                      onClick={scrollToTopcommunity}
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Transport & Services
                    </Link>
                  </li>
                </ul>{" "}
                {/* </div> */}
              </Col>

              {/* <div class="col-md-6 col-lg-3 mb-3 mb-lg-0"> */}
              <Col xs={12} sm={12} xl={3} lg={3} md={12}>
                <h4 class="mb-3">More information</h4>
                <ul class="list-unstyled list--chevron">
                  {" "}
                  <li class="mb-2">
                    <Link
                    // id="scrollinguphowitworks" onClick={this.scrolltouphowitworks}
                    onClick={scrollToTopcommunity}
                      to={{
                        pathname: "/howitworks",
                        state: { fromDashboard: true }
                      }}
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      How it Works
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                      to="/community"
                      onClick={scrollToTopcommunity}
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Wow Community
                    </Link>
                  </li>

                  <li class="mb-2">
                    <Link
                      to="/communityguidline"
                      onClick={scrollToTopcommunity}
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                    Community Guideline
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                    //  id="scrollingupblogpost" onClick={this.scrolltoupholidayblog}
                    onClick={scrollToTopcommunity}
                      to="/holidayblogs"
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Blog posts
                    </Link>
                  </li>
                  <li class="mb-2">
                    <Link
                    // id="scrollingupnewsroom" onClick={this.scrolltoupnewsroom}
                    onClick={scrollToTopcommunity}
                      to="/newsroom"
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      News Rooms
                    </Link>
                  </li>
                  <li class="">
                    <a
                     href="https://wowhealth.app/" 
                     target="_blank"
                      className="content-written-in-footer-of-wow-holiday-page"
                    >
                      Wow Health
                    </a>
                  </li>
                </ul>
                {/* </div> */}
              </Col>
              <Col xs={12} sm={12} xl={4} lg={4} md={12}>
                {/* <div class="col-md-6 col-lg-3 mb-3 mb-lg-0"> */}
                <h4 class="mb-3">Contact</h4>
                <ul class="list-unstyled list--icons mb-0">

                <li class="mt-2">
                    <i class="fa fa-map-marker"></i>
                    <address class="pl-4-4 ">
             {/* &nbsp;WOW, Worldwide Holiday, Office No. 3403 - 34th Floor - Burj Al Salam Tower - 
             Trade Centre 1 - Sheikh Zayed Road - Dubai - United Arab Emirates - P.O. Box No. 89498. */}

WOW Worldwide Holidays, Office No. 3403, 34th Floor, Burj Al Salam Tower, 
Trade Centre 1, Sheikh Zayed Road, P.O. Box 89498, Dubai, United Arab Emirates
                    </address>
                </li>

                <li class="mt-2">
                    <address className="pl-4-4-uae">
             &nbsp;United Arab Emirates
                    </address>
                </li>

                  <li>
                    <i class="fa fa-phone padding-between-contact-in-wow-holiday"></i>
                    <a
                      class="pl-4 padding-between-contact-in-wow-holiday"
                      href="tel:+971 45 217500"
                    >
                      &nbsp; +971 4 229 8222
                    </a>
                  </li>

                  {/* <li class="mt-2">
                    <i class="fa fa-fax padding-between-contact-in-wow-holiday"></i>
                    <a
                      class="pl-4 padding-between-contact-in-wow-holiday"
                      href="tel:+971 45 217500"
                    >
                      &nbsp; +9714 2368888
                    </a>
                  </li> */}
                
                  <li class="mt-2">
                    <i class="fa fa-envelope"></i>
                    <a
                      class="pl-4-3 padding-between-contact-in-wow-holiday"
                      href="mailto:info@wowworldwideholiday.com"
                    >
                      &nbsp; info@wowworldwideholiday.com
                      
                    </a>
                  </li>


                  <li class="mt-2">
                    <i class="fa fa-clock"></i>
                    <a
                      class="pl-4-3 padding-between-contact-in-wow-holiday"
                    >
                      {/* <span>July 23rd, 2020</span> */}
                      &nbsp; <Clock format={'dddd, MMMM Do YYYY  h:mm:ss a.'}
                             ticking={true} timezone={'Asia/Dubai'} />
                    </a>
                  </li>

                  <li class="mt-2">
                    <div className="padding-between-contact-in-wow-holiday-qrcode">
                    <img
                      class="pl-4-3-qrcode padding-between-contact-in-wow-holiday"
                      src="IMAGES/city-images/qr-code-new-WowHoliday.png"
                    ></img>
                    </div>
                  </li>

                  {/* <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>{item.name==="" ? <p style={{color:'white'}}>Dubai Time:  <Clock
                          format={' h:mm:ss a, dddd, MMMM Do YYYY'}
                          timezone={'Asia/Dubai'} /></p>:''}{item.route ? <Link onClick={()=>window.scrollTo(0,item.scroll)} to={`/${item.route}`}>{item.name}</Link> : item.href ? <a href={item.href} >{item.name}</a>  : `${item.name}`}{item.name===""? <p style={{color:'white'}}><br /><img style={{width:'100%'}} src={item.image} /></p>:''}</li>
                                            

                        ))}
                                        <li><a href="tel:+97142555111">Phone: +9714 2555111</a></li>
                                        <li><a href="fax:+97142368888">Fax: +9714 2368888</a></li>
                                        <li><a href="mailto:legalemirates@outlook.com">Email: legalemirates@outlook.com</a></li>
                   </ul> */}
                  
                  
                </ul>
                {/* </div> */}
              </Col>
            </Row>


            <div class="row mt-4">
              <div class="col-12">
                <div class="d-flex flex-column flex-md-row align-items-lg-center footer__bottom py-3">
                  <div class="mb-3 mb-md-0 copyright-footer">
                    <strong>
                      © 2020 Copyright WOW Worldwide Holiday.
                    </strong>
                  </div>
                  <div class="ml-md-3 mb-3 mb-md-0">
                    <ul class="list-unstyled list-inline mb-0">
                      <li class="list-inline-item m-0 ">
                        <a 
                        style={{color:'#f87370'}}
                        className="copyright-links">Disclaimer &nbsp; / &nbsp;</a>
                      </li>
                      <li class="list-inline-item m-0">
                        <Link 
                        to="/privacypolicy"
                        onClick={scrollToTopcommunity}
                        className="copyright-links">Privacy declaration</Link>
                      </li>
                    </ul>
                  </div>

                  {/* fab fa-facebook-f */}
                  <div className="icons-in-footer-below-section-of-wow-holiday ml-md-auto">
                    <a>
                    {" "}
                      <i class="fab fa-facebook-f icons-in-footer-of-wow-holiday"></i>
                    </a>
                    <a>
                    {" "}
                      <i class="fab fa-twitter icons-in-footer-of-wow-holiday"></i>
                    </a>
                    <a>
                    {" "}
                      <i class="fab fa-instagram icons-in-footer-of-wow-holiday"></i>
                    </a>
                    <a>
                      {" "}
                      <i class="fab fa-pinterest-p icons-in-footer-of-wow-holiday"></i>
                    </a>
                    <a>
                      {" "}
                      <i class="fab fa-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>


      

        {/* <Container>
	<Form className="form-in-wow-holiday-footer form-in-wow">
		<h1>
			DJSOPDJQP
		</h1>

	</Form>
</Container> */}
      </div>
    );
  }
}
export default Wowholidayfooter;







