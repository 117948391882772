import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { 
  Form,
  Container,
  Card, Row,
  Slider,
  Col,
  Button
 } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Newscard3 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      date: new Date().toLocaleString(),
      toggle: true,
     };
  }


  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  SpacePortae = () => {
    document.getElementById("emirates").style.display = "none";
    document.getElementById("tripadvisor").style.display = "none";
    document.getElementById("Travellingman").style.display = "none";
    document.getElementById("smartdubai").style.display = "none";
    document.getElementById("dubaipolice").style.display = "none";
    document.getElementById("natgeotravel").style.display = "none";
    document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "block";
    document.getElementById("europespaceagency").style.display = "none";

    document.getElementById("mySidenav-newsroom").style.width = "0px";
  };

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };


  render() {
    return (
      <div>
        
        {/* <Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-travel-work">
        <div  className="heading-secondary-section-wow-travel-work ">
        Coronavirus Tips If You Still Have to Travel Domestically

        <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2-newscard2"
            src="IMAGES/city-images/newsroom/card3-2.jpg"/>
        </div>
        </div>


</Container> */}

        

<Container>
        <div className="td-post-header-holder-card2 td-image-gradient-newscard3">
        <div className="td-post-featured-image-newscard3">
           <img width="1068" height="580" 
            className="entry-thumb td-animation-stack-type0-2-newscard3"
            src="IMAGES/city-images/newsroom/card3-2.jpg"/>

        </div>
            <header className="td-post-title-newscard3">
            <ul className="td-category-newscard3">
                    <li className="entry-category">
                        <a 
                        target="blank"
                        href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/travel-in-the-us.html">
                        Corona Tips
                        </a>
                        </li>
                </ul>
                             
<h1 className="entry-title-newscard3">Coronavirus Tips If You Still Have to Travel Domestically & internationally</h1>

                

                <div className="td-module-meta-info-newscard3">
                 <span className="td-post-date-newscard3">
                     <time className="entry-date-newscard3 updated td-module-date-newscard3"
                      datetime="2018-11-07T10:59:17+00:00">May 28, 2020</time>
                </span>      

                <div className="td-post-views-newscard3">
                    <i className="fa fa-eye view-icon-views-newscard3"></i>
                    <span className="td-nr-views-10968">12736</span>
                </div>                    
                              
                     </div>

            </header>
        </div>
 
</Container>





<Container>


<div className="u-center-text u-margin-bottom-second-heading-wow-newscard2">
        <div  className="heading-secondary-section-wow-newscard2 ">
        If you are thinking about traveling away from your local community, ask:
        </div>
        </div>        

</Container>





<Container>
<div id="accordion-5ed01b058660a" className="accordion indicator-plus accordion-white mb-3" role="tablist">
<div className="card-newsroom3 bar">
  <div className="card-newsroom3 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-0" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom3-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-0">
        <span role="heading" aria-level="0">Is COVID-19 spreading in your community?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-0" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-0" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>You can get infected while traveling.
       
       </p>
       <h2>If You Travel</h2>

       <ul>
        <li>
        Clean your hands often. Wash your hands with soap and water for at least 20 seconds, especially
         after you have been in a public place, after touching surfaces frequently 
        touched by others, after blowing your nose, coughing, or sneezing, and before touching your face or eating.
            </li>
        <li> Avoid touching your eyes, nose, or mouth.
        </li>
        
        <li> Avoid close contact with others. Keep 6 feet of physical distance from others.</li>
        <li> Cover coughs and sneezes.</li>
        <li>Pick up food at drive-throughs, curbside restaurant service, or stores.</li>
        </ul>
      </div>
  </div> 
  </div>
  </div>





  <div className="card-newsroom3 bar">
  <div className="card-newsroom3 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-1" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom3-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-1">
        <span role="heading" aria-level="0">Can traveling to visit family or friends increase chances of 
        getting & spreading COVID-19?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-1" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-1" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Yes. Travel increases your chances of getting and spreading COVID-19. Before you travel, learn 
          if COVID-19 is spreading in your local area or in any of the places you are going. Traveling 
          to visit family may be especially dangerous if you or your loved ones are more likely to get very
           ill from COVID-19. People at higher risk for severe illness need to take extra precautions.
     For more considerations see the webpage <a 
     target="blank"
href="https://www.thenational.ae/uae/government/coronavirus-abu-dhabi-ready-for-tourism-rebound-says-senior-official-1.1022762">
        Coronavirus in the United States—Considerations for Travelers</a>.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom3 bar">
  <div className="card-newsroom3 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-2" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom3-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-2">
        <span role="heading" aria-level="0">Should I avoid travelling internationally?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-2" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-2" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Yes. CDC recommends that you avoid all nonessential international travel because of the COVID-19
           pandemic. Some healthcare systems are overwhelmed and there may be limited access to adequate
            medical care in affected areas. Many countries are implementing travel restrictions and mandatory
             quarantines, closing borders, and prohibiting non-citizens from entry with little advance notice.
              Airlines have cancelled many international flights and in-country travel may be unpredictable.
               If you choose to travel internationally, your travel plans may be disrupted, and you may
                have to remain outside the United States for an indefinite length of time.<br/> 
                CDC also recommends all travelers  <a 
     target="blank"
href="https://wwwnc.cdc.gov/travel/notices/warning/coronavirus-cruise-ship">
        defer all cruise ship travel worldwide.</a>
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom3 bar">
  <div className="card-newsroom3 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-3" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom3-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-3">
        <span role="heading" aria-level="0">Do you live with someone who is more likely to get very ill from
         COVID-19?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-3" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-3" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>If you get infected while traveling you can spread COVID-19 to loved ones when you return,
           even if you don’t have symptoms.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom3 bar">
  <div className="card-newsroom3 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-4" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom3-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-4">
        <span role="heading" aria-level="0">Travellers should wear facemasks or coverings during travel
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-4" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-4" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Because COVID-19 is spreading in the United Arab Emirates and abroad, CDC recommends that everyone 
          wear a cloth face covering over their nose and mouth when in public, including during travel. Wear a 
          cloth face covering, keep at least 6 feet of physical distance from others, and practice 
          <a 
     target="blank"
href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html"> other 
          everyday preventive actions</a> to protect yourself and others from COVID-19.
          Avoid close contact with people who are sick, even inside your home. If possible, maintain 
          6 feet between the person who is sick and other household members.
 Cloth face coverings may 
          slow the spread of COVID-19 by helping keep people who are infected from spreading the virus 
    to others. Medical masks and N-95 respirators are for healthcare workers and other first responders, 
    as recommended by current CDC guidance. 
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom3 bar">
  <div className="card-newsroom3 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-5" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom3-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-5">
        <span role="heading" aria-level="0">State and Local Travel Restrictions
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-5" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-5" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Follow state and local travel restrictions. For up-to-date information and travel guidance, check 
          the state or local health department where you are, along your route, and at your planned destination.
           While you are traveling, it is possible a state or local government may put into place travel 
           restrictions, such as stay-at-home or shelter-in-place orders, mandated quarantines upon arrival,
            or even state border closures. Plan to keep checking for updates as you travel.
       
       </p>

       <h2>Anticipate Travel Needs</h2>
       <ul>
        <li>
        Bring enough of your medicine to last you for the entire trip.
            </li>
        <li> Pack enough alcohol-based hand sanitizer (at least 60% alcohol) and keep it within easy to reach.
        </li>
        
        <li> Bring a cloth face covering to wear in public places.</li>
        <li> Prepare food and water for your trip. Pack non-perishable food in case restaurants
           and stores are closed.</li>
        <li>If you are considering cleaning your travel lodgings, see CDC’s guidance on how to clean and disinfect.
        </li>
        </ul>
      </div>
  </div> 
  </div>
  </div>







  </div>

  
  </Container>








<div className="gradient-back-travel-newscard3">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard3-grid-text">
                  <div className="travel-newscard3-page-araea-sub-headings110">
                  Carry all essentials
                  </div>

                  <div className="travel-newscard3-page-page-text">
                  Make sure that you have all the necessary things that you need to ensure exceptional hygiene.
                   Wear a pair of gloves, carry alcohol wipes and hand sanitizers, and remember that the 
                   face mask isn't must to wear. Instead of coughing or sneezing carelessly, see that you
                    cover your mouth and sleeves with your sleeves.

 
<br/>
Regularly use your sanitizer when you aren't wearing gloves and avoid passing
 the sanitizer bottle to another person.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/5e861ff92d41c16f01595de4.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-newscard3">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/wearing-mask.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard3-grid-text">
                  <div className="travel-newscard3-page-araea-sub-headings110">
                  Get medical history
                  </div>

                  <div className="travel-newscard3-page-page-text">
           Your medical history is your best friend under these circumstances. Make sure that you have all 
           the necessary paperwork to prove that you haven't faced an illness that might indicate that you 
           were a carrier of the virus. Have at least the updated records from the past two weeks to help 
           you get through the checks smoothly.

                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>












<Container>


<div class="u-center-text u-margin-bottom-second-heading-expert-wow-newscard3">
        <div  className="heading-secondary-expert-section-wow-newscard3 ">
        Coronavirus: Expert Travel Tips
        </div>
        </div>        

</Container>





<Container>
<div class="description_of_card_of_newsroom-card3">
  <div class="description_of_card_of_newsroom_area-card3">

  <h2 class="blogs-description-area-of-main-headings-card3">
  1. Avoid Public Transportation
  </h2>
  <p class="blogs-description-area-of-main--text-card3">
  The goal when traveling during the coronavirus crisis it to avoid as many people as possible, so steer
   clear of any public transportation if you can. Either take your own car to the airport, have someone 
   you’ve been quarantining with drive you, or take a car service/taxi. If you’re taking public 
   transportation of any kind, wear a mask and be mindful to touch as few surfaces as possible.
   </p>

   
<div class="wow-news-card1-work-page-top-image-card3">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/img1.jpg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings-card3">2. Wear a Face Mask or Cloth Covering</h2>
   <p class="blogs-description-area-of-main--text-card3">
   Even if you aren’t showing symptoms, there’s a chance you may have contracted the virus. To protect others, 
   the CDC recommends that people wear a cloth face covering to cover their nose and mouth when out in public.
    While stores are largely out of masks, you can create your own using a towel, bandanna, scarf, or t-shirt.
     The CDC offers a 
     <a 
     target="blank"
     href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/diy-cloth-face-coverings.html"> guide 
     </a>
      on how to make your own. Note that coverings should not be placed on children 
     under two years of age or anyone who has trouble breathing. Once it’s on, don’t touch it without washing
      your hands first.
      </p>
    <p class="blogs-description-area-of-main--text-card3">
      Again, you want to avoid as many physical touchpoints with other people during your travels as possible.
       Fortunately, you can check into your flight online and download the boarding pass to your phone,
        allowing for fewer interactions and forgoing the need to hand over a ticket. You will still have to
         hand over your form of identification at TSA security, however. On the plus side, the TSA has 
         implemented new social distancing measures such as placing markers where travelers should stand,
          staggering the security lanes when possible, and allowing people to keep their mask on—though 
          they may ask for it to be removed or adjusted when verifying identity.
    </p>

      <div className="wow-news-card1-work-page-top-image-card3">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/img2.jpg" style={{width: '100%'}}/>

  </div>


      
<h2 className="blogs-description-area-of-main-headings-card3">3. Wash Your Hands Frequently and Bring Sanitizer</h2>
  <p className="blogs-description-area-of-main--text-card3">
  At each stage of your journey—arriving at the airport, getting through security, and after getting off the
   plane—find the nearest bathroom and wash your hands with soap and water for the CDC-suggested time of at
    least 20 seconds. If soap isn’t readily available, use hand sanitizer with at least 60 percent alcohol.
     Major airports have hand-sanitizing stations set up for travelers, and the TSA has eased its liquid 
     restrictions and is allowing one liquid hand sanitizer container up to 12 ounces in carry-on bags. 
     Additionally, bring sanitizing wipes for any surfaces you must touch, like armrests on the plane, 
     and pay attention to the label—most recommend keeping the wiped surface wet for a certain length of time.
     </p>

     <div className="wow-news-card1-work-page-top-image-card3">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/img3.jpg" style={{width: '100%'}}/>

  </div>




     
     <h2 className="blogs-description-area-of-main-headings-card3">4. Pack Food and a Reusable Water Bottle</h2>
      
      <p className="blogs-description-area-of-main--text-card3">
      Most airlines have stopped or limited food and beverage service on flights to avoid unnecessary 
      interactions. And although there are still some grab-and-go retailers and restaurants providing 
      take-out, it’s better to skip them and instead pack your purse or backpack with food and snacks 
      from home. Similarly, bring an empty reusable water bottle that you can refill at one of the 
      refill stations. The added perk? You’ll be saving money on notoriously pricey airport food.
         </p>

         <div className="wow-news-card1-work-page-top-image-card3">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/img4.jpg" style={{width: '100%'}}/>

  </div>




  <h2 className="blogs-description-area-of-main-headings-card3">5. Sit by the Window and Stay Seated</h2>
      
      <p className="blogs-description-area-of-main--text-card3">
      Due to the thorough cleaning airlines have employed for planes and the HEPA air filters found on most 
      planes, viruses do not spread easily on planes. However, you could still come into contact with the virus,
       particularly if you’re seated within six feet of someone who is infected. Many airlines have said they 
       will allow travelers to sit in a socially distant manner on the plane if that’s doable, and Delta is 
       even blocking middle seats for now. To increase your safety, opt for a window seat and stay seated 
       throughout the flight, thus limiting your exposure to people and surfaces. in rooms after each stay, 
       temperature measurement on 
          arrival at the airport, availability of masks and numerous hospital beds (Greece has more hospital
           rooms per capita than Ireland or the United Kingdom).
         </p>
       
         <div className="wow-news-card1-work-page-top-image-card3">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/img5.jpg" style={{width: '100%'}}/>

  </div>



<h2 className="blogs-description-area-of-main-headings-card3">6. Bottom Line</h2>
      
      <p className="blogs-description-area-of-main--text-card3">
      The CDC states that the virus is thought to mainly spread from person to person through respiratory
       droplets produced when an infected person coughs, sneezes, or talks, typically between people who
        are within about six feet of each other. In people who have high-risk or moderate-risk exposure to someone
         with confirmed COVID-19 infection, does taking hydroxychloroquine following the exposure compared to
          placebo reduce the incidence of a new illness compatible with COVID-19? So, your best bet at staying
           safe while taking essential
         travel is by staying as far from others as possible and avoiding touching your face. When 
         touching something or eating is necessary, be sure to wash or disinfect your hands both before
          and after. And remember that regardless of whether or not it’s a law in your final destination,
           the CDC recommends self-quarantining for 14 days.
      </p>

      <div className="wow-news-card1-work-page-top-image-card3">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card3/img6.jpg" style={{width: '100%'}}/>

  </div>






    
  
        
   
        </div>
        </div>





        </Container>









        










































{/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}































      </div>
    );
  }
}
export default Newscard3;
