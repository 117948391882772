import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";




import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory
} from "react-router-dom";

class Wowholidaygallery extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false
    };
  }



  cities = () => {
    document.getElementById("cities-cards").style.display = 'block';
    document.getElementById("beaches-cards").style.display = "none";
    document.getElementById("nature-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "none";
    document.getElementById("year-cards").style.display = "none";
    document.getElementById("ski-cards").style.display = "none";

  };


  beaches = () => {
    document.getElementById("cities-cards").style.display = 'none';
    document.getElementById("beaches-cards").style.display = "block";
    document.getElementById("ski-cards").style.display = "none";
    document.getElementById("nature-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "none";
  };



  natures = () => {
    document.getElementById("cities-cards").style.display = 'none';
    document.getElementById("beaches-cards").style.display = "none";
    document.getElementById("nature-cards").style.display = "block";
    document.getElementById("ski-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "none";
    document.getElementById("year-cards").style.display = "none";
  };
  

  ski = () => {
    document.getElementById("cities-cards").style.display = 'none';
    document.getElementById("beaches-cards").style.display = "none";
    document.getElementById("ski-cards").style.display = "block";
    document.getElementById("nature-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "none";
    document.getElementById("year-cards").style.display = "none";
  };


  events = () => {
    document.getElementById("cities-cards").style.display = 'none';
    document.getElementById("beaches-cards").style.display = "none";
    document.getElementById("ski-cards").style.display = "none";
    document.getElementById("nature-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "block";
    document.getElementById("year-cards").style.display = "none";
  };

  allyear = () => {
    document.getElementById("cities-cards").style.display = 'none';
    document.getElementById("beaches-cards").style.display = "none";
    document.getElementById("ski-cards").style.display = "none";
    document.getElementById("year-cards").style.display = "block";
    document.getElementById("nature-cards").style.display = "none";
    document.getElementById("events-cards").style.display = "none";

  };
  

  toscrolltoup = () =>{
    const scrolltoup = document.querySelector("#scrolltoup");
  
    scrolltoup.addEventListener("click" , function(){
      // window.scrollTo(0, 2000);

      window.scrollTo({
        top: 2000,
        left: 0,
        behaviour: "smooth"

      });

      // document.getElementById("ourtourid").style.display = 'block';
  
    });
  
  }


 
  render() {
    const scrollTotours = () => window.scrollTo({ top: 2000, behavior: "smooth"});
    return (
      <div>






        {/* <div className="holiday-inspiration-wow-holiday-our-container">
          <div class="holiday-inspiration-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-inspiration-wow-holiday-home-our-story">
                <div class="holiday-inspiration-wow-holiday-our-team-heading">
                  Holiday inspiration
                </div>
                <div class="holiday-inspiration-wow-holiday-our-team-heading-below-text">
                  If you need ideas for where to go on your next trip, let us
                  inspire you with our list of the best holiday destinations for
                  every type of vacation.
                </div>
                <div class="holiday-credit-wow-holiday-our-team-heading">
                  <Button
                    variant="outline-light"
                    className="holiday-credit-wow-holiday-our-team-button"
                  >
                    Check My Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        

       

        <div>
          <header className="header-image-in-new-design-gallery">


   <div className="logo-inside-header-of-new-design-page-gallery">
     <img className="image-logo-inside-header-of-new-design-page-wow-gallery" src="IMAGES/WOWholiday-iconlogo.png"></img>
   </div>


   <div className="logo-inside-header-of-new-design-page--wow-gallery">
     <img className="image-logo-inside-header-of-new-design-page-gallery" src="IMAGES/city-images/wow-gallery/tour-logo.png"></img>
   </div>


   <div className="text-box-inside-background-image-of-new-design-gallery">
  <h1 className="heading-primary-gallery">
  {/* <span className="heading-primary-main-wow-gallery">
     WOW GALLERY

    </span> */}



    <span className="heading-primary-main-gallery">
      Outdoors

    </span>
    <span className="heading-primary-sub-gallery">
      is where life happens

    </span>
  </h1>
  <Button 
  variant="outline-light" 
  id="scrolltoup" 
  onClick={scrollTotours} 
  className="button-in-new-design-gallery" 
  > 
  Discover Our Tours
  </Button>
  
  </div>
  
  <toggle>

  </toggle>

{/* <div style={{textAlign:'center'}}>
    <img style={{height:'15vh'}} src="IMAGES/wowboard.png"></img>
</div> */}


          </header></div>



       

          <main>
              <section className="section-gallery-wow-holiday">
              <Container>
                  <div class="u-center-text u-margin-bottom-second-heading-gallery">
                  <div  className="heading-secondary-section-gallery-holiday ">
                      Exciting Tours For Adventurous People
                  </div>
                  </div>

                  <Row>
                      <Col lg={6} md={6} sm={12} xs={12}>
                      <div className="header-inside-grid-wow-gallery u-margin-bottom-small">
                        You're going to fall in love with nature

                      </div>

                      <p className="header-context-inside-grid-wow-gallery">
                      Nature is an awesome backdrop to so many special and everyday moments
                       in our lives. We play, rest, exercise and connect with others on beaches, in parks,
                       forests, by lakes, on mountains and in backyards. And the benefits 
                       nature offers are far-reaching

                      </p>


                      <div className="header-inside-grid-wow-gallery u-margin-bottom-small">
                        Live adventours you have never like before

                      </div>

                      <p className="header-context-inside-grid-wow-gallery">
                      If you feel yourself settling into
                       a down mood, push yourself to take on
                        a new adventure. Let the rewards boost your mood. 
                      </p>

                      <Link  className="button-text-wow-gallery-grid">
                        Learn more &rarr;

                      </Link>



                      </Col>

                      <Col lg={6} md={6} sm={12} xs={12}>
                      <div className="composition">
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-1-large.jpg" alt="" className="composition__photo composition__photo--p1"></img>
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-2-large.jpg" alt="" className="composition__photo composition__photo--p2"></img>
                        <img src="IMAGES/city-images/wow-gallery/nature/nat-3-large.jpg" alt="" className="composition__photo composition__photo--p3"></img>
                      </div>
                      </Col>
                  </Row>


                  </Container>

              </section>




             






          </main> 


          <section className="section-features-nature-wow-gallery-holiday">

<Container>

  <Row>

    <Col lg={3} xs={3} md={3} xs={12}>

      <div className="feature-box-in-wow-gallery-holiday">



     
      <i className="feature-box__icon-wow-gallery icon-basic-world"></i>

      <div  className="header-inside-grid-wow-gallery-features-box u-margin-bottom-small">
     Explore the World
      </div>


      <p className="feature__box-text-wow-gallery">
      You'll be able to explore
       new places and cultures, and discover
        the different ways that how people live around the globe and enjoy.

      </p>

      </div>

    </Col>




    <Col lg={3} xs={3} md={3} xs={12}>

    <div className="feature-box-in-wow-gallery-holiday">



     
<i className="feature-box__icon-wow-gallery icon-basic-compass"></i>

<div  className="header-inside-grid-wow-gallery-features-box u-margin-bottom-small">
Meet Nature
</div>


<p className="feature__box-text-wow-gallery">
Wherever you go, no matter what the weather,always bring your own sunshine.
In walk with nature one receives more he seeks.


</p>

</div>
    </Col>

    <Col lg={3} xs={3} md={3} xs={12}>


    <div className="feature-box-in-wow-gallery-holiday">



     
<i className="feature-box__icon-wow-gallery icon-basic-map"></i>

<div  className="header-inside-grid-wow-gallery-features-box u-margin-bottom-small">
Find Your Way
</div>


<p className="feature__box-text-wow-gallery">
Find motivation in moments of doubt or confusion by finding your own way to 
peace, happiness and prosperity in this world.

</p>

</div>
    </Col>


    <Col lg={3} xs={3} md={3} xs={12}>


    <div className="feature-box-in-wow-gallery-holiday">



     
<i className="feature-box__icon-wow-gallery icon-basic-heart"></i>

<div  className="header-inside-grid-wow-gallery-features-box u-margin-bottom-small">
Live a Healthy Life
</div>


<p className="feature__box-text-wow-gallery">
Healthy living involves more than physical health,
it also includes mental and emotional health which can achieve by travelling the world.

</p>

</div>
    </Col>





  </Row>



</Container>








</section>

         

{/* <br/><br/><br/><br/><br/> */}

















<div class="u-center-text u-margin-bottom-second-heading-gallery-grid-section-pictures" id="ourtourid">
                  <div  className="heading-secondary-section-gallery-holiday-grid-section-pictures">
                      Holiday Popular Tours
                  </div>
                  </div>


<Container>



<center>
                  <Link 
                  onClick={this.cities}
                   className="button-text-wow-gallery-grid-grid-section-pictures">
                        Best For City 
                        

                      </Link><span className="slash-in-gallery-grid-section-pictures"> &nbsp; |  &nbsp;</span>


                      <Link 
                      onClick={this.beaches}
                       className="button-text-wow-gallery-grid-grid-section-pictures">
                        Best For Beaches 
                        

                      </Link><span className="slash-in-gallery-grid-section-pictures"> &nbsp; |  &nbsp;</span>


                      <Link 
                      onClick={this.natures}
                       className="button-text-wow-gallery-grid-grid-section-pictures">
                        Best For Nature 
                        

                      </Link><span className="slash-in-gallery-grid-section-pictures"> &nbsp; |  &nbsp;</span>

                      <Link  
                      onClick={this.ski}
                      className="button-text-wow-gallery-grid-grid-section-pictures">
                        Best For Ski 
                        

                      </Link><span className="slash-in-gallery-grid-section-pictures"> &nbsp; |  &nbsp;</span>

                      <Link 
                      onClick={this.events}
                       className="button-text-wow-gallery-grid-grid-section-pictures">
                        Events  
                        

                      </Link><span className="slash-in-gallery-grid-section-pictures"> &nbsp; |  &nbsp;</span>

                      <Link 
                      onClick={this.allyear}
                       className="button-text-wow-gallery-grid-grid-section-pictures">
                        All Year Round
                        

                      </Link>

                      
                      </center>






                      </Container>




<Container>
                      <div className="border-line-gallery-holiday"></div>
                      </Container>






                      <Container id="cities-cards" >


                        <Row>

                          <Col xs={4} lg={4} md={12} sm={12} xs={12}>
                            <div className="images-in-grid-section-pictures-gallery athens-bg-image">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                Athens, Greece 
                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                  Enjoy the perfect climate <span
                                   className="price-written-grid-section-pictures-gallery">
                                     495$
                                   </span>
                                </div>
                              </div>
                            </div>

                          </Col>




                          <Col xs={4} lg={4} md={12} sm={12} xs={12}>

                          <div className="images-in-grid-section-pictures-gallery porto-bg-image">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  PORTO, PORTUGAL
                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                  Enjoy the perfect climate <span
                                   className="price-written-grid-section-pictures-gallery">
                                     295$
                                   </span>
                                </div>
                              </div>
                            </div>
                        
                             </Col>




                          <Col xs={4} lg={4} md={12} sm={12} xs={12}>

                          <div className="images-in-grid-section-pictures-gallery barca-bg-image">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                Barcelona, Spain 
                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                  Enjoy the perfect climate <span
                                   className="price-written-grid-section-pictures-gallery">
                                     795$
                                   </span>
                                </div>
                              </div>
                            </div>
                          
                          </Col>



                        </Row>







                        <Row className="margin-bw-pics-grid-section-pictures-gallery">

<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery sebastian-bg-image">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  SAN SEBASTIAN, SPAIN
                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                A wonderful city to explore. <span
                                   className="price-written-grid-section-pictures-gallery">
                                     225$
                                   </span>
                                </div>
                              </div>
                            </div>
</Col>




<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery metz-bg-image">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  METZ, FRANCE

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                A mix of amusement and relaxation. <span
                                   className="price-written-grid-section-pictures-gallery">
                                     345$
                                   </span>
                                </div>
                              </div>
                            </div>
</Col>




<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery paris-bg-image">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  PARIS, FRANCE

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                One of the world’s few really legendary capitals. <span
                                   className="price-written-grid-section-pictures-gallery">
                                     495$
                                   </span>
                                </div>
                              </div>
                            </div>
</Col>



</Row>






                      </Container>

































                      {/* cards-prices-for-beaches */}





                      <Container className="gaib-ho-gallery-grid-section-pictures" id="beaches-cards">


<Row>

  <Col xs={4} lg={4} md={12} sm={12} xs={12}>
    <div className="images-in-grid-section-pictures-gallery krk-bg-image-beaches">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          KRK ISLAND, CROATIA

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Best time to go: April to October <span
           className="price-written-grid-section-pictures-gallery">
             495$
           </span>
        </div>
      </div>
    </div>

  </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery larnaca-bg-image-beaches">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          larnaca, cyprus
        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
          Enjoy the perfect climate <span
           className="price-written-grid-section-pictures-gallery">
             295$
           </span>
        </div>
      </div>
    </div>

     </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery malaga-bg-image-beaches">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          MALAGA, SPAIN

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Best time to go: March to November <span
           className="price-written-grid-section-pictures-gallery">
             795$
           </span>
        </div>
      </div>
    </div>
  
  </Col>



</Row>







<Row className="margin-bw-pics-grid-section-pictures-gallery">

<Col xs={6} lg={6} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery sebastian-bg-image-beaches">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          SAN SEBASTIAN, SPAIN
        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        A wonderful city to explore. <span
           className="price-written-grid-section-pictures-gallery">
             225$
           </span>
        </div>
      </div>
    </div>
</Col>




<Col xs={6} lg={6} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery algrave-bg-image-beaches">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          ALGARVE, PORTUGAL

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Best time to go : from March to November
<span className="price-written-grid-section-pictures-gallery">
             345$
           </span>
        </div>
      </div>
    </div>
</Col>








</Row>






</Container>



































  {/* cards-prices-for-nature */}





  <Container className="gaib-ho-gallery-grid-section-pictures"  id="nature-cards">


<Row>

  <Col xs={4} lg={4} md={12} sm={12} xs={12}>
  <div className="images-in-grid-section-pictures-gallery Cascadia-bg-image-nature">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  Cascadia, America

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                One of the world’s beautiful place. <span
                                   className="price-written-grid-section-pictures-gallery">
                                     695$
                                   </span>
                                </div>
                              </div>
                            </div>

  </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery hawaii-bg-image-nature">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  Hawaii, USA 
                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                  Enjoy the perfect climate <span
                                   className="price-written-grid-section-pictures-gallery">
                                     795$
                                   </span>
                                </div>
                              </div>
                            </div>

     </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery malaga-bg-image-beaches">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          MALAGA, SPAIN

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Best time to go: March to November <span
           className="price-written-grid-section-pictures-gallery">
             795$
           </span>
        </div>
      </div>
    </div>
  
  </Col>



</Row>







<Row className="margin-bw-pics-grid-section-pictures-gallery">

<Col xs={6} lg={6} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery reef-bg-image-nature">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          Coral reef, Australia
        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        A wonderful place to explore. <span
           className="price-written-grid-section-pictures-gallery">
             525$
           </span>
        </div>
      </div>
    </div>
</Col>




<Col xs={6} lg={6} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery pltivice-bg-image-nature">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          pltivice Lakes, Croatia

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        One of the most impressive karst entities in the world.
<span className="price-written-grid-section-pictures-gallery">
             1345$
           </span>
        </div>
      </div>
    </div>
</Col>








</Row>






</Container>


                     

































 {/* cards-prices-for-ski */}






 <Container className="gaib-ho-gallery-grid-section-pictures" id="ski-cards">

<center>
<div className="ski-desinations-heading">
 Best Ski Destinations in Europe
 </div>
 </center>


 <div className="ski-desinations-heading-below-text">
 Europe is the best destination in the world for ski lovers as it is in Europe that you will 
 find the best ski resorts with good snow coverage, excellent facilities and a wide choice of
  activities. Whether you like thrills, snowboarding, snowshoe walks in the snow or just skiing
   with family and friends on the best tracks, this selection of the <b>best places to ski
    in Europe </b>  is for you.<br/> <br/>

 

For those who are a little less energetic, the <b>best ski resorts in Europe</b>  have other 
offers to seduce you with: some of the most beautiful scenery in the world with traditional and
 typical villages but also ski on mountain tops with breathtaking views, charming hotels equipped
  with wellness centers, trendy shops and quality restaurants and activities for all ages. <br/> <br/>

 

Prepare your sunglasses, your skis, snowboards and sleds and get ready for the best ski holiday 
in the European best ski resorts.
 </div>









<Row>

  <Col xs={4} lg={4} md={12} sm={12} xs={12}>
  <div className="images-in-grid-section-pictures-gallery kitzbuhel-bg-image-ski">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  KITZBÜHEL, AUSTRIA

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                Season: 19th October 2019 - 1st May 2020 <span
                                   className="price-written-grid-section-pictures-gallery">
                                     495$
                                   </span>
                                </div>
                              </div>
                            </div>

  </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery cortina-bg-image-ski">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  CORTINA D'AMPEZZO, ITALY

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                Season: November 2019 - May 2020 <span
                                   className="price-written-grid-section-pictures-gallery">
                                     1195$
                                   </span>
                                </div>
                              </div>
                            </div>

     </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery crans-bg-image-ski">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          CRANS-MONTANA, SWITZERLAND


        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: december 2019 - April 2020 <span
           className="price-written-grid-section-pictures-gallery">
             795$
           </span>
        </div>
      </div>
    </div>
  
  </Col>



</Row>







<Row className="margin-bw-pics-grid-section-pictures-gallery">

<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery livingo-bg-image-ski">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          LIVIGNO, ITALY

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: November 2019 - 1st May 2020 <span
           className="price-written-grid-section-pictures-gallery">
             225$
           </span>
        </div>
      </div>
    </div>
</Col>




<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery gardena-bg-image-ski">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          VAL GARDENA, ITALY


        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: december 2019 -April 2020
<span className="price-written-grid-section-pictures-gallery">
             345$
           </span>
        </div>
      </div>
    </div>
</Col>




<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery valloire-bg-image-ski">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          VALLOIRE, FRANCE


        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: December 2019 - April 2020
<span className="price-written-grid-section-pictures-gallery">
             345$
           </span>
        </div>
      </div>
    </div>
</Col>








</Row>



<div  className="button-ski-resort-holiday-gallery-center">

<Button 
            variant="outline-dark"
            className="button-ski-resort-holiday-gallery"
            style={{height:'45px'}}

            >
             Famous Ski Resorts

            </Button>


            </div>


</Container>




































{/* cards-prices-for-events */}






<Container className="gaib-ho-gallery-grid-section-pictures" id="events-cards">

<center>
<div className="events-desinations-heading">
SELECTION OF OUR FAVORITE EVENTS.
 </div>
 </center>




<Row>

  <Col xs={4} lg={4} md={12} sm={12} xs={12}>
  <div className="images-in-grid-section-pictures-gallery uefa-bg-image-events">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  UEFA EURO 2020

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                JUNE 12, 2020 – JULY 12, 2020 <span
                                   className="price-written-grid-section-pictures-gallery">
                                     2495$
                                   </span>
                                </div>
                              </div>
                            </div>

  </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery augusta-bg-image-events">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  The Masters, Augusta, GA.

                                </div>
                                <div className="text-written-inside-images-grid-section-pictures-gallery">
                                Season: November 2019 - May 2020 <span
                                   className="price-written-grid-section-pictures-gallery">
                                     1195$
                                   </span>
                                </div>
                              </div>
                            </div>

     </Col>




  <Col xs={4} lg={4} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery fifa-bg-image-events">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          FIFA World Cup


        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: junr 2020 - july 2020 <span
           className="price-written-grid-section-pictures-gallery">
             795$
           </span>
        </div>
      </div>
    </div>
  
  </Col>



</Row>







<Row className="margin-bw-pics-grid-section-pictures-gallery">

<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery venice-bg-image-events">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          Venice Carnival, ITALY

        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: Jan 30, 2021 – Feb 16, 2021 <span
           className="price-written-grid-section-pictures-gallery">
             225$
           </span>
        </div>
      </div>
    </div>
</Col>




<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery cricket-bg-image-events">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          Cricket World Cup


        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Season: december 2019 -April 2020
<span className="price-written-grid-section-pictures-gallery">
             445$
           </span>
        </div>
      </div>
    </div>
</Col>




<Col xs={4} lg={4} md={4} sm={12} xs={12}>
<div className="images-in-grid-section-pictures-gallery chinese-bg-image-events">
      

      <div className="text-area-inside-images-grid-section-pictures-gallery ">
        <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
          <span> 
            <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
          </span>
          Chinese New Year


        </div>
        <div className="text-written-inside-images-grid-section-pictures-gallery">
        Friday, February 12, 2021
<span className="price-written-grid-section-pictures-gallery">
             345$
           </span>
        </div>
      </div>
    </div>
</Col>








</Row>







</Container>
      


       

       




















{/* cards-prices-for-all-year */}






<Container className="gaib-ho-gallery-grid-section-pictures" id="year-cards">

<center>
<div className="year-desinations-heading">
BEST FOR ALL YEAR-ROUND.
 </div>
 </center>




<Row>

  <Col xs={6} lg={6} md={12} sm={12} xs={12}>
  <div className="images-in-grid-section-pictures-gallery music-bg-image-year">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  Best

                                </div>
                                <div className="text-written-inside-year-images-grid-section-pictures-gallery">
                                Summer Music Festivals
                                </div>
                              </div>
                            </div>

  </Col>




  <Col xs={6} lg={6} md={12} sm={12} xs={12}>

  <div className="images-in-grid-section-pictures-gallery easter-bg-image-year">
                              

                              <div className="text-area-inside-images-grid-section-pictures-gallery ">
                                <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
                                  <span> 
                                    <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
                                  </span>
                                  Best

                                </div>
                                <div className="text-written-inside-year-images-grid-section-pictures-gallery">
                                easter breaks
                                </div>
                              </div>
                            </div>

     </Col>




 



</Row>






<Row className="margin-bw-pics-grid-section-pictures-gallery">
<Col xs={12} lg={12} md={12} sm={12} xs={12}>
<Link>
<div className="images-in-grid-section-pictures-gallery eve-bg-image-year">
    

    <div className="text-area-inside-images-grid-section-pictures-gallery ">
      <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
        <span> 
          <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
        </span>
        Best


      </div>
      <div className="text-written-inside-year-images-grid-section-pictures-gallery">
      New Year Eve
      </div>
    </div>
  </div>
  </Link>
</Col>
</Row>







<Row className="margin-bw-pics-grid-section-pictures-gallery">

<Col xs={6} lg={6} md={6} sm={12} xs={12}>
<Link>
<div className="images-in-grid-section-pictures-gallery spring-bg-image-year">
    

    <div className="text-area-inside-images-grid-section-pictures-gallery ">
      <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
        <span> 
          <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
        </span>
        Best


      </div>
      <div className="text-written-inside-year-images-grid-section-pictures-gallery">
      Spring breaks
      </div>
    </div>
  </div>
  </Link>
</Col>




<Col xs={6} lg={6} md={6} sm={12} xs={12}>
<Link>
<div className="images-in-grid-section-pictures-gallery halloween-bg-image-year">
    

    <div className="text-area-inside-images-grid-section-pictures-gallery ">
      <div className="text-heading-written-inside-images-grid-section-pictures-gallery">
        <span> 
          <i className="fas fa-map-pin map-pin-icon-grid-section-pictures-gallery">&nbsp;</i>
        </span>
        Best


      </div>
      <div className="text-written-inside-year-images-grid-section-pictures-gallery">
      Halloween
      </div>
    </div>
  </div>
  </Link>
</Col>












</Row>










</Container>


























{/* stories section */}





<section className="section-stories">
<div class="u-center-text u-margin-bottom-second-heading-gallery-grid-section-pictures" id="ourtourid">
                  <div  className="heading-secondary-section-gallery-holiday-stories-section-pictures">
                      We Make People Genuinely Happy
                  </div>
                  </div>
                 
<Row className="row-in-stories-section-wow-gallery">
  <div className="story-gallery-holiday">
   
    <figure className="story__shape">
      <img 
      src="IMAGES/city-images/wow-gallery/stories/nat-8.jpg"
       alt="person on tour"
       className="story-gallery-holiday-image"
       >
       </img>
     <figcaption className="story__image-caption">
Mary Jaimes
     </figcaption>
    </figure>

    
    <div className="story__text">
      <div className="heading-tertiary-story-section-gallery-holiday">
      I HAD THE BEST WEEK WITH MY FAMILY
      </div>
      <div className="heading-tertiary-text-story-section-gallery-holiday">
      I visited for the Yoga & Activity week at the beginning of May and had the best 
      time. Myself and 4 others were travelling solo, and the other 2 were a couple -
       we all really connected as a group, and had a great week of hiking, kayaking, wild
       swimming, caving, and of course - daily yoga sessions. All of my favourite things

      </div>
    </div>
   
  </div>
</Row>



<Row className="row-in-stories-section-wow-gallery">
  <div className="story-gallery-holiday-two">
   
    <figure className="story__shape">
      <img 
      src="IMAGES/city-images/wow-gallery/stories/nat-8-2.jpg"
       alt="person on tour"
       className="story-gallery-holiday-image-two"
       >
       </img>
       <figcaption className="story__image-caption-two">
Julian
     </figcaption>
    </figure>
    
    <div className="story__text">
      <div className="heading-tertiary-story-section-gallery-holiday">
      Best trip ever WITH MY Friends
      </div>
      <div className="heading-tertiary-text-story-section-gallery-holiday">
      It was best week ever. 7 days in Hurghada in hotel “Hawaii Riviera” with
       perfect territory, good food and drink and
       unforgettable animation team Thanks for everything. Hope for new meeting in the nearest future ️

      </div>
    </div>
   
  </div>
</Row>




<div className="u-center-text">
  <Link className="button-text-wow-story-section">
Read more Stories &rarr;
  </Link>

</div>





</section>














{/* <br/><br/><br/> */}










      </div>
    );
  }
}
export default Wowholidaygallery;
