import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { FaBeer } from "react-icons/fa";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import "../Wowholidayblogpost6/blogpost6.scss";

class Blogpost6 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
    };
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        <div className="td-post-header-holder-blogcard6 td-image-gradient-blogcard6">
          <div className="td-post-featured-image-blogcard6">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard6"
              src="IMAGES/city-images/wow-blog/additional-cards/blog6/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard6">
              <ul className="td-category-blogcard6">
                <li className="entry-category-blogcard6">
                  <a
                    target="blank"
                    href="https://www.businessinsider.com.au/best-caribbean-island-for-every-traveler-2017-3#"
                  >
                    Caribbean Islands
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard6">
                News & Trends: WOW Travelling to Island
              </h1>

              <h4 className="entry-title-two-blogcard6">
                Looking out for the Best Caribbean islands for every type of
                traveler...
              </h4>

              <div className="td-module-meta-info-blogcard6">
                <span className="td-post-date-blogcard6">
                  <time
                    className="entry-date-blogcard6 updated td-module-date-blogcard6"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    24 NOV 2019
                  </time>
                </span>

                <div className="td-post-views-blogcard6">
                  <i className="fa fa-eye view-icon-views-blogcard6"></i>
                  <span className="td-nr-views-10968">6235</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard6">
            <div className="heading-secondary-uk-section-wow-blogscard6 ">
              WOW News & Trends
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard6-description-area-of-main--text-above">
            With pristine beaches, lush palm trees, and crystal-blue water,
            there’s no destination that says “dream vacation” quite like the
            Caribbean. But, while every Caribbean island is as ‘grammably
            tropical as the next, scratch the surface and you’ll find each one
            has its own unique vibe. So, which one’s right for you?
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard6-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog6/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard6-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog6/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard6-description-area-of-main--text-above">
            When it comes to making the most of Europe’s warmer months, there’s
            no more idyllic destination than the Greek islands. With over 200
            inhabited slices of paradise to choose from, there’s an island — or
            three — to suit every traveler’s taste. Read on to discover which
            ones are right for you.
          </p>

          <p class="blogscard6-description-area-of-main--text-above">
            We hope you can keep a secret. It’s time to leave the crowds behind
            and discover eight beautiful Mediterranean’s islands, where hunting
            for space on a crowded beach simply doesn’t exist. So put down your
            guide to Mallorca and start planning a trip to one of these dreamy
            destinations…
          </p>
        </Container>
        <br />

        <div className="gradient-back-travel-blogscard6">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard6-grid-text">
                  <div className="travel-blogscard6-page-araea-sub-headings110">
                    COTTON HOUSE
                  </div>

                  <div className="travel-blogscard6-page-page-text">
                    The choice island of the British royal family, Mustique is
                    best known for its '70s playboy hedonism, when barons and
                    princesses threw headline-hitting scandalous parties. Cotton
                    House, the sole full-service hotel on the isle, boasts
                    plenty of awards alongside its impressive guest book
                    brimming with celebrity signatures. Opulent mansions of the
                    rich and famous, like those of Mick Jagger and David Bowie,
                    speckle the remote hills. Private golf buggies enable the
                    few privileged guests to explore nine bewitching beaches,
                    where you'd be hard-pressed to see another soul.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog6/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard6">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog6/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard6-grid-text">
                  <div className="travel-blogscard6-page-araea-sub-headings110">
                    Best for families: Bahamas
                  </div>

                  <div className="travel-blogscard6-page-page-text">
                    Easily accessible from the US, the Bahamas have a variety of
                    all-inclusive resorts where both parents and children will
                    enjoy their trip.One of the best known is Atlantis, which
                    has its own water park, aquarium, movie theatre, kids' club,
                    and game room.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard6">
            <div className="heading-secondary-help-community-section-wow-blogscard6 ">
              Best Caribbean Islands for Travelling
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard6">
            <div class="description_of_card_of_blogscard6_area">
              <h2 class="blogscard6-description-area-of-main-headings">
                If you like a good party, you’ll want to visit…
              </h2>
              <p class="blogscard6-description-area-of-main--text">
                <span style={{ fontWeight: "bold", color: "#f87370" }}>
                  Mykonos{" "}
                </span>
                Equally popular with the international jet-set as it is with
                backpackers, you’ll never run out of things to do on Mykonos.
                Discover trendy beach bars and a club scene that rivals Ibiza’s.
                For a change of pace, take a yacht to nearby Delos, said to be
                the birthplace of the Greek god Apollo. Here, you can visit
                ancient temples and take a refreshing dip in the sapphire waters
                of the Aegean before you head back to the hustle and bustle of
                Mykonos.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog6/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog6/help-tips-grid2.jpg" />
                </Col>
              </Row>

              <h2 class="blogscard6-description-area-of-main-headings">
                If you’re a foodie, get a taste of…
              </h2>
              <p class="blogscard6-description-area-of-main--text">
                <span style={{ fontWeight: "bold", color: "#f87370" }}>
                  Santorini{" "}
                </span>
                WOW for shopping will allow you to book a car of your choice and
                our Leader will take you to the places where you wanted to shop
                and even stays with you waiting for you to shop and take to back
                while you are done. You don’t even have to carry all the bags
                with you, you can place them in the car and your Leader will
                take the responsibility to secure those bags and even as per
                your instructions you can let the Leader carry your bags.
                <br />
                <br />
                Santorini is one of the Cyclades islands in the Aegean Sea. It
                was devastated by a volcanic eruption in the 16th century BC,
                forever shaping its rugged landscape. The whitewashed, cubiform
                houses of its 2 principal towns, Fira and Oia, cling to cliffs
                above an underwater caldera (crater). They overlook the sea,
                small islands to the west and beaches made up of black, red and
                white lava pebbles.
              </p>

              <h2 class="blogscard6-description-area-of-main-headings">
                Porquerolles, France
              </h2>
              <p class="blogscard6-description-area-of-main--text">
                The French Riviera is a byword for luxury — but where can you
                peel back this layer of glitz and glamor to experience
                traditional French culture? Enter Porquerolles, an idyllic
                island located just 20 minutes by ferry from Hyères. The locals
                are loco for pedal power, so rent a bike and get exploring. You
                can rent one from almost every corner, and they’re the perfect
                mode of transport for stopping at one or two (or three) of the
                numerous wineries that are scattered across the island. The
                ideal location to have no set plans, feel free to explore the
                winding pathways leading to breathtaking (and often empty)
                beaches. One of the more popular beaches is Pointe Prime, where
                a narrow ribbon of sand connects a smaller island to the beach.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard6">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog6/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard6">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog6/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard6-description-area-of-main-headings">
                Explore Nisyros, Greece sights
              </h2>
              <p class="blogscard6-description-area-of-main--text">
                It’s pretty tough to find a Greek island that isn’t beautiful,
                but you’ll struggle to find one as special as Nisyros. Not only
                is the island literally a volcano, but it’s also home to
                gorgeous churches and monasterie. Make sure to pay Panagia
                Spiliani and Agios Theologos a visit. Another perk? Few
                travelers venture here compared to Kos, its famous neighbor. In
                the quaint port town of Mandraki, you’ll find a mixture of white
                and colorful houses that are actually made of volcanic rocks.
                Take a walk on the wild side by hiking through the volcano
                crater. If you’re after water adventure, the island’s rugged
                coastline is ideal for windsurfing and snorkeling.
              </p>

              <h2 class="blogscard6-description-area-of-main-headings">
                Private Island Paradises in the Caribbean
              </h2>
              <p class="blogscard6-description-area-of-main--text">
                Looking to get away with your extra-special somebody—and,
                preferably, nobody else? These romantic private island resorts
                deliver sun, surf, sand, and seclusion, at off-the-path oases
                that you'll have (practically) all to yourselves. It's like
                being marooned on a desert island but with all of the amenities
                of a luxury resort, along with a doting staff. Each of these
                ultimate get-away-from-it-all island retreats are private
                paradises tailored for two—just be prepared to pay for the
                privilege of playing at castaways.
              </p>

              <ul className="list-circle-type-blogscard">
                <li
                  class="blogscard6-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                      PETER ISLAND RESORT & SPA:{" "}
                    </span>
                    The largest private island resort in the British Virgin
                    Islands, Peter Island Resort & Spa has been welcoming
                    Caribbean-bound couples in search of easy-going elegance for
                    more than 30 years. The 1,800-acre tropical isle brings
                    together five beaches (including dreamy Deadman's Beach),
                    countless coves, swaying palm trees, and forested mountains
                    for an unspoiled island escape. Bunk down in one of 55
                    units, including indulgent private villas with your own
                    staff and incredible ocean views; beachfront junior suites,
                    set just steps from the sea; or value-friendly ocean-view
                    rooms. Couples can opt for treatments at the
                    Ayurveda-inspired spa (book one of the open-air bohios for
                    couples' massages); book private beachfront dining
                    experiences (like picnics in secluded coves or tiki
                    torch-lit dinners); throw back cocktails with a Caribbean
                    steel drum band; or partake in activities like snorkeling,
                    sea kayaking, deep sea fishing, hiking, or diving (some
                    activities carry fees). Guests enjoy a three-to-one
                    staff-to-guest ratio, with intuitive service to attend to
                    your every need—from unpacking your bags to crafting your
                    customized island itinerary.!{" "}
                  </p>
                </li>
                <li
                  class="blogscard6-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <span style={{ fontWeight: "bold" }}>CAYO ESPANTO: </span>
                  Just off the coast of Belize, this intimate private island
                  paradise—measuring just four palm-forested, turquoise
                  water-enclosed acres—beckons honeymooners for pampering and
                  privacy. Sign up for optional activities like world-class
                  sportfishing, snorkeling, or diving on the world's second
                  longest barrier reef, and indulge in all-inclusive meals,
                  catered to guest preferences (served at your villa, on the
                  beach, or the dock).
                </li>
                <li class="blogscard6-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>
                    Best for divers: Curacao:{" "}
                  </span>
                  With 40 different diving areas that cover 65 individual sites,
                  Curacao is a premiere destination for divers and snorkelers.
                  Divers can explore incredible underwater wrecks like the
                  Tugboat and Superior Producer, explore the Curacao Underwater
                  Marine Park, or take a peek at the famous Mushroom Forest,
                  made of coral formations that resemble mushrooms.
                </li>
              </ul>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard6">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog6/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard6">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog6/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard6-description-area-of-main-headings">
                If you love to swim, paddle over to… visit Corfu.
              </h2>

              <p class="blogscard6-description-area-of-main--text">
                Corfu. The island is famous for Corfu Town, a colorful
                historical city nestled on the waters of the Ionian Sea. But it
                also offers some of the most interesting swimming experiences on
                the islands. If you want more than sunbathing, try exploring
                Corfu’s underwater caves and grottos. Cruise to the Blue Caves
                from Corfu Town and meet the local monk seals, with a stop-off
                at Paradise Beach — it’s just as nice as its name suggests.{" "}
              </p>

              <h2 class="blogscard6-description-area-of-main-headings">
                Best for luxury: Saint-Barthélemy
              </h2>

              <p class="blogscard6-description-area-of-main--text">
                Saint-Barthélemy -- aka St. Barts or St. Barths -- is a
                French-speaking Caribbean island that's popular among the rich
                and famous, thanks to its stunning beaches, designer boutiques,
                and gourmet restaurants. Celebrities can often be seen mooring
                their yachts here, while beach clubs like Nikki Beach host
                parties that last well into the night. Accommodations include
                private villas and rooms that come with private butlers.{" "}
              </p>


              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard6">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog6/img5.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard6">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog6/img6.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <p class="blogscard6-description-area-of-main--text">
              Travellers often explore the various islands of the British Virgin Islands by boat.
              The beaches of caribbean are famous for their powder-fine white sands and turquoise waters. 
              With wide-shaded expanses and quiet retreats, the island's beaches are perfection for relaxing.

Many of them are also lined with resorts, restaurants, and beach bars so you never have to stray too far 
from the water. Plus, steady trade winds make kitesurfing a popular beach activity here.{" "}
              </p>

              <div class="description_of_card_of_blogscard6_bottom_area">
                <div class="description_of_card_of_blogscard6_bottom_area_previous">
                  <h3>
                    <i class="fas fa-reply" aria-hidden="true"></i>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard6_bottom_area_next">
                  <h3>
                    <i class="fa fa-mail-forward" aria-hidden="true"></i>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost6;
