import React, {Component} from 'react';
import {
  Navbar,
  Nav,
  Form,
  NavDropdown,
  Button,
  Container,
  Row,
  Col
} from "react-bootstrap";
class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (
      
          <Navbar  expand="lg" className="navbar">
              <Container>
                  <Row>
                  <Col >
  <Navbar.Brand href="#home">Online </Navbar.Brand>

  </Col>


<Col >

  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" >
      
  
      
   
    <Nav>
      <Nav.Link href="#home">Home</Nav.Link>
      <Nav.Link href="#link">Link</Nav.Link>
      <NavDropdown title="Dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown>
     
    
    </Nav>
    <Form inline>
      <Button variant="outline-success">Search</Button>
    </Form>
    
    </Navbar.Collapse>
  </Col>
  

  
  </Row>
  </Container>
</Navbar>          


   
    );
  }
}
export default Header;
