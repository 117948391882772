import React, { Component } from "react";

import {
  Form,
  Card,
  Container,
  Button,
  Header,
  FormLabel,
  FormControl,
  Col,
  Row,
  View
} from "react-bootstrap";


class Adminsignup extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (
      
        <div >
      
         
     
            <Row>
            
              <Col xs={0} lg={8} md={8} sm={8}>

              <div className="background-image col-12  " >


                
                  <Container>
                  <Container>

                  <Row>
                  <img class="wow-image-in-admin-sign-up"   src="IMAGES/wowboard.png"/>
                    
                  </Row>
                  <Row>
                    <Col>
                    <div className="ambition-text-in-sign-up-page">
                      AMBITION &nbsp; WITH &nbsp; WOW</div>
                      <div className="text-written-behind-ambition-text-in-admin-sign-up">
                    Offer multiple types of Commutation services<br/> based on requirements of variety of users.
                   </div>
                    
                    </Col>
                    
                  
                  </Row>

                  <br/>
                  <br/>
                  <br/>
                  
                 



                  <Row className="row-of-icons-in-admin-sign-up">
                    <Col xs={3} lg={3} md={3} sm={3}>
                     <img className="icon-in-admin-sign-up-page-background-image-car-pehla" src="IMAGES/car-01.png"></img>
                     <div className="text-written-below-icons-in-admin-sign-up">
                       Categories</div> <div className="text-written-in-categories-in-admin-sign-up">12+</div>
                     
                    </Col>


                    <Col xs={3} lg={3} md={3} sm={3}>
                    <div>
                     
                    <img className="icon-in-admin-sign-up-page-background-image" src="IMAGES/2 Car-01.png"></img>
                    <div className="text-written-below-icons-in-admin-sign-up">
                       Vehicles
                     </div>
                     <div style={{marginLeft: '20px'}} className="text-written-in-categories-in-admin-sign-up">
                       50+
                     </div></div>
                    </Col>


                    <Col xs={3} lg={3} md={3} sm={3}>
                    <img className="icon-in-admin-sign-up-page-background-image" src="IMAGES/3-01.png"></img>
                     <div className="text-written-below-icons-in-admin-sign-up">
                       Leaders</div> <div className="text-written-in-categories-in-admin-sign-up" style={{marginLeft: '20px'}}>3k+</div>
                     

                    </Col>

                    <Col xs={3} lg={3} md={3} sm={3}>
                    <img className="icon-in-admin-sign-up-page-background-image" src="IMAGES/Loc-01.png"></img>
                     <div style={{marginLeft: '16px'}} className="text-written-below-icons-in-admin-sign-up">
                       Users <div style={{marginLeft: '7px', }}>10k+</div>
                     </div>

                    </Col>
                    






                  </Row>
                  <Row>
                    <Col>
                    <div className="text-written-in-footer-of-admin-sign-up">
                      Copyrights &copy; 2020. WOW. All Rights Reserved.
                    </div>



                    </Col>


                    <Col>
                    <div className="text-written-in-footer-of-admin-sign-up">
                      Terms of Use|Privacy Policy.
                    </div>

                    </Col>
                  </Row>










                  </Container>
                  </Container>

              

                  </div>
              </Col>
              
              

              <Col xs={12} lg={4} md={4} sm={4}>
              <div className="mobile-view-background-image"> <Container>
                 

              <Form className="height-of-the-form" >
              
 

    
 
              
     
              <p className="password-reset-text-in-admin-sign-up">SIGN UP</p>
<p className="welcome-wow">CREATE YOUR ACCOUNT TO GET STARTED</p>
       
                



                  





{/* <div className="Email-border-password-reset"> */}


                  <div  className="Email-border">











<Form.Group className="password-text-forms-group" controlId="formBasicEmail">
<input type="text" id="text1" class="input-section" placeholder="Full Name"></input>

<Form.Label><h3 className="password-text-forms">Enter a Valid name</h3></Form.Label>
</Form.Group>



<Form.Group className="password-text-forms-group " controlId="formBasicPassword">
<input type="text" id="text2" class="input-section" placeholder="Company Name"></input>

<Form.Label><h3 className="password-text-forms">Enter Company Name</h3></Form.Label>
</Form.Group>






<Form.Group className="password-text-forms-group" controlId="formBasicPassword">
<input type="text" id="text3" class="input-section" placeholder="Website"></input>

<Form.Label><h3 className="password-text-forms">Enter valid Website</h3></Form.Label>
</Form.Group>




<Form.Group className="password-text-forms-group" controlId="formBasicPassword">
<input type="text" id="text4" class="input-section" placeholder="Mobile Number"></input>

<Form.Label><h3 className="password-text-forms">Select Country</h3></Form.Label>
</Form.Group>





<Form.Group className="password-text-forms-group" controlId="formBasicPassword">
<input type="text" id="text5" class="input-section" placeholder="E-Mail"></input>

<Form.Label><h3 className="password-text-forms">Please enter a valid email address</h3></Form.Label>
</Form.Group>


<Form.Group className="password-text-forms-group" controlId="formBasicPassword">
<input type="password" id="text6" class="input-section-text-id-6" placeholder="Password"></input>

<Form.Label><h3 className="password-text-forms">Enter a strong valid password</h3></Form.Label>
</Form.Group>



<Form.Group className="password-text-forms-group" controlId="formBasicPassword">
<input type="password" id="text7" class="input-section" placeholder="Confirm Password"></input>

<Form.Label><h3 className="password-text-forms">Password do not match</h3></Form.Label>
</Form.Group>











  


 
 

  
 


</div>
{/* </div> */}

<button type="button" class="reset-buttons" >SIGN UP</button>


</Form>
{/* <div class="field user-name">
                            <input type="email" id="email" name="email_id" data-rule-required="true" placeholder="E-mail" autocomplete="off" required=""/>
                            <span class="validate-img"></span>
                        </div> */}










</Container></div>
              </Col>
              
            </Row>
            

         

            </div>
      


      

    

    


      


      
  


    




    

       
        
       

    
   
   

    


 
     
    
    
    );
  }
}
export default Adminsignup;