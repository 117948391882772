import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Visible, Hidden, setConfiguration } from "react-grid-system";
import ReactToPrint from 'react-to-print-advanced';
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
  Media,
  DropdownButton,
  Dropdown,
  Modal,
  ButtonGroup,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";



class Lenooneinvoice extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      setSmShow: false,
      setLgShow: false,
      show: false,
      lgShow: false
    };
  }


  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  handleModal(){
    this.setState({show:!this.state.show})
  }

  handleModaltwo(){
    this.setState({lgShow:!this.state.lgShow})
  }
  


  render() {
    const { setSmShow, setLgShow, show} = this.state;
    return (
      <div>







        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                
                  <div className="new-invoice-payment-voucher-header-text-area" role="alert">
                    <h2>Invoice#1</h2>

                    <div className="two-button-edi-save-new-invoice-payment-voucher">

                   

                      <div className="le-button--with-actions--savecontinue-newinvoice-paymentvoucher">
                        <button className="le-button">
                            Create another invoice</button>
                        <div
                          className="le-dropdown-newinvoice-paymentvoucher is-open"
                          id="accordion-5ed01b058660a-card-0"
                          data-target="#accordion-5ed01b058660a-collapse-0"
                          data-toggle="collapse"
                          role="tab"
                          aria-expanded="false"
                        >
                          <button
                            className="le-dropdown__toggle-newinvoice-paymentvoucher le-button"
                            data-controls="accordion-5ed01b058660a-collapse-0"
                          >
                            <i className="	fa fa-caret-down">
                              <use></use>
                            </i>
                          </button>
                          <ul
                            class="le-dropdown__menu--right-newinvoice-paymentvoucher"
                            role="menu"
                            aria-labelledby="accordion-5ed01b058660a-card-0"
                            className="d-print-block collapse"
                            collapsed=""
                            id="accordion-5ed01b058660a-collapse-0"
                            role="tabpanel"
                          >
                            <li class="le-dropdown__menu__item-newinvoice-paymentvoucher" role="menuitem">
                              <a
                                class="le-dropdown__menu__link-newinvoice-paymentvoucher"
                                rel=""
                                target="_self"
                              >
                                Save and send...
                              </a>
                            </li>
                            <li class="le-dropdown__menu__item-newinvoice-paymentvoucher" role="menuitem">
                              <a
                                class="le-dropdown__menu__link-newinvoice-paymentvoucher"
                                rel=""
                                target="_self"
                              >
                                Save and record a payment
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div></div>


                  <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top-invoiceone"></div>
                      </div>

                  <div class="invoice-preview__warning-message le-text--body"></div>













                  </div>
              </Col>
            </Row>
          </Container>
        </div>

















<div className="bg-color-invoice-email">
    <div className="new-email-invoice-div-body-le">

    <div className="email-invoice-body-inside__header__logo">
    <img src="https://wave-prod-accounting.s3.amazonaws.com/uploads/invoices/business_logos/efcee799-07f1-4667-a72c-eaf2265ecbb7.png" />
     </div>


<div className="email-text-le-inoice13">
    Bader Hamad Al Zaabi Advocates & Legal Emirates
</div>


<div className="email-invoice13__divider--full-width">
    <div className="le-divider-email-inoice13"></div>
     </div>



    <div className="invoice13-email-text">Invoice for AED 137.00 due by <br/> july 02, 2020</div>

<div className="set-anchor-link-email-invoice13">
    <a href="#" className="anchor-link-email-invoice13">Invoice#: 13</a>
 </div>



 <div className="invoice13-email-text-le">Legal Emirates</div>


 <div className="invoice13-email-text-phoneemail">Phone:+971 4 255 5111 <br/> info@legalemirates.com</div>


</div>
                

</div>




<Button onClick={()=>{this.handleModal()}}>Small modal</Button>{' '}
      <Button onClick={()=>{this.handleModaltwo()}} variant="outline-dark">Large modal</Button>
      <Modal
        size="sm"
        show={this.state.show}
        onHide={()=>this.handleModal()}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Small Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...asdas</Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{this.handleModal()}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      
      <Modal
        size="lg"
        show={this.state.lgShow}
        onHide={() => this.handleModaltwo()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Large Modal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...</Modal.Body>
      </Modal>
        










      </div>
    );
  }
}

export default Lenooneinvoice;