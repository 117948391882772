import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Media, Col, Figure } from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../RentalCarsWowHoliday/RentalCarsBookingFilterPage.scss";
import Rental_Cars_Search from "./CarsSectionRentalCarsSearch";
import RentalCarsAccordion from "./RentalCarsBookingAccordion";
import { Table, Tag, Space } from "antd";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

const menustarrating = (
  <Menu>
    <Menu.Item>
      <a rel="noopener noreferrer" href="#">
        Stars (high to low)
      </a>
    </Menu.Item>
    <Menu.Item>
      <a rel="noopener noreferrer" href="#">
        Stars (low to high)
      </a>
    </Menu.Item>
  </Menu>
);

const menudistance = (
  <Menu>
    <Menu.Item>
      <a rel="noopener noreferrer" href="#">
        Distance Far From Pickup
      </a>
    </Menu.Item>
    <Menu.Item>
      <a rel="noopener noreferrer" href="#">
        Distance Closer to Pickup
      </a>
    </Menu.Item>
  </Menu>
);

const menupricerange = (
  <Menu>
    <Menu.Item>
      <a rel="noopener noreferrer" href="#">
        Price (High to low)
      </a>
    </Menu.Item>
    <Menu.Item>
      <a rel="noopener noreferrer" href="#">
        Price (low to high)
      </a>
    </Menu.Item>
  </Menu>
);

const { Column, ColumnGroup } = Table;

// const data = [
//   {
//     key: '1',
//     firstName: 'John',
//     lastName: 'Brown',
//     age: 32,
//     address: 'New York No. 1 Lake Park',
//     tags: ['nice', 'developer'],
//   },
//   {
//     key: '2',
//     firstName: 'Jim',
//     lastName: 'Green',
//     age: 42,
//     address: 'London No. 1 Lake Park',
//     tags: ['loser'],
//   },
//   {
//     key: '3',
//     firstName: 'Joe',
//     lastName: 'Black',
//     age: 32,
//     address: 'Sidney No. 1 Lake Park',
//     tags: ['cool', 'teacher'],
//   },
// ];

class Rental_cars_booking extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      showHide13passengers: true,
      showHide47passengers: false,
      showHideAllTaxis: false,
      cars: [
        { name: "Audi", country: "Germany" },
        { name: "BMW", country: "Germany" },
        { name: "Chevrolet", country: "USA" },
        { name: "Citroen", country: "France" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Mercedes-Benz", country: "Germany" },
        { name: "Renault", country: "France" },
        { name: "Seat", country: "Spain" },
        { name: "Dodge", country: "USA" },
        { name: "BMW", country: "Germany" },
        { name: "Tesla", country: "USA" },
        { name: "Volkswagen", country: "Germany" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Jaguar", country: "United Kingdom" },
        { name: "GMC", country: "USA" },
        { name: "Bentley", country: "United Kingdom" },
      ],
      numberOfitemsShown: 1,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  onChange = (key) => {
    console.log(key);
  };

  hideComponent(name) {
    switch (name) {
      case "showHide13passengers":
        this.setState({
          // showHide13passengers: !this.state.showHide13passengers,
          showHide13passengers: true,
          showHide47passengers: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHide47passengers":
        this.setState({
          // showHide47passengers: !this.state.showHide47passengers,
          showHide47passengers: true,
          showHide13passengers: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHideAllTaxis":
        this.setState({
          // showHideAllTaxis: !this.state.showHideAllTaxis,
          showHideAllTaxis: true,
          showHide13passengers: false,
          showHide47passengers: false,
        });
        break;
      case "showHide13passengersimg":
        this.setState({
          showHide13passengersimg: !this.state.showHide13passengersimg,
        });
      default:
      // null;
    }
  }

  componentWillMount() {}
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="td-post-header-holder-RentalCars-bookingpage-mainClass">
          <div className="td-post-header-holder-RentalCars-bookingpage td-image-gradient-RentalCars-bookingpage">
            <div className="td-post-featured-image-RentalCars-bookingpage">
              <img
                width="1068"
                height="580"
                className="entry-thumb td-animation-stack-type0-2-RentalCars"
                src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/antalyada-rent2.jpg"
              />

              <img
                width="1068"
                height="580"
                className="entry-thumb td-animation-stack-type0-2-RentalCars2"
                src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/antalyada-rent2.jpg"
              />
              <div class="join-us-our-content">
                <div class="MuiContainer-root MuiContainer-maxWidthLg">
                  <div class="airportTaxi-wow-holiday-home-our-story">
                    <h1 class="airportTaxi-wow-holiday-our-team-heading">
                      WOW Rental Cars
                    </h1>
                    <h3
                      className="text-in-h3-airportTaxi"
                      style={{
                        color: "#ffffff",
                        paddingLeft: "0%",
                        textAlign: "left",
                        fontSize: "25px",
                      }}
                    >
                      WITH LATEST TECHNOLOGICAL TRENDS
                    </h3>
                    <h5
                      style={{
                        color: "#ffffff",
                        paddingLeft: "0%",
                        fontSize: "20px",
                      }}
                      className="airportTaxi-banner-top-co"
                    >
                      WOW Easy transportation between the airport and your
                      accommodations.
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Container>
          <Row>
            <Col xl={3} lg={3} md={4} sm={6} xs={12}>
              <div
                className="small-col-table-area-rental-cars-booking-search-page"
                style={{ borderRight: "3px solid #f87370" }}
              >
                <h3>Narrow results:</h3>
                <h6>No# Cars, Places and Company Names</h6>
                <div>
                  <RentalCarsAccordion />
                </div>
              </div>
            </Col>

            <Col xl={9} lg={9} md={8} sm={6} xs={12}>
              <div className="table-area-rental-cars-booking-search-page">
                <h1>Faisalabad, Punjab Pakistan</h1>

                <Row>
                  <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                    <div className="top-row-headings-rental-cars-booking-search">
                      Sort By
                    </div>
                  </Col>
                  <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                    <div className="top-row-headings-rental-cars-booking-search">
                      Featured
                    </div>
                  </Col>
                  <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                    <Dropdown overlay={menustarrating}>
                      <a
                        className="ant-dropdown-link top-row-headings-rental-cars-booking-search"
                        style={{ color: "black" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Star rating <DownOutlined />
                      </a>
                    </Dropdown>
                  </Col>
                  <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                    <Dropdown overlay={menudistance}>
                      <a
                        className="ant-dropdown-link top-row-headings-rental-cars-booking-search"
                        style={{ color: "black" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Distance <DownOutlined />
                      </a>
                    </Dropdown>
                  </Col>
                  <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                    <div className="top-row-headings-rental-cars-booking-search">
                      Guest rating
                    </div>
                  </Col>
                  <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                    <Dropdown overlay={menupricerange}>
                      <a
                        className="ant-dropdown-link top-row-headings-rental-cars-booking-search"
                        style={{ color: "black" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        Price <DownOutlined />
                      </a>
                    </Dropdown>
                  </Col>
                </Row>

                <div class="cancellation-banner-rentalcars-bookingpage">
                  <div class="cancellation-icon-rentalcars-bookingpage">
                    {/* <span class="free-cancellation-icon"></span> */}
                    <span class="far fa-edit cancellation-rentalcars-bookingpage "></span>
                  </div>
                  <div class="cancellation-message-rentalcars-bookingpage">
                    <strong class="cancellation-header-rentalcars-bookingpage">
                      Stay flexible with free cancellation
                    </strong>
                    <p class="cancellation-bodytext-rentalcars-bookingpage">
                      We recommend booking a stay that offers
                      <span> free cancellation </span>
                      just in case your plans change
                    </p>
                  </div>
                </div>

                <Row>
                  <Col xl={2} lg={2} md={4} sm={6} xs={12}>
                    <div className="section-showing-cars-from-economttoluxuary-rentalcars">
                      <Figure>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
                        />
                        <Figure.Caption>Economy</Figure.Caption>
                      </Figure>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={4} sm={6} xs={12}>
                    <div className="section-showing-cars-from-economttoluxuary-rentalcars">
                      <Figure>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
                        />
                        <Figure.Caption>Family</Figure.Caption>
                      </Figure>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={4} sm={6} xs={12}>
                    <div className="section-showing-cars-from-economttoluxuary-rentalcars">
                      <Figure>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
                        />
                        <Figure.Caption>VIP</Figure.Caption>
                      </Figure>
                    </div>
                  </Col>


                  <Col xl={2} lg={2} md={4} sm={6} xs={12}>
                    <div className="section-showing-cars-from-economttoluxuary-rentalcars">
                      <Figure>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
                        />
                        <Figure.Caption>easy</Figure.Caption>
                      </Figure>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={4} sm={6} xs={12}>
                    <div className="section-showing-cars-from-economttoluxuary-rentalcars">
                      <Figure>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
                        />
                        <Figure.Caption>Long Drive</Figure.Caption>
                      </Figure>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={4} sm={6} xs={12}>
                    <div className="section-showing-cars-from-economttoluxuary-rentalcars">
                      <Figure>
                        <Figure.Image
                          width={171}
                          height={180}
                          alt="171x180"
                          src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
                        />
                        <Figure.Caption>Luxuary</Figure.Caption>
                      </Figure>
                    </div>
                  </Col>
                </Row>

                <div>
                  <Rental_Cars_Search />
                </div>

                <div class="cancellation-banner-rentalcars-bookingpage">
                  <div class="cancellation-icon-rentalcars-bookingpage">
                    {/* <span class="free-cancellation-icon"></span> */}
                    <span class="far fa-edit cancellation-rentalcars-bookingpage "></span>
                  </div>
                  <div class="cancellation-message-rentalcars-bookingpage">
                    <strong class="cancellation-header-rentalcars-bookingpage">
                      10 additional properties are not available on your travel
                      dates.
                    </strong>
                    <p class="cancellation-bodytext-rentalcars-bookingpage">
                      Price includes all other taxes and fees. Prices are
                      subject to availability.
                      <span>free cancellation</span>
                      just in case your plans change
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Rental_cars_booking;