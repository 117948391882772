import React, { Component } from "react";
import FontAwesome from "react-fontawesome";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";
import{
  Link
} from "react-router-dom";

class Wowholiday extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    
  }

  
  componentWillMount() {}
  componentDidMount() {}
  render() {
    return (
      <div>
       






       <div className="about-us-wow-holiday-our-container">
       <div class="join-us-our-content">



       {/* <div class="MuiContainer-root MuiContainer-maxWidthLg">
      <div class="about-us-wow-holiday-home-our-story">
        <h1 class="about-us-wow-holiday-our-team-heading" >ABOUT US</h1>
        
          </div>
          </div> */}




       </div>
       </div>






















        

       

        <section
          className="bg-displaced-wrap height-of-about-us-section-in-wow-holiday"
          dir="ltr"
        >
          <div class="bg-displaced-body">
            <div class="shell">
              <div class="inset-lg-left-70 inset-lg-right-70">
                <article class="box-cart bg-ebony-clay">
                  <div class="box-cart-image ">
                    <img
                      className="image-in-about-us-of-wow-holiday"
                      src="IMAGES/wow-holiday.png"
                      alt=""
                      width="342"
                      height="338"
                    />
                  </div>
                  <div class="box-cart-body">
                    <blockquote class="blockquote-complex blockquote-complex-inverse">
                      <h2>About Us </h2>
                      <p className="text-written-in-about-us-in-wow-holiday">
                        with experience more than 22 years,WOW Electronics and
                        Transport services &amp; WOW Worldwide Holiday prides
                        itself to have a group of the best develpers that
                        Our website designs are visually attractive with good typefaces,
                         easy navigation, fully accessible, best legibility, best graphics, 
                         fully cross platform compatible, type style consistency,
                          clean with easy-to-use and powerful web interface designs. 
                          Our designed website's web page elements are fully optimized for quick
                           downloading and superior performance that will work very well 
                           on dial-up connection. Search Engine Optimization (Seo)
                            is also an important part of our web design projects. 
                            Because we know that an effective website is accessible to 
                            search engines if that is designed and optimized well, 
                            It also help visually impaired and even the blind.
                      </p>
                    </blockquote>
                    <div class="contact-us-button ">
                      <Link
                        className="contact-in-wow-holiday-about-us"
                        to="holidaycontactus"
                      >
                        Contact Us
                      </Link>
                      {/* <!--<a href="/common/company profile.pdf" target="_blank" title="Download Company Profile"><i class="fa fa-download"></i></a>--> */}
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: "url(/IMAGES/city-images/aboutus/background-image.jpg)"
            }}
            class="bg-displaced bg-image"
          ></div>
        </section>





<br></br>
<br></br>
<br></br>



        <Container>
            <Row>

                <Col xs={12} sm={5} lg={5} md={4} >
                    <center>
                <img src="IMAGES/city-images/aboutus/icons/unique.png" className="image-our-values-in-about-us-wow-holiday">
                </img>
                </center>
                </Col>



                <Col xs={12} sm={3} lg={3} md={4} >
                
                {/* <div  className="">
<div class="icon_box_inner row ">
<i class="fa fa-flag" aria-hidden="true"></i><span>
<h3 class=""> Integrity</h3></span>
<div class="icon_box_text">
<p></p>
</div>
</div>
</div> */}

<div className="padding-in-our-values-icons-about-wow-holiday">
<Row className="display-icons-about-us-in-mobile-view">
    <Col xs={12} sm={12} lg={3} md={3}>
    {/* <i className="far fa-flag  icons-in-about-us-wow-holiday"></i> */}
    <img src="IMAGES/city-images/aboutus/icons/trusted.png" alt="icon-support" />
    </Col>

    <Col xs={12} sm={12} lg={9} md={3}>
    <div className="text-written-after-icons-in-about-us-wow-holiday">
    Trusted
    </div>
    </Col>

</Row>
</div>




<div className="padding-in-our-values-icons-about-wow-holiday">
<Row className="display-icons-about-us-in-mobile-view">
    <Col xs={12} sm={12} lg={3} md={3}>
    {/* <i className="fas fa-award icons-in-about-us-wow-holiday"></i> */}
    <img src="IMAGES/city-images/aboutus/icons/responsible_1.png" alt="icon-support" />
    </Col>

    <Col xs={12} sm={12} lg={9} md={3}>
    <div className="text-written-after-icons-in-about-us-wow-holiday">
    Responsibility 
    </div>
    </Col>

</Row>
</div>




<div className="padding-in-our-values-icons-about-wow-holiday">
<Row className="display-icons-about-us-in-mobile-view">
    <Col xs={12} sm={12} lg={3} md={3} >
    {/* <i className="fa fa-graduation-cap icons-in-about-us-wow-holiday"></i> */}
    <img src="IMAGES/city-images/aboutus/icons/inspiring.png" alt="icon-support" />
    </Col>

    <Col xs={12} sm={12} lg={9} md={3}>
    <div className="text-written-after-icons-in-about-us-wow-holiday">
    Inspiring
    </div>
    </Col>




   
</Row>
</div>





                </Col>



                <Col xs={12} sm={3} lg={3} md={4} >

                  
                <div className="padding-in-our-values-icons-about-wow-holiday">

                <Row className="display-icons-about-us-in-mobile-view">
    <Col xs={12} sm={3} lg={3} md={3}>
    {/* <i className="far fa-thumbs-up icons-in-about-us-wow-holiday"></i> */}
    <img src="IMAGES/city-images/aboutus/icons/commitment.png" alt="icon-support" />
    </Col>

    <Col xs={12} sm={3} lg={9} md={3}>
    <div className="text-written-after-icons-in-about-us-wow-holiday">
     Commitment
    </div>
    </Col>

</Row>
</div>






<div className="padding-in-our-values-icons-about-wow-holiday">
<Row className="display-icons-about-us-in-mobile-view">
    <Col xs={12} sm={12} lg={3} md={3}>
    {/* <i className="fas fa-users icons-in-about-us-wow-holiday"></i> */}
    <img src="IMAGES/city-images/aboutus/icons/support.png" alt="icon-support" />
    </Col>

    <Col xs={12} sm={12} lg={9} md={3}>
    <div className="text-written-after-icons-in-about-us-wow-holiday">
    Support 
    </div>
    </Col>

</Row>
</div>





<div className="padding-in-our-values-icons-about-wow-holiday">
<Row className="display-icons-about-us-in-mobile-view">
    
    <Col xs={12} sm={12} lg={3} md={3}>
    {/* <i className="far fa-clock icons-in-about-us-wow-holiday"></i> */}
    <img src="IMAGES/city-images/aboutus/icons/reliable.png" alt="icon-support" />
    </Col>

    <Col xs={12} sm={12} lg={9} md={3}>
    <div className="text-written-after-icons-in-about-us-wow-holiday">
    Reliable
    </div>
    </Col>
   
</Row>
</div>
                </Col>





            </Row>




        </Container>






        <br></br>
<br></br>
<br></br>

<div 
class="w-separator size_medium with_line width_30 thick_1 style_solid color_primary align_center with_content">
  <div class="w-separator-h">
    <i class="material-icons"></i>
    </div>
    </div>






<Container>

<section class="makes-us-different-section wpb_row height_large" id="third">
<div class="makes-us-different-section-h i-cf">
  <div class="g-cols vc_row type_default valign_top">
    <div class="vc_col-sm-12 wpb_column vc_column_container">
      <div class="vc_column-inner">
        <div class="makes-us-different-section-wpb_wrapper">
	<div class="wpb_text_column ">
		<div class="makes-us-different-section-wpb_wrapper">
			<h2 style={{textAlign: 'center'}}>What makes us different ?</h2>

		</div>
	</div>
<div class="w-separator size_medium"></div><div class="g-cols wpb_row type_default valign_top vc_inner ">
  <div class="vc_col-sm-6 wpb_column vc_column_container">
    <div class="vc_column-inner">
      <div class="makes-us-different-section-wpb_wrapper">
	<div class="wpb_text_column ">
		<div class="makes-us-different-section-wpb_wrapper">
			<h3 style={{textAlign: 'center'}}>Value</h3>
<h5 style={{textAlign: 'center'}}>Complimentary no obligation valuation<br/>
Finding property that fits your wishlist<br/>
Secure your investment at the right price</h5>

		</div>
	</div>
</div></div></div><div class="vc_col-sm-6 wpb_column vc_column_container">
  <div class="vc_column-inner"><div class="makes-us-different-section-wpb_wrapper">
	<div class="wpb_text_column ">
		<div class="makes-us-different-section-wpb_wrapper">
			<h3 style={{textAlign: 'center'}}>Care</h3>
<h5 style={{textAlign: 'center'}}>Talk with you regularly to know about your needs<br/>
Expertly market your property<br/>
Let you know about properties that are off-market</h5>

		</div>
	</div>
</div>
</div>
</div>
</div>



<div 
class="w-separator size_medium with_line width_default thick_1 style_solid color_border align_center with_content">
  <div class="w-separator-h">
    <i class="fas fa-bullseye"></i>
    </div>
    </div>
    <div class="g-cols wpb_row type_default valign_top vc_inner ">
      <div class="vc_col-sm-6 wpb_column vc_column_container"><div class="vc_column-inner">
        <div class="makes-us-different-section-wpb_wrapper">
	<div class="wpb_text_column ">
		<div class="makes-us-different-section-wpb_wrapper">
			<h3 style={{textAlign: 'center'}}>Connection</h3>
<h5 style={{textAlign: 'center'}}>Explain regulations, laws and key developments<br/>
Draft offers, negotiating for the most competitive prices<br/>
Deal with contracts, paperwork and insurance</h5>

		</div>
	</div>
</div>
</div>
</div>


<div class="vc_col-sm-6 wpb_column vc_column_container">
  <div class="vc_column-inner">
    <div class="makes-us-different-section-wpb_wrapper">
	<div class="wpb_text_column ">
		<div class="makes-us-different-section-wpb_wrapper">
			<h3 style={{textAlign: 'center'}}>Choice</h3>
<h5 style={{textAlign: 'center'}}>Shortlist suitable properties that meet your requirements<br/>
Show you the local area, amenities and prices<br/>
Always available even after handling over the keys</h5>

		</div>
	</div>
</div>
</div>
</div>
</div>
<div 
class="w-separator size_medium with_line width_30 thick_1 style_solid color_primary align_center with_content">
  <div class="w-separator-h">
    <i class="material-icons"></i>
    </div>
    </div>
        </div>
        </div>
        </div>
        </div>
</div>

</section>


</Container>







<div className="bgcolor-our-mission-context-about-us-wow-holiday">
<Container>

  <div className="padding-mission-context-about-wow-holiday">
    
    <div className="headings-of-our-mission-mission-about-us-holiday">
    Our Mission

    </div>
   
    <div className="context-below-mission-about-us-wow-holiday">
  
To apply Branding, Digital/eCommerce and Design capabilities to enable Startups &
 Brands to meet Consumers needs and develop world-class skills & abilities. Our strategy
  is focused on helping clients improve their operational performance, deliver their products
   and services more effectively & efficiently and grow their businesses in existing & new markets.
    </div>
    
<div className="borderline-bw-mission-vision"></div>



    <Container style={{marginTop:'20px'}}>
      <Row>

      
        <Col xs={12} sm={3} lg={4} md={4}>
        
        <center>
        <div className="headings-of-cols-below-mission-about-us-holiday">
        Our Vision

        </div>

        <div>

<i class="far fa-eye color-of-font-icon-below-vision-context" ></i>
 </div>



        <div className="context-written-behind-col-headings-wow-holiday-about">
        Fulfilling our obligation of building a better,
         efficient and more agile company for future,
          meeting our clients expectation, Developing our people skills
           and helping to improve businesses bottom-line.
          
          </div>
          </center>
        </Col>
        



        <Col xs={12} sm={3} lg={3} md={3}>

        <center>
        <div className="headings-of-cols-below-mission-about-us-holiday">
        Experience
          
          </div>

          <div>
          
<i class="fab fa-asymmetrik color-of-font-icon-below-vision-context"></i>
 </div>


          <div className="context-written-behind-col-headings-wow-holiday-about">
          BT Software Solutions has been striving to 
          give efficient and affordable Services by continuous 
          investment both in technology and people knowledge.


          
          </div>
          </center>

        </Col>



        <Col xs={12} sm={3} lg={5} md={5}>

        <center>
        <div className="headings-of-cols-below-mission-about-us-holiday">
        What We Do
          
          </div>

          <div>

          <i class="fa fa-bullseye color-of-font-icon-below-vision-context"></i>
           </div>



          <div className="context-written-behind-col-headings-wow-holiday-about">


          At BT Software  we engage with our customers and their teams
           to strengthen android and ios development, Software Q/A  capabilities to build 
           a consumer-centric organisation. 
            To better understand this ever-connected world, we use research, analytics,
             and creativity to gain  profound understanding of the unmet consumer
              needs and opportunities related to brands.
          
          </div>
          </center>

        </Col>


      </Row>
    </Container>


  </div>
 
</Container>
</div>


































        {/* <div className="border-line-in-wow-holidays"></div> */}
      </div>
    );
  }
}
export default Wowholiday;































































 
