import React, { Component, useEffect } from "react";
import { Visible, Hidden, setConfiguration } from "react-grid-system";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
} from "react-bootstrap";

class Apipractice extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      items: [],
      isloaded: false,
    };
  }

  componentDidMount() {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          isloaded: true,
          items: json,
        });
      });
  }

  render() {
    var { isloaded, items } = this.state;

    if (!isloaded) {
      return <div>Data is loading....</div>;
    } 
    
    else {

      return (

        <div>
          {/* <div className="td-post-header-holder-blogcard5 td-image-gradient-blogcard5">
          <div className="td-post-featured-image-blogcard5">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard5"
              src="IMAGES/city-images/wow-blog/additional-cards/blog5/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard5">
              <ul className="td-category-blogcard5">
                <li className="entry-category-blogcard5">
                  <a
                    target="blank"
href="https://money.usnews.com/money/blogs/my-money/articles/shopping-holidays-the-best-days-to-shop-this-year"
                  >
                    Best days to shop
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard5">
              News & Trends: WOW for Shopping
              </h1>

              <h4 className="entry-title-two-blogcard5">
              How can you go shopping when you don’t have your own conveyance?...
              </h4>

              <div className="td-module-meta-info-blogcard5">
                <span className="td-post-date-blogcard5">
                  <time
                    className="entry-date-blogcard5 updated td-module-date-blogcard5"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                   04 JAN 2020 / Dubai
                  </time>
                </span>

                <div className="td-post-views-blogcard5">
                  <i className="fa fa-eye view-icon-views-blogcard5"></i>
                  <span className="td-nr-views-10968">11235</span>
                </div>
              </div>
            </header>
          </center>
        </div> */}

          <div>Here you api data is placed in json form</div>
<Container>

          <table>
              {
                  items.map(item => (
                      <th key={item.id}>
                          Name: {item.name} 
                      </th>
                      

                     
                  ))

              }<br/>
              <tr>

              {
                  items.map(item =>(
                    
                      <td key={item.id}>
                    | Email: {item.email}
                      </td>
                    
                  ))
              };
              </tr>
          </table>
</Container>


        </div>
      );
    }
  }
}
export default Apipractice;
































