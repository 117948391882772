import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { 
  Form,
  Container,
  Card, Row,
  Slider,
  Col,
  Button
 } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Newscard5 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      date: new Date().toLocaleString(),
      toggle: true,
     };
  }


  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };


  render() {
    return (
      <div>
        
        {/* <Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-travel-work">
        <div  className="heading-secondary-section-wow-travel-work ">
        Coronavirus Tips If You Still Have to Travel Domestically

        <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2-newscard2"
            src="IMAGES/city-images/newsroom/card3-2.jpg"/>
        </div>
        </div>


</Container> */}

        

<Container>
        <div className="td-post-header-holder-card5 td-image-gradient-newscard5">
        <div className="td-post-featured-image-newscard5">
           <img width="1068" height="580" 
            className="entry-thumb td-animation-stack-type0-2-newscard5"
            src="IMAGES/city-images/newsroom/card5.jpg"/>

        </div>
            <header className="td-post-title-newscard5">
            <ul className="td-category-newscard5">
                    <li className="entry-category-newscard5">
                        <a 
                        target="blank"
                        href="https://www.britannica.com/place/Dubai-United-Arab-Emirates">
                        Dubai
                        </a>
                        </li>

                
                        <li className="entry-category-newscard5">
                        <a 
                        target="blank"
href="https://gulfnews.com/uae/government/uae-travel-procedures-announced-for-citizens-residents-1.1592414112210">
                        UAE
                        </a>
                        </li>
                </ul>
                             
<h1 className="entry-title-newscard5">WOW: 10 fun facts you didn’t know about Dubai</h1>

                

                <div className="td-module-meta-info-newscard5">
                 <span className="td-post-date-newscard5">
                     <time className="entry-date-newscard5 updated td-module-date-newscard5"
                      datetime="2018-11-07T10:59:17+00:00">March 25, 2020</time>
                </span>      

                <div className="td-post-views-newscard5">
                    <i className="fa fa-eye view-icon-views-newscard5"></i>
                    <span className="td-nr-views-10968">4735</span>
                </div>                    
                              
                     </div>

            </header>
        </div>
 
</Container>





<Container>


<div className="u-center-text u-margin-bottom-second-heading-wow-newscard5">
        <div  className="heading-secondary-section-wow-newscard5 ">
        Interesting facts you didn’t know about the Dubai
        </div>
        <p className="text-written-top-heading-newscard5">
        Dubai is famous for its modernity, but the biggest and best part is unquestionably the Burj
         Khalifa. While travel might be off the table for now, but you can still get a little inspiration
          for the future. One of the most-visited cities in the world, Dubai is home to over 200 nationalities 
          and features everything from the world’s tallest buildings to man-made paradises on the sea. 
          Dedicated to living up to its own hype, Dubai doesn’t disappoint: between gold-plated Lamborghinis
           and glittering fountains, it’s a desert gem worth visiting.
           </p>

           <p className="text-written-top-heading-newscard5">
            A visit to Dubai offers a thrilling mix of adventure, culture, and — of course — shopping. 
            With this 5-day
            travel plan, your Dubai trip will tick all the boxes for an unforgettable vacation.
           When travelers of old got lost in the desert, they would sometimes spy glittering oases on the horizon,
            only to find they were mirages — hallucinations likely caused by dehydration. But while Dubai 
            is a glittering oasis, it’s no mirage. This dreamy desert destination is all real and it’s rich
             in sights to suit every type of traveler.
             </p>
          <p className="text-written-top-heading-newscard5">
          Dubai is a city that boasts many world records. From the highest building in the world to 
          a seven-star hotel. But here are some Dubai fun facts that you may not know…
        </p>
        </div> 


</Container>


<Container>
<div>
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/dubai-text.jpg" />

  </div>
  </Container>












<div className="gradient-back-travel-newscard5">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard5-grid-text">
                  <div className="travel-newscard5-page-araea-sub-headings110">
                  Dinner and a view
                  </div>

                  <div className="travel-newscard5-page-page-text">
                  Treating your eyes with exotic skyline views, and your taste buds with authentic delicacies,
                   the best romantic restaurants in Dubai offer nothing less than perfection. Whether you’re just
                    holidaying or honeymooning in Dubai, don’t come back without experiencing sophisticated
                     dining at one of these romantic restaurants. They are built to amp up your romantic life
                      and make sure that you have a wonderful time with your partner.

 
<br/>
Regularly use your sanitizer when you aren't wearing gloves and avoid passing
 the sanitizer bottle to another person.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/grid1.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-newscard5">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/grid2.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard5-grid-text">
                  <div className="travel-newscard5-page-araea-sub-headings110">
                  UNESCO World Heritage Site
                  </div>

                  <div className="travel-newscard5-page-page-text">
                  Escape the appeal of Dubai’s bling and glamor to discover the historic Dubai Creek area.
                   Walk in the small alleyways of the Al Fahidi Historical Neighbourhood and explore museums,
                    galleries, and local food. You can even cruise the river on a traditional abra boat.
                     Take a tour with a local guide to learn facts about Dubai in this fascinating area.

                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>















     







<Container>

<div class="u-center-text u-margin-bottom-second-heading-expert-wow-newscard5">
        <div  className="heading-secondary-expert-section-wow-newscard5 ">
        Travel To Dubai: 10 fun facts you didn’t know about Dubai
        </div>
        </div>   






<div class="description_of_card_of_newsroom-card5">
  <div class="description_of_card_of_newsroom_area-card5">

  <h2 class="blogs-description-area-of-main-headings-card5">
  1. It’s a very young city
  </h2>
  <p class="blogs-description-area-of-main--text-card5">
  In the early 1700s, Dubai was just a small fishing and pearl-diving village. By the end of the 18th century,
   about 1,200 people were living there. Fast-forward to today, Dubai has a population of 
   3.3 million. Before the discovery of oil, pearls and gold were highly sought after 
  trading items. Visit the souks and Dubai Museum to learn facts about Dubai and the Emirati civilization.
   </p>

   
<div class="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img1.jpg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings-card5">2. It’s a birder’s paradise</h2>
   <p class="blogs-description-area-of-main--text-card5">
   Bird-watchers will be thrilled to hear that there are over 320 bird species that migrate between Europe,
    Asia, and Africa via Dubai. During the spring and fall, birds such as spotted eagles, broad-billed 
    sandpipers, and greater herons pass through the city. They can be seen on a visit to Ras al Khor Wildlife
     Sanctuary, and a falconry safari is a must for bird-lovers young and old. Experience the traditional
      sport of falconry first-hand with a greater spotted eagle, Harris’ hawk, and desert eagle owl. 
      The most well run city sightseeing bus i have ever been on. I have done themin every city but Dubai
       is the best. I also love that 
      they give out free bottled water. Every stop they drop you off right in front of the attraction. 
    </p>

      <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img2.jpg" style={{width: '100%'}}/>

  </div>


      
<h2 className="blogs-description-area-of-main-headings-card5">3. It took an army</h2>
  <p className="blogs-description-area-of-main--text-card5">
  Inspired by the spider lily flower, the elaborately designed tower was always going to be a huge undertaking.
   Even for a city that features the Dubai Fountain and a slew of other skyscrapers, this was the city’s 
   biggest (and tallest) project. The building of the Burj Khalifa required a workforce that was larger 
   than any the city had ever needed before. By the peak of construction, 12,000(!) workers were needed 
   on-site every day. That’s a lot of hard hats!<br/>

   The world’s tallest building, at 2,716 feet, the Burj Khalifa needs no introduction. An icon in the Dubai 
   skyline, this building should be on your Dubai bucket list. Zip to the 148th floor to see the city below, 
   or chill in the lounge with cocktails on the 123rd floor. A fun Dubai fact is that the design of the building
    is based on a local desert flower, hymenocallis, also known as the spider lily.
     </p>

     <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img3.jpg" style={{width: '100%'}}/>

  </div>




     
     <h2 className="blogs-description-area-of-main-headings-card5">4. Its cuisine is truly cosmopolitan</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      Being a trading center for centuries has meant that food from all over the world has influenced Dubai
       cuisine. Along with traditional Arabic food, you’ll find spices from India, ingredients from Persia,
        and eastern Mediterranean influences. Join a food tour to taste different cuisines and learn foodie
         facts about Dubai. For an authentic start to the day, join a traditional Emirati breakfast experience.
          If you feel like an adventurous and romantic evening, a dinner at a Bedouin camp is the real deal. <br/>


          The Burj Khalifa really has it all, including a little At.mosphere… the restaurant!  You can find 
          the world’s highest restaurant up 441 meters (1,447 ft) — just hit the button for floor 122. No matter
           where you find yourself seated, the views are unbeatable, thanks to the segmented floor plan 
           making every angle the best vantage point. Panoramic views of Dubai and the surrounding landscape
            are our preferred digestive. It doesn’t get much higher on the bucket list than the Burj Khalifa.
         </p>

         <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img4.jpg" style={{width: '100%'}}/>

  </div>



<h2 className="blogs-description-area-of-main-headings-card5">5. Art scene is flourishing(Sky-high sculptures)</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      The Burj Khalifa isn’t just about views and food. The skyscraper is also filled to the brim with art,
       culture, and the world’s first Armani hotel. First stop on the art-lover’s tour should be the corporate
        entrance’s oval sculpture made by Egyptian artist, Karim Rashid. Afterward, head to the incredible “World
         Voices” installation from Catalan artist, Jaume Plensa..<br/>

         The annual international art fair, Art Dubai, features artists from the Middle East, 
         South East Asia, Africa, Latin America, and Australasia. This cutting-edge event focuses on contemporary
          artworks that relate to cultural heritage.
         </p>
       
         <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img5.jpg" style={{width: '100%'}}/>

  </div>



<h2 className="blogs-description-area-of-main-headings-card5">6. Going the distance</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      One of the most popular sports in Dubai is camel racing. To prevent children from being used for their
       small size to ride and race the camels, small robots are now the jockeys. While you won’t be racing 
       a camel, you can go for a camel ride adventure in the desert.<br/>

       It might be debatable whether you can see the Great Wall from space. But there’s no doubt that from land,
        the Burj Khalifa can be seen from up to 95 km (60 miles) away. Whether exploring the desert dunes
         on safari or drifting along the cool 
       waters of the nearby bay, the skyscraper seems to follow. Dubai will be ready and waiting for you.
      </p>

      <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img6.jpg" style={{width: '100%'}}/>

  </div>





  <h2 className="blogs-description-area-of-main-headings-card5">7. A little spring cleaning</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      With over 26,000 glass panels covering the exterior of the tower, it’s no surprise that cleaning can 
      be… a bit high-maintenance. Did you know? It can take a team of cleaners up to 3 months to clean every
       single window. The best part? Once they’re done, they have to start all over again.

This might not be the job for someone afraid of heights, but you can (securely) enjoy the sights from the
 observation deck with 360-degree views.
      </p>

      <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img7.jpg" style={{width: '100%'}}/>

  </div>






  <h2 className="blogs-description-area-of-main-headings-card5">8. It has a museum dedicated to coffee</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      Coffee lovers need their cup of the good stuff to start the day, and the Arabic culture certainly
       delivers. Visit the Coffee Museum to learn about the history of this beloved beverage and 
  see coffee artifacts from around the world. For a taste of tradition, take part in a Bedouin coffee ritual.<br/>

It’s also one great time saver, especially if you don’t have the luxury to go around and look for actual 
souvenirs. The local currency is good enough to help you remember that trip!

You can even make it a habit every time you go for an adventure. Who says you can only bring one souvenir 
every time?
      </p>

      <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img8.jpg" style={{width: '100%'}}/>

  </div>



  <h2 className="blogs-description-area-of-main-headings-card5">9.  Set of islands shaped like a palm tree.</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      The Palm in Dubai is the world’s largest artificial archipelago. These artificial islands were
       created out of only sand and rocks. In fact, it took over 3 billion cubic feet of sand from the
        seabed and 7 million tons of rock from the Hajar Mountains. Explore it on a fun jet ski ride,
         or take to the skies in a thrilling tandem skydiving experience.<br/>

         Palm Islands, Dubai. It's a big call but Dubai's Palm Islands are perhaps one of the country's 
         boldest megaprojects undertaken. Often referred to as the 'eighth wonder of the world' Dubai's Palm
          Islands are the largest manmade islands in the world
      </p>

      <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img9.jpg" style={{width: '100%'}}/>

  </div>





  <h2 className="blogs-description-area-of-main-headings-card5">10. Lofty goals</h2>
      
      <p className="blogs-description-area-of-main--text-card5">
      Breaking the world record for the tallest building wasn’t enough for the Burj Khalifa. The enormous
       tower also cemented its place in the Guinness World Records for a couple of other reasons. As the record
        holder for the world’s highest inhabitable floor, the Burj Khalifa has the (sky-)highest views
         from any home. There’s good news for lucky residents looking to reach their oh-so-lofty estates.
          No need to lug groceries up countless stairs when there’s (what else) but the second record-breaker:
           the world’s tallest elevator!
      </p>

      <div className="wow-news-card1-work-page-top-image-card5">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card5/img10.jpg" style={{width: '100%'}}/>

  </div>


    
  
        
   
        </div>
        </div>





        </Container>









        










































{/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}































      </div>
    );
  }
}
export default Newscard5;
