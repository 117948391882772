import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import '../Wowholidayblogpost2/blogpost2.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Blogpost2 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>



        <div className="td-post-header-holder-blogcard2 td-image-gradient-blogcard2">
          <div className="td-post-featured-image-blogcard2">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard2"
              src="IMAGES/city-images/wow-blog/additional-cards/blog2/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard2">
              <ul className="td-category-blogcard2">
                <li className="entry-category-blogcard2">
                  <a
                    target="blank"
                    href="https://www.thrillist.com/travel/nation/travel-hacking-tips-10-travel-hacks-that-ll-make-any-trip-easier"
                  >
                    Travel on a budget
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard2">
              How to travel on a budget: 8 ways to save money on your next trip
              </h1>

              <h4 className="entry-title-two-blogcard2">
                Trip planning: Want to go on Travel on a low budget…
              </h4>

              <div className="td-module-meta-info-blogcard2">
                <span className="td-post-date-blogcard2">
                  <time
                    className="entry-date-blogcard2 updated td-module-date-blogcard2"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                   June 02, 2019
                  </time>
                </span>

                <div className="td-post-views-blogcard2">
                  <i className="fa fa-eye view-icon-views-blogcard2"></i>
                  <span className="td-nr-views-10968">5035</span>
                </div>
              </div>
            </header>
          </center>
        </div>














        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard2">
            <div className="heading-secondary-uk-section-wow-blogscard2 ">
            Travel planning for low budget
            </div>
          </div>
        </Container>


        <Container>
<p class="blogscard2-description-area-of-main--text-above">
            Traveling is fun. That’s just a fact. Another well-known fact is that travel is not always easy,
             smooth or convenient. Hands up if you want to take a vacation, but your account balance has other 
             ideas. Well, we’re here to show you that you don’t need a bank account 
             that’s bursting with digits to take a well-deserved getaway. With 
             these eight top tips, we’ll prove you can save money without scrimping on awesome
              vacation experiences.
            
             </p>

             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard2-aboveimage">
               <img 
             style={{width:'100%'}}
             src="IMAGES/city-images/wow-blog/additional-cards/blog2/image-above1.jpg"/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard2-aboveimage">
               <img 
             style={{width:'100%'}}
             src="IMAGES/city-images/wow-blog/additional-cards/blog2/image-above2.jpg"/>

  </div>
  </Col>
               
             </Row>
             

             <p class="blogscard2-description-area-of-main--text-above">
             Regardless of the travel time you make, the simplest way to make a budget and be able to control
              it during the trip is to determine a daily spending amount. This daily expense will tell you how
               much money you need exactly for lodging, food, transportation, leisure, museum tickets, etc. If
                you not sure about the cost 
             then use online travel cost tool like

              <a 
              target="blank"
              href="https://travelsites.com/trvel-cost-plan/"> Travel cost plan</a> and plan your trip like a pro.

Often find yourself scrolling through your social feed, daydreaming about faraway lands,
              inspiring photography and life-changing adventure? Then you wake up and check your bank 
              balance…dammit. To travel on a budget is a daunting thing to many people, and unfortunately
               can deter some would-be wanderers from experiencing this incredible planet. 
             To help you get the most out of your journey, here are some genius
              travel tips for when you’re broke AF:  
             </p>
</Container>
<br/>




        
<div className="gradient-back-travel-blogscard2">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard2-grid-text">
                  <div className="travel-blogscard2-page-araea-sub-headings110">
                  Come up with a plan
                  </div>

                  <div className="travel-blogscard2-page-page-text">
                  Travelling spontaneously is great, if you have the luxury of time and money to spare. But if
                   you’re travelling on a budget, the first thing to do is come up with a plan. You don’t have need
                    a tight, hour-by-hour itinerary, but you should at least have an idea of 
                  how long you’ll be spending in each city or country, and know the route that your epic adventure will take.
                  Leaving less to chance means less unexpected spends; last-minute flights and accommodation are often 
                  far more expensive.
 

                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/grid1.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-blogscard2">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/grid2.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard2-grid-text">
                  <div className="travel-blogscard2-page-araea-sub-headings110">
                  Travel out of season
                  </div>

                  <div className="travel-blogscard2-page-page-text">
                  Avoid trips during the school holidays, this is when the travel industry hikes up prices 
                  to take advantage of families who can only travel during these weeks. Research the best time 
                  to visit your intended destination, and then travel just before or after these dates. This is called
                   the ‘shoulder season’, where you’ll still have a great
                    trip but maybe the sun won’t shine quite as brightly (and, on the plus side, 
                  it won’t be quite as hot.) Hotels and airlines lower their prices to attract customers during this time.
                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>














<Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard2">
            <div className="heading-secondary-uk-section-wow-blogscard2 ">
            Pro Travel Tips
            </div>
          </div>
        </Container>







<Container>
        <div class="description_of_card_of_blogscard2">
          <div class="description_of_card_of_blogscard2_area">
            <p class="blogscard2-description-area-of-main--text">
            The world is full of affordable destinations, and it doesn’t require much effort to find them.
             No matter what continent, there are always places you can visit on a budget — even
             countries we think of as expensive are quite budget-friendly if you know certain tips and tricks.
            </p>

            <p class="blogscard2-description-area-of-main--text">
            Traveling is fun. That’s just a fact. Another well-known fact is that travel is not always easy,
             smooth or convenient. Of course, chalking up all the mishaps as part of the fun is the best attitude
              to have, however avoiding them in the first place is probably better. You know the feeling, you love the
               feeling: there’s nothing like a good travel hack. It goes hand in hand with making the most of your
                destination. Read on to discover some super-smart hacks 
              (and learn how to see the world like a seasoned pro). Money-saving hacks Take 
              advantage of local produce..
             cheap flight hacks, airplane hacks, or just general travel life hacks, we have you covered.
             </p>


             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/travel-tips-grid1.jpg"/>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/travel-tips-grid2.jpg"/>
               </Col>
               
             </Row>

             
            <h2 class="blogscard2-description-area-of-main-headings">
            Take to the skies for less
            </h2>
            <p class="blogscard2-description-area-of-main--text">
            Not sure you’ll have the cash to see Berlin from atop the TV Tower and explore Museum Island?
             Not to worry. It’s never been easier to save money on plane tickets, no matter where you’re
              headed. Check out Skyscanner or Google’s Flight Price Tracker and discover the cheapest time
               to fly, ensuring you have plenty of cash to spend on what really matters: memory-making 
               experiences. The more flexible
             you are with your dates (think off-season and avoiding Friday and
              Sunday nights), the bigger the savings.
              <br/>
            </p>


            <h2 class="blogscard2-description-area-of-main-headings">
            Travel smart
            </h2>
            <p class="blogscard2-description-area-of-main--text">
            It’s not just in the air where you can make big savings. When the plane touches down, don’t just 
            jump in an Uber. Swap the app for a city pass like this 24-96 hour one in Amsterdam. Not only will
             you enjoy unlimited access to the Dutch capital’s public transport, 
             but you can also enter 44 top museums and attractions for free. As well as travel cards,
              hop-on-hop-off buses are a cheap and fun way to get around. This ticket for 
            Amsterdam lasts up to 48 hours, so you can hop on and off buses 
            and boats whenever something catches your eye.
            </p>
            <p class="blogscard2-description-area-of-main--text">
            Chances are, there’s one iconic attraction that’s made you want to visit a particular 
            destination. This will often be the most 
            expensive thing to do on your vacation — so you want 
            to make sure you’re getting value for money and time.
            </p>

            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard2">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/img1.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard2">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/img2.jpg" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>






             <div class="description_of_card_of_blogpost2">
  <div class="description_of_card_of_newsroom_area-blogpost2">

  <h2 class="blogs-description-area-of-main-headings-blogpost2">
  1. Be accommodation-savvy
  </h2>
  <p class="blogs-description-area-of-main--text-blogpost2">
  Trade expensive hotel suites for dorm rooms in hostels. Sharing a room naturally divides the 
  costs and communal bunk rooms offer the opportunity to meet people who might be keen to explore
   with you. Other great alternatives are websites like AirBnB and Couchsurfing, where you simply book a
    spare room in a local person’s house or apartment. It’ll halve the 
    price and allow you an authentic snapshot of real life in the city. Consider your host your very own, 
  personal tour guide, filled with insider-tips for the best eateries and tourist spots in that neighbourhood.
  </p>

  <p class="blogs-description-area-of-main--text-blogpost2">
  You could even stay with family or friends. Reach out to people you know or plan a trip to somewhere
   that a long-lost cousin
   or school friend now lives – this could take you to visit places you’d never have thought of before.
   </p>

   
<div class="wow-news-card1-work-page-top-image-blogpost2">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/img3.jpeg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings-blogpost2">2. Embrace public transport</h2>
   <p class="blogs-description-area-of-main--text-blogpost2">
   Buses and trains are cheaper than planes. It’s that simple! A journey on an overnight 
   train also mean you have one less night in a hostel to pay for…<br/>
   It takes a strong knowledge of the area, patience, and of course a license to become a traveller. 
   But in europe, the bar to qualify is far higher. Facing one of the most notoriously difficult application
    and approval processes for cab drivers in the world, Europe cabbies have to take the Knowledge 
    test including knowing 25,000 streets and all the landmarks within 6 miles of Charing Cross. A 
     cabbie can really say (and show the certificate as proof!) they know the place like the back of their hand.
    </p>

      <div className="wow-news-card1-work-page-top-image-blogpost2">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/img4.jpg" style={{width: '100%'}}/>

  </div>


      
<h2 className="blogs-description-area-of-main-headings-blogpost2">3. Don’t eat away your cash</h2>
  <p className="blogs-description-area-of-main--text-blogpost2">
  Cheap lunches can be bought in a supermarket or even a local fresh food market, rather than in
   an overpriced cafe or restaurant. Aim to wander around before choosing a place to eat dinner too;
    if you just pick the first restaurant you see you won’t know if you’re overspending. Drinking beer
     with every dinner soon adds up – both your wallet and your waistline will thank you 
     if you opt for water instead sometimes. Alternatively, hostels and spare rooms 
  often let you have access to a kitchen if you want to save a little money by cooking for yourself.
     </p>

     <p className="blogs-description-area-of-main--text-blogpost2">
      Why not combine eating out with a fun-filled activity? Choose from a number of foodie workshops, 
      including this family-friendly pizza class in Florence or a paella experience in Barcelona. It’s
       a win-win. Not only will you save time finding a restaurant, but you’ll also return home with
       a tasty recipe or two. Plus, you won’t have to splurge on dinner as it comes with the experience.
         </p>

     <div className="wow-news-card1-work-page-top-image-blogpost2">
     <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/img5.jpg" style={{width: '100%'}}/>

  </div>




     
     <h2 className="blogs-description-area-of-main-headings-blogpost2">4. Keep your luggage safe</h2>
      
      <p className="blogs-description-area-of-main--text-blogpost2">
      We’ve all seen bags get manhandled at the airport. If you’re coming back from a trip
       with precious cargo, ask for a “fragile” sticker at the check-in counter. While this doesn’t
        guarantee accident-free transport, airport staff will be more likely to handle your baggage
         with care. Plus, the bags
       are likely to be placed on top of other luggage, meaning you’ll be one of the first to get your stuff.
      </p>

      <p className="blogs-description-area-of-main--text-blogpost2">
      Consider labeling your luggage “fragile” at check-in to ensure it’s handled more carefully.
      Depending on the type of trip you’re going on, make a list of the essentials you’ll need. Choose small
       multi-purpose gadgets over bulky items and share them with co-travelers if you’re part of a group. As
        for the items you end up taking, 
      charge them before your trip and use sleek cases to carry them around safely during your trip.
         </p>

         <div className="wow-news-card1-work-page-top-image-blogpost2">
         <img src="IMAGES/city-images/wow-blog/additional-cards/blog2/img6.jpg" style={{width: '100%'}}/>

  </div>








  


    
  
        
   
        </div>
        </div>




















            


            
            
            <div class="description_of_card_of_blogscard2_bottom_area">
              <div class="description_of_card_of_blogscard2_bottom_area_previous">
                <h3>
                  <i class="fas fa-reply" aria-hidden="true"></i>
                </h3>
                <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                <p>Previouse Article</p>
              </div>
              <div class="description_of_card_of_blogscard2_bottom_area_next">
                <h3>
                  <i class="fa fa-mail-forward" aria-hidden="true"></i>
                </h3>
                <h5>WOW IS FINALLY HERE!</h5>
                <p>Next Article</p>
              </div>
            </div>
          </div>
</div>




</Container>








































        












        {/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}
      </div>
    );
  }
}
export default Blogpost2;
