import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Visible, Hidden, setConfiguration } from "react-grid-system";
import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Button,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost13/blogpost13.scss";

class Blogpost13 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
      show: true,
    };
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>
        <div className="td-post-header-holder-blogcard13 td-image-gradient-blogcard13">
          <div className="td-post-featured-image-blogcard13">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard13"
              src="IMAGES/city-images/wow-blog/additional-cards/blog13/bg-image2.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard13">
              <ul className="td-category-blogcard13">
                <li className="entry-category-blogcard13">
                  <a
                    target="blank"
                    href="https://www.visitdubai.com/en/travel-planning/travel-tools?gclid=CjwKCAjwm_P5BRAhEiwAwRzSO6aKuSVESdHqLA1vJp5Gr6oSgSt2I9rISllTZ5nhKB68goYNQU1eWhoCxXoQAvD_BwE"
                  >
                    Dubai Travel
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard13">WOW NEWS & TRENDS</h1>

              <h4 className="entry-title-two-blogcard13">
                Dubai Travel Blog, Guide and Where to stay in Dubai
              </h4>

              <div className="td-module-meta-info-blogcard13">
                <span className="td-post-date-blogcard13">
                  <time
                    className="entry-date-blogcard13 updated td-module-date-blogcard13"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    21 july 2020 / Dubai
                  </time>
                </span>

                <div className="td-post-views-blogcard13">
                  <i className="fa fa-eye view-icon-views-blogcard13"></i>
                  <span className="td-nr-views-10968">123239</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard13">
            <div className="heading-secondary-uk-section-wow-blogscard13 ">
              WOW NEWS & TRENDS
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard13-description-area-of-main--text-above">
            Have you ever heard of love at first sight? Well, Dubai is a place
            that makes everyone falls in love with a magnificent city. Dubai is
            one big wow. Things that may come to our minds are camel-riding,
            dune-jumping adventure, skyscrapers, cultural heritage, the dancing
            fountains at the Burj Khalifa, etc. but the truth is there are many
            more things that you may do in this wonderful city. We will discuss
            some tips, guides, things to do, places to visit, places to stay,
            and more in this blog about travel in Dubai.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard13-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog13/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard13-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog13/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard13-description-area-of-main--text-above">
            There is a small problem, you must have a long period of holiday at
            least a month to travel and do it all in this city. Dubai is no
            usual sort of destination, some of the people won’t have this
            wonderful city on their travel list, and they don’t know what
            experience they miss. This guide is up to date but things change so
            fast in Dubai, double-check the facts and figures before taking a
            step towards or in Dubai.
          </p>

          <p class="blogscard13-description-area-of-main--text-above">
            Dubai is a city that must be seen to be believed. Record-breaking
            architecture stands alongside traditional quarters, while manmade
            islands jut out of the striking coastline. Here are the top places
            to tick off your sightseeing list when you’re in town.
          </p>
        </Container>
        <br />

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogcard13">
            <div className="heading-secondary-section-wow-blogcard13 ">
              Incredible Stays to Dubai Travel Blog, Guide and Where to stay in
              Dubai:
            </div>
          </div>
        </Container>

        <Container>
          <div
            id="accordion-5ed01b058660a"
            className="accordion indicator-plus accordion-white mb-3"
            role="tablist"
          >
            <div className="card-blogcard13 bar">
              <div className="card-blogcard13 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-0"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard13-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-0"
                  >
                    <span role="heading" aria-level="0">
                      How to Save Money in Dubai?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-0"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-0"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard13">
                    <Row>
                      <Col lg={6}>
                        <p style={{ padding: "3rem" }}>
                          Dubai doesn’t have to bust your budget but it easily
                          can if you aren’t careful! Like most cities with
                          extremely high prices, many of the city’s residents
                          have found tips and tricks on how to squeeze every
                          last dirham possible.
                          <ul className="collapse-section-blogpost">
                            <li>
                              Use Groupon – Groupon is huge in Dubai and you can
                              find tons of discounts, 2-for-1 specials, and
                              deals on the website.
                            </li>

                            <li>
                              Get The Entertainer – The Entertainer, a magazine
                              and app that offers discounts and specials on
                              restaurants, hotels, and activities.
                            </li>

                            <li>
                              You can pick up a copy when you arrive in Dubai at
                              supermarkets and bookstores, or find an online
                              version on their website (the app costs 445 AED or
                              $121 USD).
                            </li>
                          </ul>
                        </p>
                      </Col>

                      <Col lg={6}>
                        <div className="wow-news-card1-work-page-top-image-blogscard9-aboveimage">
                          <img
                            style={{ width: "100%" }}
                            src="IMAGES/city-images/wow-blog/additional-cards/blog13/collapse1.jpg"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard13 bar">
              <div className="card-blogcard13 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-1"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard13-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-1"
                  >
                    <span role="heading" aria-level="0">
                      What is the best time to travel Dubai?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-1"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-1"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard13">
                    <p style={{ marginBottom: "1rem" }}>
                      You can pay a visit to Dubai throughout the year. But
                      tourists generally visit Dubai from September to April
                      when climate is breezy and healthy. Dubai is one of the
                      safest cities in the world. However, make sure to obey the
                      rules and regulation. UAE is a Muslim country, so
                      spiritual sensitivities must be taken into concern.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard13 bar">
              <div className="card-blogcard13 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-2"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard13-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-2"
                  >
                    <span role="heading" aria-level="0">
                      Things to know about Dubai before travelling
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-2"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-2"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard13">
                    <p>
                      Dubai’s many wonders are well documented, but when it
                      comes down to the basics - like public transport, weather,
                      culture and business – all you need is in this essential
                      guide. So, with your plane tickets and hotel booked, read
                      on to discover all you need to know before visiting our
                      emirate.
                    </p>
                    <p>
                      Dubai is ideally situated at the crossroads of Europe,
                      Asia and Africa, meaning that one-third of the world’s
                      population is within a four-hour flight and two-thirds are
                      within an eight-hour flight. With an excellent network of
                      roads and highways connecting neighbouring Arab countries,
                      and regular cruises to and from Dubai, the emirate is
                      easily accessible by air, land, and sea.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard13 bar">
              <div className="card-blogcard13 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-5"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard13-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-5"
                  >
                    <span role="heading" aria-level="0">
                      What tourist attractions are in Dubai?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-5"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-5"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard13">
                    <p>
                      There are SO many, and all suit different ages and
                      lifestyles. From the Burj Khalifa, to The Beach in JBR, to
                      the IMG Worlds of Adventure theme park, to City Walk’s HUB
                      ZERO as well as Burj Al Arab, Souk Madinat, desert
                      safaris, sand dune buggy rides, to the Dubai Canal and
                      Dubai Marina… There’s SO much to see and do during your
                      time in Dubai, with options that suit all budgets. In
                      fact, why don’t you take a look at deal websites like
                      Cobone that have plenty of activity deals to help save you
                      some Dirhams during your stay here?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="gradient-back-travel-blogscard13">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard13-grid-text">
                  <div className="travel-blogscard13-page-araea-sub-headings110">
                    WOW Welcomes Tourists
                  </div>

                  <div className="travel-blogscard13-page-page-text">
                    WOW welcomes the tourists coming from all over the world to
                    explore this wonderful city. WOW is set to give the
                    high-class ride-sharing services to the citizens as well as
                    the foreigners.
                  </div>
                  <div className="travel-blogscard13-page-page-text">
                    We emphasize travelers to take the best riding services in
                    the city with the experience they never have experienced
                    before.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog13/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard13">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog13/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard13-grid-text">
                  <div className="travel-blogscard13-page-araea-sub-headings110">
                    Getting around in Dubai
                  </div>

                  <div className="travel-blogscard13-page-page-text">
                    Connect seamlessly from the airport to your meeting using
                    Dubai’s state-of-the-art transport system. You’ll find a
                    string of public taxis awaiting your arrival and, being a
                    relatively small city, you can expect to be at your hotel or
                    remote office in under 30 minutes. They accept most major
                    credit cards, and will soon be wifi-enabled for passengers
                    to stay connected on the go. You can alternatively use apps
                    such as Uber or local-based Careem to hire a taxi.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard13">
            <div className="heading-secondary-help-community-section-wow-blogscard13 ">
              Welcome To Dubai
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard13">
            <div class="description_of_card_of_blogscard13_area">
              <h2 class="blogscard13-description-area-of-main-headings">
                Finding Culture and History in Dubai- Old Dubai …
              </h2>
              <p class="blogscard13-description-area-of-main--text">
                You may find it a little weird but Dubai is not all about the
                luxury stuff with huge skyscrapers, but Dubai is old too. Down
                by the Creek you can visit the Al Fahidi Historical District
                where wind towers coral blockhouses and well restored
                traditional Arabian doorways give you a glimpse into yesterday.
                The area is small but even so, is so aesthetic and fascinating.
                Dubai Fort is one of the other such tourist attractions.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog13/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog13/help-tips-grid2.jpg" />
                </Col>
              </Row>
              <h2 class="blogscard13-description-area-of-main-headings">
                Where to stay in Dubai.....
              </h2>
              <p class="blogscard13-description-area-of-main--text">
                If you are looking for a trip of a lifetime and want to stay in
                a luxury hotel. Well, we will recommend some of the best luxury
                hotels but it's totally up to your taste. Cheaper options also
                exist apartment hotels are also the best option to stay in
                Dubai. There are hugely luxurious coastal resorts for
                holidaymakers but there are hundreds of options in this city in
                different areas as well, all depends upon your trip and plans.
              </p>
              <p class="blogscard13-description-area-of-main--text">
                Atlantis the Palm or Burj al Arab on Jumeirah Beach, are two of
                the most luxurious hotels for the ultimate Dubai experience, but
                for this experience, you have to spend a lot of your money.
              </p>
              <p class="blogscard13-description-area-of-main--text">
                There are apartment-style hotels that will make you feel like
                home, they include kitchens, washing machines, etc. that will
                make life easier and feel like home. They are very much
                budget-friendly as compare to the luxury ones. The Golden Sands
                series of apartment hotels are popular for swimming pools.
              </p>
              <h2 class="blogscard13-description-area-of-main-headings">
                Getting around in Dubai....
              </h2>
              <p class="blogscard13-description-area-of-main--text">
                There are metro lines that will cover most of the tourist areas
                of the city. You can buy a red NOL card, through which you will
                be allowed to travel on all metro, bus, and trams.
              </p>
              <p class="blogscard13-description-area-of-main--text">
                If you are not going to so many places and you are visiting some
                of them, taxis are a convenient way of getting around the city.
                For a family of four, the cost of a taxi won’t be of too much
                difference from traveling in the metro. Keep in mind the peak
                hours when traveling through taxis. Buses are common across
                Dubai, but you must know what exactly their routes are. The NOL
                card will include travel on buses if you want to have a go.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard13">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog13/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard13">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog13/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <h2 className="blogscard13-description-area-of-main-headings">
                Business in Dubai...
              </h2>
              <p className="blogscard13-description-area-of-main--text">
                Dubai is the region’s biggest business hubs, with its central
                global location playing a vital role in its importance within
                international commerce. Business travellers should not take
                offence if, when meeting with professionals of the opposite sex,
                they are not offered a handshake. To avoid any awkwardness, the
                best advice is to wait for a hand to be offered to you.
              </p>
              <p className="blogscard13-description-area-of-main--text">
                It’s worth remembering that Muslims are required to pray five
                times a day as part of their faith. Most of the time this will
                go unnoticed by business travellers, but it’s worth bearing in
                mind when planning your meeting schedule.
              </p>
              <h2 className="blogscard13-description-area-of-main-headings">
                Dubai Mall.....
              </h2>
              <p className="blogscard13-description-area-of-main--text">
                Dubai mall is not just a shopping destination. Dubai Mall is the
                largest mall in the world, this mall is an experience in itself.
                You will love the Dubai aquarium on the ground floor and the
                Burj Khalifa outside where you’ll be able to see the water
                fountain display every evening. Many of the attractions listed
                above under Dubai with Kids are in the mall. Not just those, the
                mall has one of the biggest candies stores you would have ever
                seen. In short, this is a perfect destination.
              </p>
              <p className="blogscard13-description-area-of-main--text">
                Apart from all these famous places, there are still so many that
                attract thousands of tourists every year, places like Dubai
                Creek, The Fish Market, The Date Market, Burj Khalifa, and Burj
                Al Arab are some other very famous destinations.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard13">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog13/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard13">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog13/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <h2 class="blogscard13-description-area-of-main-headings">
                Dubai Beaches...
              </h2>
              <p class="blogscard13-description-area-of-main--text">
                Dubai has all the beaches you can think of. All the beaches are
                very clean and the water is mostly calm. Water temperature will
                not be as warm as you’d think but not as cold as the
                Mediterranean. The beaches have all facilities like shops and
                restaurants. getting there will probably require a taxi or
                shuttle buses as the metro stopped a few blocks back. The most
                famous beach scene will be Jumeirah beach and the Burj Al Arab.{" "}
              </p>
              <p class="blogscard13-description-area-of-main--text">
                On this famous palm tree-shaped island, you’ll find a large
                shopping walkway, the Atlantis resort, Aquaventure waterpark,
                and a host of fancy restaurants, bars, and clubs. It’s beautiful
                to walk around and explore during the day (at night, it’s pretty
                boring!)
              </p>
              <div class="description_of_card_of_blogscard13_bottom_area">
                <div class="description_of_card_of_blogscard13_bottom_area_previous">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fas fa-reply" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard13_bottom_area_next">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fa fa-mail-forward" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost13;
