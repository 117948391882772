import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { FaBeer } from 'react-icons/fa';

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import "../Wowholidayblogpost5/blogpost5.scss";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Blogpost5 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        <div className="td-post-header-holder-blogcard5 td-image-gradient-blogcard5">
          <div className="td-post-featured-image-blogcard5">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard5"
              src="IMAGES/city-images/wow-blog/additional-cards/blog5/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard5">
              <ul className="td-category-blogcard5">
                <li className="entry-category-blogcard5">
                  <a
                    target="blank"
                    href="https://money.usnews.com/money/blogs/my-money/articles/shopping-holidays-the-best-days-to-shop-this-year"
                  >
                    Best days to shop
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard5">
                News & Trends: WOW for Shopping
              </h1>

              <h4 className="entry-title-two-blogcard5">
                How can you go shopping when you don’t Know about place?
              </h4>

              <div className="td-module-meta-info-blogcard5">
                <span className="td-post-date-blogcard5">
                  <time
                    className="entry-date-blogcard5 updated td-module-date-blogcard5"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    04 JAN 2020 / Dubai
                  </time>
                </span>

                <div className="td-post-views-blogcard5">
                  <i className="fa fa-eye view-icon-views-blogcard5"></i>
                  <span className="td-nr-views-10968">11235</span>
                </div>
              </div>
            </header>
          </center>
        </div>


      



        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard5">
            <div className="heading-secondary-uk-section-wow-blogscard5 ">
              WOW News & Trends
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard5-description-area-of-main--text-above">
          Love it or loathe it, shopping is high on many a traveller’s to-do list. Some people will even
           base an entire holiday around the shopping experience. Whether you’re into discounted 
           high-end brands or haggling 
          at the local market there’s a shopping holiday for you. Here are seven of our favourites. 
          Getting the best price on both necessities 
            and splurges boils down to timing. Knowing when seasonal items are likely to go on 
            sale and when major sales take place allows you to buy everything 
            you want but pay significantly less.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard5-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog5/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard5-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog5/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard5-description-area-of-main--text-above">
          When it comes to shopping every one of us gets so excited I guess especially girls. Even some people
           are just shopping addicts. And why shouldn’t you be, if you have money 
           and bank balance, then all you can do is shopping obviously? Buying good stuff, spending 
          on your dresses, shoes, bags, makeup, get-over, and many more but Alas! Here comes the problem.
          </p>

          <p class="blogscard5-description-area-of-main--text-above">
          It is a moment of rejoice to introduce ourselves to our clients. We
           Wow Travels & Tours believe whatever we have achieved; its credit should be
            directed towards our clients.
            <br />
            Hands up if you want to take a vacation, but your account balance has other ideas. Well,
             we’re here to show you that you don’t need a bank account that’s bursting with 
             digits to take a well-deserved getaway. With these eight 
            top tips, we’ll prove you can save money without scrimping on awesome vacation experiences.  
          </p>
        </Container>
        <br />

        <div className="gradient-back-travel-blogscard5">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard5-grid-text">
                  <div className="travel-blogscard5-page-araea-sub-headings110">
                 Shopping in NYC
                  </div>

                  <div className="travel-blogscard5-page-page-text">
                  A shopping oasis, New York City caters to every type of traveler, from Fifth Avenue 
                  and its high-end brands and designers to pop-up holiday shops dotted around Bryant 
                  Park and Union Square, which also offer a dose of Christmas fun with hot chocolate,
                   treats and an ice-skating rink. Admire festive department store displays and visit 
                   Macy’s on 34th Street, then browse discount retailers or pick up kid-friendly 
                  toys at FAO Schwartz, American Girl on Fifth Avenue or the LEGO store at Rockefeller Center.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog5/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard5">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog5/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard5-grid-text">
                  <div className="travel-blogscard5-page-araea-sub-headings110">
                    Shop Europe
                  </div>

                  <div className="travel-blogscard5-page-page-text">
                  A romantic European city with loads of charm, In Europe opulent buildings decorated 
                  with sparkling lights and festive activities make top destination in the 
                  world. You’ll find pedestrian-friendly zones lined with shops selling luxurious goods,
                   works of art and antiques, while its markets such as Schönbrunn and Belvedere
                    sell a range of festive specialities. You can’t miss the annual Christkindlmarkt on 
                    Rathausplatz, where you can enjoy mulled wine and local pastries while browsing for handmade
                   ornaments.. Art lovers looking for a unique piece can head to Kunstsupermarkt.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>



        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard5">
            <div className="heading-secondary-help-community-section-wow-blogscard5 ">
              Best Shopping Destination In Europe
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard5">
            <div class="description_of_card_of_blogscard5_area">
              <h2 class="blogscard5-description-area-of-main-headings">
              How can you go shopping when you don’t have your own conveyance?
              </h2>
              <p class="blogscard5-description-area-of-main--text">
              That’s so sad and hectic to handle without your own car. But don’t worry, we have a solution
               for you: ‘WOW Electronic Transport Services’. WOW lets the transportation 
               services in your hand. You can now 
              book a car for you and will do the shopping and move anywhere and anytime without any problem.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog5/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog5/help-tips-grid2.jpg" />
                </Col>
              </Row>

              <h2 class="blogscard5-description-area-of-main-headings">
              How can WOW helps with shopping?
              </h2>
              <p class="blogscard5-description-area-of-main--text">
              WOW for shopping will allow you to book a car of your choice and our Leader will take you to
               the places where you wanted to shop and even stays with you waiting for you to shop 
               and take to back while you are done. You don’t even have to carry all the bags with 
               you, you can place them in the car and your Leader will take the responsibility 
              to secure those bags and even as per your instructions you can let the Leader carry your bags.
                <br />
                <br />
                Dubai is a world unto its own, and in this world the polarity of
                East and West converges in an architectural juxtaposition that,
                surprisingly, works.
              </p>

              <h2 class="blogscard5-description-area-of-main-headings">
              How much WOW charges for shopping?
              </h2>
              <p class="blogscard5-description-area-of-main--text">
              Depending upon the distance you have covered in the car and the services. It also depends 
              upon the time and type of car. So keeping all the factors in mind, you will get a final 
              cost which will be quite reasonable as compared 
              to the conveyance you will take every time you move from one place to another while shopping.
              </p>

              <h2 class="blogscard5-description-area-of-main-headings">
                Explore New York’s iconic sights
              </h2>
              <p class="blogscard5-description-area-of-main--text">
                The City That Never Sleeps is best from above, so get your skip
                the line tickets for the Top of the Rock and the Empire State
                Building and snap the skyline of New York. Whatever goes up must
                come down, so head to the High Line, Brooklyn Bridge, and the
                Staten Island Ferry to continue your photo tour.{" "}
              </p>

              <p class="blogscard5-description-area-of-main--text">
                The most popular attraction in New York City is the Metropolitan
                Museum of Art, which just set a new record of its own. The jewel
                of Museum Mile welcomed a record high 7.35 million visitors in
                its 2018 fiscal year that began on July 1, 2017. Everyone wants
                to visit The Statue of Liberty, The Empire State Building, Grand
                Central Terminal and the Brooklyn Bridge as famous, and
                historic, New York City landmarks.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard5">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog5/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard5">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog5/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard5-description-area-of-main-headings">
              Outlets Shops In Europe That Are Worth to visit and why
              </h2>
              <p class="blogscard5-description-area-of-main--text">
              Yes – Everyone is crazy about shopping! No doubt we always need something for shopping 
              and women to go crazier when they see the “SALE” sign written across any fashion outlets. {" "}
              Well, come to the point – there are plenty of best outlets around all over Europe which
               offers you stunning & tremendous deals and affordable rates!
               Here we are going to list very best outlets shops in Europe that has great worth!
              </p>

              <ul className="list-circle-type-blogscard">
                <li class="blogscard5-description-area-of-main--text">
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                    La Roca Village – “Barcelona, Catalonia”: {" "}
                    </span>
                    If you ever visit Barcelona, then you have a better idea. If no, then make a trip and
                     get a vast collection of latest and exciting designer brands. Apart from it, 
                     there you can also find out a great range of some older classic brands too. It is the largest 
                    shopping village; you have to visit Catalan capital of Barcelona to get your shop on!{" "}
                  </p>
                </li>
                <li class="blogscard5-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>Bicester Village, Oxford, England: </span>
                  If you are England and look for designer brands within affordable rates, then Bicester 
                  Village is the great option. Yes, this shopping village is one of the most popular 
                  tourist’s attractions in the country, and you need just an hour from London to reach your 
                  fashion destination. Visit, the fabulous shopping store and get a vast range of designer brands.
                </li>
                <li class="blogscard5-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>
                  The Mall Fashion Outlets:  {" "}
                  </span>
                  Do you have an Italian shopping experience? If no, then make a trip to this outlet to know
                   why Italy is such a fashion capital. In the region of Italy, this mall is the largest 
                   that has a lot of the big names brands in design & fashion of the world. With this
                    town of fashion, you can find a stunning variety 
                  of fashion, including outfits, wallets, handbags, glasses, and what you are really looking!
                </li>
              </ul>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard5">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog5/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard5">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog5/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard5-description-area-of-main-headings">
              Popular shops / food court!!
              </h2>

              <p class="blogscard5-description-area-of-main--text">
              Many of you may have noticed this, either the food court or the popular shops are on the top 
              floor of the shopping mall. This is also done to create more foot traffic for the other shops in 
              the lower floors of the building. Encouraging you to buy something from the other stores too.{" "}
              </p>

              <p class="blogscard5-description-area-of-main--text">
              Malls are generally created in abnormal shapes or arcs. This is deliberately done to include 
              bends and turns in the building. This is to ensure that a shopper doesn’t feel like 
              they have to walk a long distance inside the shopping mall,
               the bends and turns gives you the illusion that you don’t have a lot of distance to cover.{" "}
              </p>

              <div class="description_of_card_of_blogscard5_bottom_area">
                <div class="description_of_card_of_blogscard5_bottom_area_previous">
                  <h3>
                    <i class="fas fa-reply" aria-hidden="true"></i>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard5_bottom_area_next">
                  <h3>
                    <i class="fa fa-mail-forward" aria-hidden="true"></i>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}
      </div>
    );
  }
}
export default Blogpost5;
