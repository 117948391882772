import React, { Component } from "react";




import {
  Form,
  Container,
  Card,
  Row,
  Carousel,
 
  Col
} from "react-bootstrap";

// const responsive = {
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 2,
//       slidesToSlide: 1, // optional, default to 1.
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 1,
//       slidesToSlide: 1, // optional, default to 1.
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//       slidesToSlide: 1, // optional, default to 1.
//     },
//   };





class Anews extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date
    });
  }
  render() {
    return (


<Container>




<center>
    <h6 className="news-header-text">
        WOW NEWS</h6>
        </center>

        <br></br>


          <div className="carousel-border">
         

           
        <Carousel>
            
               
  <Carousel.Item>
  <Row >
      <Col xs={12} md={6} sm={6} lg={6} xl={6}>
     
    <Container><p className="carousel-inside-text-header">WHO WILL DRIVE YOUR WOW JOURNEY WITH WOW? A 'LEADER'</p>
    <p className="carousel-inside-text">Here comes the first major concern regarding your ride, you might be thinking of who the leader is?</p>
    
    <a href="#" className="carousel-inside-link" style={{textAlign: 'right'}} >Read Full News></a>
    
   
    
     <div class="date">
        <p>  {this.state.date}</p>
      </div>

    

     </Container>
   
    </Col>

    <Col xs={12} md={6} sm={6} lg={6} xl={6}>
    <img
    className="image-in-carousel-news"
      src="IMAGES/news12345678.jpg"
      alt="First slide"
      style={{width:'100%', height: '100%'}}
    />
 
    </Col>
    </Row> 
     
    
  </Carousel.Item>



  <Carousel.Item>
  <Row>
  <Col xs={12} md={6} sm={6} lg={6}>
  <Container>
     
     <p><p className="carousel-inside-text-header">WHO WILL DRIVE YOUR WOW JOURNEY WITH WOW? A 'LEADER'</p>
     <p className="carousel-inside-text">Here comes the first major concern regarding your ride, you might be thinking of who the leader is?</p>
     <a href="#" className="carousel-inside-link">Read Full News></a></p>
     
    
    
    
     <div class="date">
        <p>  {this.state.date}</p>
      </div>

     
 
 
     </Container>
     </Col>
    <Col xs={12} md={6} sm={6} lg={6}>
    <img
      className="image-in-carousel-news"
      src="IMAGES/news12345678.jpg"
      alt="Second slide"
      style={{width:'100%', height: '100%'}}
    />
     
    </Col>
    </Row>

   
  </Carousel.Item>



  <Carousel.Item>
  <Row>
  <Col xs={12} md={6} sm={6} lg={6}>
  <Container>
     
     <p><p className="carousel-inside-text-header">WHO WILL DRIVE YOUR WOW JOURNEY WITH WOW? A 'LEADER'</p>
     <p className="carousel-inside-text">Here comes the first major concern regarding your ride, you might be thinking of who the leader is?</p>
     <a href="#" className="carousel-inside-link">Read Full News></a></p>
    
   
    
    
     <div class="date">
        <p>  {this.state.date}</p>
      </div>

    
 
 
     </Container>
     </Col>
    <Col xs={12} md={6} sm={6} lg={6}>
    <img
      className="image-in-carousel-news"
      src="IMAGES/news12345678.jpg"
      alt="Third slide"
      style={{width:'100%', height: '100%'}}
    />
    
    </Col>
    </Row>

    
  </Carousel.Item>
  






 



</Carousel>
</div>


</Container>






 



    );
  }
}
export default Anews;