import React from 'react';
import {render, IndexRoute} from 'react-dom';
// import './App.css';
import Header2 from './Header2/header.js';
import HolidayAirportTaxi from './HolidayAirportTaxi/AirportTaxi';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer/Footer.js';
import SLIDER from './SLIDER/aboutus.js';
import Grd from './Grd/grid.js';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Home from './Home/home';
import Passwordreset from './Passwordreset/reset';
import Table from './Table/table.js';
import VendorStatement from './VendorStatement/vstatement.js';
import Custstatement from './Custstatement/custstatement';
import Adminpanel from './Adminpanel/adminsignin';
// import './Adminpanel/admin.css';
import Wownews from './Wownews/news.js';
// import './Wownews/news.css';
import Wowabove from './Wowabove/anews.js';
import Newscontext from './Newscontext/context.js';
// import './Newscontext/context.css';
import Ridedetails from './Ridedetails/ride.js';
import './Ridedetails/ride.css';
import Webpages from './Webpages/notifemail.js';
// import './Webpages/notif.css';
import Emaildisapproval from './Emaildisapproval/disapproval.js';
// import './Emaildisapproval/disapproval.css';
import Loginintimation from './Loginintimation/intimation.js';
import './Loginintimation/intimation.css';
import Otacemail from './Otacemail/otac.js';
// import './Otacemail/otac.css';
import Welcomeemailleader from './Welcomeemailleader/emailleader.js';
// import './Welcomeemailleader/emailleader.css';
import Welcometowow from './Welcometowow/logincredentials.js';
// import './Welcometowow/logincredentials.css';

import AdminSignup from './AdminSignup/adminsignup.js';
// import './AdminSignup/adminsignup.css';
import Newdesign from './Newdesign/newdesign.js';
import './Newdesign/newdesign.css';

import Wowholidaysdesign from './Wowholidaysdesign/wowholiday.js';
import './Wowholidaysdesign/wowholiday.scss';

import Wowholidaycards from './Wowholidaycards/wowholidaycards.js';

import Wowholidayslider from './Wowholidayslider/wowholidayslider.js';
// import './Wowholidayslider/wowholidayslider.css';


import Wowholidayfooter from './Wowholidayfooter/wowholidayfooter.js';
import './Wowholidayfooter/wowholidayfooter.css';

import Multicarouselwowholiday from './Multicarouselwowholiday/multicarousel.js';
import './Multicarouselwowholiday/multicarousel.css';


import Wowholidaycontactus from './Wowholidaycontactus/holidaycontactus.js';
import './Wowholidaycontactus/holidaycontactus.css';


import Aboutuswowholiday from './Aboutuswowholiday/aboutuswow.js';
import './Aboutuswowholiday/aboutuswow.css';

import Wowholidaycredits from './Wowholidaycredits/holidaycredits.js';
import './Wowholidaycredits/holidaycredits.css';


import Wowholidayinspiration from './Wowholidayinspiration/holidayinspiration.js';
import './Wowholidayinspiration/holidayinspiration.css';


import Holidayinspirationcard1 from './Holidayinspirationcard1/inspirationcard1.js';
import './Holidayinspirationcard1/inspirationcard1.css';


import Wowholidaygallery from './Wowholidaygallery/holidaygallery.js';
import './Wowholidaygallery/holidaygallery.css';


import Wowholidaytransportservices from './Wowholidaytransportservices/transportservices.js';
import './Wowholidaytransportservices/transportservices.css';

import Privacypolicywowholiday from './Privacypolicywowholiday/privacypolicy.js';
import './Privacypolicywowholiday/privacypolicy.css';

import Trustsafetywowholiday from './Trustsafetywowholiday/trustsafety.js';
import './Trustsafetywowholiday/trustsafety.css';


import Termsconditionswowholiday from './Termsconditionswowholiday/termsconditions.js';
import './Termsconditionswowholiday/termsconditions.css';

import Blogpostwowholiday from './Blogpostwowholiday/blogpost.js';
import './Blogpostwowholiday/blogpost.scss';


import Wowholidayhelppage from './Wowholidayhelppage/helpcenter.js';
import './Wowholidayhelppage/helpcenter.css';

import Wowholidayhowitworks from './Wowholidayhowitworks/howitworks.js';

import Wowholidaynews from './Wowholidaynews/newsroom.js';
import './Wowholidaynews/newsroom.css';


import Wowholidaycommunity from './Wowholidaycommunity/community.js';
import './Wowholidaycommunity/community.css';

import Wowholidaycommunityguideline from './Wowholidaycommunityguideline/communityguideline.js';
import './Wowholidaycommunityguideline/communityguideline.css';

import Wowtravelhowworks from './Wowtravelhowworks/travelworks.js';
import './Wowtravelhowworks/travelworks.css';


import Wownewsroomcard1 from './Wownewsroomcard1/newscard1.js';
import './Wownewsroomcard1/newscard1.css';

import Wownewsroomcard2 from './Wownewsroomcard2/newscard2.js';
import './Wownewsroomcard2/newscard2.css';

import Wownewsroomcard3 from './Wownewsroomcard3/newscard3.js';
import './Wownewsroomcard3/newscard3.css';

import Wownewsroomcard4 from './Wownewsroomcard4/newscard4.js';
import './Wownewsroomcard4/newscard4.css';

import Wownewsroomcard5 from './Wownewsroomcard5/newscard5.js';
import './Wownewsroomcard5/newscard5.css';

import Wownewsroomcard6 from './Wownewsroomcard6/newscard6.js';
import './Wownewsroomcard6/newscard6.css';

import Wowholidayblogpost1 from './Wowholidayblogpost1/blogpost1.js';
import './Wowholidayblogpost1/blogpost1.css';

import Wowholidayblogpost2 from './Wowholidayblogpost2/blogpost2';
import './Wowholidayblogpost2/blogpost2.css';
import './Wowholidayblogpost2/blogpost2.scss';


import Wowholidayblogpost3 from './Wowholidayblogpost3/blogpost3';
import './Wowholidayblogpost3/blogpost3.scss';



import Legalemiratesinvoiceview from './Legalemiratesinvoiceview/invoiceview';
import './Legalemiratesinvoiceview/invoiceview.scss';

import Lenooneinvoice from './Lenooneinvoice/nooneinvoice';


import LEemailinvoice13 from './LEemailinvoice13/emailinvoice13';
import './LEemailinvoice13/emailinvoice13.css';

import LEcustomerlist from './LEcustomerlist/lecustomerlist';
import './LEcustomerlist/lecustomerlist.scss';

import Lecustomisedservices from './Lecustomisedservices/lecustomisedservies';

import Lechequedetails from './Lechequedetails/lechequedetails';

import LEnewnoinvoice from './LEnewnoinvoice/newnooneinvoice';

import Wowholidayblogpost4 from './Wowholidayblogpost4/blogpost4';

import Wowholidayblogpost5 from './Wowholidayblogpost5/blogpost5';

import Wowholidayblogpost6 from './Wowholidayblogpost6/blogpost6';

import Wowholidayblogpost7 from './Wowholidayblogpost7/blogpost7';

import Wowholidayblogpost8 from './Wowholidayblogpost8/blogpost8';

import Wowholidayblogpost9 from './Wowholidayblogpost9/blogpost9.js';

import Wowholidayblogpost10 from './Wowholidayblogpost10/blogpost10.js';

import Wowholidayblogpost11 from './Wowholidayblogpost11/blogpost11.js';

import Wowholidayblogpost12 from './Wowholidayblogpost12/blogpost12.js';

import Wowholidayblogpost13 from './Wowholidayblogpost13/blogpost13.js';

import Wowholidayblogpost14 from './Wowholidayblogpost14/blogpost14.js';

import Wowholidayblogpost15 from './Wowholidayblogpost15/blogpost15.js';

import TopcitiesTovisit from './TopcitiesTovisit/topCities';

import RentalCarsWowHoliday from './RentalCarsWowHoliday/RentalCars';

import Rental_cars_booking from './RentalCarsWowHoliday/RentalCarsBookingFilterPage';

import RentalCars_FormCustomerDetails from './RentalCarsWowHoliday/RentalCarsBookingCustomerDetailsForm.js'

// import WowHolidaySignUp from './WowHolidaySignUp/HolidaySignUp';

// import WowBookingticketholidaysystem from './WowBookingticketholidaysystem/bookingticketsystem';

// import WowBookingticketholidaysystem2 from './WowBookingticketholidaysystem2/bookingticketsystem2';

// import Tawktochatbox from './Tawktochatbox/chatbox';

// import Reactglobeearthpractice from './Reactglobeearthpractice/reactglobeearthpractice';


import CookiesNotify from './cookies-notify-holiday';
import { ToastContainer } from 'react-toastify';

// import Adminsignuppractice from './Adminsignuppractice/Formadminsignuppractice';

import Apipractice from './Apipractice/apipractice';

// import DropDownbutton from './DropDownbutton/dropdown';

import Navbar from './Navbar/header';


// import Modalexample from './Modalexample/modal2';



import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
  
} from "react-router-dom";

export default class App extends React.Component{
  render(){
    return (
    <div>



{/* <ToastContainer autoClose={2500} position="top-center" /> */}
  {/* <CookiesNotify /> */}


{/* <Wowholidaygallery/> */}



{/* <Legalemiratesverifyemail/> */}

<Router history="browserhistory">

<Wowholidaysdesign/>

{/* <WowHolidaySignUp/> */}

{/* <DropDownbutton/> */}

{/* <Tawktochatbox/> */}

{/* <Reactglobeearthpractice/> */}

{/* <Apipractice/> */}

{/* <LEnewnoinvoice/> */}

{/* <Lechequedetails/> */}

{/* <Lecustomisedservices/> */}

{/* <LEcustomerlist/> */}

{/* <LEemailinvoice13/> */}

{/* <Lenooneinvoice/> */}

{/* <Legalemiratesinvoiceview/> */}


{/* <WowBookingticketholidaysystem/> */}





<switch>
      
  <Route exact path="/" component={Wowholidaycards}/>

  {/* Top Headder Section */}
  <Route path="/AirportTaxi" component={HolidayAirportTaxi}/>
  <Route path="/RentalCars"  component={RentalCarsWowHoliday} />
  <Route path='/RentalCarsBooking' component={Rental_cars_booking} />
  <Route path='/RentalCarsBookingDetailsForm' component={RentalCars_FormCustomerDetails} />
  
  {/* Below Header Section */}
  <Route path="/holidayinspiration" component={Wowholidayinspiration}/>    
  <Route path="/holidaygallery" component={Wowholidaygallery}/>
  <Route path="/aboutuswow" component={Aboutuswowholiday}/>
  <Route path="/holidaycontactus" component={Wowholidaycontactus}/>

{/* home-page-section */}
  <Route path="/holidaycredits" component={Wowholidaycredits}/>
  <Route path="/holidayblogs" component={Blogpostwowholiday}/>
  <Route path="/topcities" component={TopcitiesTovisit}/>
  
{/* news room cards router */}
<Route path="/newscard1" component={Wownewsroomcard1}/>
<Route path="/newscard2" component={Wownewsroomcard2}/>
<Route path="/newscard3" component={Wownewsroomcard3}/>
<Route path="/newscard4" component={Wownewsroomcard4}/>
<Route path="/newscard5" component={Wownewsroomcard5}/>
<Route path="/newscard6" component={Wownewsroomcard6}/>

{/* Blog post cards router section */}
<Route path="/blogpost1" component={Wowholidayblogpost1}/>
<Route path="/blogpost2" component={Wowholidayblogpost2}/>
<Route path="/blogpost3" component={Wowholidayblogpost3}/>
<Route path="/blogpost4" component={Wowholidayblogpost4}/>
<Route path="/blogpost5" component={Wowholidayblogpost5}/>
<Route path="/blogpost6" component={Wowholidayblogpost6}/>
<Route path="/blogpost7" component={Wowholidayblogpost7}/>
<Route path="/blogpost8" component={Wowholidayblogpost8}/>
<Route path="/blogpost9" component={Wowholidayblogpost9}/>
<Route path="/blogpost10" component={Wowholidayblogpost10}/>
<Route path="/blogpost11" component={Wowholidayblogpost11}/>
<Route path="/blogpost12" component={Wowholidayblogpost12}/>
<Route path="/blogpost13" component={Wowholidayblogpost13}/>
<Route path="/blogpost14" component={Wowholidayblogpost14}/>
<Route path="/blogpost15" component={Wowholidayblogpost15}/>

{/* footer section */}
  <Route path="/transportservices" component={Wowholidaytransportservices}/> 
  <Route path="/privacypolicy" component={Privacypolicywowholiday}/>
  <Route path="/trustsafety" component={Trustsafetywowholiday}/>
  <Route path="/termsconditions" component={Termsconditionswowholiday}/>
  <Route path="/helpcenter" component={Wowholidayhelppage}/>
  <Route path="/howitworks" component={Wowholidayhowitworks}/>
  <Route path="/newsroom" component={Wowholidaynews}/>
  <Route path="/community" component={Wowholidaycommunity}/>
  <Route path="/communityguidline" component={Wowholidaycommunityguideline}/>
  <Route path="/travelworks" component={Wowtravelhowworks}/>
  
</switch>

  <Wowholidayfooter/>
</Router> 



{/* <Route exact path="/" component={Wowholidayinspiration}/>
  <Route path="/inspirationcard1" component={Holidayinspirationcard1}/>
  <Route path="/holidayinspirationalcard2" component={Holidayinspirationcard2}/> */}




{/* <Holidayinspirationcard1/> */}




{/* <Wowholidaysdesign/> */}


{/* <Wowholidaycredits/> */}


      
      {/* <br></br> 
      <br></br> */}
      {/* <br></br> */}



      {/* <Wowholidaycards/> */}
      {/* <Wowholidaycontactus/> */}
      {/* <br></br> */}
{/* <Wowholidayslider/> */}


     


      {/* <Aboutuswowholiday/> */}

      {/* <br></br> 
      <br></br>
      <br></br> */}
      {/* <br></br> */}

      {/* <Wowholidayfooter/> */}


      {/* <Multicarouselwowholiday/> */}













      {/* <Newdesign/> */}


      {/* <br></br>
      <br></br>
      <br></br> 
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br> 
      <br></br>
      <br></br>
      <br></br> */}













     
      {/* <AdminSignup/> */}


      {/* <Adminpanel/> */}


      {/* <Webpages/>

      <center>
        <h1 style={{color:'blue', fontSize:'50px'}}>///////////</h1>
      </center>

      <br></br>
      <br></br>
      <br></br>
      <br></br> */}



      {/* <Welcometowow/> */}

      {/* <br/><br/><br/>
      <br/><br/><br/>

      <center>
        <h1 style={{color:'blue', fontSize:'50px'}}>///////////</h1>
      </center>

      <br/><br/><br/>
      <br/><br/><br/>


      <Welcomeemailleader/> */}





      {/* <Otacemail/> */}

      


      {/* <Emaildisapproval/> */}

      


      {/* <Loginintimation/> */}





      {/* <Ridedetails/> */}





      {/* <emailnotify/> */}








      {/* <Newscontext/> */}


      {/* <Wowabove/> */}

    {/* <Wownews/> */}
    
    
   




      {/* <Router history="browserhistory">
      <Header2/>
      <switch>
  <Route exact path="/" component={Home}/>
  
  <Route path="/aboutus" component={SLIDER}/>
  </switch>

 
</Router>


<Footer/> */}







{/* <Grd/> */}

      {/* <Route path='/' compoent={NavBar}/> */}
      {/* <Passwordreset/> */}
    {/* <Table/> */}
    {/* <br></br> */}
      {/* <VendorStatement/> */}
      {/* <br></br>
      <br></br>
      <br></br> */}
      
      {/* <Custstatement/> */}
      
    </div>
    )
  }
}

