import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  NavLink,
  Route,
  browserhistory,
} from "react-router-dom";

class Privacypolicy extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false,
    };
  }

  privacypolicy = () => {
    document.getElementById("privacy-policy").style.display = "block";
    document.getElementById("acceptance-policy").style.display = "none";
  };

  acceptancepolicy = () => {
    document.getElementById("privacy-policy").style.display = "none";
    document.getElementById("acceptance-policy").style.display = "block";
  };

  headings1privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "block";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "block";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  // document.getElementById("privacy-policy").style.display = 'none';
  //   document.getElementById("acceptance-policy").style.display = "block";

  headings2privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "block";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "block";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  headings3privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "block";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "block";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  headings4privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "block";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "block";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  headings5privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "block";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "block";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  headings6privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "block";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "block";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  headings7privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "block";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "block";
    document.getElementById("margin-right-outline8").style.display = "none";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };

  headings8privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "block";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "block";

    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
  };
  userright = () => {
    document.getElementById("user-right").style.display = "block";
    document.getElementById("icon-user").style.display = "none";
    document.getElementById("icon-users").style.display = "block";
  };

  userrights = () => {
    document.getElementById("user-right").style.display = "none";
    document.getElementById("icon-user").style.display = "block";
    document.getElementById("icon-users").style.display = "none";
  };

  userright2 = () => {
    document.getElementById("user-right2").style.display = "block";
    document.getElementById("icon-user2").style.display = "none";
    document.getElementById("icon-users2").style.display = "block";
  };

  userrights2 = () => {
    document.getElementById("user-right2").style.display = "none";
    document.getElementById("icon-user2").style.display = "block";
    document.getElementById("icon-users2").style.display = "none";
  };

  userright3 = () => {
    document.getElementById("user-right3").style.display = "block";
    document.getElementById("icon-user3").style.display = "none";
    document.getElementById("icon-users3").style.display = "block";
  };

  userrights3 = () => {
    document.getElementById("user-right3").style.display = "none";
    document.getElementById("icon-user3").style.display = "block";
    document.getElementById("icon-users3").style.display = "none";
  };

  alertshow = () => {
    alert("asdasdad");
  };

  openNav = () => {
    document.getElementById("mySidenav").style.height = "100%";
    document.getElementById("close-button").style.display = "";
  };

  closeNav = () => {
    document.getElementById("mySidenav").style.height = "0";
    document.getElementById("close-button").style.display = "none";
  };

  openNavprivacypolicy = () => {
    document.getElementById("mySidenav-privacypolicy").style.width = "300px";
    document.getElementById("close-button-privacypolicy").style.display = "";
  };

  closeNavprivacypolicy = () => {
    document.getElementById("mySidenav-privacypolicy").style.width = "0px";
    document.getElementById("close-button-privacypolicy").style.display =
      "none";
  };

  render() {
    return (
      <div>
        <div className="holiday-privacy-policy-wow-holiday-our-container">
          <div class="holiday-privacy-policy-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-privacy-policy-wow-holiday-home-our-story">
                <div class="holiday-privacy-policy-wow-holiday-our-team-heading">
                  Privacy Policy
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*        

        <div>
          <header className="header-image-in-new-design-transport">


   <div className="logo-inside-header-of-new-design-page-transport">
     <img className="image-logo-inside-header-of-new-design-page-wow-transport" src="IMAGES/WOWholiday-iconlogo.png"></img>
   </div>


   <div className="logo-inside-header-of-new-design-page--wow-transport">
     <img className="image-logo-inside-header-of-new-design-page-transport" src="IMAGES/city-images/wow-gallery/tour-logo.png"></img>
   </div>


   <div className="text-box-inside-background-image-of-new-design-transport">
  <h1 className="heading-primary-transport">
 



    <span className="heading-primary-main-transport">
      WOW Holiday

    </span>
    <span className="heading-primary-sub-transport">
      Transport,Services & Team

    </span>
  </h1>

  <div class="holiday-transport-wow-holiday-our-team-heading-below-text">
                  We provide the best transport &services that you need during your trip. Our team is very
                   cooperative, provide good hospitality and keep you entertain all the way. 
                </div>
  {/* <Button variant="outline-light" id="scrolltoup" onClick={this.toscrolltoup} className="button-in-new-design-gallery" > Discover Our Tours</Button> */}
        {/* 
  </div>
  
  <toggle>

  </toggle>



          </header></div> */}

        {/* 
          <Container>


          <div class="u-center-text u-margin-bottom-second-heading-transport">
                  <div  className="heading-secondary-section-transport-holiday ">
                      WOW Holiday Transport & Services
                  </div>
                  </div>


          </Container>

        */}

        <div class="privacy_and_policy1">
          <Container>
            <center>
              <h3 class="privacy_and_policy">
                WOW Worldwide Holiday Privacy Policy
              </h3>
            </center>
            <div class="onPCMode">
              <div class="jss8">
                <Row>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={4}
                    className="muitab-in-mobile-view-privacy-policy"
                  >
                    <div
                      className="MuiTabs-root jss9 MuiTabs-vertical"
                      aria-label="Vertical tabs example"
                    >
                      <div
                        className="MuiTabs-scrollable"
                        style={{
                          width: "99px",
                          height: "99px",
                          position: "absolute",
                          top: "-9999px",
                          overflow: "scroll",
                        }}
                      ></div>

                      <div
                        className="MuiTabs-scroller MuiTabs-scrollable"
                        style={{ marginBottom: "0px" }}
                      >
                        <div
                          className="MuiTabs-flexContainer MuiTabs-flexContainerVertical"
                          role="tablist"
                        >
                          <button
                            onClick={this.headings1privacypolicy}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit yo terms-and-conditions-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>{" "}
                                Privacy Policy
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings2privacypolicy}
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-1"
                            aria-controls="vertical-tabpanel-1"
                          >
                            <span className="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Cookie policy
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings3privacypolicy}
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-2"
                            aria-controls="vertical-tabpanel-2"
                          >
                            <span className="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Acceptable use policy
                              </span>
                            </span>

                            <span className="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings4privacypolicy}
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-3"
                            aria-controls="vertical-tabpanel-3"
                          >
                            <span className="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Heading 4
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings5privacypolicy}
                            className="MuiButtonBase-root MuiTab-root on-focus-button-termcondition MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-4"
                            aria-controls="vertical-tabpanel-4"
                          >
                            <span class="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Heading 5
                              </span>
                            </span>
                            <span class="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings6privacypolicy}
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-5"
                            aria-controls="vertical-tabpanel-5"
                          >
                            <span class="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Heading 6
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings7privacypolicy}
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-5"
                            aria-controls="vertical-tabpanel-5"
                          >
                            <span class="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Heading 7
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>

                          <button
                            onClick={this.headings8privacypolicy}
                            className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="false"
                            id="vertical-tab-5"
                            aria-controls="vertical-tabpanel-5"
                          >
                            <span class="MuiTab-wrapper">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                                Disclaimer
                              </span>
                            </span>
                            <span className="MuiTouchRipple-root"></span>
                          </button>

                          {/* <Button onClick={this.headings6privacypolicy} className="MuiButtonBase-root-button MuiTab-root-button MuiTab-textColorInherit-button terms-and-conditions-tab-name-button" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
  <span class="MuiTab-wrapper-button">
  <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading-button"></i>Disclaimer</span></span>
<span className="MuiTouchRipple-root-button"></span>
</Button>

<Button onClick={this.headings6privacypolicy} className="MuiButtonBase-root-button MuiTab-root-button MuiTab-textColorInherit-button terms-and-conditions-tab-name-button" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
  <span class="MuiTab-wrapper-button">
  <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading-button"></i>asdasdadsad</span></span>
<span className="MuiTouchRipple-root-button"></span>
</Button>

<Button onClick={this.headings6privacypolicy} className="MuiButtonBase-root-button MuiTab-root-button MuiTab-textColorInherit-button terms-and-conditions-tab-name-button" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
  <span class="MuiTab-wrapper-button">
  <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading-button"></i>OPASKpdmasp</span></span>
<span className="MuiTouchRipple-root-button"></span>
</Button> */}
                        </div>

                        {/* <span className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy" style={{top: '0px', height: '48px'}}>
</span> */}

                        <span
                          style={{ top: "0" }}
                          id="margin-right-outline1"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "48px", display: "none" }}
                          id="margin-right-outline2"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "96px", display: "none" }}
                          id="margin-right-outline3"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "144px", display: "none" }}
                          id="margin-right-outline4"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "192px", display: "none" }}
                          id="margin-right-outline5"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "240px", display: "none" }}
                          id="margin-right-outline6"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "288px", display: "none" }}
                          id="margin-right-outline7"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "346px", display: "none" }}
                          id="margin-right-outline8"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>
                      </div>
                    </div>
                  </Col>

                  <Container className="sidenav-privacy-policy-mobile-view privacy-menu-button-border">
                    <div
                      id="mySidenav-privacypolicy"
                      className="sidenav-privacy-policy"
                    >
                      <a
                        href="javascript:void(0)"
                        className="closebtn-privacypolicy"
                        id="close-button-privacypolicy"
                        onClick={this.closeNavprivacypolicy}
                      >
                        &times;
                      </a>
                      {/* <a onClick={this.headings1privacypolicy}>Privacy policy</a>

  <a onClick={this.headings2privacypolicy}> Cookie policy</a>
  <a href="#">Clients</a>
  <a href="#">Contact</a> */}

                      <button
                        onClick={this.headings1privacypolicy}
                        activeClassName="active-privacy-policy"
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit yo terms-and-conditions-tab-name Mui-selected"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="true"
                        id="vertical-tab-0"
                        aria-controls="vertical-tabpanel-0"
                      >
                        <span class="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>{" "}
                            Privacy policy
                          </span>{" "}
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings2privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-1"
                        aria-controls="vertical-tabpanel-1"
                      >
                        <span className="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Cookie policy
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings3privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-2"
                        aria-controls="vertical-tabpanel-2"
                      >
                        <span className="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Acceptable use policy
                          </span>
                        </span>

                        <span className="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings4privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-3"
                        aria-controls="vertical-tabpanel-3"
                      >
                        <span className="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Heading 4
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings5privacypolicy}
                        className="MuiButtonBase-root MuiTab-root on-focus-button-termcondition MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-4"
                        aria-controls="vertical-tabpanel-4"
                      >
                        <span class="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Heading 5
                          </span>
                        </span>
                        <span class="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings6privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-5"
                        aria-controls="vertical-tabpanel-5"
                      >
                        <span class="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Heading 6
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings7privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-5"
                        aria-controls="vertical-tabpanel-5"
                      >
                        <span class="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Heading 7
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings8privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-5"
                        aria-controls="vertical-tabpanel-5"
                      >
                        <span class="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Disclaimer
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>

                      <button
                        onClick={this.headings8privacypolicy}
                        className="MuiButtonBase-root MuiTab-root MuiTab-textColorInherit terms-and-conditions-tab-name"
                        tabindex="0"
                        type="button"
                        role="tab"
                        aria-selected="false"
                        id="vertical-tab-5"
                        aria-controls="vertical-tabpanel-5"
                      >
                        <span class="MuiTab-wrapper">
                          <span>
                            <i class="fas fa-angle-double-right icons-in-term-condition-heading"></i>
                            Disclaimer
                          </span>
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                      </button>
                    </div>

                    {/* <h2>Animated Sidenav Example</h2> */}
                    <span
                      className="privacy-menu-button"
                      onClick={this.openNavprivacypolicy}
                    >
                      &#9776; Privacy Menu
                    </span>
                  </Container>

                  <Col xl={9} lg={9} md={8} sm={8} id="headings1-privacypolicy">
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>APPLICATION OF THIS PRIVACY POLICY</h4>
                          <div class="sub_details">
                            <p>
                              These privacy policies apply to all the users and
                              visitors who want to use the WOW HOLIDAY. Kindly
                              read, the Policy we have on your personal
                              information and go through all the points
                              cautiously before taking our services to avoid any
                              trouble. Reading this page will help you know the
                              use of the content of this Website.
                            </p>

                            <p>
                              Whilst reading these conditions if at any point
                              you feel disagree with something then kindly
                              discontinue using this Website or contact us
                              immediately to avoid any inconvenience. The
                              content of this Website always keeps updating
                              including the privacy policies and terms and
                              conditions, so keep yourself up to date and try
                              not to miss any important information during the
                              use of the Website.
                            </p>

                            <p>
                              This privacy policy is designed as per the rules
                              of protecting user data. We respect and protect
                              clients' and visitors’ privacy during the visit to
                              our website or when sharing personal information
                              through this website.
                            </p>
                          </div>
                        </div>

                        {/* <div class="termsandcondtions_detail">
                          <div className="right-of-user">
                            <h4>The rights of users</h4>

                            <a onClick={this.userright} id="icon-user">
                              <i className="fas fa-plus"></i>
                            </a>
                            <a
                              onClick={this.userrights}
                              style={{ display: "none" }}
                              id="icon-users"
                            >
                              <i className="fas fa-minus"></i>
                            </a>
                          </div>

                          <div class="sub_details">
                            <p>
                              You may exercise certain rights regarding your
                              information processed by us. In particular, you
                              have the right to do the following:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              id="user-right"
                              style={{ display: "none" }}
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                you have the right to withdraw consent where you
                                have previously given your consent to the
                                processing of your information;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to object to the processing
                                of your information if the processing is carried
                                out on a legal basis other than consent;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                {" "}
                                you have the right to learn if information is
                                being processed by us, obtain disclosure
                                regarding certain aspects of the processing and
                                obtain a copy of the information undergoing
                                processing;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to verify the accuracy of
                                your information and ask for it to be updated or
                                corrected;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right, under certain circumstances,
                                to restrict the processing of your information,
                                in which case, we will not process your
                                information for any purpose other than storing
                                it;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right, under certain circumstances,
                                to obtain the erasure of your Personal
                                Information from us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to receive your information
                                in a structured, commonly used and machine
                                readable format and, if technically feasible, to
                                have it transmitted to another controller
                                without any hindrance. This provision is
                                applicable provided that your information is
                                processed by automated means and that the
                                processing is based on your consent, on a
                                contract which you are part of or on
                                pre-contractual obligations thereof.{" "}
                              </li>
                            </ol>
                          </div>
                        </div> */}

                        <div class="termsandcondtions_detail">
                          <h4>COLLECTION OF PERSONAL INFORMATION </h4>
                          <div class="sub_details">
                            <p>
                              This policy gives you an idea of how we collect,
                              use, and manage the data you share with us through
                              this website when you request something from us,
                              when you ask us about travel services, travel
                              destinations, holiday plans, or anything related
                              to travel.
                            </p>

                            <p>
                              We also collect data by just asking you for it,
                              depending upon the services you want from us. We
                              may ask you to provide:
                            </p>

                            <ol
                              className="ordered-list-in-privacy-policy"
                              id="user-right"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                Your name
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Your email address
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                {" "}
                                Your landline and cellphone numbers{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Any information you share while filling out any
                                contact form on this website{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Your location (city, region, or town, etc.){" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Your job title or about your business{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Your IP Address{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Yours delivered or uploaded data{" "}
                              </li>
                            </ol>
                            <p>
                              In addition to all these, we also collect
                              technical information regarding your visit to our
                              website, for instance, the services you viewed,
                              the information you searched for, page response
                              time, length of visits, and page interaction data,
                              etc.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>PURPOSE OF COLLECTING INFORMATION</h4>
                          <div class="sub_details">
                            <p>
                              We may use this information to respond to your
                              queries as well. But mainly we use your personal
                              information to complete the travel requirements
                              and documentations, and records such as:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              id="user-right"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                To respond to queries and provide our services
                                accordingly
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                To make mutual relationship with our clients and
                                visitors
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                {" "}
                                To market our travel plans and holiday services{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                To ensure network and information security{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                To keep in touch with you and inform you about
                                new travel plans and destinations{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                To notify you about the bonuses or discounts for
                                certain amount of time{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                To investigate any complaint, you make{" "}
                              </li>
                            </ol>
                            <p>
                              We advise to provide true and authentic
                              information about you to avoid any inconvenience.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>HOW AND WHEN WE SHARE YOUR INFORMATION?</h4>
                          <div class="sub_details">
                            <p>
                              On some occasions, we are obliged to disclose your
                              information to other linked companies, for
                              instance any Airline, if you are traveling by air.
                              But we believe we must make sure the parties or
                              people with whom we are sharing your information
                              are fully trusted and won’t use that information
                              for other purposes.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>
                            HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?
                          </h4>
                          <div class="sub_details">
                            <p>
                              It is part of the policy to keep your personal
                              information for a long time, the time required for
                              the very specific purpose it has been collected
                              from you.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>THE RIGHTS YOU HAVE </h4>
                          <div class="sub_details">
                            <p>
                              You have these rights under the specific
                              circumstances
                            </p>
                            <h3>Access and Accuracy</h3>
                            <p>
                              You have the right to ask about the copies of your
                              data we shared with any third party and you can
                              take those copies too. You can confirm the
                              authenticity of the party with whom we are sharing
                              your data at any time during the process.
                            </p>

                            <h3>Erasure or Blocking </h3>
                            <p>
                              This is also known as “the right to be forgotten”
                              and in simple terms, it enables you to ask for the
                              erasure of the data of your personal information
                              that is being processed.
                            </p>

                            <h3>Data portability </h3>
                            <p>
                              You have the right to use your personal
                              information for your purposes across different
                              companies.
                            </p>

                            <h3>To Object </h3>
                            <p>
                              You have the right to object to a certain type of
                              processing on legitimate grounds.
                            </p>

                            <h3>Rectification</h3>
                            <p>
                              If you find any of your personal information
                              inaccurate or incomplete you can simply ask us to
                              rectify it.
                            </p>

                            <h3>Complaints</h3>
                            <p>
                              If you believe that the protection and security of
                              your data have been compromised, you have the
                              right to record a complaint to the data protection
                              authority.
                            </p>

                            <h3>Withdraw and Clearance</h3>
                            <p>
                              When personal information is processed on the
                              ground of agreement, you have the right to
                              withdraw from the agreement.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>CONFIDENTIALLY </h4>
                          <div class="sub_details">
                            <p>
                              We acknowledge that the information you share may
                              be confidential, and we are obliged to maintain
                              that information a secret and to protect that
                              information in accordance with the privacy notice
                              and applicable law. We assure you that we do not
                              take any kind of advantage from your personal
                              information, we do not sell or distribute the
                              personal information to any third-party for our
                              interests.
                            </p>
                          </div>
                        </div>
                        <div class="termsandcondtions_detail">
                          <h4>INFORMATION SECURITY </h4>
                          <div class="sub_details">
                            <p>
                              Suitable management is implemented and technical
                              measures have been taken by our travel and holiday
                              company to protect your personal information from
                              any loss, harm, or misuse.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>CHANGES TO THIS PRIVACY POLICY </h4>
                          <div class="sub_details">
                            <p>
                              Our privacy policy may experience time to time
                              changes. Please keep in touch with our management
                              and keep on checking this policy every time you
                              wish to take our services. The changes are
                              effective as soon as they are written on this
                              page.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Acceptance of this policy</h4>
                          <div class="sub_details">
                            <p>
                              You acknowledge that you have read this Policy and
                              agree to all its terms and conditions. By using
                              the Mobile Application or its Services you agree
                              to be bound by this Policy. If you do not agree to
                              abide by the terms of this Policy, you are not
                              authorized to use or access the Mobile Application
                              and its Services.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>CONTACT DETAILS </h4>
                          <div class="sub_details">
                            <p>
                              If you have any questions related to the policy or
                              our way of processing your personal information,
                              please contact us.
                            </p>
                          </div>
                        </div>

                        {/* <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings2-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>Cookie Policy</h4>
                          <div class="sub_details">
                            <p>
                              This privacy policy ("Policy") describes how WOW
                              Electronic Transport Services ("WOW Electronic
                              Transport Services", "we", "us" or "our")
                              collects, protects and uses the personally
                              identifiable information ("Personal Information")
                              you ("User", "you" or "your") may provide in the
                              WOW Electronic Transport Services mobile
                              application and any of its products or services
                              (collectively, "Mobile Application" or
                              "Services"). It also describes the choices
                              available to you regarding our use of your
                              Personal Information and how you can access and
                              update this information. This Policy does not
                              apply to the practices of companies that we do not
                              own or control, or to individuals that we do not
                              employ or manage.
                            </p>
                          </div>
                        </div>
                        <div class="termsandcondtions_detail">
                          <h4>Automatic collection of information</h4>
                          <div class="sub_details">
                            <p>
                              When you open the Mobile Application our servers
                              automatically record information that your device
                              sends. This data may include information such as
                              your device's IP address and location, device name
                              and version, operating system type and version,
                              language preferences, information you search for
                              in our Mobile Application, access times and dates,
                              and other statistics.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Collection of personal information</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Managing personal information</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Use and processing of collected information</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Information transfer and storage</h4>
                          <div class="sub_details">
                            <p>
                              Depending on your location, data transfers may
                              involve transferring and storing your information
                              in a country other than your own. You are entitled
                              to learn about the legal basis of information
                              transfers to a country outside the European Union
                              or to any international organization governed by
                              public international law or set up by two or more
                              countries, such as the UN, and about the security
                              measures taken by us to safeguard your
                              information. If any such transfer takes place, you
                              can find out more by checking the relevant
                              sections of this document or inquire with us using
                              the information provided in the contact section.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <div className="right-of-user">
                            <h4>The rights of users</h4>

                            <a onClick={this.userright2} id="icon-user2">
                              <i className="fas fa-plus"></i>
                            </a>
                            <a
                              onClick={this.userrights2}
                              style={{ display: "none" }}
                              id="icon-users2"
                            >
                              <i className="fas fa-minus"></i>
                            </a>
                          </div>

                          <div class="sub_details">
                            <p>
                              You may exercise certain rights regarding your
                              information processed by us. In particular, you
                              have the right to do the following:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              id="user-right2"
                              style={{ display: "none" }}
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                you have the right to withdraw consent where you
                                have previously given your consent to the
                                processing of your information;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to object to the processing
                                of your information if the processing is carried
                                out on a legal basis other than consent;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                {" "}
                                you have the right to learn if information is
                                being processed by us, obtain disclosure
                                regarding certain aspects of the processing and
                                obtain a copy of the information undergoing
                                processing;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to verify the accuracy of
                                your information and ask for it to be updated or
                                corrected;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right, under certain circumstances,
                                to restrict the processing of your information,
                                in which case, we will not process your
                                information for any purpose other than storing
                                it;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right, under certain circumstances,
                                to obtain the erasure of your Personal
                                Information from us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to receive your information
                                in a structured, commonly used and machine
                                readable format and, if technically feasible, to
                                have it transmitted to another controller
                                without any hindrance. This provision is
                                applicable provided that your information is
                                processed by automated means and that the
                                processing is based on your consent, on a
                                contract which you are part of or on
                                pre-contractual obligations thereof.{" "}
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Acceptance of this policy</h4>
                          <div class="sub_details">
                            <p>
                              You acknowledge that you have read this Policy and
                              agree to all its terms and conditions. By using
                              the Mobile Application or its Services you agree
                              to be bound by this Policy. If you do not agree to
                              abide by the terms of this Policy, you are not
                              authorized to use or access the Mobile Application
                              and its Services.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="termsandcondtions_detail">
                      <h4>Heading</h4>
                      <div class="sub_details">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Nesciunt esse quia possimus officiis quasi
                          assumenda, voluptate molestias corrupti nostrum
                          deserunt enim, veritatis iste? Odio necessitatibus
                          aliquam blanditiis, exercitationem sit similique?Lorem
                          ipsum dolor sit amet consectetur adipisicing elit.
                          Nesciunt esse quia possimus officiis quasi assumenda,
                          voluptate molestias corrupti nostrum deserunt enim,
                          veritatis iste? Odio necessitatibus aliquam
                          blanditiis, exercitationem sit similique?Lorem ipsum
                          dolor sit amet consectetur adipisicing elit. Nesciunt
                          esse quia possimus officiis quasi assumenda, voluptate
                          molestias corrupti nostrum deserunt enim, veritatis
                          iste? Odio necessitatibus aliquam blanditiis,
                          exercitationem sit similique?Lorem ipsum dolor sit
                          amet consectetur adipisicing elit. Nesciunt esse quia
                          possimus officiis quasi assumenda, voluptate molestias
                          corrupti nostrum deserunt enim, veritatis iste? Odio
                          necessitatibus aliquam blanditiis. To learn, whether
                          we are processing Personal Information for direct
                          marketing purposes, you may refer to the relevant
                          sections of this document.
                        </p>
                      </div>
                    </div>
                    <div class="termsandcondtions_detail">
                      <h4>Heading</h4>
                      <div class="sub_details">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Nesciunt esse quia possimus officiis quasi
                          assumenda, voluptate molestias corrupti nostrum
                          deserunt enim, veritatis iste? Odio necessitatibus
                          aliquam blanditiis, exercitationem sit similique?Lorem
                          ipsum dolor sit amet consectetur adipisicing elit.
                          Nesciunt esse quia possimus officiis quasi assumenda,
                          voluptate molestias corrupti nostrum deserunt enim,
                          veritatis iste? Odio necessitatibus aliquam
                          blanditiis, exercitationem sit similique?Lorem ipsum
                          dolor sit amet consectetur adipisicing elit. Nesciunt
                          esse quia possimus officiis quasi assumenda, voluptate
                          molestias corrupti nostrum deserunt enim, veritatis
                          iste? Odio necessitatibus aliquam blanditiis,
                          exercitationem sit similique?Lorem ipsum dolor sit
                          amet consectetur adipisicing elit. Nesciunt esse quia
                          possimus officiis quasi assumenda, voluptate molestias
                          corrupti nostrum deserunt enim, veritatis iste? Odio
                          necessitatibus aliquam blanditiis. To learn, whether
                          we are processing Personal Information for direct
                          marketing purposes, you may refer to the relevant
                          sections of this document.
                        </p>
                      </div>
                    </div>

                    <div class="termsandcondtions_detail">
                      <h4>Heading</h4>
                      <div class="sub_details">
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Nesciunt esse quia possimus officiis quasi
                          assumenda, voluptate molestias corrupti nostrum
                          deserunt enim, veritatis iste? Odio necessitatibus
                          aliquam blanditiis, exercitationem sit similique?Lorem
                          ipsum dolor sit amet consectetur adipisicing elit.
                          Nesciunt esse quia possimus officiis quasi assumenda,
                          voluptate molestias corrupti nostrum deserunt enim,
                          veritatis iste? Odio necessitatibus aliquam
                          blanditiis, exercitationem sit similique?Lorem ipsum
                          dolor sit amet consectetur adipisicing elit. Nesciunt
                          esse quia possimus officiis quasi assumenda, voluptate
                          molestias corrupti nostrum deserunt enim, veritatis
                          iste? Odio necessitatibus aliquam blanditiis,
                          exercitationem sit similique?Lorem ipsum dolor sit
                          amet consectetur adipisicing elit. Nesciunt esse quia
                          possimus officiis quasi assumenda, voluptate molestias
                          corrupti nostrum deserunt enim, veritatis iste? Odio
                          necessitatibus aliquam blanditiis. To learn, whether
                          we are processing Personal Information for direct
                          marketing purposes, you may refer to the relevant
                          sections of this document.
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings3-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>Acceptance Use Policy </h4>
                          <div class="sub_details">
                            <p>
                              You acknowledge that you have read this Policy and
                              agree to all its terms and conditions. By using
                              the Mobile Application or its Services you agree
                              to be bound by this Policy. If you do not agree to
                              abide by the terms of this Policy, you are not
                              authorized to use or access the Mobile Application
                              and its Services.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Privacy Policy</h4>
                          <div class="sub_details">
                            <p>
                              This privacy policy ("Policy") describes how WOW
                              Electronic Transport Services ("WOW Electronic
                              Transport Services", "we", "us" or "our")
                              collects, protects and uses the personally
                              identifiable information ("Personal Information")
                              you ("User", "you" or "your") may provide in the
                              WOW Electronic Transport Services mobile
                              application and any of its products or services
                              (collectively, "Mobile Application" or
                              "Services"). It also describes the choices
                              available to you regarding our use of your
                              Personal Information and how you can access and
                              update this information. This Policy does not
                              apply to the practices of companies that we do not
                              own or control, or to individuals that we do not
                              employ or manage.
                            </p>
                          </div>
                        </div>
                        <div class="termsandcondtions_detail">
                          <h4>Automatic collection of information</h4>
                          <div class="sub_details">
                            <p>
                              When you open the Mobile Application our servers
                              automatically record information that your device
                              sends. This data may include information such as
                              your device's IP address and location, device name
                              and version, operating system type and version,
                              language preferences, information you search for
                              in our Mobile Application, access times and dates,
                              and other statistics.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Collection of personal information</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Managing personal information</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Use and processing of collected information</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Information transfer and storage</h4>
                          <div class="sub_details">
                            <p>
                              Depending on your location, data transfers may
                              involve transferring and storing your information
                              in a country other than your own. You are entitled
                              to learn about the legal basis of information
                              transfers to a country outside the European Union
                              or to any international organization governed by
                              public international law or set up by two or more
                              countries, such as the UN, and about the security
                              measures taken by us to safeguard your
                              information. If any such transfer takes place, you
                              can find out more by checking the relevant
                              sections of this document or inquire with us using
                              the information provided in the contact section.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <div className="right-of-user">
                            <h4>The rights of users</h4>

                            <a onClick={this.userright3} id="icon-user3">
                              <i className="fas fa-plus"></i>
                            </a>
                            <a
                              onClick={this.userrights3}
                              style={{ display: "none" }}
                              id="icon-users3"
                            >
                              <i className="fas fa-minus"></i>
                            </a>
                          </div>

                          <div class="sub_details">
                            <p>
                              You may exercise certain rights regarding your
                              information processed by us. In particular, you
                              have the right to do the following:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              id="user-right3"
                              style={{ display: "none" }}
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                you have the right to withdraw consent where you
                                have previously given your consent to the
                                processing of your information;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to object to the processing
                                of your information if the processing is carried
                                out on a legal basis other than consent;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                {" "}
                                you have the right to learn if information is
                                being processed by us, obtain disclosure
                                regarding certain aspects of the processing and
                                obtain a copy of the information undergoing
                                processing;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to verify the accuracy of
                                your information and ask for it to be updated or
                                corrected;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right, under certain circumstances,
                                to restrict the processing of your information,
                                in which case, we will not process your
                                information for any purpose other than storing
                                it;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right, under certain circumstances,
                                to obtain the erasure of your Personal
                                Information from us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                you have the right to receive your information
                                in a structured, commonly used and machine
                                readable format and, if technically feasible, to
                                have it transmitted to another controller
                                without any hindrance. This provision is
                                applicable provided that your information is
                                processed by automated means and that the
                                processing is based on your consent, on a
                                contract which you are part of or on
                                pre-contractual obligations thereof.{" "}
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings4-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4> headings 4</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Magnam, id saepe sit hic repellendus
                              voluptatem ea delectus nostrum ipsa veritatis
                              impedit adipisci quo culpa quos deserunt
                              laboriosam doloribus quisquam ullam.Lorem ipsum
                              dolor sit amet consectetur adipisicing elit.
                              Magnam, id saepe sit hic repellendus voluptatem ea
                              delectus nostrum ipsa veritatis impedit adipisci
                              quo culpa quos deserunt laboriosam doloribus
                              quisquam ullam.Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Magnam, id saepe sit
                              hic repellendus voluptatem ea delectus nostrum
                              ipsa veritatis impedit adipisci quo culpa quos
                              deserunt laboriosam doloribus quisquam ullam.Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Magnam, id saepe sit hic repellendus voluptatem ea
                              delectus nostrum ipsa veritatis impedit adipisci
                              quo culpa quos deserunt laboriosam doloribus
                              quisquam ullam.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings5-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>heading 5</h4>
                          <div class="sub_details">
                            <p>
                              You acknowledge that you have read this Policy and
                              agree to all its terms and conditions. By using
                              the Mobile Application or its Services you agree
                              to be bound by this Policy. If you do not agree to
                              abide by the terms of this Policy, you are not
                              authorized to use or access the Mobile Application
                              and its Services.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>
                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings6-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>Heading 6 </h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Magnam, id saepe sit hic repellendus
                              voluptatem ea delectus nostrum ipsa veritatis
                              impedit adipisci quo culpa quos deserunt
                              laboriosam doloribus quisquam ullam.Lorem ipsum
                              dolor sit amet consectetur adipisicing elit.
                              Magnam, id saepe sit hic repellendus voluptatem ea
                              delectus nostrum ipsa veritatis impedit adipisci
                              quo culpa quos deserunt laboriosam doloribus
                              quisquam ullam.Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Magnam, id saepe sit
                              hic repellendus voluptatem ea delectus nostrum
                              ipsa veritatis impedit adipisci quo culpa quos
                              deserunt laboriosam doloribus quisquam ullam.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings7-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>Heading 7 </h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Magnam, id saepe sit hic repellendus
                              voluptatem ea delectus nostrum ipsa veritatis
                              impedit adipisci quo culpa quos deserunt
                              laboriosam doloribus quisquam ullam.Lorem ipsum
                              dolor sit amet consectetur adipisicing elit.
                              Magnam, id saepe sit hic repellendus voluptatem ea
                              delectus nostrum ipsa veritatis impedit adipisci
                              quo culpa quos deserunt laboriosam doloribus
                              quisquam ullam.Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Magnam, id saepe sit
                              hic repellendus voluptatem ea delectus nostrum
                              ipsa veritatis impedit adipisci quo culpa quos
                              deserunt laboriosam doloribus quisquam ullam.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You can visit the Mobile Application without
                              telling us who you are or revealing any
                              information by which someone could identify you as
                              a specific, identifiable individual. If, however,
                              you wish to use some of the Mobile Application's
                              features, you will be asked to provide certain
                              Personal Information (for example, your name and
                              e-mail address). We receive and store any
                              information you knowingly provide to us when you
                              create an account, make a purchase, or fill any
                              online forms in the Mobile Application. When
                              required, this information may include your email
                              address, name, phone number, address, credit card
                              information, bank information, or other Personal
                              Information. You can choose not to provide us with
                              your Personal Information, but then you may not be
                              able to take advantage of some of the Mobile
                              Application's features. Users who are uncertain
                              about what information is mandatory are welcome to
                              contact us.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col
                    xl={9}
                    lg={9}
                    md={8}
                    sm={8}
                    id="headings8-privacypolicy"
                    style={{ display: "none" }}
                  >
                    <div
                      class="MuiTypography-root termsTabs MuiTypography-body1"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root jss69">
                        <div class="termsandcondtions_detail">
                          <h4>Disclaimer </h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Magnam, id saepe sit hic repellendus
                              voluptatem ea delectus nostrum ipsa veritatis
                              impedit adipisci quo culpa quos deserunt
                              laboriosam doloribus quisquam ullam.Lorem ipsum
                              dolor sit amet consectetur adipisicing elit.
                              Magnam, id saepe sit hic repellendus voluptatem ea
                              delectus nostrum ipsa veritatis impedit adipisci
                              quo culpa quos deserunt laboriosam doloribus
                              quisquam ullam.Lorem ipsum dolor sit amet
                              consectetur adipisicing elit. Magnam, id saepe sit
                              hic repellendus voluptatem ea delectus nostrum
                              ipsa veritatis impedit adipisci quo culpa quos
                              deserunt laboriosam doloribus quisquam ullam.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Contacting us</h4>
                          <div class="sub_details">
                            <p>
                              If you would like to contact us to understand more
                              about this Policy or wish to contact us concerning
                              any matter relating to individual rights and your
                              Personal Information, you may send an email to{" "}
                              <span style={{ Color: "rgb(16, 54, 252)" }}>
                                info@wowets.com
                              </span>
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              You are able to access, add to, update and delete
                              certain Personal Information about you. The
                              information you can view, update, and delete may
                              change as the Mobile Application or Services
                              change. When you update information, however, we
                              may maintain a copy of the unrevised information
                              in our records. Some information may remain in our
                              private records after your deletion of such
                              information from your account. We will retain and
                              use your Personal Information for the period
                              necessary to comply with our legal obligations,
                              resolve disputes, and enforce our agreements
                              unless a longer retention period is required or
                              permitted by law. We may use any aggregated data
                              derived from or incorporating your Personal
                              Information after you update or delete it, but not
                              in a manner that would identify you personally.
                              Once the retention period expires, Personal
                              Information shall be deleted. Therefore, the right
                              to access, the right to erasure, the right to
                              rectification and the right to data portability
                              cannot be enforced after the expiration of the
                              retention period.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Context Heading</h4>
                          <div class="sub_details">
                            <p>
                              Any of the information we collect from you may be
                              used to personalize your experience; improve our
                              Mobile Application; improve customer service and
                              respond to queries and emails of our customers;
                              process transactions; send newsletters; send
                              notification emails such as password reminders,
                              updates, etc; run and operate our Mobile
                              Application and Services. Information collected
                              automatically is used only to identify potential
                              cases of abuse and establish statistical
                              information regarding Mobile Application traffic
                              and usage. This statistical information is not
                              otherwise aggregated in such a way that would
                              identify any particular user of the system.
                            </p>
                            <p>
                              We may process Personal Information related to you
                              if one of the following applies:
                            </p>
                            <ol
                              className="ordered-list-in-privacy-policy"
                              type="i"
                            >
                              <li class="terms-and-conditions-tab-name">
                                You have given your consent for one or more
                                specific purposes. Note that under some
                                legislations we may be allowed to process
                                information until you object to such processing
                                (by opting out), without having to rely on
                                consent or any other of the following legal
                                bases below. This, however, does not apply,
                                whenever the processing of Personal Information
                                is subject to European data protection law;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Provision of information is necessary for the
                                performance of an agreement with you and/or for
                                any pre-contractual obligations thereof;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for compliance with a
                                legal obligation to which you are subject;
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is related to a task that is carried
                                out in the public interest or in the exercise of
                                official authority vested in us;{" "}
                              </li>
                              <li class="terms-and-conditions-tab-name">
                                Processing is necessary for the purposes of the
                                legitimate interests pursued by us or by a third
                                party. In any case, we will be happy to clarify
                                the specific legal basis that applies to the
                                processing, and in particular whether the
                                provision of Personal Information is a statutory
                                or contractual requirement, or a requirement
                                necessary to enter into a contract.
                              </li>
                            </ol>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>

                        <div class="termsandcondtions_detail">
                          <h4>Heading</h4>
                          <div class="sub_details">
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis, exercitationem sit
                              similique?Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Nesciunt esse quia possimus
                              officiis quasi assumenda, voluptate molestias
                              corrupti nostrum deserunt enim, veritatis iste?
                              Odio necessitatibus aliquam blanditiis,
                              exercitationem sit similique?Lorem ipsum dolor sit
                              amet consectetur adipisicing elit. Nesciunt esse
                              quia possimus officiis quasi assumenda, voluptate
                              molestias corrupti nostrum deserunt enim,
                              veritatis iste? Odio necessitatibus aliquam
                              blanditiis, exercitationem sit similique?Lorem
                              ipsum dolor sit amet consectetur adipisicing elit.
                              Nesciunt esse quia possimus officiis quasi
                              assumenda, voluptate molestias corrupti nostrum
                              deserunt enim, veritatis iste? Odio necessitatibus
                              aliquam blanditiis. To learn, whether we are
                              processing Personal Information for direct
                              marketing purposes, you may refer to the relevant
                              sections of this document.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>

        {/* <Container>


<div id="mySidenav" class="sidenav">
<span
className="open-nav-help"
 style={{fontSize: '30px', cursor:'pointer'}}
  onClick={this.openNav}>&#9776; open</span>

<a href="javascript:void(0)" 
class="closebtn"
id="close-button"
onClick={this.closeNav}
style={{display:'none'}}  >&times;</a>





<a href="#">About</a>
<a href="#">Services</a>
<a href="#">Clients</a>
<a href="#">Contact</a>
</div>

<h2>Animated</h2>
<p>Click on.</p>



</Container> */}
      </div>
    );
  }
}
export default Privacypolicy;
