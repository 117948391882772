import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";
import { Visible, Hidden, setConfiguration } from "react-grid-system";
import { connect } from "react-redux";
import Slider from "react-animated-slider";
import StarRatings from "react-star-ratings";
import '../Wowholidayhowitworks/howitworks.scss';
import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  Media,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  NavLink,
  Route,
  browserhistory,
} from "react-router-dom";

var img_2 = require("../IMAGES/city-images/how-it-works/Signupmockup2.png");
var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide5.png");
var default_step = 1;
class Holidayhowworks extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      img: img_2,
      img1: img_1,
      active_step: default_step,
      active_step1: default_step,
    };
    this.set_image = this.set_image.bind(this);
    this.set_image1 = this.set_image1.bind(this);
  }

  set_image = (img) => {
    if (img === "1") {
      var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide1.png");
      this.setState({ img: img_1, active_step: img });
    }
    if (img === "2") {
      var img_1 = require("../IMAGES/city-images/how-it-works/Signupmockup2.png");
      this.setState({ img: img_1, active_step: img });
    }

    if (img === "3") {
      var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide5.png");
      this.setState({ img: img_1, active_step: img });
    }

    if (img === "4") {
      var img_1 = require("../IMAGES/city-images/how-it-works/Signupmockup.png");
      this.setState({ img: img_1, active_step: img });
    }

    if (img === "5") {
      var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide5.png");
      this.setState({ img: img_1, active_step: img });
    }
  };

  set_image1 = (img) => {
    if (img === "1") {
      var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide5.png");
      this.setState({ img1: img_1, active_step1: img});
    }
    if (img === "2") {
      var img_1 = require("../IMAGES/city-images/how-it-works/Signupmockup2.png");
      this.setState({ img1: img_1, active_step1: img });
    }

    if (img === "3") {
      var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide5.png");
      this.setState({ img1: img_1, active_step1: img });
    }

    if (img === "4") {
      var img_1 = require("../IMAGES/city-images/how-it-works/howBookRide5.png");
      this.setState({ img1: img_1, active_step1: img });
    }

    if (img === "5") {
      var img_1 = require("../IMAGES/city-images/how-it-works/Signupmockup.png");
      this.setState({ img1: img_1, active_step1: img });
    }
  };

  alertshow = () => {
    alert("asdasdad");
  };

  render() {
    const scrollToTophowworks = () => window.scrollTo({ top: 0 });
    return (
      <div>
        {/* <div className="holiday-how-works-wow-holiday-our-container">
          <div class="holiday-blog-post-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-blog-post-wow-holiday-home-our-story">
                <div class="holiday-blog-post-wow-holiday-our-team-heading">
                  Wow Holiday<br/>
                  Simplifying Lives.
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="how-to-work-top-content">
          <div className="how-to-work-container-top">
            <div className="how-to-work-top-content-area">
              <Row className="how-to-work-grid">
                <Col xl={8} md={8} sm={12} xs={12} lg={8}>
                  <div className="how-text-top12">
                    <h2
                      style={{
                        color: "#f87370",
                        paddingLeft: "3%",
                        textAlign: "left",
                      }}
                      className="how-it-work-banner-top-heading"
                    >
                      SIMPLIFYING LIVES{" "}
                    </h2>
                    <h3
                      className="text-in-h3-how-works"
                      style={{
                        color: "#565656",
                        paddingLeft: "3%",
                        textAlign: "left",
                        fontSize: "20px",
                      }}
                    >
                      WITH LATEST TECHNOLOGICAL TRENDS
                    </h3>
                    <h5
                      style={{
                        color: "#565656",
                        paddingLeft: "3%",
                        fontSize: "17px",
                      }}
                      className="how-it-work-banner-top-co"
                    >
                      WOW puts your transportation in your hand, your car is
                      just a click away from you.
                    </h5>
                  </div>
                </Col>
                <Col
                  xl={4}
                  md={4}
                  lg={4}
                  className="simplify-how-works-text-col-tab"
                >
                  <Hidden sm xs></Hidden>
                </Col>
              </Row>
              <Row className="how-to-work-grid how-to-work-grid-mobile">
                <Col xl={7} md={12} sm={12} xs={12} lg={7}>
                  <Row>
                    <Col xl={4} md={2} sm={4} xs={3} lg={4}>
                      <div className="how-to-work-col-items19">
                        <img
                          className="pc-view-icons-how-works"
                          src={require("../IMAGES/city-images/how-it-works/inside-bg/register-1.png")}
                        />

                        <img
                          className="mobile-view-icons-how-works"
                          src={require("../IMAGES/city-images/how-it-works/inside-bg/register90.png")}
                        />
                      </div>
                    </Col>
                    <Col xl={4} md={2} sm={4} xs={3} lg={4}>
                      <center>
                        <div className="how-to-work-col-items19">
                          <img
                            className="pc-view-icons-how-works"
                            src={require("../IMAGES/city-images/how-it-works/inside-bg/signin-1.png")}
                          />

                          <img
                            className="mobile-view-icons-how-works"
                            src={require("../IMAGES/city-images/how-it-works/inside-bg/signin90.png")}
                          />
                        </div>
                      </center>
                    </Col>
                    <Col xl={4} md={2} sm={4} xs={3} lg={4}>
                      <center>
                        <div className="how-to-work-col-items19">
                          <img
                            className="pc-view-icons-how-works"
                            src={require("../IMAGES/city-images/how-it-works/inside-bg/bookholiday.png")}
                          />

                          <img
                            className="mobile-view-icons-how-works"
                            src={require("../IMAGES/city-images/how-it-works/inside-bg/bookholiday90.png")}
                          />
                        </div>
                      </center>
                    </Col>
                  </Row>
                </Col>
                <Col xl={5} lg={5}>
                  <Hidden sm xs md></Hidden>
                </Col>
              </Row>

              {/* <p className="how-font-size">SIMPLIFYING LIVES. ONE FEATURE AT A TIME.</p>
                 <p className="how-font-size1"><center>See how our product puts the power in your hands.</center></p> */}
            </div>
          </div>
        </div>

        <div class="blog_post1">
          <Container>
            <center className="main-heading-wow-howitworks">
              <h3 class="how_works">How It Works</h3>
            </center>
          </Container>

          <Row className="grid-trip-success-images-how-works grid-trip-success-mobile">
            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="grid-trip-success-images-how-works"
            >
              <div className="how-works-grid-padding">
                <div className="how-works-grid-heading">
                  How to Travel Europe and Guarantee Your Trip's Success
                </div>

                <div className="how-works-grid-text">
                  Knowing how to travel Europe is as important to the success of
                  your trip as deciding where on the Continent to go.
                  <br />
                  Every traveler, whether seasoned and savvy, or newbie making a
                  first trip abroad, finds her own level of comfort when
                  traveling in Europe or anywhere overseas. It’s called your
                  personal travel style.
                  <br />
                  Knowing what that style is: how you dress, how you work and
                  play, even how you live your life, allows you to tailor your
                  trip to your own personality to find the best way to travel
                  Europe.
                </div>
              </div>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="grid-trip-success-images-how-works"
            >
              <div>
                <figure className="story__shape-how-it-works">
                  <img
                    className="how-works-grid-image"
                    src="IMAGES/city-images/how-it-works/top-grid/employes.jpg"
                  ></img>

                  <figcaption className="story__image-caption-how-it-works">
                    <span>Trip Success</span>
                  </figcaption>
                </figure>
              </div>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="grid-trip-success-images-how-works"
            >
              <img
                className="how-works-grid-image"
                src="IMAGES/city-images/how-it-works/top-grid/employes2.jpg"
              ></img>
            </Col>

            <Col
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className="grid-trip-success-images-how-works"
            >
              <div className="how-works-grid-padding">
                <div className="how-works-grid-heading">
                  How to Travel Europe and Leave The Planning To Our Guides
                </div>

                <div className="how-works-grid-text">
                  Gone are the days when your European itinerary has you fly
                  into a gateway city, pile onto a bus with 30 or 40 of your
                  newest friends, and set off across the Continent.
                  <br />
                  If you’re of a certain age, you might remember the phrase [and
                  the movie of the same name]:
                  <b>If it’s Tuesday, this must be Belgium.</b> <br />
                  This is not how to travel Europe today! Medieval Belgian
                  Buildings Current day travelers have untold options for Europe
                  vacation packages - anything from tightly scheduled,
                  multi-country guided tours, to flexible free-wheeling 'travel
                  on your own' excursions, where the only 'package' may be a
                  hotel room and rental car.
                </div>
              </div>
            </Col>
          </Row>

          <Container>
            <Row>
              <div class="l-grid l-grid--4">
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i className="fas fa-tasks user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-howitworks">
                        <b>Book a holiday that suits you</b>
                        <br />
                      </div>
                      <div className="subTitle-text-wow-howitworks">
                        Experience Nature your way by choosing from over 70
                        optional activities to add to your tour.Select from a
                        wide range of options and get a holiday trip schedule
                        one for later.
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i className="fa fa-map-marker user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-howitworks">
                        <b>Track your Holiday</b>
                        <br />
                      </div>
                      <div class="subTitle-text-wow-howitworks">
                        From the moment your Guide Manager is assigned till you
                        get to your destination, track your Holiday ride in real
                        time or share your details with your loved ones.
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i className="fas fa-credit-card user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-howitworks">
                        <b>Pay the way you like</b>
                        <br />
                      </div>{" "}
                      <div class="subTitle-text-wow-howitworks">
                        Cash, card, or WOW credits/Rewards, we’ve got you
                        covered.
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </div>

        <div className="wow-key-features-area">
          <div className="wow-lets-you-ride">
            <div className="howBookARidebackgradiant">
              <Container>
                <h2
                  style={{ textAlign: "left" }}
                  className="our-story-main-araea-headings12-how-work"
                >
                  HOW TO BOOK A Holiday Flight?
                </h2>
                <Row className="lets-you-ride-gride">
                  <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="let-get-ride-left-area">
                      <ul className="list-unstyled-how-works list-ride-area">
                        <Media
                          as="li"
                          className="media-ride-list-adjust1 media-how-works-bookholiday"
                          onClick={this.set_image.bind(this, "1")}
                        >
                          <div className="list-steps-of-ride">
                            {this.state.active_step == 1 ? (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/1active.png")}
                                />
                              </div>
                            ) : (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/1inactive.png")}
                                />
                              </div>
                            )}
                         </div>
                          <Media.Body className="ride-body-content">
                            <h5>Select Your Airport </h5>
                            <p className="ride-list-para-text">
                              Turn on your location or pin-point by searching on
                              the map. Set your destination on the map where you
                              want to go
                            </p>
                          </Media.Body>
                        </Media>

                        <Media
                          as="li"
                          className="media-ride-list-adjust"
                          onClick={this.set_image.bind(this, "2")}
                        >
                          <div className="list-steps-of-ride">
                            {this.state.active_step == 2 ? (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/1inactive.png")}
                                />
                              </div>
                            ) : (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/2inactive.png")}
                                />
                              </div>
                            )}
                          </div>
                          <Media.Body className="ride-body-content">
                            <h5>Choose Your Flight Dates</h5>
                            <p className="ride-list-para-text">
                              You are free to choose any dates from to return
                              from the available ones.
                            </p>
                          </Media.Body>
                        </Media>
                        <Media
                          as="li"
                          className="media-ride-list-adjust"
                          onClick={this.set_image.bind(this, "3")}
                        >
                          <div className="list-steps-of-ride">
                            {this.state.active_step == 3 ? (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/1inactive.png")}
                                />
                              </div>
                            ) : (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/3inactive.png")}
                                />
                              </div>
                            )}
                          </div>
                          <Media.Body className="ride-body-content">
                            <h5>Select number of Passengers</h5>
                            <p className="ride-list-para-text">
                              Set your destination on the map where you want to
                              go.
                            </p>
                          </Media.Body>
                        </Media>
                        <Media
                          as="li"
                          className="media-ride-list-adjust"
                          onClick={this.set_image.bind(this, "4")}
                        >
                          <div className="list-steps-of-ride">
                            {this.state.active_step == 4 ? (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/1inactive.png")}
                                />
                              </div>
                            ) : (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/4inactive.png")}
                                />
                              </div>
                            )}
                          </div>
                          <Media.Body className="ride-body-content">
                            <h5>Choose Payment Method</h5>
                            <p className="ride-list-para-text">
                              Cash, card, or WOW credits, we’ve got you covered.
                            </p>
                          </Media.Body>
                        </Media>
                        <Media
                          as="li"
                          className="media-ride-list-adjust"
                          onClick={this.set_image.bind(this, "5")}
                        >
                          <div className="list-steps-of-ride">
                            {this.state.active_step == 5 ? (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/1inactive.png")}
                                />
                              </div>
                            ) : (
                              <div className="how-it-works-step-active">
                                <img
                                  src={require("../IMAGES/city-images/how-it-works/device-form/4inactive.png")}
                                />
                              </div>
                            )}
                          </div>
                          <Media.Body className="ride-body-content">
                            <h5>Confirm Your Flight</h5>
                            <p className="ride-list-para-text">
                              After Completing above steps you can confirm your
                              pickup flight.
                            </p>
                          </Media.Body>
                        </Media>
                      </ul>
                    </div>
                  </Col>

                  <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                    <div className="let-get-ride-right-area">
                      <div className="let-get-ride-right-content">
                        <center>
                          {" "}
                          <img src={this.state.img} className="image-mockupsize" />{" "}
                        </center>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {/* <div className="howBookASHRidebackgradiant">
             <Container> 
             <h2 style={{textAlign:'left'}}  className="our-story-main-araea-headings12-how-work" >SCHEDULE A RIDE</h2>
             <Row className="lets-you-ride-gride">
             <Col xs={12} sm={12} md={12} lg={6} xl={6}>
               <div className="let-get-ride-right-area">
                  <div className="schedule-ride-how-right-content">
                 <center> <img src={this.state.img1} /> </center>
                  </div>
                   </div>
               </Col>
               <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                 <div className="let-get-ride-left-area">
                 <ul className="list-unstyled list-ride-area">
                 <Media as="li" className="media-ride-list-adjust1"  onClick={this.set_image1.bind(this,'1')}>
                 <div className="list-steps-of-ride">
                   
                   { (this.state.active_step1==1) ?    <div className="how-it-works-step-active">
                   <img src={require('../IMAGES/city-images/how-it-works/device-form/1active.png')} />
                   </div>
                  :   <div className="how-it-works-step-active">
                  <img src={require('../images/1inactive.png')} />
                  </div> }

                   </div>
                   <Media.Body className="ride-body-content">
                     <h5>MENTION DATE AND TIME</h5>
                     <p className="ride-list-para-text">
                    Please enter your desire date and appropiate time for ride.
                     </p>
                   </Media.Body>
                 </Media>

                 <Media as="li" className="media-ride-list-adjust" onClick={this.set_image1.bind(this,'2')}>
                 <div className="list-steps-of-ride">
                   
                   { (this.state.active_step1==2) ?    <div className="how-it-works-step-active">
                   <img src={require('../images/2active.png')} />
                   </div>
                  :   <div className="how-it-works-step-active">
                  <img src={require('../images/2inactive.png')} />
                  </div> }

                   </div>
                   <Media.Body className="ride-body-content">
                   <h5>Set Your Drop-off Location</h5>
                     <p className="ride-list-para-text">
                     Set your destination on the map where you want to go.
                     </p>
                   </Media.Body>
                 </Media>
                 <Media as="li" className="media-ride-list-adjust" onClick={this.set_image1.bind(this,'3')}>
                 <div className="list-steps-of-ride">
                   
                   { (this.state.active_step1==3) ?    <div className="how-it-works-step-active">
                   <img src={require('../images/3active.png')} />
                   </div>
                  :   <div className="how-it-works-step-active">
                  <img src={require('../images/3inactive.png')} />
                  </div> }

                   </div>
                   <Media.Body className="ride-body-content">
                     
                     <h5>Choose Your Car</h5>
                     <p className="ride-list-para-text">
                     You are free to choose any car/ vehicle type from the available ones.
                     </p>
                   </Media.Body>
                 </Media>
                 <Media as="li" className="media-ride-list-adjust" onClick={this.set_image1.bind(this,'4')}>
                 <div className="list-steps-of-ride">
                   
                   { (this.state.active_step1==4) ?    <div className="how-it-works-step-active">
                   <img src={require('../images/4active.png')} />
                   </div>
                  :   <div className="how-it-works-step-active">
                  <img src={require('../images/4inactive.png')} />
                  </div> }

                   </div>
                   <Media.Body className="ride-body-content">
                     <h5>Schedule Your Ride</h5>
                     <p className="ride-list-para-text">
                   Get ready to save and re-schedule your ride in advance during intense hours.
                     </p>
                   </Media.Body>
                 </Media>
               </ul>
                 </div>
               </Col>

             </Row>
              </Container>  
              </div>   */}
          </div>
        </div>

        <Container>
          <Row className="margin-in-rows-cards-how-works-holiday">
            <Col xs={4} lg={4} md={4} sm={6} xs={12}>
              <div className="card-how-works-mobile">
                <div className="card-for-practice-how-works-holiday">
                  <a>
                    <img
                      className="card-img-top-for-practice-how-works-holiday"
                      src="IMAGES/city-images/wow-howitworks/blog-card/card1.png"
                      alt="Card image cap"
                    />

                    <div>
                      <li>
                        <p className="card-text-for-practice-how-works-holiday">
                          WOW Rewards
                        </p>
                      </li>

                      <div className="text-inside-how-works-wow-holiday-card">
                        Earn points for every ride you take and redeem them for
                        amazing rewards or use them to donate to a charity of
                        your choice
                      </div>

                      <center>
                        <Link
                          to="/holidaycredits"
                          onClick={scrollToTophowworks}
                          className="button-text-wow-how-works-card-holiday"
                        >
                          Read more
                        </Link>
                      </center>
                    </div>
                  </a>
                </div>
              </div>
            </Col>

            <Col xs={4} lg={4} md={4} sm={6} xs={12}>
              <div className="card-how-works-mobile">
                <div className="card-for-practice-how-works-holiday">
                  <a>
                    <img
                      className="card-img-top-for-practice-how-works-holiday"
                      src="IMAGES/city-images/how-it-works/card2.png"
                      alt="Card image cap"
                    />

                    <div>
                      <li>
                        <p className="card-text-for-practice-how-works-holiday">
                          WOW Trust & Safety
                        </p>
                      </li>

                      <div className="text-inside-how-works-wow-holiday-card">
                        Our aim is to make Wow the safest form of vehicular
                        transport. We leverage the latest technology, 24/7
                        on-call support, and strict onboarding processes.
                      </div>

                      <center>
                        <Link
                          to="/trustsafety"
                          onClick={scrollToTophowworks}
                          className="button-text-wow-how-works-card-holiday"
                        >
                          Read more
                        </Link>
                      </center>
                    </div>
                  </a>
                </div>
              </div>
            </Col>

            <Col xs={4} lg={4} md={4} sm={6} xs={12}>
              <div>
                <div className="card-for-practice-how-works-holiday">
                  <a>
                    <img
                      className="card-img-top-for-practice-how-works-holiday"
                      src="IMAGES/city-images/how-it-works/card3.jpg"
                      alt="Card image cap"
                    />

                    <div>
                      <li>
                        <p className="card-text-for-practice-how-works-holiday">
                          WOW Travel
                        </p>
                      </li>

                      <div className="text-inside-how-works-wow-holiday-card">
                        Are you one of those conscious personalities who want to
                        travel and need every little detail touches the maximum
                        height of perfection?
                      </div>

                      <center>
                        <Link
                          to="/travelworks"
                          onClick={scrollToTophowworks}
                          className="button-text-wow-how-works-card-holiday"
                        >
                          Read more
                        </Link>
                      </center>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Holidayhowworks;
