import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { 
  Form,
  Container,
  Card, Row,
  Slider,
  Col,
  Button
 } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Newscard4 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      date: new Date().toLocaleString(),
      toggle: true,
     };
  }


  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };


  render() {
    return (
      <div>
        
        {/* <Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-travel-work">
        <div  className="heading-secondary-section-wow-travel-work ">
        Coronavirus Tips If You Still Have to Travel Domestically

        <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2-newscard2"
            src="IMAGES/city-images/newsroom/card3-2.jpg"/>
        </div>
        </div>


</Container> */}

        

<Container>
        <div className="td-post-header-holder-card4 td-image-gradient-newscard4">
        <div className="td-post-featured-image-newscard4">
           <img width="1068" height="580" 
            className="entry-thumb td-animation-stack-type0-2-newscard4"
            src="IMAGES/city-images/newsroom/card4.jpg"/>

        </div>
            <header className="td-post-title-newscard4">
            <ul className="td-category-newscard4">
                    <li className="entry-category-newscard4">
                        <a 
                        target="blank"
                        href="https://www.kasandz.com/travel-keepsakes-8-ideas-for-a-perfect-travel-souvenir/">
                        Keepsakes
                        </a>
                        </li>
                </ul>
                             
<h1 className="entry-title-newscard4">WOW: 8 Creative, Crafty Ways to Display Travel Keepsakes</h1>

                

                <div className="td-module-meta-info-newscard4">
                 <span className="td-post-date-newscard4">
                     <time className="entry-date-newscard4 updated td-module-date-newscard4"
                      datetime="2018-11-07T10:59:17+00:00">Apr 20, 2020</time>
                </span>      

                <div className="td-post-views-newscard4">
                    <i className="fa fa-eye view-icon-views-newscard4"></i>
                    <span className="td-nr-views-10968">32736</span>
                </div>                    
                              
                     </div>

            </header>
        </div>
 
</Container>





<Container>


<div className="u-center-text u-margin-bottom-second-heading-wow-newscard4">
        <div  className="heading-secondary-section-wow-newscard4 ">
        Ways to Feel Like You’re on Vacation at Home
        </div>
        </div>        

</Container>





<Container>
<div id="accordion-5ed01b058660a" className="accordion indicator-plus accordion-white mb-3" role="tablist">
<div className="card-newsroom4 bar">
  <div className="card-newsroom4 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-0" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom4-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-0">
        <span role="heading" aria-level="0">
        Turn Your House into a Cozy Cabin
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-0" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-0" 
    role="tabpanel">
      <div className="card-body-newscard4">
        <p>Rather pretend you’re away at a cozy cabin this winter? It’s easy to feel like you’re on a winter
           vacation at home with a few hygge-inspired items. This heated blanket will instantly turn your couch
            into a warm cocoon that you don’t want to leave. Make your bed into a holiday-like nest by 
            upgrading your linens to Brooklyn Bedding’s Tencel Sateen Sheets, which are made from a
             super-soft TENCEL Lyocell fiber blend. These sheets absorb moisture quickly, so you’ll stay
              comfortable no matter how many blankets you pile on top. Top it off with a Pluto Pillow, 
              a revolutionary pillow that is personalized to your preferences and assembled by hand to 
              give you the most comfortable spot to rest your head.<br/>

Upgrade your morning routine to feel more like a vacation with a programmable coffee maker. It can’t 
bring you coffee in bed, but it can wake you up with a freshly brewed cup.
       
       </p>
      </div>
  </div> 
  </div>
  </div>





  <div className="card-newsroom4 bar">
  <div className="card-newsroom4 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-1" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom4-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-1">
        <span role="heading" aria-level="0">Should I avoid travellig internationally?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-1" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-1" 
    role="tabpanel">
      <div className="card-body-newscard4">
        <p>Yes. CDC recommends that you avoid all nonessential international travel because of the COVID-19
           pandemic. Some healthcare systems are overwhelmed and there may be limited access to adequate 
           medical care in affected areas. Many countries are implementing travel restrictions and mandatory
            quarantines, closing borders, and prohibiting non-citizens from entry with little advance notice.
             Airlines have cancelled many international flights and in-country travel may be unpredictable.
              If you choose to travel internationally, your travel plans may be disrupted, and you may have 
              to remain outside the United States for an indefinite length of time.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom4 bar">
  <div className="card-newsroom4 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-2" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom4-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-2">
        <span role="heading" aria-level="0">Completely Disconnect
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-2" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-2" 
    role="tabpanel">
      <div className="card-body-newscard4">
        <p>If your favorite part about vacation is being unreachable, recreate it at home. Turn off your 
          Wi-Fi, put your phone on airplane mode, and enjoy being off the internet. Put down your phone 
          and pick up a book. The new Kindle Oasis is designed to be feel just like reading a real book, 
          thanks to an adjustable warm light setting, page turn buttons, and e-ink technology.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom4 bar">
  <div className="card-newsroom4 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-3" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom4-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-3">
        <span role="heading" aria-level="0">Blackout Curtains
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-3" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-3" 
    role="tabpanel">
      <div className="card-body-newscard4">
        <p>The best hotels have blackout curtains that leave your room pitch black, no matter the time of day.
           Splurge on remote-controlled blackout roller shades that can open and close without you needing to get
            out of bed. Or, for the more frugal and less lazy among us, a good pair of blackout curtains will not
           only block light but also insulate the room in winter and keep the heat of the sun out in summer.
       
       </p>
      </div>
  </div> 
  </div>
  </div>






  </div>

  
  </Container>








<div className="gradient-back-travel-newscard4">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard4-grid-text">
                  <div className="travel-newscard4-page-araea-sub-headings110">
                  Shadow Box Display
                  </div>

                  <div className="travel-newscard4-page-page-text">
                  Originally used to display military artifacts, shadow boxes are a great way to display
                   small travel mementos collected from a summer vacation, a study abroad trip, or a volunteer
                    holiday. You can fill your shadow box with shells or vials of sand collected from 
                    various beaches, Polaroid pictures with friends you met during your travels, and 
                    other meaningful keepsakes.

 
<br/>
Regularly use your sanitizer when you aren't wearing gloves and avoid passing
 the sanitizer bottle to another person.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/shadow-box.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-newscard4">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/travel-book.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard4-grid-text">
                  <div className="travel-newscard4-page-araea-sub-headings110">
                  Themed Travel Photo Book
                  </div>

                  <div className="travel-newscard4-page-page-text">
                  Photo books are a great way to immortalize your travel snaps, but why not take it one step
                   further by making a themed book? If trying all kinds of exotic food is one of the activities
                    you enjoy most during your travels, a culinary-themed photo book filled with 
                    stunning photos of your gastronomical experiences would make a unique keepsake.

                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>















     







<Container>

<div class="u-center-text u-margin-bottom-second-heading-expert-wow-newscard4">
        <div  className="heading-secondary-expert-section-wow-newscard4 ">
        Travel Keepsakes: 8 Ideas for a Perfect Travel Souvenir
        </div>
        </div>   






<div class="description_of_card_of_newsroom-card4">
  <div class="description_of_card_of_newsroom_area-card4">

  <h2 class="blogs-description-area-of-main-headings-card4">
  1. Three-Ring Binder
  </h2>
  <p class="blogs-description-area-of-main--text-card4">
  There are plenty of decorative binders out there that don’t look like you stole them from the office 
  supply closet. Find one you love, or create a personalized cover for one using travel photos. Then, take
   all of your paper mementos—postcards, brochures, restaurant menus, etc.—and punch holes in them. 
   Soon you’ll have a library full of binders from all your trips. 
   </p>

   
<div class="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img1.jpg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings-card4">2. Sand Jars</h2>
   <p class="blogs-description-area-of-main--text-card4">
   Want to remember that beautiful white-sand or black-sand beach forever? Scoop up a little bit to take
    home with you (and maybe some shells, too) if that’s permitted at your beach. Use your souvenir sand
     to partially fill up a glass mason jar. Insert a photo in the jar and maybe some shells or other
      memorabilia. See how to do it (and how to protect the photo in the jar)
      </p>
    <p class="blogs-description-area-of-main--text-card4">
    The way to keep sand in place is to contain it in a bowl or some other containing structure.
     I would use clear resin  which will dry clear as glass and hard. Top with a layer of melted wax.
      That's a smart idea
    </p>

      <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img2.jpg" style={{width: '100%'}}/>

  </div>


      
<h2 className="blogs-description-area-of-main-headings-card4">3. Make a Ticket Collage</h2>
  <p className="blogs-description-area-of-main--text-card4">
  Put your tickets on display by making a collage. You can use ones from events, flights, and more.
   Best of all, it’s easy and cheap to do. All you need is a frame and a map. Use the map as a solid
    background, and then overlay your tickets on top.
    If you're looking for a new, interesting way to display your photos, here's a fun and 
    simple project you should try!
    Postcards and travel photos have a huge visual impact when displayed in a proper way.
     Use a string to make a hanging postcard and photo gallery.<br/>

     If you’ve managed to keep the ticket stubs from those plane, train, or bus rides you’ve taken while
      hopping from country to country, making a ticket collage would be an easy and inexpensive way to document
       those adventures. Simply grab a piece of posterboard (or any type of thick paper) and some glue,
        arrange the tickets into a colorful mosaic, and glue everything together.
     </p>

     <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img3.jpg" style={{width: '100%'}}/>

  </div>




     
     <h2 className="blogs-description-area-of-main-headings-card4">4. Photo Book</h2>
      
      <p className="blogs-description-area-of-main--text-card4">
      If you’d rather get crafty on your phone or computer, turn your travel snaps into a photo book
       from Shutterfly. You can customize photo sizes, layouts, captions, backgrounds, and more, and
        then add a memorabilia pocket to store boarding passes, tickets, or other mementos.
         </p>

         <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img4.jpg" style={{width: '100%'}}/>

  </div>




  <h2 className="blogs-description-area-of-main-headings-card4">5. Fillable Ornaments</h2>
      
      <p className="blogs-description-area-of-main--text-card4">
      Wound up with a handful of foreign currency that you can’t bear to throw away? Grab some of these
       fillable ornaments and add in your coins for a sentimental decoration.<br/>

       Get a glass or plastic ornament and insert souvenirs. Try to get a larger size to fit your mementos.
        Each ornament can represent a city you've visited.

This is a great idea because you'll definitely be taking them out to look at once a year, and Christmas
 is the perfect time to relive memories.
         </p>
       
         <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img5.jpg" style={{width: '100%'}}/>

  </div>



<h2 className="blogs-description-area-of-main-headings-card4">6. Shot glasses</h2>
      
      <p className="blogs-description-area-of-main--text-card4">
      This may sound borderline hard kill, but shot glasses are useful if you know exactly what to do with it.

Our tip? Use it as a holder for small items like keys, paper clips or hair ties. You can even use them as pots
 for your tiny plants. The ideas are boundless. You just have to stay creative.

You only have to be careful about packing these items when you’re heading home as it may break inside your
 luggage.<br/>

Now that you have ideas on what souvenirs to buy on your next trip, the other thing that you should focus
 on now is how to organize them properly. A wall, or if you’re really that of a hoarder, a room full of travel
  souvenirs will soon be the center of attraction inside your humble abode.
      </p>

      <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img6.jpg" style={{width: '100%'}}/>

  </div>





  <h2 className="blogs-description-area-of-main-headings-card4">7. Home Magnets</h2>
      
      <p className="blogs-description-area-of-main--text-card4">
      If you ask nicely, most hotels will let you keep those cheap plastic room keys. Once you’ve stockpiled
       a few, all you need is some magnetic tape to turn your keys into refrigerator magnets. Now every 
       time you look at your fridge, you’ll be reminded of all of the amazing places you’ve stayed. You can
        also create magnets out of other memorabilia, such as casino chips<br/>

        Travel magnets never go out of style. They are everywhere.

If you have zero idea on what to bring home to remember your trip by, this is the easiest thing that you can
 snag from markets and shopping centers.

They even come in different designs. So, if you happen to have a very fond memory of a place, you can pick
 from a variety of magnet designs that resonate most with you. Aside from that, most magnet sizes are small
  enough to fit inside your carry-on. They don’t even weigh that much.
      </p>

      <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img7.jpg" style={{width: '100%'}}/>

  </div>






  <h2 className="blogs-description-area-of-main-headings-card4">8. Local currency</h2>
      
      <p className="blogs-description-area-of-main--text-card4">
      This is another cult favorite among frequent travelers. Aside from the fact that it doesn’t cost anything
       at all, it can serve as a gorgeous room ornament when you put it in a jar or a frame!

You could save one bill or a couple of spare change as a souvenir. Trust me, there will be at least a couple of
 coins that will be left inside your carry on or your pocket when you unpack your belongings.<br/>

It’s also one great time saver, especially if you don’t have the luxury to go around and look for actual 
souvenirs. The local currency is good enough to help you remember that trip!

You can even make it a habit every time you go for an adventure. Who says you can only bring one souvenir 
every time?
      </p>

      <div className="wow-news-card1-work-page-top-image-card4">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card4/img8.jpg" style={{width: '100%'}}/>

  </div>


    
  
        
   
        </div>
        </div>





        </Container>









        










































{/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}































      </div>
    );
  }
}
export default Newscard4;
