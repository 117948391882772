import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Newscard1 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString() };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  SpacePortae = () => {
    document.getElementById("emirates").style.display = "none";
    document.getElementById("tripadvisor").style.display = "none";
    document.getElementById("Travellingman").style.display = "none";
    document.getElementById("smartdubai").style.display = "none";
    document.getElementById("dubaipolice").style.display = "none";
    document.getElementById("natgeotravel").style.display = "none";
    document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "block";
    document.getElementById("europespaceagency").style.display = "none";

    document.getElementById("mySidenav-newsroom").style.width = "0px";
  };

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        

<Container>
        <div class="td-post-header-holder td-image-gradient">
        <div class="td-post-featured-image">
            
            <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2"
            src="https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1.jpg" srcset="https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1.jpg 1068w, https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1-300x163.jpg 300w, https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1-768x417.jpg 768w, https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1-520x282.jpg 520w, https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1-696x378.jpg 696w, https://www.getyourguide.com/magazine/wp-content/migrated-content/uploads/2018/10/Heeader-image-Dubai-GetYourGuide-1-400x217.jpg 400w" sizes="(max-width: 1068px) 100vw, 1068px" alt="" title="Heeader image - Dubai - GetYourGuide"/>
      
        </div>
            <header class="td-post-title">
                <ul class="td-category">
                    <li class="entry-category">
                        <a 
                        target="blank"
                        href="https://www.visitdubai.com/en">Dubai</a>
                        </li>
                    <li class="entry-category">
                        <a target="blank" href="#">UAE</a>
                    </li>
                </ul>                
        <h1 class="entry-title-newscard1">6 tips for first-time travelers to Dubai</h1>

                

                <div class="td-module-meta-info">
                 <span class="td-post-date">
                     <time class="entry-date updated td-module-date"
                      datetime="2018-11-07T10:59:17+00:00">November 7, 2018</time>
                </span>      

                <div class="td-post-views">
                    <i class="fa fa-eye view-icon-views-newscard1"></i>
                    <span class="td-nr-views-10968">26736</span>
                </div>                    
                              
                     </div>

            </header>
        </div>
 
</Container>








<div className="gradient-back-travel-how-work">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-how-work-grid-text">
                  <div className="travel-how-work-page-araea-sub-headings110">
                    Dubai: Get your guide
                  </div>

                  <div className="travel-how-work-page-page-text">
                  Glitz, glam, and a whole lot of luxury. If this is this first thing you think of when
                   <span style={{color:'#f87370'}}> Dubai</span> comes to mind,
        you wouldn’t be wrong. It’s big and flashy: home to the tallest buildings, the largest malls, and the most
        extravagant experiences. However, there are some differences to the western world that are worth mentioning.
     Here are six tips for first-time travelers to get the most out of their time in Dubai.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/newsroom/additional-cards/dubai-get-guide.jpg"></img>
              </Col>
            </Row>
          </Container>
        </div>



<Container>
<div class="description_of_card_of_newsroom">
  <div class="description_of_card_of_newsroom_area">
    <p class="blogs-description-area-of-main--text"><span style={{color:'#f87370'}}> Dubai</span> is a city that
     boasts many world records. 
    From the highest building in the world to a seven-star hotel. But here are some Dubai fun facts that 
    you may not know… spending on your dresses, shoes,
       bags, makeup, get-over, and many more but Wow!</p>

  <h2 class="blogs-description-area-of-main-headings">
  1. Mind your manners
  </h2>
  <p class="blogs-description-area-of-main--text">
    Dubai’s futuristic vibe and glossy, Western veneer makes it easy to forget that you’re on an Arabian peninsula.
     However, the United Arab Emirates’ cultural norms are different from the West. What might seem like a regular
      outfit for a night out or one drink too many could land you in a potentially uncomfortable situation. Sure, 
      there are bars that serve alcohol and western dress abounds, but it’s never a bad idea to err on the side of
     caution.
   </p>

   
<div class="wow-news-card1-work-page-top-image">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card1/img1.jpg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings">2. Hop in a cab (or bus)</h2>
   <p class="blogs-description-area-of-main--text">
     Dubai has two seasons: hot, and hotter. For travelers used to taking on cities by foot, we’ve got news for you:
      Dubai is not made for walking. It’s made for driving and riding. Or, if you’re feeling opulent, take to the skies
       in a helicopter.</p>

      <div class="wow-news-card1-work-page-top-image">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card1/img2.jpg" style={{width: '100%'}}/>

  </div>


      
  <h2 class="blogs-description-area-of-main-headings">3. Get your weekend started right</h2>
  <p class="blogs-description-area-of-main--text">
    On Friday. That’s right—the weekend begins on Friday morning in Dubai, and it starts with brunch. If you hail 
    from the West, you’re most likely accustomed to weekends that span from Saturday to Sunday. Dubai does things 
    differently. Emiratis work from Sunday to Thursday and enjoy their weekend on Friday and Saturday.
     </p>

     <div class="wow-news-card1-work-page-top-image">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card1/img3.jpg" style={{width: '100%'}}/>

  </div>
     
     <h2 class="blogs-description-area-of-main-headings">4. Visit the desert</h2>
      
      <p class="blogs-description-area-of-main--text">
      It takes no time at all (45 minutes, max) to go from the glittering towers of Dubai to endless desert dunes.
       So go ahead: leave the city behind and head into the starkly beautiful Arabian Desert. Take to the skies in a
        hot air balloon and search for emerald green oases, gazelles, and wandering camels. If you’d rather stay on
         the ground, consider a sundown safari and dune drive. No matter what you choose, the desert doesn’t disappoint.
         </p>
       
         <div class="wow-news-card1-work-page-top-image">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card1/img4.jpg" style={{width: '100%'}}/>

  </div>




  <h2 class="blogs-description-area-of-main-headings">5. Explore massive malls</h2>
      
      <p class="blogs-description-area-of-main--text">
      Emiratis take their shopping seriously. We’re pretty sure even “shop til you drop” enthusiasts will be a bit 
      overwhelmed. Why? These malls are enormous, and they have everything. Designer clothes? Check. Jewelry? Check.
       Food, souvenirs, entertainment, ATMs for withdrawing physical bars of solid gold? Check. Even if shopping isn’t
        your thing, you have to see these malls to believe their splendor and size.
         </p>
       
         <div class="wow-news-card1-work-page-top-image">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card1/img5.jpg" style={{width: '100%'}}/>

  </div>



  <h2 class="blogs-description-area-of-main-headings">6. Head to the beach (but only in the winter)</h2>
      
      <p class="blogs-description-area-of-main--text">
      The beaches of Dubai are beautiful. And hot. Very, very hot. Temperatures from June to August can reach
       105 degrees Fahrenheit (40 degrees Celsius), so it’s best to save your beach visit until the winter.
        If you’re visiting Dubai between November and March, you’re in luck. This is when it cools down enough
       to make beach lounging enjoyable. Between lounging, jet skiing, and soaring above the Arabian Gulf on a
        parasail, there’s no end to amazing things to do.
      </p>

      <div class="wow-news-card1-work-page-top-image">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card1/img6.jpg" style={{width: '100%'}}/>

  </div>






    
    <p class="blogs-description-area-of-main--text">
      <span class="blogs-description-area-of-sub-headings">So, why are you worried now?</span> Pick your cards and cash
    and let’s go-to Travelling with WOW. We will try our level best to make your Travelling experience as much comfortable
        and fun as you ever wish to be.</p>
        
   
        </div>
        </div>





        </Container>









        






{/* next previous article */}


{/* <div class="description_of_card_of_newsroom_bottom_area">
      <div class="description_of_card_of_newsroom_bottom_area_previous"><h3><i class="fa fa-mail-reply" aria-hidden="true">
        </i>
        </h3>
        
      <h5>  WHO WILL DRIVE YOUR JOURNEY</h5>
      <p>Previouse Article</p>
      </div>
      
      <div class="description_of_card_of_newsroom_bottom_area_next">
        <h3><i class="fa fa-mail-forward" aria-hidden="true"></i></h3>
        <h5>WOW IS FINALLY HERE!</h5>
        <p>Next Article</p>
        </div>
        
        
        </div> */}



















{/* <Container>




<div className="wow-travel-work-top-area-third-area-contet">
<div className="wow-travel-work-top-area-third-area-contetn">
    <p>That is why we require all our Travellers and Managers to uphold an excessive popular of conduct,
         and to observe the law — and our Community Guidelines. Failure to meet the expectations of 
         the Community Guidelines can result in permanent deactivation from WOW.</p>
    
    <h3>Plan as little as possible</h3>
    <p>One of the first lessons on the road are that your plans will nearly always change. 
      You’ll arrive in a place and hate it and want to leave immediately, or you’ll fall in love with 
      a destination and want to spend longer there. You’ll make friends with a group of awesome people
       and want to change your plans so you can travel with them for longer, or you’ll find out about
        an amazing-sounding town that’s nearby and want to head there instead.</p>
         
    <h3>Patience Is Important</h3>
    <p>WOW is built at the energy of a diverse and inclusive community. Don’t sweat the stuff 
      you can’t control. Life is much too short to be angry & annoyed all the time. Did you miss
       your bus? No worries, there will be another one.
       Just take a deep breath and remind yourself that it could be worse.</p>
        
        <h3>Observe Daily Life Around You</h3>
    <p>If you really want to get a feel for the pulse of a place, I recommend spending a few hours sitting
       in a park or on a busy street corner by yourself just watching day to day life happen 
       in front of you.

Slow down your train of thought and pay close attention to the details around you. The smells,
 the colors, human interactions, and sounds. It’s a kind of meditation — and you’ll see stuff you never 
 noticed before.</p>


 <h3>Slow Down To Enjoy Your Trip</h3>
    <p>Don’t try to cram 6 countries into 6 weeks of travel. All the good stuff happens when you really
       take the time to explore. You’ll learn about activities that aren’t in your guidebook and meet people
        who are eager to show you around.

I can honestly say that NONE of my best travel experiences happened within the first few days 
of arriving somewhere. Spend more time in fewer places for maximum enjoyment. I promise you’ll have a
 much better time!</p>



 <h3>Unpack as Little as Possible</h3>
    <p>There is nothing more frustrating than losing something when you’re travelling.

And when do things generally get lost? It’s moving from one place to another.

I’ve found the best way to avoid this is just to not unpack!</p>

<p> Wow Sujjests do unpack have a system.</p>

<ul>
        <li>
        Washbag always hangs in the bathroom.
            </li>
        <li> Wallet and keys always sit on the desk.
        </li>
        
        <li> Kindle and chargers on the bedside table.</li>
        <li> Folder of essential documents straight into the safe.</li>
        </ul>



 <h3>Wake Up Early To Avoid Crowds</h3>
    <p>Rise before sunrise to have the best attractions all to yourself while avoiding large tourist crowds.
       It’s also a magical time for photos due to soft diffused light, and it’s usually easier to interact
        with locals getting ready for their day.

Want those postcard Instagram travel shots? You need to get out there before everyone else shows up. Sketchy
 areas are less dangerous in the morning too.</p>



 <h3>Volunteer Occasionally</h3>
    <p>Make it a point to volunteer some of your time for worthwhile projects when traveling.
       Not only is it a very rewarding experience,
       but you’ll often learn more about the country and its people while also making new friends.

 Spend more time in fewer places for maximum enjoyment. I promise you’ll have a
 much better time!</p>



 <h3>Break Out Of Your Comfort Zone</h3>
    <p>Challenge yourself to try things that normally give you anxiety. The more you do this,
       the more that anxiety will fade away. Not a hiker? Go on more hikes. Have trouble talking to
        strangers? Talk to everyone. Scared of weird food? Eat the weirdest thing you can find.

I can honestly say that NONE of my best travel experiences happened within the first few days 
of arriving somewhere. Spend more time in fewer places for maximum enjoyment. I promise you’ll have a
 much better time!</p>



 <h3>Learn A Few Foreign Words</h3>
    <p>There’s no need to be fluent in order to visit a foreign country. However, taking the time to learn 
      a few phrases in the local language can improve your travel experience. Hello, please, thank you,
       nice to meet you, excuse me, sorry, and “can I take your portrait” are some of my favorites.

Not being able to communicate while traveling can be frustrating, but remember that this is your problem,
 not theirs. Raising your voice doesn’t magically help Mexican’s understand English. Try hand signals, 
 images on your phone, or Google Translate!</p>
            

    <h3>Traveler rules:</h3>
    <p>You will definitely look like a fool many times when traveling to new places.
       Rather than get embarrassed, laugh at yourself.
       Don’t be afraid to screw up, and don’t take life so seriously. Relax!</p>
    <p>To make certain a secure and respectful ride, do no longer:</p>
    
    <ul>
        <li>
             Violate WOW’s weapons policy through bringing a weapon alongside on a
              WOW experience or on WOW property
            </li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,
           expedita labore dicta dolorem ducimus aperiam eos laudantium
           iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!
        </li>
        
        <li> iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!</li>
        <li> iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!e</li>
        <li>iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!uidelines</li>
        <li> Pay your fare in cash (cash pointers are OK)</li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, sex, marital status, gender 
             identity, age, army status, or any different feature protected below law</li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,</li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,, or gestures</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,eat alcohol 
          at some point of your ride</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,y</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,rney, 
          coupon phishing, or manipulating opinions
          for experience credit</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, guidelines or WOW’s 
        privacy policy and terms &amp; conditions</li>
        </ul>
        





        
        </div>






        </div>
</Container> */}




















      </div>
    );
  }
}
export default Newscard1;
