import React from 'react';
import {
    Col,
    Row,
    Container,
    TouchableOpacity,
    View,
    Text

  } from "react-bootstrap";
 
class Grd extends React.Component {
 
    constructor(props) {
        super(props);
         this.state = { pictures: [] };
         this.onDrop = this.onDrop.bind(this);
    }
 
    onDrop=(picture)=> {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }
 
    render() {
        return (
            <Container className="mn">
  <Row>
    
    <Col xs={12} md={6} sm={6} >
    <Container className="textp">
   
    
      
      <h3 class="h3">About us</h3>
      <p >
      WOW Electronic Transport Services is aimed to introduce a better, improved, 
      and trendy dimension in the technology platform. Giving you more feasible,
       affordable, safe and secure taxi services controlled with your phone device.</p>
       <p>
       WOW's e-hailing application comes up with promising stress-free, simplified, 
       and improvised services. Starting their way from one region to another, WOW will
        be operational in almost every city of <span style={{color: "blue"}}>North America, South America, Europe, 
        Africa, Australia, Middle East, and South Asia</span> and will aspire to inspire and assist
         in every area of the region.
      </p>
      
     
    </Container>
    </Col>
    <Col xs={12} md={6} sm={6} >
    <Container className="textp">
      <img 
      className="a1"
      src="IMAGES/A1.png"/>

    </Container>
    
    </Col>
  </Row>
  <Row>
  <Col xs={12} md={6} sm={6} >
    <Container className="textp">
      <img 
      className="a1"
      src="IMAGES/A2.png"/>

    </Container>
    
    </Col>
  <Col xs={12} md={6} sm={6} >
    <Container>
    <div className="mn">
      <Container className="textp">
    
      <h3 class="h3">MISSION STATEMENT</h3>
      <p className="textp">
      Our mission is to pursue the business by incorporating
       innovation and advanced technology into our services.
        To create a long-term relationship based on trust with
         the people who use transportation services by giving 
         them an ultimate package of innovation, comfort, luxury,
          affordability, privacy, safety & security.
      </p>
      </Container>
      </div>
      <p>

      </p>
    </Container>
    </Col>
    
  </Row>

  <Row>
  <Col xs={12} md={6} sm={6} >
    <Container className="textp">
    <div className="mn">
      <Container>
      <h3 class="h3">VISION STATEMENT</h3>
      <p className="text">
      We aim to create an innovatively simple and easy
       commutation system that could set a benchmark of affordability 
       and luxury in transportation services. Followed by the latest smart 
       technological trends, for users as well as for Leaders who work 
       with us to earn their livings and a respective appellation in society.
      </p>
      </Container>
      </div>
      
      
    </Container>
    </Col>
    <Col xs={12} md={6} sm={6} >
    <Container>
      <img 
      className="a1"
      src="IMAGES/A3.png"/>

    </Container>
    
    </Col>
  </Row>


  <Row >
  <Col xs={12} md={6} sm={6} >
    <Container>
      <img 
      className="a1"
      src="IMAGES/A4.png"/>

    </Container>
    
    </Col>
  <Col xs={12} md={6} sm={6} >
    <Container>
    <div className="mn">
      <Container>
    
      <h3 class="h3">WOW PROMISES</h3>
      <p className="text">
      WOW comes up with a promising positive and realistic
       impact to reflect in your lives. Rather than building
        up our business for profits, our teams are up to ensure
         your comfort, safety, and affordability to
          travel with our reliable and trusted Leaders.
      </p>
      </Container>
      </div>
    </Container>
    </Col>
  </Row>

  <Row>
  <Col xs={12} md={6} sm={6}>
    <Container>
    <div className="mn">
      <Container>
    
      <h3 class="h3">WHY CHOOSE WOW?</h3>
      <p className="text">
      Choose WOW to help you focus on where you want
       to be headed because we’ll focus on getting you there on time.
        From our users who are going home after a tiring day at their office
         or school to our Leaders who are behind the wheel for our users,
          we aim to move everyone towards a better life.
      </p>
      </Container>
      </div>
      
    </Container>
    </Col>
    <Col xs={12} md={6} sm={6} >
    <Container>
      <img 
      className="a12"
      src="IMAGES/A5.png"/>

    </Container>
    
    </Col>
  </Row>

</Container>

        );
    }
}
export default Grd;