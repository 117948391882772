import React, { Component } from "react";


class Table extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

        // <button onclick="hell()">click me</button> 
        // <!-- /////////////////////////////////////////// -->
       
        
      //    <div className="container" style={{marginTop:"2%"}} >
      // <div className="headerCon">
      //   <div className="logo-con">
      //     <img src="IMAGES/Logo.png" className="img-fluid" alt="logo" />
      //   </div>

      //   <div className="barCode">
      //      <div className="row "> 
          /* <!-- <div className="col-4 sssf offset-2 "> --> */
          // <div className="imgCon">
          //   <img src="IMAGES/qr.png" className="img-fluid mx-auto" alt="bb" />
            /* <!-- </div> -->
            <!-- </div> --> */
      //     </div>
      //   </div>
      // </div>
      // <div className="topLine"></div>

      /* <!-- under section start --> */

      // <section className="bioSection">
      //   <div className="mainHeading">
      //     LEADER STATEMENTrow
      //   </div>

        /* <!-- data con --> */
      //   <div className="dataCon">
      //     <div className="">
      //       <div className="col-3 bioHead">Leader Name:</div>
      //       <div className="col-5">Tahir sb</div>
      //       <div className="col-4 accountF">
      //         <div className="rightInts">BANK Account #</div>
      //         <div>293484822323223</div>
      //       </div>
      //     </div>
      //     <div className="row">
      //       <div className="col-3 bioHead">Leader Addess:</div>
      //       <div className="col-5">Dubai-UAE</div>
      //       <div className="col-4 accountF">
      //         <div className="rightInts">Bank Name & Branch Code</div>
      //         <div>HBL (2001)</div>
      //       </div>
      //     </div>
      //     <div className="row">
      //       <div className="col-3 bioHead">CURRENT BALANCE:</div>
      //       <div className="col-5">
      //         <div className="amount">AED 2,086.50</div>
      //       </div>
      //       <div className="col-4 accountF">
      //         <div className="rightInts">
      //           IBAN NO #
      //         </div>
      //         <div>HBL000000039020293</div>
      //         <div></div>
      //       </div>
      //     </div>
      //   </div>
      // </section>
      
      /* <!--  -->
      <!-- table section --> */
      // <section className="tablecon" xs={12} md={6} sm={12} >
      //   <div className="tabCon">
      //     <div className="table table-responsive">
      //       <table className="table table-striped">
      //         <thead>
      //           <tr>
      //             <th scope="col">
      //               Order <br />
      //               Date/Time
      //             </th>
      //             <th scope="col">
      //               Order <br />
      //               Code
      //             </th>
      //             <th scope="col">
      //               Payment <br />
      //               Mode
      //             </th>
      //             <th scope="col">
      //               Total <br />
      //               Fare
      //             </th>
      //             <th scope="col">
      //               Tax <br />
      //               on Fee
      //             </th>
      //             <th scope="col">
      //               Cash <br />
      //               Collected
      //             </th>
      //             <th scope="col">
      //               RTA <br />
      //               Fee
      //             </th>
      //             <th scope="col">
      //               Leader <br />
      //               Earning
      //             </th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>

      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>

      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>

      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>

      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>

      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //           <tr>
      //             <td>2020-01-23</td>
      //             <td>3uhf09234jhf92bj3232</td>
      //             <td>cash</td>
      //             <td>505.5</td>
      //             <td>1.125</td>
      //             <td>30</td>
      //             <td>5</td>
      //             <td>32.5543</td>
      //           </tr>
      //         </tbody>
      //         <tfoot>
      //           <tr className="tfooter ttss">
      //             <td>SUM</td>
      //             <td>Total Trips</td>
      //             <td></td>
      //             <td>ALL TOTAL <br/> FARE</td>
      //             <td>TOTAL <br/>VAT FEE</td>
      //             <td>TOTAL CASH <br/>COLLECTED</td>
      //             <td>TOTAL<br/> RTA FEE</td>
      //             <td>TOTAL LEADER<br/> EARNING</td>
      //           </tr>
      //           <tr className="tTotal">
      //             <td></td>
      //             <td>10</td>
      //             <td></td>
      //             <td>400</td>
      //             <td>11</td>
      //             <td>451</td>
      //             <td>50</td>
      //             <td>2,086</td>
      //           </tr>
      //         </tfoot>
      //       </table>
      //     </div>
      //   </div>
        
      // </section>
      

      /* <!-- footer section --> */

      // <div className="baseLine"></div>

      // <footer xs={12} md={6} sm={12} className="sst">
          
              
      //   <div className="footerCon row">
      //     <div className="col-3">
      //       <div className="itemCosn">
      //         Regards <br />
      //         <b>
      //           Customer Service Division <br />
      //           WOW electronic transport services
      //         </b>
      //       </div>
      //     </div>
          
      //     <div className="col-3">
      //       <div className="itemCon">
      //        <div className="iconCon">
      //         <img src="IMAGES/call.png" alt=""/>
      //        </div>
      //        <span className="iconDesc">
      //          +9712233122
      //        </span>
      //       </div>
      //     </div>

      //     <div className="col-3">
      //       <div className="itemCon">
      //        <div className="iconCon">
      //         <img src="IMAGES/web.png" alt=""/>
      //        </div>
      //        <span className="iconDesc">
      //          www.wowets.com
      //        </span>
      //       </div>
      //     </div>

      //     <div className="col-3">
      //       <div className="itemCon">
      //        <div className="iconCon">
      //         <img src="loc.png" alt=""/>
      //        </div>
      //        <span className="iconDescs">
      //          WOW Electronic Transport Services, Aspin Commercial Tower, 48yh Floor, Sheikh Zayed Road, Dubai - UAE
      //        </span>
      //       </div>
      //     </div>
         
      //   </div>
      
        
      // </footer>
      // </div>
      // <div className="container">
      
    /* <div className="container1" style={{marginTop:"2%"}} >
      <div className="logocon">
      <img src="IMAGES/Logo.png" className="image-logo" alt="logo" />

      </div>
      <div className="img-con">
      <img src="IMAGES/qr.png" className="image-qr" alt="logo" />
      </div>

    </div> */

    
<div className="container">
  
    <div classname="header">
  <div className="flex-image-container">
    <div>
    <img src="IMAGES/Logo.png" className="image-logo" alt="logo" />
    </div>

  <div align="right"><img src="IMAGES/qr.png" className="image-qr" alt="logo" />
  </div>
  </div>
  </div>
  
    <div className="header-line"> </div>

    


    
    <div className="mainHeading">
          LEADER STATEMENT
        </div> 



        

        <div class="infoCon">
     

      {/* <!-- row --> */}
      <div class="horizontalRows">
        <div class="one leader-text ">Leader Name:</div>
        <div class="two leader-inside-text">Sir Tahir</div>
        <div class="three ">
          <div class="leader-text">Bank Account# <br></br></div>
          <div class="leader-inside-text">293484822323223</div>
        </div>
      </div>

      {/* <!-- row end --> */}


      <div class="horizontalRows">
        <div class="one leader-text"><b>Leader Addess:</b></div>
        <div class="two leader-inside-text">Dubai-UAE</div>
        <div class="three">
          <div class="leader-text">Bank Name & Branch Code</div>
          <div class="leader-inside-text">HBL (2001)</div>
        </div>
      </div>

      {/* <!-- row end --> */}

      <div class="horizontalRows">
        <div class="one leader-text-current">CURRENT BALANCE:</div>
        <div class="two "><p class="leader-inside-text-box">AED 2,086.50</p></div>
        <div class="three">
          <div class="leader-text">IBAN NO #</div>
          <div class="leader-inside-text">HBL000000039020293</div>
        </div>
      </div>
    </div>

        
        
       


          
 
        
 
  
     
 <table class="table table-top">
          <thead>
            <th>Order <br />
                    Date/Time</th>
            <th>Order <br />
                    Code</th>
            <th>Payment <br />
                    Mode</th>
            <th>Total <br />
                    Fare</th>
            <th>Tax <br />
                    on Fee</th>
            <th>Cash <br />
                    Collected</th>
            <th>RTA <br />
                    Fee</th>   
            <th>Leader <br />
                    Earning</th>          
            </thead>
             <tbody>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>

        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>

        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>

        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
     </tbody>               

     <tfoot>
       <tr class="tfooter ttss">
                  <td><b>SUM</b></td>
                  <td><b>Total Trips</b></td>
                  <td></td>
                  <td><b>ALL TOTAL <br/> FARE</b></td>
                  <td><b>TOTAL <br/>VAT FEE</b></td>
                  <td><b>TOTAL CASH <br/>COLLECTED</b></td>
                  <td><b>TOTAL<br/> RTA FEE</b></td>
                  <td><b>TOTAL LEADER<br/> EARNING</b></td>
                </tr>
                <tr class="tTotal">
                  <td class="td-text"></td>
                  <td className="td-text">10</td>
                  <td className="td-text"></td>
                  <td className="td-text">400</td>
                  <td className="td-text">11</td>
                  <td className="td-text">451</td>
                  <td className="td-text">50</td>
                  <td className="td-text">2,086</td>
                </tr>
     </tfoot>                

        </table>
        
 

      <div className="header-line-base"> </div>

      


      
  {/* <div className="flex-container">
<table>
  <tbody>
    <td className="table-tds">
  <div class="itemCosn">
              Regards, <br />
              <b>
                Customer Service Division, <br />
                WOW electronic transport services.
              </b>
            </div>
            </td>

            <td className="table-tds">
             <div class="itemCon">
             <div class="iconCon">
              <img className="footer-baseline-images" src="IMAGES/call.png" alt=""/>
             </div>
             <span class="iconDesc">
               +9712233122
             </span>
            </div>  </td>
         
         <td className="table-tds">
            <div class="itemCon">
             <div class="iconCon">
              <img className="footer-baseline-images" src="IMAGES/web.png" alt=""/>
             </div>
             <span class="iconDesc">
               www.wowets.com
             </span>
            </div>  </td>

            <td className="table-tds">
            <div class="itemCon">
             <div class="iconCon">
              <img className="footer-baseline-images" src="IMAGES/loc.png" alt=""/>
             </div>
             <span class="iconDescs">
               WOW Electronic Transport Services, Aspin Commercial Tower, 48yh Floor, Sheikh Zayed Road, Dubai - UAE
             </span>
            </div> 
            </td>

            </tbody>
            </table>

 
  </div> */}
<div class="vendor-footer-content">
  <div class="infoCons">
     

      {/* <!-- row --> */}
      <div class="horizontalRow">
        <div class="ones leader-texts ">
          <p class="regard-text"><strong>Regards</strong> <br />
              <b>
                Customer Service Division <br />
                WOW electronic transport services
              </b></p></div>
        <div class="twos leader-inside-texts">
          <div class="itemCons">
             <div class="iconCon">
              <img  class="footer-baseline-image" src="IMAGES/call.png" alt=""/>
             </div>
             <span class="iconDescss">
               +9712233122
             </span>
            </div></div>
        <div class="threes leader-inside-texts"><div class="itemCons">
             <div class="iconCon">
              <img class="footer-baseline-image" src="IMAGES/web.png" alt=""/>
             </div>
             <span class="iconDescss">
               www.wowets.com
             </span>
            </div></div>


        <div class="four ">
          <div class="leader-texts"><div class="itemCons">
             <div class="iconCon">
              <img class="footer-baseline-image" src="IMAGES/loc.png" alt=""/>
             </div>
             <span class="iconDescsss">
               WOW Electronic Transport Services, Aspin Commercial Tower, 48yh Floor, Sheikh Zayed Road, Dubai - UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div>
    

    




    

       
        
       

    
   
   </div>

    


 
     
    
    
    );
  }
}
export default Table;