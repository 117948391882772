// material ui stepper

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import VerifyCustomerBookingDetailsRentalCars from "../RentalCarsWowHolidayStepperPages/VerifyCustomerBookingDetailsRentalCars";
import Stepper1PageAdditionalChargesDetails from "../RentalCarsWowHolidayStepperPages/Stepper1PageAdditionalChargesDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Search Results",
    "Create an ad group",
    "Create an ad",
    "ad group created with another step",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return (
        <Stepper1PageAdditionalChargesDetails
        // handleOpenModal={handleOpenModal}
        />
      );
    case 1:
      return (
        <VerifyCustomerBookingDetailsRentalCars
        // handleOpenModal={handleOpenModal}
        // handleCloseModal={handleCloseModal}
        // modalIsOpen={modalIsOpen}
        // setStepsRoute={setStepsRoute}
        // phoneNumber={phoneNumber}
        />
      );
    case 2:
      return "Step 3: This is the bit I really care about!";
    case 3:
      return "Step 3: This is the bit I really care about gfhfghfghfgh!";
    default:
      return "Unknown step";
  }
}

export default function RentalCars_FormCustomerDetails() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const totalSteps = () => {
    return getSteps().length;
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  function isStepComplete(step) {
    return completed.has(step);
  }

  return (
    <div>
      <div className="td-post-header-holder-RentalCars-bookingpage-CustomerDetails-mainClass">
        <div className="td-post-header-holder-RentalCars-CustomerDetails-bookingpage td-image-gradient-RentalCars-bookingpage">
          <div className="td-post-featured-image-RentalCars-bookingpage">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-RentalCars"
              src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/stepp-car.png"
            />

            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-RentalCars2"
              src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/steppe-car.jpg"
            />
            <div class="join-us-our-content">
              <div class="MuiContainer-root MuiContainer-maxWidthLg">
                <div class="airportTaxi-wow-holiday-home-our-story">
                  <h1 class="airportTaxi-wow-holiday-our-team-heading">
                    Rental Cars Booking Details
                  </h1>
                  <h3
                    className="text-in-h3-airportTaxi"
                    style={{
                      color: "#ffffff",
                      paddingLeft: "0%",
                      textAlign: "left",
                      fontSize: "25px",
                    }}
                  >
                    WITH LATEST TECHNOLOGICAL TRENDS
                  </h3>
                  <h5
                    style={{
                      color: "#ffffff",
                      paddingLeft: "0%",
                      fontSize: "20px",
                    }}
                    className="airportTaxi-banner-top-co"
                  >
                    WOW Easy transportation between the airport and your
                    accommodations.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.root}>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const buttonProps = {};
            if (isStepOptional(index)) {
              buttonProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
                {/* All steps completed - you&apos;re finished */}
                Congradulations Your Ride Has been Booked. On its Way.
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div className="stepper-button-center">
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Next
                </Button>
                {isStepOptional(activeStep) && !completed.has(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
                {activeStep !== steps.length &&
                  (completed.has(activeStep) ? (
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleComplete}
                    >
                      {completedSteps() === totalSteps() - 1
                        ? "Finish"
                        : "Complete Step"}
                    </Button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
