import React, { Component, useEffect } from "react";

import '../LEnewnoinvoice/newnooneinvoice.scss';
// import Invoiceview from './invoiceview';
import { Dropdown, Input,Label,Icon,Checkbox,TextArea,Button,Header,Modal,Image } from 'semantic-ui-react'
import ReactToPrint from 'react-to-print';
/* import { Visible, Hidden, setConfiguration } from "react-grid-system"; */
/* import ReactToPrint from 'react-to-print-advanced'; */
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Media,
  
  ButtonGroup,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,withRouter,
  browserhistory,
} from "react-router-dom";
// import * as Actions from "./store/actions";
import { compose } from "redux";
import { connect } from "react-redux";

// import reducer from "./store/reducers";
// import withReducer from "../../../store/withReducer";
// import store from "../../../store";


    

  

    


const options = [
  { key: 'save', icon: 'save', text: 'Save and Send', value: 'Save and Send' },
  { key: 'delete', icon: 'credit card outline', text: 'Save and Record A Payment', value: 'Save and Record A Payment' },
]
class InvoiceTopAreaPreview extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
      invoiceData:{},
      open: false,
    };
  }

//   open = () => this.setState({ open: true })
//   close = () => this.setState({ open: false })


  show = (dimmer) => () => this.setState({ dimmer, open: true })

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape,
       closeOnDimmerClick,
        open: true
       })
  }

  close = () => this.setState({ open: false ,opentwo: false, openthree: false})
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  

//   componentWillReceiveProps(nextProps){
//      let resNumber4 =nextProps.creatInvoiceReducer;
//     if (!resNumber4.isLoading) {
//       if (resNumber4.errMsg == null && !(resNumber4.data===undefined)) {
//        if(this.state.invoiceFlag78){ 
//        this.setState({loaderFlag:false,createFileError:'',invoiceData:resNumber4.data})  
//        }
//       }else{
//        this.setState({createFileError:resNumber4.errMsg})  
//  console.log(resNumber4.errMsg)
//       }
//     } 
//   }
//   componentDidMount(){
//     store.subscribe(() => {
//        getInvoiceReducer 
//       let resNumber5 = store.getState().Invoiceview1.getInvoiceReducer;
//       if(!(resNumber5.data===null)){
//       this.setState({invoiceData:resNumber5.data.invoice})
//       console.log(resNumber5.data,"pppppppppppppppppppppp") 
//       }

//       })
//   }


  render() {
    const { open, closeOnEscape, closeOnDimmerClick, dimmer, opentwo, openthree} = this.state;
    return (
      <div>







        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} mg={12}>
                <div className="invoice-payment-voucher-view-area">
                
                  <div className="new-invoice-payment-voucher-header-text-area" role="alert">
                    <h2>Invoice#1</h2>

                    <div className="two-button-edi-save-new-invoice-payment-vouche">

                   

                      <Button.Group color='black'>
    <Button onClick={()=>this.props.history.push('/AccountantInvoiceAdd')}   style={{borderTopLeftRadius:'20px',borderBottomLeftRadius:'20px',marginRight:'-2px'}}>   Create another invoice</Button>
    <Dropdown
      className='button icon'
      style={{borderTopRightRadius:'20px',borderBottomRightRadius:'20px',borderLeft:'1px solid dimgray'}}
      floating
      options={options}
      trigger={<React.Fragment />}
    />
  </Button.Group>
                    </div>
                  </div>

                  <div></div>


                  <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top-invoiceone"></div>
                      </div>

                  <div class="invoice-preview__warning-message le-text--body"></div>

  <div className="invoice-previe-div-body-le">
                    <div className="invoice-contemplary-body-inside">






                      

                      <Container>


<div className="status-cust-invoice">
<div>
<div className="status-cust-headings-invoice">Status</div>
<p className="status-cust-draft-invoice">Draft</p>
</div>

<div>
      <div className="status-cust-headings-invoice">Customer</div>
      <p className="status-cust-name-invoice">{this.state.invoiceData.userProfile && this.state.invoiceData.userProfile.firstName+" "+this.state.invoiceData.userProfile.lastName}
      <span><i class="fa fa-exclamation-circle exclamation-icon" aria-hidden="true"></i></span></p>
</div>

<div>
      <div className="status-cust-headings-invoice">Amount due</div>
    <p className="status-cust-amount-invoice">AED {this.state.invoiceData.amountToBePaid && this.state.invoiceData.amountToBePaid}</p>
</div>

<div>
      <div className="status-cust-headings-invoice">due</div>
      <p className="status-cust-dueday-invoice">{this.state.invoiceData.generatedAt && this.state.invoiceData.generatedAt.slice(0,10)}</p>
</div>
</div>
</Container>
              














<div className="invoice-previe-div-body-le-small-box">
<div className="invoice-contemplary-body-inside-small-box">

<Row>
                        <Col lg={12} md={12} xl={12} >

                          {/* <div style={{marginTop:'2rem'}}>
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <img src="IMAGES/circle-doc.png"></img>
                              <div style={{marginTop:'2rem'}}>
                                Creative Invoice
                              </div>

                            </div>

                          </div> */}
<div className="invoice-one-border-box">
<Media
                          as="li"
                          className="media-ride-list-invoice-small-box"
                          
                        >
                          <div className="list-steps-of-ride-invoice-small-box">
                              <div className="invoice-contemplary-body-inside__header__logo-small-box">
                                <img src={require('../IMAGES/circle-doc.png')} />
                              </div>
                          </div>
                          <Media.Body>
                          <div className="contemporary-template__header__info-small-box">
                          <div className="le-heading--title-small-box">Invoice Created
                           </div>
                          <strong className="le-text--strong-small-box">
                            Created: <span style={{color:'#aaaaaa'}}>
                              on july 1, 2020 at 11:41 AM GMT+5</span>
                          </strong>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>

                          </Media.Body>
                        </Media>

{/* </Col>


      <Col lg={6} md={6} xl={6}> */}

             <div >

             <Button className="le-newinvoice-payment-voucher-edit-button" variant="dark">
                  Edit
            </Button>


            <Button className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
                Export as pdf
             </Button>

             <Button className="le-newinvoice-payment-voucher-edit-button" variant="outline-grey">
                Print
             </Button>

              

  
            </div>
            </div>
</Col>

</Row>

</div>

</div>        
                   
                   









<div className="invoice-previe-div-body-le-small-box-invoicegetpaid">
<div className="invoice-contemplary-body-inside-small-box-invoicegetpaid">

<Row>
                        <Col lg={12} md={12} xl={12} >

<div className="invoice-one-border-box-invoicegetpaid">
<Media
                          as="li"
                          className="media-ride-list-invoice-small-box-invoicegetpaid"
                          
                        >
                          <div className="list-steps-of-ride-invoice-small-box-invoicegetpaid">
                            <div className="invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid">
                                <img src={require('../IMAGES/circle-cursor.png')}/>
                              </div>
                          </div>
                          <Media.Body>
                          <div className="contemporary-template__header__info-small-box-invoicegetpaid">
                          <div className="le-heading--title-small-box-invoicegetpaid">Send Invoice
                           </div>
                          <strong className="le-text--strong-small-box-invoicegetpaid">
                            Last Sent: Never
                          </strong>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>

                          </Media.Body>
                        </Media>


                        <div style={{marginTop:'20px'}}>




<div>
        <Button onClick={this.closeConfigShow(false, true)} className="le-newinvoice-payment-voucher-edit-button"
         variant="outline-grey">
         Invoice Sent
        </Button>

        <Button className="le-newinvoice-payment-voucher-edit-button"
         variant="outline-grey">
         Skip
        </Button>

        <Button className="le-newinvoice-payment-voucher-edit-button" color="black">
            Mark as sent
          </Button>

        <Modal
          open={open}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={this.close}
          closeIcon>
          <Modal.Header>Send this invoice</Modal.Header>
          <Modal.Content>
            <div>
                <div className="email-text-inside-modal-1">
                    <p>Email</p>
                </div>



                <div class="description_of_card_of_newnoinvoice">
 <div class="description_of_card_of_newnoinvoice_area">


<Row>
<Col xl={12} md={12} lg={12} xs={12}>


<Form>
<div className="forms-group-chequedetails">
  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={2}>
              <div className="label-form-newnoinvoice">
    <Form.Label>From</Form.Label>
    </div>
    </Col>
    <Col lg={9}>
    <Form.Control as="select" type="email" defaultValue="ahsani1419@gmail.com">
    <option>ahsani1419@gmail.com</option>
    <option>Large select</option>
    <option>Large select</option>
  </Form.Control>
  </Col>
  <Col lg={1}>
  <i style={{padding:'20px 0'}} className="fa fa-question"></i>
  </Col>
    </Row>
  </Form.Group>




  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
      <Col lg={2}>
              <div className="label-form-newnoinvoice">
    <Form.Label>T0</Form.Label>
    </div>
    </Col>
    <Col lg={9}>
    <Form.Control type="email" placeholder="ceo@wowets.com">
  </Form.Control>
  </Col>
  <Col lg={1}>
      <img style={{padding:'20px 0'}} src="IMAGES/plus-circle.png"></img>
  </Col>
    </Row>
  </Form.Group>



  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={2}>
          <div className="label-form-newnoinvoice">
    <Form.Label>Subject</Form.Label>
    </div>
    </Col>
    <Col lg={9}>
    <div className="label-form-newnoinvoice-invoice11">
    <Form.Label>Invoice #11 from Legal Emirates</Form.Label>
    </div>
  </Col>
  <Col lg={1}>
  </Col>
    </Row>
  </Form.Group>



  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
      <Col lg={2}>
              <div className="label-form-newnoinvoice">
    <Form.Label>Message</Form.Label>
    </div>
    </Col>
    <Col lg={9}>
    <Form.Control type="email" placeholder="ceo@wowets.com">
  </Form.Control>
  </Col>
  <Col lg={1}>
  </Col>
    </Row>
  </Form.Group>

  </div>

  
</Form>
</Col>




</Row>



        </div>
        </div>

            </div>
          </Modal.Content>
          <Modal.Actions>
          <Button className="le-newinvoice-payment-voucher-edit-button"
         variant="outline-grey">
         Cancel
        </Button>

        <Button className="le-newinvoice-payment-voucher-edit-button"
         variant="outline-grey">
         Preview
        </Button>

        <Modal
          opentwo={opentwo}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          onClose={this.close}
          className="class-modal-1-invoice"
          trigger={
            <Button color="black" onClick={this.closeConfigShow(false, true)} 
            className="le-newinvoice-payment-voucher-edit-button">
            Sent
           </Button>
          }
          closeIcon
        >
          <Modal.Header>Send this inovice</Modal.Header>
          <Modal.Content>
            <div className="sent-invoice-tick-image-modal">
            <img src="IMAGES/tick-circle.png"></img>
            <p>This invoice is sent</p>

            </div>
            
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.close} style={{borderRadius:'15px'}}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        
          </Modal.Actions>
        </Modal>

        




       
      </div>


</div>

            </div>
</Col>

</Row>

</div>

</div>    










<div className="invoice-previe-div-body-le-small-box-invoicegetpaid">
<div className="invoice-contemplary-body-inside-small-box-invoicegetpaid">

<Row>
                        <Col lg={12} md={12} xl={12} >

<div className="invoice-one-border-box-invoicegetpaid">
<Media
                          as="li"
                          className="media-ride-list-invoice-small-box-invoicegetpaid"
                          
                        >
                          <div className="list-steps-of-ride-invoice-small-box-invoicegetpaid">
                          <div className="invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid">
                                <img src={require('../IMAGES/circle-credit.png')} />
                              </div>
                          </div>
                          <Media.Body>
                          <div className="contemporary-template__header__info-small-box-invoicegetpaid">
                          <div className="le-heading--title-small-box-invoicegetpaid">Get Paid
                           </div>
                          <strong className="le-text--strong-small-box-getpaid-invoicegetpaid">
                            Amount Due: AED1000 <br/>
                          </strong>
                          <strong className="le-text--strong-small-box-getpaid-invoicegetpaid">
                            Status: Partial Paid/Full Paid
                          </strong>
                            <div class="address__field"></div>
                            <div class="address__field"></div>
                          </div>

                          </Media.Body>
                        </Media>

                        <div className="send-invoice-button-of-newinvoice">
                        <Modal
          openthree={openthree}
          closeOnEscape={closeOnEscape}
          closeOnDimmerClick={closeOnDimmerClick}
          className="class-modal-1-invoice"
          trigger={
            <Button className="le-newinvoice-payment-voucher-edit-button" color="black">
            Record a payment
           </Button>
          }
          closeIcon
        >
          <Modal.Header>Record a payment</Modal.Header>
          <Modal.Content>
          <div>
                <div className="recordpayment-text-inside-modal-1">
                    <p>Record a payment you have already recieved, such as cash, cheque 
                      or bank payment
                    </p>
                </div>



                <div class="description_of_card_of_recordpayment">
 <div class="description_of_card_of_recordpayment_area">


<Row>
<Col xl={12} md={12} lg={12} xs={12}>


<Form>
<div className="forms-group-chequedetails">
  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
              <div className="label-form-newnoinvoice">
    <Form.Label>Payment Date</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="date" defaultValue="mm/dd/yy">
  </Form.Control>
  </Col>
    </Row>
  </Form.Group>




  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
      <Col lg={4}>
              <div className="label-form-newnoinvoice">
    <Form.Label>Amount</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="number" placeholder="AED 137.00">
  </Form.Control>
  </Col>
    </Row>
  </Form.Group>



  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-newnoinvoice">
    <Form.Label>Payment Method</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <div className="label-form-newnoinvoice-invoice11">
    <Form.Control as="select" type="text" defaultValue="Select a payment method">
    <option>Select a payment method...</option>
    <option>cash </option>
    <option>online</option>
  </Form.Control>
    </div>
  </Col>
    </Row>
  </Form.Group>



  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
      <Col lg={4}>
              <div className="label-form-newnoinvoice">
    <Form.Label>Memo / notes</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control as="textarea" rows="1" >
  </Form.Control>
  </Col>
    </Row>
  </Form.Group>

  </div>

  
</Form>
</Col>




</Row>



        </div>
        </div>

            </div>
          </Modal.Content>
          <Modal.Actions>
          <Button className="le-record-payment-voucher-edit-button"
         variant="outline-grey">
         Cancel
        </Button>
        <Button type="submit" className="le-record-payment-voucher-edit-button" color="black"
         variant="outline-grey">
         Submit
        </Button>
          </Modal.Actions>
        </Modal>


         </div>
            

            </div>
            {/* <div className="contemporary-template__divider--full-width">
                        <div className="le-divider-top-newinvoiceone"></div>
                      </div> */}

         
</Col>

</Row>

</div>

</div>    












                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>







        

        <center>
              <div className="addInvoiceTpArea-heading-left-type-print" >
             
                <ReactToPrint
       removeAfterPrint={true}
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return  <div style={{paddingLeft:'5px',paddingRight:'5px',fontSize:'20px'}}>  <Icon name="print" ></Icon> Print</div>;
          }}
          content={() => this.componentRef}
          /* bodyClass="printScreenddddddddddddddddddddd"  
          
       
        
          */
        />
                  </div>
              </center>


              {/* <LEnewnoinvoice    ref={el => (this.componentRef = el)} /> */}














      </div>

      
    );
  }
}



export default InvoiceTopAreaPreview;
// export default compose(
// withReducer("InvoiceTopAreaPreview1", reducer),
// connect(mapStateToProps),withRouter
// )(InvoiceTopAreaPreview); 

