import React, { Component } from "react";

import {
  Form,
  Card,
  Container,
  Button,
  Header,
  FormLabel,
  FormControl,
  Col
} from "react-bootstrap";

class Admin extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (
//       <div >
//       <Form>
//   <Form.Group controlId="formBasicEmail">
//     <Form.Label>Email address</Form.Label>
//     <Form.Control type="email" placeholder="Enter email" />
//     <Form.Text className="text-muted">
//       We'll never share your email with anyone else.
//     </Form.Text>
//   </Form.Group>

//   <Form.Group controlId="formBasicPassword">
//     <Form.Label>Password</Form.Label>
//     <Form.Control type="password" placeholder="Password" />
//   </Form.Group>
//   <Form.Group controlId="formBasicCheckbox">
//     <Form.Check type="checkbox" label="Check me out" />
//   </Form.Group>
//   <Button variant="primary" type="submit">
//     Submit
//   </Button>
// </Form>
//       </div>
<div className="sign-in-background-image">



  

  
    


<div className="Email-border-password-reset">
 <div className="email-border">
 

    
 

     <Container>

         <Container>
        


<p className="password-reset-text">SIGN IN</p>
<p className="welcome-wow">CREATE YOUR ACCOUNT TO GET STARTED</p>






<Form>
<Form.Group className="password-text-forms" controlId="formBasicEmail">



<div class="field">
   <div class="ui left icon input">
  {/* <i class="mail outline icon"></i> */}
  <img src="IMAGES/user.png" class="icon"></img>
  
    <input type="email" className="password-forms" name="email" placeholder="Full Name" autofocus="true"/>
  </div></div>

<Form.Label><h3 className="password-text-forms">Please enter a valid email address</h3></Form.Label>
</Form.Group>

<Form.Group className="password-text-forms" controlId="formBasicPassword">

<div class="field">
   <div class="ui left icon input">
  <i class="mail outline icon"></i>
    <input type="password" className="password-forms" name="password" placeholder="Password" autofocus="true"/>
  </div></div>
<Form.Label><h3 className="password-text-forms">Please enter a valid password</h3></Form.Label>

</Form.Group>




</Form>
{/* <div class="field user-name">
                            <input type="email" id="email" name="email_id" data-rule-required="true" placeholder="E-mail" autocomplete="off" required=""/>
                            <span class="validate-img"></span>
                        </div> */}





<button type="button" class="reset-buttons" style={{backgroundColor:'#1036fc'}}>SIGN IN</button>
<p className="sign-in-text-forms"> Forgot Your Password?</p>



<div className="password-reset-text">
<div className="cust-header-data-flex">


    <div className="admin-signin-link-button"><p ><a className="admin-signin-link-button-text" href="">Need an Account? </a></p>  </div>
  <div className="admin-signin-link-button-data"><p ><a className="admin-signin-link-button-data-text" href=""><u>Sign Up</u> </a></p> </div>  

  



    </div>

</div>

</Container>
</Container>
</div>
</div>





 

</div>

    );
  }
}
export default Admin;