import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Communityguideline extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString() };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }


  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        <div>
          <div class="carousel-community-guidline ">
            <ol class="carousel-indicators-community-guidline ">
              <li class="active-community-guidline"></li>
            </ol>
            <div class="carousel-inner-community-guidline slide-community-guidline">
              <div class="home-pageslider_top-community-guidline carousel-item-community-guidline active-carousel-inner-community-guidline carousel-item-community-guidline">
                <img
                  class="d-block-community-guidline w-100-community-guidline image-carousel-community-guidline"
                  src="IMAGES/city-images/wow-community/community-guideline/community-guidline-banner.png"
                  alt="First slide"
                />
                <div class="home_slider_text_area123456l-community-guidline carousel-caption-community-guidline">
                  <h3 class="about_fdkng12154-community-guidline">
                    WOW Holiday Community Guidelines.
                  </h3>
                  <p class="about_fdkng-community-guidline">
                    Our aim is for each WOW experience to be safe and
                    comfortable from begin to finish.
                  </p>
                </div>
              </div>
            </div>

            <a
              class="carousel-control-prev-community-guidline"
              role="button"
              href="#"
            >
              <span class="sr-only-community-guidline">Previous</span>
            </a>
            <a
              class="carousel-control-next-community-guidline"
              role="button"
              href="#"
            ></a>
          </div>
        </div>

        <Container>
          <center>
            <h3 className="wow_community_guidline">WOW Community Guide</h3>
            <div className="wow_community-guideline-heading-text">
              Wow provides resources to grow your community to monetize your
              ideas.
            </div>
          </center>
        </Container>

        <br />

        <Container>
          <div className="covid-new-top-area-third-area-contetn-community-guideline">
            <div className="covid-new-top-area-third-area-contetn">
              <p>
                That is why we require all our Travellers and Managers to uphold
                an excessive popular of conduct, and to observe the law — and
                our Community Guidelines. Failure to meet the expectations of
                the Community Guidelines can result in permanent deactivation
                from WOW.
              </p>
              <p>
                Many rides occur at the WOW platform each day. Safety incidents
                are rare, and by way of following those guidelines, you could
                assist make sure they are even less probably to show up. We are
                all in this together, and it is up to anybody to assist make WOW
                a safe network for all.
              </p>

              <h3>Be Harmless</h3>
              <p>
                However, you use WOW; you must hold the journey secure for
                yourself and others. That method following federal, local and
                UAE laws, and abiding by means of our guns and age rules.
              </p>

              <h3>Be Humble</h3>
              <p>
                WOW is built at the energy of a diverse and inclusive community.
                To hold the power of our network, treat anybody you meet via WOW
                with dignity and respect. Do now not do something to endanger,
                threaten, or harass any different man or woman you meet at the
                platform.
              </p>

              <h3>Be Supportive</h3>
              <p>
                Help shield and improve the WOW network by means of alerting us
                to any state of affairs that makes you sense uncomfortable. When
                reporting an incident to WOW, provide as many details as
                possible — the more facts we have, the faster we are able to
                cope with your concern. If you ever record an incident to 911,
                allow us to recognize so we are able to work with law
                enforcement and take suitable action.
              </p>

              <h3>Traveler rules:</h3>
              <p>
                Failure to abide by WOW’s Terms of Service or other guidelines
                can bring about temporary or everlasting deactivation from the
                WOW platform.
              </p>
              <p>To make certain a secure and respectful ride, do no longer:</p>

              <ul>
                <li>
                  Violate WOW’s weapons policy through bringing a weapon
                  alongside on a WOW experience or on WOW property
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, expedita labore dicta dolorem ducimus aperiam eos
                  laudantium iusto error! Tempore quod porro nam quos odit
                  excepturi! Incidunt error labore quidem!
                </li>

                <li>
                  {" "}
                  iusto error! Tempore quod porro nam quos odit excepturi!
                  Incidunt error labore quidem!
                </li>
                <li>
                  {" "}
                  iusto error! Tempore quod porro nam quos odit excepturi!
                  Incidunt error labore quidem!e
                </li>
                <li>
                  iusto error! Tempore quod porro nam quos odit excepturi!
                  Incidunt error labore quidem!uidelines
                </li>
                <li> Pay your fare in cash (cash pointers are OK)</li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, sex, marital status, gender identity, age, army
                  status, or any different feature protected below law
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,, or gestures
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,eat alcohol at some point of your ride
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,y
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,rney, coupon phishing, or manipulating opinions
                  for experience credit
                </li>
                <li>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, guidelines or WOW’s privacy policy and terms
                  &amp; conditions
                </li>
              </ul>

              <h3>Manager rules:</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellendus, different rules can bring about transient or
                permanent deactivation from the WOW platform.
              </p>

              <p>To make certain a safe and respectful ride, do not:</p>

              <ul>
                <li>Drive below the influence of medication or alcohol</li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, a weapon along on a WOW experience or on WOW
                  property
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,g, strolling red lights and prevent signs,
                  tailgating, and making lane modifications without proper
                  signaling
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, color, religion, country wide origin, disability,
                  sexual orientation, sex, marital status, gender
                  identification, age, military status, or any other function
                  protected below law
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus, that may properly and securely healthy for your
                  car’s trunk or backseat without obstructing the Leader’s view
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,t
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,havior, or gestures
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,'s recording device policy
                </li>
              </ul>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repellendus,precise standing, do no longer:
              </p>

              <ul>
                <li>
                  {" "}
                  Engage in fraudulent behavior, including but no longer
                  confined to: falsifying pickups, misrepresenting your
                  location, failing to quit the journey, price fraud, or
                  misrepresenting your identification to your profile or
                  submitted documents
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,he common of the last one hundred rides you
                  completed)
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,s
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,ng rides to riders
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,ommendations are OK)
                </li>
                <li>
                  {" "}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus,
                </li>
              </ul>
            </div>
          </div>
        </Container>

        {/* 
<Container>

              <h3 className="wow_community-our-people">Our People & Community</h3>
              <div className="wow_community-our-people-heading-text">
              We believe it’s the passion, energy and expertise of our local guides that truly sets
               Wow Holiday Tours and people apart. They’re our heroes and they get a huge buzz out of helping
                you discover all the highlights and hidden gems and in delivering a 100% authentic
              Travel experience. When you join a Wow Worldwode Tour you become part of the 
                 Wow(family).
              </div>

</Container>




<Container>
<div class="community-page-top-image">
  <img src="IMAGES/city-images/wow-community/top-image.jpg" style={{width: '100%'}}/>

  </div>
  </Container> */}
      </div>
    );
  }
}
export default Communityguideline;
