import React, { Component } from "react";


class Table extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

       

    
<div className="container">
  
    <div classname="header">
  <div className="flex-image-container">
    <div>
    <img src="IMAGES/download.png" className="image-logo" alt="logo" />
    </div>

  <div align="right"><img src="IMAGES/qrs.jpg" className="image-qrs" alt="logo" />
  </div>

  </div>
  </div>
    <div className="statement-header-line"> </div>

    


    
    <div classname="header">
  <div className="flex-statement-container">
    <div>
    <h1><b>VENDOR STATEMENT<br></br> P9-0004</b>
</h1>
    </div>

  <div align="right"><h4>STATEMENT DATE: JAN 20 2020<br></br> FROM: FEB 01 2020<br></br> TO: FEB 08 2020</h4>

  </div>
  </div>
  </div>

        <div class="infoCon">
     

      {/* <!-- row --> */}
      <div class="horizontalRows">
        <div class="one leader-text ">Company Name:</div>
        <div class="two leader-inside-text-statement">Ahdab International<br></br> Luxury Transport LLC
</div>
        <div class="three ">
          <div class="leader-text">Bank Account# <br></br></div>
          <div class="leader-inside-text-statement">37646151515151</div>
        </div>
      </div>

      {/* <!-- row end --> */}


      <div class="horizontalRows">
        <div class="one leader-text"><b>Company Address:</b></div>
        <div class="two leader-inside-text-statement">Dubai Downtown 2nd floor,<br></br> Dubai - UAE
</div>
        <div class="three">
          <div class="leader-text">Bank Name & Branch Code</div>
          <div class="leader-inside-text-statement">Emirates Islamic MEBLAEADRIQ </div>
        </div>
      </div>

      {/* <!-- row end --> */}

      <div class="horizontalRows">
        <div class="one leader-text-current">CURRENT BALANCE:</div>
        <div class="two "><p class="leader-inside-text-box-statement">AED 9,000.50</p></div>
        <div class="three">
          <div class="leader-text">IBAN NO #</div>
          <div class="leader-inside-text-statement">AE070331 2345 6789 0123 456
</div>
        </div>
      </div>
    </div>

        
        
       


          
 
        
 
  
     
 <table class="table table-top">
          <thead>
            <th>Leader <br />
                   ID</th>
            <th>Order <br />
                    Code</th>
            <th>Order <br />
                    Date Time</th>
            <th>Payment <br />
                    Mode</th>
            <th>Total <br />
                    Fare</th>
            <th>Fare <br />
                    Adjustment</th>
            <th>WOW <br />
                    Fee</th>   
            <th>TAX <br />
                    On Fee</th>  
                    <th>Amount <br />
                    Collected</th>    
                    <th>RTA  <br />
                    Fee</th>                      
            </thead>
             <tbody>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>

        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>

        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>

        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
        <tr>
            <td data-label="S.No">2020-01-23</td>
            <td data-label="Name">3uhf09234jhf92bj3232</td>
            <td data-label="Age">cash</td>
            <td data-label="Marks%">505.5</td>
            <td data-label="Staus">1.125</td>
            <td data-label="Staus">30</td>
            <td data-label="Staus">5</td>
            <td data-label="Staus">32.5543</td>
        </tr>
     </tbody>               

     <tfoot>
       <tr class="tfooter ttss">
                  <td><b>SUM</b></td>
                  <td><b>Total Trips</b></td>
                  <td></td>
                  <td><b>ALL TOTAL <br/> FARE</b></td>
                  <td><b>TOTAL <br/>FARE</b></td>
                  <td><b>TOTAL  <br/>ADJ. FEE</b></td>
                  <td><b>TOTAL<br/> WOW FEE</b></td>
                  <td><b>TOTAL TAX<br/> ON FEE</b></td>
                  <td><b>TOTAL AMOUNT<br/> COLLECTED</b></td>
                  <td><b>TOTAL TAX<br/> ON FEE</b></td>
                </tr>
                <tr class="tTotal">
                  <td class="td-text"></td>
                  <td className="td-text">20</td>
                  <td className="td-text"></td>
                  <td className="td-text">400</td>
                  <td className="td-text">11</td>
                  <td className="td-text">451</td>
                  <td className="td-text">451</td>
                  <td className="td-text">50</td>
                  <td className="td-text">2,086</td>
                  <td className="td-text">200</td>
                </tr>
     </tfoot>                

        </table>
        <br></br>
        
        <p><b style={{fontWeight:'bold'}}>NOTE:</b> <span class="note-section"> Report for 15 days. Computer generated document. No signature required.
</span></p>
        
 

      <div className="statement-header-line-base"> </div>

      

      


      
  {/* <div className="flex-container">
<table>
  <tbody>
    <td className="table-tds">
  <div class="itemCosn">
              <b>Regards,</b> <br />
              <b>
                Customer Service Division, <br />
                <p style={{color:'blue'}}>WOW electronic transport services.</p>
              </b>
            </div>
            </td>

            <td className="table-tds">
             <div class="itemCon">
             <div class="iconCon">
              <img className="footer-baseline-images" src="IMAGES/calllogo.png" alt=""/>
             </div>
             <span class="iconDesc">
               +9712233122
             </span>
            </div>  </td>
         
         <td className="table-tds">
            <div class="itemCon">
             <div class="iconCon">
              <img className="footer-baseline-images" src="IMAGES/browser.png" alt=""/>
             </div>
             <span class="iconDesc">
               www.wowets.com
             </span>
            </div>  </td>

            <td className="table-tds">
            <div class="itemCon">
             <div class="iconCon">
              <img className="footer-baseline-images" src="IMAGES/location.png" alt=""/>
             </div>
             <span class="iconDescs">
               WOW Electronic Transport Services, Aspin Commercial Tower, 48yh Floor, Sheikh Zayed Road, Dubai - UAE
             </span>
            </div> 
            </td>

            </tbody>
            </table>

           

 
  </div> */}

  <div class="vendor-footer-content">
  <div class="infoCons">
     

      {/* <!-- row --> */}
      <div class="horizontalRow">
        <div class="ones leader-texts ">
          <p class="regard-text"><strong>Regards</strong> <br />
              <b>
                Customer Service Division</b> <br />
                <p class="regards-footer-context">WOW electronic transport services</p>
              </p></div>
        <div class="twos leader-inside-texts"><div class="itemCons">
             <div class="iconCon">
              <img  class="footer-baseline-image" src="IMAGES/calllogo.png" alt=""/>
             </div>
             <span class="iconDescss">
               +9712233122
             </span>
            </div></div>
        <div class="threes leader-inside-texts"><div class="itemCons">
             <div class="iconCon">
              <img class="footer-baseline-image" src="IMAGES/browser.png" alt=""/>
             </div>
             <span class="iconDescss">
               www.wowets.com
             </span>
            </div></div>


        <div class="four ">
          <div class="leader-texts"><div class="itemCons">
             <div class="iconCon">
              <img class="footer-baseline-image" src="IMAGES/location.png" alt=""/>
             </div>
             <span class="iconDescsss">
               WOW Electronic Transport Services, Aspin Commercial Tower, 48yh Floor, Sheikh Zayed Road, Dubai - UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div>
  
    

    




    

       
        
       

    
   
   </div>

    


 
     
    
    
    );
  }
}
export default Table;