import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  // Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  Figure,
  View,
  Dropdown,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";
import { Table, Tag, Space } from "antd";
import { Collapse, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const columns = [
  {
    title: "Rating",
    dataIndex: "rating",
    key: "rating",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Review",
    dataIndex: "review",
    key: "review",
  },
];

const data = [
  {
    key: "1",
    rating: 8.3,
    review: "Overall value for money of your vehicle hire",
  },
  {
    key: "2",
    rating: 8.4,
    review: "Helpfulness of the counter staff",
  },
  {
    key: "3",
    rating: 8.5,
    review: "Time it took to collect your vehicle",
  },
  {
    key: "4",
    rating: 8.7,
    review: "Time it took to drop off your vehicle",
  },
  {
    key: "5",
    rating: 9.5,
    review: "Cleanliness of the vehicle",
  },
  {
    key: "6",
    rating: 8.0,
    review: "Overall condition of the vehicle",
  },
  {
    key: "7",
    rating: 9.0,
    review: "Ease of locating the Wiber rental desk or representative",
  },
];

class ImportantInfo_RentalCars extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false,
      size: "default",
      expandIconPosition: "right",
    };
  }

  render() {
    const { expandIconPosition } = this.state;
    return (
      <div>
        <div className="ImportantInfo-CollapseSection-RentalCarsBookingDetailsForm-Top-mainClass">
          <Collapse
            //   defaultActiveKey={['1']}
            onChange={callback}
            expandIconPosition={expandIconPosition}
          >
            <Panel header="Important Information" key="1">
              <div>{text}</div>
            </Panel>
            <Panel header="Included in the Rental Price" key="2">
              <div className="ImportantInfo-CollapseSection-RentalCarsBookingDetailsForm-mainClass">
                <div className="ImportantInfo-CollapseSection-2ndDivClass-RentalCarsBookingDetailsForm">
                  <h3>Insurance, Covers and Waivers</h3>
                  <h4>Collision Damage Waiver (CDW)</h4>
                  <p>
                    If the car’s bodywork gets damaged, the most you’ll pay
                    towards repairs covered by the Collision Damage Waiver is
                    the damage excess. This cover is only valid if you stick to
                    the terms of the rental agreement. It doesn’t cover other
                    parts of the car (e.g. glass, wheels, interior, roof or
                    undercarriage), or charges (e.g. for towing or off-road
                    time), or anything in the car (e.g. child seats, GPS devices
                    or personal belongings).
                  </p>

                  <h4>Third-Party Liability (TPL)</h4>
                  <p>
                    This covers the driver’s liability for injuries and property
                    damage which are covered by the policy. It does not cover
                    injuries to the driver or damage to the rental car. This
                    cover is only valid if you stick to the terms of the rental
                    agreement.
                  </p>

                  <h4>Theft Protection (TP)</h4>
                  <p>
                    If the car is stolen, the most you’ll pay towards
                    replacement costs covered by the policy is the theft excess.
                    This cover is only valid if you stick to the terms of the
                    rental agreement.
                  </p>

                  <h3>Included at no extra cost</h3>
                  <p>
                  Airport Surcharge
                  </p>

                  <p>
                  Local Taxes
                  </p>

                  <p>
                  Road Fee / Road Licensing Fee
                  </p>


                  <h4>Third-Party Liability (TPL)</h4>
                  <p>
                    This covers the driver’s liability for injuries and property
                    damage which are covered by the policy. It does not cover
                    injuries to the driver or damage to the rental car. This
                    cover is only valid if you stick to the terms of the rental
                    agreement.
                  </p>

                  <h4>Theft Protection (TP)</h4>
                  <p>
                    If the car is stolen, the most you’ll pay towards
                    replacement costs covered by the policy is the theft excess.
                    This cover is only valid if you stick to the terms of the
                    rental agreement.
                  </p>

                  <h3>Mileage / Kilometres</h3>
                  <p>
                  Your rental includes unlimited free kilometres.
                  </p>

                  <p>
                  Changing the length of your rental can affect how many kilometres you can drive for free. It can also affect how much you will pay for each additional kilometre.
                  </p>


                </div>
              </div>
            </Panel>
            <Panel header="This is panel header 3" key="3">
              <div>{text}</div>
            </Panel>
          </Collapse>
        </div>

        <br />
        <br />
        <div className="CustomerRevies-Section-aboveReviews-RentalCarsBookingDetailsForm-mainClass">
          <h3>Dubai International Airport</h3>

          <Row>
            <Col xl={6} lg={6}>
              <Table columns={columns} dataSource={data} />
            </Col>

            <Col xl={6} lg={6}>
              <div class="RentalCarsBookingDetailsForm_reviews_score2">
                <div className="RentalCarsBookingDetailsForm_reviews_score22">
                  <img
                    src="https://i.pinimg.com/280x280_RS/37/21/87/372187e972e2755d72e117b09ba47396.jpg"
                    class="RentalCarsBookingDetailsForm_supplier_logo2"
                    alt="WOW"
                  />
                  <div class="RentalCarsBookingDetailsForm_reviews_text2">
                    Excellent/ Overall
                  </div>
                  <div class="RentalCarsBookingDetailsForm_reviews_value2">
                    8.5<span>/10</span>
                  </div>
                  <div class="RentalCarsBookingDetailsForm_frequency2">
                    2000+ reviews
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default ImportantInfo_RentalCars;
