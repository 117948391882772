import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Blogpost1 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        {/* <Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-travel-work">
        <div  className="heading-secondary-section-wow-travel-work ">
        Coronavirus Tips If You Still Have to Travel Domestically

        <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2-newscard2"
            src="IMAGES/city-images/newsroom/card3-2.jpg"/>
        </div>
        </div>


</Container> */}

        <div className="td-post-header-holder-blogcard1 td-image-gradient-blogcard1">
          <div className="td-post-featured-image-blogcard1">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard1"
              src="IMAGES/city-images/wow-blog/additional-cards/blog1/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard1">
              <ul className="td-category-blogcard1">
                <li className="entry-category-blogcard1">
                  <a
                    target="blank"
                    href="https://www.thrillist.com/travel/nation/travel-hacking-tips-10-travel-hacks-that-ll-make-any-trip-easier"
                  >
                    Travel Hacks Tips
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard1">
              Going For A Vacation! But Don’t Know What To Pack?
              </h1>

              <h4 className="entry-title-two-blogcard1">
                Here Are Some Packing Hacks And Tips For You…
              </h4>

              <div className="td-module-meta-info-blogcard1">
                <span className="td-post-date-blogcard1">
                  <time
                    className="entry-date-blogcard1 updated td-module-date-blogcard1"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    May 29, 2020/Dubai
                  </time>
                </span>

                <div className="td-post-views-blogcard1">
                  <i className="fa fa-eye view-icon-views-blogcard1"></i>
                  <span className="td-nr-views-10968">12735</span>
                </div>
              </div>
            </header>
          </center>
        </div>














        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard1">
            <div className="heading-secondary-uk-section-wow-blogscard1 ">
            Travel Hacks & Tips
            </div>
          </div>
        </Container>


        <Container>
<p class="blogscard1-description-area-of-main--text-above">
            Traveling is fun. That’s just a fact. Another well-known fact is that travel is not always easy,
             smooth or convenient. Of course, chalking up all the mishaps as part of the fun is the best attitude
              to have, however avoiding them in the first place is probably better. To ensure that your travel 
              is as pain-free as possible, we’ve assembled the best travel hacks that ever existed. Whether 
              you’re looking for packing hacks,
             cheap flight hacks, airplane hacks, or just general travel life hacks, we have you covered.
             </p>

             <p class="blogscard1-description-area-of-main--text-above">
             If “travel more” is at the top of your to-do list for 2020 but you need a little help on the 
             planning front, you’ve come to the right place. Leading a jet-set lifestyle may sound complicated
              (and oh-so glamorous), but with just a few simple travel tips from the experts here at PlacePass, 
             you can most definitely make it a reality.
             </p>
</Container>
<br/>
        
<div className="gradient-back-travel-blogscard1">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard1-grid-text">
                  <div className="travel-blogscard1-page-araea-sub-headings110">
                  Plan ahead with skip-the-line tickets from PlacePass
                  </div>

                  <div className="travel-blogscard1-page-page-text">
                  If you’re traveling somewhere new, you don’t want to show up to your destination with
                   nothing planned and nowhere to go. Skip-the-line tickets can get you easy access
                    to some of the world’s top  destinations and will save you a ton of time from 
                    having to stand in long, tourist-filled lines.

 

                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/grid1.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-blogscard1">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/grid2.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard1-grid-text">
                  <div className="travel-blogscard1-page-araea-sub-headings110">
                  Invest in packing cubes
                  </div>

                  <div className="travel-blogscard1-page-page-text">
                  It’s not what you pack, it’s how you pack, and our travel hacks would be incomplete if
                   we didn’t mention packing cubes. Packing cubes let you separate your stuff 
                   into different categories, like airplane essentials,
                   toiletries, hotel necessities, and clothes, so you can find what your need quickly and easily.
                   Their expandable cubes comes in three different sizes—small, medium, 
                   and large—so you can squeeze as much as you need to into each one.
                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>














<Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard1">
            <div className="heading-secondary-uk-section-wow-blogscard1 ">
            Pro Travel Tips
            </div>
          </div>
        </Container>







<Container>
        <div class="description_of_card_of_blogscard1">
          <div class="description_of_card_of_blogscard1_area">
            <p class="blogscard1-description-area-of-main--text">
              Everyone loves traveling, vacations, tours, and trips. Whether
              going for a 2-3 days short tour or a whole week or months’
              vacation, one of the biggest hurdles apart from excitement is
              packing.
            </p>

            <p class="blogscard1-description-area-of-main--text">
            Traveling is fun. That’s just a fact. Another well-known fact is that travel is not always easy,
             smooth or convenient. Of course, chalking up all the mishaps as part of the fun is the best attitude
              to have, however avoiding them in the first place is probably better. To ensure that your travel 
              is as pain-free as possible, we’ve assembled the best travel hacks that ever existed. Whether 
              you’re looking for packing hacks,
             cheap flight hacks, airplane hacks, or just general travel life hacks, we have you covered.
             </p>


             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/travel-tips-grid1.jpg"/>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/travel-tips-grid2.jpg"/>
               </Col>
               
             </Row>

             
            <h2 class="blogscard1-description-area-of-main-headings">
              But why is packing so important for traveling?
            </h2>
            <p class="blogscard1-description-area-of-main--text">
              There’s no second opinion that packing is the most important and
              one of the toughest jobs to do before traveling. That’s because if
              you have packed the stuff you wanted to carry and wear or use
              during traveling then you will have a happy and relaxing vacation.
              Looking at the other side of the story where you haven’t packed
              good, essential, or complete stuff you needed for traveling you
              will face a lot of trouble during your whole journey.
              Packing light allows you to prepare your your belongings in less time, 
              both before you leave for the airport and when you check out of your room to go back home
              <br/><br/>
              Packing cubes are zippered fabric containers, usually in a rectangular shape, for organising 
              your items and making them easier to find in your luggage. They come in a range of sizes and are often 
              sold as sets. Unlike compression bags, you can use them to organise all kinds of gear, not just clothes.
            </p>


            <h2 class="blogscard1-description-area-of-main-headings">
              Can travel luggage affect your vacation?
            </h2>
            <p class="blogscard1-description-area-of-main--text">
              Yes, obviously. Imagine that you forgot packing good and suitable
              stuff for vacation, or forgot something very important to take
              with you. As far as I can imagine this situation, I won’t be able
              to enjoy and even get myself diverted towards happiness and
              relaxation of vacation for which I would be there.
            </p>
            <p class="blogscard1-description-area-of-main--text">
              To overcome these troubling situations, you should make a
              checklist for packing at least 2 days before travel. This way you
              can mark and check the list and you will be able to purchase and
              arrange the stuff which you don’t have, at the right time.
            </p>

            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard1">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/img1.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard1">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/img2.jpg" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>

            


            
            <h2 class="blogscard1-description-area-of-main-headings">
              Choosing the right stuff needs a few considerations:
            </h2>
            <p class="blogscard1-description-area-of-main--text">
              Al-Ain is a city of the inland oasis that is surrounded by the
              desert and natural waters. So there’s even very much to visit than
              your expectations. Some of the most famous and must-visit places
              are here:{" "}
            </p>
            <ul className="list-circle-type-blogscard">
              <li class="blogscard1-description-area-of-main--text">
                <span class="blogscard1-description-area-of-sub-headings">
                  {" "}
                  Firstly,{" "}
                </span>{" "}
                consider the purpose of your traveling. Either it’s a vacation,
                business tour, friend’s gathering plan, hiking plan, adventure
                trip, or whatever it is. Be clear about the purpose of your
                packaging.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                <span class="blogscard1-description-area-of-sub-headings">
                  {" "}
                  Secondly,{" "}
                </span>{" "}
                keep the stay limit in mind. How many days and nights you are
                going to spend on vacation or tour.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                <span class="blogscard1-description-area-of-sub-headings">
                  {" "}
                  Thirdly,{" "}
                </span>{" "}
                I guess one of the most important things, the weather. Be sure
                about the climate changes and weather forecast of the place
                where you are going to stay. Because it will help you pack the
                stuff according to the weather, either you need warm clothes for
                cold weather or light stuff for summers.
              </li>
            </ul>


            <h2 class="blogscard1-description-area-of-main-headings">
            Get to know your destination on a food tour.
            </h2>
            <p class="blogscard1-description-area-of-main--text">
            This travel hack is one of our top international travel tips. No matter where you go,
            there is no better way to get to know your destination than with a local food tour. You can
            learn a lot about the people and culture of a city through what they eat. Indulge your
             foodie side with:{" "}
            </p>

            <ul className="list-circle-type-blogscard">
              <li class="blogscard1-description-area-of-main--text">
              Follow sites with advice and keep a running list.
              </li>
              <li class="blogscard1-description-area-of-main--text">
              Ask the most well-traveled people you know. ...
              Pick an event to attend (not a destination)
              </li>
              <li class="blogscard1-description-area-of-main--text">
              Some food tours require a minimum of two people before they run. 
               This is especially important for solo travelers to be aware of.  I 
              have had several planned food tours cancelled on me the day before because I was the only one who’d booked.
              </li>

              <li class="blogscard1-description-area-of-main--text">
              Some food tours require a minimum of two people before they run. 
               This is especially important for solo travelers to be aware of.  I 
              have had several planned food tours cancelled on me the day before because I was the only one who’d booked.
              </li>
            </ul>


            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard1">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/img3.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard1">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog1/img4.png" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>













            <h2 class="blogscard1-description-area-of-main-headings">
              After considering all these things now comes the point ‘What Stuff
              you should pack?’
            </h2>


            <p class="blogscard1-description-area-of-main--text">
              That’s the main issue in packing which confuses a lot of people
              that they have a lot of amazing stuff which they wanted to carry
              with them but can’t decide what is more important to carry.{" "}
            </p>





            <h2 class="blogscard1-description-area-of-main-headings">
              Here are a few tricks and hacks to pack properly for your next
              vacation:
            </h2>
            <ul className="list-number-type-blogscard">
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Choose the bag or suitcase according to the days you are going
                to spend on vacations. For 2-3 days tour, a travel backpack is
                enough to carry all the necessities. For a week or month
                vacation, bring a suitcase with you.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Properly fold and compress the clothes to get the maximum space
                in the bag for packing the stuff.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Keep lightweight clothes and stuff in the top section of the
                bag, while heavy clothes or stuff like shoes, bottles, jackets
                at the bottom to balance the suitcase on wheels.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Pack the complete outfit pair a complete outfit and pack it as a
                complete look, otherwise, you will not find a suitable shirt
                with jeans while wearing it and a lot of things will remain
                unused, occupy the space, and you can get out of clothes.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                Don’t pack more than 2-3 outfits of the same color, your
                photographs or tour perks might need some variety of colors for
                a different look every day.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Don’t forget the First-Aid Kit. You might be injured or get sick
                during your vacations, so be prepared and carry a whole
                first-aid kit containing bandages, pain-killers (especially for
                headache, stomach pain, and seasonal cough &amp; flu), and
                anti-allergy or anti-itching creams.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Carry separate and lightweight laundry bags to keep your dirty
                clothes separately from the new and clean ones. Otherwise, your
                new and clean clothes will even start smelling.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Carry an extra outfit, even if you are going on a single day
                tour or business travel.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Don’t carry shampoo, soaps, conditioner, etc. with you while
                traveling. In case you are going to a place where it will be
                harder to buy such things then carry these essentials but in
                small packaging, otherwise, invest some money and purchase from
                the place you are staying. You can also get these essentials in
                a hotel room so better not to carry in a bag; these things will
                occupy the space only.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Choose a waterproof carry bag and suitcase to get your luggage
                safe from any weather conditions or mishaps.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Store jewelry and accessories in a separate pocket or pouch.
                Don’t pack in a heavy-weight bag or in some pouch which carries
                more space.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Don’t pack shoes in a box. It will occupy very much space. Pack
                them in a plastic bag, or shower caps.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Utilize space. Pack your garments in a pouch and place them
                inside shoes to maximize the space.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Carry a shawl or sarong with you. It will help you in many
                conditions such as to cover yourself in cold weather, to cover
                yourself while visiting any religious place, to protect you from
                a dirty atmosphere or mosquitos, etc.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Protect breakable things like perfumes, glass pieces, or
                pottery. Never hesitate to buy any good piece from tours but try
                to buy small things like memory and most importantly pack them
                properly in scarfs and clothes. Better to wrap them in
                sweatshirts or warm clothes as they will be safer there.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Avoid packing heavy clothes in a suitcase or bag, rather wear
                them while going. Wear your heaviest jackets, coats, or
                sweaters, and even long shoes, etc. This will let you carry more
                clothes and necessary stuff in a bag.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Carry a separate backpack to keep your essentials in hand and it
                will let you pick everything from it while traveling.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Pack your hands-free, headphones, charger, power bank, and small
                devices in a pouch and better to keep them in a backpack rather
                than in the suitcase.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Some people like to carry clothes in small pouches or pockets to
                separate everything. But I guess that carries more space in your
                suitcase. So keep jewelry, shoes, etc. in separate pouches or
                plastic bags but keep the clothes directly into a suitcase. Just
                keep them in an organized way so that it will be easier.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                {" "}
                Ohh! How could we forget the makeup? Obviously, girls can’t skip
                this thing, so try to carry a small pouch for makeup and keep
                products in small packaging.
              </li>
              <li class="blogscard1-description-area-of-main--text">
                21. Well, it’s good not to carry any hair tools with you as it
                carries a lot of space and is heavy-weight too. Besides this, a
                lot of hotels have hair tools in their rooms so confirm this
                before booking. But if they don’t have such things in rooms and
                it’s necessary for you, then try to carry a single product which
                comes in 3 in 1 (straightener, curler, crimper, etc.) or there
                are also such products in mini sizes now, you can also carry
                those.
              </li>
            </ul>
            <p class="blogscard1-description-area-of-main--text">
              I hope these hacks and tips will help you with your next tour.
              Plan and pack wisely, and you will notice having greater fun and
              enjoyment with a relaxing time. Another tip for traveling
              especially if you are going for a vacation to relax and enjoy away
              from your daily busy routines is to be yourself. You don’t really
              need too much to look good, just be yourself and enjoy the way you
              like. Wear whatever you are comfortable with after all vacation is
              all about relaxing so forget everything and adapt your
              comfort-zone only.
            </p>
            <div class="description_of_card_of_blogscard1_bottom_area">
              <div class="description_of_card_of_blogscard1_bottom_area_previous">
                <h3>
                  <i class="fas fa-reply" aria-hidden="true"></i>
                </h3>
                <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                <p>Previouse Article</p>
              </div>
              <div class="description_of_card_of_blogscard1_bottom_area_next">
                <h3>
                  <i class="fa fa-mail-forward" aria-hidden="true"></i>
                </h3>
                <h5>WOW IS FINALLY HERE!</h5>
                <p>Next Article</p>
              </div>
            </div>
          </div>
</div>




</Container>








































        












        {/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}
      </div>
    );
  }
}
export default Blogpost1;
