import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Visible, Hidden, setConfiguration } from "react-grid-system";
import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../TopcitiesTovisit/topCities.scss";

class TopCities extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
      show: true,
      showvideo: false,
      showHideTopThings: false,
      showHidetopcities: true,
      showHidetopActivites: false,
      showHidetopEvents: false,
      cars: [
        { name: "Audi", country: "Germany" },
        { name: "BMW", country: "Germany" },
        { name: "Chevrolet", country: "USA" },
        { name: "Citroen", country: "France" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Mercedes-Benz", country: "Germany" },
        { name: "Renault", country: "France" },
        { name: "Seat", country: "Spain" },
        { name: "Dodge", country: "USA" },
        { name: "BMW", country: "Germany" },
        { name: "Tesla", country: "USA" },
        { name: "Volkswagen", country: "Germany" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Jaguar", country: "United Kingdom" },
        { name: "GMC", country: "USA" },
        { name: "Bentley", country: "United Kingdom" },
      ],
      numberOfitemsShown: 1,
    };
    this.hideComponent = this.hideComponent.bind(this);
    this.showMore = this.showMore.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  // componentDidMount() {
  //   fetch(this.state.url).then(res => res.json())
  //   .then((res)=>{
  //     this.setState({items: res})
  //   })
  // }

  showMore = () => {
    if (this.state.numberOfitemsShown + 1 <= this.state.cars.length) {
      this.setState((state) => ({
        numberOfitemsShown: state.numberOfitemsShown + 1,
      }));
    } else {
      this.setState({ numberOfitemsShown: this.state.cars.length });
    }
  };

  hideComponent(name) {
    switch (name) {
      case "showHidetopcities":
        this.setState({
          // showHidetopcities: !this.state.showHidetopcities,
          showHidetopcities: true,
          showHideTopThings: false,
          showHidetopActivites: false,
          showHidetopEvents: false,
        });
        break;
      case "showHideTopThings":
        this.setState({
          // showHideTopThings: !this.state.showHideTopThings,
          showHideTopThings: true,
          showHidetopcities: false,
          showHidetopActivites: false,
          showHidetopEvents: false,
        });
        break;
      case "showHidetopActivites":
        this.setState({
          // showHidetopActivites: !this.state.showHidetopActivites,
          showHidetopActivites: true,
          showHideTopThings: false,
          showHidetopcities: false,
          showHidetopEvents: false,
        });
        break;
      case "showHidetopEvents":
        this.setState({
          // showHidetopEvents: !this.state.showHidetopEvents,
          showHidetopEvents: true,
          showHideTopThings: false,
          showHidetopcities: false,
          showHidetopActivites: false,
        });
        break;
      case "showHidetopthingsimg":
        this.setState({
          showHidetopthingsimg: !this.state.showHidetopthingsimg,
        });
      default:
      // null;
    }
  }

  Showimgtopthings = () => {
    document.getElementById("img-topthings1").style.display = "block";
    document.getElementById("img-topthings2").style.display = "block";
    document.getElementById("img-topthings3").style.display = "block";
    document.getElementById("img-topthings4").style.display = "block";
    document.getElementById("showimgtopthings-button1").style.display = "none";
    document.getElementById("showimgtopthings-button2").style.display = "block";
  };

  Showlessimgtopthings = () => {
    document.getElementById("img-topthings1").style.display = "none";
    document.getElementById("img-topthings2").style.display = "none";
    document.getElementById("img-topthings3").style.display = "none";
    document.getElementById("img-topthings4").style.display = "none";
    document.getElementById("showimgtopthings-button1").style.display = "block";
    document.getElementById("showimgtopthings-button2").style.display = "none";
  };

  render() {
    const scrollTotours = () => window.scrollTo({ top: 0 });
    const scrollTotopcities = () => window.scrollTo({ top: 0 });
    const {
      showHideTopThings,
      showHidetopthingsimg,
      showHidetopcities,
      showHidetopActivites,
      showHidetopEvents,
    } = this.state;
    const { showHideFName, showHideLName } = this.state;
    // const itemsToShow = this.state.cars
    //   .slice(0, this.state.numberOfitemsShown)
    //   .map(car => <Row>
    //     <th className={"margin-n-th"} key={car.name}>{car.name}</th>
    //     <th key={car.country}>{car.country}</th>
    //     <Col lg={3} xl={3} className="cols-section-image-thumb-section-topthings">
    //               <div className="image-thumb-section-topthings">
    //                 <Link className="link-class-img-top-things-to-do image-thumb-topthings">
    //                 <img
    //                 className="img-top-things-to-do"
    //                  src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg" />
    //                  </Link>
    //                   <h3>England</h3>
    //               </div>
    //             </Col>

    //             <Col lg={3} xl={3} className="cols-section-image-thumb-section-topthings">
    //               <div className="image-thumb-section-topthings">
    //                 <Link className="link-class-img-top-things-to-do image-thumb-topthings">
    //                 <img
    //                 className="img-top-things-to-do"
    //                  src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg" />
    //                  </Link>
    //                   <h3>Amsterdam</h3>
    //               </div>
    //             </Col>

    //             <Col lg={3} xl={3} className="cols-section-image-thumb-section-topthings">
    //               <div className="image-thumb-section-topthings">
    //                 <Link className="link-class-img-top-things-to-do image-thumb-topthings">
    //                 <img
    //                 className="img-top-things-to-do"
    //                  src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg" />
    //                  </Link>
    //                   <h3>England</h3>
    //               </div>
    //             </Col>

    //             <Col lg={3} xl={3} className="cols-section-image-thumb-section-topthings">
    //               <div className="image-thumb-section-topthings">
    //                 <Link className="link-class-img-top-things-to-do image-thumb-topthings">
    //                 <img
    //                 className="img-top-things-to-do"
    //                  src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg" />
    //                  </Link>
    //                   <h3>Amsterdam</h3>
    //               </div>
    //             </Col>
    //   </Row> );
    return (
      <div>
        <div className="td-post-header-holder-topcities-holiday td-image-gradient-topcities-holiday">
          <div className="td-post-featured-image-topcities-holiday">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-topcities-holiday"
              src="IMAGES/city-images/city-home-images/top-cities/wowFood.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-topcities-holiday">
              <ul className="td-category-topcities-holiday">
                <li className="entry-category-topcities-holiday">
                  <a
                    target="blank"
                    href="https://www.thebrokebackpacker.com/is-dubai-safe/"
                  >
                    Safe Travels
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-topcities-holiday">
                Wow: Recommended Places
              </h1>

              <h4 className="entry-title-two-topcities-holiday">
                WOW Recommended Top Cities, Places, Events & Things to do!
              </h4>

              <div className="td-module-meta-info-topcities-holiday">
                <span className="td-post-date-topcities-holiday">
                  <time
                    className="entry-date-topcities-holiday updated td-module-date-topcities-holiday"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    <i className="fa fa-calendar-check-o view-icon-views-topcities-holiday"></i>
                    25 JULY 2020 / Dubai
                  </time>
                </span>

                <div className="td-post-views-topcities-holiday">
                  <i className="fa fa-eye view-icon-views-topcities-holiday"></i>
                  <span className="td-nr-views-10968">1232239</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        {/* <div>
          {itemsToShow.length ? itemsToShow : "Loading..."}
        </div>
        <button onClick={this.showMore}>
          show more
        </button> */}

        <Container>
          <div className="button-group-section-topcities-holiday">
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              <Button
                onClick={() => this.hideComponent("showHidetopcities")}
                className={
                  "" + (this.state.showHidetopcities ? "selected-button" : "")
                }
              >
                Top Cities to Visit
              </Button>
              <Button
                onClick={() => this.hideComponent("showHideTopThings")}
                className={
                  "saaaada" +
                  (this.state.showHideTopThings ? "selected-button" : "")
                }
              >
                Top Things to Do
              </Button>
              <Button
                onClick={() => this.hideComponent("showHidetopActivites")}
                className={
                  "saaaada" +
                  (this.state.showHidetopActivites ? "selected-button" : "")
                }
              >
                Top Activities
              </Button>
              <Button
                onClick={() => this.hideComponent("showHidetopEvents")}
                className={
                  "saaaada" +
                  (this.state.showHidetopEvents ? "selected-button" : "")
                }
              >
                Top Events
              </Button>
            </ButtonGroup>
          </div>

          <div
            class="seo_link_maing_top_cities_section g_main_top_cities_section"
            style={{
              paddingTop: "28px",
              borderTop: "1px solid rgb(235, 235, 235)",
            }}
          >
            <div>
              {showHidetopcities && (
                <p class="seo_link_title">Top Cities to Visit</p>
              )}
              <div class="seo_link_content">
                {showHidetopcities && (
                  <Row>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/losangeles.jpg"
                          />
                        </Link>
                        <h3>Los Angeles</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/sanfransisco.jpg"
                          />
                        </Link>
                        <h3>San Fransisco</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/veronas.jpg"
                          />
                        </Link>
                        <h3>VERONA</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/bilbao.jpg"
                          />
                        </Link>
                        <h3>BILBAO</h3>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/hamburg.jpg"
                          />
                        </Link>
                        <h3>HAMBURG</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/madrid.jpg"
                          />
                        </Link>
                        <h3>MADRID</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/london.jpg"
                          />
                        </Link>
                        <h3>LONDON</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/venice.jpg"
                          />
                        </Link>
                        <h3>VENICE</h3>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                          />
                        </Link>
                        <h3>Abu Dhabi</h3>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg"
                          />
                        </Link>
                        <h3>AMSTERDAM</h3>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                          />
                        </Link>
                        <h3>BASEL</h3>
                      </div>
                    </Col>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                          />
                        </Link>
                        <h3>BERLIN</h3>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>

              {showHidetopcities && (
                <Row>
                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                        />
                      </Link>
                      <h3>England</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/dallas.jpg"
                        />
                      </Link>
                      <h3>Dallas</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                        />
                      </Link>
                      <h3>Basel Switzerland</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                        />
                      </Link>
                      <h3>Berlin Germany</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    id="img-topthings1"
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                        />
                      </Link>
                      <h3>England 22222</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings2"
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg"
                        />
                      </Link>
                      <h3>Amsterdam 2</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings3"
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                        />
                      </Link>
                      <h3>Basel Switzerland 2</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings4"
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                        />
                      </Link>
                      <h3>Berlin Germany 2</h3>
                    </div>
                  </Col>
                </Row>
              )}

              {showHidetopcities && (
                <div className="topthings-button">
                  <Button
                    aria-label="outlined primary button group"
                    variant="outlined"
                    color="dark"
                    onClick={() => this.hideComponent("showHidetopthingsimg")}
                  >
                    <i
                      className="fa fa-map-marker-alt"
                      style={{ marginRight: "1rem" }}
                    ></i>
                    Explore More Locations
                  </Button>
                </div>
              )}

              {/* {showHidetopcities && (
              <div class="seo_link seo_hide">
                <a href="#">Things To Do in San Diego</a>
                <a href="#">Things To Do in Chicago</a>
                <a href="#">Things To Do in Amsterdam</a>
                <a href="#">Things To Do in Boston</a>
                <a href="#">Things To Do in Las Vegas</a>
                <a href="#">Things To Do in Barcelona</a>
                <a href="#">Things To Do in Prague</a>
                <a href="#">Things To Do in Berlin</a>
                <a href="#">Things To Do in Rome</a>
                <a href="#">Things To Do in Budapest</a>
              </div>
              )} */}
              {/* {showHidetopcities && (
              <p class="seo_more_btn f_hidden-view">View more</p>
              )} */}
            </div>

            {/* show hite Top Thins to do ///////////////////////////////////////////////////////      */}

            <div>
              {showHideTopThings && (
                <p class="seo_link_title">Top Things to Do</p>
              )}

              <div class="seo_link_content">
                {/* {showHideTopThings && (
                  <div class="seo_link">
                    <a href="#">Las Vegas Shows</a>
                    <a href="#">Las Vegas Attractions</a>
                    <a href="#">Washington DC Tours</a>
                    <a href="#">Rome Attractions</a>
                    <a href="#">San Diego Attractions</a>
                    <a href="#">Shows in Chicago</a>
                    <a href="#">Chicago Attractions</a>
                    <a href="#">Chicago Tour</a>
                    <a href="#">Rome Tours</a>
                    <a href="#">Barcelona Attractions</a>
                    <a href="#">San Diego Zoo</a>
                    <a href="#">Zoo Berlin</a>
                    <a href="##">Amsterdam Museum</a>
                    <a href="#">Boston Aquarium</a>
                    <a href="#">Chicago Museums</a>
                    <a href="#">Concerts in Chicago</a>
                    {/* <a href="https://www.klook.com/city/45-jakarta-things-to-do/34-tag/">
                Jakarta Aquarium
              </a>
              <a href="https://www.klook.com/city/136-las-vegas-things-to-do/139-tag/">
                Las Vegas Concerts
              </a>
              <a href="https://www.klook.com/city/109-madrid-things-to-do/34-tag/">
                Zoo Madrid
              </a>
              <a href="https://www.klook.com/city/80-auckland-things-to-do/43-tag/">
                Auckland Transport
              </a>
                    {showHideTopThings && (
                      <a href="https://www.klook.com/city/45-jakarta-things-to-do/34-tag/">
                        Jakarta Aquarium
                      </a>
                    )}
                    {showHideTopThings && (
                      <a href="https://www.klook.com/city/136-las-vegas-things-to-do/139-tag/">
                        Las Vegas Concerts
                      </a>
                    )}
                    {showHideTopThings && (
                      <a href="https://www.klook.com/city/109-madrid-things-to-do/34-tag/">
                        Zoo Madrid
                      </a>
                    )}
                    {showHideTopThings && (
                      <a href="https://www.klook.com/city/80-auckland-things-to-do/43-tag/">
                        Auckland Transport
                      </a>
                    )}
                  </div>
                )} */}

                {showHideTopThings && (
                  <Row>
                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                          />
                        </Link>
                        <h3>Englandss</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg"
                          />
                        </Link>
                        <h3>Amsterdam</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                          />
                        </Link>
                        <h3>Basel Switzerland</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                          />
                        </Link>
                        <h3>Berlin Germany</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                          />
                        </Link>
                        <h3>Englandss</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg"
                          />
                        </Link>
                        <h3>Amsterdam</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                          />
                        </Link>
                        <h3>Basel Switzerland</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                          />
                        </Link>
                        <h3>Berlin Germany</h3>
                      </div>
                    </Col>

                    <Col
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      id="img-topthings1"
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                          />
                        </Link>
                        <h3>England 2</h3>
                      </div>
                    </Col>
                    <Col
                      id="img-topthings2"
                      lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                      xl={3}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg"
                          />
                        </Link>
                        <h3>Amsterdam 2</h3>
                      </div>
                    </Col>
                    <Col
                      id="img-topthings3"
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                          />
                        </Link>
                        <h3>Basel Switzerland 2</h3>
                      </div>
                    </Col>
                    <Col
                      id="img-topthings4"
                      lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                      className="cols-section-image-thumb-section-topthings"
                    >
                      <div className="image-thumb-section-topthings">
                        <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                          <img
                            className="img-top-things-to-do"
                            src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                          />
                        </Link>
                        <h3>Berlin Germany 2</h3>
                      </div>
                    </Col>
                  </Row>
                )}
                {/* {showHideTopThings && (
                  <p
                    class="seo_more_btn f_hidden"
                    onClick={() => this.hideComponent("showHideTopThings")}
                  >
                    View more
                  </p>
                )} */}
              </div>
            </div>

            {showHidetopActivites && (
              <p class="seo_link_title">Top Activities</p>
            )}
            {showHidetopActivites && (
              <div>
                {/* <div class="seo_link_content">
            
              <div class="seo_link">
                <a href="https://www.klook.com/activity/6698-san-diego-zoo-admission-ticket-los-angeles/">
                  San Diego Zoo
                </a>
                <a href="https://www.klook.com/activity/2956-empire-state-building-new-york/">
                  Empire State Building
                </a>
                <a href="https://www.klook.com/activity/4096-sea-world-san-diego-ticket-los-angeles/">
                  Seaworld San Diego
                </a>
                <a href="https://www.klook.com/activity/2964-top-of-the-rock-new-york/">
                  Top Of The Rock
                </a>
                <a href="https://www.klook.com/activity/4115-six-flags-magic-mountain-ticket-los-angeles/">
                  Six Flags Magic Mountain
                </a>
                <a href="https://www.klook.com/activity/4097-legoland-california-ticket-los-angeles/">
                  Legoland California
                </a>
                <a href="https://www.klook.com/activity/3588-neuschwanstein-linderhof-royal-castle-oberammergau-tour-munich/">
                  Neuschwanstein Castle
                </a>
                <a href="https://www.klook.com/activity/3969-disneyland-resort-paris/">
                  Disneyland Paris
                </a>
                <a href="https://www.klook.com/activity/12225-great-barrier-reef-cruise-scenic-helicopter-ride-cairns/">
                  Great Barrier Reef
                </a>
                <a href="https://www.klook.com/activity/6455-angkor-temples-sunrise-tour-siem-reap/">
                  Angkor Wat
                </a>
                <a href="https://www.klook.com/activity/2958-metropolitan-museum-art-new-york/">
                  Met Museum
                </a>
                <a href="https://www.klook.com/activity/13691-san-francisco-zoo-gardens-admission-ticket-san-francisco/">
                  San Francisco Zoo
                </a>
                <a href="https://www.klook.com/activity/3315-mont-saint-michel-full-day-tour-paris/">
                  Mont Saint Michel
                </a>
                <a href="https://www.klook.com/activity/18333-citypass-chicago/">
                  Chicago City Pass
                </a>
                <a href="https://www.klook.com/activity/10320-las-vegas-high-roller-admission-ticket-las-vegas/">
                  High Roller Las Vegas
                </a>
                <a href="https://www.klook.com/activity/4333-shanghai-tower-observation-deck-shanghai/">
                  Shanghai Tower
                </a>
                <a href="https://www.klook.com/activity/6525-san-diego-uss-midway-museum-los-angeles/">
                  Uss Midway Museum
                </a>
                <a
                  id="things-to-do"
                  href="https://www.klook.com/activity/13995-spyscape-spy-museum-admission-ticket-new-york/"
                >
                  Spyscape
                </a>
                <a
                  id="things-to-do"
                  href="https://www.klook.com/activity/8355-grand-canyon-south-rim-tour-las-vegas/"
                >
                  Grand Canyon Tours
                </a>
                <a
                  id="things-to-do"
                  href="https://www.klook.com/activity/12005-le-reve-the-dream-ticket-las-vegas/"
                >
                  Le Reve
                </a>
              </div>
)}
{showHidetopActivites && (  */}

                <Row>
                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/san-diego.jpg"
                        />
                      </Link>
                      <h3>San Diego Zoo</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/toprock.jpg"
                        />
                      </Link>
                      <h3>Top of the Rock</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/seaworld.jpg"
                        />
                      </Link>
                      <h3>SeaWorld San Diego</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/empirestate.jpg"
                        />
                      </Link>
                      <h3>Empire State Building</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                    xl={3}
                    id="img-topthings1"
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/sixflags.jpg"
                        />
                      </Link>
                      <h3>Six Flags Magic Mountain</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings2"
                    lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                    xl={3}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/legoland.jpg"
                        />
                      </Link>
                      <h3>Legoland California</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings3"
                    lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                    xl={3}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/citypass.jpg"
                        />
                      </Link>
                      <h3>Chicago CityPASS</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings4"
                    lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                    xl={3}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/sanfranciscozoo.jpg"
                        />
                      </Link>
                      <h3>San Francisco Zoo</h3>
                    </div>
                  </Col>

                  <Col
                    lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                    xl={3}
                    id="img-topthings1"
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/sixflags.jpg"
                        />
                      </Link>
                      <h3>Six Flags Magic Mountain</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings2"
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/legoland.jpg"
                        />
                      </Link>
                      <h3>Legoland California</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings3"
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-activities/citypass.jpg"
                        />
                      </Link>
                      <h3>Chicago CityPASS</h3>
                    </div>
                  </Col>
                  <Col
                    id="img-topthings4"
                    lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                    className="cols-section-image-thumb-section-topthings"
                  >
                    <div className="image-thumb-section-topthings">
                      <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                        <img
                          className="img-top-things-to-do"
                          src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                        />
                      </Link>
                      <h3>Berlin Germany 2</h3>
                    </div>
                  </Col>
                </Row>
                <p class="seo_more_btn f_hidden">View more</p>
              </div>
            )}

            {/* show hide top events /////////////////////////////////////////////////////////    */}

            {showHidetopEvents && <p class="seo_link_title">Top Events</p>}
            {/* {showHidetopEvents && (
            <div class="seo_link_content">
              <div class="seo_link seo_hide">
                <a href="https://www.klook.com/europe-rail/">
                  Europe Train Tickets
                </a>
                <a href="https://www.klook.com/china-train-ticket/">
                  China Train Tickets
                </a>
                <a href="https://www.klook.com/airport-transfers/">
                  Airport Transfers
                </a>
                <a href="https://www.klook.com/japan-rail-jr-pass/">
                  Japan Rail Pass
                </a>
                <a href="https://www.klook.com/city/393-taiwan-rail-things-to-do/">
                  Taiwan Rail
                </a>
                <a href="https://www.klook.com/city/467-vietnam-rail-things-to-do/">
                  Vietnam Rail
                </a>
                <a href="https://www.klook.com/hotels/">Hotel</a>
                <a href="https://www.klook.com/wifi-sim-card/">
                  WiFi &amp; SIM Card
                </a>
              </div>
              </div>
)} */}
            {showHidetopEvents && (
              <Row>
                <Col
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/carnival.jpg"
                      />
                    </Link>
                    <h3>Rio Carnival</h3>
                  </div>
                </Col>

                <Col
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/chinese.jpg"
                      />
                    </Link>
                    <h3>Chinese New Year</h3>
                  </div>
                </Col>

                <Col
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/queensday.jpg"
                      />
                    </Link>
                    <h3>Queen's Day</h3>
                  </div>
                </Col>

                <Col
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/hogmanay.jpg"
                      />
                    </Link>
                    <h3>Hogmanay</h3>
                  </div>
                </Col>

                <Col
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  id="img-topthings1"
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/superbowl.jpg"
                      />
                    </Link>
                    <h3>Super Bowl</h3>
                  </div>
                </Col>
                <Col
                  id="img-topthings2"
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/venicecarnival.jpg"
                      />
                    </Link>
                    <h3>Carnival</h3>
                  </div>
                </Col>
                <Col
                  id="img-topthings3"
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/canadiannationalnxhibition.jpg"
                      />
                    </Link>
                    <h3>Canadian National Exhibition</h3>
                  </div>
                </Col>
                <Col
                  id="img-topthings4"
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-events/worldcup.jpg"
                      />
                    </Link>
                    <h3>World Cup</h3>
                  </div>
                </Col>

                <Col
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  id="img-topthings1"
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-cities-visited/abu-dhabi.jpg"
                      />
                    </Link>
                    <h3>England 2</h3>
                  </div>
                </Col>
                <Col
                  id="img-topthings2"
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-cities-visited/amsterdam.jpg"
                      />
                    </Link>
                    <h3>Amsterdam 2</h3>
                  </div>
                </Col>
                <Col
                  id="img-topthings3"
                  lg={3}
                      xl={3}
                      md={3}
                      sm={6}
                      xs={6}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-cities-visited/basel.jpg"
                      />
                    </Link>
                    <h3>Basel Switzerland 2</h3>
                  </div>
                </Col>
                <Col
                  id="img-topthings4"
                  lg={3}
                      md={3}
                      sm={6}
                      xs={6}
                  xl={3}
                  className="cols-section-image-thumb-section-topthings"
                >
                  <div className="image-thumb-section-topthings">
                    <Link className="link-class-img-top-things-to-do image-thumb-topthings">
                      <img
                        className="img-top-things-to-do"
                        src="IMAGES/city-images/holiday-cards/top-cities-visited/berlin.jpg"
                      />
                    </Link>
                    <h3>Berlin Germany 2</h3>
                  </div>
                </Col>
              </Row>
            )}
            {showHidetopEvents && (
              <p class="seo_more_btn f_hidden">View more</p>
            )}
          </div>
        </Container>
        <br />
      </div>
    );
  }
}
export default TopCities;
