import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  
  Col
} from "react-bootstrap";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};




class News extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date
    });
  }
  render() {
    return (


<Container>

    
<center>
    <h6 className="news-header-text">
        RECENT NEWS</h6>
        </center>
        <br></br>

        



<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={500}
  keyBoardControl={true}
  customTransition="all 1s linear"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  pause={true}

>
  
  <div>
  <div className="card" >
  <img className="card-img-top" src="IMAGES/carouseln1.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <a href="#" className="carousel-inside-link-card">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  </div>




  <div>
  <div className="card" >
  <img className="card-img-top" src="IMAGES/carouseln2.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY</p>
    <a href="#" className="carousel-inside-link-card">Read Full News></a>
   
    
    <footer>
     <div class="date-card">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  </div>



  <div>

  <div className="card" >
  <img className="card-img-top" src="IMAGES/carouseln3.jpg" alt="Card image cap"/>
  <div className="card-body">
  <h5 className="card-title">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY</p>
    <a href="#" className="carousel-inside-link-card">Read Full News></a>
    
    
    <footer>
     <div class="date-card">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  </div>

  <div>
  <div className="card" >
  <img className="card-img-top" src="IMAGES/carouseln1.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <a href="#" className="carousel-inside-link-card">Read Full News></a>
   
  
    <footer>
     <div class="date-card">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  </div>



  <div>
  <div className="card" >
  <img className="card-img-top" src="IMAGES/carouseln2.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY</p>
    <a href="#" className="carousel-inside-link-card">Read Full News></a>
    
    
    <footer>
     <div class="date-card">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  </div>



  <div>

  <div className="card" >
  <img className="card-img-top" src="IMAGES/carouseln3.jpg" alt="Card image cap"/>
  <div className="card-body">
  <h5 className="card-title">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY</p>
    <a href="#" className="carousel-inside-link-card">Read Full News></a>
   
    <footer>
     <div class="date-card">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  </div>




  
</Carousel>;

<center>
    <h6 className="news-header-text">
        //////////////////////</h6>
        </center>



        {/* <div className="card-border">
  <div className="card-for-practice" >
  <a>

  <img className="card-img-top-for-practice" src="IMAGES/carouseln1.jpg" alt="Card image cap"/>
  <div className="card-body">
    
<li>
    <p className="card-text-for-practice">Melbourne Star Observation Wheel Ticket (E-Ticket Direct Entry)</p>

    




    </li> 
    <li class="t12-wow t_gray star_box">
                                            <span class="">
                                                <span class="m_star_v2">
                                                <i class="fas fa-star"></i>
                                                    <span class="t14 star_score">&nbsp;4.6  &nbsp;</span>
                                                </span>
                                                <span class="u_t_gray_9-wow">(224 reviews)</span>
                                            </span><span className="black-wow"><b> |</b> </span>
                                            <span class="u_t_gray_9-wow">3K+ Booked</span>
                                        </li>






                                        <li className="price">
                                            <p className="del_price u_t_gray_9-wow">
                                                <del><span>US$</span> 23.55</del>
                                                &nbsp;
                                            </p>
                                            <p>
                                                <span className="latest_price"><b>
                                                        US$ 23.25 &nbsp;
                                                                        </b></span>
                                                <span className="klook-fast_box">
                                                    <span className="abtest5030 t12" style={{display: 'none'}}>Instant Confirmation</span>
                                                    <i className="t14 fas fa-bolt t_main " ></i>
                                                    
                                                </span>
                            
                                                <!-- 目前有些接口对于hotel还没返回业务字段hotel_end_time  --> <br/>
                                                  <span className="g_right j_card_date notice" data-serverdate="2020-03-03 00:00:00">Available Today</span>
                                            </p>
                                        </li>

                                        









    
    
  
    <footer>
     <div class="date-card-for-practice">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>

  </a>
</div>

  </div> */}

  

  <center>
    <h6 className="news-header-text">
        //////////////////////</h6>
        </center>
        
        


        










{/* <i class="prefer-wow t_main-wow j_perfer-tag-wow">
                                                    <svg class="wow-symbol preferred-wow_tag_icon_bg">
                                                        <use href="#icon-klook-preferred-badge"></use>
                                                        <svg id="icon-klook-preferred-badge" viewBox="0 0 52 40">
                                                          #shadow-root (closed) == $0
                                                          <g fill="none" fill-rule="evenodd">
                                                            <path fill="#FFF" d="M26.137 0c8.745 0 14.888 5.529 18.192 12.154 1.084 2.174 4.245 4.043 7.671 4.043v3.74l-23.781.053-.003.01L26 19.995 23.784 20l-.004-.01L0 19.938v-3.74c3.426 0 6.587-1.87 7.671-4.044C10.975 5.53 17.118 0 25.863 0l.138.001.136-.001z">
                                                              </path>
                                                              <path fill="#FF9200" d="M37.891 13.67a3.741 3.741 0 0 0-5.291 0l-6.588 6.588 2.646 2.645 9.233-9.233z">
                                                                </path>
                                                                <path fill="#FF5722" d="M24.632 18.877a3.741 3.741 0 0 0-5.292 0l4.026 4.026 2.646-2.645-1.38-1.38z">
                                                                  </path>
                                                                  <path fill="#F14214" d="M26.012 20.258l-2.646 2.645 2.646 2.646 2.646-2.646z">
                                                                    </path>
                                                                    <path fill="#FF9200" d="M17.034 28.492V38.8l9.082-2.04 8.96 2.04V28.492a13.006 13.006 0 0 1-9.022 3.624 13 13 0 0 1-9.02-3.624">
                                                                      </path>
                                                                      <path fill="#FF9200" d="M26.054 8.828c2.207 0 4.248.7 5.919 1.887.129.092.209.15.372.15.164 0 .258-.055.39-.141.669-.439 1.442-.716 2.442-.575.013.002.162.025.214-.026.052-.051.067-.117-.052-.237a13.013 13.013 0 0 0-9.285-3.88C18.844 6.007 13 11.853 13 19.062c0 3.71 1.55 7.055 4.034 9.432v-4.598a10.187 10.187 0 0 1-1.213-4.834c0-5.651 4.582-10.233 10.233-10.233z"></path>
                                                                      <path fill="#F14214" d="M38.871 16.588c-.029-.148-.193-.15-.243-.15-.05 0-.167.026-.326.184l-1.798 1.798a.707.707 0 0 0-.177.241c-.04.098-.04.261-.04.4 0 1.749-.439 3.395-1.212 4.835v4.596a13.013 13.013 0 0 0 3.796-11.904z"></path>
                                                                      <path fill="#FF5722" d="M26.054 29.295a10.23 10.23 0 0 1-9.02-5.4v4.598a13.006 13.006 0 0 0 9.02 3.623c3.5 0 6.677-1.38 9.021-3.624v-4.596a10.23 10.23 0 0 1-9.02 5.399"></path>
                                                                      </g>
                                                                      </svg>
                                                    </svg>
                                                </i> */}
    {/* <a href="#" className="carousel-inside-link-card-for-practice">Read Full News></a> */}







</Container>





 



    );
  }
}
export default News;












