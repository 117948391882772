import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { FaBeer } from "react-icons/fa";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  Button,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost10/blogpost10.scss";

class Blogpost10 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
    };
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  myFunction() {
    var x = document.getElementById("mySelect").value;
    document.getElementById("demo").innerHTML = "You selected: " + x;
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    return (
      <div>
        <div className="td-post-header-holder-blogcard10 td-image-gradient-blogcard10">
          <div className="td-post-featured-image-blogcard10">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard10"
              src="IMAGES/city-images/wow-blog/additional-cards/blog10/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard10">
              <ul className="td-category-blogcard10">
                <li className="entry-category-blogcard10">
                  <a target="blank" href="https://en.wikipedia.org/wiki/Museum">
                    Museum
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard10">Wow: News & Trends</h1>

              <h4 className="entry-title-two-blogcard10">
                Virtual History & Museum Tour: experience this joy through
                virtual history tours
              </h4>

              <div className="td-module-meta-info-blogcard10">
                <span className="td-post-date-blogcard10">
                  <time
                    className="entry-date-blogcard10 updated td-module-date-blogcard10"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    29 December 2019
                  </time>
                </span>

                <div className="td-post-views-blogcard10">
                  <i className="fa fa-eye view-icon-views-blogcard10"></i>
                  <span className="td-nr-views-10968">4239</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard10">
            <div className="heading-secondary-uk-section-wow-blogscard10 ">
              WOW News & Trends
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard10-description-area-of-main--text-above">
            If walking around ancient ruins and perusing historical artifacts
            are your favorite part of travel, you can still experience this joy
            through virtual history tours. Journey to Pompeii via Zoom with a
            live guide. Then, view the online collection of the National Museum
            of African American History and Culture. You don’t need a flux
            capacitor to travel back to the past with a virtual history tour.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard10-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog10/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard10-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog10/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard10-description-area-of-main--text-above">
            There are many types of museums, including art museums, natural
            history museums, science museums, war museums, and children's
            museums. Amongst the world's largest and most visited museums are
            the Louvre in Paris, the National Museum of China in Beijing, the
            Smithsonian Institution in Washington, D.C., the British Museum and
            National Gallery in London, the Metropolitan Museum of Art in New
            York City and Vatican Museums in Vatican City. According to the
            International Council of Museums, there are more than 55,000 museums
            in 202 countries.
          </p>

          <p class="blogscard10-description-area-of-main--text-above">
            Early museums began as the private collections of wealthy
            individuals, families or institutions of art and rare or curious
            natural objects and artifacts. These were often displayed in
            so-called wonder rooms or cabinets of curiosities. One of the oldest
            museums known is Ennigaldi-Nanna's museum, built by Princess
            Ennigaldi at the end of the Neo-Babylonian Empire. The site dates
            from c. 530 BCE, and contained artifacts from earlier Mesopotamian
            civilizations. Notably, a clay drum label—written in three
            languages—was found at the site, referencing the history and
            discovery of a museum item.
          </p>
        </Container>
        <br />

        <div className="gradient-back-travel-blogscard10">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard10-grid-text">
                  <div className="travel-blogscard10-page-araea-sub-headings110">
                    Fall of Rome Live Virtual History Tour
                  </div>

                  <div className="travel-blogscard10-page-page-text">
                    What factors ended the more than 500-year reign of the Roman
                    Empire? Connect with an expert historian in Rome through
                    Zoom and be transported back in time to the fall of the
                    Roman Empire. This live virtual history tour of Rome weaves
                    together research with engaging stories, maps, and pictures
                    to bring history to life. Also, find out why scholars think
                    climate change could have played a role in the decline of
                    the Roman Empire.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog10/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard10">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog10/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard10-grid-text">
                  <div className="travel-blogscard10-page-araea-sub-headings110">
                    British Museum, London
                  </div>

                  <div className="travel-blogscard10-page-page-text">
                    The British Museum is the OG national museum. When it opened
                    in 1759 it was the first of its kind to open to the public
                    in the whole world, and they’re still showing us how it’s
                    done today. The graphics on this tour are crazy; think an
                    intergalactic guitar fretboard. Tap through a musical guide
                    to Africa, the Americas, Asia, Oceania and Europe – and play
                    a little tune along the way. F
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard10">
            <div className="heading-secondary-help-community-section-wow-blogscard10 ">
              Virtual Museum Travel Tours
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard10">
            <div class="description_of_card_of_blogscard10_area">
              <h2 class="blogscard10-description-area-of-main-headings">
                National Women’s History Museum …
              </h2>
              <p class="blogscard10-description-area-of-main--text">
                From the high-achieving women of NASA to the rough-and-tumble
                feminism of roller derby, the National Women’s History Museum
                celebrates the contributions of women to US history. The museum
                doesn’t yet have a physical location, so you can find all their
                exhibits online. Photographs, videos, and firsthand accounts
                bring these extraordinary stories to life. Tune in to the past
                by listening to oral histories by women who worked in Rosie the
                Riveter roles during WWII and see photographs of women marching
                for civil rights.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog10/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog10/help-tips-grid2.jpg" />
                </Col>
              </Row>

              <h2 class="blogscard10-description-area-of-main-headings">
                Ancient Troy Self-Guided Virtual Experience....
              </h2>
              <p class="blogscard10-description-area-of-main--text">
                You probably know the story of the Trojan horse—now take a
                virtual trip to see where it happened. This immersive virtual
                tour of ancient Troy lets you wander the city at your own pace
                with the help of satellite and street views. As you explore this
                ancient metropolis, insightful audio commentary provides
                historical facts and lesser-known stories. Gain a deeper
                understanding of the history of Troy and feel like you’re
                standing in the old stone city without ever having to leave your
                living room.
              </p>

              <h2 class="blogscard10-description-area-of-main-headings">
                Solomon R. Guggenheim Museum, New York....
              </h2>
              <p class="blogscard10-description-area-of-main--text">
                Thousands visit the Gugg every day just to explore its epic
                Frank Lloyd Wright-designed building, and thanks to this Google
                Street View tour you can still wander its halls from your couch.
                Peruse the museum’s most significant offerings of postmodern,
                conceptual and installation art, then head to the homepage for a
                bumper database of its entire collection.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard10">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog10/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard10">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog10/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 className="blogscard10-description-area-of-main-headings">
                Musée d’Orsay, Paris...
              </h2>
              <p className="blogscard10-description-area-of-main--text">
                Fancy a trip to Paris? Oui oui! This grand museum holds the
                largest collection of Impressionist and post-Impressionist works
                in the world, and you can click your way through the very best
                among them thanks to interactive galleries featuring van Gogh,
                Cézanne, Degas and more.
                <br />
                The Musée d'Orsay is a museum in Paris, France, on the Left Bank
                of the Seine. It is housed in the former Gare d'Orsay, a
                Beaux-Arts railway station built between 1898 and 1900. The
                museum holds mainly French art dating from 1848 to 1914,
                including paintings, sculptures, furniture, and photography.
              </p>

              <h2 className="blogscard10-description-area-of-main-headings">
                Museum of Broken Relationships, Zagreb and Los Angeles.....
              </h2>
              <p className="blogscard10-description-area-of-main--text">
                This could be a touchy subject for quarantining couples, but the
                Museum of Broken Relationships takes a nostalgic look at old
                flames – and it’s really quite beautiful. Each item on display
                represents the donor’s ex, and the stories behind them range
                from uplifting to heartbreaking. Who knew an old toaster could
                be so poignant?
              </p>

              <ul className="list-circle-type-blogscard">
                <li
                  class="blogscard10-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <p>
                    Museum of Broken Relationships is a physical and virtual
                    public space created with the sole purpose of treasuring and
                    sharing your heartbreak stories and symbolic possessions. It
                    is a museum about you, about us, about the ways we love and
                    lose.{" "}
                  </p>
                </li>

                <li class="blogscard10-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>
                    Museum of Broken Relationships:{" "}
                  </span>
                  is an original creative art project conceived by Olinka
                  Vištica and Dražen Grubišić in 2006. It has since taken
                  thousands of people on an empathetic journey around the world,
                  challenging our ideas about heritage. Its original permanent
                  location was founded in Zagreb. In 2010 it won the EMYA
                  Kenneth Hudson Award as the most innovative and daring museum
                  project in Europe.
                </li>
              </ul>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard10">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog10/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard10">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog10/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard10-description-area-of-main-headings">
                National Museum of African American History and Culture...
              </h2>

              <p class="blogscard10-description-area-of-main--text">
                The National Museum of African American History and Culture
                (NMAAHC) is dedicated to documenting African American life,
                history, and culture and making it accessible to the public. In
                this spirit, the newest Smithsonian museum welcomes you to
                explore its collection and exhibits—even if you can’t visit in
                person. Delve into a variety of online exhibits on topics like
                slavery and freedom, the African American military experience,
                and the history of blues, jazz, and hip-hop. You can also listen
                to oral histories, view historical artifacts, and learn about
                the museum through Curator Chats videos.{" "}
              </p>

              <div class="description_of_card_of_blogscard10_bottom_area">
                <div class="description_of_card_of_blogscard10_bottom_area_previous">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fas fa-reply" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard10_bottom_area_next">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fa fa-mail-forward" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost10;
