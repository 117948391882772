import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import '../Wowholidayblogpost4/blogpost4.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Blogpost4 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>



        <div className="td-post-header-holder-blogcard4 td-image-gradient-blogcard4">
          <div className="td-post-featured-image-blogcard4">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard4"
              src="IMAGES/city-images/wow-blog/additional-cards/blog4/bg.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard4">
              <ul className="td-category-blogcard4">
                <li className="entry-category-blogcard4">
                  <a
                    target="blank"
                    href="https://www.valuepenguin.com/2016/best-cities-photographers"
                  >
                    Best Cities Photographers
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard4">
              WOW Events & Stories Blogs
              </h1>

              <h4 className="entry-title-two-blogcard4">
              Looking out for the Best travel cities & places best for photography...
              </h4>

              <div className="td-module-meta-info-blogcard4">
                <span className="td-post-date-blogcard4">
                  <time
                    className="entry-date-blogcard4 updated td-module-date-blogcard4"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                   June 11, 2020
                  </time>
                </span>

                <div className="td-post-views-blogcard4">
                  <i className="fa fa-eye view-icon-views-blogcard4"></i>
                  <span className="td-nr-views-10968">5035</span>
                </div>
              </div>
            </header>
          </center>
        </div>














        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard4">
            <div className="heading-secondary-uk-section-wow-blogscard4 ">
            WOW Events & Stories Blogs
            </div>
          </div>
        </Container>


        <Container>
<p class="blogscard4-description-area-of-main--text-above">
When making—not taking—a picture, a professional photographer considers three main
 factors: the aperture, shutter speed and the ISO.

Here at ValuePenguin, we consider four... when determining which cities are best for 
these artistic pros to live and work: number of jobs,average salary, cost of living and location quotient.

To contribute to ValuePenguin’s coverage on careers, follow us on Twitter @VP_Careers.
 Please also feel free to use the comment section below.
            
             </p>

             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard4-aboveimage">
               <img 
             style={{width:'100%'}}
             src="IMAGES/city-images/wow-blog/additional-cards/blog4/image-above1.jpg"/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard4-aboveimage">
               <img 
             style={{width:'100%'}}
             src="IMAGES/city-images/wow-blog/additional-cards/blog4/image-above2.jpg"/>

  </div>
  </Col>
               
             </Row>
             
             <p class="blogscard4-description-area-of-main--text-above">
             It doesn’t matter if you’re a pro photographer or amateur snapper, you’ll have to bring an extra 
             memory card or two to capture the beauty of these 13 picture-perfect cities.
             </p>

             <p class="blogscard4-description-area-of-main--text-above">
             If you travel often, you probably travel light — which means you’re more likely to rely
              on your smartphone for pics than a professional-grade camera. We get that. What’s more,
               we know all about it! While snapping insta-worthy pics on your smartphone
              seems simple (point, click, shoot), there are ways to make documenting your travels even better.
               <br/>

               One of the most vibrant cities in the world, travelers have flocked to London for years. 
               With something for everyone, you might think there’s never a reason to leave New York City.
                But beyond the Big Apple,
                you’ll find plenty of incredible destinations in the northeast of the United States.
             </p>
</Container>
<br/>










<div className="gradient-back-travel-blogscard4">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard4-grid-text">
                  <div className="travel-blogscard4-page-araea-sub-headings110">
                  EDINBURGH
                  </div>

                  <div className="travel-blogscard4-page-page-text">
                  A visit to Scotland’s capital city feels like stepping back in time. Between the Gothic 
                  architecture, cobblestone streets, and hilltop
                   castle, you’re sure to find hundreds of irresistible Edinburgh photo spots.
                   </div>

                   <div className="travel-blogscard4-page-page-text">
                  Places like Arthur’s Seat, Edinburgh Castle, and Calton Hill offer incredible panoramic views
                   over the city. And thanks to Harry Potter, the colorful storefronts of Victoria Street
                    are one of the top Instagram spots in Europe. If you’re visiting Edinburgh
                   in October, you’ll have the bonus of vibrant red and gold leaves decorating the cityscape.
 

                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/grid1.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-blogscard4">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/grid2.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard4-grid-text">
                  <div className="travel-blogscard4-page-araea-sub-headings110">
                  Reflections in water
                  </div>

                  <div className="travel-blogscard4-page-page-text">
                  So many cities are built on the water – take advantage of this to create beautiful 
                  reflection photos. Depending on the water, you may need to shoot from a few locations
                   until you get a reflection you like.<br/>
                   Life is full of symmetry and in cities – where there is so much architecture – it’s 
                   everywhere. Use the urban symmetry to highlight the subject of your photos and 
                   strengthen the composition.
                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>










<Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard4">
            <div className="heading-secondary-help-community-section-wow-blogscard4 ">
            Travel Best Cities Photographers
            </div>
          </div>
        </Container>







<Container>
        <div class="description_of_card_of_blogscard4">
          <div class="description_of_card_of_blogscard4_area">
          <h2 class="blogscard4-description-area-of-main-headings">
          Snap the best of London
            </h2>
            <p class="blogscard4-description-area-of-main--text">
            It’s hard to find a place in the British capital that isn’t picturesque. In between afternoon 
            tea and West End plays, you’ll find photo opportunities around 
            every corner of London. Notting Hill’s vibrant streets will add color to your
             Instagram feed while the Shard and London Eye will provide the setting for unbeatable panoramas.
            </p>

            <p class="blogscard4-description-area-of-main--text">
            Traveling is fun. That’s just a fact. Another well-known fact is that travel is not always easy,
             smooth or convenient. Of course, chalking up all the mishaps as part of the fun is the best attitude
              to have, however avoiding them in the first place is probably better. To ensure that your travel 
              is as pain-free as possible, we’ve assembled the best travel hacks that ever existed. Whether 
              you’re looking for packing hacks,
             cheap flight hacks, airplane hacks, or just general travel life hacks, we have you covered.
             </p>


             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/help-tips-grid1.jpg"/>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/help-tips-grid2.png"/>
               </Col>
               
             </Row>

             
            <h2 class="blogscard4-description-area-of-main-headings">
            Shoot Dubai’s sleek skyline
            </h2>
            <p class="blogscard4-description-area-of-main--text">
            In a desert city where you can ski indoors and dine underwater, the number of photo spots in
             Dubai is endless. Head to the top of the world’s tallest building
             for 360 views, snap an underwater photo at the Dubai Aquarium, and strike a pose while dune bashing.
              <br/><br/>
              Dubai is a world unto its own, and in this world the polarity of East and 
              West converges in an architectural juxtaposition that, surprisingly, works. 
            </p>


            <h2 class="blogscard4-description-area-of-main-headings">
            Rome around must-visit attractions
            </h2>
            <p class="blogscard4-description-area-of-main--text">
            All roads lead to Rome, which is probably why it can feel so crowded. The key to taking
             awesome photos here is to get up early and head to the icons before the masses. Not only will you 
            beat the crowds, but you’ll also enjoy the best light
             for taking photos of the Colosseum and the Vatican. 
            </p>
            <p class="blogscard4-description-area-of-main--text">
            All visitors must go through security. Your ticket, called the Colosseum and Roman Forum 
            pass, also includes entrance to the Roman Forum and Palatine
             Hill which you can visit any time within 48 hours.
            </p>

            <h2 class="blogscard4-description-area-of-main-headings">
            Explore New York’s iconic sights
            </h2>
            <p class="blogscard4-description-area-of-main--text">
            The City That Never Sleeps is best from above, so get your skip the line tickets for the Top 
            of the Rock and the Empire State Building and snap the skyline 
            of New York. Whatever goes up must come down, 
            so head to the High Line, Brooklyn Bridge, and the Staten Island Ferry to continue your photo tour.{" "}
            </p>

            <p class="blogscard4-description-area-of-main--text">
            The most popular attraction in New York City is the Metropolitan Museum of Art, 
            which just set a new record of its own. The jewel of Museum Mile 
            welcomed a record high 7.35 million visitors in its 2018 fiscal year that began on July 1, 2017.
            Everyone wants to visit The Statue of Liberty, The Empire State Building, Grand Central 
            Terminal and the Brooklyn Bridge as famous, and historic, New York City landmarks.
            </p>

            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard4">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/img1.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard4">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/img2.jpg" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>

            


            
           
           


            <h2 class="blogscard4-description-area-of-main-headings">
            Tips to up your travel photography game
            </h2>
            <p class="blogscard4-description-area-of-main--text">
            If you travel often, you probably travel light — which means you’re more likely to rely on your
             smartphone for pics than a professional-grade camera. We get that. What’s more, we know all
              about it! While snapping insta-worthy pics on your smartphone
             seems simple (point, click, shoot), there are ways to make documenting your travels even better.{" "}
            </p>

            <ul className="list-circle-type-blogscard">
              <li class="blogscard4-description-area-of-main--text">
              <p>
              <span style={{fontWeight:'bold'}}>Find the right light: </span>
             If you know your best angle (to the left, chin tilted slightly upwards), then you probably 
            know how important the right light can be. After all, it’s one of — if not the most — important
             factors in taking an excellent picture. Insta-pros know how to find the perfect lighting for 
             postcard-worthy pics, and so can you! For the best light, head to the landmark you want to shoot
              in the early morning or in the later afternoon. If you’re indoors, set up your shot so that light 
              is falling on your subject.
             Even window light can do the trick. This will guarantee optimal lighting and minimal editing.{" "}
            </p>
              </li>
              <li class="blogscard4-description-area-of-main--text">
              <span style={{fontWeight:'bold'}}>Don’t lose focus: </span>
              Your phone can really get in there — use this to your advantage! If you want to focus on 
              a particular element of your shot (a plated meal, for example), all it takes is a tap in 
              the desired area. When it
               comes to taking pictures of people, don’t be afraid to rely 
               on your phone’s facial-recognition feature.
              </li>
              <li class="blogscard4-description-area-of-main--text">
              <span style={{fontWeight:'bold'}}>Take photos in bursts: </span>
              If you aren’t feeling particularly confident in your photo-taking skills, using the burst
               function might be the right way to go. Why? Because 18 pictures in a row are sure to hold
                something good. Once you’ve taken a 
              bunch, scroll through to find the picture. This feature is especially handy for shots with people.
              </li>

              
            </ul>


            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard4">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/img3.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard4">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog4/img4.jpg" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>













            <h2 class="blogscard4-description-area-of-main-headings">
            Avoid the dark, turn off the flash
            Know your modes!!
            </h2>


            <p class="blogscard4-description-area-of-main--text">
            If you’re hoping to get a semi-obscured, moody shot, think again. Smartphones don’t take the
             best shots in low light due to limited hardware and lack of slow shutter speed. This means
              that features you might be used to in digital single-lens reflex cameras (DSLRs) aren’t
               possible. As for the flash function, we suggest avoiding it 
            as much as possible. Why? Because you can’t control the strength of the LED flash on your phone.{" "}
            </p>

            <p class="blogscard4-description-area-of-main--text">
            Don’t be afraid to play around with your smartphone’s camera. The more you 
            explore your camera’s capabilities, the more impressed you’ll find. There’s a world of possibility:
             from adding filters and tweaking colors to straightening images and blurring backgrounds.{" "}
            </p>





           
            <div class="description_of_card_of_blogscard4_bottom_area">
              <div class="description_of_card_of_blogscard4_bottom_area_previous">
                <h3>
                  <i class="fas fa-reply" aria-hidden="true"></i>
                </h3>
                <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                <p>Previouse Article</p>
              </div>
              <div class="description_of_card_of_blogscard4_bottom_area_next">
                <h3>
                  <i class="fa fa-mail-forward" aria-hidden="true"></i>
                </h3>
                <h5>WOW IS FINALLY HERE!</h5>
                <p>Next Article</p>
              </div>
            </div>
          </div>
</div>




</Container>
        

















            


            
            








































        












        {/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}
      </div>
    );
  }
}
export default Blogpost4;
