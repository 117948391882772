import React, { Component } from "react";


class Emailleader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

    

    
<div >

<div class="welcome-leader-email-wow-header">
	<center>
		<img class="wow-image-in-welcome-leader-email"   src="IMAGES/wowboard.png"/>
	</center></div>
  
    
 

     
<div class="container">

      <div class="text-written-in-welcome-leader-email">
		<p class="text" >
        Welcome Email Leader
		</p>
		<p class="text" >
			Dear Muhammad Tahir,
		</p>

        <p class="text" >
        Thank You for showing your interest in becoming a Leader of WOW Electronics Transport Services. 
		</p>

        <p class="text" >
        Your credentials for logging into WOW App are as follows:  
		</p>

        <center>
            <div class="username-in-welcome-leader-email underline-in-welcome-leader-code ">
                USERNAME: Tahir12
            </div>
            <div class="code-in-welcome-leader-email underline-in-welcome-leader-code ">
                PIN CODE: 9040
            </div>
            </center>
        

        <p class="text" >
        Your profile application has been received and reviewed.
         Your documents are uploaded and completed according to WOW 
         requirements. So you have successfully rolled in WOW Leader App. 
		</p>

        <p class="text" >
        We wish you the best of luck in your endeavors.
		</p>

        <p class="text" >
        Thank You for registering with WOW.
		</p>

        <p class="text" >
        If you have any queries regarding with sign up
         feel free to contact at the provided e-mail address: info@wowets.com 
		</p>


		<p class="text" >
           Regards,       <br/>
           WOW &nbsp; (ETS) Team.

		</p>
		
		

        

    
        <br/>
	
		<p   class="text-thank-you-welcome-leader" >
			THANK YOU 
		</p>

	</div></div>
	<br/>
    

	<div class="container">

	<div class="statement-header-line-in-welcome-leader-email"> </div>

    <p class="text-below-welcome-email-leader-footer-page" >
        Private and Confidential,<br/>
        DiscIcirnic This email contckis crivleged cod/or conklatial information intended only for the
         use of the addressee. If you we not the Intended recipterit of this message, you are strictly
          prohibited to road copy, distribute% discus, cresserninate its contents or take my croon
           raying on the sure and please notify the sender 111E1 1014.11.1*. The recipient ozknavedges
            that to sender is unable to exercise control or ensure a gucrantee the integrity of/over the
     contents ancl/or infomiation contained in this message, therefore, the WOWETS will not be to for 
     any coots or omissions vAlich aise as a result of emai trcnsmission, any damages or eras whatever
      ter notarial' viruses, external:limns% delays and the like. 

		</p>

   




	<div class="welcome-leader-email-footer-content">
  <div class="infoCons-in-welcome-leader-email">
     

       {/* <!-- row  --> */}


      <div class="horizontalRow-in-welcome-leader-email">

        <div class="welcome-leader-email-ones welcome-leader-email-inside-texts"><div class="itemCons-in-welcome-leader-email">
             <div class="iconCon-in-welcome-leader-email">
              <a> <img class="footer-baseline-image-welcome-leader-email" src="IMAGES/calllogo.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-welcome-leader-email">
              +971 45 217500
             </span>
            </div></div>


        <div class="welcome-leader-email-twos welcome-leader-email-inside-texts"><div class="itemCons-in-welcome-leader-email">
             <div class="iconCon-in-welcome-leader-email">
             <a> <img  class="footer-baseline-image-welcome-leader-email" src="IMAGES/msg.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-welcome-leader-email">
               info@wowets.com
             </span>
            </div></div>

        <div class="welcome-leader-email-threes welcome-leader-email-inside-texts"><div class="itemCons-in-welcome-leader-email">
             <div class="iconCon-in-welcome-leader-email">
             <a> <img class="footer-baseline-image-welcome-leader-email" src="IMAGES/browser.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-welcome-leader-email">
               wowets.com
             </span>
            </div></div>


        <div class="welcome-leader-email-four welcome-leader-email-inside-texts"><div class="itemCons-in-welcome-leader-email">
             <div class="iconCon-in-welcome-leader-email">
             <a> <img class="footer-baseline-image-welcome-leader-email" src="IMAGES/location.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-welcome-leader-email">
               WOW Electronic Transport Services, <br/>
                34th floor Burj Al Salam Tower, Trade <br/>
                Centrel, Sheikh Zayed Road Dubai, UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div>





</div>

      


      
  


    




    

       
        
       

    
   
   

    


 
     
    
    
    );
  }
}
export default Emailleader;