import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux';
import store from './store/index';

const app = (
    <Provider store={store}>
        <App />
    </Provider>
)



ReactDOM.render(app , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// /* https://api-files.wowets.com/$$fileService
//  https://api-profiles.wowets.com/$$profileService */
//  /* 
//  https://prod-legalemirates.wowets.com/$$fileService
// https://prod-legalemirates.wowets.com/$$profileService
//  */