import React, { Component } from "react";


class Notifyemail extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

    

    
<div >

<div class="notify-email-wow-header">
	<center>
		<img class="wow-image-in-notify-email"   src="IMAGES/wowboard.png"/>
	</center></div>
  
    
 

     
<div class="container">

      <div class="text-written-in-notify-email">
		<p class="text" >
			Email Blocked
		</p>
		<p class="text" >
			Dear Muhammad Tahir,
		</p>
		<p class="text" >
			Its been written to appraise that you have violated the terms & <br/>
			conditions and privacy policy of WOW. Tragically, Your account <br/>
			has been blocked by WOW security team. For further info, <br/>
			please contact WOW helpline and security support.
		</p>
		<br/>
		<p class="text" >
			For further info contact info@wowets.com<br/>
			WOW helpline +971 45 217500 <br/>
			Regards, <br/>
			WOW &nbsp; ETS.
		</p>
	
		<p   class="text-thank-you" >
			THANK YOU 
		</p>

	</div></div>
	<br/>

	<div class="container">

	<div class="statement-header-line-in-notify-email"> </div>




	<div class="notify-email-footer-content">
  <div class="infoCons-in-notify-email">
     

       {/* <!-- row  --> */}


      <div class="horizontalRow-in-notify-email">

        <div class="notify-email-ones notify-email-inside-texts"><div class="itemCons-in-notify-email">
             <div class="iconCon-in-notify-email">
              <img class="footer-baseline-image-notify-email" src="IMAGES/calllogo.png" alt=""/>
             </div>
             <span class="iconDescss-in-notify-email">
               Phone: +971 45 217500
             </span>
            </div></div>


        <div class="notify-email-twos notify-email-inside-texts"><div class="itemCons-in-notify-email">
             <div class="iconCon-in-notify-email">
              <img  class="footer-baseline-image-notify-email" src="IMAGES/msg.png" alt=""/>
             </div>
             <span class="iconDescss-in-notify-email">
               info@wowets.com
             </span>
            </div></div>

        <div class="notify-email-threes notify-email-inside-texts"><div class="itemCons-in-notify-email">
             <div class="iconCon-in-notify-email">
              <img class="footer-baseline-image-notify-email" src="IMAGES/browser.png" alt=""/>
             </div>
             <span class="iconDescss-in-notify-email">
               wowets.com
             </span>
            </div></div>


        <div class="notify-email-four notify-email-inside-texts"><div class="itemCons-in-notify-email">
             <div class="iconCon-in-notify-email">
              <img class="footer-baseline-image-notify-email" src="IMAGES/location.png" alt=""/>
             </div>
             <span class="iconDescss-in-notify-email">
               WOW Electronic Transport Services, <br/>
                34th floor Burj Al Salam Tower, Trade <br/>
                Centrel, Sheikh Zayed Road Dubai, UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div>





</div>

      


      
  


    




    

       
        
       

    
   
   

    


 
     
    
    
    );
  }
}
export default Notifyemail;