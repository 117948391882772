import React from 'react';
import {
    Form,
    Container,

  } from "react-bootstrap";
 
class Reset extends React.Component {
 
    constructor(props) {
        super(props);
         
    }
 
   
    
 
    render() {
        return (
            
    <Container >
       <center>
       <div className="Email-border-password-reset">
        <div className="email-border">
            <Container>
   
    <h1 className="password-reset-heading" >Password Reset</h1>
    <p className="password-reset-text">Enter a new password below and we'll log  you in asap!</p>
    <br></br>
      
  

     <Container>
     <Container>
    <Form>
  <Form.Group className="password-text-forms" controlId="formBasicEmail">
    <Form.Label><h3 className="password-text-forms">New Password</h3></Form.Label>
    <Form.Control type="email" placeholder="********" />
    
  </Form.Group>

  <Form.Group className="password-text-forms" controlId="formBasicPassword">
    <Form.Label><h3 className="password-text-forms">Confirm Password</h3></Form.Label>
    <Form.Control type="password" placeholder="********" />
  </Form.Group>
  
  </Form>
</Container>
</Container>


  
  <button type="button" class="reset-buttons" style={{backgroundColor:'#1036fc'}}>Save my Password</button>
  
  
  <h3 className="hint-pass">Hints for Password:</h3>
  <div className="password-reset-text">
  <p >>At least 8 characters or more</p>
  <p>>At least one alphabet</p>
  <p>>At least one number</p>
  </div>
  </Container>
  </div>
  </div>
  
       </center>
        
</Container>

        );
    }
}
export default Reset;