import React, { Component } from "react";
import FontAwesome from "react-fontawesome";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import { Link } from "react-router-dom";

class Wowholiday extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false
    };
  }

  componentWillMount() {}
  componentDidMount() {}
  render() {
    return (
      <div>












        <div className="holiday-credit-wow-holiday-our-container">
          <div class="holiday-credit-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-credit-wow-holiday-home-our-story">
                <div class="holiday-credit-wow-holiday-our-team-heading">
                  Get free credits when you complete an experience
                </div>
                <div class="holiday-credit-wow-holiday-our-team-heading-below-text">
                  Find out how to get extra discounts on your next booking by
                  completing WOW activities and leaving reviews
                </div>
                <div class="holiday-credit-wow-holiday-our-team-heading">
                  <Button
                    variant="outline-light"
                    className="holiday-credit-wow-holiday-our-team-button"
                  >
                    Check My Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>












        <Container>
<div className=" carousel-display-in-pc-views">
  <div className="heading-primary-in-wow-holiday-credits text-box-inside-background-image-of-new-design-credits">
    <span className="heading-primary-main-credits border-line-credit-headings">
    What are WOW Credits?

    </span>
    <span className="heading-primary-sub-credits">
    Credits can be earned by WOW customers for activities they book 
    on WOW.com or via the WOW Holiday App. After you collect enough credits, you
     can use these credits to get a discount on your next booking with WOW<br/><br/>

    <span className="credit-equal-dollar-wow-credit">10 credits = 1 US Dollar</span> The credits value
      in other currencies is based on the exchange rate at the time of booking

    </span>
  </div>
 

  </div>
  </Container>







<div className="background-color-in-wow-credits">
  <Container>
<div className=" carousel-display-in-pc-views">
  <div className="heading-primary-in-wow-holiday-credits text-box-inside-background-image-of-new-design-credits">
    <span className="heading-primary-main-credits-how-get">
    How do I get credits?

    </span>


    <Row>
        <Col xs={12} sm={12} lg={4} md={3}>
        <img src="IMAGES/city-images/city-home-images/activity1.png" className="activity-images-wow-credits"></img>

        <div className="activity-heading-below-img-holiday">
            Complete an Activity
        </div>

        <div className="activity-heading-context-below-img-holiday">
        When you complete a WOW activity, you will receive
         1%-5% of the activity price in WOW credits. The credits will appear
          in your WOW account 2 days after you complete the activity
        </div>


        </Col>


        <Col xs={12} sm={12} lg={4} md={3}>


        <i class="arrow_box g_c_mid">
                <i class="line g_rel">
                    <i class="arrow g_v_mid" ></i>
                </i>
            </i>
        

        


        </Col>






        <Col xs={12} sm={12} lg={4} md={3}>
        <img src="IMAGES/city-images/city-home-images/activity2.png" className="activity-images-wow-credits"></img>

        <div className="activity-heading-below-img-holiday">
        Leave a review
        </div>

        <div className="activity-heading-context-below-img-holiday">
        After completing any WOW activity, you are invited to leave a 
        review. Each review earns you 80 credits which will appear 
        in your account immediately
        </div>





        </Col>



    </Row>






   
  </div>
 

  </div>
  </Container>



  </div>












  <Container>
<div className=" carousel-display-in-pc-views">
  <div className="heading-primary-in-wow-holiday-credits text-box-inside-background-image-of-new-design-credits">
    <span className="heading-primary-main-credits border-line-credit-headings">
    WHAT ARE WOW REWARDS?

    </span>
    {/* <span className="heading-primary-sub-credits">
    When you have collected <span className="credit-equal-dollar-wow-credit">at least 10</span> credits,you can choose to use these
     credits to get a discount on your next booking with Klook

     

    </span> */}

<p class="website-text">
    
        {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAABQElEQVRIiaXVPyhuYRzA8c/rz6awGy2sRJYbJTObjMKgbrfucA3GeweRMpD8KZPBooxSSunWW7Ips+ySMlH6lbfkOeflnPNdTn2f53x7huf8Tq1r6GUee0ryUG9Vq9W0YBcLZUMNWt6fO1hMVkuEgi38TnaUCAXrWE52lQgF/7CS2C/ICgVL2EhsE/JCwa8i16JZKJjDYWIz+CoUzOA4sQVP1GAKp4ktEQomcIH2ZKVgKPiB/+ioGgoGUUdn1VDQjyv0VA0FvbjrHn7tqxoKzvFUNXSA8Yd6632V0DZmP4oyobWsIVg09Bd/Eou2xOQTo2U1b/W7oZ/YTGzBUPyu9hNbMDSNo8Rm0Cw0iZPE5pAXipFxltgmfA69YAyX+a9k8zEU38worjN3fjP0jBHclIkEcbMfMVAl0gjFSW6rROANE7Isev8upC8AAAAASUVORK5CYII="/> */}
        <i class="fas fa-play color-of-icon-in-credit-holiday"></i>
        <span>
        </span> WOW Rewards are the benefits you can earn against the points earned by every single Trip Experience.</p>
        <p class="website-text">
    
        {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAABQElEQVRIiaXVPyhuYRzA8c/rz6awGy2sRJYbJTObjMKgbrfucA3GeweRMpD8KZPBooxSSunWW7Ips+ySMlH6lbfkOeflnPNdTn2f53x7huf8Tq1r6GUee0ryUG9Vq9W0YBcLZUMNWt6fO1hMVkuEgi38TnaUCAXrWE52lQgF/7CS2C/ICgVL2EhsE/JCwa8i16JZKJjDYWIz+CoUzOA4sQVP1GAKp4ktEQomcIH2ZKVgKPiB/+ioGgoGUUdn1VDQjyv0VA0FvbjrHn7tqxoKzvFUNXSA8Yd6632V0DZmP4oyobWsIVg09Bd/Eou2xOQTo2U1b/W7oZ/YTGzBUPyu9hNbMDSNo8Rm0Cw0iZPE5pAXipFxltgmfA69YAyX+a9k8zEU38worjN3fjP0jBHclIkEcbMfMVAl0gjFSW6rROANE7Isev8upC8AAAAASUVORK5CYII="/> */}
        <i class="fas fa-play color-of-icon-in-credit-holiday"></i>
        <span>
        </span> You can use your earned points to get a Reward of Credit in your WOW Wallet.</p>
        <p class="website-text">
    
        {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAABQElEQVRIiaXVPyhuYRzA8c/rz6awGy2sRJYbJTObjMKgbrfucA3GeweRMpD8KZPBooxSSunWW7Ips+ySMlH6lbfkOeflnPNdTn2f53x7huf8Tq1r6GUee0ryUG9Vq9W0YBcLZUMNWt6fO1hMVkuEgi38TnaUCAXrWE52lQgF/7CS2C/ICgVL2EhsE/JCwa8i16JZKJjDYWIz+CoUzOA4sQVP1GAKp4ktEQomcIH2ZKVgKPiB/+ioGgoGUUdn1VDQjyv0VA0FvbjrHn7tqxoKzvFUNXSA8Yd6632V0DZmP4oyobWsIVg09Bd/Eou2xOQTo2U1b/W7oZ/YTGzBUPyu9hNbMDSNo8Rm0Cw0iZPE5pAXipFxltgmfA69YAyX+a9k8zEU38worjN3fjP0jBHclIkEcbMfMVAl0gjFSW6rROANE7Isev8upC8AAAAASUVORK5CYII="/> */}
        <i class="fas fa-play color-of-icon-in-credit-holiday"></i>
        <span>
        </span> WOW Credit helps you save more from your rides with the rewarded credit in your Wallet.</p>
        <p class="website-text">
    
        {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAABQElEQVRIiaXVPyhuYRzA8c/rz6awGy2sRJYbJTObjMKgbrfucA3GeweRMpD8KZPBooxSSunWW7Ips+ySMlH6lbfkOeflnPNdTn2f53x7huf8Tq1r6GUee0ryUG9Vq9W0YBcLZUMNWt6fO1hMVkuEgi38TnaUCAXrWE52lQgF/7CS2C/ICgVL2EhsE/JCwa8i16JZKJjDYWIz+CoUzOA4sQVP1GAKp4ktEQomcIH2ZKVgKPiB/+ioGgoGUUdn1VDQjyv0VA0FvbjrHn7tqxoKzvFUNXSA8Yd6632V0DZmP4oyobWsIVg09Bd/Eou2xOQTo2U1b/W7oZ/YTGzBUPyu9hNbMDSNo8Rm0Cw0iZPE5pAXipFxltgmfA69YAyX+a9k8zEU38worjN3fjP0jBHclIkEcbMfMVAl0gjFSW6rROANE7Isev8upC8AAAAASUVORK5CYII="/> */}
        <i class="fas fa-play color-of-icon-in-credit-holiday"></i>
        <span>
        </span>  You can get more Rewards by inviting your friends and family to WOW.</p>
        <p class="website-text">
    
        {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAABQElEQVRIiaXVPyhuYRzA8c/rz6awGy2sRJYbJTObjMKgbrfucA3GeweRMpD8KZPBooxSSunWW7Ips+ySMlH6lbfkOeflnPNdTn2f53x7huf8Tq1r6GUee0ryUG9Vq9W0YBcLZUMNWt6fO1hMVkuEgi38TnaUCAXrWE52lQgF/7CS2C/ICgVL2EhsE/JCwa8i16JZKJjDYWIz+CoUzOA4sQVP1GAKp4ktEQomcIH2ZKVgKPiB/+ioGgoGUUdn1VDQjyv0VA0FvbjrHn7tqxoKzvFUNXSA8Yd6632V0DZmP4oyobWsIVg09Bd/Eou2xOQTo2U1b/W7oZ/YTGzBUPyu9hNbMDSNo8Rm0Cw0iZPE5pAXipFxltgmfA69YAyX+a9k8zEU38worjN3fjP0jBHclIkEcbMfMVAl0gjFSW6rROANE7Isev8upC8AAAAASUVORK5CYII="/> */}
        <i class="fas fa-play color-of-icon-in-credit-holiday"></i>
        <span>
        </span>  Every Ride with the WOW give you 20 points.</p>


  </div>
 

  </div>
  </Container>




























  <Container>
<div className=" carousel-display-in-pc-views">
  <div className="heading-primary-in-wow-holiday-credits text-box-inside-background-image-of-new-design-credits">
    <span className="heading-primary-main-credits border-line-credit-headings">
    How do I use credits?

    </span>
    <span className="heading-primary-sub-credits">
    When you have collected <span className="credit-equal-dollar-wow-credit">at least 10</span> credits,you can choose to use these
     credits to get a discount on your next booking with WOW Worldwide Holiday

     

    </span>
  </div>
 

  </div>
  </Container>




 







      </div>
    );
  }
}
export default Wowholiday;
