import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { FaBeer } from "react-icons/fa";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  Button,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost8/blogpost8.scss";

class Blogpost8 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
    };
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        <div className="td-post-header-holder-blogcard8 td-image-gradient-blogcard8">
          <div className="td-post-featured-image-blogcard8">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard8"
              src="IMAGES/city-images/wow-blog/additional-cards/blog8/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard8">
              <ul className="td-category-blogcard8">
                <li className="entry-category-blogcard8">
                  <a
                    target="blank"
                    href="https://www.jsonline.com/story/travel/advice/2020/04/05/coronavirus-quarantine-project-research-8-hard-plan-dream-trips/2940670001/"
                  >
                    Hard-to-Plan Dream
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard8">WOW News & Trends</h1>

              <h4 className="entry-title-two-blogcard8">
                Wow 8 Hard-to-Plan Dream Trips to Research at Home While You
                Have the Time...
              </h4>

              <div className="td-module-meta-info-blogcard8">
                <span className="td-post-date-blogcard8">
                  <time
                    className="entry-date-blogcard8 updated td-module-date-blogcard8"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    22 May 2020
                  </time>
                </span>

                <div className="td-post-views-blogcard8">
                  <i className="fa fa-eye view-icon-views-blogcard8"></i>
                  <span className="td-nr-views-10968">9235</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard8">
            <div className="heading-secondary-uk-section-wow-blogscard8 ">
              WOW News & Trends
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard8-description-area-of-main--text-above">
            In stressful times like this global pandemic, it’s easy to get
            caught up in fear, confusion, and the never-ending news cycle. But
            perhaps the best way to escape it all is to mentally dive into a
            good vacation. Researching your dream trip of choice is a helpful
            reminder that the world will go back to normal again—and when it
            does, you’ll have a decisive plan of action for a trip you’re
            excited to take. Whether it’s a new type of travel for you (sailing,
            hiking, train itineraries, or road tripping) or a destination you’re
            unfamiliar with, now’s the time to tackle all your wildest travel
            ambitions. Here are the dream trips we’re researching while we’re
            stuck at home, and where to look for the most reliable and
            up-to-date information on each.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard8-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog8/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard8-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog8/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard8-description-area-of-main--text-above">
            Many avid travelers may be wondering when we can start traveling
            again. While we don’t have a definitive answer just yet, some places
            are inching closer to reopening the skies and roads. The decision
            ultimately comes down to when medical experts say we have
            sufficiently succeeded in flattening the curve, and when businesses
            and local governments think it’s safe to start opening back up for
            business. Even though hotels and airlines are stepping up to help
            during the pandemic, travel is likely to resume gradually once
            travel advisories expire and airlines slowly reintroduce flight
            routes.
          </p>

          <p class="blogscard8-description-area-of-main--text-above">
            Ladies and gentlemen, 2021 is almost upon us and there is so much
            excitement ahead! Planning to travel abroad in the coming year?
            Excellent! That means you have plenty of time to tailor your trip to
            include a few of the famous European festivals. Here are our top
            picks of annual Europe events in 2021:
          </p>
        </Container>
        <br />

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogcard8">
            <div className="heading-secondary-section-wow-blogcard8 ">
              Fun Travel Quizzes, What you should Know:
            </div>
          </div>
        </Container>

        <Container>
          <div
            id="accordion-5ed01b058660a"
            className="accordion indicator-plus accordion-white mb-3"
            role="tablist"
          >
            <div className="card-blogcard8 bar">
              <div className="card-blogcard8 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-0"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard8-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-0"
                  >
                    <span role="heading" aria-level="0">
                      What are the most surreal places to visit?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-0"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-0"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard8">
                    <Row>
                      <Col lg={6}>
                        <p style={{ padding: "3rem" }}>
                          Some of the strangest places on earth are also the
                          most sublime: from the UFO-like dragon's blood trees
                          in Yemen to a rainbow-colored hot spring in
                          Yellowstone to a bridge in Germany that looks like a
                          leftover prop from Lord of the Rings. Wherever you
                          choose to go, you’ll enjoy a historical experience
                          like no other. Still, there's ample anecdotal
                          corroboration, like the flight where a 30-degree
                          navigation error was rectified simply by asking a
                          passenger to turn off a portable DVD player. The issue
                          is still being debated, so if in doubt, flick that Off
                          switch. We might, eventually, long for the days when a
                          chatty seatmate couldn’t make an hour-long phone call.
                        </p>
                      </Col>

                      <Col lg={6}>
                        <div className="wow-news-card1-work-page-top-image-blogscard8-aboveimage">
                          <img
                            style={{ width: "100%" }}
                            src="IMAGES/city-images/wow-blog/additional-cards/blog8/collapse1.jpg"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard8 bar">
              <div className="card-blogcard8 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-1"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard8-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-1"
                  >
                    <span role="heading" aria-level="0">
                      Do you have to be rich to travel the world?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-1"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-1"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard8">
                    <p style={{ marginBottom: "1rem" }}>
                      The beauty of travel is that it’s accessible to everyone,
                      in some form. You don't have to go far to experience a new
                      culture. Plus there are several ways to make most flights
                      affordable, whether by being flexible, searching on new
                      websites like Skypicker and Fareness, or just by planning
                      ahead. Know the best time to book a flight: 57 days in
                      advance for domestic flights, 117 for international. Then
                      again, you could just make traveling the world your
                      9-to-5.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard8 bar">
              <div className="card-blogcard8 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-2"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard8-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-2"
                  >
                    <span role="heading" aria-level="0">
                      WHY VISIT EUROPE?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-2"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-2"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard8">
                    <p>
                      Thanks to centuries of history, popular movies and
                      legendary literature, the allure of Europe’s landscapes
                      and cultures has been romanticized for ages. Many people
                      dream of turning the European vacation in their hearts
                      into reality. Here’s why now is better than ever to visit
                      Europe.
                      <br />
                      For years, traveling across Europe required frustrating
                      border stops. Now, many EU countries are part of the
                      Schengen Area. This agreement allows travelers to cross
                      borders freely with a single passport stamp on arrival,
                      and another upon departure from the continent. Going from
                      Denmark’s endless coastline to Romania’s legendary
                      Transylvanian castles just got a whole lot easier.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard8 bar">
              <div className="card-blogcard8 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-3"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard8-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-3"
                  >
                    <span role="heading" aria-level="0">
                      How Far in Advance Should I Book?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-3"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-3"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard8">
                    <p>
                      Truth be told, airfare prices—which fluctuate
                      constantly—are impossible to predict. However, there are a
                      few strategies that will guide you to a good deal and
                      minimize your risk of overpaying. For domestic travel,
                      you’ll typically find the best fares one to two months
                      before your trip. For international itineraries, you’ll
                      often want to start searching for fares a bit further in
                      advance, especially if your dates are firm or you’re
                      traveling at a busy time of year. Add an extra month or
                      two for peak travel times like holidays or major events.
                      Some destinations, such as Disney World during spring
                      break or popular beach spots in August, require even more
                      advance planning, so do your homework.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard8 bar">
              <div className="card-blogcard8 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-5"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard8-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-5"
                  >
                    <span role="heading" aria-level="0">
                      How do you pack a suit?....
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-5"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-5"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard8">
                    <p>
                      Don’t wear that suit if you want it to arrive
                      wrinkle-free. Instead, heed the advice of our style
                      editor, Jayna Maleri: "Hold your jacket in front of you,
                      with the front facing you, and fold length-wise in half
                      away from you (so the buttons are on the fold and the
                      shoulders are touching). Then carefully turn one shoulder
                      inside out, and wrap it over the other shoulder (you'll
                      know you're on the right track if the lining is now on the
                      outside). Then fold the jacket one more time, in half
                      width-wise. Fold your trousers carefully in half (use
                      their natural crease as your guide), then place the folded
                      jacket on top of them, near the knees. Fold the pants
                      carefully around the jacket, and pack the bundle in the
                      bottom of your luggage. Follow these steps and we promise,
                      it won’t need a frantic steaming in the hotel shower."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="gradient-back-travel-blogscard8">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard8-grid-text">
                  <div className="travel-blogscard8-page-araea-sub-headings110">
                    Greece’s Far-Flung Islands
                  </div>

                  <div className="travel-blogscard8-page-page-text">
                    While Greece’s main hot spots get a break from the
                    overtourism they’ve faced for years, explore the country’s
                    thousands of islands online to find out which groupings are
                    best for your travel style.
                    <br />
                    There are the easily accessible Ionian Islands of the north
                    (think Corfu and Zakynthos), more remote North Aegean
                    options near Turkey, and, of course, the famous Cyclades:
                    hard-partying Mykonos and picturesque Santorini included.
                    But you also won’t want to miss their smaller siblings
                    either: Folegandros, Milos, Amorgos, and more are among the
                    lesser-known Cycladic gems.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog8/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard8">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog8/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard8-grid-text">
                  <div className="travel-blogscard8-page-araea-sub-headings110">
                    Hiking Jordan Top-to-Bottom
                  </div>

                  <div className="travel-blogscard8-page-page-text">
                    Petra is far from the only site to see in Jordan, which
                    recently opened its new 400-mile Jordan Trail to hikers’
                    delight. If you’re not aiming to tackle the entire route,
                    you can opt instead for mapping out the sections you would
                    like to conquer, like the southernmost part of the route
                    from Petra to the Red Sea’s world-renowned snorkeling and
                    scuba sites.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard8">
            <div className="heading-secondary-help-community-section-wow-blogscard8 ">
            Hard-to-Plan Dream Trips 
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard8">
            <div class="description_of_card_of_blogscard8_area">
              <h2 class="blogscard8-description-area-of-main-headings">
                The Ultimate New Zealand Road Trip …
              </h2>
              <p class="blogscard8-description-area-of-main--text">
                Not familiar with New Zealand’s North Island and South Island
                geography? Most people aren’t aware you can drive both in their
                entirety if you plan out an appropriate route and spend at least
                two weeks. That’s a lot of pit stops, hikes, and hotel stays to
                plan: tortoises, sea lions, rare birds like waved albatross and
                blue-footed boobies, and more.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog8/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog8/help-tips-grid2.jpg" />
                </Col>
              </Row>

              <h2 class="blogscard8-description-area-of-main-headings">
                Seeing Japan’s Cherry Blossoms by Bullet Train....
              </h2>
              <p class="blogscard8-description-area-of-main--text">
                With Japan’s cherry blossom festivals canceled this year, many
                travelers vying for this dream trip during peak season start
                planning up to a year in advance to ensure they’ll get their
                ideal hotel during what’s usually the busiest time of year for
                the country’s tourism. The fast and affordable bullet trains,
                which were recently updated to accommodate the now-postponed
                Olympics, are the best way to get around the mainland.
              </p>

              <h2 class="blogscard8-description-area-of-main-headings">
                When should I buy my airline ticket? Is there a way to game the
                system?
              </h2>
              <p class="blogscard8-description-area-of-main--text">
                Book a ticket when you need it. And no, there isn’t. But it’s a
                qualified “no.” Research suggests that if you buy your ticket
                when most people do — between one and four months before you fly
                — you’re likely to find the lowest price. Don’t push the button
                too early or too late, because fares tend to rise, especially as
                you close in on your departure date. Some airfare soothsayers
                claim you can find a bargain by waiting until a particular day
                and time, like Wednesday at 1 a.m. in the airline’s time zone.
                But the savings are minimal and probably not worth your time,
                not to mention the lost sleep.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard8">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog8/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard8">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog8/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 className="blogscard8-description-area-of-main-headings">
                Are all-inclusives worth it?
              </h2>
              <p className="blogscard8-description-area-of-main--text">
                Yes: Joe and Lorraine have three school-age children. They want
                to know exactly how much that Caribbean vacation is going to
                cost before the credit card bill arrives. The couple like their
                margaritas, and the kids feel all grown up ordering an endless
                stream of burgers and virgin frozen drinks from the snack bar.
                They all love snorkeling and banana-boat rides, which is why
                they chose an all-inclusive that includes watersports. And when
                the social director yells “Limbo,” they’re first in line.
              </p>

              <p className="blogscard8-description-area-of-main--text">
                No one is a foodie. A buffet with lots of choices is good
                enough. Bonus: Their package included airfare. No: Mary and
                Edward spend their weekends exploring wineries and hitting the
                newest restaurants. They don’t like crowds and still talk about
                that trip they took hiking the Alaskan backcountry. Neither can
                remember ever having eaten from a buffet. They like an
                occasional cocktail but don’t drink very much. Their idea of a
                vacation is meeting the locals and getting to know places
                tourists don’t typically visit. They work hard, so they aren’t
                worried about paying more than expected.
              </p>

              <h2 className="blogscard8-description-area-of-main-headings">
                Easy Cocktail Recipes to Bring the World Home.....
              </h2>
              <p className="blogscard8-description-area-of-main--text">
                For the time being, our travel plans are on hold—except for
                those of the armchair kind. In the spirit(s) of better times to
                come, we suggest whipping up a cocktail that will remind you of
                places you’ve been, or places you dream of visiting. You don’t
                have to brave a trip to the liquor store: Drizly partners with
                local liquor stores to deliver wine, beer, liquor, mixers,
                bitters, and more to your doorstep. Make a video cocktail-party
                date with friends, or settle in to take a virtual tour of some
                of the world’s most popular destinations.
              </p>

              <ul className="list-circle-type-blogscard">
                <li
                  class="blogscard8-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <p>
                    <span style={{ fontWeight: "bold"}}>Peru: </span>
                    Since two countries claim the Pisco Sour, you’ll be able to
                    dream double as you drink one. Pisco is a type of
                    brandy—essentially wine that is then distilled—that began
                    being produced in the 17th century with grapes imported from
                    Spain. Both Peru and Chile claim this tart and sweet
                    cocktail. The Pisco Sour is so inextricably linked to
                    Peruvian culture, there’s even a national Pisco Sour Day:
                    February 1. To get a peek of Peru, mix yourself a Pisco Sour
                    and immerse yourself in a virtual tour of Machu Picchu,
                    Cusco, or Lima.{" "}
                  </p>
                </li>
                <li
                  class="blogscard8-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <span style={{ fontWeight: "bold" }}>Venice: </span>
                  Harry’s Bar, a favorite of Ernest Hemingway’s, has attracted a
                  host of notables over the its nearly 90 years, including
                  Alfred Hitchcock, Truman Capote, Orson Welles, and George
                  Clooney. In 1950, owner Giuseppe Cipriani devised the Bellini,
                  in honor of Italian Renaissance painter Giovanni Bellini, and
                  the rest is history. (Fun fact: Two years later, Cipriani is
                  credited with first creating carpaccio, named after Venetian
                  painter Vittore Carpaccio.) Summer is peak Bellini time, when
                  fresh peaches are in season. Stir one up, and take a
                  360-degree tour of San Marco Square, the canals, and the
                  Rialto Bridge.
                  <ul className="list-circle-type-blogscard-inherited-list">
                    <span style={{ fontWeight: "bold" }}>Bellini: </span>
                    <li
                      class="blogscard8-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      2 ounces ​peach purée
                    </li>

                    <li
                      class="blogscard8-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      4 ounces chilled sparkling wine (or ​Prosecco)
                    </li>
                  </ul>
                </li>
                <li class="blogscard8-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>New Orleans: </span>
                  Some credit Antoine Amédée Peychaud, who owned a drug store in
                  New Orleans, with inventing the very first cocktail in 1838
                  (or at least giving it its name). Peychaud liked to treat
                  friends to his own concoctions, often including his Peychaud’s
                  Bitters, made from a secret family recipe. The drinks were
                  made using a double-ended egg cup for measuring, called a
                  “coquetier” (“ko-k-tay”), the word from which “cocktail” was
                  derived. Savor a Sazerac while immersed in the sounds of New
                  Orleans: Radio station WWOZ has a daily list and links of
                  virtual gigs by NOLA artists, mostly on social media
                  platforms. A number of artists are offering digital “tip jars”
                  through Venmo and other payment platforms, so you can support
                  them until they can play to live audiences again.
                  <ul className="list-circle-type-blogscard-inherited-list">
                    <span style={{ fontWeight: "bold" }}>Sazerac: </span>
                    <li
                      class="blogscard8-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      1 cube sugar
                    </li>

                    <li
                      class="blogscard8-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      3 dashes Peychaud’s Bitters
                    </li>

                    <li
                      class="blogscard8-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      1 1/2 ounces Sazerac Rye Whiskey
                    </li>

                    <li
                      class="blogscard8-description-area-of-main--text"
                      style={{ marginBottom: "1em" }}
                    >
                      Lemon peel
                    </li>
                  </ul>
                </li>
              </ul>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard8">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog8/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard8">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog8/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard8-description-area-of-main-headings">
                The Towers of Pain: Patagonia via Buenos Aires......
              </h2>

              <p class="blogscard8-description-area-of-main--text">
                Patagonia’s Torres del Paine (or Towers of Paine) National Park
                is a bucket-list item for hikers, skiers, and just about any
                outdoor enthusiast. The best way to conquer a trek in
                800,000-square-kilometer Patagonia is with a seasoned tour
                company that can show you the way, but you’ll still need to
                figure out which season you want to see this spectacular scenery
                in, how to get there, and if you want to tack on some time in
                romantic Buenos Aires since most air routes will include a stop
                there.{" "}
              </p>

              <div class="description_of_card_of_blogscard8_bottom_area">
                <div class="description_of_card_of_blogscard8_bottom_area_previous">
                  <h3>
                    <i class="fas fa-reply" aria-hidden="true"></i>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard8_bottom_area_next">
                  <h3>
                    <i class="fa fa-mail-forward" aria-hidden="true"></i>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost8;
