import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Travelworks extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString() };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  SpacePortae = () => {
    document.getElementById("emirates").style.display = "none";
    document.getElementById("tripadvisor").style.display = "none";
    document.getElementById("Travellingman").style.display = "none";
    document.getElementById("smartdubai").style.display = "none";
    document.getElementById("dubaipolice").style.display = "none";
    document.getElementById("natgeotravel").style.display = "none";
    document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "block";
    document.getElementById("europespaceagency").style.display = "none";

    document.getElementById("mySidenav-newsroom").style.width = "0px";
  };

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        <div>
          <div class="carousel-wow-travel-works ">
            <ol class="carousel-indicators-wow-travel-works ">
              <li class="active-wow-travel-works"></li>
            </ol>
            <div class="carousel-inner-wow-travel-works slide-wow-travel-works">
              <div class="home-pageslider_top-wow-travel-works carousel-item-wow-travel-works active-carousel-inner-wow-travel-works carousel-item-wow-travel-works">
                <img
                  class="d-block-wow-travel-works w-100-wow-travel-works image-carousel-wow-travel-works"
                  src="IMAGES/city-images/wow-travel-how-works-card/banner3.jpg"
                  alt="First slide"
                />
                <div class="home_slider_text_area123456l-wow-travel-works carousel-caption-wow-travel-works">
                  <h3 class="about_fdkng12154-wow-travel-works">
                    WOW Holiday Travel.
                  </h3>
                  <p class="about_fdkng-wow-travel-works">
                    Our aim is for each WOW experience to be safe and
                    comfortable from begin to finish.
                  </p>
                </div>
              </div>
            </div>

            <a
              class="carousel-control-prev-wow-travel-works"
              role="button"
              href="#"
            >
              <span class="sr-only-wow-travel-works">Previous</span>
            </a>
            <a
              class="carousel-control-next-wow-travel-works"
              role="button"
              href="#"
            ></a>
          </div>
        </div>

        <Container>
          <div className="heading-top-text-image-wow-travel-how-works">
            <h3 className="wow_community-wow-travel-how-works">
              Your Trip, Your Choice, Your Time
            </h3>
            <div className="wow_community-wow-travel-how-works-heading-text">
              It is no secret that we are avid advocates of group travel. It has
              its obvious advantages such as fair price, great company and
              safety and security. That said, it may well be that your travel
              dates do not match ours. Or you and your group of friends would
              want your own private trip. We have just the thing for you. When
              you join a Wow Worldwode Tour you become part of the Wow(family).
            </div>
          </div>
        </Container>

        <Container>
          <div class="wow-travel-how-works-page-top-image">
            <div className="wow-travel-how-works-page-top-image-inside-text">
              <i class="w-banner-quote-icon fa fa-quote-left" />
              Man cannot discover new oceans unless he has the courage to lose
              sight of the shore.
              <div className="wow-travel-how-works-page-top-image-inside-text-author-name">
                — Andre Gide
              </div>
            </div>
            {/* <img src="IMAGES/city-images/wow-community/top-image.jpg" style={{width: '100%'}}/> */}
          </div>
        </Container>

        <br />

        <div className="gradient-back-travel-how-work">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-how-work-grid-text">
                  <div className="travel-how-work-page-araea-sub-headings110">
                    Travel Healthy: Wanderlusting
                  </div>

                  <div className="travel-how-work-page-page-text">
                    You can experience and learn new things at any age, and
                    travel is the perfect way to do so. Whether you're looking
                    to relax and rejuvenate or be active and challenge yourself,
                    travel has the power to enliven the senses, ignite
                    inspiration, and make us feel young again. You will come
                    back refreshed and energized. In the words of Charlotte
                    Bronte, I feel monotony and death to be almost the same. We
                    side with her!
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-travel-how-works-card/grid/grid1.jpg"></img>
              </Col>
            </Row>
          </Container>
        </div>





















        <div class="wow-travel-work-top-area-second-area">
         <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div class="wow-travel-work-top-area-second-area-left-area">
                <i 
                className="lg-icon-wow-travel-work circle-icon-wow-travel-work primary-icon-filled-wow-travel-work fas fa-users"></i>
                  <h3>Tips for Travllers</h3>
                  <p>
                  Grab your things, pack your bags get on a plane you’re gone Life is a series of
                   good events, bad events meeting people, losing people doing the right thing, 
                   making mistakes and everything in between. Step out, explore, connect learn,
                   grow, inspire. The time to live is now.
                  </p>
                </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div class="wow-travel-work-top-area-second-area-left-area">
                <i 
                className="lg-icon-wow-travel-work circle-icon-wow-travel-work primary-icon-filled-wow-travel-work fas fa-car"></i>
                  <h3>How we are supporting Leaders</h3>
                  <p>
                    Those of you who pick to force are playing a critical
                    function in helping our communities right now. To help guard
                    you, we are distributing cleansing materials like hand
                    sanitizer and facemasks — at no cost to you. We are
                    additionally exploring new earning opportunities and
                    approaches to prevent money via existing programs.
                  </p>
                </div>
                </Col>
            </Row>
          </Container>
        </div>

















        <Container>
<div class="wow-travel-work-page-top-image">
  <img src="IMAGES/city-images/wow-travel-how-works-card/wowtrip-gifts.jpg" style={{width: '100%'}}/>

  </div>
  </Container>













  <Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-travel-work">
        <div  className="heading-secondary-section-wow-travel-work ">
            WOW Travel Tips
        </div>
        </div>


</Container>






<Container>




<div className="wow-travel-work-top-area-third-area-contet">
<div className="wow-travel-work-top-area-third-area-contetn">
    <p>That is why we require all our Travellers and Managers to uphold an excessive popular of conduct,
         and to observe the law — and our Community Guidelines. Failure to meet the expectations of 
         the Community Guidelines can result in permanent deactivation from WOW.</p>
    
    <h3>Plan as little as possible</h3>
    <p>One of the first lessons on the road are that your plans will nearly always change. 
      You’ll arrive in a place and hate it and want to leave immediately, or you’ll fall in love with 
      a destination and want to spend longer there. You’ll make friends with a group of awesome people
       and want to change your plans so you can travel with them for longer, or you’ll find out about
        an amazing-sounding town that’s nearby and want to head there instead.</p>
         
    <h3>Patience Is Important</h3>
    <p>WOW is built at the energy of a diverse and inclusive community. Don’t sweat the stuff 
      you can’t control. Life is much too short to be angry & annoyed all the time. Did you miss
       your bus? No worries, there will be another one.
       Just take a deep breath and remind yourself that it could be worse.</p>
        
        <h3>Observe Daily Life Around You</h3>
    <p>If you really want to get a feel for the pulse of a place, I recommend spending a few hours sitting
       in a park or on a busy street corner by yourself just watching day to day life happen 
       in front of you.

Slow down your train of thought and pay close attention to the details around you. The smells,
 the colors, human interactions, and sounds. It’s a kind of meditation — and you’ll see stuff you never 
 noticed before.</p>


 <h3>Slow Down To Enjoy Your Trip</h3>
    <p>Don’t try to cram 6 countries into 6 weeks of travel. All the good stuff happens when you really
       take the time to explore. You’ll learn about activities that aren’t in your guidebook and meet people
        who are eager to show you around.

I can honestly say that NONE of my best travel experiences happened within the first few days 
of arriving somewhere. Spend more time in fewer places for maximum enjoyment. I promise you’ll have a
 much better time!</p>



 <h3>Unpack as Little as Possible</h3>
    <p>There is nothing more frustrating than losing something when you’re travelling.

And when do things generally get lost? It’s moving from one place to another.

I’ve found the best way to avoid this is just to not unpack!</p>

<p> Wow Sujjests do unpack have a system.</p>

<ul>
        <li>
        Washbag always hangs in the bathroom.
            </li>
        <li> Wallet and keys always sit on the desk.
        </li>
        
        <li> Kindle and chargers on the bedside table.</li>
        <li> Folder of essential documents straight into the safe.</li>
        </ul>



 <h3>Wake Up Early To Avoid Crowds</h3>
    <p>Rise before sunrise to have the best attractions all to yourself while avoiding large tourist crowds.
       It’s also a magical time for photos due to soft diffused light, and it’s usually easier to interact
        with locals getting ready for their day.

Want those postcard Instagram travel shots? You need to get out there before everyone else shows up. Sketchy
 areas are less dangerous in the morning too.</p>



 <h3>Volunteer Occasionally</h3>
    <p>Make it a point to volunteer some of your time for worthwhile projects when traveling.
       Not only is it a very rewarding experience,
       but you’ll often learn more about the country and its people while also making new friends.

 Spend more time in fewer places for maximum enjoyment. I promise you’ll have a
 much better time!</p>



 <h3>Break Out Of Your Comfort Zone</h3>
    <p>Challenge yourself to try things that normally give you anxiety. The more you do this,
       the more that anxiety will fade away. Not a hiker? Go on more hikes. Have trouble talking to
        strangers? Talk to everyone. Scared of weird food? Eat the weirdest thing you can find.

I can honestly say that NONE of my best travel experiences happened within the first few days 
of arriving somewhere. Spend more time in fewer places for maximum enjoyment. I promise you’ll have a
 much better time!</p>



 <h3>Learn A Few Foreign Words</h3>
    <p>There’s no need to be fluent in order to visit a foreign country. However, taking the time to learn 
      a few phrases in the local language can improve your travel experience. Hello, please, thank you,
       nice to meet you, excuse me, sorry, and “can I take your portrait” are some of my favorites.

Not being able to communicate while traveling can be frustrating, but remember that this is your problem,
 not theirs. Raising your voice doesn’t magically help Mexican’s understand English. Try hand signals, 
 images on your phone, or Google Translate!</p>
            

    <h3>Traveler rules:</h3>
    <p>You will definitely look like a fool many times when traveling to new places.
       Rather than get embarrassed, laugh at yourself.
       Don’t be afraid to screw up, and don’t take life so seriously. Relax!</p>
    <p>To make certain a secure and respectful ride, do no longer:</p>
    
    <ul>
        <li>
             Violate WOW’s weapons policy through bringing a weapon alongside on a
              WOW experience or on WOW property
            </li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,
           expedita labore dicta dolorem ducimus aperiam eos laudantium
           iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!
        </li>
        
        <li> iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!</li>
        <li> iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!e</li>
        <li>iusto error! Tempore quod porro nam quos odit excepturi! Incidunt error labore quidem!uidelines</li>
        <li> Pay your fare in cash (cash pointers are OK)</li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, sex, marital status, gender 
             identity, age, army status, or any different feature protected below law</li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,</li>
        <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,, or gestures</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,eat alcohol 
          at some point of your ride</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,y</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus,rney, 
          coupon phishing, or manipulating opinions
          for experience credit</li>
        <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, guidelines or WOW’s 
        privacy policy and terms &amp; conditions</li>
        </ul>
        





        
        </div>






        </div>
</Container>




















      </div>
    );
  }
}
export default Travelworks;
