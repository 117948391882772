import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Media, Col, Figure } from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../RentalCarsWowHoliday/RentalCars.scss";
import RentalCarsHireForm from "../RentalCarsWowHoliday/RentalCarsHireForm";
// import ServiceArea from "./servicearea";
import { Collapse } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Panel } = Collapse;

class Rental_Cars extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      showHide13passengers: true,
      showHide47passengers: false,
      showHideAllTaxis: false,
      cars: [
        { name: "Audi", country: "Germany" },
        { name: "BMW", country: "Germany" },
        { name: "Chevrolet", country: "USA" },
        { name: "Citroen", country: "France" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Mercedes-Benz", country: "Germany" },
        { name: "Renault", country: "France" },
        { name: "Seat", country: "Spain" },
        { name: "Dodge", country: "USA" },
        { name: "BMW", country: "Germany" },
        { name: "Tesla", country: "USA" },
        { name: "Volkswagen", country: "Germany" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Jaguar", country: "United Kingdom" },
        { name: "GMC", country: "USA" },
        { name: "Bentley", country: "United Kingdom" },
      ],
      numberOfitemsShown: 1,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  hideComponent(name) {
    switch (name) {
      case "showHide13passengers":
        this.setState({
          // showHide13passengers: !this.state.showHide13passengers,
          showHide13passengers: true,
          showHide47passengers: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHide47passengers":
        this.setState({
          // showHide47passengers: !this.state.showHide47passengers,
          showHide47passengers: true,
          showHide13passengers: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHideAllTaxis":
        this.setState({
          // showHideAllTaxis: !this.state.showHideAllTaxis,
          showHideAllTaxis: true,
          showHide13passengers: false,
          showHide47passengers: false,
        });
        break;
      case "showHide13passengersimg":
        this.setState({
          showHide13passengersimg: !this.state.showHide13passengersimg,
        });
      default:
      // null;
    }
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const {
      showHide13passengers,
      showHide13passengersimg,
      showHide47passengers,
      showHideAllTaxis,
    } = this.state;
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>
        <div className="td-post-header-holder-RentalCars-mainClass">
        <div className="td-post-header-holder-RentalCars td-image-gradient-RentalCars">
          <div className="td-post-featured-image-RentalCars">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-RentalCars"
              src="IMAGES/city-images/rental-cars/bg-image.png"
            />

            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-RentalCars2"
              src="IMAGES/city-images/rental-cars/rent-car-mobile-view.png"
            />
            <div class="join-us-our-content">
              <div class="MuiContainer-root MuiContainer-maxWidthLg">
                {/* <div class="airportTaxi-wow-holiday-home-our-story">
                  <h1 class="airportTaxi-wow-holiday-our-team-heading">
                    Airport Taxi
                  </h1>
                  <h3
                    className="text-in-h3-airportTaxi"
                    style={{
                      color: "#ffffff",
                      paddingLeft: "0%",
                      textAlign: "left",
                      fontSize: "25px",
                    }}
                  >
                    WITH LATEST TECHNOLOGICAL TRENDS
                  </h3>
                  <h5
                    style={{
                      color: "#ffffff",
                      paddingLeft: "0%",
                      fontSize: "20px",
                    }}
                    className="airportTaxi-banner-top-co"
                  >
                    WOW Easy transportation between the airport and your accommodations.
                  </h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        </div>

        <div className="wow-RentalCars--bg">
          <Container>
            <Row>
              <div className="wow-RentalCars--4">
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div className="l-best-nz__tile">
                    <div className="l-best-nz__tile-icon">
                      <span
                        className="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i class="fas fa-car user-icon-RentalCars-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-RentalCars">
                        <b>Guaranteed pick-up service</b>
                        <br />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i class="fab fa-accessible-icon user-icon-RentalCars-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-RentalCars">
                        <b>Professional, local experts</b>
                        <br />
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i className="fas fa-credit-card user-icon-RentalCars-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-RentalCars">
                        <b>Book with confidence</b>
                        <br />
                      </div>{" "}
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-RentalCars-wowholiday">
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="padding-in-travel-RentalCars-grid-text-wowholiday">
                  <div className="travel-RentalCars-page-araea-sub-headings110-wowholiday">
                    Rental Cars!
                  </div>

                  <h6 class="our-story-main-araea-headings12-RentalCars-wowholiday">
                    Easy airport transfers to and from your accommodation.
                  </h6>

                  <div
                    style={{ textAlign: "center" }}
                    className="text-written-inside-rentalcars-description-section"
                  >
                    <span className="text-tickmark-rental-cars-belowsection">
                      <i
                        class="fa fa-check-circle"
                        aria-hidden="true"
                        style={{ color: "#ff1b17", paddingRight: ".7rem" }}
                      ></i>
                      Luxuary cars on lowest-rates
                    </span>

                    <span className="text-tickmark-rental-cars-belowsection">
                      <i
                        class="fa fa-check-circle"
                        aria-hidden="true"
                        style={{ color: "#ff1b17", paddingRight: ".7rem" }}
                      ></i>
                      Guaranteed pick-up service
                    </span>

                    <span className="text-tickmark-rental-cars-belowsection">
                      <i
                        class="fa fa-check-circle"
                        aria-hidden="true"
                        style={{ color: "#ff1b17", paddingRight: ".7rem" }}
                      ></i>
                      Free cancellations most bookings
                    </span>
                  </div>

                  <Row style={{ margin: "2rem 0" }}>
                    <Col lg={4} xl={4}>
                      <center>
                        <div className="figure-section-rental-cars-belowsection">
                          <Figure>
                            <Figure.Image
                              width={130}
                              height={130}
                              alt="171x180"
                              src="IMAGES/city-images/rental-cars/asset 13.png"
                            />
                            <Figure.Caption>New & Used</Figure.Caption>
                            <Figure.Caption>
                              <div className="subTitle-text-wow-rentalcars">
                                Your driver tracks your flight and waits for you
                                if it's delayed
                              </div>
                            </Figure.Caption>
                          </Figure>
                        </div>
                      </center>
                    </Col>

                    <Col lg={4} xl={4}>
                      <center>
                        <div className="figure-section-rental-cars-belowsection">
                          <Figure>
                            <Figure.Image
                              width={130}
                              height={130}
                              alt="171x180"
                              src="IMAGES/city-images/rental-cars/car-condition.png"
                            />
                            <Figure.Caption>
                              Car Condition
                              <div className="subTitle-text-wow-rentalcars">
                                Select car accordingly to your preferance. You got option of choosing new & used car
                              </div>
                            </Figure.Caption>
                          </Figure>
                        </div>
                      </center>
                    </Col>

                    <Col lg={4} xl={4}>
                      <center>
                        <div className="figure-section-rental-cars-belowsection">
                          <Figure>
                            <Figure.Image
                              width={130}
                              height={130}
                              alt="171x180"
                              src="IMAGES/city-images/rental-cars/maintence-packages.png"
                            />
                            <Figure.Caption>
                            Prolongation Packages
                              <div className="subTitle-text-wow-rentalcars">
                                Maintainence charges shall be applied to the person who's hiring a car
                              </div>
                            </Figure.Caption>
                          </Figure>
                        </div>
                      </center>
                    </Col>

                    <Col lg={4} xl={4}>
                      <center>
                        <div className="figure-section-rental-cars-belowsection">
                          <Figure>
                            <Figure.Image
                              width={130}
                              height={130}
                              alt="171x180"
                              src="IMAGES/city-images/rental-cars/asset 11.png"
                            />
                            <Figure.Caption>Buy, Sell, Trade</Figure.Caption>
                            <Figure.Caption>
                              <div className="subTitle-text-wow-rentalcars">
                                Your driver tracks your flight and waits for you
                                if it's delayed
                              </div>
                            </Figure.Caption>
                          </Figure>
                        </div>
                      </center>
                    </Col>

                    <Col lg={4} xl={4}>
                      <center>
                        <div className="figure-section-rental-cars-belowsection">
                          <Figure>
                            <Figure.Image
                              width={130}
                              height={130}
                              alt="171x180"
                              src="IMAGES/city-images/rental-cars/test-drive.png"
                            />
                            <Figure.Caption>
                              Free Test Drives
                              <div className="subTitle-text-wow-rentalcars">
                                We offer free test drives for our users to experience our sevices
                              </div>
                            </Figure.Caption>
                          </Figure>
                        </div>
                      </center>
                    </Col>

                    <Col lg={4} xl={4}>
                      <center>
                        <div className="figure-section-rental-cars-belowsection">
                          <Figure>
                            <Figure.Image
                              width={130}
                              height={130}
                              alt="171x180"
                              src="IMAGES/city-images/rental-cars/car-history.png"
                            />
                            <Figure.Caption>
                              Vehicle History
                              <div className="subTitle-text-wow-rentalcars">
                                All vehicles details and its past record and history shall be provided
                              </div>
                            </Figure.Caption>
                          </Figure>
                        </div>
                      </center>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="et_pb_bottom_inside_divider"></div>
        </div>

        <div className="td-post-header-holder-RentalCars-bookform">
          {/* <div className="td-post-header-holder-RentalCars td-image-gradient-RentalCars"> */}
          <div className="td-post-featured-image-RentalCars">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-RentalCars"
              src="IMAGES/city-images/rental-cars/book-your-ride-form.png"
            />
            <div class="join-us-our-content-rentalcars">
              <div class="MuiContainer-root MuiContainer-maxWidthLg">
                <div class="airportTaxi-wow-holiday-home-our-story">
                  <RentalCarsHireForm />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-RentalCars">
            <div className="heading_secondary_uk_section_wow_RentalCars">
              WOW: Airport to city centre transfers
            </div>
          </div>
        </Container>

        {/* <Container> */}
        <div className="images-shown-where-wow-services-available-rentalcars">
          <Row>
            <Col
              lg={3}
              xl={3}
              md={3}
              sm={6}
              xs={12}
              className="cols-section-image-thumb-section-rentalcars"
            >
              <div className="image-thumb-section-rentalcars">
                <Link className="link-class-img-top-things-to-do image-thumb-rentalcars">
                  <img
                    className="img-top-things-to-do"
                    src="IMAGES/city-images/rental-cars/wow-servicescities/dubai2.png"
                  />
                </Link>
                <h3>Dubai, UAE</h3>
                <h6>From 50$</h6>
              </div>
            </Col>

            <Col
              lg={3}
              xl={3}
              md={3}
              sm={6}
              xs={12}
              className="cols-section-image-thumb-section-rentalcars"
            >
              <div className="image-thumb-section-rentalcars">
                <Link className="link-class-img-top-things-to-do image-thumb-rentalcars">
                  <img
                    className="img-top-things-to-do"
                    src="IMAGES/city-images/rental-cars/wow-servicescities/pakistan2.png"
                  />
                </Link>
                <h3>Pakistan</h3>
                <h6>From 66$</h6>
              </div>
            </Col>

            <Col
              lg={3}
              xl={3}
              md={3}
              sm={6}
              xs={12}
              className="cols-section-image-thumb-section-rentalcars"
            >
              <div className="image-thumb-section-rentalcars">
                <Link className="link-class-img-top-things-to-do image-thumb-rentalcars">
                  <img
                    className="img-top-things-to-do"
                    src="IMAGES/city-images/rental-cars/wow-servicescities/america2.png"
                  />
                </Link>
                <h3>America</h3>
                <h6>From 26$</h6>
              </div>
            </Col>

            <Col
              lg={3}
              xl={3}
              md={3}
              sm={6}
              xs={12}
              className="cols-section-image-thumb-section-rentalcars"
            >
              <div className="image-thumb-section-rentalcars">
                <Link className="link-class-img-top-things-to-do image-thumb-rentalcars">
                  <img
                    className="img-top-things-to-do"
                    src="IMAGES/city-images/rental-cars/wow-servicescities/england2.png"
                  />
                </Link>
                <h3>England</h3>
                <h6>From 36$</h6>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
        <br />

        <Container style={{ padding: "2rem 0rem" }}>
          <div className="heading-primary-in-wow-holiday-cards-rentalcars text-box-inside-background-image-of-new-design">
            <span className="heading-primary-main-rentalcars">
              Holiday Travel Cars to Hire
            </span>
            <span className="heading-primary-sub-rentalcars">
              A car for every budget and occasion provided by WOW
            </span>
          </div>

          {/* wow Economy cars section //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

          <div className="cars-section-Rental-Cars-holiday-grid">
            {/* <div className="heading-text-cars-section-Rental-Cars-holiday-grid">
              WOW Economy Cars
            </div> */}
            <Row
              style={{ padding: "0 1rem" }}
              className="margin-in-row-rentalcars-abovecars-area"
            >
              <Col lg={3} xl={3} md={3} sm={6} xs={12}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src="IMAGES/city-images/rental-cars/wow-car-categories/1-Wow-economy/2 Lexus ES 300 HYBRID (P).png"
                  />
                  <Figure.Caption>Standard</Figure.Caption>
                </Figure>
              </Col>

              <Col lg={3} xl={3} md={3} sm={6} xs={12}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src="IMAGES/city-images/rental-cars/wow-car-categories/1-Wow-economy/5-Chevrolet-IMPALA-(LIMO).png"
                  />
                  <Figure.Caption>Executive</Figure.Caption>
                </Figure>
              </Col>

              <Col lg={3} xl={3} md={3} sm={6} xs={12}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src="IMAGES/city-images/rental-cars/wow-car-categories/2-WOW-Family/4-GMC.png"
                  />
                  <Figure.Caption>Carrier</Figure.Caption>
                </Figure>
              </Col>

              <Col lg={3} xl={3} md={3} sm={6} xs={12}>
                <Figure>
                  <Figure.Image
                    width={171}
                    height={180}
                    alt="171x180"
                    src="IMAGES/city-images/rental-cars/wow-car-categories/2-WOW-Family/6-Mercedes-(V-Class).png"
                  />
                  <Figure.Caption>Executive Carrier</Figure.Caption>
                </Figure>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}
export default Rental_Cars;
