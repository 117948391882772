import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
// import GoogleMapReact from 'google-map-react';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  ButtonToolbar,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBWhL0GRX48cEBzpXBN_vHJ_VYxKnFBVh4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `350px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={12} defaultCenter={{ lat: 25.204849, lng: 55.270782 }}>
    <Marker position={{ lat: 25.204849, lng: 55.270782 }} />
  </GoogleMap>
));
class Wowholidaycontact extends Component {

  static defaultProps = {
    center: {
      // lat: 25.229921,
      // lng: 55.286422,
      lat: 25.201849,
      lng: 55.269782,
      lat1: 25.215958,
      lng1: 55.745682,
      height: "300px",
    },
    zoom: 13
  };

    state = {
        isMarkerShown: false,
      }
    
      componentDidMount() {
        this.delayedShowMarker()
      }
    
      delayedShowMarker = () => {
        setTimeout(() => {
          this.setState({ isMarkerShown: true })
        }, 3000)
      }
    
      handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
      }




  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  
  render() {
    return (
      <div>




<div className="contact-us-wow-holiday-our-container">
       <div class="join-us-our-content">



       <div class="MuiContainer-root MuiContainer-maxWidthLg">
      <div class="contact-us-wow-holiday-home-our-story">
        <h1 class="contact-us-wow-holiday-our-team-heading" ></h1>
        
          </div>
          </div>




       </div>
       </div>
















        <Container>
          <Row>
            <Col xs={12} sm={6} lg={7} md={7}>
              
              <div className="headings-written-in-contact-us-wow-holiday">CONTACT FORM</div>

              <div className="border-behind-contact-us-form-wow-holiday"></div>

              <div className="context-under-contact-us-form-wow-holiday">
                WOW Worldwide Holiday is simply dummy text of the printing and
                typesetting industry.
              </div>
              <Form>
              <div className="form-group">
                <label>Name </label>
                <input
                  type="text"
                  name="name"
                  placeholder="  NAME"
                  className="form-control input-name-contact-wow-holiday"
                  aria-required="true"
                  aria-required="false"
                  required
                />
              </div>

              <div className="form-group">
                <label>Phone No </label>
                <input
                  type="tel"
                  name="name"
                  placeholder=" PHONE #"
                  className="form-control"
                  aria-required="true"
                  aria-required="false"
                  required
                />
              </div>
              <div className="form-group">
                <label> Email</label>
                <input
                  type="email"
                  placeholder=" YOUR E-MAIL"
                  name="name"
                  className="form-control"
                  aria-required="true"
                  aria-required="false"
                  required
                />
              </div>
              <div className="form-group">
                <label>Message </label>
                <textarea 
                cols="40"
                 rows="5" name="message"
                 placeholder="MESSAGE"
                  class="textarea form-control"
                  required
                  >

                  </textarea>
              </div>

              {/* <a
                        id="contact"
                        className=" contact-us-button-in-wow-holiday  m_btn_main-contact t_mid-contact t14-contact   "
                        data-track-event="Email Subscription|Newsletter Subscribed"
                        data-gatag="Main Page|Subcribe"
                      >
                        Subscribe
                      </a> */}


                      <button class="btn-send submit-buttom" type="submit">Send Message</button>
                      </Form>
             
            </Col>











           

            <Col xs={12} sm={6} lg={5} md={5}>
            <div className="headings-written-in-contact-us-wow-holiday">OFFICE ADDRESS</div>

            <div className="border-behind-contact-us-form-wow-holiday"></div>
            <br></br>

<Row className="margin-icons-in-contact-wow-holiday">
    
            <Col  xs={2} md={2} sm={1} lg={2}>

            <i class="fa fa-clock-o clock-icon-wow-holiday-contact"></i>

</Col>


<Col className="" xs={10} md={10} sm={11} lg={8} >

<div class="ride-header-data-flex">


<div class="icon-clock-contact-context">Monday to Saturday</div>
</div>

</Col>




</Row>




<Row className="margin-icons-in-contact-wow-holiday">
    
            <Col  xs={2} md={2} sm={1} lg={2}>

            <i class="fa fa-map-marker clock-icon-wow-holiday-contact"></i>

</Col>


<Col className="" xs={10} md={10} sm={11} lg={8} >

<div class="ride-header-data-flex">


<div class="icon-clock-contact-context-address">
WOW, Worldwide Holiday, Office No. 3403 - 34th Floor - Burj Al Salam Tower - 
Trade Centre 1 - Sheikh Zayed Road - Dubai - United Arab Emirates - P.O. Box No. 89498.</div>
</div>

</Col>




</Row>





<Row className="margin-icons-in-contact-wow-holiday">
    
            <Col  xs={2} md={2} sm={1} lg={2}>

            <i class="fa fa-phone clock-icon-wow-holiday-contact"></i>

</Col>


<Col className="" xs={10} md={10} sm={11} lg={8} >

<div class="ride-header-data-flex">


<div class="icon-clock-contact-context">  +9714 2555111</div>
</div>

</Col>




</Row>











<Row className="margin-icons-in-contact-wow-holiday">
    
            {/* <Col  xs={2} md={2} sm={1} lg={2}>

            <i class="fa fa-envelope icon-clock-wow-holiday-contact"></i>

</Col>


<Col className="" xs={10} md={10} sm={11} lg={8} >

<div class="ride-header-data-flex">


<div class="icon-clock-contact-context">info@wowets.com</div>
</div>

</Col> */}





<Col  xs={11} md={10} sm={11} lg={8} >

<div className="timing-contact-us-wow-holiday">From 08:30am - 01:00pm </div>


</Col>


<Col  xs={11} md={10} sm={11} lg={8} >

<div className="timing-contact-us-wow-holiday">From 5:00pm - 09:00pm </div>


</Col>



<Col xs={12} sm={12} lg={6} md={12}>
            <div className="google-maps-in-contact-us-wow-holiday"><a>Open With Google Maps</a></div>
            </Col>



            <Col xs={12} sm={12} lg={12} md={12}>
           <div className="map-in-mobile-view-in-contact-us-wow-holiday" >
        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBWhL0GRX48cEBzpXBN_vHJ_VYxKnFBVh4' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={25.204849}
            lng={55.270782}
            text="Marker"
          />
        </GoogleMapReact> */}
        {/* <MyMapComponent isMarkerShown /> */}
  <MyMapComponent key="map" />
      </div>
            </Col>

            

</Row>
           
            </Col>
          </Row>




        </Container>
















<div className="backgroung-color-below-contact-form-section-holiday" style={{marginTop:'15px'}}>
<Container>
  <div className="padding-in-form-behind-contact-wow-holiday">
        <Row >

          <Col xs={12} sm={12} lg={4} md={3}>
         
          <div className="icon-padding-in-wow-holiday-contact">
           


             <div> 
               {/* <i className="fab fa-facebook-f social-icons-in-contact-us-wow-holiday "></i>
             <i className="fab fa-twitter social-icons-in-contact-us-wow-holiday"></i>
             <i className="fab fa-instagram social-icons-in-contact-us-wow-holiday"></i>
             <i className="fab fa-pinterest-p social-icons-in-contact-us-wow-holiday"></i>
             <i className="fab fa-youtube social-icons-in-contact-us-wow-holiday"></i> */}
             <img  className="wow-logo-in-contact-below-forms-holiday"
                    src="IMAGES/WOWholiday-iconlogo.png"></img>




             </div>



             </div>

          </Col>
          
          <Col xs={12} sm={12} lg={4} md={4}>
          <div className="icon-padding-in-wow-holiday-contact">
            <i className="fa fa-envelope marker-in-contact-us-wow-holiday"></i>
            <div style={{color:'white'}}> <b className="text-written-below-contact-icon-form-wow-holiday">Email</b>
             <br/> info@wowworldwideholiday.com
             </div>
             </div>
         
          </Col>

          <Col xs={12} sm={12} lg={4} md={5}>
          <div className="icon-padding-in-wow-holiday-contact">
  <i className="fa fa-map-marker  marker-in-contact-us-wow-holiday" aria-hidden="true"></i>
  <div style={{color:'white'}}> <b className="text-written-below-contact-icon-form-wow-holiday">Address</b>
             <br/> <p className="context-in-icons-above-footer-contact-us-holiday-page">34th floor Burj Al Salam Tower,  Dubai, UAE</p>
             </div>
             
   </div>
          </Col>

        
        </Row>
        </div>
        </Container>
        </div>
        





















      </div>
    );
  }
}
export default Wowholidaycontact;











































{
  /* <div className="mobile-menu-area">
            <div className="container mean-container">
              <div className="mean-bar">
             
                <a
                  href="#"
                  className="meanmenu-reveal meanclose"
                  style={{
                    right: "0px",
                    left: "auto",
                    textAlign: "center",
                    textIndent: "0px",
                    fontSize: "18px"
                  }}
                >
                  X
                </a>
                <nav className="mean-nav" >
                  <ul style={{ display: "block" }}>
                    <li className="current">
                      <a href="/">
                        <i className="fa fa-home"></i>
                      </a>
                    </li>
                    <li>
                      <a href="/practices">Practices</a>
                      <ul style={{ display: "none" }}>
                        <li>
                          <a href="/practice?id=28">Arbitration</a>
                        </li>
                        <li>
                          <a href="/practice?id=29">Criminal Cases</a>
                        </li>
                        <li>
                          <a href="/practice?id=30">Interpol Cases</a>
                        </li>
                        <li>
                          <a href="/practice?id=31">
                            {" "}
                            Corporate &amp; Commercial Law
                          </a>
                        </li>
                        <li>
                          <a href="/practice?id=32">
                            {" "}
                            LABOUR &amp; IMMIGRATION LAW
                          </a>
                        </li>
                        <li>
                          <a href="/practice?id=33">
                            Real Estate and Property Disputes
                          </a>
                        </li>
                        <li>
                          <a href="/practice?id=34">Private Notary Services</a>
                        </li>
                        <li>
                          <a href="/practice?id=35">Companies Establishment</a>
                        </li>
                        <li>
                          <a href="/practice?id=36">
                            {" "}
                            PERSONAL STATUS &amp; FAMILY LAW
                          </a>
                        </li>
                      </ul>
                      <a
                        className="mean-expand"
                        href="#"
                        style={{ fontSize: "18px" }}
                      >
                        +
                      </a>
                    </li>

                    <li>
                      <a href="/team">Team</a>
                    </li>

                    <li>
                      <a href="/gallery">Gallery</a>
                    </li>

                    <li>
                      <a href="/clients">Our Clients</a>
                    </li>

                    <li>
                      <a href="/about">About Us</a>
                    </li>

                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>

                    <li>
                      <a onclick="switchLang('ar')" className=" text-center">
                        <img
                          className="language-icon"
                          src="/common/images/flags/ar.png"
                          alt="العربية"
                        />
                        العربية
                      </a>
                    </li>
                    <li className="mean-last">
                      <a onclick="switchLang('en')" className=" text-center">
                        <img
                          classNameName="language-icon"
                          src="/common/images/flags/en.png"
                          alt="English"
                        />
                        English
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div classNameName="row">
                <div classNameName="col-md-12">
                  <div classNameName="mobile-menu">
                    <div classNameName="mean-push"></div>
                    <nav id="dropdown" style={{ display: "none" }}>
                      <ul>
                        <li classNameName="current">
                          <a href="/">
                            <i classNameName="fa fa-home"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/practices">Practices</a>
                          <ul>
                            <li>
                              <a href="/practice?id=28">Arbitration</a>
                            </li>
                            <li>
                              <a href="/practice?id=29">Criminal Cases</a>
                            </li>
                            <li>
                              <a href="/practice?id=30">Interpol Cases</a>
                            </li>
                            <li>
                              <a href="/practice?id=31">
                                {" "}
                                Corporate &amp; Commercial Law
                              </a>
                            </li>
                            <li>
                              <a href="/practice?id=32">
                                {" "}
                                LABOUR &amp; IMMIGRATION LAW
                              </a>
                            </li>
                            <li>
                              <a href="/practice?id=33">
                                Real Estate and Property Disputes
                              </a>
                            </li>
                            <li>
                              <a href="/practice?id=34">
                                Private Notary Services
                              </a>
                            </li>
                            <li>
                              <a href="/practice?id=35">
                                Companies Establishment
                              </a>
                            </li>
                            <li>
                              <a href="/practice?id=36">
                                {" "}
                                PERSONAL STATUS &amp; FAMILY LAW
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <a href="/team">Team</a>
                        </li>

                        <li>
                          <a href="/gallery">Gallery</a>
                        </li>

                        <li>
                          <a href="/clients">Our Clients</a>
                        </li>

                        <li>
                          <a href="/about">About Us</a>
                        </li>

                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>

                        <li>
                          <a onclick="switchLang('ar')" classNameName=" text-center">
                            <img
                              classNameName="language-icon"
                              src="/common/images/flags/ar.png"
                              alt="العربية"
                            />
                            العربية
                          </a>
                        </li>
                        <li>
                          <a onclick="switchLang('en')" classNameName=" text-center">
                            <img
                              classNameName="language-icon"
                              src="/common/images/flags/en.png"
                              alt="English"
                            />
                            English
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div> */
}









































































