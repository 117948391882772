import React, { Component } from "react";

import FontAwesome from 'react-fontawesome'


import {
  Form,
  Container,
  Card,
  Row,
  Carousel,
 
  Col
} from "react-bootstrap";

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };





class Context extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date
    });
  }
  render() {
    return (


<Container>





        <br></br>

        <Container>
        <Container>


        <left>
            <p className="context-news-header">WHO WILL DRIVE YOUR JOURNEY WITH WOW? A 'LEADER'</p>

            <div class="date-context">
        <p>  {this.state.date}</p>
      </div>
        </left> 
        </Container>
        </Container>

        <img
      className="image-in-context-news"
      src="IMAGES/news12345678.jpg"
      alt="First slide"
      style={{width:'100%', height: '100%'}}
    />

<Container>
        <Container>


        <left>
            <p className="context-news-text-written" style={{marginTop: '30px'}}>Here Comes the first major concern regarding your ride, you might be thinking of who the leader is? And 
            alot more concerns might strike your mind regarding your safety and security with a leader. Let us cover all your concerns to 
            make you believe that you are safety and security with the leader. Let us cover all your concerns to make you believe that you are safe travelling with our leaders.
             </p>
             <br></br>
             <p className="context-news-text-writtens">So, we start with the first and foremost concern:</p>
            
            <p className="context-news-headings">Who The Leader is?</p>
         
            <p className="context-news-text-writtens">
                A leader is a driver who will take your ride's responsibility to pick you from your source 
                position and drops you at your desired destination  safely and securely. Leaders are the most important
                persons to accomplish our aim of providing the best and comfortable services and so does we guide and train
                the accordingly. 

            </p>

            <p className="context-news-headings">Why do we call our drivers a leader?</p>
         
            <p className="context-news-text-writtens">
                We think everyone in our society and especially the ones working with or for us needs respect
                either it's a servant, office boy, manager, or anyother employee. To imprint our efforts of positive
                effects on society we initiate our cause from this step by giving our drivers respect and post of 
                the leader. <br></br> <br></br>   
                Respect isn't the only factor behind it, but another reason is their responsibilities. Our
                leader, just reflecting the basic means of leader, will be the one who dosen't only drive  you 
                through the ride, but also leads and guides you to make your ride a memorable and comfortable
                journey.
                </p>

                <p className="context-news-headings">Why do we call our drivers a leader?</p>
         
            <p className="context-news-text-writtens">
                We think everyone in our society and especially the ones working with or for us needs respect
                either it's a servant, office boy, manager, or anyother employee. To imprint our efforts of positive
                effects on society we initiate our cause from this step by giving our drivers respect and post of 
                the leader. <br></br> <br></br>   
                Respect isn't the only factor behind it, but another reason is their responsibilities. Our
                leader, just reflecting the basic means of leader, will be the one who dosen't only drive  you 
                through the ride, but also leads and guides you to make your ride a memorable and comfortable
                journey.
                </p>

                <p className="context-news-headings">Why do we call our drivers a leader?</p>
         
            <p className="context-news-text-writtens">
                We think everyone in our society and especially the ones working with or for us needs respect
                either it's a servant, office boy, manager, or anyother employee. To imprint our efforts of positive
                effects on society we initiate our cause from this step by giving our drivers respect and post of 
                the leader. <br></br> <br></br>   
                Respect isn't the only factor behind it, but another reason is their responsibilities. Our
                leader, just reflecting the basic means of leader, will be the one who dosen't only drive  you 
                through the ride, but also leads and guides you to make your ride a memorable and comfortable
                journey.
                </p>
                <br></br>


            
        </left> 
        <br></br>
        <Row>
            <Col xs={6} md={6} lg={6} sm={6} >
                <div>
                <FontAwesome className="super-crazy-color"
        name="fa fa-reply"
        size="3x"
        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color:'#565656' }}/>
                

                </div>
               
        
                <div>
                    <p className="context-footer-written">
                        WHO WILL DRIVE YOUR JOURNEY
                        
                    </p>
                </div>
                
                <div>
                    <p className="context-footer-written-go-next">
                        Previous News
                        
                    </p>
                </div>
            </Col>
            <Col xs={6} md={6} lg={6} sm={6} style={{textAlign:'right'}}>
            <div>
                <FontAwesome className="super-crazy-color"
        name="fa fa-share"
        size="3x"
        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', color:'#565656' }}/>
                

                </div>
                <div>
                    <p className="context-footer-written">
                        WOW IS FINALLY HERE
                        
                    </p>
                </div>
                
                <div>
                    <p className="context-footer-written-go-next">
                        Next News
                        
                    </p>
                </div>
            </Col>
        </Row>
        <br></br>


        </Container>
        </Container>

       
    

           
        

</Container>





 



    );
  }
}
export default Context;