import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
  Button,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import '../Wowholidayblogpost2/blogpost2.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Blogpost3 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>



        <div className="td-post-header-holder-blogcard3 td-image-gradient-blogcard3">
          <div className="td-post-featured-image-blogcard3">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard3"
              src="IMAGES/city-images/wow-blog/additional-cards/blog3/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard3">
              <ul className="td-category-blogcard3">
                <li className="entry-category-blogcard3">
                  <a
                    target="blank"
                    href="https://www.ustravel.org/issues/emergency-readiness"
                  >
                    Travel Community
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard3">
              WOW NEWS & TRENDS BLOGS
              </h1>

              <h4 className="entry-title-two-blogcard3">
              Looking out for the WOW Community during an emergency...
              </h4>

              <div className="td-module-meta-info-blogcard3">
                <span className="td-post-date-blogcard3">
                  <time
                    className="entry-date-blogcard3 updated td-module-date-blogcard3"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                   Feb 12, 2020
                  </time>
                </span>

                <div className="td-post-views-blogcard3">
                  <i className="fa fa-eye view-icon-views-blogcard3"></i>
                  <span className="td-nr-views-10968">12035</span>
                </div>
              </div>
            </header>
          </center>
        </div>














        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard3">
            <div className="heading-secondary-uk-section-wow-blogscard3 ">
            WOW HELP & TRENDS BLOGS
            </div>
          </div>
        </Container>


        <Container>
<p class="blogscard3-description-area-of-main--text-above">
Over the previous decade, WOW has associated a worldwide network of individuals who utilize
 our innovation for transportation, food conveyance, business, and work. We have taken in a great
  deal in route and seen firsthand that it is so critical to be set up in the midst of an emergency,
   regardless of whether
 it is the extraordinary climate like a storm or a flood or a significant demonstration of brutality.
            
             </p>

             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard3-aboveimage">
               <img 
             style={{width:'100%'}}
             src="IMAGES/city-images/wow-blog/additional-cards/blog3/image-above1.jpg"/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard3-aboveimage">
               <img 
             style={{width:'100%'}}
             src="IMAGES/city-images/wow-blog/additional-cards/blog3/image-above2.jpg"/>

  </div>
  </Col>
               
             </Row>
             

             <p class="blogscard3-description-area-of-main--text-above">
             We are focused on your wellbeing. That is the reason we are unifying and enumerating our
              worldwide crisis approach and convention. In case of a significant crisis,
               we need our reaction to be steady and dependable,
              so you can remain concentrated on what is significant: 
              the wellbeing of you and your friends and family.<br/>

              Here is a diagram of how we work to help everybody in the WOW people
               group during a significant crisis:
             </p>
</Container>
<br/>













<Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard3">
            <div className="heading-secondary-help-community-section-wow-blogscard3 ">
            Travel Help Community Tips
            </div>
          </div>
        </Container>







<Container>
        <div class="description_of_card_of_blogscard3">
          <div class="description_of_card_of_blogscard3_area">
            <p class="blogscard3-description-area-of-main--text">
              Everyone loves traveling, vacations, tours, and trips. Whether
              going for a 2-3 days short tour or a whole week or months’
              vacation, one of the biggest hurdles apart from excitement is
              packing.
            </p>

            <p class="blogscard3-description-area-of-main--text">
            Traveling is fun. That’s just a fact. Another well-known fact is that travel is not always easy,
             smooth or convenient. Of course, chalking up all the mishaps as part of the fun is the best attitude
              to have, however avoiding them in the first place is probably better. To ensure that your travel 
              is as pain-free as possible, we’ve assembled the best travel hacks that ever existed. Whether 
              you’re looking for packing hacks,
             cheap flight hacks, airplane hacks, or just general travel life hacks, we have you covered.
             </p>


             <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog3/help-tips-grid1.jpg"/>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <img src="IMAGES/city-images/wow-blog/additional-cards/blog3/help-tips-grid2.jpg"/>
               </Col>
               
             </Row>

             
            <h2 class="blogscard3-description-area-of-main-headings">
            Global Security Center (GSC)
            </h2>
            <p class="blogscard3-description-area-of-main--text">
            WOW has a Global Security Center that screens and banners give that may influence
             our business and the networks where we work—24 hours per day, seven days every week.

The GSC is comprised of people with mastery in security, crisis strategy, and debacle the board who communicate 
in twelve distinct dialects and have lived and worked all around the globe.
              <br/><br/>
              They use innovative apparatuses and trend-setting innovation to identify crises 
              that may place our clients and representatives in harm's way.
            </p>


            <h2 class="blogscard3-description-area-of-main-headings">
            Top outing valuing
            </h2>
            <p class="blogscard3-description-area-of-main--text">
            At the point when the GSC decides a significant crisis occasion may have happened, it evaluates
             the circumstance and will quickly top excursion estimating in the zone.
              We will survey circumstances where unintended charges may have happened during a crisis 
              and work to discount them. Clients can generally report issues through the application. We additionally 
            adhere to neighborhood laws and guidelines identified with the States of Emergency where they apply.
            </p>
            <p class="blogscard3-description-area-of-main--text">
            Incorporating our reaction universally will at last make those reaction increasingly proficient, opportune,
             and predictable components that become considerably progressively fundamental during an emergency.
            </p>

            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard3">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog3/img1.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard3">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog3/img2.jpg" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>

            


            
            <h2 class="blogscard3-description-area-of-main-headings">
            Joining forces with government organizations
            </h2>
            <p class="blogscard3-description-area-of-main--text">
            Working with crisis the board organizations and neighborhood government partners, we convey 
            to our clients through the WOW applications to tell them about
         hazardous conditions and during times when the circumstance requires a suspension of administration.{" "}
            </p>

            <p class="blogscard3-description-area-of-main--text">
            Regardless of whether it is a typhoon, pandemic COVID-19, flooding or other natural disaster,
             in the consequence of an emergency or debacle, we mean to utilize the extent of our business,
              and our innovation to enable our networks to recuperate and refocus.
            </p>
           


            <h2 class="blogscard3-description-area-of-main-headings">
            Fiasco help for neighborhood networks
            </h2>
            <p class="blogscard3-description-area-of-main--text">
            As we incorporate our fiasco aid ventures in the networks we serve, we have built up a help 
            finance with assets put in a safe spot with the expectation of complimentary rides to covers,
             dinners for people on the call, transportation for volunteers, coordination’s assistance, 
             and backing to neighborhood non-benefit associations.{" "}
            </p>

            <ul className="list-circle-type-blogscard">
              <li class="blogscard3-description-area-of-main--text">
              Obviously, all crises are one of a kind and we will keep on working with 
              neighborhood governments and associations on the ground to help debacle recuperation endeavors.
              </li>
              <li class="blogscard3-description-area-of-main--text">
              In any case, we likewise realize that concentrating our reaction all-inclusive
               will, at last, make those reaction progressively proficient,
               opportune, and predictable components that become much increasingly crucial during an emergency.
              </li>
              <li class="blogscard3-description-area-of-main--text">
              Therefore, whether you are utilizing WOW to get a film, bounce on a bicycle,
               or convey a late-night nibble, you can have genuine 
              feelings of serenity that when a crisis hits, we are paying special mind to you and your locale.
              </li>

              
            </ul>


            <Row style={{marginBottom:'2rem'}}>
               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard3">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog3/img3.jpg" style={{width: '100%'}}/>

  </div>
               </Col>

               <Col xs={12} xl={6} lg={6} md={6} sm={12}>
               <div className="wow-news-card1-work-page-top-image-blogscard3">
  <img src="IMAGES/city-images/wow-blog/additional-cards/blog3/img4.jpg" style={{width: '100%'}}/>

  </div>
  </Col>
               
             </Row>













            <h2 class="blogscard3-description-area-of-main-headings">
              After considering all these things now comes the point ‘What Stuff
              you should pack?’
            </h2>


            <p class="blogscard3-description-area-of-main--text">
              That’s the main issue in packing which confuses a lot of people
              that they have a lot of amazing stuff which they wanted to carry
              with them but can’t decide what is more important to carry.{" "}
            </p>





            <h2 class="blogscard3-description-area-of-main-headings">
              Here are a few tricks and hacks to travel safely for your next
              vacation:
            </h2>
            <ul className="list-number-type-blogscard">
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Destinations are significantly impacted by traveler perceptions of man-made and
                 natural disasters so proactively providing fact-based information to the media, 
                policymakers and the travel community offering context during times of crisis is critical.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                With a focus on short trips in Europe and a strong responsible travel ethic, this 
                company ensures at least 80% of the cost of a trip 
                goes into the local economy and conservation projects.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                This small tour operator specialises in sustainable 
                activity breaks in the Alps, taking guests to areas
                 where tourism has a positive impact on local people.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Carry separate and lightweight laundry bags to keep your dirty
                clothes separately from the new and clean ones. Otherwise, your
                new and clean clothes will even start smelling.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Carry an extra outfit, even if you are going on a single day
                tour or business travel.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                While there’s a limit to how much advance planning you can do 
                for an event you can’t anticipate, there are several steps you should take – beginning 
                with the onboarding process – to help inform your
                 employees what to do in the event of an emergency that occurs while traveling on company
                  business.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Choose a waterproof carry bag and suitcase to get your luggage
                safe from any weather conditions or mishaps.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Store jewelry and accessories in a separate pocket or pouch.
                Don’t pack in a heavy-weight bag or in some pouch which carries
                more space.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Policy decisions during times of crisis can have unintended consequences
                 affecting travel so it is critical to engage with
                  relevant decision-makers to ensure that the potential impact 
                on one of America’s largest and important industries 
                is always considered as handling of a crisis continues.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Emergency response isn’t just about supporting the employee
                 directly affected, but also ensuring your business can keep operating.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Understanding everyone’s responsibilities and having it on file will enable you 
                to quickly and effectively divide the duties of the affected employee
                 among the other team members.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                Some people like to carry clothes in small pouches or pockets to
                separate everything. But I guess that carries more space in your
                suitcase. So keep jewelry, shoes, etc. in separate pouches or
                plastic bags but keep the clothes directly into a suitcase. Just
                keep them in an organized way so that it will be easier.
              </li>
              <li class="blogscard3-description-area-of-main--text">
                {" "}
                If you get sick or injured while travelling, Wow health insurance
                 plans will only pay a fraction of your medical expenses. Travel insurance is an affordable
                  way to make the situation manageable.
              </li>
              <li class="blogscard3-description-area-of-main--text">
              The No. 1 priority for employers is to ensure the safety of their
               workforce. So, here’s a good rule of thumb when it comes to an emergency:<br/>
               Do what’s right.

An organization that takes care of its employees greatly increases loyalty and enhances morale
 companywide. When leaders are visibly 
taking steps to care for employees, you’re sending a strong message to employees that you care.
              </li>
            </ul>
            <div class="description_of_card_of_blogscard3_bottom_area">
              <div class="description_of_card_of_blogscard3_bottom_area_previous">
                <h3>
                  <i class="fas fa-reply" aria-hidden="true"></i>
                </h3>
                <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                <p>Previouse Article</p>
              </div>
              <div class="description_of_card_of_blogscard3_bottom_area_next">
                <h3>
                  <i class="fa fa-mail-forward" aria-hidden="true"></i>
                </h3>
                <h5>WOW IS FINALLY HERE!</h5>
                <p>Next Article</p>
              </div>
            </div>
          </div>
</div>




</Container>
        

















            


            
            








































        












        {/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}
      </div>
    );
  }
}
export default Blogpost3;
