import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Container,
  Card,
  Row,
  DropdownButton,
  Dropdown,
  Col,
  Button,
  Media
} from "react-bootstrap";



class ComponentToPrint extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }
  
  render() {
    return (
      <div>


        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="invoice-le-view_area-customerlist" style={{overflow:'auto'}}>
                
                 

                  <div className="invoice-customerlist-div-body-le">
                    <div className="invoice-customerlist-body-inside">





<div className="accountant-heading-customerlist">
    Acountant Panel
</div>
        
<div className="customerlist-heading-area">
    <div className="customerlist-heading">
Customer List
    </div>


    <div className="customerlist-heading-area-filter-button">
    <DropdownButton id="dropdown-basic-button"
    variant='light' title="Filters">
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
</DropdownButton>
    </div>
</div>

                   

{/*                    
                      <div className="customerlist-template__divider--full-width">
                        <div className="le-divider-top-customerlist"></div>
                      </div> */}








<div class="description_of_card_of_customerlist">
 <div class="description_of_card_of_customerlist_area">



<Col xl={12} md={12} lg={12}>
                      <div class="customerlist-template__items-table">
                        <table class="le-table-customerlist">

                          <thead class="le-table-customerlist__header">
                            <tr class="le-table-customerlist__row">
                              <th class="le-table-customerlist__cell--amount" colspan="2">First Name</th>


<th class="le-table-customerlist__cell--amount class-table-particular-customerlist" colspan="2">Last Name</th>

<th class="le-table-customerlist__cell--amount" colspan="2">Email</th>

<th class="le-table-customerlist__cell--amount" colspan="2">Contact No</th>

<th class="le-table-customerlist__cell--amount" colspan="2">Emirate ID</th>

<th class="le-table-customerlist__cell--amount" colspan="2">Action</th>



            </tr>
            </thead>

            
            
              <tr class="le-table-customerlist__row">
              <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">Haider</em>
                  </div>
                  </td>

                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">Ali</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">haider.ali@gmail.com</em>
                  </div>
                  </td>

                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">+923357737360</em>
                  </div>
                  </td>


                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">788-7894-7894561-2</em>
                  </div>
                  </td>


                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist"><i className="fa fa-eye"></i></em>
                  </div>
                  </td>
                  
                  
                  </tr>





                  <tr class="le-table-customerlist__row">
              <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">Usman</em>
                  </div>
                  </td>

                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">Ali</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">usman.ali@gmail.com</em>
                  </div>
                  </td>

                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">+923399737360</em>
                  </div>
                  </td>


                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">788-7894-7821561-2</em>
                  </div>
                  </td>


                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist"><i className="fa fa-eye"></i></em>
                  </div>
                  </td>
                  
                  
                  </tr>







                  <tr class="le-table-customerlist__row">
              <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">Fahad</em>
                  </div>
                  </td>

                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">Ali</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">fahad.ali@gmail.com</em>
                  </div>
                  </td>

                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">+923352237360</em>
                  </div>
                  </td>


                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist">788-1294-7894561-2</em>
                  </div>
                  </td>


                  <td class="le-table-customerlist__cell" colspan="2">
                <div class="no-items-message-customerlist">
                  <em class="le-text--emphasized-customerlist"><i className="fa fa-eye"></i></em>
                  </div>
                  </td>
                  
                  
                  </tr>
                  
                  </table>
                  </div>
                  </Col>





                  





              





        </div>
        </div>








                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>




        









      </div>
    );
  }
}

class LEcustomerlist extends React.Component {
  render() {
    return (
      <div>
        <Container>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <Button 
            style={{borderRadius:'20px'}}
            variant="outline-dark">Print this out!</Button>;
          }}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={el => (this.componentRef = el)} />
        </Container>
      </div>
    );
  }
}

export default LEcustomerlist;
