import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { 
  Form,
  Container,
  Card, Row,
  Slider,
  Col,
  Button
 } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Newscard6 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { 
      date: new Date().toLocaleString(),
      toggle: true,
     };
  }


  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };


  render() {
    return (
      <div>
        
        {/* <Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-travel-work">
        <div  className="heading-secondary-section-wow-travel-work ">
        Coronavirus Tips If You Still Have to Travel Domestically

        <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2-newscard2"
            src="IMAGES/city-images/newsroom/card3-2.jpg"/>
        </div>
        </div>


</Container> */}

        

<Container>
        <div className="td-post-header-holder-card6 td-image-gradient-newscard6">
        <div className="td-post-featured-image-newscard6">
           <img width="1068" height="580" 
            className="entry-thumb td-animation-stack-type0-2-newscard6"
            src="IMAGES/city-images/newsroom/card6-2.jpg"/>

        </div>
            <header className="td-post-title-newscard6">
            <ul className="td-category-newscard6">
                    <li className="entry-category-newscard6">
                        <a 
                        target="blank"
href="https://www.traveller.com.au/london-travel-guide-and-things-to-do-20-thing-that-will-surprise-firsttime-visitors-h1hupe">
                        London
                        </a>
                        </li>

                
                        <li className="entry-category-newscard6">
                        <a 
                        target="blank"
href="https://www.bbc.com/news/explainers-52544307">
                        UK
                        </a>
                        </li>
                </ul>
                             
<h1 className="entry-title-newscard6">WOW: 10 best weekend trips from London & UK travel restrictions</h1>

                

                <div className="td-module-meta-info-newscard6">
                 <span className="td-post-date-newscard6">
                     <time className="entry-date-newscard6 updated td-module-date-newscard6"
                      datetime="2018-11-07T10:59:17+00:00">May 29, 2020</time>
                </span>      

                <div className="td-post-views-newscard6">
                    <i className="fa fa-eye view-icon-views-newscard6"></i>
                    <span className="td-nr-views-10968">12735</span>
                </div>                    
                              
                     </div>

            </header>
        </div>
 
</Container>







<Container>


<div className="u-center-text u-margin-bottom-second-heading-wow-newscard6">
        <div  className="heading-secondary-section-wow-newscard6 ">
        Coronavirus (COVID-19) travel restrictions: What are the UK travel quarantine rules?
        </div>
        </div>     
      

</Container>




<Container>
<div id="accordion-5ed01b058660a" className="accordion indicator-plus accordion-white mb-3" role="tablist">
<div className="card-newsroom6 bar">
  <div className="card-newsroom6 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-0" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom6-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-0">
        <span role="heading" aria-level="0">What are the new quarantine rules?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-0" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-0" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Passengers arriving in the UK by plane, ferry or train - including UK nationals - will be asked 
            to provide an address where they will self-isolate for 14 days. Travellers can be fined £100 for
             failing to fill in a form with these details.<br/>

Surprise visits will be used to check they are following the rules. Those in England could be fined up to 
£1,000 if they fail to self-isolate.<br/>

Passengers should drive their own car to their destination, where possible. If they don't provide an address,
 the government will arrange accommodation at the traveller's expense.

Once at their destination they must not use public transport or taxis. They must not go to work, school, 
or public areas, or have visitors except for essential support.<br/>

They are also not allowed to go out to buy food, or other essentials, where they can rely on others.
       </p>
      
       
      </div>
  </div> 
  </div>
  </div>





  <div className="card-newsroom6 bar">
  <div className="card-newsroom6 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-1" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom6-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-1">
        <span role="heading" aria-level="0">Is anywhere exempt from quarantine?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-1" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-1" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Anyone arriving from the Common Travel Area (CTA) - the Republic of Ireland, the Channel Islands, 
            or the Isle of Man - will not have to enter quarantine, as long as they have been in the CTA for
             at least 14 days.<br/>

The government has also said it is looking into international travel corridors or "air bridges" to countries
 with low infection rates and strong healthcare systems. It hopes this could avoid the need for quarantine 
 for travellers between the two.
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom6 bar">
  <div className="card-newsroom6 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-2" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom6-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-2">
        <span role="heading" aria-level="0">Should I avoid travelling internationally?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-2" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-2" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>Yes. CDC recommends that you avoid all nonessential international travel because of the COVID-19
           pandemic. Some healthcare systems are overwhelmed and there may be limited access to adequate
            medical care in affected areas. Many countries are implementing travel restrictions and mandatory
             quarantines, closing borders, and prohibiting non-citizens from entry with little advance notice.
              Airlines have cancelled many international flights and in-country travel may be unpredictable.
               If you choose to travel internationally, your travel plans may be disrupted, and you may
                have to remain outside the United States for an indefinite length of time.<br/> 
                CDC also recommends all travelers  <a 
     target="blank"
href="https://wwwnc.cdc.gov/travel/notices/warning/coronavirus-cruise-ship">
        defer all cruise ship travel worldwide.</a>
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom6 bar">
  <div className="card-newsroom6 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-3" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom6-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-3">
        <span role="heading" aria-level="0">Can I bring relatives to visit me in the U.K.?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-3" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-3" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>The Government requires all international arrivals to disclose information about their contact
             and accommodation. In addition, all travelers need to self-isolate for 14 days. Where international
              travelers are unable to demonstrate where they would self-isolate, they will be required to do
               so in accommodation arranged by the Government. The rules apply to all travelers, including 
               relatives, unless they are coming from Ireland, the Channel Islands or the Isle of Man.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom6 bar">
  <div className="card-newsroom6 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-4" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom6-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-4">
        <span role="heading" aria-level="0">Can I travel domestically in the U.K?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-4" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-4" role="tabpanel">
      <div className="card-body-newscard3">
        <p>Yes, you can travel inside the United Kingdom and visit open spaces at any distance, but 
            remember to follow the sanitary and social distancing rules wherever you go. Generally, 
            the national parks are open but it is always a good idea to double check before visiting,
             in order to avoid any inconvenience.<br/>
             Most of the non-essential businesses are still closed to the public but the government hopes to
              open at least some of the hospitality industry in July. 
             You can already go visit outdoor places whenever you want, keeping the minimum distance from others.
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-newsroom6 bar">
  <div className="card-newsroom6 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-5" 
    data-toggle="collapse"     role="tab" aria-expanded="false">
      <button className="card-title btn btn-link newsroom6-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-5">
        <span role="heading" aria-level="0">Who is exempt from quarantine?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-5" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-5" 
    role="tabpanel">
      <div className="card-body-newscard3">
        <p>There are a number of groups who are exempt, including:
       
       </p>

       <ul>
        <li>
        Road haulage and freight workers
            </li>
        <li> Medical and care professionals providing essential healthcare</li>
        
        <li> Seasonal agricultural workers if they self-isolate where they are working</li>
        <li>UK residents who ordinarily travel overseas at least once a week for work</li>
        </ul>
      </div>
  </div> 
  </div>
  </div>







  </div>

  
  </Container>









<Container>
<div className="u-center-text u-margin-bottom-second-heading-uk-wow-newscard6">
        <div  className="heading-secondary-uk-section-wow-newscard6 ">
        The United Kingdom
        </div>
        </div>  
</Container>











<div className="gradient-back-travel-newscard6">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard6-grid-text">
                  <div className="travel-newscard6-page-araea-sub-headings110">
                  UK travel: It's safe
                  </div>

                  <div className="travel-newscard6-page-page-text">
                  Despite extremely dubious far-right propaganda gaining remarkable traction overseas,
                   there aren't any genuine no-go areas in London. By international standards, it's a 
                   remarkably safe city and while there is some crime, visitors generally don't have to 
                   take any precautions they wouldn't take in a big Australian city.

 
<br/>
Regularly use your sanitizer when you aren't wearing gloves and avoid passing
 the sanitizer bottle to another person.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/grid1.jpg"/>
              </Col>
            </Row>
          </Container>
        </div>





<div className="gradient-back-travel-newscard6">
          <Container>
            <Row>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/grid2.jpg"/>
              </Col>


              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard6-grid-text">
                  <div className="travel-newscard6-page-araea-sub-headings110">
                  Immerse yourself in the past
                  </div>

                  <div className="travel-newscard6-page-page-text">
                  The heart of London, Westminster Abbey, has been the center of royal and historical London
                   for over 1,000 years. Admire the magnificent stained glass windows and soaring Gothic 
                   architecture as you learn about the coronations of the kings and queens of England. 
                   This is where Queen Elizabeth II got married in 1947 as well as, more recently, Prince 
                   William and Kate Middleton. If you’re a fan of great literature, stop at Poet’s Corner, 
                   where famous writers such as Charles Dickens and Geoffrey Chaucer are buried.

                  </div>
                </div>
              </Col>

              
            </Row>
          </Container>
</div>










<Container>


<div class="u-center-text u-margin-bottom-second-heading-expert-wow-newscard6">
        <div  className="heading-secondary-expert-section-wow-newscard6 ">
        London travel guide and things to do: 10 unmissable experiences in London
        </div>
        </div>   






<div class="description_of_card_of_newsroom-card6">
  <div class="description_of_card_of_newsroom_area-card6">

  <h2 class="blogs-description-area-of-main-headings-card6">
  1. Make your way to Stonehenge
  </h2>
  <p class="blogs-description-area-of-main--text-card6">
  Head to one of the ancient wonders of the world, Stonehenge, enjoying the stunning 
  English landscapes along the way. Stonehenge was built in several stages more than 5,000 years ago on 
  Salisbury Plain in the Wiltshire countryside. </p>

  <p class="blogs-description-area-of-main--text-card6">
This impressive cluster of 40-ton rocks remains shrouded in mystery, still causing 
debate about its meaning and initial function. But it is always better to see something once than to hear 
about it a thousand times. Visit Stonehenge yourself to see if you can uncover the mystery of this prehistoric
 monument.
   </p>

   
<div class="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img1.jpg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings-card6">2. Famous fab cabs</h2>
   <p class="blogs-description-area-of-main--text-card6">
   It takes a strong knowledge of the area, patience, and of course a license to become a cab driver. 
   But in London, the bar to qualify is far higher. Facing one of the most notoriously difficult application
    and approval processes for cab drivers in the world, London cabbies have to take the London Knowledge 
    test including knowing 25,000 streets and all the landmarks within 6 miles of Charing Cross. A London
     cabbie can really say (and show the certificate as proof!) they know the city like the back of their hand.
    </p>

      <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img2.jpg" style={{width: '100%'}}/>

  </div>


      
<h2 className="blogs-description-area-of-main-headings-card6">3. Wander around Bath</h2>
  <p className="blogs-description-area-of-main--text-card6">
  Less than 2 hours away from London, the city of Bath is an ideal destination for travelers of 
  all ages. Breathtaking views and spectacular architecture of this Georgian town are perfect for 
  roaming around and spending pleasant time with family or friends. Relax and head to the famous Roman Baths
   afterwards, which have supplied water for more than 2,000 years. Afterward, get a cup of tasty
    tea or coffee in one of the many cozy restaurants to soak up the local atmosphere and charm..  
     </p>

     <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img3.jpg" style={{width: '100%'}}/>

  </div>




     
     <h2 className="blogs-description-area-of-main-headings-card6">4.  Go sky high</h2>
      
      <p className="blogs-description-area-of-main--text-card6">
      Take a trip around the London Eye, one of the world’s tallest observation wheels.
      Tally-ho and toodle-pip, it’s time to go to one of the most exciting cities in the world: London.
       Experience the culture, history, and charm of this bustling city with this 
       guide to the best things to do in London. It doesn’t 
      matter if you’re visiting for a day, a weekend, or a week, there’s inspiration around every corner.
      </p>

      <p className="blogs-description-area-of-main--text-card6">
      There’s no better way to get a true bird’s-eye view of the city than on the London Eye. Enter a
       glass capsule and be whisked over 1,000 feet into the sky for a 360-degree view. You’ll see the River 
       Thames snaking below you, along with famous landmarks such as Big Ben, St. Paul’s Cathedral, and 
       Buckingham Palace. You can’t say you’ve been to London if you haven’t seen it from the Eye…
         </p>

         <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img4.jpg" style={{width: '100%'}}/>

  </div>



<h2 className="blogs-description-area-of-main-headings-card6">5. Immerse yourself in the past</h2>
      
      <p className="blogs-description-area-of-main--text-card6">
      An absolutely essential part visit to London, Westminster 
      Abbey is one of the city’s most iconic buildings.<br/>

      The heart of London, Westminster Abbey, has been center of royal and historical London for over 1,000 
      years. Admire
       the magnificent stained glass windows and soaring Gothic architecture as you learn
      about the coronations of the kings and queens of England. This is where Queen Elizabeth II got married
      in 1947 as well as, more recently, Prince William and Kate Middleton. If you’re a fan of great 
      literature, stop at Poet’s Corner, where famous writers such as Charles Dickens and Geoffrey 
      Chaucer are buried.
         </p>
       
         <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img5.jpg" style={{width: '100%'}}/>

  </div>



<h2 className="blogs-description-area-of-main-headings-card6">6. Enjoy White Cliffs of Dover </h2>
      
      <p className="blogs-description-area-of-main--text-card6">
      One of England’s most stunning natural sites, the White Cliffs of Dover is guaranteed to take
       anybody’s breath away. Take a day trip to the channel port in Kent to see the unique variety of 
       flora and fauna that can be found only here. Ramble around and find the best spot for a view of 
       the soft white chalks and clear water of the English Channel.  </p>

       <p className="blogs-description-area-of-main--text-card6">
       It might be the whisky talking but, since the year 565, visitors to Scotland’s Loch Ness have reported 
       sighting a mysterious creature under the lake’s pristine waters. Want to get to the bottom of the Loch 
       Ness mystery? Grab your binoculars and head to the Scottish Highlands. Even if you don’t spot Nessie 
       herself, you’ll fall in love with Scotland’s rugged beauty. 
      </p>

      <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img6.jpg" style={{width: '100%'}}/>

  </div>





  <h2 className="blogs-description-area-of-main-headings-card6">7. Visit Waddesdon Manor</h2>
      
      <p className="blogs-description-area-of-main--text-card6">
      The 16th century French château Waddesdon Manor is a perfect place for a weekend getaway from London.
       Belonging to the wealthy Rothschild family, this place is widely known for its beautiful outdoor areas.
       </p>

       <p className="blogs-description-area-of-main--text-card6"> 
Hilltop gardens impress with incredible views, which stretch far away for miles. To round off your trip on
 a high note, have a glass of wine from the famous Rothschild vineyards after exploring the estate’s 
 gorgeous interiors
      </p>

      <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img7.jpg" style={{width: '100%'}}/>

  </div>






  <h2 className="blogs-description-area-of-main-headings-card6">8. Cruise down the Thames</h2>
      
     <p className="blogs-description-area-of-main--text-card6">
      Float along the famous River Thames all the way from Westminster to Greenwich. You’ll not only spot 
      famous sights, but you’ll also learn more about the history of this fascinating city. Relax, take in 
      the views, and see the city from a completely different angle. Once you reach Greenwich, you can visit
       the Royal Observatory and stand with your feet in different time zones on the Greenwich meridian.
      </p>

      <p className="blogs-description-area-of-main--text-card6">
      Take a cruise down the Thames and see London from a different perspective.
      If you’re fascinated by gory stories, a Jack the Ripper walking tour will send shivers down your spine.
       Walk through the streets of London and hear all about this killer’s crimes in Victorian London. 
      The tales come to life thanks to hand-held projectors, creating an eerie atmosphere around you.</p>

      <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img8.jpg" style={{width: '100%'}}/>

  </div>



  <h2 className="blogs-description-area-of-main-headings-card6">9.  Travel to Windsor Castle.</h2>
      
      <p className="blogs-description-area-of-main--text-card6">
      Escape the bustle of London and head west for a morning trip to the incredible Windsor Castle.
       Learn more about the royal family while visiting an official residence of Her Majesty Queen Elizabeth
        II. Learn about the 900-year 
      long history of the residence, and take your time to enjoy the gorgeous interiors and roam the grounds.
      </p>

      <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img9.jpg" style={{width: '100%'}}/>

  </div>





  <h2 className="blogs-description-area-of-main-headings-card6">10. Stand on top of the world</h2>
      
      <p className="blogs-description-area-of-main--text-card6">
      London’s The Shard building, which opened in 2013, stands an incredible 1,016 feet tall. Visit the 
      viewing platform on the 72nd floor and see the whole of London spread out below you. 
      Top off this bucket-list experience with a glass of Champagne, and say “Cheers!” to London.
      </p>


      <p className="blogs-description-area-of-main--text-card6">
      It’s hard to find a place in the British capital that isn’t picturesque. In between afternoon tea 
      and West End plays, you’ll find photo opportunities around every corner of London. Notting 
      Hill’s vibrant streets will add color to
       your Instagram feed while the Shard and London Eye will provide the setting for unbeatable panoramas.
      </p>

      <div className="wow-news-card1-work-page-top-image-card6">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card6/img10.jpg" style={{width: '100%'}}/>

  </div>


    
  
        
   
        </div>
        </div>





        </Container> 









        










































{/* <div className="container">
  <h2>Simple Collapsible</h2>
  <p>Click on the button to toggle between showing and hiding content.</p>
  <div 
  size="lg" 
  data-toggle="collapse" 
  data-target="#demo" block>Simple collapsible</div>
  <div id="demo" className="collapse">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </div>
</div> */}































      </div>
    );
  }
}
export default Newscard6;

