import React, { Component } from "react";


class Emailintimation extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

    

    
<div >

{/* <div class="intimation-email-wow-header">
	<center>
		<img class="wow-image-in-intimation-email"   src="IMAGES/wowboard.png"/>
	</center></div>
  
    
 

     
<div class="container">

      <div class="text-written-in-intimation-email">
		<p class="text" >
			EMAIL LOG-IN INTIMATION,
		</p>
		<p class="text" >
			Dear Muhammad Tahir,
		</p>

        <p class="text" >
			You are logged in to WOW at 4:51 PM on 12/11/2019.
		</p>

        <p class="text" >
			We noticed a log in from new device or location and wants<br/> to make sure it's You. 
		</p>

        <p class="text" >
        Device Name: SAMSUNG Galaxy A7 OS Name: ANDROID 5.0.6<br/>
         Location Where: Punjab, Pakistan (Approximate)
          
		</p>
        <p class="text-this-was-intimation-page" >
        If this was you:  
		</p>
        <p class="text" >
        You're good to go! You don't need to do anything. 
		</p>

        <p class="text-this-was-intimation-page" >
        If this wasn't you:
		</p>


		<p class="text" >
        Someone knows your login details and used it to access your <br/>
        account. To protect your account, reset your password and <br/>
         turn on two-factor authentication. Also check for suspicious <br/>
          activity on any linked social media accounts. 
		</p>
		<br/>
		<p class="text" >
        For any query call our helpline: +97145217500 or email us<br/>
        at info@wowets.com. <br/>
		
		</p>
	
		<p   class="text-thank-you" >
			THANK YOU 
		</p>

	</div></div>
	<br/>

	<div class="container">

	<div class="statement-header-line-in-intimation-email"> </div>

    <p class="text-below-intimation-footer-page" >
        Disclaimer,<br/>
        DiscIcirnic This email contckis crivleged cod/or conklatial information intended only for the
         use of the addressee. If you we not the Intended recipterit of this message, you are strictly
          prohibited to road copy, distribute% discus, cresserninate its contents or take my croon
           raying on the sure and please notify the sender 111E1 1014.11.1*. The recipient ozknavedges
            that to sender is unable to exercise control or ensure a gucrantee the integrity of/over the
     contents ancl/or infomiation contained in this message, therefore, the WOWETS will not be to for 
     any coots or omissions vAlich aise as a result of emai trcnsmission, any damages or eras whatever
      ter notarial' viruses, external:limns% delays and the like. 

		</p>
       




	<div class="intimation-email-footer-content">
  <div class="infoCons-in-intimation-email">
     

       <!-- row  -->


      <div class="horizontalRow-in-intimation-email">

        <div class="intimation-email-ones intimation-email-inside-texts"><div class="itemCons-in-intimation-email">
             <div class="iconCon-in-intimation-email">
              <a> <img class="footer-baseline-image-intimation-email" src="IMAGES/calllogo.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-intimation-email">
               Phone: +971 45 217500
             </span>
            </div></div>


        <div class="intimation-email-twos intimation-email-inside-texts"><div class="itemCons-in-intimation-email">
             <div class="iconCon-in-intimation-email">
             <a> <img  class="footer-baseline-image-intimation-email" src="IMAGES/msg.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-intimation-email">
               info@wowets.com
             </span>
            </div></div>

        <div class="intimation-email-threes intimation-email-inside-texts"><div class="itemCons-in-intimation-email">
             <div class="iconCon-in-intimation-email">
             <a> <img class="footer-baseline-image-intimation-email" src="IMAGES/browser.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-intimation-email">
               wowets.com
             </span>
            </div></div>


        <div class="intimation-email-four intimation-email-inside-texts"><div class="itemCons-in-intimation-email">
             <div class="iconCon-in-intimation-email">
             <a> <img class="footer-baseline-image-intimation-email" src="IMAGES/location.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-intimation-email">
               WOW Electronic Transport Services, <br/>
                34th floor Burj Al Salam Tower, Trade <br/>
                Centrel, Sheikh Zayed Road Dubai, UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div> */}
























  <div class="verify-email-emirates-header">
  
    <img
    class="image-logo-verify-email-emirates"
     src="https://drive.google.com/uc?export=view&id=1ALm-qFoop4klkos6YHe2o9FJUXedHwIi"></img>
   
    <div class="heading-text-verify-email-emirates">
       Legal Emirates

    </div>
    
  </div>


<div class="border-line-verify-email-emirates border-line-verify-email-emirates15" ></div>



<div class="image-verify-email-emirates">
  <img src="https://drive.google.com/uc?export=view&id=1aUXQe5FnEERPfL6fjQqwJsKhqxLSeBeF"></img>

  
</div>







<div class="content-section-verify-email-emirates">
<div class="content-section-text-verify-email-emirates">
    <h2>You are nearly there!</h2>
    <h3>Hi Haider Ali,</h3>
    <p>To finish the verification process of your account and
     to start using the Legal Emirates services, please confirm your email address</p>

     <button>
       Verify your email
     </button>

  <div class="border-line-verify-email-emirates"></div>
         
    <h3>Your account</h3>
    <p>Make appointments, live meetings, or counselling by using
     one account across all of your Legal Emirates services.</p>
        


     <div class="border-line-bottom-verify-email-emirates"></div>
    <center>
        <p>This message was sent you by Legal Emirates</p>
            
    <h1>Legal Emirates</h1>
        
    </center>


        
        </div>






        </div>

















</div>

      


      
  


    




    

       
        
       

    
   
   

    


 
     
    
    
    );
  }
}
export default Emailintimation;