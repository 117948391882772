import React, { Component } from "react";
// import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Button,
  Card,
  Row,
  Slider,
  Col,
} from "react-bootstrap";
import { ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../WowBookingticketholidaysystem2/bookingticketsystem2.scss";

class Booking_ticket2 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      adultSeat: 0,
      childSeat: 0,
      flyingTo: "",
      flyingFrom: "",
      travelClass: "",
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }


  handleChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  changeVisibility = () => {
    this.setState({
      visible: !this.state.visible,
    });
    window.scrollTo({ top: 700 });
  };
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  addAdultSeat = () => {
    this.setState((prevState) => ({
      adultSeat: parseInt(prevState.adultSeat) + 1,
    }));
  };
  removeAdultSeat = () => {
    this.setState((prevState) => ({
      adultSeat: parseInt(prevState.adultSeat) - 1,
    }));
  };
  resetAdultSeat = () => {
    this.setState({
      adultSeat: 0,
    });
  };
  addChildSeat = () => {
    this.setState((prevState) => ({
      childSeat: parseInt(prevState.childSeat) + 1,
    }));
  };
  removeChildSeat = () => {
    this.setState((prevState) => ({
      childSeat: parseInt(prevState.childSeat) - 1,
    }));
  };
  resetChildSeat = () => {
    this.setState({
      childSeat: 0,
    });
  };

  flightsData = [
    {
      flight_name: "DUB, Dublin Airport",
      currency: "Dirham",
      city_name: "Dublin",
      price: 128.67,
      carrier: "KL",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T09:35+02:00 AMS 2017-05-30T11:15+02:00"],
    },
    {
      flight_name: "Lyon-Bron Airport", 
      city_name: "Lyon",
      currency: "ZAR",
      price: 151.41,
      carrier: "BA",
      img: "https://blog.alaskaair.com/wp-content/uploads/2016/01/logo-for-blog1.jpg",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
    {
      flight_name: "Marseille Provence Airport",
      city_name: "Marseille",
      currency: "Dirham",
      price: 138.7,
      carrier: "AF",
      img: "https://cf.bstatic.com/xdata/images/hotel/square200/234576175.webp?k=976c2aec88481bafbfd91f8af97fdfc9459b373c6fd4d4f8a5ddd9393f3d76c3&o=",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T12:35+02:00 AMS 2017-05-30T13:50+02:00"],
    },
    {
      flight_name: "Charles de Gaulle International",
      city_name: "Charles de Gaulle",
      currency: "Dirham",
      price: 151.41,
      carrier: "BA",
      img: "https://cf.bstatic.com/xdata/images/hotel/square200/255542055.webp?k=2b318819e32d8a85bdd3d062fb8c80f5311d2c213b2b46a37205b929d9c2c3e3&o=",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
    {
      flight_name: "Frankfurt am Main International",
      city_name: "Frankfurt",
      currency: "Dirham",
      price: 174.7,
      carrier: "KL",
      img: "IMAGES/city-images/book_holiday/flights/dublin.jpg",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T18:35+02:00 AMS 2017-05-30T19:50+02:00"],
    },
    {
      flight_name: "Paris-Orly Airport",
      city_name: "Paris",
      currency: "Dirham",
      price: 204.7,
      carrier: "AF",
      img: "https://cf.bstatic.com/xdata/images/hotel/square200/183065428.webp?k=23123f8db7249214d0796fdd0b446afa52e6ba89b5e9a5de1bb6899b00b876ff&o=",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
    {
      flight_name: "Le Mans-Arnage Airport",
      city_name: "Le Mans-Arnage",
      currency: "Dirham",
      price: 204.7,
      carrier: "AF",
      img: "https://wowholiday-fffa4.web.app/IMAGES/wow-holiday.png",
      time: "2h 30min",
      nodes: ["CDG 2017-05-30T11:40+02:00 AMS 2017-05-30T12:55+02:00"],
    },
  ];

  carrierData = {
    AF: "Air France",
    KL: "KLM Royal Dutch Airlines",
    BA: "British Airways",
  };

  airportsData = [
    {
      name: "DUB, Dublin Airport",
      city: "Dubai",
      country: "UAE",
      IATA: "UAE",
    },
    {
      name: "Vichy-Charmeil Airport",
      city: "Vichy",
      country: "France",
      IATA: "VHY",
    },
    { 
      name: "Lyon-Bron Airport", 
      city: "Lyon", 
      country: "France", 
      IATA: "LYN" },
    {
      name: "Cannes-Mandelieu Airport",
      city: "Cannes",
      country: "France",
      IATA: "CEQ",
    },
    {
      name: "Marseille Provence Airport",
      city: "Marseille",
      country: "France",
      IATA: "MRS",
    },
    {
      name: "Charles de Gaulle International",
      city: "Paris",
      country: "France",
      IATA: "CDG",
    },
    {
      name: "Toussus-le-Noble Airport",
      city: "Toussous-le-noble",
      country: "France",
      IATA: "TNF",
    },
    {
      name: "Paris-Orly Airport",
      city: "Paris",
      country: "France",
      IATA: "ORY",
    },
    {
      name: "Le Mans-Arnage Airport",
      city: "Le Mans",
      country: "France",
      IATA: "LME",
    },
    {
      name: "Nantes Atlantique Airport",
      city: "Nantes",
      country: "France",
      IATA: "NTE",
    },
    {
      name: "Nancy-Essey Airport",
      city: "Nancy",
      country: "France",
      IATA: "ENC",
    },
    {
      name: "Frankfurt am Main International",
      city: "Frankfurt",
      country: "Germany",
      IATA: "FRA",
    },
    {
      name: "Hamburg Airport",
      city: "Hamburg",
      country: "Germany",
      IATA: "HAM",
    },
    {
      name: "Cologne Bonn Airport",
      city: "Cologne",
      country: "Germany",
      IATA: "CGN",
    },
    {
      name: "Munich International Airport",
      city: "Munich",
      country: "Germany",
      IATA: "MUC",
    },
    {
      name: "Stuttgart Airport",
      city: "Stuttgart",
      country: "Germany",
      IATA: "STR",
    },
    {
      name: "Berlin-Tegel International Airport",
      city: "Berlin",
      country: "Germany",
      IATA: "TXL",
    },
    {
      name: "Hannover Airport",
      city: "Hannover",
      country: "Germany",
      IATA: "HAJ",
    },
    { name: "Bremen Airport", city: "Bremen", country: "Germany", IATA: "BRE" },
    {
      name: "Frankfurt-Hahn Airport",
      city: "Hahn",
      country: "Germany",
      IATA: "HHN",
    },
    {
      name: "Dortmund Airport",
      city: "Dortmund",
      country: "Germany",
      IATA: "DTM",
    },
    { name: "Cork Airport", city: "Cork", country: "Ireland", IATA: "ORK" },
    { name: "Galway Airport", city: "Galway", country: "Ireland", IATA: "GWY" },
    { name: "Dublin Airport", city: "Dublin", country: "Ireland", IATA: "DUB" },
    {
      name: "Waterford Airport",
      city: "Waterford",
      country: "Ireland",
      IATA: "WAT",
    },
    {
      name: "Amsterdam Airport Schiphol",
      city: "Amsterdam",
      country: "Netherlands",
      IATA: "AMS",
    },
    {
      name: "Maastricht Aachen Airport",
      city: "Maastricht",
      country: "Netherlands",
      IATA: "MST",
    },
    {
      name: "Eindhoven Airport",
      city: "Eindhoven",
      country: "Netherlands",
      IATA: "EIN",
    },
    {
      name: "Rotterdam The Hague Airport",
      city: "Rotterdam",
      country: "Netherlands",
      IATA: "RTM",
    },
    {
      name: "Belfast International Airport",
      city: "Belfast",
      country: "United Kingdom",
      IATA: "BFS",
    },
    {
      name: "Manchester Airport",
      city: "Manchester",
      country: "United Kingdom",
      IATA: "MAN",
    },
    {
      name: "Southampton Airport",
      city: "Southampton",
      country: "United Kingdom",
      IATA: "SOU",
    },
    {
      name: "London Heathrow Airport",
      city: "London",
      country: "United Kingdom",
      IATA: "LHR",
    },
    {
      name: "Blackpool International Airport",
      city: "Blackpool",
      country: "United Kingdom",
      IATA: "BLK",
    },
    {
      name: "Newcastle Airport",
      city: "Newcastle",
      country: "United Kingdom",
      IATA: "NCL",
    },
    {
      name: "London Stansted Airport",
      city: "London",
      country: "United Kingdom",
      IATA: "STN",
    },
    {
      name: "Miami International Airport",
      city: "Miami",
      country: "United States",
      IATA: "MIA",
    },
    {
      name: "John F Kennedy International Airport",
      city: "New York",
      country: "United States",
      IATA: "JFK",
    },
    {
      name: "Piedmont Triad International Airport",
      city: "Greensboro",
      country: "United States",
      IATA: "GSO",
    },
    {
      name: "Wings Field",
      city: "Philadelphia",
      country: "United States",
      IATA: "BBX",
    },
    {
      name: "Hardwick Field",
      city: "Cleveland",
      country: "United States",
      IATA: "HDI",
    },
    {
      name: "Warren Field",
      city: "Washington",
      country: "United States",
      IATA: "OCW",
    },
  ];

  render() {
    const scrollTobookholiday = () => window.scrollTo({ top: 0 });
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>
        {this.state.visible === false ? (
          <div>
            <div className="book-holiday">
              <div id="booking" className="section">
                <div className="section-center">
                  <div className="container">
                    <div className="row">
                      <div className="booking-form">
                        <form>
                          <div className="form-group">
                            <div className="form-checkbox">
                              <Row>
                                <Form.Label for="roundtrip">
                                  <Form.Control
                                    type="radio"
                                    id="roundtrip"
                                    name="flight-type"
                                  />
                                  <span></span>Roundtrip
                                </Form.Label>
                                <Form.Label for="one-way">
                                  <Form.Control
                                    type="radio"
                                    id="one-way"
                                    name="flight-type"
                                  />
                                  <span></span>One way
                                </Form.Label>
                                <Form.Label for="multi-city">
                                  <Form.Control
                                    type="radio"
                                    id="multi-city"
                                    name="flight-type"
                                  />
                                  <span></span>Multi-City
                                </Form.Label>
                              </Row>
                            </div>
                          </div>
                          <Row>
                            <div className="col-md-6">
                              <div className="form-group">
                                <span className="form-label">Flying from</span>
                                <Form.Control
                                  as="select"
                                  name="flyingFrom"
                                  onChange={this.changeHandler}
                                  required
                                >
                                  <option value="">Select destination</option>
                                  {this.airportsData.map((airport) => {
                                    const { name, city, country } = airport;
                                    return <option>{name}</option>;
                                  })}
                                </Form.Control>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <span className="form-label">Flying to</span>
                                <Form.Control
                                  as="select"
                                  name="flyingTo"
                                  onChange={this.changeHandler}
                                  required
                                >
                                  <option value="">Select destination</option>
                                  {this.airportsData.map((airport) => {
                                    const { name, city, country } = airport;
                                    return <option value={name}>{name}</option>;
                                  })}
                                </Form.Control>
                              </div>
                            </div>
                          </Row>
                          <Row>
                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className="col-md-3"
                            >
                              <div className="form-group">
                                <span className="form-label">Departing</span>
                                <Form.Control
                                  className="form-control"
                                  type="date"
                                  required
                                />
                              </div>
                            </Col>
                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className="col-md-3"
                            >
                              <div className="form-group">
                                <span className="form-label">Returning</span>
                                <Form.Control
                                  className="form-control"
                                  type="date"
                                  required
                                />
                              </div>
                            </Col>
                            <Col
                              xl={3}
                              lg={3}
                              md={3}
                              sm={12}
                              xs={12}
                              className="col-md-3"
                            >
                              <div className="form-group">
                                <span className="form-label">Adults (18+)</span>
                                <div className="booking-adult-child-increase-button">
                                  <Form.Control
                                    type="number"
                                    name="adultSeat"
                                    value={this.state.adultSeat}
                                    onChange={this.changeHandler}
                                    required
                                  />
                                  {/* <button type="button" onClick={this.addAdultSeat}>+</button>
                            <button type="button" onClick={this.removeAdultSeat}>-</button> */}
                                  <button
                                    type="button"
                                    onClick={this.resetAdultSeat}
                                  >
                                    reset
                                  </button>
                                  <span className="select-arrow"></span>
                                </div>
                              </div>
                            </Col>
                            <Col className="col-md-3">
                              <div className="form-group">
                                <span className="form-label">
                                  Children (0-10)
                                </span>
                                <div className="booking-adult-child-increase-button">
                                  <Form.Control
                                    type="number"
                                    name="childSeat"
                                    value={this.state.childSeat}
                                    onChange={this.changeHandler}
                                  />
                                  {/* <button type="button" onClick={this.addChildSeat}>+</button>
                            <button type="button" onClick={this.removeChildSeat}>-</button> */}
                                  <button
                                    type="button"
                                    onClick={this.resetChildSeat}
                                  >
                                    reset
                                  </button>
                                  <span className="select-arrow"></span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div className="col-md-3">
                              <div className="form-group">
                                <span className="form-label">Travel class</span>
                                <Form.Control
                                  as="select"
                                  name="travelClass"
                                  onChange={this.changeHandler}
                                >
                                  <option>Choose class</option>
                                  <option>Economy class</option>
                                  <option>Business class</option>
                                  <option>First class</option>
                                </Form.Control>
                                <span className="select-arrow"></span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-btn">
                                {/* this.state.flyingFrom===""&&this.state.flyingTo===""&& */}
                                {this.state.travelClass === "" ? (
                                  <button
                                    className="submit-btn-disable"
                                    disabled={true}
                                  >
                                    Show flights
                                  </button>
                                ) : (
                                  <Button
                                    to="/blogpost12"
                                    type="submit"
                                    className="submit-btn-show-flights submit-link"
                                    onClick={this.changeVisibility}
                                  >
                                    Show flights
                                  </Button>
                                )}
                              </div>
                            </div>
                          </Row>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        ) : (
          <div>
            <div>
              {this.flightsData.map((flights) => {
                const { flight_name, city_name, currency, price, carrier, time, img } = flights;
                return (
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      cursor: "pointer",
                    }}
                  >
                    <Container>
                      <Row>
                        {/* <Col>
                          <img src={img} style={{ width: "30%" }} />
                          <br />
                        </Col>
                        <Col>
                          Flight: {carrier}
                          <br />
                        </Col>
                        <Col>
                          currency: {currency}
                          <br />
                        </Col>
                        <Col>
                          price: {price}
                          <br />
                        </Col>
                        <Col>time: {time}</Col>
                        <Col>
                          <Button
                            variant="outlined-blue"
                            onClick={() =>
                              alert(`thanks for booking flight#${carrier}`)
                            }
                          >
                            Book Holiday
                          </Button>
                        </Col> */}

                        {/* <div>
        <input type="file" onChange={this.handleChange}/>
        <img src={this.state.file}/>
      </div> */}
                        {/* currency: {currency}<br/>
                      price: {price}<br/>
                      time: {time}<br/> */}


                      </Row>

                      <div
                        id="hotellist_inner"
                        className="wider_image"
                        role="region"
                        aria-label="Search results"
                        data-et-view="  OQLOLOLOXaTFKGHCcZQHKKATXT:1  OQLOLOLOMSVSRQbVKKMadMUWe:1 OQLOLOLOdbLEZdfNaT:1 OQLOLOLOVCJVIQNcGZdMbHGQWKHC:2     OLGPNceIWDAOdbIVXVEHXT:4    OLGPNceIWDAOdbIVXVEHXT:6      OLGPNceIWDAOdbIVXVEHINET:1   OLGPNceIWDAOdbIVXVEHINET:4   OLGPNceIWDAOdbIVXVEHINET:5   OLGPNceIWDAOdbIVXVEHINET:8  "
                      >
                        <div
                          data-et-view=""
                          data-et-click="customGoal:OQLOLOLOdbLEZdfNaT:1 customGoal:OQLOLOLOdbLEZdfNaT:3 "
                          className="sr_item  sr_item_new sr_item_default sr_property_block sr_card_no_hover  sr_flex_layout        sr_item--highlighted   with_dates     "
                          data-hotelid="1097107"
                          data-class="5"
                          data-score="8.4"
                          data-highlightedhotel="1097107"
                        >
                          <div
                            className="sr_item_photo sr_card_photo_wrapper add-wow-red-tag"
                            id="hotel_1097107"
                          >
                            <div
                              className="add-red-tag 
                              add-red-tag--ribbon 
                              all-inclusive-ribbon 
                              js_icon_over_photo
                              bfast-included-ribbon"
                              data-et-view=" OLGHeTEFDZSJLZZCYWebFfKcVeRe:1 OLGHeTEFDZSJLZZCYWebFfKcVeRe:5 "
                            >
                              <div className="add-red-tag__content">
                                <span className="add-red-tag__amount add-red-tag__amount_all_inclusive add-red-tag__amount--small">
                                  Best Services
                                  <span className="add-red-tag__symbol"></span>
                                </span>
                              </div>
                            </div>
                            <svg
                              className="wow-icon -iconset-loading"
                              height="128"
                              style={{ display: "none" }}
                              width="128"
                              viewBox="0 0 128 128"
                              role="presentation"
                              aria-hidden="true"
                              focusable="false"
                            >
                              <path
                                d="m64 8a4.67 4.67 0 0 1 4.67 4.67v18.66a4.67 4.67 0 0 1 -4.67 4.67 4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67z"
                                opacity=".75"
                              ></path>
                              <path
                                d="m92 15.48a4.67 4.67 0 0 1 1.71 6.37l-9.34 16.15a4.67 4.67 0 0 1 -6.37 1.72 4.67 4.67 0 0 1 -1.71-6.37l9.33-16.17a4.67 4.67 0 0 1 6.38-1.7z"
                                opacity=".2"
                              ></path>
                              <path
                                d="m112.52 36a4.67 4.67 0 0 1 -1.71 6.37l-16.16 9.34a4.67 4.67 0 0 1 -6.37-1.71 4.67 4.67 0 0 1 1.72-6.37l16.17-9.33a4.67 4.67 0 0 1 6.35 1.7z"
                                opacity=".25"
                              ></path>
                              <path
                                d="m120 64a4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67 4.67 4.67 0 0 1 4.67-4.67h18.67a4.67 4.67 0 0 1 4.66 4.67z"
                                opacity=".3"
                              ></path>
                              <path
                                d="m112.52 92a4.67 4.67 0 0 1 -6.37 1.71l-16.15-9.34a4.67 4.67 0 0 1 -1.72-6.37 4.67 4.67 0 0 1 6.37-1.71l16.17 9.33a4.67 4.67 0 0 1 1.7 6.38z"
                                opacity=".35"
                              ></path>
                              <path
                                d="m92 112.52a4.67 4.67 0 0 1 -6.37-1.71l-9.34-16.16a4.67 4.67 0 0 1 1.71-6.37 4.67 4.67 0 0 1 6.37 1.72l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.35z"
                                opacity=".4"
                              ></path>
                              <path
                                d="m64 120a4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67 4.67 4.67 0 0 1 4.67 4.67v18.67a4.67 4.67 0 0 1 -4.67 4.66z"
                                opacity=".45"
                              ></path>
                              <path
                                d="m36 112.52a4.67 4.67 0 0 1 -1.71-6.37l9.34-16.15a4.67 4.67 0 0 1 6.37-1.72 4.67 4.67 0 0 1 1.71 6.37l-9.33 16.17a4.67 4.67 0 0 1 -6.38 1.7z"
                                opacity=".5"
                              ></path>
                              <path
                                d="m15.48 92a4.67 4.67 0 0 1 1.71-6.37l16.17-9.33a4.67 4.67 0 0 1 6.36 1.7 4.67 4.67 0 0 1 -1.72 6.37l-16.15 9.34a4.67 4.67 0 0 1 -6.37-1.71z"
                                opacity=".55"
                              ></path>
                              <path
                                d="m8 64a4.67 4.67 0 0 1 4.67-4.67h18.66a4.67 4.67 0 0 1 4.67 4.67 4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67z"
                                opacity=".6"
                              ></path>
                              <path
                                d="m15.48 36a4.67 4.67 0 0 1 6.37-1.71l16.15 9.34a4.67 4.67 0 0 1 1.72 6.37 4.67 4.67 0 0 1 -6.37 1.71l-16.17-9.34a4.67 4.67 0 0 1 -1.7-6.37z"
                                opacity=".65"
                              ></path>
                              <path
                                d="m36 15.48a4.67 4.67 0 0 1 6.37 1.71l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.36 4.67 4.67 0 0 1 -6.37-1.72l-9.34-16.15a4.67 4.67 0 0 1 1.71-6.37z"
                                opacity=".7"
                              ></path>
                            </svg>
                            <div className="wl-entry-container">
                              <button
                                type="button"
                                data-hotel-id="1097107"
                                data-text-create="Create a new wish list"
                                data-text-header=""
                                data-ga-label="small"
                                data-placement="hp_tittle"
                                data-wow-comparison-mode="true"
                                data-position="bottom right"
                                data-title="Save"
                                className="js-wl-dropdown-handle lists-wow-dropdown-square-heart jq_tooltip entry-heart-svg-z-idx"
                                aria-label="Add property to your lists "
                                role="button"
                                aria-live="assertive"
                                data-loadingtext=""
                              >
                                <svg
                                  aria-hidden="true"
                                  className="wow-icon -iconset-loading wow-wl-entry-loader-svg"
                                  focusable="false"
                                  role="presentation"
                                  size="medium"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 128 128"
                                >
                                  <path
                                    d="m64 8a4.67 4.67 0 0 1 4.67 4.67v18.66a4.67 4.67 0 0 1 -4.67 4.67 4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67z"
                                    opacity=".75"
                                  ></path>
                                  <path
                                    d="m92 15.48a4.67 4.67 0 0 1 1.71 6.37l-9.34 16.15a4.67 4.67 0 0 1 -6.37 1.72 4.67 4.67 0 0 1 -1.71-6.37l9.33-16.17a4.67 4.67 0 0 1 6.38-1.7z"
                                    opacity=".2"
                                  ></path>
                                  <path
                                    d="m112.52 36a4.67 4.67 0 0 1 -1.71 6.37l-16.16 9.34a4.67 4.67 0 0 1 -6.37-1.71 4.67 4.67 0 0 1 1.72-6.37l16.17-9.33a4.67 4.67 0 0 1 6.35 1.7z"
                                    opacity=".25"
                                  ></path>
                                  <path
                                    d="m120 64a4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67 4.67 4.67 0 0 1 4.67-4.67h18.67a4.67 4.67 0 0 1 4.66 4.67z"
                                    opacity=".3"
                                  ></path>
                                  <path
                                    d="m112.52 92a4.67 4.67 0 0 1 -6.37 1.71l-16.15-9.34a4.67 4.67 0 0 1 -1.72-6.37 4.67 4.67 0 0 1 6.37-1.71l16.17 9.33a4.67 4.67 0 0 1 1.7 6.38z"
                                    opacity=".35"
                                  ></path>
                                  <path
                                    d="m92 112.52a4.67 4.67 0 0 1 -6.37-1.71l-9.34-16.16a4.67 4.67 0 0 1 1.71-6.37 4.67 4.67 0 0 1 6.37 1.72l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.35z"
                                    opacity=".4"
                                  ></path>
                                  <path
                                    d="m64 120a4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67 4.67 4.67 0 0 1 4.67 4.67v18.67a4.67 4.67 0 0 1 -4.67 4.66z"
                                    opacity=".45"
                                  ></path>
                                  <path
                                    d="m36 112.52a4.67 4.67 0 0 1 -1.71-6.37l9.34-16.15a4.67 4.67 0 0 1 6.37-1.72 4.67 4.67 0 0 1 1.71 6.37l-9.33 16.17a4.67 4.67 0 0 1 -6.38 1.7z"
                                    opacity=".5"
                                  ></path>
                                  <path
                                    d="m15.48 92a4.67 4.67 0 0 1 1.71-6.37l16.17-9.33a4.67 4.67 0 0 1 6.36 1.7 4.67 4.67 0 0 1 -1.72 6.37l-16.15 9.34a4.67 4.67 0 0 1 -6.37-1.71z"
                                    opacity=".55"
                                  ></path>
                                  <path
                                    d="m8 64a4.67 4.67 0 0 1 4.67-4.67h18.66a4.67 4.67 0 0 1 4.67 4.67 4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67z"
                                    opacity=".6"
                                  ></path>
                                  <path
                                    d="m15.48 36a4.67 4.67 0 0 1 6.37-1.71l16.15 9.34a4.67 4.67 0 0 1 1.72 6.37 4.67 4.67 0 0 1 -6.37 1.71l-16.17-9.34a4.67 4.67 0 0 1 -1.7-6.37z"
                                    opacity=".65"
                                  ></path>
                                  <path
                                    d="m36 15.48a4.67 4.67 0 0 1 6.37 1.71l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.36 4.67 4.67 0 0 1 -6.37-1.72l-9.34-16.15a4.67 4.67 0 0 1 1.71-6.37z"
                                    opacity=".7"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                            <a
                              className=" sr_item_photo_link sr_hotel_preview_track  "
                              rel="noopener"
                              tabindex="-1"
                              focusable="false"
                              aria-hidden="true"
                            >
                              {/* <img
                                className="hotel_image"
                                src="https://cf.bstatic.com/xdata/images/hotel/square200/183067061.webp?k=cb84b55cc2ff46207844efe69bddbfb9c8663c3937756b67a6ba216f006e044f&amp;o="
                                data-highres="https://cf.bstatic.com/xdata/images/hotel/square600/183067061.webp?k=cb84b55cc2ff46207844efe69bddbfb9c8663c3937756b67a6ba216f006e044f&amp;o="
                                alt="Faisalabad Serena Hotel"
                                data-google-track="Click/sr_item_main_photo/0"
                                width="200"
                                height="200"
                              /> */}
                              <img src={img} style={{ width: "100%" }} />
                              <span className="invisible_spoken">
                                Opens in new window
                              </span>
                            </a>
                          </div>
                          <div className="sr_item_content sr_item_content_slider_wrapper ">
                            <div className="sr_property_block_main_row">
                              <div className="sr_item_main_block">
                                <div className="wow-hotel__title-wrap">
                                  <h3
                                    className="wow-hotel__title  "
                                  >
                                    <a
                                      className="hotel_name_link url"
                                      rel="noopener"
                                    >
                                      <span
                                        className="wow-hotel__name"
                                        data-et-click="    customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1    "
                                      >
                                        {flight_name}
                                        {/* Faisalabad Serena Hotel */}
                                      </span>
                                      <span className="invisible_spoken">
                                        Opens in new window
                                      </span>
                                    </a>
                                  </h3>
                                  <span className="wow-hotel__title-badges">
                                    <i
                                      className="wow-icon-wrapper wow-icon-stars star_track"
                                      title="5-star hotel"
                                    >
                                      <svg
                                        aria-hidden="true"
                                        className="wow-icon -sprite-ratings_stars_5"
                                        focusable="false"
                                        height="10"
                                        width="54"
                                        viewBox="0 0 65 12"
                                        role="presentation"
                                      >
                                        <path
                                          d="m13 4.6c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.1-.5-.1-.5 0l-1.5 4-4.6.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3h.3l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.4 3.5-2.5c.1 0 .1-.1.1-.2zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5z"
                                          fill="#feba02"
                                        ></path>
                                      </svg>
                                      <span className="invisible_spoken">
                                        5-star hotel
                                      </span>
                                    </i>
                                    <span data-et-view="TPOaXGZCHQGPGJIMADXRT:1"></span>
                                    <svg
                                      aria-hidden="true"
                                      className="wow-icon -iconset-thumbs_up_square 
                                      pp-icon-valign--tbottom 
                                      js-preferred-property-copy-cached"
                                      data-bui-component="Tooltip"
                                      data-tooltip-follow="true"
                                      data-tooltip-position="bottom"
                                      data-tooltip-text="This is a Preferred Partner property. It's committed to giving guests positive experiences with its excellent service and great value. This property might pay Booking.com a little more to be in this Program."
                                      fill="#FEBB02"
                                      height="20"
                                      rel="300"
                                      width="20"
                                      viewBox="0 0 128 128"
                                      role="presentation"
                                      focusable="false"
                                    >
                                      <path d="M112 8H16a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h96a8 8 0 0 0 8-8V16a8 8 0 0 0-8-8zM48 96H24V58h24zm56-25a8.7 8.7 0 0 1-2 6 8.9 8.9 0 0 1 1 4 6.9 6.9 0 0 1-5 7c-.5 4-4.8 8-9 8H56V58l10.3-23.3a5.4 5.4 0 0 1 10.1 2.7 10.3 10.3 0 0 1-.6 2.7L72 52h23c4.5 0 9 3.5 9 8a9.2 9.2 0 0 1-2 5.3 7.5 7.5 0 0 1 2 5.7z"></path>
                                    </svg>
                                  </span>
                                </div>
                                <div className="sr_card_address_line">
                                  <a
                                    className="bui-link"
                                    data-google-track="Click/Action: sr_map_link_used"
                                    data-coords="73.083575963974,31.4271238764644"
                                    target="_blank"
                                    data-map-caption=""
                                    rel="noopener"
                                    data-et-click="customGoal:YdXfdKNKNKHUScFAYTZTEeNGTODXKe:3"
                                  >
                                    {/* Faisalabad */}
                                    {city_name}
                                    {/* <span className="sr_card_address_line__item">
                                      <span className="sr_card_address_line__dot-separator"></span>
                                      Show on map
                                    </span> */}
                                  </a>
                                  <span className="sr_card_address_line__dot-separator"></span>
                                  <span
                                    data-bui-component="Tooltip"
                                    data-tooltip-position="top"
                                    data-tooltip-follow=""
                                    data-tooltip-light=""
                                    data-tooltip-text="This is the straight-line distance on the map. Actual travel distance may vary."
                                  >
                                    1.2 km from center
                                  </span>
                                </div>
                              </div>
                              <div className="sr_item_review_block">
                                <div className="reviewFloater reviewFloaterBadge__container">
                                  <div
                                    className="wow-review-score"
                                  >
                                    <a
                                      className="wow-review-score__link"
                                    >
                                      <div className="bui-review-score c-score bui-review-score--end">
                                        {" "}
                                        <div
                                          className="bui-review-score__badge"
                                          aria-label="Scored 8.4 "
                                        >
                                          {" "}
                                          8.4{" "}
                                        </div>{" "}
                                        <div className="bui-review-score__content">
                                          {" "}
                                          <div className="bui-review-score__title">
                                            {" "}
                                            Very Good{" "}
                                          </div>{" "}
                                          <div className="bui-review-score__text">
                                            {" "}
                                            157 reviews{" "}
                                          </div>{" "}
                                          <div class="bui-review-score__text flights-card-for-practice">
                                        {" "}
                                         {this.state.date}
                                         {" "}
                                        </div>{" "}
                                        </div>{" "}
                                      </div>
                                      <span className="invisible_spoken">
                                        Opens in new window
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="wow-badges__row">
                              <div className="wow-property-highlights">
                                <span data-et-view="OLWQREDRETJUTGCdNJBcSTKe:1 OLWQREDRETJUTGCdNJBcSTKe:2"></span>
                                <div className="bui-spacer--small wow-badge-block">
                                  <svg
                                    className="wow-icon -streamline-hospital_shield"
                                    fill="#333333"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 24 24"
                                    role="presentation"
                                    aria-hidden="true"
                                    focusable="false"
                                  >
                                    <path d="M18 9a1.5 1.5 0 0 0-1.5-1.5h-2.25l.75.75V6a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 9 6v2.25l.75-.75H7.5A1.5 1.5 0 0 0 6 9v3a1.5 1.5 0 0 0 1.5 1.5h2.25L9 12.75V15a1.5 1.5 0 0 0 1.5 1.5h3A1.5 1.5 0 0 0 15 15v-2.25l-.75.75h2.25A1.5 1.5 0 0 0 18 12V9zm-1.5 0v3h-2.25a.75.75 0 0 0-.75.75V15h-3v-2.25a.75.75 0 0 0-.75-.75H7.5V9h2.25a.75.75 0 0 0 .75-.75V6h3v2.25c0 .414.336.75.75.75h2.25zm-15-5.077v7.614c0 5.237 3.225 9.933 8.113 11.813l1.041.4a3.75 3.75 0 0 0 2.692 0l1.041-.4A12.657 12.657 0 0 0 22.5 11.537V3.923a2.234 2.234 0 0 0-1.306-2.044A22.463 22.463 0 0 0 11.993 0C8.846-.03 5.715.61 2.818 1.874A2.24 2.24 0 0 0 1.5 3.928zM3 3.918a.736.736 0 0 1 .43-.675A20.937 20.937 0 0 1 11.993 1.5a20.969 20.969 0 0 1 8.589 1.748c.252.116.42.38.418.67v7.619c0 4.616-2.843 8.756-7.151 10.413l-1.041.4c-.52.2-1.096.2-1.616 0l-1.041-.4A11.157 11.157 0 0 1 3 11.537V3.923z"></path>
                                  </svg>
                                  <span>Extra health &amp; safety measures taken</span>
                                </div>
                              </div>
                              <div className="genius-benefits-badges"></div>
                            </div>
                            <div className="sr_rooms_table_block clearfix sr_card_rooms_container">
                              <div className="room_details ">
                                <div
                                  className="featuredRooms sr_room_table sr_room_table_flex sr_rt_wider_urgency_msg sr_card_room_info__container  nohead sr_rms_tbl_bdr "
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <div>
                                    <div
                                      className="roomrow roomrow_flex entire_row_clickable"
                                      data-link="/hotel/pk/faisalabad-serena.en-us.html?aid=808942&amp;label=europeanbd-hotelspage-sb-click-topbox&amp;sid=cc98ca06f46cb4162221e8cfc1ff0d7b&amp;all_sr_blocks=109710712_274304051_0_1_0&amp;checkin=2020-08-31&amp;checkout=2020-09-01&amp;dest_id=-2759985&amp;dest_type=city&amp;group_adults=2&amp;group_children=0&amp;hapos=1&amp;highlighted_blocks=109710712_274304051_0_1_0&amp;hpos=1&amp;no_rooms=1&amp;sr_order=popularity&amp;sr_pri_blocks=109710712_274304051_0_1_0__9000&amp;srepoch=1598857501&amp;srpvid=580431ceafba01dc&amp;ucfs=1&amp;from=searchresults;show_room=109710712#RD109710712"
                                      data-target="_blank"
                                      tabindex="0"
                                      data-et-click="   customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1  "
                                    >
                                      <div
                                        className="roomName roomName_flex --wider unit-info"
                                      >
                                        <div className="roomNameInner">
                                          <div className="room_link">
                                            <span
                                              style={{ color: "#000" }}
                                              role="link"
                                              tabindex="0"
                                            >
                                              <strong>
                                                Deluxe Double Room
                                              </strong>
                                              &nbsp;–&nbsp;
                                              <div
                                                className="c-occupancy-icons "
                                                style={{
                                                  fontSize: "10px",
                                                  color: "#000000",
                                                }}
                                              >
                                                <span
                                                  className="c-occupancy-icons__adults "
                                                  aria-hidden="true"
                                                >
                                                  <i className="fas fa-user"></i>
                                                  <i className="fas fa-user"></i>
                                                </span>
                                                <span className="bui-u-wow-only">
                                                  ...
                                                </span>
                                              </div>
                                            </span>
                                          </div>
                                          <span className="room_info">
                                            <div data-et-view="NAFLeOeJAETbUBPTVdCTEPMWQIXe:1"></div>
                                            Private room •
                                            <div className="c-beds-configuration c-beds-configuration--inline">
                                              1 full bed
                                            </div>
                                          </span>
                                          <div className="sr_card_room_policies__container">
                                            <sup className="sr_room_reinforcement">
                                              Breakfast included
                                            </sup>
                                          </div>
                                          <div className="sr_card_room_policies__container">
                                          <sup className="sr_room_reinforcement-risk-text">
                                            Risk Free: You can cancel later, so lock in 
                                            this great price today!
                                            </sup>
                                          </div>
                                          <div className="js_sr_persuation_msg"></div>
                                        </div>
                                      </div>
                                      <div
                                        className="roomPrice roomPrice_flex  sr_discount "
                                        colspan="2"
                                      >
                                        <div className="prco-wrapper bui-price-display prco-wow-default-assembly-wrapper prc-d-wow-wrapper">
                                          <div className="prco-ltr-right-align-helper">
                                            <div className="bui-price-display__label prco-inline-block-maker-helper">
                                              1 night, 2 adults
                                            </div>
                                          </div>
                                          <div className="prco-ltr-right-align-helper">
                                            <div className="prco-inline-block-maker-helper">
                                              <div
                                                className="bui-price-display__value prco-text-nowrap-helper prco-inline-block-maker-helper"
                                                aria-hidden="true"
                                                data-et-mouseenter="customGoal:AdeKbCcBUfQUaSHbZFVXOJUNQKFcFXZYCaJFSSZRe:2"
                                              >
                                                PKR&nbsp;{price}
                                              </div>
                                              <span className="bui-u-wow-only">
                                                Price PKR&nbsp;{price}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="prco-ltr-right-align-helper">
                                            <div
                                              className="prd-taxes-and-fees-under-price prco-inline-block-maker-helper blockuid-"
                                              data-excl-charges-raw="2397.60106974966"
                                              data-cur-stage="2"
                                            >
                                              {" "}
                                              +PKR&nbsp;2,398 taxes and charges{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          data-component="earn-credits"
                                          className="c-earn-credits "
                                          data-earn-credits-raw=""
                                          data-prevent-propagation=""
                                        ></div>
                                        <div>
                                          <div>
                                            <div colspan="4" className="roomFooter">
                                              <div
                                                className="wow-cta-button-row"
                                                data-et-click="    customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1   "
                                              >
                                                <a
                                                  className="txp-cta bui-button bui-button--primary sr_cta_button"
                                                  data-et-click="customGoal:OQLOLOLOMSVSRQbVKKMadMUWe:1 customGoal:OQLOLOLOMSVSRQbVKKMadMUWe:3"
                                                  data-click-store-id="wow-compset-1097107"
                                                >
                                                  <span className="bui-button__text"
                                                  onClick={() =>{
                                                  alert(`thanks for booking flight ${flight_name}`);
                                                  alert(`Hi bro lets book Your flight ${time}`);
                                                  this.changeVisibility();}}
                                                  >
                                                  Book Holiday
                                                  </span>

                                                  <div className="txp-arr bui-button__icon">
                                                    <svg
                                                      className="wow-icon -streamline-arrow_nav_right"
                                                      height="16"
                                                      width="16"
                                                      viewBox="0 0 24 24"
                                                      role="presentation"
                                                      aria-hidden="true"
                                                      focusable="false"
                                                    >
                                                      <path d="M9.45 6c.2 0 .39.078.53.22l5 5c.208.206.323.487.32.78a1.1 1.1 0 0 1-.32.78l-5 5a.75.75 0 0 1-1.06 0 .74.74 0 0 1 0-1.06L13.64 12 8.92 7.28a.74.74 0 0 1 0-1.06.73.73 0 0 1 .53-.22zm4.47 5.72zm0 .57z"></path>
                                                    </svg>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                );
              })}
              <center>
              <Button variant="outline-dark" onClick={this.changeVisibility}>
                Back
              </Button>
              </center>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Booking_ticket2;
