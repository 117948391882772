import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";




import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory
} from "react-router-dom";

class Termsconditions extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false
    };
  }


  



 

 

 
  render() {
    return (
      <div>






        {/* <div className="holiday-inspiration-wow-holiday-our-container">
          <div class="holiday-inspiration-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-inspiration-wow-holiday-home-our-story">
                <div class="holiday-inspiration-wow-holiday-our-team-heading">
                  Holiday inspiration
                </div>
                <div class="holiday-inspiration-wow-holiday-our-team-heading-below-text">
                  If you need ideas for where to go on your next trip, let us
                  inspire you with our list of the best holiday destinations for
                  every type of vacation.
                </div>
                <div class="holiday-credit-wow-holiday-our-team-heading">
                  <Button
                    variant="outline-light"
                    className="holiday-credit-wow-holiday-our-team-button"
                  >
                    Check My Credits
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> */}











{/* 

<Carousel>
          <Carousel.Item className="carousel-slider-in-wow-holiday-trust-safety">
            <img
              className="blockx"
              src="IMAGES/city-images/home-slider/homeslider1.png"
              alt="First slide"
            />

            <Carousel.Caption>
              <div className="trust_slider_text_area123456l">
              <h3 className="carousel-text-wow-holiday">WOW HOLIDAY RIDE</h3>
              <p className="carousel-text-inside-wow-holiday">
                Simplify your life with comfortable and secure drive.</p>
                </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
         */}



        
        <div>
          <div class="carousel-trust-safety ">
            <ol class="carousel-indicators-trust-safety ">
              <li class="active-trust-safety">
                </li>
                 </ol>
                 <div class="carousel-inner-trust-safety slide-trust-safety">
                   <div class="home-pageslider_top-trust-safety carousel-item-trust-safety active-carousel-inner-trust-safety carousel-item-trust-safety">
                     <img 
                     class="d-block-trust-safety w-100-trust-safety image-carousel-trust-safety" 
                     src="IMAGES/city-images/trustsafety/carousel1.png"
                      alt="First slide"/>
                        <div class="home_slider_text_area123456l-trust-safety carousel-caption-trust-safety">
                          <h3 class="about_fdkng12154-trust-safety">WOW Holiday anticipate towards more dependable casual.</h3>
                          <p class="about_fdkng-trust-safety">
                             Enjoy your stress-free and secure rides which will reflect a positive 
                             impact in your life.</p></div>
                             </div>
                             </div>
                             
                             <a class="carousel-control-prev-trust-safety" 
                             role="button"
                              href="#">
                                <span class="sr-only-trust-safety">Previous</span></a>
                                <a class="carousel-control-next-trust-safety" role="button" href="#">
                                  </a>
                                  </div>
                                  </div>













































       

        {/* <div>
          <header className="header-image-in-new-design-transport">


   <div className="logo-inside-header-of-new-design-page-transport">
     <img className="image-logo-inside-header-of-new-design-page-wow-transport" src="IMAGES/WOWholiday-iconlogo.png"></img>
   </div>


   <div className="logo-inside-header-of-new-design-page--wow-transport">
     <img className="image-logo-inside-header-of-new-design-page-transport" src="IMAGES/city-images/wow-gallery/tour-logo.png"></img>
   </div>


   <div className="text-box-inside-background-image-of-new-design-transport">
  <h1 className="heading-primary-transport">
 



    <span className="heading-primary-main-transport">
     Trust & Safety

    </span>
    <span className="heading-primary-sub-transport">
      Transport,Services & Team

    </span>
  </h1>

  <div class="holiday-transport-wow-holiday-our-team-heading-below-text">
                  We provide the best transport &services that you need during your trip. Our team is very
                   cooperative, provide good hospitality and keep you entertain all the way. 
                </div>
  <Button variant="outline-light" id="scrolltoup" onClick={this.toscrolltoup} className="button-in-new-design-gallery" > Discover Our Tours</Button>

  </div>
  
  <toggle>

  </toggle>



          </header></div> */}




          <Container>


          <div class="u-center-text u-margin-bottom-second-heading-trust-safety">
                  <div  className="heading-secondary-section-trust-safety ">
                      WOW Holiday Trust & Safety
                  </div>
                  </div>


          </Container>





          



          <Container>


          <div class="u-left-text u-margin-bottom-heading-trust-safety">
                  <div  className="heading-secondary-trust-safety ">
                      WOW Key To Travel Safe and Smart
                  </div>
                  </div>


                  <p className="trust-and-saefuty-page-bold-text">
                    Whether you’re a rider, leader, or anyone who functions WOW, your safety thrusts us.</p>


                  <div className="trust-and-safety-behind-bold-text">
                  Well...if your goal is to have a unique and memorable travel adventure...
                  if you want to see and ex-perience the most you can from your travel time in Europe,
                   you can do it all!
                  </div>

                  <div className="trust-and-safety-planning-text">
                  Your trip begins with the planning.
                  </div>

                  <p className="trust-and-saefuty-page-classic-text">
                  Whether it's choosing your destination, learning about the history and culture,
                   or creating a travel budget, planning ahead is the most important step 
                   in the travel planning process.

And knowing how to travel safe and travel smart is the glue that holds your travel plans together.<br/>

We acknowledge that every time you open your WOW app, you are committing your confidence in us to not
 exclusively aim you wherever you motive to travel, merely to besides assist hold you and your dearest’s
  secure. That commit comprises what aims us to ceaselessly climb the block, 
  constructing new guard technology, arranging guideposts for reverential and favorable receives,
   and a lot. We're seeking to build WOW the most dependable approach to circulate our locale. We
    do this by employing the most recent security innovations and guaranteeing a exhaustive on boarding
     and devising process for our Captains. You are able to likewise follow your ride and connect with us
      whenever, day in and day out.
                  </p>


          </Container>


































          <Container>


<div class="u-center-text u-margin-bottom-second-heading-trust-safety">
        <div  className="heading-safety-action-choose-us-trust-safety ">
        Why Choose Us
        </div>
        </div>


        <div className="u-center-text text-written-below-why-choose-us-safety-holiday" >
        JUST SOME OF THE REASONS YOU CAN TRUST US WITH YOUR GUIDED WOW HOLIDAY
</div>




        
<Row>
  <Col xl={4} lg={4} md={4} sm={12} xs={12} className="service-box-safety-margin">
  <div className="services-box-safety">



     
<i 
className="
lg-icon-safety
  circle-icon-safety primary-icon-filled-safety fas fa-users"></i>

<div  className="header-heading1-inside-grid-wow-trust-our-safety-features-box u-margin-bottom-small">
Our Exceptional Level of Care
</div>


<p className="feature__box-text-wow-trust-our-safety">
From the moment you enquire, until you return to your home shores, you’ll have a dedicated 
account manager to help create your dream New Zealand tour. They’ll work hard behind-the-scenes
 so that you can relax and enjoy a stress-free vacation.                  
</p>

</div>




  </Col>


  <Col xl={4} lg={4} md={4} sm={12} xs={12} className="service-box-safety-margin">
 
<div className="services-box-safety">



   
<i 
className="
lg-icon-safety
  circle-icon-safety primary-icon-filled-safety fas fa-cubes"></i>

<div  className="header-inside-grid-wow-trust-our-safety-features-box u-margin-bottom-small">
Friendly Enviroment
</div>


<p className="feature__box-text-wow-trust-our-safety">
Our Expert Team are friendly and accommodating to your needs. They receive extra training 
in working and other medical equipment you need.
They all genuinely love to share with you their local knowledge.
</p>

</div>

</Col>




  <Col xl={4} lg={4} md={4} sm={12} xs={12} className="service-box-safety-margin">
  <div className="services-box-safety">



     
<i className="lg-icon-safety
  circle-icon-safety primary-icon-filled-safety fas fa-user-shield"></i>

<div  className="header-inside-grid-wow-trust-our-safety-features-box u-margin-bottom-small">
Our Reviews & 24/7 Support
</div>


<p className="feature__box-text-wow-trust-our-safety">
The memories made on your bespoke tour will last a lifetime. This is why we’re delighted 
to receive such fantastic feedback from our guests about services provided by our 
tour guides.
Get assistance whenever and wherever you are,
Providing an exceptional visitor experience is what we do 24/7, 365 days a year.
</p>

</div>
  </Col>


 

</Row>




</Container>
























          

          <Container>


<div class="u-center-text u-margin-bottom-second-heading-trust-safety">
        <div  className="heading-safety-action-rider-section-trust-safety ">
        SAFETY ACTIONS FOR Travellers
        </div>
        </div>


</Container>





<div className="safety-page-right-back-gradiant">
  <Container>
  
    <Row>
    
      <Col xl={6} lg={6} md={6} sm={12} xs={12}> 

      <div className="padding-in-trustsafety-grid-text">
        <div className="safety-page-araea-sub-headings110">
        BEFORE YOU TRAVEL
        </div>

        <div className="trust-and-saefuty-page-text">
        All Captains experience an exhaustive blocking out routine and habitually acquire well-being and 
        surety grooming. To screen your shelter, you are able to link your Leader namelessly with margin call.

        </div>
      </div>


    </Col>


      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col3.jpg">

       </img>
      </Col>
      </Row>

  


   
  </Container>
  </div>







  <div className="safety-page-left-back-gradiant">
  <Container>
  
    <Row>

    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col4.jpg"></img>
      </Col>


    
      <Col xl={6} lg={6} md={6} sm={12} xs={12}> 

      <div className="padding-in-trustsafety-grid-text">
        <div className="safety-page-araea-sub-headings110">
        BEFORE YOU TRAVEL
        </div>

        <div className="trust-and-saefuty-page-text">
        All Captains experience an exhaustive blocking out routine and habitually acquire well-being and 
        surety grooming. To screen your shelter, you are able to link your Leader namelessly with margin call.

        </div>
      </div>


    </Col>


      
      </Row>


   
  </Container>
  </div>


  {/* <Container>


<div class="u-center-text u-margin-bottom-second-heading-trust-safety">
        <div  className="heading-safety-action-rider-section-trust-safety ">
        SAFETY ACTIONS FOR Travellers
        </div>
        </div>


</Container> */}


  <div className="safety-page-right-back-gradiant-3">
  <Container>
  
    <Row>
    
      <Col xl={6} lg={6} md={6} sm={12} xs={12}> 

      <div className="padding-in-trustsafety-grid-text">
        <div className="safety-page-araea-sub-headings110-3">
        BEFORE YOU TRAVEL
        </div>

        <div className="trust-and-saefuty-page-text-3">
        All Captains experience an exhaustive blocking out routine and habitually acquire well-being and 
        surety grooming. To screen your shelter, you are able to link your Leader namelessly with margin call.

        </div>
      </div>


    </Col>


      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col.jpg">

       </img>
      </Col>
      </Row>

  


   
  </Container>
  </div>










  <div className="safety-page-right-back-gradiant">
  <Container>
  
    <Row>

    <Col xl={6} lg={6} md={6} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col3.jpg">

       </img>
      </Col>


    
      <Col xl={6} lg={6} md={6} sm={12} xs={12}> 

      <div className="padding-in-trustsafety-grid-text">
        <div className="safety-page-araea-sub-headings110">
        BEFORE YOU TRAVEL
        </div>

        <div className="trust-and-saefuty-page-text">
        All Captains experience an exhaustive blocking out routine and habitually acquire well-being and 
        surety grooming. To screen your shelter, you are able to link your Leader namelessly with margin call.

        </div>
      </div>


    </Col>


      
      </Row>


   
  </Container>
  </div>





  <div className="safety-page-left-back-gradiant">
  <Container>
  
    <Row>
    
      <Col xl={6} lg={6} md={6} sm={12} xs={12}> 

      <div className="padding-in-trustsafety-grid-text">
        <div className="safety-page-araea-sub-headings110">
        BEFORE YOU TRAVEL
        </div>

        <div className="trust-and-saefuty-page-text">
        All Captains experience an exhaustive blocking out routine and habitually acquire well-being and 
        surety grooming. To screen your shelter, you are able to link your Leader namelessly with margin call.

        </div>
      </div>


    </Col>


      <Col xl={6} lg={6} md={6} sm={12} xs={12}>
       <img src="IMAGES/city-images/trustsafety/blog-col3.jpg">

       </img>
      </Col>
      </Row>

  


   
  </Container>
  </div>















{/* <br/><br/><br/>

       
<Container>
<button type="button" class="collapsible" id="collapse">Open Section 1</button>

<div class="content">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div>
</Container>


<br/><br/> */}

















                      



         




             






{/* <Container>

<div className="border-line-bw-transport-images-three-buttons-below"></div>
</Container> */}




</div>



    );
  }
}
export default Termsconditions;
