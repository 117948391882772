import React, { Component } from "react";
import FontAwesome from "react-fontawesome";

import Wowholidayinspiration from "../Wowholidayinspiration/holidayinspiration.js";
import "../Wowholidayinspiration/holidayinspiration.css";

import Holidayinspirationcard1 from "../Holidayinspirationcard1/inspirationcard1.js";
import "../Holidayinspirationcard1/inspirationcard1.css";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory
} from "react-router-dom";

class holidayinspiration extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false
    };
  }

  componentWillMount() {}
  componentDidMount() {}
  render() {
    return (
      <div>
        <div className="holiday-inspiration-wow-holiday-our-container">
          <div class="holiday-inspiration-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-inspiration-wow-holiday-home-our-story">
                <div class="holiday-inspiration-wow-holiday-our-team-heading">
                  Holiday Attractions
                </div>
                <div class="holiday-inspiration-wow-holiday-our-team-heading-below-text">
                  If you need ideas for where to go on your next trip, let us
                  inspire you with our list of the best holiday destinations for
                  every type of vacation.
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{
              backgroundImage: "url(/IMAGES/city-images/aboutus/background-image.jpg)"
            }}
            class="bg-displaced bg-image">

        <Container>


          <div className="background-image-in-cols-inspiration">

          
            <Row>
           

              <Col>
             
              <div className="class-around-text-inpiration-cols">
  <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
      <div style={{padding:'15px'}}>
    <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
    Hiking Adventures in Japan

    </span>
    <span className="heading-primary-sub-inspiration-inside-cols">
    Inside Japan Tours organise small group tours & self-guided trips which include
     some of Japan’s best multi-day hikes. The sacred pilgrimage trails of the Kumano
      Kodo and the ancient Nakasendo Way samurai route are just some of the epic
       hikes included on this tour. 

    </span></div>
  </div>
 
  </div>
  
  
              </Col>





              <Col>

              <div className="padding-in-images">
                  <img className="img-fluid image-in-inspirations-cols " src="IMAGES/city-images/holiday-inspiration/locations/japan.jpg">
                  
                  </img>
              </div>
              
              
              </Col>


              
            </Row>

            
            


            <Row>



              <Col>
              <div className="padding-in-images">
                  <img className="img-fluid image-in-inspirations-cols " src="IMAGES/city-images/holiday-inspiration/locations/japan.jpg">
                  
                  </img>
              </div>
              
              </Col>




              <Col>
              <div className="class-around-text-inpiration-cols">
  <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
      <div style={{padding:'15px'}}>
    <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
    Hiking Adventures in Japan

    </span>
    <span className="heading-primary-sub-inspiration-inside-cols">
    Inside Japan Tours organise small group tours & self-guided trips which include
     some of Japan’s best multi-day hikes. The sacred pilgrimage trails of the Kumano
      Kodo and the ancient Nakasendo Way samurai route are just some of the epic
       hikes included on this tour. 

    </span></div>
  </div>
 
  </div>
              
              </Col>



            </Row>


            <Row>



              <Col>

              <div className="class-around-text-inpiration-cols">
  <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
      <div style={{padding:'15px'}}>
    <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
    Hiking Adventures in Japan

    </span>
    <span className="heading-primary-sub-inspiration-inside-cols">
    Inside Japan Tours organise small group tours & self-guided trips which include
     some of Japan’s best multi-day hikes. The sacred pilgrimage trails of the Kumano
      Kodo and the ancient Nakasendo Way samurai route are just some of the epic
       hikes included on this tour. 

    </span></div>
  </div>
 
  </div>
              
              
              </Col>


              <Col>

              <div className="padding-in-images">
                  <img className="img-fluid image-in-inspirations-cols " src="IMAGES/city-images/holiday-inspiration/locations/japan.jpg">
                  
                  </img>
              </div>
              
              
              </Col>



            </Row>



          </div>
        </Container>
        </div> */}

        <br />

        <div class="bg-displaced bg-image background-image-inholiday-inspiration-cols"  >
          <Container >
            <div className="background-image-in-cols-inspiration">
              <Row>
                <Col xs={12} lg={6} md={6} sm={6}>      
                  <div className="class-around-text-inpiration-cols">
                    <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
                      <div style={{ padding: "0px 15px" }}>
                        <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
                          Hiking Adventures in Japan
                        </span>
                        <span className="heading-primary-sub-inspiration-inside-cols">
                          Inside Japan Tours organise small group tours &
                          self-guided trips which include some of Japan’s best
                          multi-day hikes. The sacred pilgrimage trails of the
                          Kumano Kodo and the ancient Nakasendo Way samurai
                          route are just some of the epic hikes included on this
                          tour.
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12} lg={6} md={6} sm={6}>  
                  <div className="padding-in-images">
                    <img
                      className="img-fluid image-in-inspirations-cols "
                      src="IMAGES/city-images/holiday-inspiration/locations/japan.JPG"
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <br />

        <div class="bg-displaced bg-image background-image-inholiday-inspiration-cols" >
          <Container>
            <div className="background-image-in-cols-inspiration">
              <Row>
              <Col xs={12} lg={6} md={6} sm={6}>  
                  <div className="padding-in-images">
                    <img
                      className="img-fluid image-in-inspirations-cols "
                      src="IMAGES/city-images/holiday-inspiration/locations/austria.JPG"
                    ></img>
                  </div>
                </Col>

                <Col xs={12} lg={6} md={6} sm={6}>  
                  <div className="class-around-text-inpiration-cols">
                    <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
                      <div style={{ padding: "0px 15px" }}>
                        <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
                          Austria in the Summer
                        </span>
                        <span className="heading-primary-sub-inspiration-inside-cols">
                          The mountains are calling. Austria in the summer
                          breaks are all about getting outdoors. Think
                          picturesque lakes, magnificent mountains, hiking,
                          biking and spa hotels. I spent time in Alpbach in the
                          summer and I can’t wait to get back there. Holiday
                          inspiration indeed.
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div class="bg-displaced bg-image background-image-inholiday-inspiration-cols">
          <Container>
            <div className="background-image-in-cols-inspiration">
              <Row>
              <Col xs={12} lg={6} md={6} sm={6}>  
                  <div className="class-around-text-inpiration-cols">
                    <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
                      <div style={{ padding: "0px 15px" }}>
                        <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
                          Cooking & wine-tasting Puglia
                        </span>
                        <span className="heading-primary-sub-inspiration-inside-cols">
                          To get an authentic taste of local life you’ll stay in
                          a masseria. This traditional farmhouse in Puglia is
                          surrounded by olive groves and beautiful countryside.
                          On the menu is traditional Italian fare with a cooking
                          class, and wine-tasting.
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xs={12} lg={6} md={6} sm={6}>  
                  <div className="padding-in-images">
                    <img
                      className="img-fluid image-in-inspirations-cols "
                      src="IMAGES/city-images/holiday-inspiration/locations/pulgina.JPG"
                    ></img>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* <div 
            class="bg-displaced bg-image background-image-inholiday-inspiration-cols">

        <Container>


          <div className="background-image-in-cols-inspiration">

          
            <Row>



            <Col lg={6}>

<div className="padding-in-images">
    <img className="img-fluid image-in-inspirations-cols " src="IMAGES/city-images/holiday-inspiration/locations/japan.jpg">
    
    </img>
</div>


</Col>
           

              <Col lg={6}>
             
              <div className="class-around-text-inpiration-cols">
  <div className="heading-primary-in-wow-holiday-inspiration-inside-cols  text-box-inside-background-image-of-new-design-inspiration-inside-cols">
      <div style={{padding:'0px 15px'}}>
    <span className="heading-primary-main-inspiration-inside-cols border-line-inspiration-inside-cols-headings">
    SriLanka Wildlife, Culture & Beach

    </span>
    <span className="heading-primary-sub-inspiration-inside-cols">
    This Sri Lanka holiday with Experience Travel Group is a biggie on the bucket list.
     See the best of Sri Lanka from the jungle to train journeys and tea plantations paired
      with cultural heritage, wildlife and beautiful beaches. 
      

    </span></div>
  </div>
 
  </div>
  
  
              </Col>





              


              
            </Row>

            
            


            

           



          </div>
        </Container>
        </div> */}

        <br />
        <br />



<div className="borderline-bw-history-holiday-cards-inspiration" ></div>










        <Container>





          <Row>




            <Col lg={6} md={6} sm={6} xs={6} xs={12} className="firsst-history-card">

            <Link >
              <div class="wrapper-inspiratin-topCards culture-cards-holiday-inspiration">
                <div class="group">
                  <div class="gCon">
                    <div class="InspirationTop-group__card InspirationTop-group__card--1-of-8 topParent">
                      <div
                        class="InspirationTop-group__card-wrapper innerParent"
                        aria-hidden="true"
                      >
                        <div class="InspirationTop-group__card-text cText">
                          <p class="InspirationTop-group__card-pretitle pTitle">
                            Destinations
                          </p>
                          <h3 class="mainTitle">
                            History and culture holidays
                          </h3>
                          <p class="InspirationTop-group__card-cta postTitle">
                            Discover more
                          </p>
                        </div>
                        <div class="InspirationTop-group__card-image cImage">
                          <img
                            alt=""
                            src="IMAGES/city-images/holiday-inspiration/cards-location/culture/card11.jpg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </Col>










            <Col lg={6} md={6} sm={6} xs={6} xs={12}>




              <Row>










                <Col lg={6} md={6} sm={6} xs={6} xs={12}>


                <Link >
                  <div class="wrapper-inspiratin-topCards culture-cards-holiday-inspiration">
                    <div class="group">
                      <div class="gCon">
                        <div class="InspirationTop-group__card InspirationTop-group__card--1-of-8 topParent-smallcards">
                          <div
                            class="InspirationTop-group__card-wrapper innerParent"
                            aria-hidden="true"
                          >
                            <div class="InspirationTop-group__card-text cText">
                              <p class="InspirationTop-group__card-pretitle pTitle-smallcards">
                                Destinations
                              </p>
                              <h3 class="mainTitle-smallcards">
                                History and culture holidays
                              </h3>
                              <p class="InspirationTop-group__card-cta postTitle-smallcards">
                                Discover more
                              </p>
                            </div>
                            <div class="InspirationTop-group__card-image cImage">
                              <img
                                alt=""
                                src="IMAGES/city-images/holiday-inspiration/cards-location/culture/smallcards2.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </Col>







                <Col lg={6} md={6} sm={6} xs={6} xs={12}>
                <Link >
                  <div class="wrapper-inspiratin-topCards culture-cards-holiday-inspiration">
                    <div class="group">
                      <div class="gCon">
                        <div class="InspirationTop-group__card InspirationTop-group__card--1-of-8 topParent-smallcards">
                          <div
                            class="InspirationTop-group__card-wrapper innerParent"
                            aria-hidden="true"
                          >
                            <div class="InspirationTop-group__card-text cText">
                              <p class="InspirationTop-group__card-pretitle pTitle-smallcards">
                                Destinations
                              </p>
                              <h3 class="mainTitle-smallcards">
                                History and culture holidays
                              </h3>
                              <p class="InspirationTop-group__card-cta postTitle-smallcards">
                                Discover more
                              </p>
                            </div>
                            <div class="InspirationTop-group__card-image cImage">
                              <img
                                alt=""
                                src="IMAGES/city-images/holiday-inspiration/cards-location/culture/smallcards3.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </Col>
              </Row>

              <Row>
                <Col lg={6} md={6} sm={6} xs={6} xs={12}>
                  <Link>
                  <div class="wrapper-inspiratin-topCards culture-cards-holiday-inspiration">
                    <div class="group">
                      <div class="gCon">
                        <div class="InspirationTop-group__card InspirationTop-group__card--1-of-8 topParent-smallcards">
                          <div
                            class="InspirationTop-group__card-wrapper innerParent"
                            aria-hidden="true"
                          >
                            <div class="InspirationTop-group__card-text cText">
                              <p class="InspirationTop-group__card-pretitle pTitle-smallcards">
                                Destinations
                              </p>
                              <h3 class="mainTitle-smallcards">
                                History and culture holidays
                              </h3>
                              <p class="InspirationTop-group__card-cta postTitle-smallcards">
                                Discover more
                              </p>
                            </div>
                            <div class="InspirationTop-group__card-image cImage">
                              <img
                                alt=""
                                src="IMAGES/city-images/holiday-inspiration/cards-location/culture/smallcards4.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </Col>

                <Col lg={6} md={6} sm={6} xs={6} xs={12}>
                <Link>
                  <div class="wrapper-inspiratin-topCards culture-cards-holiday-inspiration">
                    <div class="group">
                      <div class="gCon">
                        <div class="InspirationTop-group__card InspirationTop-group__card--1-of-8 topParent-smallcards">
                          <div
                            class="InspirationTop-group__card-wrapper innerParent"
                            aria-hidden="true"
                          >
                            <div class="InspirationTop-group__card-text cText">
                              <p class="InspirationTop-group__card-pretitle pTitle-smallcards">
                                Destinations
                              </p>
                              <h3 class="mainTitle-smallcards">
                                History and culture holidays
                              </h3>
                              <p class="InspirationTop-group__card-cta postTitle-smallcards">
                                Discover more
                              </p>
                            </div>
                            <div class="InspirationTop-group__card-image cImage">
                              <img
                                alt=""
                                src="IMAGES/city-images/holiday-inspiration/cards-location/culture/smallcards5.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>




        </Container>


<Container>

<Holidayinspirationcard1/>

</Container>






      </div>
    );
  }
}
export default holidayinspiration;
