import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Figure,
  Col,
} from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../HolidayAirportTaxi/AirportTaxi.scss";

import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

class Airport_taxi41passengers extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      showHide47passengerscards: true,
      showHide47passengerscars1: false,
      showHide47passengerscars2: false,
      showHideAllTaxis: false,
      cars_4: [
        {
          name: "Audi",
          country: "Germany",
          currency: "Dirham",
          price: 128.67,
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/1-Honda.png",
          time: "2h 30min",
        },
        {
          name: "BMW",
          country: "Germany",
          currency: "Dirham",
          price: 158.67,
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/1-Honda.png",
          time: "2h 30min",
        },
        {
          name: "Chevrolet",
          country: "USA",
          currency: "Dirham",
          price: 228.67,
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/1-Honda.png",
          time: "2h 30min",
        },
        {
          name: "Citroen",
          country: "France",
          currency: "Dirham",
          price: 138.67,
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/1-Honda.png",
          time: "2h 30min",
        },
        {
          name: "Hyundai",
          country: "South Korea",
          currency: "Dirham",
          price: 432.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Mercedes-Benz",
          country: "Germany",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Renault",
          country: "France",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Seat",
          country: "Spain",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Dodge",
          country: "USA",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "BMW",
          country: "Germany",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Tesla",
          country: "USA",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Volkswagen",
          country: "Germany",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Hyundai",
          country: "South Korea",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",

        },
        {
          name: "Jaguar",
          country: "United Kingdom",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "GMC",
          country: "USA",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
        {
          name: "Bentley",
          country: "United Kingdom",
          currency: "Dirham",
          price: 128.67,
          img:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/Emirates_logo.svg/1200px-Emirates_logo.svg.png",
          time: "2h 30min",
        },
      ],

      cars_5: [
        {
          name: "Renault",
          country: "France",
          currency: "Dirham",
          price: "123.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/8-Mercedes-(E-CLASS).png",
          time: "2h 30min",
        },
        {
          name: "Seat",
          country: "Spain",
          currency: "Dirham",
          price: "321.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/7-Mercedes-S-CLASS.png",
          time: "2h 30min",
        },
        {
          name: "Dodge",
          country: "USA",
          currency: "Dirham",
          price: "231.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/5 Maserati Ghibli.png",
          time: "2h 30min",
        },
        {
          name: "BMW",
          country: "Germany",
          currency: "Dirham",
          price: "534.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/4-Jaguar-(XJ.png",
          time: "2h 30min",
        },
        {
          name: "Tesla",
          country: "USA",
          currency: "Dirham",
          price: "234.67$",
          img:
            "IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/2-Audi-(A8).png",
          time: "2h 30min",
        },
      ],
      numberOfitemsShown: 1,
    };
    this.hideComponent = this.hideComponent.bind(this);
    // this.showMore = this.showMore.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  // showMore = () => {
  //   if (this.state.numberOfitemsShown + 1 <= this.state.cars.length) {
  //     this.setState((state) => ({
  //       numberOfitemsShown: state.numberOfitemsShown + 1,
  //     }));
  //   } else {
  //     this.setState({ numberOfitemsShown: this.state.cars.length });
  //   }
  // };

  hideComponent(name) {
    switch (name) {
      case "showHide47passengers":
        this.setState({
          // showHide47passengers: !this.state.showHide47passengers,
          showHide47passengers: true,
          showHide47passengerscards: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHide47passengerscars1":
        this.setState({
          // showHide47passengerscars1: !this.state.showHide47passengerscars1,
          showHide47passengerscars1: true,
        showHide47passengerscars2: false,
          showHideAllTaxis: false,
        });
        break;

        case "showHide47passengerscars2":
          this.setState({
            // showHide47passengerscars2: !this.state.showHide47passengerscars2,
            showHide47passengerscars2: true,
            showHide47passengerscars1: false,
            showHideAllTaxis: false,
          });
          break;

      case "HideTaxiSectionCars":
        this.setState({
          showHide47passengerscars1: false,
          showHide47passengerscars2: false,
        });
        break;
    //   case "showHide47passengerscardsimg":
    //     this.setState({
    //       showHide47passengerscardsimg: !this.state.showHide47passengerscardsimg,
    //     });
      default:
      // null;
    }
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { showHide47passengerscards, showHide47passengerscars1, showHide47passengerscars2 } = this.state;
    return (
      <div>
        <div>
          <Container>
            <div>
              <div class="seo_link_content">
                {showHide47passengerscards && (
                  <Row>
                    <Col
                      lg={6}
                      xl={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="cols-section-image-thumb-section-airporttaxi"
                    >
                      <div className="cols-section-bg-color-section-airporttaxi">
                        <h3>Standard</h3>
                        <h5>Volkswagen Jetta or similar</h5>

                        <div>
                          <i
                            className="fa fa-user"
                            style={{ paddingRight: "10px" }}
                          ></i>
                          <span>1 passenger</span>
                        </div>

                        <div>
                          <i
                            className="fa fa-eye"
                            style={{ paddingRight: "10px" }}
                          ></i>
                          <span>dsfsdffsdfsdfdsfsdfsdf</span>
                        </div>

                        <div>
                          <i
                            className="fa fa-user"
                            style={{ paddingRight: "10px", color: "#f87370" }}
                          ></i>
                          <span style={{ color: "#f87370" }}>asasdd</span>
                        </div>

                        <div>
                          <i
                            className="fa fa-check"
                            style={{ paddingRight: "10px", color: "green" }}
                          ></i>
                          <span style={{ color: "green" }}>Meet & Greet</span>
                        </div>

                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              this.hideComponent("showHide47passengerscars1")
                            }
                            className={
                              "" +
                              (this.state.showHide47passengerscars1
                                ? "selected-button"
                                : "")
                            }
                          >
                            Search Taxis oh yeah
                          </Button>
                        </div>
                      </div>
                    </Col>

                    <Col
                      lg={6}
                      xl={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className="cols-section-image-thumb-section-airporttaxi"
                    >
                      <div className="cols-section-bg-color-section-airporttaxi">
                        <h3>Executive</h3>
                        <h5>Mercedes-Benz E-Class or similar</h5>

                        <div>
                          <i
                            className="fa fa-user"
                            style={{ paddingRight: "10px" }}
                          ></i>
                          <span>3 passengers</span>
                        </div>

                        <div>
                          <i
                            className="fa fa-eye"
                            style={{ paddingRight: "10px" }}
                          ></i>
                          <span>dsfsdffsdfsdfdsfsdfsdf</span>
                        </div>

                        <div>
                          <i
                            className="fa fa-user"
                            style={{ paddingRight: "10px", color: "#f87370" }}
                          ></i>
                          <span style={{ color: "#f87370" }}>asasdd</span>
                        </div>

                        <div>
                          <i
                            className="fa fa-check"
                            style={{ paddingRight: "10px", color: "green" }}
                          ></i>
                          <span style={{ color: "green" }}>Meet & Greet</span>
                        </div>

                        <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              this.hideComponent("showHide47passengerscars2")
                            }
                            className={
                              "" +
                              (this.state.showHide47passengerscars2
                                ? "selected-button"
                                : "")
                            }
                          >
                            Search Taxis oh yeah
                          </Button>
                        </div>
                      </div>
                    </Col>
                    
                  </Row>

                )}

              </div>
            </div>
          </Container>

          <center>
              <Button
                variant="outlined"
                style={{margin: '1rem 0'}}
                color="primary"
                onClick={() => this.hideComponent("HideTaxiSectionCars")}
                className={
                  "" + (this.state.HideTaxiSectionCars ? "selected-button" : "")
                }
              >
                Hide Taxis Sections
              </Button>
            </center>




            {showHide47passengerscars1 && (
                <div>
                  {this.state.cars_4.map((car4) => {
                    const { name, country, time, price, img } = car4;
                    return <div
                        style={{
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                        }}
                      >
                        <Container>
    
                          <div
                            id="hotellist_inner"
                            className="wider_image"
                            role="region"
                            aria-label="Search results"
                            data-et-view="  OQLOLOLOXaTFKGHCcZQHKKATXT:1  OQLOLOLOMSVSRQbVKKMadMUWe:1 OQLOLOLOdbLEZdfNaT:1 OQLOLOLOVCJVIQNcGZdMbHGQWKHC:2     OLGPNceIWDAOdbIVXVEHXT:4    OLGPNceIWDAOdbIVXVEHXT:6      OLGPNceIWDAOdbIVXVEHINET:1   OLGPNceIWDAOdbIVXVEHINET:4   OLGPNceIWDAOdbIVXVEHINET:5   OLGPNceIWDAOdbIVXVEHINET:8  "
                          >
                            <div
                              data-et-view=""
                              data-et-click="customGoal:OQLOLOLOdbLEZdfNaT:1 customGoal:OQLOLOLOdbLEZdfNaT:3 "
                              className="sr_item  sr_item_new sr_item_default sr_property_block sr_card_no_hover  sr_flex_layout        sr_item--highlighted   with_dates     "
                              data-hotelid="1097107"
                              data-class="5"
                              data-score="8.4"
                              data-highlightedhotel="1097107"
                            >
                              <div
                                className="sr_item_photo sr_card_photo_wrapper add-wow-red-tag"
                                id="hotel_1097107"
                              >
                                <div
                                  className="add-red-tag 
                                  add-red-tag--ribbon 
                                  all-inclusive-ribbon 
                                  js_icon_over_photo
                                  bfast-included-ribbon"
                                  data-et-view=" OLGHeTEFDZSJLZZCYWebFfKcVeRe:1 OLGHeTEFDZSJLZZCYWebFfKcVeRe:5 "
                                >
                                  <div className="add-red-tag__content">
                                    <span className="add-red-tag__amount add-red-tag__amount_all_inclusive add-red-tag__amount--small">
                                      Best Services
                                      <span className="add-red-tag__symbol"></span>
                                    </span>
                                  </div>
                                </div>
                                <svg
                                  className="wow-icon -iconset-loading"
                                  height="128"
                                  style={{ display: "none" }}
                                  width="128"
                                  viewBox="0 0 128 128"
                                  role="presentation"
                                  aria-hidden="true"
                                  focusable="false"
                                >
                                  <path
                                    d="m64 8a4.67 4.67 0 0 1 4.67 4.67v18.66a4.67 4.67 0 0 1 -4.67 4.67 4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67z"
                                    opacity=".75"
                                  ></path>
                                  <path
                                    d="m92 15.48a4.67 4.67 0 0 1 1.71 6.37l-9.34 16.15a4.67 4.67 0 0 1 -6.37 1.72 4.67 4.67 0 0 1 -1.71-6.37l9.33-16.17a4.67 4.67 0 0 1 6.38-1.7z"
                                    opacity=".2"
                                  ></path>
                                  <path
                                    d="m112.52 36a4.67 4.67 0 0 1 -1.71 6.37l-16.16 9.34a4.67 4.67 0 0 1 -6.37-1.71 4.67 4.67 0 0 1 1.72-6.37l16.17-9.33a4.67 4.67 0 0 1 6.35 1.7z"
                                    opacity=".25"
                                  ></path>
                                  <path
                                    d="m120 64a4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67 4.67 4.67 0 0 1 4.67-4.67h18.67a4.67 4.67 0 0 1 4.66 4.67z"
                                    opacity=".3"
                                  ></path>
                                  <path
                                    d="m112.52 92a4.67 4.67 0 0 1 -6.37 1.71l-16.15-9.34a4.67 4.67 0 0 1 -1.72-6.37 4.67 4.67 0 0 1 6.37-1.71l16.17 9.33a4.67 4.67 0 0 1 1.7 6.38z"
                                    opacity=".35"
                                  ></path>
                                  <path
                                    d="m92 112.52a4.67 4.67 0 0 1 -6.37-1.71l-9.34-16.16a4.67 4.67 0 0 1 1.71-6.37 4.67 4.67 0 0 1 6.37 1.72l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.35z"
                                    opacity=".4"
                                  ></path>
                                  <path
                                    d="m64 120a4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67 4.67 4.67 0 0 1 4.67 4.67v18.67a4.67 4.67 0 0 1 -4.67 4.66z"
                                    opacity=".45"
                                  ></path>
                                  <path
                                    d="m36 112.52a4.67 4.67 0 0 1 -1.71-6.37l9.34-16.15a4.67 4.67 0 0 1 6.37-1.72 4.67 4.67 0 0 1 1.71 6.37l-9.33 16.17a4.67 4.67 0 0 1 -6.38 1.7z"
                                    opacity=".5"
                                  ></path>
                                  <path
                                    d="m15.48 92a4.67 4.67 0 0 1 1.71-6.37l16.17-9.33a4.67 4.67 0 0 1 6.36 1.7 4.67 4.67 0 0 1 -1.72 6.37l-16.15 9.34a4.67 4.67 0 0 1 -6.37-1.71z"
                                    opacity=".55"
                                  ></path>
                                  <path
                                    d="m8 64a4.67 4.67 0 0 1 4.67-4.67h18.66a4.67 4.67 0 0 1 4.67 4.67 4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67z"
                                    opacity=".6"
                                  ></path>
                                  <path
                                    d="m15.48 36a4.67 4.67 0 0 1 6.37-1.71l16.15 9.34a4.67 4.67 0 0 1 1.72 6.37 4.67 4.67 0 0 1 -6.37 1.71l-16.17-9.34a4.67 4.67 0 0 1 -1.7-6.37z"
                                    opacity=".65"
                                  ></path>
                                  <path
                                    d="m36 15.48a4.67 4.67 0 0 1 6.37 1.71l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.36 4.67 4.67 0 0 1 -6.37-1.72l-9.34-16.15a4.67 4.67 0 0 1 1.71-6.37z"
                                    opacity=".7"
                                  ></path>
                                </svg>
                                <div className="wl-entry-container">
                                  <button
                                    type="button"
                                    data-hotel-id="1097107"
                                    data-text-create="Create a new wish list"
                                    data-text-header=""
                                    data-ga-label="small"
                                    data-placement="hp_tittle"
                                    data-wow-comparison-mode="true"
                                    data-position="bottom right"
                                    data-title="Save"
                                    className="js-wl-dropdown-handle lists-wow-dropdown-square-heart jq_tooltip entry-heart-svg-z-idx"
                                    aria-label="Add property to your lists "
                                    role="button"
                                    aria-live="assertive"
                                    data-loadingtext=""
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="wow-icon -iconset-loading wow-wl-entry-loader-svg"
                                      focusable="false"
                                      role="presentation"
                                      size="medium"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 128 128"
                                    >
                                      <path
                                        d="m64 8a4.67 4.67 0 0 1 4.67 4.67v18.66a4.67 4.67 0 0 1 -4.67 4.67 4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67z"
                                        opacity=".75"
                                      ></path>
                                      <path
                                        d="m92 15.48a4.67 4.67 0 0 1 1.71 6.37l-9.34 16.15a4.67 4.67 0 0 1 -6.37 1.72 4.67 4.67 0 0 1 -1.71-6.37l9.33-16.17a4.67 4.67 0 0 1 6.38-1.7z"
                                        opacity=".2"
                                      ></path>
                                      <path
                                        d="m112.52 36a4.67 4.67 0 0 1 -1.71 6.37l-16.16 9.34a4.67 4.67 0 0 1 -6.37-1.71 4.67 4.67 0 0 1 1.72-6.37l16.17-9.33a4.67 4.67 0 0 1 6.35 1.7z"
                                        opacity=".25"
                                      ></path>
                                      <path
                                        d="m120 64a4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67 4.67 4.67 0 0 1 4.67-4.67h18.67a4.67 4.67 0 0 1 4.66 4.67z"
                                        opacity=".3"
                                      ></path>
                                      <path
                                        d="m112.52 92a4.67 4.67 0 0 1 -6.37 1.71l-16.15-9.34a4.67 4.67 0 0 1 -1.72-6.37 4.67 4.67 0 0 1 6.37-1.71l16.17 9.33a4.67 4.67 0 0 1 1.7 6.38z"
                                        opacity=".35"
                                      ></path>
                                      <path
                                        d="m92 112.52a4.67 4.67 0 0 1 -6.37-1.71l-9.34-16.16a4.67 4.67 0 0 1 1.71-6.37 4.67 4.67 0 0 1 6.37 1.72l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.35z"
                                        opacity=".4"
                                      ></path>
                                      <path
                                        d="m64 120a4.67 4.67 0 0 1 -4.67-4.67v-18.66a4.67 4.67 0 0 1 4.67-4.67 4.67 4.67 0 0 1 4.67 4.67v18.67a4.67 4.67 0 0 1 -4.67 4.66z"
                                        opacity=".45"
                                      ></path>
                                      <path
                                        d="m36 112.52a4.67 4.67 0 0 1 -1.71-6.37l9.34-16.15a4.67 4.67 0 0 1 6.37-1.72 4.67 4.67 0 0 1 1.71 6.37l-9.33 16.17a4.67 4.67 0 0 1 -6.38 1.7z"
                                        opacity=".5"
                                      ></path>
                                      <path
                                        d="m15.48 92a4.67 4.67 0 0 1 1.71-6.37l16.17-9.33a4.67 4.67 0 0 1 6.36 1.7 4.67 4.67 0 0 1 -1.72 6.37l-16.15 9.34a4.67 4.67 0 0 1 -6.37-1.71z"
                                        opacity=".55"
                                      ></path>
                                      <path
                                        d="m8 64a4.67 4.67 0 0 1 4.67-4.67h18.66a4.67 4.67 0 0 1 4.67 4.67 4.67 4.67 0 0 1 -4.67 4.67h-18.66a4.67 4.67 0 0 1 -4.67-4.67z"
                                        opacity=".6"
                                      ></path>
                                      <path
                                        d="m15.48 36a4.67 4.67 0 0 1 6.37-1.71l16.15 9.34a4.67 4.67 0 0 1 1.72 6.37 4.67 4.67 0 0 1 -6.37 1.71l-16.17-9.34a4.67 4.67 0 0 1 -1.7-6.37z"
                                        opacity=".65"
                                      ></path>
                                      <path
                                        d="m36 15.48a4.67 4.67 0 0 1 6.37 1.71l9.33 16.17a4.67 4.67 0 0 1 -1.7 6.36 4.67 4.67 0 0 1 -6.37-1.72l-9.34-16.15a4.67 4.67 0 0 1 1.71-6.37z"
                                        opacity=".7"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                                <a
                                  className=" sr_item_photo_link sr_hotel_preview_track  "
                                  rel="noopener"
                                  tabindex="-1"
                                  focusable="false"
                                  aria-hidden="true"
                                >
                                  {/* <img
                                    className="hotel_image"
                                    src="https://cf.bstatic.com/xdata/images/hotel/square200/183067061.webp?k=cb84b55cc2ff46207844efe69bddbfb9c8663c3937756b67a6ba216f006e044f&amp;o="
                                    data-highres="https://cf.bstatic.com/xdata/images/hotel/square600/183067061.webp?k=cb84b55cc2ff46207844efe69bddbfb9c8663c3937756b67a6ba216f006e044f&amp;o="
                                    alt="Faisalabad Serena Hotel"
                                    data-google-track="Click/sr_item_main_photo/0"
                                    width="200"
                                    height="200"
                                  /> */}
                                  <img src={img} style={{ width: "100%" }} />
                                  <span className="invisible_spoken">
                                    Opens in new window
                                  </span>
                                </a>
                              </div>
                              <div className="sr_item_content sr_item_content_slider_wrapper ">
                                <div className="sr_property_block_main_row">
                                  <div className="sr_item_main_block">
                                    <div className="wow-hotel__title-wrap">
                                      <h3
                                        className="wow-hotel__title  "
                                      >
                                        <a
                                          className="hotel_name_link url"
                                          rel="noopener"
                                        >
                                          <span
                                            className="wow-hotel__name"
                                            data-et-click="    customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1    "
                                          >
                                            {name}
                                            {/* Faisalabad Serena Hotel */}
                                          </span>
                                          <span className="invisible_spoken">
                                            Opens in new window
                                          </span>
                                        </a>
                                      </h3>
                                      <span className="wow-hotel__title-badges">
                                        <i
                                          className="wow-icon-wrapper wow-icon-stars star_track"
                                          title="5-star hotel"
                                        >
                                          <svg
                                            aria-hidden="true"
                                            className="wow-icon -sprite-ratings_stars_5"
                                            focusable="false"
                                            height="10"
                                            width="54"
                                            viewBox="0 0 65 12"
                                            role="presentation"
                                          >
                                            <path
                                              d="m13 4.6c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.1-.5-.1-.5 0l-1.5 4-4.6.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3h.3l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.4 3.5-2.5c.1 0 .1-.1.1-.2zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5zm13 0c0-.1-.1-.2-.2-.2l-4.5-.3-1.5-4c-.1-.2-.4-.2-.5 0l-1.5 4-4.5.3c-.1 0-.2.1-.2.2s0 .2.1.3l3.5 2.5-1.2 4.3c0 .1 0 .2.1.3s.2.1.3 0l3.7-2.6 3.7 2.6h.3c.1-.1.1-.2.1-.3l-1.2-4.3 3.5-2.5z"
                                              fill="#feba02"
                                            ></path>
                                          </svg>
                                        </i>
                                        <span data-et-view="TPOaXGZCHQGPGJIMADXRT:1"></span>
                                        <svg
                                          aria-hidden="true"
                                          className="wow-icon -iconset-thumbs_up_square 
                                          pp-icon-valign--tbottom 
                                          js-preferred-property-copy-cached"
                                          data-bui-component="Tooltip"
                                          data-tooltip-follow="true"
                                          data-tooltip-position="bottom"
                                          data-tooltip-text="This is a Preferred Partner property. It's committed to giving guests positive experiences with its excellent service and great value. This property might pay Booking.com a little more to be in this Program."
                                          fill="#FEBB02"
                                          height="20"
                                          rel="300"
                                          width="20"
                                          viewBox="0 0 128 128"
                                          role="presentation"
                                          focusable="false"
                                        >
                                          <path d="M112 8H16a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h96a8 8 0 0 0 8-8V16a8 8 0 0 0-8-8zM48 96H24V58h24zm56-25a8.7 8.7 0 0 1-2 6 8.9 8.9 0 0 1 1 4 6.9 6.9 0 0 1-5 7c-.5 4-4.8 8-9 8H56V58l10.3-23.3a5.4 5.4 0 0 1 10.1 2.7 10.3 10.3 0 0 1-.6 2.7L72 52h23c4.5 0 9 3.5 9 8a9.2 9.2 0 0 1-2 5.3 7.5 7.5 0 0 1 2 5.7z"></path>
                                        </svg>
                                      </span>
                                    </div>
                                    <div className="sr_card_address_line">
                                      <a
                                        className="bui-link"
                                        data-google-track="Click/Action: sr_map_link_used"
                                        data-coords="73.083575963974,31.4271238764644"
                                        target="_blank"
                                        data-map-caption=""
                                        rel="noopener"
                                        data-et-click="customGoal:YdXfdKNKNKHUScFAYTZTEeNGTODXKe:3"
                                      >
                                        {/* Faisalabad */}
                                        {country}
                                        {/* <span className="sr_card_address_line__item">
                                          <span className="sr_card_address_line__dot-separator"></span>
                                          Show on map
                                        </span> */}
                                      </a>
                                      <span className="sr_card_address_line__dot-separator"></span>
                                    </div>
                                  </div>
                                  <div className="sr_item_review_block">
                                    <div className="reviewFloater reviewFloaterBadge__container">
                                      <div
                                        className="wow-review-score"
                                      >
                                        <a
                                          className="wow-review-score__link"
                                        >
                                          <div className="bui-review-score c-score bui-review-score--end">
                                            {" "}
                                            <div
                                              className="bui-review-score__badge"
                                              aria-label="Scored 8.4 "
                                            >
                                              {" "}
                                              8.4{" "}
                                            </div>{" "}
                                            <div className="bui-review-score__content">
                                              {" "}
                                              <div className="bui-review-score__title">
                                                {" "}
                                                Very Good{" "}
                                              </div>{" "}
                                              <div className="bui-review-score__text">
                                                {" "}
                                                157 reviews{" "}
                                              </div>{" "}
                                            </div>{" "}
                                          </div>
                                          <span className="invisible_spoken">
                                            Opens in new window
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="wow-badges__row">
                                  <div className="wow-property-highlights">
                                    <span data-et-view="OLWQREDRETJUTGCdNJBcSTKe:1 OLWQREDRETJUTGCdNJBcSTKe:2"></span>
                                    <div className="bui-spacer--small wow-badge-block">
                                      <svg
                                        className="wow-icon -streamline-hospital_shield"
                                        fill="#333333"
                                        height="16"
                                        width="16"
                                        viewBox="0 0 24 24"
                                        role="presentation"
                                        aria-hidden="true"
                                        focusable="false"
                                      >
                                        <path d="M18 9a1.5 1.5 0 0 0-1.5-1.5h-2.25l.75.75V6a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 9 6v2.25l.75-.75H7.5A1.5 1.5 0 0 0 6 9v3a1.5 1.5 0 0 0 1.5 1.5h2.25L9 12.75V15a1.5 1.5 0 0 0 1.5 1.5h3A1.5 1.5 0 0 0 15 15v-2.25l-.75.75h2.25A1.5 1.5 0 0 0 18 12V9zm-1.5 0v3h-2.25a.75.75 0 0 0-.75.75V15h-3v-2.25a.75.75 0 0 0-.75-.75H7.5V9h2.25a.75.75 0 0 0 .75-.75V6h3v2.25c0 .414.336.75.75.75h2.25zm-15-5.077v7.614c0 5.237 3.225 9.933 8.113 11.813l1.041.4a3.75 3.75 0 0 0 2.692 0l1.041-.4A12.657 12.657 0 0 0 22.5 11.537V3.923a2.234 2.234 0 0 0-1.306-2.044A22.463 22.463 0 0 0 11.993 0C8.846-.03 5.715.61 2.818 1.874A2.24 2.24 0 0 0 1.5 3.928zM3 3.918a.736.736 0 0 1 .43-.675A20.937 20.937 0 0 1 11.993 1.5a20.969 20.969 0 0 1 8.589 1.748c.252.116.42.38.418.67v7.619c0 4.616-2.843 8.756-7.151 10.413l-1.041.4c-.52.2-1.096.2-1.616 0l-1.041-.4A11.157 11.157 0 0 1 3 11.537V3.923z"></path>
                                      </svg>
                                      <span>Extra health &amp; safety measures taken</span>
                                    </div>
                                  </div>
                                  <div className="genius-benefits-badges"></div>
                                </div>
                                <div className="sr_rooms_table_block clearfix sr_card_rooms_container">
                                  <div className="room_details ">
                                    <div
                                      className="featuredRooms sr_room_table sr_room_table_flex sr_rt_wider_urgency_msg sr_card_room_info__container  nohead sr_rms_tbl_bdr "
                                      cellspacing="0"
                                      role="presentation"
                                    >
                                      <div>
                                        <div
                                          className="roomrow roomrow_flex entire_row_clickable"
                                          data-link="/hotel/pk/faisalabad-serena.en-us.html?aid=808942&amp;label=europeanbd-hotelspage-sb-click-topbox&amp;sid=cc98ca06f46cb4162221e8cfc1ff0d7b&amp;all_sr_blocks=109710712_274304051_0_1_0&amp;checkin=2020-08-31&amp;checkout=2020-09-01&amp;dest_id=-2759985&amp;dest_type=city&amp;group_adults=2&amp;group_children=0&amp;hapos=1&amp;highlighted_blocks=109710712_274304051_0_1_0&amp;hpos=1&amp;no_rooms=1&amp;sr_order=popularity&amp;sr_pri_blocks=109710712_274304051_0_1_0__9000&amp;srepoch=1598857501&amp;srpvid=580431ceafba01dc&amp;ucfs=1&amp;from=searchresults;show_room=109710712#RD109710712"
                                          data-target="_blank"
                                          tabindex="0"
                                          data-et-click="   customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1  "
                                        >
                                          <div
                                            className="roomName roomName_flex --wider unit-info"
                                          >
                                            <div className="roomNameInner">
                                              <div className="room_link">
                                                <span
                                                  style={{ color: "#000" }}
                                                  role="link"
                                                  tabindex="0"
                                                >
                                                  <strong>
                                                    Passengers
                                                  </strong>
                                                  &nbsp;–&nbsp;
                                                  <div
                                                    className="c-occupancy-icons "
                                                    style={{
                                                      fontSize: "10px",
                                                      color: "#000000",
                                                    }}
                                                  >
                                                    <span
                                                      className="c-occupancy-icons__adults "
                                                      aria-hidden="true"
                                                    >
                                                      <i className="fas fa-user"></i>
                                                      <i className="fas fa-user"></i>
                                                    </span>
                                                    <span className="bui-u-wow-only">
                                                      ...
                                                    </span>
                                                  </div>
                                                </span>
                                              </div>
                                              <span className="room_info">
                                                <div data-et-view="NAFLeOeJAETbUBPTVdCTEPMWQIXe:1"></div>
                                                Private room •
                                                <div className="c-beds-configuration c-beds-configuration--inline">
                                                  1 full bed
                                                </div>
                                              </span>
                                              <div className="sr_card_room_policies__container">
                                              <sup className="sr_room_reinforcement-risk-text">
                                                Risk Free: You can cancel later, so lock in 
                                                this great price today!
                                                </sup>
                                              </div>
                                              <div className="js_sr_persuation_msg"></div>
                                            </div>
                                          </div>
                                          <div
                                            className="roomPrice roomPrice_flex  sr_discount "
                                            colspan="2"
                                          >
                                            <div className="prco-wrapper bui-price-display prco-wow-default-assembly-wrapper prc-d-wow-wrapper">
                                              <div className="prco-ltr-right-align-helper">
                                                <div className="bui-price-display__label prco-inline-block-maker-helper">
                                                  1 night, 2 adults
                                                </div>
                                              </div>
                                              <div className="prco-ltr-right-align-helper">
                                                <div className="prco-inline-block-maker-helper">
                                                  <div
                                                    className="bui-price-display__value prco-text-nowrap-helper prco-inline-block-maker-helper"
                                                    aria-hidden="true"
                                                    data-et-mouseenter="customGoal:AdeKbCcBUfQUaSHbZFVXOJUNQKFcFXZYCaJFSSZRe:2"
                                                  >
                                                    PKR&nbsp;{price}
                                                  </div>
                                                  <span className="bui-u-wow-only">
                                                    Price PKR&nbsp;{price}
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="prco-ltr-right-align-helper">
                                                <div
                                                  className="prd-taxes-and-fees-under-price prco-inline-block-maker-helper blockuid-"
                                                  data-excl-charges-raw="2397.60106974966"
                                                  data-cur-stage="2"
                                                >
                                                  {" "}
                                                  +PKR&nbsp;2,398 taxes and charges{" "}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              data-component="earn-credits"
                                              className="c-earn-credits "
                                              data-earn-credits-raw=""
                                              data-prevent-propagation=""
                                            ></div>
                                            <div>
                                              <div>
                                                <div colspan="4" className="roomFooter">
                                                  <div
                                                    className="wow-cta-button-row"
                                                    data-et-click="    customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1   "
                                                  >
                                                    <a
                                                      className="txp-cta bui-button bui-button--primary sr_cta_button"
                                                      data-et-click="customGoal:OQLOLOLOMSVSRQbVKKMadMUWe:1 customGoal:OQLOLOLOMSVSRQbVKKMadMUWe:3"
                                                      data-click-store-id="wow-compset-1097107"
                                                    >
                                                      <span className="bui-button__text"
                                                      >
                                                      Book Holiday
                                                      </span>
    
                                                      <div className="txp-arr bui-button__icon">
                                                        <svg
                                                          className="wow-icon -streamline-arrow_nav_right"
                                                          height="16"
                                                          width="16"
                                                          viewBox="0 0 24 24"
                                                          role="presentation"
                                                          aria-hidden="true"
                                                          focusable="false"
                                                        >
                                                          <path d="M9.45 6c.2 0 .39.078.53.22l5 5c.208.206.323.487.32.78a1.1 1.1 0 0 1-.32.78l-5 5a.75.75 0 0 1-1.06 0 .74.74 0 0 1 0-1.06L13.64 12 8.92 7.28a.74.74 0 0 1 0-1.06.73.73 0 0 1 .53-.22zm4.47 5.72zm0 .57z"></path>
                                                        </svg>
                                                      </div>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Container>
                      </div>
                  })}
                </div>
            )}











{showHide47passengerscars2 && (
                 <Container>
                 <Row>
                   {this.state.cars_5.map((car5) => {
                     const { name, country, time, price, img } = car5;
                     return (
                       <Col xl={6} lg={6} md={6}>
                         <div className="cars-section-passenger13-taxi-practice">
                           <div class="et_pb_column et_pb_column_1_3 et_pb_column_12  et_pb_css_mix_blend_mode_passthrough">
                             <div class="et_pb_module et_pb_blurb et_pb_blurb_8  et_pb_text_align_center  et_pb_blurb_position_top et_pb_bg_layout_light">
                               <div class="et_pb_blurb_content">
                                 <div class="et_pb_main_blurb_image">
                                   <span class="et_pb_image_wrap">
                                     <img src={img}></img>
                                   </span>
                                 </div>
                                 <div class="et_pb_blurb_container">
                                   <h4 class="et_pb_module_header">
                                     <span>{name}</span>
                                   </h4>
                                   <div class="et_pb_blurb_description">
                                     <p>{price}</p>
                                   </div>
                                 </div>
                               </div>
                             </div>{" "}
                             <div class="et_pb_module et_pb_toggle et_pb_toggle_0 et_pb_toggle_item  et_pb_text_align_left et_pb_toggle_close">
                               {/* <h5 class="et_pb_toggle_title">Details</h5> */}
                               <div class="et_pb_toggle_content clearfix">
                                 <Collapse
                                   bordered={false}
                                   defaultActiveKey={["1"]}
                                   expandIcon={({ isActive }) => (
                                     <CaretRightOutlined
                                       rotate={isActive ? 90 : 0}
                                     />
                                   )}
                                   className="site-collapse-custom-collapse"
                                 >
                                   <Panel
                                     header="See Details"
                                     key="0"
                                     className="site-collapse-custom-panel"
                                   >
                                     {/* <p>{text}</p> */}
                                     <div>
                                       <ul>
                                         <li>Vivamus suscipit tortor</li>
                                         <li>Sed porttitor lectus nibh.</li>
                                         <li>Curabitur arcu erat</li>
                                         <li>Curabitur aliquet quam id dui</li>
                                         <li>
                                           Vestibulum ante ipsum primis in faucibus
                                         </li>
                                       </ul>
                                     </div>
                                   </Panel>
                                 </Collapse>
                               </div>
                             </div>
                           </div>{" "}
                         </div>
                       </Col>
                     );
                   })}
                 </Row>
               </Container>
            )}

            
        </div>
        </div>
    );
  }
}
export default Airport_taxi41passengers;
