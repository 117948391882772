import React, { Component, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton,
} from "react-twitter-embed";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory,
} from "react-router-dom";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

class Newscard2 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString() };
  }

  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  SpacePortae = () => {
    document.getElementById("emirates").style.display = "none";
    document.getElementById("tripadvisor").style.display = "none";
    document.getElementById("Travellingman").style.display = "none";
    document.getElementById("smartdubai").style.display = "none";
    document.getElementById("dubaipolice").style.display = "none";
    document.getElementById("natgeotravel").style.display = "none";
    document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "block";
    document.getElementById("europespaceagency").style.display = "none";

    document.getElementById("mySidenav-newsroom").style.width = "0px";
  };

  openNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "300px";
    document.getElementById("close-button-newsroom").style.display = "";
  };

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        


        

<Container>
        <div class="td-post-header-holder-card2 td-image-gradient-newscard2">
        <div class="td-post-featured-image-newscard2">
           <img width="1068" height="580" 
            class="entry-thumb td-animation-stack-type0-2-newscard2"
            src="IMAGES/city-images/newsroom/card2-header2.jpg"/>

        </div>
            <header class="td-post-title-newscard2">
            <ul class="td-category-newscard2">
                    <li class="entry-category">
                        <a 
                        target="blank"
                        href="https://www.forbes.com/sites/ceciliarodriguez/2020/05/26/europe-travel-safest-beaches-post-lockdown/#7566a2e023d6">
                        EUROPE
                        </a>
                        </li>
                </ul>
                             
        <h1 class="entry-title-newscard2">CORONAVIRUS - SAFEST BEACHES IN EUROPE</h1>

                

                <div class="td-module-meta-info-newscard2">
                 <span class="td-post-date-newscard2">
                     <time class="entry-date-newscard2 updated td-module-date-newscard2"
                      datetime="2018-11-07T10:59:17+00:00">December 7, 2019</time>
                </span>      

                <div class="td-post-views-newscard2">
                    <i className="fa fa-eye view-icon-views-newscard2"></i>
                    <span class="td-nr-views-10968">12736</span>
                </div>                    
                              
                     </div>

            </header>
        </div>
 
</Container>




<Container>


<div class="u-center-text u-margin-bottom-second-heading-wow-newscard2">
        <div  className="heading-secondary-section-wow-newscard2 ">
        Coronavirus: Safest Beaches in Europe for a Summer Holiday in 2020
        </div>
        </div>


<div className="text-section-corona-safest-beaches-newscard2">

  <p>You have been staying at home for months and what you definitely need is space, fresh air, sun but also 
  and above all a <span style={{color:'#000000', fontWeight:'bold'}}>safe place</span>  for you and  your family.</p>


  <p>The <span style={{color:'#000000', fontWeight:'bold'}}> safest beaches in Europe </span> (Covid-19) were
   selected on the basis of various criteria such as a<span style={{color:'#000000', fontWeight:'bold'}}> low number
   of people infected by the coronavirus </span>  (from 10 to 600 times fewer cases of
    Covid-19), the size of the beaches and the number of  square metres available for each person on the beach. </p>


  <p>These destinations have also been selected on the basis of the large offer of 
    <span style={{color:'#000000', fontWeight:'bold'}}> private villas, tourist 
    apartments, small or medium-sized hotels</span>  with specific health charter and commitments to travellers, such 
    as cleaning and room disinfection service after each stay, respect for social distancing.

 
<br/>
These destinations are also located near hospitals that have never been overcrowded during the crisis with an 
average of hospital beds per inhabitant above the European average.</p>

</div>


</Container>



<div className="gradient-back-travel-newscard2">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-newscard2-grid-text">
                  <div className="travel-newscard2-page-araea-sub-headings110">
                  Corona: Safest Beaches
                  </div>

                  <div className="travel-newscard2-page-page-text">
                  From Greek beaches to beaches in Portugal, or even Lithuania and Poland, discover the longest
                   and safest beaches for a summer holiday in Europe. These beaches, some of which are over 100km
                    long, are perfect for safe holidays: even at the peak of the season, keeping your distance 
                  will not be a problem.

 
<br/>
Book from our range of small hotels, tourist apartments or private villas with swimming pools 
(choose the "free cancellation" option) as well as your flights and enjoy your holiday on the safest beaches in Europe.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/newsroom/additional-cards/corona-safest-beaches.jpg"></img>
              </Col>
            </Row>
          </Container>
        </div>



<Container>
<div class="description_of_card_of_newsroom-card2">
  <div class="description_of_card_of_newsroom_area-card2">
    {/* <p class="blogs-description-area-of-main--text"><span style={{color:'#f87370'}}> Dubai</span> is a city that
     boasts many world records. 
    From the highest building in the world to a seven-star hotel. But here are some Dubai fun facts that 
    you may not know… spending on your dresses, shoes,
       bags, makeup, get-over, and many more but Wow!</p> */}

  <h2 class="blogs-description-area-of-main-headings-card2">
  1. Preveza(Greece)
  </h2>
  <p class="blogs-description-area-of-main--text-card2">
  That settles it! Greece will be your next destination this summer. It is one of the European
   countries least affected by  Coronavirus (up to 50 times less affected than any other European country).
    Greece has already taken many safety and health measures to ensure travellers a safe holiday. For example,
     air conditioner filters are changed after each stay and a distance of 4 metres is compulsory between
   each beach umbrella. If you are looking for THE safest destination in Europe, book your holiday in Preveza.
   </p>
   <p class="blogs-description-area-of-main--text-card2">
   Preveza is full of charm, preserved from big resorts. Tourist accommodation is on a human scale (villas, 
   apartments, family hotels). Even in high season and even if all the accommodation was 100% full, you would
    still have tens of square metres just for you on one of the beautiful Preveza beaches.
   </p>

   
<div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/perveze.jpg" style={{width: '100%'}}/>

  </div>






   
   <h2 class="blogs-description-area-of-main-headings-card2">2. Comporta Alentejo - (Portugal)</h2>
   <p class="blogs-description-area-of-main--text-card2">
   Sometimes one can stay for hours on this beach and not come across anyone. Until recently, Madonna used to ride
    her horse on this beach on Sunday mornings and enjoy the tranquility of this 20km long beach (12 miles).
     She should not have left Comporta because the Alentejo is one of the regions least affected by Coronavirus
      in Europe (up to 600 times less cases of Coronavirus in the Alentejo than in any other region of Europe).
      </p>


      <p class="blogs-description-area-of-main--text-card2">
      Portugal has hundreds of kilometres of magnificent beaches along its coastline, perfect for a family
       holiday. Comporta beach is just a few kilometres from Lisbon, one of the most beautiful capitals
        in Europe.
      </p>

      <div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/img2.jpg" style={{width: '100%'}}/>

  </div>


      
  <h2 class="blogs-description-area-of-main-headings-card2">3. Hel Peninsula (Poland)</h2>
  <p class="blogs-description-area-of-main--text-card2">
  Poland is one of the European countries least affected by Coronavirus, along with Portugal and Greece. 
  There are up to 20 times less cases there than in the most affected countries of Western Europe. Here 
  hospitals have never been overloaded and Poland has more hospital beds per resident than many European 
  countries as the Netherlands or Switzerland.
     </p>

     <div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/img3.jpg" style={{width: '100%'}}/>

  </div>




     
     <h2 class="blogs-description-area-of-main-headings-card2">4. Porto Santo Beach (Porto Santo)</h2>
      
      <p class="blogs-description-area-of-main--text-card2">
      Portugal is considered to be one of the safest destinations this summer: some regions as The Azores,
       The Alentejo and The Algarve are relatively spared compared to the rest of Europe. Madeira is even 
       the least affected region of Portugal.
         </p>


         <p class="blogs-description-area-of-main--text-card2">
         How to get there: Book your flights to Funchal (Madeira) as well as your accommodation,
          tours and activities such as a "From Funchal: Porto Santo 1-Day Cruise and Tour". You can
           reach the island by ferry (1 hour crossing from Funchal). There is also an international
            airport in Porto Santo with direct flights.
         </p>
       
         <div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/img4.jpg" style={{width: '100%'}}/>

  </div>




  <h2 class="blogs-description-area-of-main-headings-card2">5. Halikounas beach(Greece)</h2>
      
      <p class="blogs-description-area-of-main--text-card2">
      Corfu is one of the greenest Greek islands, with exceptional flora and fauna. Do you know 
      that Greece has been up to 50 times less affected by Coronavirus than any other destination
       in Europe?
         </p>

         <p class="blogs-description-area-of-main--text-card2">
         Greece is often mentioned as one of the safest destinations in Europe for Coronavirus. Some
          specific measures have been taken such as social distancing on beaches, room disinfection, 
          air conditioner replacement filters in rooms after each stay, temperature measurement on 
          arrival at the airport, availability of masks and numerous hospital beds (Greece has more hospital
           rooms per capita than Ireland or the United Kingdom).
         </p>
       
         <div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/img5.jpg" style={{width: '100%'}}/>

  </div>



<h2 class="blogs-description-area-of-main-headings-card2">6. Ghajn Tuffieha Beach (Riviera bay beach - Malta)</h2>
      
      <p class="blogs-description-area-of-main--text-card2">
      There have been up to 55 times fewer cases of Coronavirus in Malta than in the most affected European 
      countries. Did you know that Malta has more hospital beds per resident than Finland, Ireland or the
       United Kingdom? As the epidemic has been very limited in Malta, hospitals have never been overcrowded
        and will be able to accommodate travellers if necessary. 
      </p>

      <p class="blogs-description-area-of-main--text-card2">
      Knowing this what about diving into the turquoise water of the Mediterranean and discovering the 
      treasures and riches of one of the most beautiful destinations in Europe? Lay your beach towel on 
      Riviera bay beach or on one of the many other beaches in Malta. If you really want to distance 
      yourself from the crowd this summer you can also book your stay in Gozo: the island is smaller 
      than the main island and ideal for nature lovers,  hikers or scuba divers.
      </p>

      <div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/img6.jpg" style={{width: '100%'}}/>

  </div>






    
  <h2 class="blogs-description-area-of-main-headings-card2">7. Meia Praia (Lagos - Algarve - Portugal)</h2>
      
      <p class="blogs-description-area-of-main--text-card2">
      The Algarve is one of the regions least affected by  Coronavirus in Europe and Lagos is one of
       the least affected town in the Algarve. With up to 40 times fewer cases than in the most affected
        European regions, the Algarve is a favourite destination for travellers looking for safe destinations
         this summer.
      </p>

      <p class="blogs-description-area-of-main--text-card2">
      Lagos has a very nice historic centre with traditional shops, trendy bars and cafes, a fruit and 
      fish market and some magnificient beaches. The largest beach in Lagos is "Meia Praia". Although "Meia" 
      means "Medium" in Portuguese, "Meia Praia" is the largest beach in Lagos.
      </p>

      <p class="blogs-description-area-of-main--text-card2">
      Reopening to travellers: Portugal is ready and the full tourist season will
       start on July 1st (no quarantine requested).
      </p>

      <div class="wow-news-card1-work-page-top-image-card2">
  <img src="IMAGES/city-images/newsroom/additional-cards/news_card2/img7.jpg" style={{width: '100%'}}/>

  </div>
        
   
        </div>
        </div>





        </Container>









        













































      </div>
    );
  }
}
export default Newscard2;
