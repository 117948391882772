import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";
import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  // Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  Figure,
  View,
  Dropdown
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  browserhistory
} from "react-router-dom";
import { Table, Tag, Space } from 'antd';

const columns = [
  {
    title: 'Rating',
    dataIndex: 'rating',
    key: 'rating',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Review',
    dataIndex: 'review',
    key: 'review',
  },
  // {
  //   title: 'Address',
  //   dataIndex: 'address',
  //   key: 'address',
  // },
  // {
  //   title: 'Tags',
  //   key: 'tags',
  //   dataIndex: 'tags',
  //   render: tags => (
  //     <>
  //       {tags.map(tag => {
  //         let color = tag.length > 5 ? 'geekblue' : 'green';
  //         if (tag === 'loser') {
  //           color = 'volcano';
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </>
  //   ),
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (text, record) => (
  //     <Space size="middle">
  //       <a>Invite {record.name}</a>
  //       <a>Delete</a>
  //     </Space>
  //   ),
  // },
];


const data = [
  {
    key: '1',
    rating: 8.3,
    review: 'Overall value for money of your vehicle hire',
  },
  {
    key: '2',
    rating: 8.4,
    review: 'Helpfulness of the counter staff',
  },
  {
    key: '3',
    rating: 8.5,
    review: 'Time it took to collect your vehicle',
  },
  {
    key: '4',
    rating: 8.7,
    review: 'Time it took to drop off your vehicle',
  },
  {
    key: '5',
    rating: 9.5,
    review: 'Cleanliness of the vehicle',
  },
  {
    key: '6',
    rating: 8.0,
    review: 'Overall condition of the vehicle',
  },
  {
    key: '7',
    rating: 9.0,
    review: 'Ease of locating the Wiber rental desk or representative',
  },
];

class CustomerReviews_RentalCars extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false,
      size: 'default',
      cars_5: true,
      cars_4: [
        {
          name: "Audi",
          country: "UAE, Dubai",
          currency: "US$",
          price: 128.67,
          price_1: 0,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/2-WOW-Family/4-GMC.png",
          time: "2h 30min",
        },
      ],
    };
  }


  onChange = e => {
    console.log('size checked', e.target.value);
    this.setState({
      size: e.target.value,
    });
  };



  ShowMoreReviews = () => {
    document.getElementById("reviews-3").style.display = 'block';
    document.getElementById("reviews-4").style.display = "block";
    document.getElementById("reviews-5").style.display = "block";
    document.getElementById("reviews-6").style.display = "block";
    document.getElementById("ShowMoreReviews-Button").style.display = "none";
    document.getElementById("ShowLessReviews-Button").style.display = "block";

};

  ShowLessReviews = () => {
    document.getElementById("reviews-3").style.display = 'none';
    document.getElementById("reviews-4").style.display = "none";
    document.getElementById("reviews-5").style.display = "none";
    document.getElementById("reviews-6").style.display = "none";
    document.getElementById("ShowMoreReviews-Button").style.display = "block";
    document.getElementById("ShowLessReviews-Button").style.display = "none";
}



  hideComponent(name) {
    switch (name) {
      case "HideCarsSectionFilterPage":
        this.setState({
          cars_5: !this.state.cars_5,
        });
        break;
      //   case "showHide47passengerscardsimg":
      //     this.setState({
      //       showHide47passengerscardsimg: !this.state.showHide47passengerscardsimg,
      //     });
      default:
      // null;
    }
}
 
  
  render() {
    const scrollTotours = () => window.scrollTo({ top: 2000, behavior: "smooth"});
    const {cars_5} = this.state;
    return (
      <div>





<div style={{ padding: "0rem 0" }}>
                      <Row>
                        {cars_5 && (
                          <Container>
                            {this.state.cars_4.map((car4) => {
                              const {
                                name,
                                country,
                                time,
                                price,
                                img,
                                price_1,
                                currency,
                              } = car4;
                              return (
                                <div
                                  style={{
                                    borderBottom: "1px solid black",
                                  }}
                                >
                                  <Row>
                                    <Col xl={4} lg={4}>
                                      <div>
                                        <Figure>
                                          <Figure.Caption
                                            style={{ textAlign: "center" }}
                                          >
                                            {name}
                                          </Figure.Caption>
                                          <Figure.Image
                                            width={171}
                                            height={180}
                                            alt="171x180"
                                            src={img}
                                          />
                                          <Figure.Caption
                                            style={{ textAlign: "center" }}
                                          >
                                            {country}
                                          </Figure.Caption>
                                        </Figure>
                                      </div>
                                    </Col>

                                    <Col xl={8} lg={8}>
                                      <div className="cars-data-section-RentalCarsBookingDetailsForm-mainClass">
                                        <div className="cars-data-section-RentalCarsBookingDetailsForm">
                                          <div className="cars-data-section-textArea-RentalCarsBookingDetailsForm">
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              {" "}
                                              <i className="fa fa-eye"></i>{" "}
                                              <h6>Seats</h6>{" "}
                                            </p>
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              <i className="fa fa-car"></i>{" "}
                                              <h6>Doors</h6>
                                            </p>
                                          </div>
                                          <div className="cars-data-section-textArea-RentalCarsBookingDetailsForm">
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              {" "}
                                              <i className="fa fa-eye"></i>{" "}
                                              <h6>Air Conditioning</h6>
                                            </p>
                                            <p className="cars-data-section-iconHeadingsSection-RentalCarsBookingDetailsForm">
                                              {" "}
                                              <i className="fa fa-eye"></i>{" "}
                                              <h6>Gearbox</h6>{" "}
                                            </p>
                                          </div>

                                          <div className="cars-data-section-PriceArea-RentalCarsBookingDetailsForm">
                                            <h3 style={{ textAlign: "right" }}>
                                              {price} {currency}
                                            </h3>
                                            <div
                                              style={{
                                                fontFamily: "wow-regular",
                                              }}
                                            >
                                              Credit card Charge {price_1}{" "}
                                              {currency}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <h1 style={{position:'absolute', bottom:'0'}}>Customer Ratings</h1>
                                    </Col>
                                  </Row>
                                </div>
                              );
                            })}
                          </Container>
                        )}
                      </Row>
                    </div>





        <br />
        <br />
<div className="CustomerRevies-Section-aboveReviews-RentalCarsBookingDetailsForm-mainClass">
        <h3>Dubai International Airport</h3>

        <Row>
          <Col xl={6} lg={6}>
          <Table columns={columns} dataSource={data} />
          </Col>

          <Col xl={6} lg={6}>

<div class="RentalCarsBookingDetailsForm_reviews_score2">
  <div className="RentalCarsBookingDetailsForm_reviews_score22">
<img src="https://i.pinimg.com/280x280_RS/37/21/87/372187e972e2755d72e117b09ba47396.jpg" class="RentalCarsBookingDetailsForm_supplier_logo2" alt="WOW" />
<div class="RentalCarsBookingDetailsForm_reviews_text2">Excellent/ Overall</div>
<div class="RentalCarsBookingDetailsForm_reviews_value2">8.5<span>/10</span></div>
<div class="RentalCarsBookingDetailsForm_frequency2">2000+ reviews</div>
</div>
</div>
          </Col>
        </Row>
        </div>














{/* Reviews section */}




<div className="CustomerRevies-Section-RentalCarsBookingDetailsForm-mainClass">
<section className="section-stories">
<div class="u-center-text u-margin-bottom-second-heading-RentalCarsBookingDetailsForm-grid-section-pictures" id="ourtourid">
                  <div  className="heading-secondary-section-RentalCarsBookingDetailsForm-holiday-stories-section-pictures">
                      We Make People Genuinely Happy
                  </div>
                  </div>
                 
<Row className="row-in-stories-section-wow-RentalCarsBookingDetailsForm">
  <div className="story-RentalCarsBookingDetailsForm-holiday">
   
    <figure className="story__shape-RentalCarsBookingDetailsForm">
      <img 
      src="IMAGES/city-images/rental-cars/rentalcarsfliterpage/reviews/nat-82.jpg"
       alt="person on tour"
       className="story-RentalCarsBookingDetailsForm-holiday-image"
       >
       </img>
     <figcaption className="story__image-caption-RentalCarsBookingDetailsForm">
Mary Jaimes
     </figcaption>
    </figure>

    
    <div className="story__text-RentalCarsBookingDetailsForm">
  <h4 style={{textAlign:'right'}}>8.4</h4>
      <div className="heading-tertiary-story-section-RentalCarsBookingDetailsForm-holiday">
      I HAD THE BEST WEEK WITH MY FAMILY
      </div>
      <div className="heading-tertiary-text-story-section-RentalCarsBookingDetailsForm-holiday">
      I visited for the Yoga & Activity week at the beginning of May and had the best 
      time. Myself and 4 others were travelling solo, and the other 2 were a couple -
       we all really connected as a group, and had a great week of hiking, kayaking, wild
       swimming, caving, and of course - daily yoga sessions. All of my favourite things

      </div>
    </div>
   
  </div>
</Row>



<Row className="row-in-stories-section-wow-RentalCarsBookingDetailsForm">
  <div className="story-RentalCarsBookingDetailsForm-holiday-two">
   
    <figure className="story__shape-RentalCarsBookingDetailsForm">
      <img 
      src="IMAGES/city-images/wow-gallery/stories/nat-8-2.jpg"
       alt="person on tour"
       className="story-RentalCarsBookingDetailsForm-holiday-image-two"
       >
       </img>
       <figcaption className="story__image-caption-RentalCarsBookingDetailsForm-two">
Julian
     </figcaption>
    </figure>
    
    <div className="story__text-RentalCarsBookingDetailsForm">
      <div className="heading-tertiary-story-section-RentalCarsBookingDetailsForm-holiday">
      Best trip ever WITH MY Friends
      </div>
      <div className="heading-tertiary-text-story-section-RentalCarsBookingDetailsForm-holiday">
      It was best week ever. 7 days in Hurghada in hotel “Hawaii Riviera” with
       perfect territory, good food and drink and
       unforgettable animation team Thanks for everything. Hope for new meeting in the nearest future ️

      </div>
    </div>
   
  </div>
</Row>




<Row className="row-in-stories-section-wow-RentalCarsBookingDetailsForm" style={{display: 'none'}} id="reviews-3">
  <div className="story-RentalCarsBookingDetailsForm-holiday-two">
   
    <figure className="story__shape-RentalCarsBookingDetailsForm">
      <img 
      src="https://cdn.imgbin.com/25/9/3/imgbin-thought-stock-photography-thinking-man-man-wearing-black-suit-looking-up-WwXp9Y5XBPjv7dA9W2eEcEmXQ.jpg"
       alt="person on tour"
       className="story-RentalCarsBookingDetailsForm-holiday-image-two"
       >
       </img>
       <figcaption className="story__image-caption-RentalCarsBookingDetailsForm-two">
Rodriguez
     </figcaption>
    </figure>
    
    <div className="story__text-RentalCarsBookingDetailsForm">
      <div className="heading-tertiary-story-section-RentalCarsBookingDetailsForm-holiday">
      Best trip ever WITH MY Friends
      </div>
      <div className="heading-tertiary-text-story-section-RentalCarsBookingDetailsForm-holiday">
      It was best week ever. 7 days in Hurghada in hotel “Hawaii Riviera” with
       perfect territory, good food and drink and
       unforgettable animation team Thanks for everything. Hope for new meeting in the nearest future ️

      </div>
    </div>
   
  </div>
</Row>


<Row className="row-in-stories-section-wow-RentalCarsBookingDetailsForm" id="reviews-4" style={{display: 'none'}}>
  <div className="story-RentalCarsBookingDetailsForm-holiday-two">
   
    <figure className="story__shape-RentalCarsBookingDetailsForm">
      <img 
      src="https://salesfocusadvisory.com/wp-content/uploads/2018/08/CEO-challenges-being-considered.jpg"
       alt="person on tour"
       className="story-RentalCarsBookingDetailsForm-holiday-image-two"
       >
       </img>
       <figcaption className="story__image-caption-RentalCarsBookingDetailsForm-two">
Mane
     </figcaption>
    </figure>
    
    <div className="story__text-RentalCarsBookingDetailsForm">
      <div className="heading-tertiary-story-section-RentalCarsBookingDetailsForm-holiday">
      Best trip ever WITH MY Friends
      </div>
      <div className="heading-tertiary-text-story-section-RentalCarsBookingDetailsForm-holiday">
      It was best week ever. 7 days in Hurghada in hotel “Hawaii Riviera” with
       perfect territory, good food and drink and
       unforgettable animation team Thanks for everything. Hope for new meeting in the nearest future ️

      </div>
    </div>
   
  </div>
</Row>





<Row className="row-in-stories-section-wow-RentalCarsBookingDetailsForm" id="reviews-5" style={{display: 'none'}}>
  <div className="story-RentalCarsBookingDetailsForm-holiday-two">
   
    <figure className="story__shape-RentalCarsBookingDetailsForm">
      <img 
      src="https://www.fond.co/wp-content/uploads/2020/03/recognize-employees-for-specific-actions.jpg"
       alt="person on tour"
       className="story-RentalCarsBookingDetailsForm-holiday-image-two"
       >
       </img>
       <figcaption className="story__image-caption-RentalCarsBookingDetailsForm-two">
Julian
     </figcaption>
    </figure>
    
    <div className="story__text-RentalCarsBookingDetailsForm">
      <div className="heading-tertiary-story-section-RentalCarsBookingDetailsForm-holiday">
      Best trip ever WITH MY Friends
      </div>
      <div className="heading-tertiary-text-story-section-RentalCarsBookingDetailsForm-holiday">
      It was best week ever. 7 days in Hurghada in hotel “Hawaii Riviera” with
       perfect territory, good food and drink and
       unforgettable animation team Thanks for everything. Hope for new meeting in the nearest future ️

      </div>
    </div>
   
  </div>
</Row>



<Row className="row-in-stories-section-wow-RentalCarsBookingDetailsForm" id="reviews-6" style={{display: 'none'}}>
  <div className="story-RentalCarsBookingDetailsForm-holiday-two">
   
    <figure className="story__shape-RentalCarsBookingDetailsForm">
      <img 
      src="https://i.pinimg.com/236x/63/cc/bd/63ccbd257073a8bf2f7c6f1fdca32c00.jpg"
       alt="person on tour"
       className="story-RentalCarsBookingDetailsForm-holiday-image-two"
       >
       </img>
       <figcaption className="story__image-caption-RentalCarsBookingDetailsForm-two">
Alex Vidal
     </figcaption>
    </figure>
    
    <div className="story__text-RentalCarsBookingDetailsForm">
      <div className="heading-tertiary-story-section-RentalCarsBookingDetailsForm-holiday">
      Best trip ever WITH MY Friends
      </div>
      <div className="heading-tertiary-text-story-section-RentalCarsBookingDetailsForm-holiday">
      It was best week ever. 7 days in Hurghada in hotel “Hawaii Riviera” with
       perfect territory, good food and drink and
       unforgettable animation team Thanks for everything. Hope for new meeting in the nearest future ️

      </div>
    </div>
   
  </div>
</Row>







<div className="u-center-text" id="ShowMoreReviews-Button">
  <Link onClick={this.ShowMoreReviews} className="button-text-wow-story-section">
Read more Reviews &rarr;
  </Link>

</div>



<div className="u-center-text" id="ShowLessReviews-Button" style={{display:'none'}}>
  <Link onClick={this.ShowLessReviews} className="button-text-wow-story-section">
Hide Reviews &rarr;
  </Link>

</div>





</section>
</div>



      </div>
    );
  }
}
export default CustomerReviews_RentalCars;
