import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";

import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  NavLink,
  Route,
  browserhistory,
} from "react-router-dom";

class Holidayhelp extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false,
      dateblog: new Date().toLocaleString(),
    };
  }

  getDate() {
    var dateblog = { currentDate: new Date().toLocaleString() };

    this.setState({
      dateblog: dateblog,
    });
  }

  blogcard1 = () => {
    document.getElementById("blog-card1").style.display = "block";
    document.getElementById("blog-card2").style.display = "none";
  };

  blogcard2 = () => {
    document.getElementById("blog-card1").style.display = "none";
    document.getElementById("blog-card2").style.display = "block";
  };

  

  headings1helpcenter = () => {
    document.getElementById("headings1-helpcenter").style.display = "block";
    document.getElementById("headings2-helpcenter").style.display = "none";
    document.getElementById("headings3-helpcenter").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "block";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
  };


  headings2helpcenter = () => {
    document.getElementById("headings1-helpcenter").style.display = "none";
    document.getElementById("headings2-helpcenter").style.display = "block";
    document.getElementById("headings3-helpcenter").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "block";
    document.getElementById("margin-right-outline3").style.display = "none";
  };


  headings3helpcenter = () => {
    document.getElementById("headings1-helpcenter").style.display = "none";
    document.getElementById("headings2-helpcenter").style.display = "none";
    document.getElementById("headings3-helpcenter").style.display = "block";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "block";
  };


  

 
 


  alertshow = () => {
    alert("asdasdad");
  };

  render() {
    return (
      <div>





<div class="">
    <div class="help-page-top-container">
        <div class="help-page-top-content">
            <div class="container-help-center-wow-holiday">
                <h2 style={{textAlign: 'left', fontWeight: '700'}}>
                    Having trouble?</h2>
                <p>We're here to help</p>
                <p class="help-page-search-area">
                    <div class="rbt clearfix open" tabindex="-1" style={{position: 'relative'}}>
                        <div class="rbt-input-hint-container" style={{position: 'relative'}}>
                            {/* <input aria-autocomplete="both"
                             aria-expanded="false" 
                             aria-haspopup="listbox"
                              autocomplete="nope" 
                              placeholder=""
                               role="combobox"
                                type="text"
                                 class="rbt-input-main form-control-help-center-wow-holiday rbt-input " 
                                 value=""/> */}
                                 <div 
                                 class="rbt-input-hint input-hint-help-holiday" >
                                     {/* <input 
                                     aria-hidden="true"
                                      readonly="" 
                                      tabindex="-1" 
                                      value="" 
                                      className="input-box-help-holiday-bg-image"
                                      /> */}
                                      <div className="help-bg-image-holiday-div">
                                          </div>
                                          </div>
                                          </div>
                                          <div aria-atomic="true" aria-live="polite" class="sr-only-help-center rbt-sr-status" role="status">
                                              0 selections
                                              </div>
                                              </div>
                                              <span style={{marginLeft: '15px', fontSize: '30px', marginTop: '-5px'}}>
                                                  {/* <i class="fa fa-search help-icon-search"></i> */}
                                                  </span>
                                                  </p>
                                                  </div>
                                                  </div>
                                                  </div>
                                                  </div>























           <div class="help_center1">
          <Container>
            <center>
              <h3 class="help_center">
                WOW Worldwide Holiday Help Center
              </h3>
            </center>
            <div class="onPCMode">
              <div class="jss8-help-center">
                <Row>
                  <Col xl={3} lg={3} md={3} sm={3} style={{marginBottom:'2rem'}}>
                    <div
                      className="MuiTabs-root-help-center jss9-help-center MuiTabs-vertical-help-center"
                      aria-label="Vertical tabs example"
                    >
                      <div
                        className="MuiTabs-scrollable-help-center"
                        style={{
                          width: "99px",
                          height: "99px",
                          position: "absolute",
                          top: "-9999px",
                          overflow: "scroll",
                        }}
                      ></div>

                      <div
                        className="MuiTabs-scroller-help-center MuiTabs-scrollable-help-center"
                        style={{ marginBottom: "0px" }}
                      >
                        <div
                          className="MuiTabs-flexContainer-help-center MuiTabs-flexContainerVertical-help-center"
                          role="tablist"
                        >
                          <button
                            onClick={this.headings1helpcenter}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-help-center on-focus-button-helpcenter MuiTab-root-help-center MuiTab-textColorInherit-help-center yo terms-and-conditions-tab-name-help-center Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-help-center">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-help-center-muitab-heading"></i>{" "}
                                Tour Manager
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-help-center"></span>
                          </button>

                          <button
                            onClick={this.headings2helpcenter}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-help-center on-focus-button-helpcenter MuiTab-root-help-center MuiTab-textColorInherit-help-center yo terms-and-conditions-tab-name-help-center Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-help-center">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-help-center-muitab-heading"></i>{" "}
                                Traveller
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-help-center"></span>
                          </button>

                          <button
                            onClick={this.headings3helpcenter}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-help-center on-focus-button-helpcenter MuiTab-root-help-center MuiTab-textColorInherit-help-center yo terms-and-conditions-tab-name-help-center Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-help-center">
                              <span>
                                <i class="fas fa-angle-double-right icons-in-help-center-muitab-heading"></i>{" "}
                                Tour Guides
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-help-center"></span>
                          </button>

                        

                          {/* <Button onClick={this.headings6privacypolicy} className="MuiButtonBase-root-button MuiTab-root-button MuiTab-textColorInherit-button terms-and-conditions-tab-name-button" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
  <span class="MuiTab-wrapper-button">
  <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading-button"></i>Disclaimer</span></span>
<span className="MuiTouchRipple-root-button"></span>
</Button>

<Button onClick={this.headings6privacypolicy} className="MuiButtonBase-root-button MuiTab-root-button MuiTab-textColorInherit-button terms-and-conditions-tab-name-button" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
  <span class="MuiTab-wrapper-button">
  <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading-button"></i>asdasdadsad</span></span>
<span className="MuiTouchRipple-root-button"></span>
</Button>

<Button onClick={this.headings6privacypolicy} className="MuiButtonBase-root-button MuiTab-root-button MuiTab-textColorInherit-button terms-and-conditions-tab-name-button" tabindex="0" type="button" role="tab" aria-selected="false" id="vertical-tab-5" aria-controls="vertical-tabpanel-5">
  <span class="MuiTab-wrapper-button">
  <span>
<i class="fas fa-angle-double-right icons-in-term-condition-heading-button"></i>OPASKpdmasp</span></span>
<span className="MuiTouchRipple-root-button"></span>
</Button> */}
                        </div>

                       
                        <span
                          style={{ top: "0" }}
                          id="margin-right-outline1"
                          className="jss34-helpcenter jss36-helpcenter MuiTabs-indicator jss37-helpcenter"
                        ></span>

                        <span
                          style={{ top: "48px", display: "none" }}
                          id="margin-right-outline2"
                          className="jss34-helpcenter jss36-helpcenter MuiTabs-indicator jss37-helpcenter"
                        ></span>

                        <span
                          style={{ top: "96px", display: "none" }}
                          id="margin-right-outline3"
                          className="jss34-helpcenter jss36-helpcenter MuiTabs-indicator jss37-helpcenter"
                        ></span>

                        <span
                          style={{ top: "144px", display: "none" }}
                          id="margin-right-outline4"
                          className="jss34-helpcenter jss36-helpcenter MuiTabs-indicator jss37-helpcenter"
                        ></span>
                      </div>
                    </div>

                  </Col>















                  <Col xl={9} lg={9} md={9} sm={9} id="headings1-helpcenter">
                    <div
                      class="MuiTypography-root-helpcenter termsTabs-helpcenter MuiTypography-body1-helpcenter"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root-helpcenter jss69-helpcenter">
                        <div class="helpcenter_detail">
                          <h4>For Tour Managers</h4>
                         
                        </div>


                        <Row>
  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-users"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Managing Tour and Team
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
     Manage Holiday Trip &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
ETA Methodology &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Canceling a Holiday Trip &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
     Learn more &rarr;
</Link>
</div>


</div>




  </Col>


  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons"> 
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-user"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
BECOME A TOUR Manager
</div>
</div>
<p className="feature__box-text-wow-help-center">
If you want to become a WOW Tour Guide and earn some additional money then
 download the WOW Holiday app and sign up to become a Guide.
</p>

<div style={{textAlign:'left'}}>

<Link  className="link-button-text-wow-help-center-holiday">
     Read more &rarr;
</Link>
</div>


</div>




  </Col>




  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons"> 
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-user-shield"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Security
</div>
</div>  
<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
Sharing Details of Travel &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Safety &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
In case of an accident &rarr;
</Link><br/>

</div>


</div>




  </Col>





  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">  
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fab fa-alipay"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
PAYMENTS
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
Payments Method &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Changing payment methods &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Payments receipt &rarr;
</Link><br/>


</div>


</div>




  </Col>









  


 

</Row>





                       

                       

                      </div>
                    </div>
                  </Col>





























                  <Col xl={9} lg={9} md={9} sm={9} id="headings2-helpcenter" style={{display:'none'}}>
                     <div
                      class="MuiTypography-root-helpcenter termsTabs-helpcenter MuiTypography-body1-helpcenter"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root-helpcenter jss69-helpcenter">
                        <div class="helpcenter_detail">
                          <h4>For Travellers</h4>
                         
                        </div>


                        <Row>
  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-plane"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Booking Process
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
How can I book an activity?
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
What do I get after I book? 
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Canceling a Trip
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
How do I know if my booking is confirmed?
</Link><br/>

</div>


</div>




  </Col>


  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-briefcase"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Lost Stuff
</div>
</div>
<p className="feature__box-text-wow-help-center">
If your wallet, ring, smartphone or any other thing valuable to you was lost during your recent WOW ride
</p>

<div style={{textAlign:'left'}}>

<Link  className="link-button-text-wow-help-center-holiday">
     Read more &rarr;
</Link>
</div>


</div>




  </Col>




  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fa fa-shield-alt" aria-hidden="true"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Security
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
Sharing Details of Travel 
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Safety 
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
In case of an accident
</Link><br/>


<Link  className="link-button-text-wow-help-center-holiday">
Where can I get more information, support or make a claim?
</Link>


</div>


</div>




  </Col>





  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">


  <div className="border-line-wow-help-icons">
     
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-credit-card"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
PAYMENTS
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
Payments Method &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Changing payment methods &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Payments receipt &rarr;
</Link><br/>


</div>


</div>




  </Col>




  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter far fa-file-alt"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
About WOW
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
About Wow &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
How to Contact Wow &rarr;
</Link><br/>



</div>


</div>




  </Col>


  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-plane"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Holiday Bookings
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
     Booking Holiday Trip &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
ETA Methodology &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Canceling a Trip &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
     Learn more &rarr;
</Link>
</div>


</div>




  </Col>




  




  


 

</Row>





                       

                       

                      </div>
                    </div>
                  </Col>









































                  <Col xl={9} lg={9} md={9} sm={9} id="headings3-helpcenter" style={{display:'none'}}>
                  <div
                      class="MuiTypography-root-helpcenter termsTabs-helpcenter MuiTypography-body1-helpcenter"
                      role="tabpanel"
                      id="vertical-tabpanel-0"
                      aria-labelledby="vertical-tab-0"
                    >
                      <div class="MuiBox-root-helpcenter jss69-helpcenter">
                        <div class="helpcenter_detail">
                          <h4>For Tour Guides</h4>
                         
                        </div>


                        <Row>
  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-user"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Become a Tour Guide
</div>
</div>

<p className="feature__box-text-wow-help-center">
If you want to become a WOW Tour Guide and earn some additional
 money then download the WOW Holiday app and sign up to become a Guide.
</p>

<div style={{textAlign:'left'}}>

<Link  className="link-button-text-wow-help-center-holiday">
     Read more &rarr;
</Link>
</div>


</div>




  </Col>


  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



     <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-plane"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
ACCIDENT HAPPENED
</div>
</div>
<p className="feature__box-text-wow-help-center">
WOW cares about its Travellers and Team members. The safety of our travellers and tour team
 is preferred in any situation.
</p>

<div style={{textAlign:'left'}}>

<Link  className="link-button-text-wow-help-center-holiday">
     Read more &rarr;
</Link>
</div>


</div>




  </Col>




  <Col xl={6} lg={6} md={6} sm={12} xs={12} className="service-box-help-center-margin">
  <div className="services-box-helpcenter">



  <div className="border-line-wow-help-icons">
<i 
className="
lg-icon-helpcenter
  circle-icon-helpcenter primary-icon-filled-helpcenter fas fa-plane"></i>

<div  className="header-heading1-inside-grid-wow-help-center-features-box">
Security
</div>
</div>

<div style={{textAlign:'left'}}>
<Link  className="link-button-text-wow-help-center-holiday">
Sharing Details of Travel &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
Safety &rarr;
</Link><br/>

<Link  className="link-button-text-wow-help-center-holiday">
In case of an accident &rarr;
</Link><br/>

</div>


</div>




  </Col>





 




  




  


 

</Row>





                       

                       

                      </div>
                    </div>
                  </Col>




            </Row>

                            
              </div>
            </div>
          </Container>
        </div>
      



























































     
      </div>
    );
  }
}
export default Holidayhelp;
