import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Container,
  Row,
  Media,
  Col,
  Figure,
} from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../HolidayAirportTaxi/AirportTaxi.scss";
import Airport_taxi13passengers from "../HolidayAirportTaxi/13PassengersTaxi";
import Airport_taxi47passengers from "../HolidayAirportTaxi/47PassengersTaxi";
import Airport_taxiAllpassengers from "../HolidayAirportTaxi/AllPassengersTaxi"


class Airport_taxi extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      showHide13passengers: true,
      showHide47passengers: false,
      showHideAllTaxis: false,
      cars: [
        { name: "Audi", country: "Germany" },
        { name: "BMW", country: "Germany" },
        { name: "Chevrolet", country: "USA" },
        { name: "Citroen", country: "France" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Mercedes-Benz", country: "Germany" },
        { name: "Renault", country: "France" },
        { name: "Seat", country: "Spain" },
        { name: "Dodge", country: "USA" },
        { name: "BMW", country: "Germany" },
        { name: "Tesla", country: "USA" },
        { name: "Volkswagen", country: "Germany" },
        { name: "Hyundai", country: "South Korea" },
        { name: "Jaguar", country: "United Kingdom" },
        { name: "GMC", country: "USA" },
        { name: "Bentley", country: "United Kingdom" },
      ],
      numberOfitemsShown: 1,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }


  hideComponent(name) {
    switch (name) {
      case "showHide13passengers":
        this.setState({
          // showHide13passengers: !this.state.showHide13passengers,
          showHide13passengers: true,
          showHide47passengers: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHide47passengers":
        this.setState({
          // showHide47passengers: !this.state.showHide47passengers,
          showHide47passengers: true,
          showHide13passengers: false,
          showHideAllTaxis: false,
        });
        break;
      case "showHideAllTaxis":
        this.setState({
          // showHideAllTaxis: !this.state.showHideAllTaxis,
          showHideAllTaxis: true,
          showHide13passengers: false,
          showHide47passengers: false,
        });
        break;
      case "showHide13passengersimg":
        this.setState({
          showHide13passengersimg: !this.state.showHide13passengersimg,
        });
      default:
      // null;
    }
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const {
      showHide13passengers,
      showHide13passengersimg,
      showHide47passengers,
      showHideAllTaxis,
    } = this.state;
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>

        <div className="td-post-header-holder-AirportTaxis td-image-gradient-AirportTaxis">
          <div className="td-post-featured-image-AirportTaxis">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-AirportTaxis"
              src="IMAGES/city-images/Airport-taxi/airport-taxi.png"
            />
            <div class="join-us-our-content">
              <div class="MuiContainer-root MuiContainer-maxWidthLg">
                {/* <div class="airportTaxi-wow-holiday-home-our-story">
                  <h1 class="airportTaxi-wow-holiday-our-team-heading">
                    Airport Taxi
                  </h1>
                  <h3
                    className="text-in-h3-airportTaxi"
                    style={{
                      color: "#ffffff",
                      paddingLeft: "0%",
                      textAlign: "left",
                      fontSize: "25px",
                    }}
                  >
                    WITH LATEST TECHNOLOGICAL TRENDS
                  </h3>
                  <h5
                    style={{
                      color: "#ffffff",
                      paddingLeft: "0%",
                      fontSize: "20px",
                    }}
                    className="airportTaxi-banner-top-co"
                  >
                    WOW Easy transportation between the airport and your accommodations.
                  </h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="wow-airporttaxi--bg">
          <Container>
            <Row>
              <div class=" wow-airporttaxi--4">
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i className="fas fa-tasks user-icon-airporttaxi-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-airporttaxi">
                        <b>Flight tracking</b>
                        <br />
                      </div>
                      <div className="subTitle-text-wow-airporttaxi">
                        Your driver tracks your flight and waits for you if it's
                        delayed
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i class="fas fa-money-bill-wave user-icon-airporttaxi-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-airporttaxi">
                        <b>One clear price</b>
                        <br />
                      </div>
                      <div class="subTitle-text-wow-airporttaxi">
                        Your price is confirmed upfront – no extra costs, no
                        cash required
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        <i className="fas fa-credit-card user-icon-airporttaxi-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-airporttaxi">
                        <b>Pay the way you like</b>
                        <br />
                      </div>{" "}
                      <div class="subTitle-text-wow-airporttaxi">
                        Cash, card, or WOW credits/Rewards, we’ve got you
                        covered.
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-airporttaxi">
            <div className="heading_secondary_uk_section_wow_airporttaxi">
              WOW: Airport transfers made easy
            </div>
          </div>
        </Container>

        <Container>
          <Row>
            <Col xl={6} lg={6}>
              <Media className="media-section-airporttaxi-holiday ">
                <img
                  width={70}
                  height={70}
                  style={{ width: "60px", height: "60px" }}
                  className="align-self-center mr-3 bui-list__icon bui-list__icon-green-bg blblbl"
                  src="IMAGES/city-images/Airport-taxi/Asset 13.png"
                  alt="Generic placeholder"
                />
                <Media.Body>
                  <h5 className="heading-text-media-airporttaxi-holiday">
                    Booking your airport taxi
                  </h5>
                  <p className="paragraph-text-media-airporttaxi-holiday">
                    Confirmation is immediate. If your plans change, you can
                    cancel for free up to 24 hours before your scheduled pick-up
                    time
                  </p>
                </Media.Body>
              </Media>

              <Media className="media-section-airporttaxi-holiday">
                <img
                  width={64}
                  height={64}
                  className="align-self-center mr-3"
                  src="IMAGES/city-images/Airport-taxi/Asset 14.png"
                  alt="Generic placeholder"
                />
                <Media.Body>
                  <h5 className="heading-text-media-airporttaxi-holiday">
                    Media Heading
                  </h5>
                  <p className="paragraph-text-media-airporttaxi-holiday">
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
                    scelerisque ante sollicitudin commodo. Cras purus odio,
                    vestibulum in vulputate at, tempus viverra turpis. Fusce
                    condimentum nunc ac nisi vulputate fringilla. Donec lacinia
                    congue felis in faucibus.
                  </p>
                </Media.Body>
              </Media>

              <Media className="media-section-airporttaxi-holiday">
                <img
                  width={64}
                  height={64}
                  className="align-self-center mr-3"
                  src="IMAGES/city-images/Airport-taxi/Asset 11.png"
                  alt="Generic placeholder"
                />
                <Media.Body>
                  <h5 className="heading-text-media-airporttaxi-holiday">
                    Media Heading
                  </h5>
                  <p className="paragraph-text-media-airporttaxi-holiday">
                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
                    scelerisque ante sollicitudin commodo. Cras purus odio,
                    congue felis in faucibus.
                  </p>
                </Media.Body>
              </Media>
            </Col>

            <Col xl={6} lg={6}>
              {/* <img
                style={{ width: "100%" }}
                src="https://cf.bstatic.com/static/img/taxi-airportTaxis/79d898d0e29dd469799e76b6d4bb71b5b0f03707.svg"
              /> */}

              <div class="bui-grid__column-6@large taxi-airportTaxis-illustration">
                <div class="taxi-airportTaxis-illustration-wrapper">
                  <span class="taxi-airportTaxis-speech ">
                    <span class="taxi-airportTaxis-speech-bubble ">
                      <b>How does it work?</b>
                    </span>
                  </span>
                  <span class="taxi-airportTaxis-book ">Book Taxi</span>
                  <span class="taxi-airportTaxis-confirmation ">
                    Receive confirmation
                  </span>
                  <span class="taxi-airportTaxis-driver ">
                    Meet your driver
                  </span>
                  <span class="taxi-airportTaxis-arrive ">
                    Arrive at your destination
                  </span>
                  <span class="taxi-airportTaxis-enjoy ">Enjoy your trip!</span>
                  <img
                    src="IMAGES/city-images/Airport-taxi/94.png"
                    class="landing-page__section-taxi-airportTaxis-img "
                    alt="Booking Airport Taxi"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <div className="gradient-back-travel-airporttaxi-wowholiday">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-airporttaxi-grid-text-wowholiday">
                  <h6 class="our-story-main-araea-headings12-ser-wowholiday">
                    Easy airport transfers to and from your accommodation.
                  </h6>
                  <div className="travel-airporttaxi-page-araea-sub-headings110-wowholiday">
                    Airport Taxi!
                  </div>

                  <div className="travel-airporttaxi-page-page-text-wowholiday">
                    WOW welcomes the tourists coming from all over the world to
                    explore this wonderful city. WOW is set to give the
                    high-class travel-sharing services to the citizens as well
                    as the foreigners.
                  </div>
                  <div className="travel-airporttaxi-page-page-text-wowholiday">
                    We emphasize travelers to take the best travelling services
                    all over world with the experience they never have
                    experienced before.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/Airport-taxi/collage.png"
                />
              </Col>
            </Row>
          </Container>
        </div>


        <Container style={{padding:'2rem 0rem'}}>
            <div className="heading-primary-in-wow-holiday-cards text-box-inside-background-image-of-new-design">
              <span className="heading-primary-main-airportaaxi">
                Airport Luxuary cars
              </span>
              <span className="heading-primary-sub-airportaaxi">
              A car for every budget and occasion provided by WOW
              </span>
            </div>


 {/* wow Economy cars section //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

<div className="cars-section-airport-taxi-holiday-grid">
  <div className="heading-text-cars-section-airport-taxi-holiday-grid">
    WOW Economy Cars
  </div>
<Row style={{padding:'0 1rem'}} className="margin-in-row-airporttaxi-abovecars-area">
  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/6-Chrysler-300C.png"
  />
  <Figure.Caption>
    Standard
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/4.png"
  />
  <Figure.Caption>
    Executive
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/1-Honda.png"
  />
  <Figure.Caption>
    Carrier
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/6-Mercedes-(V-Class).png"
  />
  <Figure.Caption>
  Executive Carrier
  </Figure.Caption>
</Figure>
  </Col>
</Row>

{/* wow family cars section //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

<div className="heading-text-cars-section-airport-taxi-holiday-grid">
 WOW Family
  </div>
<Row style={{padding:'0 1rem'}} className="margin-in-row-airporttaxi-abovecars-area">
  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/5-Chevrolet-IMPALA-(LIMO).png"
  />
  <Figure.Caption>
    Standard
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/1-Wow-economy/2 Lexus ES 300 HYBRID (P).png"
  />
  <Figure.Caption>
    Executive
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/3-Volkswagen-(MULTIVAN).png"
  />
  <Figure.Caption>
    Carrier
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/2-WOW-Family/4-GMC.png"
  />
  <Figure.Caption>
  Executive Carrier
  </Figure.Caption>
</Figure>
  </Col>
</Row>


{/* wow VIP cars section //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
<div className="heading-text-cars-section-airport-taxi-holiday-grid">
    WOW VIP
  </div>
<Row style={{padding:'0 1rem'}} className="margin-in-row-airporttaxi-abovecars-area">
  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/1.png"
  />
  <Figure.Caption>
  Mercedes
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/3-BMW-(7-SERIES).png"
  />
  <Figure.Caption>
  BMW-(7-SERIES
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/5 Maserati Ghibli.png"
  />
  <Figure.Caption>
  Maserati Ghibli
  </Figure.Caption>
</Figure>
  </Col>

  <Col lg={3} xl={3} md={3} sm={6} xs={12}>
  <Figure>
  <Figure.Image
    width={171}
    height={180}
    alt="171x180"
    src="IMAGES/city-images/Airport-taxi/wow-car-categories/3-WOW-VIP/8-Mercedes-(E-CLASS).png"
  />
  <Figure.Caption>
  Mercedes
  </Figure.Caption>
</Figure>
  </Col>
</Row>

</div>
        </Container>





        <div className="bg-class-airporttaxi-transfer-section">
          <Container>
            <div className="u-center-text u-margin-bottom-second-heading-wow-airporttaxi">
              <div className="heading_secondary_uk_section_wow_airporttaxi">
                WOW: Airport transfers made easy
              </div>
            </div>
          </Container>

          <div>
            <Container>
              <div className="button-group-section-airporttaxi-holiday">
                <ButtonGroup
                  size="large"
                  color="primary"
                  aria-label="large outlined primary button group"
                >
                  <Button
                    onClick={() => this.hideComponent("showHide13passengers")}
                    className={
                      "" +
                      (this.state.showHide13passengers ? "selected-button" : "")
                    }
                  >
                    1 - 3 Passengers
                  </Button>
                  <Button
                    onClick={() => this.hideComponent("showHide47passengers")}
                    className={
                      "saaaada" +
                      (this.state.showHide47passengers ? "selected-button" : "")
                    }
                  >
                    4 - 7 Passengers
                  </Button>
                  <Button
                    onClick={() => this.hideComponent("showHideAllTaxis")}
                    className={
                      "saaaada" +
                      (this.state.showHideAllTaxis ? "selected-button" : "")
                    }
                  >
                    All Taxis
                  </Button>
                </ButtonGroup>
              </div>

              

              <div
                class="seo_link_maing_top_cities_section g_main_top_cities_section"
                style={{
                  paddingTop: "28px",
                  borderTop: "1px solid rgb(235, 235, 235)",
                }}
              >
                <div>
                  <div class="seo_link_content">
                    {showHide13passengers && (
                      <Container>
                        <Airport_taxi13passengers/>
                      </Container>
                    )}
                  </div>
                </div>

                {/* show hite Top Thins to do ///////////////////////////////////////////////////////      */}

                <div>
                  {showHide47passengers && (
                    <Container>
                    <Airport_taxi47passengers/>
                  </Container>
                  )}
                </div>

                {/* show hide top events /////////////////////////////////////////////////////////    */}

                {showHideAllTaxis && <p class="seo_link_title">All Taxis</p>}

                {showHideAllTaxis && (
                  <Container>
                    <Airport_taxiAllpassengers/>
                  </Container>
                )}
              </div>

      
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
export default Airport_taxi;
