import React, { Component, useEffect } from "react";
import '../Lechequedetails/lechequedetails.scss';
import "react-multi-carousel/lib/styles.css";

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Container,
  Row,
  DropdownButton,
  Dropdown,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  Media
} from "react-bootstrap";


function initImageUpload(box) {
    let uploadField = box.querySelector('.image-upload');
  
    uploadField.addEventListener('change', getFile);
  
    function getFile(e){
      let file = e.currentTarget.files[0];
      checkType(file);
    }
    
    function previewImage(file){
      let thumb = box.querySelector('.js--image-preview-imageupload-chequedetails'),
          reader = new FileReader();
  
      reader.onload = function() {
        thumb.style.backgroundImage = 'url(' + reader.result + ')';
      }
      reader.readAsDataURL(file);
      thumb.className += ' js--no-default';
    }
  
    function checkType(file){
      let imageType = /image.*/;
      if (!file.type.match(imageType)) {
        throw 'Datei ist kein Bild';
      } else if (!file){
        throw 'Kein Bild gewählt';
      } else {
        previewImage(file);
      }
    }
    
  }

  // initialize box-scope
var boxes = document.querySelectorAll('.box-imageupload-chequedetails');

for(let i = 0; i < boxes.length; i++) {if (window.CP.shouldStopExecution(1)){break;}
  let box = boxes[i];
  initDropEffect(box);
  initImageUpload(box);
}
// window.CP.exitedLoop(1);




/// drop-effect
function initDropEffect(box){
  let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;
  
  // get clickable area for drop effect
  area = box.querySelector('.js--image-preview-imageupload-chequedetails');
  area.addEventListener('click', fireRipple);
  
  function fireRipple(e){
    area = e.currentTarget
    // create drop
    if(!drop){
      drop = document.createElement('span');
      drop.className = 'drop';
      this.appendChild(drop);
    }
    // reset animate class
    drop.className = 'drop';
    
    // calculate dimensions of area (longest side)
    areaWidth = getComputedStyle(this, null).getPropertyValue("width");
    areaHeight = getComputedStyle(this, null).getPropertyValue("height");
    maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));

    // set drop dimensions to fill area
    drop.style.width = maxDistance + 'px';
    drop.style.height = maxDistance + 'px';
    
    // calculate dimensions of drop
    dropWidth = getComputedStyle(this, null).getPropertyValue("width");
    dropHeight = getComputedStyle(this, null).getPropertyValue("height");
    
    // calculate relative coordinates of click
    // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
    x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10)/2);
    y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10)/2) - 30;
    
    // position drop and animate
    drop.style.top = y + 'px';
    drop.style.left = x + 'px';
    drop.className += ' animate';
    e.stopPropagation();
    
  }
}
  


class ComponentToPrint extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  
  render() {
    return (
      <div>


        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12} xs={12}>
                <div className="invoice-le-view_area-chequedetails" style={{overflow:'auto'}}>
                
                 

                  <div className="invoice-chequedetails-div-body-le">
                    <div className="invoice-chequedetails-body-inside">





<div className="accountant-heading-chequedetails">
    Acountant Panel
</div>
        
<div className="chequedetails-heading-area">
    <div className="chequedetails-heading">
Cheque Details
    </div>
</div>








<div class="description_of_card_of_chequedetails">
 <div class="description_of_card_of_chequedetails_area">


<Row>
<Col xl={6} md={6} lg={6} xs={6}>


<Form>
<div className="forms-group-chequedetails">
  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
              <div className="label-form-chequedetails">
    <Form.Label>Bank Name:</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control as="select" defaultValue="Dubai Islamic Bank">
    <option>Dubai Islamic Bank</option>
    <option>Large select</option>
    <option>Large select</option>
  </Form.Control>
  </Col>
    </Row>
  </Form.Group>




  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-chequedetails">
    <Form.Label>Name</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="text" style={{marginBottom:'10px'}} placeholder="| Type Here" />
  </Col>
    </Row>
  </Form.Group>



  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-chequedetails">
    <Form.Label>Amount:</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="number" placeholder="AED 1000" required/>
  </Col>
    </Row>
  </Form.Group>

  </div>

  
</Form>
</Col>









<Col xl={6} md={6} lg={6} xs={6}>


<Form>
<div className="forms-group-chequedetails">
  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-chequedetails">
    <Form.Label>Cheque No:</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="number" placeholder="| Type Here" />
  </Col>
    </Row>
  </Form.Group>




  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-chequedetails">
    <Form.Label>Clearance Date:</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="date" placeholder="" required="" value="2020-07-03" />
  </Col>
    </Row>
  </Form.Group>



  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-chequedetails">
    <Form.Label>Account No:</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="number" placeholder="| Type Here" />
  </Col>
    </Row>
  </Form.Group>

  </div>

  
</Form>
</Col>



</Row>




<Row style={{marginTop:'1rem'}}>
<Col xl={6} md={6} lg={6} xs={6}>

<div className="add-check-button-chequedetails">
   <p><a href="#"> <i className="fa fa-plus"></i></a> Add a check Photo </p> 
</div>


</Col>



<Col xl={6} md={6} lg={6} xs={6}>
<div className="add-save-button-chequedetails">
   <Button variant="dark" style={{ borderRadius:'15px' }}>Save</Button>
</div>

</Col>
</Row>

                  
    
                   
    <div className="chequedetails-template__divider--full-width">
        <div className="le-divider-top-chequedetails"></div>
    </div>






<Row>
<Col xl={6} md={6} lg={6} xs={6}>


<Form>
<div className="forms-group-chequedetails">
  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
              <div className="label-form-chequedetails">
    <Form.Label>Date</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control type="date" placeholder="" required="" value="2020-07-03" />
  </Col>
    </Row>
  </Form.Group>




  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
              <div className="label-form-chequedetails">
    <Form.Label>Cheque Status:</Form.Label>
    </div>
    </Col>
    <Col lg={8}>
    <Form.Control as="select" value="Select...">
    <option>Select...</option>
    <option>Bounced</option>
    <option>In Clearence</option>
    <option>Cashed/Passed</option>
    <option>Deposit In Bank Account</option>
  </Form.Control>
  </Col>
    </Row>
  </Form.Group>

  </div>

  
</Form>
</Col>









<Col xl={6} md={6} lg={6} xs={6}>


<Form>
<div className="forms-group-chequedetails">
  <Form.Group controlId="exampleForm.ControlSelect1">
      <Row>
          <Col lg={4}>
          <div className="label-form-chequedetails">
    </div>
    </Col>
    <Col lg={8}>

        <div class="wrapper-imageupload-chequedetails">
  <div class="box-imageupload-chequedetails">
    <div class="js--image-preview-imageupload-chequedetails"></div>
    <div class="upload-options-imageupload-chequedetails">
      <label>
        <input type="file" class="image-upload" accept="image/*" />
      </label>
    </div>
  </div>
  </div>

    <div className="button-save-records-below-chequedetails">
  <Button variant="dark" className="save-button" style={{ borderRadius:'15px', width:'50%' }}>Save</Button>

  </div>

  <div className="button-save-records-below-chequedetails">
  <Button variant="dark" style={{ borderRadius:'15px', width:'100%' }}>Save & Record a Payment</Button>

  </div>
  </Col>
    </Row>
  </Form.Group>

  </div>

  
</Form>
</Col>



</Row>



              





        </div>
        </div>


        





                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>




        







        


 

 









      </div>
    );
  }
}

class LEchequedetails extends React.Component {
  render() {
    return (
      <div>
        <Container>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <Button 
            style={{borderRadius:'20px'}}
            variant="outline-dark">Print this out!</Button>;
          }}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={el => (this.componentRef = el)} />
        </Container>
      </div>
    );
  }
}

export default LEchequedetails;
