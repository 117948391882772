import React, { Component , useState } from "react";
import { Container, Row, Media, Col, Figure } from "react-bootstrap";
import { Collapse, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Checkbox } from 'antd';
import { Slider } from 'antd';



const marks = {
  0: '0',
  // 26: '26',
  // 37: '37',
  100: {
    style: {
      color: '#f50',
    },
    label: <strong>100</strong>,
  },
};


  const onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  };

  const onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  };

function onChangeChecked(checkedValues) {
    console.log('checked = ', checkedValues);
}

const CheckboxGroup = Checkbox.Group;

const plainOptions = ['Apple', 'Pear', 'Orange'];
const defaultCheckedList = ['Apple', 'Orange'];

const { Panel } = Collapse;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const genExtra = () => (
  <SettingOutlined
    onClick={(event) => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation();
    }}
  />
);

class RentalCarsAccordion extends React.Component {
  state = {
    expandIconPosition: "right",
    checkedList: defaultCheckedList,
    indeterminate: true,
    checkAll: false,
  };


  onPositionChange = (expandIconPosition) => {
    this.setState({ expandIconPosition });
  };

  
  onChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  render() {
    const { expandIconPosition } = this.state;
    
    return (
      <>
        <div>
          <div className="accordion-rentalcars-bookingfilter-page">
            <Collapse
              defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
              onChange={callback}
              expandIconPosition={expandIconPosition}
              ghost
            >

<Panel header="Name Contains" key="1">
                <div>
                <input
                placeholder="e.g Audi"
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "1px solid #aaaaaa",
                  background: "none",
                  height: "35px",
                  color:'black',
                  padding:'20px'
                }}
                type="text"
              />
                </div>
              </Panel>





              <Panel header="Pick-Up" key="1">
                <div>
                <div className="site-checkbox-all-wrapper">
                <Row >
        <div className="small-heading-accordion-rentalcars-bookingfilter-page">Non-airport</div>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">Dubai, UAE</Checkbox>
      </Col>

      <div className="small-heading-accordion-rentalcars-bookingfilter-page">Airport</div>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">Dubai, UAE</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="E">Allama Iqbal INT. Airport</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="F">UK London airport</Checkbox>
      </Col>
    </Row>
    </div>
                </div>
              </Panel>



              <Panel header="Capacity" key="2">
                <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
        <div className="small-heading-accordion-rentalcars-bookingfilter-page">Passengers</div>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">Passengers 1 to 3</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">Passengers 3 to 5</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">Passengers 6 or more</Checkbox>
      </Col>

      <div className="small-heading-accordion-rentalcars-bookingfilter-page">Bags</div>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">Bags 1 to 3</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="E">Bags 3 to 5</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="F">Bags 5 or more</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>




              <Panel header="Price" key="3">
                <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">From 3000 to 2000$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">From 2000 to 1500$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">From 1500 to 1000$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">From 1 to 1000$</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>





              <Panel header="Facility Policies" key="4">
                <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">Fair Fuel Policy</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">Airport Conditioning</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">Airport Transfers</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">Enhanced Cleaning</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="E">Meeting Facilities</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="F">Wifi Included</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>






              <Panel header="Star rating" key="5">
                <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">5 star</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">4 star</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">3 star</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">2 star</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">1 star</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>






              <Panel
                header="Guest Rating"
                key="6"
                //    extra={genExtra()}
              >
               <div className="rental-cars-slider-guest-rating">

    <Slider range marks={marks} defaultValue={[0, 100]} style={{color:'black'}} />

                {/* <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">From 3000 to 2000$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">From 2000 to 1500$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">From 1500 to 1000$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">From 1 to 1000$</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group> */}
                </div>
              </Panel>


              <Panel
                header="Distance"
                key="7"
                //    extra={genExtra()}
              >
               <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">From 3000 to 2000$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">From 2000 to 1500$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">From 1500 to 1000$</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">From 1 to 1000$</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>
              
              
              
              <Panel
                header="Damage excess"
                key="8"
                //    extra={genExtra()}
              >
               <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                defaultValue={['A']}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">US$0.00 or less</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">US$500.00 or less</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="C">US$750.00 or less</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="D">US$1000.00 or less</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>



              <Panel
                header="Free Cancellation & Payment"
                key="9"
                //    extra={genExtra()}
              >
               <div>
                <Checkbox.Group 
                style={{ width: '100%' }} 
                onChange={onChangeChecked}
                >
    <Row >
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="A">Free Cancellation</Checkbox>
      </Col>
      <Col xs={12} style={{padding:'5px 16px'}}>
        <Checkbox value="B">Book without card details</Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
                </div>
              </Panel>
            </Collapse>
            <br />


            <div class="why-book">
                <section>
                    <h2>Why book with us?</h2>
                    <ul>
                        <li>
                            <p>
                                <div>Price Guarantee</div>
                                </p>
                                </li>
                                </ul>
                                </section>
                                </div>
            {/* <Select
          value={expandIconPosition}
          style={{ margin: '0 8px' }}
          onChange={this.onPositionChange}
        >
          <Option value="left">left</Option>
          <Option value="right">right</Option>
        </Select> */}
          </div>
        </div>
      </>
    );
  }
}
export default RentalCarsAccordion;
