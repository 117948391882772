import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Button,
  Col,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost15/blogpost15.scss";
// import ShowAnimationButton from "../Wowholidayblogpost15/ShowAnimationButton";

class Blogpost15 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
      show: true,
      isOpen: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  openModal() {
    this.setState({ isOpen: true });
  }

  onFormSubmit() {
    console.log("Form Submitted");
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };


  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { showHideFName, showHideLName } = this.state;
  
    return (
      <div>
        <div className="td-post-header-holder-blogcard15 td-image-gradient-blogcard15">
          <div className="td-post-featured-image-blogcard15">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard15"
              src="IMAGES/city-images/wow-blog/additional-cards/blog15/bg-image.png"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard15">
              <ul className="td-category-blogcard15">
                <li className="entry-category-blogcard15">
                  <a target="blank" href="https://www.wowets.com/marsorbiter">
                    Mars Launch
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard15">WOW: News & Trends</h1>

              <h4 className="entry-title-two-blogcard15">
                Successful Launch of UAE Mars Orbiter Hope: 2020
              </h4>

              <div className="td-module-meta-info-blogcard15">
                <span className="td-post-date-blogcard15">
                  <time
                    className="entry-date-blogcard15 updated td-module-date-blogcard15"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    19 JULY 2020 / Dubai
                  </time>
                </span>

                <div className="td-post-views-blogcard15">
                  <i className="fa fa-eye view-icon-views-blogcard15"></i>
                  <span className="td-nr-views-10968">43239</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard15">
            <div className="heading_secondary_uk_section_wow_blogscard15">
              WOW News & Trends
            </div>
          </div>
        </Container>

        <Container>
          <ol class="blogscard15-description-area-of-main--text-above collapse-section-blogpost marsBLogLi">
            <li>Successful launch of Emirati Mars Mission</li>
            <li>Taking an Arab World into the space competition</li>
            <li>First planetary space mission led by United Arab Emirates</li>
            <li>Launched on July 19</li>
          </ol>
        </Container>
        <br />
        <Container>
          <p class="blogscard15-description-area-of-main--text">
            WOW Electronic Transport Services is a group of an electronic
            services company that provides transportation services in different
            modes to assist people in going to their desired places. WOW is
            ready to start its field operation in different countries and
            regions around the world like USA, UK, France, and providing
            electronic transport services in UAE. Our future vision is to
            provide and support in the growth of space traveling. WOW will
            introduce autonomous and driverless car in future, those are would
            be work on AI algorithm with an affiliated technologies.<br/> The UAE
            additionally desires the venture to function a supply of ideas for
            Arab children, in a vicinity too frequently wracked with the aid of
            using sectarian conflicts and financial crises.
          </p>
        </Container>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogcard15">
            <div className="heading-secondary-section-wow-blogcard15 ">
              Dubai Launch Mars Orbitar
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard15">
            <div class="description_of_card_of_blogscard15_area">
              <h2 class="blogscard15-description-area-of-main-headings">
                Congratulations to all UAE!
              </h2>
              <p class="blogscard15-description-area-of-main--text">
                WOW is so glad and happy for the successful launch of Mars hope
                mission.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                UAE have just made a vision to the future. Be proud of your
                successes.
              </p>
              <h2 class="blogscard15-description-area-of-main-headings">
                Best Wishes & Greetings from WOW!
              </h2>
              <p class="blogscard15-description-area-of-main--text">
                WOW Electronic Transport Services is a group of an electronic
                services company that provides transportation services in
                different modes to assist people in going to their desired
                places. WOW is ready to start its field operation in different
                countries and regions around the world like USA, UK, France, and
                providing electronic transport services in UAE. Our future
                vision is to provide and support in the growth of space
                traveling. WOW will introduce autonomous and driverless car in
                future, those are would be work on AI algorithm with an
                affiliated technologies.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                Therefore, WOW is very privileged in wishing the very best good
                luck to UAE Space Agency and The Mohammad Bin Rashid Space
                Center for the launch of UAE Mars Orbiter Mission “Hope”.
                Feeling an honor that UAE to be the first country to enter and
                take the Arab world into the space competition. WOW sets his
                desires and support in the success of this space mission and
                future vision of the human mission on Mars. WOW aspire the aims
                of utmost different electronic traveling companies and greets
                their future vision and missions.
              </p>
              <h2 class="blogscard15-description-area-of-main-headings">
                Why Hope mission launched?
              </h2>
              <p class="blogscard15-description-area-of-main--text">
                Hope is about to attain Mars in February 2021, the 12 months the
                UAE celebrates 50 years on the grounds that its formation.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                A Hit Hope venture would be a first-rate step for the
                oil-dependent economy seeking a future in space.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                Hope will be sporting three devices to have a look at the upper
                ecosystem and monitor climate exchange and scheduled to circle
                the Red Planet for at the least two years.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                Emirates Mars Mission venture director Omran Sharif said the
                challenge would offer a complete view of the Martian environment
                at some stage in distinct seasons for the primary time. Two
                different Mars missions are deliberate in the coming days with
                the aid of America and China.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The three almost simultaneous launches are not any coincidence:
                The timing is dictated by the hole of a one-month window in
                which Mars and Earth are in perfect alignment on the identical
                side of the sun, which minimizes journey time and gasoline use.
                Such a window opens best once every 26 months.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                Japan has its personal Mars mission undertaking planned in 2024.
              </p>
              <h2 className="blogscard15-description-area-of-main-headings">
                What is Hope Mars Mission?
              </h2>
              <p className="blogscard15-description-area-of-main--text">
                The $200 million spacecraft, also known as the Emirates Mars
                Mission, represents the Arab region's first foray into the
                interplanetary area and a bold step for the UAE, which first
                participated in a satellite launch in 2009 via collaborating
                with a South Korean company.
              </p>
              <p className="blogscard15-description-area-of-main--text">
                If all is going well, Hope will spend about seven months
                traveling to Mars, arriving in early 2021. Then, the spacecraft
                will orbit over the Red Planet's equator for a complete Mars yr.
                (nearly two Earth years) studying the planet's climate and
                environment.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The spacecraft is one of three sure for Mars inside the next few
                weeks, throughout the precious orbital alignment while the Red
                Planet is easier to attain from Earth: China's Tianwen-1 lander
                and NASA's Mars 2020 Perseverance rover also are at the launch
                docket later this month.{" "}
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The first Arab area challenge to Mars, an unmanned probe dubbed
                "Hope", blasted off from Japan on Monday, in a bid to show extra
                approximately the environment of the Red Planet.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The Japanese rocket wearing the probe advanced with the aid of
                using the United Arab Emirates (UAE) lifted off from the
                Tanegashima Space Centre in southern Japan proper on timetable
                at 6: Fifty-eight am neighborhood time (2158 GMT Sunday).
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The release of the probe, recognized as "Al-Amal" in Arabic, had
                two times been behind schedule due to awful climate, however,
                the Monday liftoff seemed clean and successful. Almost precisely
                one hour later, a stay feed confirmed humans applauding with
                inside the Japanese manipulate room because the probe correctly
                detached.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                "The release car trajectory turned into done as deliberate and
                separation of the Hope spacecraft turned into confirmed," rocket
                producer Mitsubishi Heavy Industries stated. In Dubai, the
                release turned into met with rapturous excitement, with the Burj
                Khalifa -- the sector's tallest skyscraper -- lit up hours
                earlier than liftoff with a symbolic 10-2d countdown in
                anticipation. "This challenge is a vital milestone for the UAE
                and the vicinity," stated Yousuf Hamad AlShaibani, director of
                the UAE's Mohammed bin Rashid Space Centre, at a post-release
                press convention in Japan. "It has already stimulated hundreds
                of thousands of children locally to dream huge and paintings
                difficult to obtain what appears to be impossible," he stated.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The Emirati venture is one in every of 3 racing to Mars, which
                include Tianwen-1 from China and Mars 2020 from the United
                States, taking gain of a duration whilst the Earth and Mars are
                nearest.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                In October, Mars may be a relatively near 38.6 million miles
                (62.07 million kilometers) from Earth, consistent with NASA.
                "Hope" is anticipated to go into Mars orbit with the aid of
                using February 2021, marking the fiftieth anniversary of the
                unification of the UAE, an alliance of 7 emirates.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                Unlike the two different Mars ventures scheduled for this year,
                it will now no longer land at the Red Planet, however rather
                orbit it for an entire Martian year, or 687 days. While the goal
                of the UAE's challenge is to offer a complete photo of the
                climate dynamics, the probe is a basis for a far larger goal --
                constructing a human agreement on Mars with inside the
                subsequent a hundred years. Dubai has employed architects to
                assume what a Martian metropolis may appear like and construct
                it in its wilderness as "Science City", at a value of around
                $one hundred thirty-five million dollars.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The UAE additionally desires the venture to function a supply of
                ideas for Arab children, in a vicinity too frequently wracked
                with the aid of using sectarian conflicts and financial crises.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                'Pride, desire, peace'
              </p>
              <p class="blogscard15-description-area-of-main--text">
                On Twitter, the UAE's authorities declared the probe release a
                "message of pride, desire, and peace to the Arab vicinity,
                wherein we renew the golden age of Arab and Islamic
                discoveries." Several dozen probes -- maximum of them American
                -- have prompt for the Red Planet for the reason that 1960s.
                Many by no means made it that far or did not land.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The power to discover Mars flagged until the affirmation much
                less than 10 years in the past that water as soon as flowed on
                its surface. Omran Sharaf, the challenge's venture manager, has
                stated the Hope probe will provide a unique attitude on the
                planet.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                "What is precise approximately this challenge is that for the
                primary time the medical network round the sector could have a
                holistic view of the Martian environment at distinctive
                instances of the day at distinctive seasons," Sharaf informed a
                pre-release briefing.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                Hope is anticipated to start transmitting statistics returned to
                Earth in September 2021, with its statistics to be had for
                scientists round the sector to study.
              </p>
              <p class="blogscard15-description-area-of-main--text">
                The UAE already has nine functioning satellites in Earth orbit,
                with plans to release every other eight in the coming years. In
                addition, in September, it dispatched the primary Emirati into
                the area on a challenge to the International Space Station.
              </p>
              <div class="description_of_card_of_blogscard15_bottom_area">
                <div class="description_of_card_of_blogscard15_bottom_area_previous">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fas fa-reply" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard15_bottom_area_next">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fa fa-mail-forward" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost15;