import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import ScrollAnimation from "react-animate-on-scroll";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
  Form,
  Card,
  Container,
  Carousel,
  Navbar,
  Button,
  Header,
  NavDropdown,
  Nav,
  FormLabel,
  FormControl,
  Col,
  Row,
  View,
  Dropdown,
  Media,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  NavLink,
  Route,
  browserhistory,
} from "react-router-dom";

class Holidayblog extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dropDown: false,
      dropDownPractice: false,
      dateblog: new Date().toLocaleString(),
    };
  }

  getDate = () => {
    var dateblog = { currentDate: new Date().toLocaleString() };

    this.setState({
      dateblog: dateblog,
    });
  };

  blogcard1button = () => {
    document.getElementById("blog-card1").style.display = "block";
    document.getElementById("blog-card2").style.display = "none";

    window.scrollTo({ top: 1600, behavior: "smooth" });
  };

  blogcard2button = () => {
    document.getElementById("blog-card1").style.display = "none";
    document.getElementById("blog-card2").style.display = "block";

    window.scrollTo({ top: 1600, behavior: "smooth" });
  };

  headings6privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "block";
    document.getElementById("headings7-privacypolicy").style.display = "none";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "block";
    document.getElementById("margin-right-outline7").style.display = "none";
    document.getElementById("margin-right-outline8").style.display = "none";
  };

  headings7privacypolicy = () => {
    document.getElementById("headings1-privacypolicy").style.display = "none";
    document.getElementById("headings2-privacypolicy").style.display = "none";
    document.getElementById("headings3-privacypolicy").style.display = "none";
    document.getElementById("headings4-privacypolicy").style.display = "none";
    document.getElementById("headings5-privacypolicy").style.display = "none";
    document.getElementById("headings6-privacypolicy").style.display = "none";
    document.getElementById("headings7-privacypolicy").style.display = "block";
    document.getElementById("headings8-privacypolicy").style.display = "none";

    document.getElementById("margin-right-outline1").style.display = "none";
    document.getElementById("margin-right-outline2").style.display = "none";
    document.getElementById("margin-right-outline3").style.display = "none";
    document.getElementById("margin-right-outline4").style.display = "none";
    document.getElementById("margin-right-outline5").style.display = "none";
    document.getElementById("margin-right-outline6").style.display = "none";
    document.getElementById("margin-right-outline7").style.display = "block";
    document.getElementById("margin-right-outline8").style.display = "none";
  };

  alertshow = () => {
    alert("asdasdad");
  };

  render() {
    const scrollToblog = () => window.scrollTo({ top: 0 });
    return (
      <div>
        <div className="holiday-blog-post-wow-holiday-our-container">
          <div class="holiday-blog-post-our-content">
            <div class="MuiContainer-root MuiContainer-maxWidthLg">
              <div class="holiday-blog-post-wow-holiday-home-our-story">
                <div class="holiday-blog-post-wow-holiday-our-team-heading">
                  Wow Holiday Blogs
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*        

        <div>
          <header className="header-image-in-new-design-transport">


   <div className="logo-inside-header-of-new-design-page-transport">
     <img className="image-logo-inside-header-of-new-design-page-wow-transport" src="IMAGES/WOWholiday-iconlogo.png"></img>
   </div>


   <div className="logo-inside-header-of-new-design-page--wow-transport">
     <img className="image-logo-inside-header-of-new-design-page-transport" src="IMAGES/city-images/wow-gallery/tour-logo.png"></img>
   </div>


   <div className="text-box-inside-background-image-of-new-design-transport">
  <h1 className="heading-primary-transport">
 



    <span className="heading-primary-main-transport">
      WOW Holiday

    </span>
    <span className="heading-primary-sub-transport">
      Transport,Services & Team

    </span>
  </h1>

  <div class="holiday-transport-wow-holiday-our-team-heading-below-text">
                  We provide the best transport &services that you need during your trip. Our team is very
                   cooperative, provide good hospitality and keep you entertain all the way. 
                </div>
  {/* <Button variant="outline-light" id="scrolltoup" onClick={this.toscrolltoup} className="button-in-new-design-gallery" > Discover Our Tours</Button> */}
        {/* 
  </div>
  
  <toggle>

  </toggle>



          </header></div> */}

        {/* 
          <Container>


          <div class="u-center-text u-margin-bottom-second-heading-transport">
                  <div  className="heading-secondary-section-transport-holiday ">
                      WOW Holiday Transport & Services
                  </div>
                  </div>


          </Container>

        */}

        <div class="blog_post1">
          <Container>
            <center className="main-heading-wow-blog">
              <h3 class="blog_post">WOW Holiday Blogs</h3>
              <div className="conten-icon-wow-blog">
                <i className="fab fa-facebook conten-icon-blog"></i>
                <i className="fab fa-instagram conten-icon-blog"></i>
                <i className="fab fa-linkedin-in conten-icon-blog"></i>
                <i className="fab fa-twitter conten-icon-blog"></i>
              </div>
            </center>

            <div class="recent_blogs_area">
              <div class="jss8">
                <Row>
                  <Col xl={8} lg={8} md={7} sm={8} id="headings1-privacypolicy">
                    <div>
                      <img src="IMAGES/city-images/wow-blog/blog-col4.jpg"></img>

                      <div class="offset-top-10">
                        <div class="text-primary-wow-blog-image text-bold post-default-title">
                          Truckers to Seek HOS Waiver to Ease Calif
                        </div>
                      </div>

                      <div class="offset-top-15">
                        <div class="post-meta">
                          <ul class="list-inline-date-written-behind-image-blog-holiday">
                            <li>
                              <span class="far fa-calendar blog-holiday-behind-image-icon xxs-icon text-gray text-middle"></span>
                              <time
                                class="text-gray inset-left-5 text-middle-blog-holiday"
                                datetime="2018-01-01"
                              >
                                September 16, 2018, 12:20
                              </time>
                            </li>
                            <li>
                              <span class="far fa-user blog-holiday-behind-image-icon xxs-icon text-gray text-middle-blog-holiday"></span>
                              <a
                                class="text-gray inset-left-10 text-middle-blog-holiday"
                                href="blog-post-page.html"
                              >
                                Admin
                              </a>
                            </li>
                            <li>
                              <span class="fa fa-comment blog-holiday-behind-image-icon xxs-icon text-gray text-middle-blog-holiday"></span>
                              <a
                                class="text-gray inset-left-5 text-middle-blog-holiday"
                                href="blog-post-page.html"
                              >
                                2
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Cupiditate beatae neque a aperiam odit tempora
                        obcaecati officiis laboriosam, quaerat maxime. Quaerat
                        veniam sunt obcaecati. Earum quia iure laboriosam eos
                        quibusdam!Lorem, ipsum dolor sit amet consectetur
                        adipisicing elit. Cupiditate Quaerat veniam sunt
                        obcaecati. Earum quia iure laboriosam eos quibusdam!...
                      </p>
                    </div>
                  </Col>

                  <Col
                    className="recent_blogs_area_col"
                    xl={4}
                    lg={4}
                    md={5}
                    sm={4}
                  >
                    <div>
                      <h4
                        class="blogs-description-area-of-sub-headings"
                        style={{ color: "#f87370", marginTop: "30px" }}
                      >
                        <center>
                          <i
                            class="fa fa-sliders slider-icon-blog-wow"
                            aria-hidden="true"
                          ></i>
                          Recent Blogs
                        </center>
                      </h4>

                      <div
                        className="MuiTabs-root-blog jss9-blog MuiTabs-vertical-blog MuiTabs-scrollable-blog"
                        aria-label="Vertical tabs example"
                      >
                        <div
                          className="MuiTabs-scrollable-blog"
                          style={{
                            width: "99px",
                            height: "99px",
                            position: "absolute",
                            top: "-9999px",
                            overflow: "scroll",
                          }}
                        ></div>

                        <div
                          className="MuiTabs-scroller-blog"
                          // style={{ marginBottom: "0px" }}
                        >
                          <div
                            className="MuiTabs-flexContainer MuiTabs-flexContainerVertical"
                            role="tablist"
                          >
                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Travel Hacks & Tips
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog1.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        Going For A Vacation! But Don’t Know
                                        What To Pack? Here Are Some Packing
                                        Hacks And Tips For You…
                                      </div>
                                      <Link
                                        to="/blogpost1"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Events & Stories
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog2.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        It can be easy to focus all your
                                        expectations on ticking off the ‘Big 5’
                                        when planning a safari holiday but some
                                        of the other options offer the chance to
                                        experience a different, hidden magic of
                                        Africa which can be
                                      </div>
                                      <Link
                                        to="/blogpost2"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  WOW News & Trends
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog3.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        Looking out for the WOW Community during
                                        an emergency. We are focused on your
                                        wellbeing. That is the reason we are
                                        unifying and enumerating
                                      </div>
                                      <Link
                                        to="/blogpost3"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>

                                {/* <Media>
  <img
    width={110}
    height={130}
    className="mr-3"
    src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog3.jpg"
    alt="Generic placeholder"
  />
  <Media.Body>
  <div className="wow-blog-text-for-practice">
                                        It can be easy to focus all your
                                        expectations on ticking off the ‘Big 5’
                                        when planning a safari holiday but some
                                        of the other options offer the chance to
                                        experience a different, hidden magic of
                                        Africa which can be
  </div>
  <Button
                                        //  variant="outline-dark"
                                        className="button-wow-blog-read-more"
                                      >
                                        Read Full Story
                                      </Button>
  </Media.Body>
</Media> */}
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Events & Stories
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog4.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        Cities best for photographers lovers It
                                        doesn't matter if you're a pro
                                        photographer or amateur snapper, you'll
                                        have to bring an extra memory card or
                                        two to capture the beauty
                                      </div>
                                      <Link
                                        to="/blogpost4"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Wow News & Trends
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog5.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        When it comes to shopping every one gets
                                        so excited especially girls. Even some
                                        people are just shopping addicts. How
                                        can you go shopping when you don’t have
                                        your own conveyance?
                                      </div>
                                      <Link
                                        to="/blogpost5"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Wow News & Trends
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog6.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        Are you looking for a wow travel on
                                        budget and enjoyment with your family?
                                        Here are some sujjested Caribbean
                                        islands by wow for every type of
                                        traveler.
                                      </div>
                                      <Link
                                        to="/blogpost6"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Events & Stories
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog7.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        Are you one of those conscious
                                        personalities who need every little
                                        detail touches the maximum height of
                                        perfection?
                                      </div>
                                      <Link
                                        to="/blogpost7"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  News & Trends
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog8.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        8 Hard-to-Plan Dream Trips to Research
                                        at Home While You Have the Time.. Visit
                                        these places to have fun of your life
                                        suggested by wow.
                                      </div>
                                      <Link
                                        to="/blogpost8"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            <div className="recent_list_detail_items45 media-blog">
                              <div class="MuiTab-wrapper-blog">
                                <div className="heading-of-blog-muitab-holiday">
                                  Events & Travel Stories
                                </div>

                                <div class="date-card-for-blogs">
                                  <span className="text-written-date">
                                    Ellie CuthbertCategories
                                  </span>
                                  <p> {this.state.dateblog}</p>
                                </div>
                                <Row>
                                  <Col xs={5} lg={5} md={5} sm={12} xs={12}>
                                    <img
                                      className="image-blog-tab-wow-holiday"
                                      src="IMAGES/city-images/wow-blog/blog-tab/bg-image-blog9.jpg"
                                    ></img>
                                  </Col>

                                  <Col xs={7} lg={7} md={7} sm={12} xs={12}>
                                    <div>
                                      <div className="wow-blog-text-for-practice">
                                        No matter your age, spending the night
                                        among the treetops is sure to rekindle
                                        fond childhood memories of imagination
                                        and adventure.
                                      </div>
                                      <Link
                                        to="/blogpost9"
                                        onClick={scrollToblog}
                                        //  variant="outline-dark"
                                        className="button-text-wow-blog-card-holiday-muitab"
                                      >
                                        Read Full Story
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>

                            {/* <span
                          style={{ top: "0" }}
                          id="margin-right-outline1"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "48px", display: "none" }}
                          id="margin-right-outline2"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "96px", display: "none" }}
                          id="margin-right-outline3"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "144px", display: "none" }}
                          id="margin-right-outline4"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "192px", display: "none" }}
                          id="margin-right-outline5"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span>

                        <span
                          style={{ top: "240px", display: "none" }}
                          id="margin-right-outline6"
                          className="jss34-pivacypolicy jss36-pivacypolicy MuiTabs-indicator jss37-pivacypolicy"
                        ></span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <Row>
              <div class="l-grid l-grid--4">
                <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        {/* <img src="/svg/SMALLGROUPS_CIRCLE.svg" alt=""/> */}
                        <i className="fas fa-users user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-blog">
                        <b>SMALL GROUPS</b>
                      </div>
                      <div class="subTitle-text-wow-blog">
                        With a maximum of 16 people, our small group tours make
                        for a far better travel experience.
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        {/* <img src="/svg/CUSTOMTOURS_CIRCLE.svg" alt=""/> */}
                        <i className="fas fa-tasks user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-blog">
                        <b>OPTIONAL ACTIVITIES</b>
                        <br />
                      </div>
                      <div className="subTitle-text-wow-blog">
                        Experience Nature your way by choosing from over 70
                        optional activities to add to your tour.
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <div class="l-best-nz__tile">
                                        <div class="l-best-nz__tile-icon">
                                          <span 
                                          class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                                          style={{color: 'transparent', display: 'inline-block'}}>
                                             <img src="/svg/NZRATED_CIRCLE.svg" alt=""/>
                                             </span></div>
                                             <div>
                                               <div class="subTitle">
                                                 <b>FLEXI-BOOKINGS</b><br/>
                                                 </div> Swap tours or dates free of charge any time up until 45 days before your tour starts.</div>
                                                 </div> */}

                <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        {/* <img src="/svg/culture-1.svg" alt=""/> */}
                        <i className="fab fa-angellist user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-blog">
                        <b>CULTURAL EXPERIENCES</b>
                        <br />
                      </div>
                      <div class="subTitle-text-wow-blog">
                        Every tour includes experiences to teach you about our
                        unique Māori culture.
                      </div>
                    </div>
                  </div>
                </Col>

                <Col xl={3} lg={3} md={3} sm={12} xs={12}>
                  <div class="l-best-nz__tile">
                    <div class="l-best-nz__tile-icon">
                      <span
                        class="lazy-img-wrapper lazy-load-image-background opacity lazy-load-image-loaded"
                        style={{
                          color: "transparent",
                          display: "inline-block",
                        }}
                      >
                        {/* <img src="/svg/LOCALGUIDES_CIRCLE.svg" alt="" /> */}
                        <i className="fas fa-hiking user-icon-blog-wow-holiday"></i>
                      </span>
                    </div>
                    <div>
                      <div class="subTitle-wow-blog">
                        <b>100% WoW</b>
                        <br />
                      </div>{" "}
                      <div class="subTitle-text-wow-blog">
                        We’re Tour owned &amp; operated and our guides promise a
                        100% authentic pure experience.
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>

          <Container>
            <center className="famous-blog-heading-wow-blog">
              <h3 class="famous-heading-blog_post">WOW Holiday Famous Blogs</h3>
            </center>
          </Container>

          <Container id="blog-card1">
            <Row className="margin-in-rows-cards-blog-holiday">
              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card1.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            How to scale a new market from scratch while
                            supporting travel suppliers
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;September 16, 2018, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Going For A Vacation! But Don’t Know What To Pack?
                          Here Are Some Packing Hacks And Tips For You…
                        </div>

                        <center>
                          <Link
                            to="/blogpost1"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card2-2-small.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            Events & Stories: How to travel on a budget: 8 ways
                            to save money on your next trip
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;March 16, 2019, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                               Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Hands up if you want to take a vacation, but your
                          account balance has other ideas. Well, we’re here to
                          show you that you don’t need a bank account that’s
                          bursting
                        </div>

                        <center>
                          <Link
                            to="/blogpost2"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/blog3-small.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            WOW NEWS & TRENDS BLOGS
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;Februry 12, 2020, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Looking out for the WOW Community during an emergency.
                          We are focused on your wellbeing. That is the reason
                          we are unifying and enumerating
                        </div>

                        <center>
                          <Link
                            to="/blogpost3"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="margin-in-rows-cards-blog-holiday">
              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card4.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            WOW Events & Stories blogs: Cities best for
                            photography
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;September 16, 2018, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                          <span className="black-wow-blog-holiday">
                            <b> |</b>{" "}
                          </span>
                          <span class="u_t_gray_9-wow-blog-holiday">
                            <i class="fas fa-comment"></i>2
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Cities best for photographers lovers It doesn't matter
                          if you're a pro photographer or amateur snapper,
                          you'll have to bring an extra memory card or two to
                          capture the beauty
                        </div>

                        <center>
                          <Link
                            to="/blogpost4"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card5.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            News & Trends: WOW for Shopping
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;September 26, 2019, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                          <span className="black-wow-blog-holiday">
                            <b> |</b>{" "}
                          </span>
                          <span class="u_t_gray_9-wow-blog-holiday">
                            <i class="fas fa-comment"></i>2
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          When it comes to shopping every one gets so excited
                          especially girls. Even some people are just shopping
                          addicts. How can you go shopping when you don’t have
                          your own conveyance?
                        </div>

                        <center>
                          <Link
                            to="/blogpost5"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card6.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            News & Trends: WOW Travelling to Island
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;September 16, 2018, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        <div className="text-inside-blog-wow-holiday-card">
                          Are you looking for a wow travel on budget and
                          enjoyment with your family? Here are some sujjested
                          Caribbean islands by wow for every type of traveler.
                        </div>

                        <center>
                          <Link
                            to="/blogpost6"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="margin-in-rows-cards-blog-holiday">
              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card7.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            Events & Stories: WOW for Occasions
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;Februry 02, 2020, 6:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Are you one of those conscious personalities who need
                          every little detail touches the maximum height of
                          perfection?
                        </div>

                        <center>
                          <Link
                            to="/blogpost7"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card8.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            Wow News & Trends
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;September 16, 2018, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                          <span className="black-wow-blog-holiday">
                            <b> |</b>{" "}
                          </span>
                          <span class="u_t_gray_9-wow-blog-holiday">
                            <i class="fas fa-comment"></i>2
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          8 Hard-to-Plan Dream Trips to Research at Home While
                          You Have the Time.. Visit these places to have fun of
                          your life suggested by wow.
                        </div>

                        <center>
                          <Link
                            to="/blogpost8"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card9.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            Wow Events & Travel Stories: Magical Tree house
                            stays
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;September 16, 2018, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          No matter your age, spending the night among the
                          treetops is sure to rekindle fond childhood memories
                          of imagination and adventure.
                        </div>

                        <center>
                          <Link
                            to="/blogpost9"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <Container id="blog-card2" style={{ display: "none" }}>
            <Row className="margin-in-rows-cards-blog-holiday">
              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card10.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            Wow: News & Trends
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;December 29, 2019, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Feel Inspired with These Amazing Fresh Air Escapes.
                          VIRTUAL HISTORY AND MUSEUM TOURS: experience this joy
                          through virtual history tours
                        </div>

                        <center>
                          <Link
                            to="/blogpost10"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card11.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            WOW NEWS & TRENDS BLOG
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;March 06, 2020, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Several Incredible Stays to Make Your Working From
                          “Home” Fantasies a Reality: As remote working becomes
                          an increasingly viable option for more people
                        </div>

                        <center>
                          <Link
                            to="/blogpost11"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card12.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            Wow: Travel hacks & tips
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;25 July 2020 / Dubai &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                          Safe and Warm Welcome as First Tourists Land in Dubai.
                          It was announced that airports in Dubai will welcome
                          tourists from the 7th of July.
                        </div>

                        <center>
                          <Link
                            to="/blogpost12"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="margin-in-rows-cards-blog-holiday">
              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card13.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            WOW News & Trends
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;21 july 2020 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        {/* <div class="offset-top-15">
                    <div class="post-meta">
                      <ul class="list-inline">
                        <li><span class="icon icon-xxs fa-calendar text-gray text-middle"></span>
                          <time class="text-gray inset-left-5 text-middle" datetime="2018-01-01"></time>
                        </li>
                        <li><span class="icon icon-xxs fa-user text-gray text-middle"></span><a class="text-gray inset-left-10 text-middle" href="blog-post-page.html"></a></li>
                        <li><span class="icon icon-xxs fa-comment text-gray text-middle"></span><a class="text-gray inset-left-5 text-middle" href="blog-post-page.html"></a></li>
                      </ul>
                    </div>
                  </div> */}

                        <div className="text-inside-blog-wow-holiday-card">
                        Dubai Travel Blog, Guide and Where to stay in Dubai.
                        Enjoy the enviroment and beautiful places at dubai.
                        </div>

                        <center>
                        <Link
                            to="/blogpost13"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card14.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                            WOW: Travel hacks and tips
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;July 23, 2020, 12:20 &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        <div className="text-inside-blog-wow-holiday-card">
                        Dubai Travel rules and regulations, you need to know and follow
                        </div>

                        <center>
                        <Link
                            to="/blogpost14"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>

              <Col xs={4} lg={4} md={4} sm={6} xs={12}>
                <div className="card-border-blog-holiday">
                  <div className="card-for-practice-blog-holiday">
                    <a>
                      <img
                        className="card-img-top-for-practice-blog-holiday"
                        src="IMAGES/city-images/wow-blog/blog-card/card15.jpg"
                        alt="Card image cap"
                      />

                      <div className="card-body-blog-holiday">
                        <li>
                          <p className="card-text-for-practice-blog-holiday">
                          WOW: NEWS & TRENDS
                          </p>
                        </li>
                        <li class="t12-wow-blog-holiday t_gray-blog-holiday star_box-blog-holiday">
                          <span class="">
                            <span class="m_star_v2-blog-holiday">
                              <i class="far fa-calendar"></i>
                              <span
                                class="t14-blog-holiday"
                                datetime="2018-01-01"
                              >
                                &nbsp;JULY 19 2020 / Dubai &nbsp;
                              </span>
                            </span>
                            <span class="u_t_gray_9-wow-blog-holiday">
                              <i class="fas fa-user"></i>
                              Admin
                            </span>
                          </span>
                        </li>

                        <div className="text-inside-blog-wow-holiday-card">
                        Successful Launch of UAE Mars Orbiter Hope: 2020 
                        WOW Launches Mars Orbiter Hope
                        </div>

                        <center>
                        <Link
                            to="/blogpost15"
                            onClick={scrollToblog}
                            className="button-text-wow-blog-card-holiday"
                          >
                            Learn more &rarr;
                          </Link>
                        </center>
                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          {/* <CookieConsent
            location="bottom"
            buttonText="Sure man!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={50}
          >
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ fontSize: "10px" }}>
              This bit of text is smaller :O
            </span>
          </CookieConsent> */}

          <Container>
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={this.blogcard1button}
                className="button-blog-cards-wow-holiday"
              >
                1
              </Button>

              <Button
                onClick={this.blogcard2button}
                className="button-blog-cards-wow-holiday"
              >
                2
              </Button>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}
export default Holidayblog;
