import React, { Component, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { FaBeer } from "react-icons/fa";

import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Col,
} from "react-bootstrap";

import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Route,
  Button,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost7/blogpost7.scss";

class Blogpost7 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false
    };
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    return (
      <div>
        <div className="td-post-header-holder-blogcard7 td-image-gradient-blogcard7">
          <div className="td-post-featured-image-blogcard7">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard7"
              src="IMAGES/city-images/wow-blog/additional-cards/blog7/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard7">
              <ul className="td-category-blogcard7">
                <li className="entry-category-blogcard7">
                  <a
                    target="blank"
                    href="https://expatexplore.com/blog/europes-most-popular-festivals-and-cultural-events/"
                  >
                    Events in Europe
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard7">
             WOW for travelling to best events in europe
              </h1>

              <h4 className="entry-title-two-blogcard7">
                Looking out for the Best Events/Occasions to visit in europe traveler...
              </h4>

              <div className="td-module-meta-info-blogcard7">
                <span className="td-post-date-blogcard7">
                  <time
                    className="entry-date-blogcard7 updated td-module-date-blogcard7"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    02 Feb 2020
                  </time>
                </span>

                <div className="td-post-views-blogcard7">
                  <i className="fa fa-eye view-icon-views-blogcard7"></i>
                  <span className="td-nr-views-10968">19235</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard7">
            <div className="heading-secondary-uk-section-wow-blogscard7 ">
              WOW Events & Stories
            </div>
          </div>
        </Container>

         <Container>
          <p class="blogscard7-description-area-of-main--text-above">
          Want to travel in Europe and know about the best events? Please find 
          our selection of the best European events.
          By now, you’ve probably gorged yourself silly on gingerbread and Glühwein at Germany’s
           markets and are wondering what to do next. (No? Is it just us who drank too much Glühwein in 
           December? Oh well. It was worth it.) Never fear: the fun doesn’t stop in Europe just because it’s
            below freezing outside. From an epic New Year’s Eve party in Scotland to a citrus-centered 
            fête in France, 
          here are seven cool winter festivals in Europe you need to add to your bucket list.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard7-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog7/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard7-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog7/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard7-description-area-of-main--text-above">
          As always, the event that all sweet food enthusiasts and history lovers most eagerly await returns: 
          from the 18th to the 26th of November 2020 the streets and squares of Cremona will be brought to 
          life by events, performances and food tastings in the event of the Festa del Torrone. More than 
          250 initiatives will be taking place in a magical atmosphere, 
          rich in history and tradition: games, entertainment, cultural events and food and wine events.
          </p>

          <p class="blogscard7-description-area-of-main--text-above">
          Ladies and gentlemen, 2021 is almost upon us and there is so much excitement ahead! Planning 
          to travel abroad in the coming year? Excellent! That means you have plenty of time to tailor 
          your trip to include a few 
          of the famous European festivals. Here are our top picks of annual Europe events in 2021:
          </p>
        </Container>
        <br />









        <Container>


<div className="u-center-text u-margin-bottom-second-heading-wow-blogcard7">
        <div  className="heading-secondary-section-wow-blogcard7 ">
        If you are thinking about traveling to an event in europe, then you should know:
        </div>
        </div>        

</Container>

        <Container>
<div id="accordion-5ed01b058660a" className="accordion indicator-plus accordion-white mb-3" role="tablist">
<div className="card-blogcard7 bar">
  <div className="card-blogcard7 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-0" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link blogcard7-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-0">
        <span role="heading" aria-level="0">You get to walk amidst living history....
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-0" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-0" 
    role="tabpanel">
      <div className="card-body-blogcard3">
        <Row>
        <Col lg={6}>
        <p style={{padding:'3rem'}}>
          Civilisation in Europe has been around for many years and the continent has the buildings to prove
           it. The cities are old, REALLY old. Some of the buildings date back around 2000 years, especially
            in places like Rome. Take note of the buildings around you, visit the museums and take loads of 
            pictures! These cities are living, breathing reminders of the past. They are filled with stories
             just waiting to be heard. Europe culture has so much to offer. You can walk up the Acropolis 
             and think as Socrates once did. Or, you could get lost along the cobblestone walkways of 
             Prague’s Old Town. Why not hike among the UNESCO-accredited 
             Italian fishing villages of Cinque Terre in 
          Italy? Wherever you choose to go, you’ll enjoy a historical experience like no other.
        </p>
        </Col>

        <Col lg={6}>
        <div className="wow-news-card1-work-page-top-image-blogscard7-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog7/collapse1.jpg"
                />
              </div>
        </Col>
        </Row>
      </div>
  </div> 
  </div>
  </div>





  <div className="card-blogcard7 bar">
  <div className="card-blogcard7 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-1" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link blogcard7-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-1">
        <span role="heading" aria-level="0">What’s the best way to pay for hotels, meals, transportation,
         during my upcoming trip to Europe?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-1" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-1" 
    role="tabpanel">
      <div className="card-body-blogcard3">
        <p style={{marginBottom:'1rem'}}>
          I like to have some euros (or the currency of the country I’m visiting) in my pocket when I arrive
           at the airport, just in case I need a quick cup of coffee or want to jump in a cab without heading
            to an ATM first. Some banks have them in stock — Wells Fargo, for instance, offers euros at many 
            of its branches. But waiting until you arrive in Europe will work. Use a debit card rather than 
            a credit card to avoid cash-advance ATM fees, and use 
          an ATM associated with a bank. Avoid currency-exchange booths, which will cost more.
       </p>

       <p>
       Pay for most big-ticket items, such as hotels, with a credit card; Visa and MasterCard are 
       the most widely accepted. Many smaller restaurants, shops, and even some rural hotels do not
        take credit cards in Europe, so keep enough cash on hand for those. Don’t 
       fall for “we take dollars,” as the merchant’s exchange rate will not be advantageous.
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-blogcard7 bar">
  <div className="card-blogcard7 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-2" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link blogcard7-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-2">
        <span role="heading" aria-level="0">WHY VISIT EUROPE?
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-2" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-2" 
    role="tabpanel">
      <div className="card-body-blogcard3">
        <p>Thanks to centuries of history, popular movies and legendary literature, the allure of Europe’s
           landscapes and cultures has been romanticized for ages. Many people dream of turning the European 
          vacation in their hearts into reality. Here’s why now is better than ever to visit Europe.<br/>
          For years, traveling across Europe required frustrating border stops. Now, many EU countries
           are part of the Schengen Area. This agreement allows travelers to cross borders freely with a 
           single passport stamp on arrival, and another upon departure 
           from the continent. Going from Denmark’s endless 
          coastline to Romania’s legendary Transylvanian castles just got a whole lot easier.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-blogcard7 bar">
  <div className="card-blogcard7 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-3" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link blogcard7-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-3">
        <span role="heading" aria-level="0">Tell Us: Do you love festivals? 
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-3" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-3" 
    role="tabpanel">
      <div className="card-body-blogcard3">
        <p>
        From multi-day music festivals to gay pride events, film festivals, cultural events and art 
        shows — festivals are a pervasive part of the European summer experience. Whether you’re camping
         on-site or it’s happening downtown during daytime, Europe has more than a few festivals. 
         Berlin (obviously one of my favorite European cities) hosts countless festivals during the 
         summer. And if you must visit, make sure you visit when there’s a festival on. Yes, the travel
          experience will be slightly different than on any other occasion. 
          But there’s something special when traveling for festivals. The atmosphere is different, the people 
        are friendlier, the destination somehow feels more alive, more energetic, more…fun.
       
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-blogcard7 bar">
  <div className="card-blogcard7 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-4" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link blogcard7-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-4">
        <span role="heading" aria-level="0">Travellers should wear facemasks during travel
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-4" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-4" 
    role="tabpanel">
      <div className="card-body-blogcard3">
        <p>Because COVID-19 is spreading in the United Arab Emirates and abroad, CDC recommends that everyone 
          wear a cloth face covering over their nose and mouth when in public, including during travel. Wear a 
          cloth face covering, keep at least 6 feet of physical distance from others, and practice 
          <a 
     target="blank"
href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/prevention.html"> other 
          everyday preventive actions</a> to protect yourself and others from COVID-19.
          Avoid close contact with people who are sick, even inside your home. If possible, maintain 
          6 feet between the person who is sick and other household members.
 Cloth face coverings may 
          slow the spread of COVID-19 by helping keep people who are infected from spreading the virus 
    to others. Medical masks and N-95 respirators are for healthcare workers and other first responders, 
    as recommended by current CDC guidance. 
       </p>
      </div>
  </div> 
  </div>
  </div>




  <div className="card-blogcard7 bar">
  <div className="card-blogcard7 card-accordion ">
    <div 
    className="card-header collapsed" 
    id="accordion-5ed01b058660a-card-0" 
    data-target="#accordion-5ed01b058660a-collapse-5" 
    data-toggle="collapse" 
    role="tab" aria-expanded="false">
      <button className="card-title btn btn-link blogcard7-button-collapsible"
       data-controls="accordion-5ed01b058660a-collapse-5">
        <span role="heading" aria-level="0">You’ve got a great travelling partner all lined up....
        </span>
        </button>
        </div>
    <div 
    aria-labelledby="accordion-5ed01b058660a-card-5" 
    className="d-print-block collapse" 
    collapsed="" 
    id="accordion-5ed01b058660a-collapse-5" 
    role="tabpanel">
      <div className="card-body-blogcard3">
        <p>At Expat Explore, we want to give you the best European travelling experience possible. We’ve 
          travelled far and wide to pinpoint great accommodation venues. We’ve planned swift routes, 
          exceptional attractions and some of the best activity providers. We have great European coach 
          tour packages! They allow you to enjoy the best the continent has to offer. Get in touch to learn more 
          about all of our exciting tour options. And get ready to give in to that itching wanderlust!
       </p>

       <h2>Anticipate Travel Needs</h2>
       <ul>
        <li>
        Bring enough of your medicine to last you for the entire trip.
            </li>
        
        <li> Bring a cloth face covering to wear in public places.</li>
        <li> Prepare food and water for your trip. Pack non-perishable food in case restaurants
           and stores are closed.</li>
        
        </ul>
      </div>
  </div> 
  </div>
  </div>







  </div>

  
  </Container>
        

        <div className="gradient-back-travel-blogscard7">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard7-grid-text">
                  <div className="travel-blogscard7-page-araea-sub-headings110">
                  Europeans celebrate EVERYTHING
                  </div>

                  <div className="travel-blogscard7-page-page-text">
                  Europe is known for its one-of-a-kind festival calendar. From the historically important 
                  (e.g. the Semana Santa holy week in Seville or Carnivale Venezia) and cutting-edge (e.g. 
                  Sonar in Barcelona). To the ridiculous (e.g. the annual Cheese Rolling competition in 
                  Gloucestershire). You can enjoy the company of like-minded locals and visitors at festive
                   events throughout the year. Music, film, 
                  comedy, food & wine, beer, fashion… You name it, Europe has a festival that celebrates it!
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog7/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard7">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog7/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard7-grid-text">
                  <div className="travel-blogscard7-page-araea-sub-headings110">
                  It’s easy to get around
                  </div>

                  <div className="travel-blogscard7-page-page-text">
                  European countries have had time to perfect urban planning and get public transport under 
                  control. This makes getting around so easy! Travellers can enjoy a variety of sights in a
                   relatively short space of time.  A single day could include: artworks, shopping, famous 
                   landmarks, delicious foods, medieval architecture and relaxing. Also, the open borders 
                   of the Schengen Area make it possible to travel throughout much of Europe with a 
                   single passport stamp on entry and exit. This includes 
                  many countries within the European Union as well as cooperating countries like Switzerland.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard7">
            <div className="heading-secondary-help-community-section-wow-blogscard7 ">
              Best Europpean Events
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard7">
            <div class="description_of_card_of_blogscard7_area">
              <h2 class="blogscard7-description-area-of-main-headings">
              Carnival of Venice: The floating city’s masked fete (Italy) …
              </h2>
              <p class="blogscard7-description-area-of-main--text">
                The Carnival of Venice takes place each year. It takes place just before the six-week 
                Catholic religious observance of Lent. Locals and visitors can dress-up, wear masks and 
                parade fabulous costumes. What can you look forward to? 
                Street parades, theatrical events, masked balls and shows on the city’s famous waterways!
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog7/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog7/help-tips-grid2.jpg" />
                </Col>
              </Row>

              <h2 class="blogscard7-description-area-of-main-headings">
              St Patrick’s Day: The feast of Saint Patrick, Ireland…
              </h2>
              <p class="blogscard7-description-area-of-main--text">
              St Patrick’s Day is an official Irish holiday. It celebrates the passing of the patron saint of 
              Ireland. But, St. Paddy’s is now celebrated by many countries! What will you see? A lot of 
              shamrocks, leprechauns and green-tinged beer. In the capital of Dublin the festival takes on
               a whole new meaning. Here, Lá Fhéile Pádraig (St Paddy’s in Irish), means parades, concerts
                and even treasure hunts!
              </p>

              <h2 class="blogscard7-description-area-of-main-headings">
              Travelers are welcomed with open arms
              </h2>
              <p class="blogscard7-description-area-of-main--text">
              For most Europeans, hospitality is a way of life. No matter where you travel, you are
               sure to be welcomed with open arms. The locals will always be pointed in the direction
                of the best attractions.<br/>

Top tip! Get to know the city by grabbing a beer or a coffee at the ‘local’. This tends to be that 
little hole in the wall off the beaten track, where the locals gather.
              </p>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard7">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog7/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard7">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog7/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 className="blogscard7-description-area-of-main-headings">
              Your travels create job opportunities
              </h2>
              <p className="blogscard7-description-area-of-main--text">
              Looking for a selfless reason to hit the road? On average, 1.1 % of people employed in the 
              EU work in the tourist accommodation sector. That’s more than 7.4 million jobs! So you 
              contribute to the economy by buying 
              a trinket, eating a meal, sleeping over or taking a bus in a European country. Go you!
              </p>

              <p className="blogscard7-description-area-of-main--text">
              We’ve planned swift routes, exceptional attractions and some of the best activity providers.
               We have great European coach tour packages! They allow you to enjoy the best the continent 
               has to offer. Get in touch to learn more about all of our exciting tour options. And get 
               ready to give in to that itching wanderlust!
              </p>

              <h2 className="blogscard7-description-area-of-main-headings">
              It’s a great way to see your favorite bands.
              </h2>
              <p className="blogscard7-description-area-of-main--text">
              Oftentimes as my favorite bands become more and more popular, the possibility of seeing them 
              live diminishes. It’s some sort of cruel trend. Enter music festivals. If you plan your summer
               right, you can end up seeing your favorite bands multiple times at a fraction of the
                cost of a normal one-off ticket. With the 
              added bonus of about a gazillion other bands where you can hopefully discover new music.
              </p>

              <ul className="list-circle-type-blogscard">
                <li
                  class="blogscard7-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <p>
                    <span style={{ fontWeight: "bold" }}>
                    Something special is happening:{" "}
                    </span>
                    Every festival has that special hook to bring in visitors. Maybe it’s a food festival with
                     a star chef, or a music festival with a favorite band. I think even the world’s most 
                     boring festivals must be loved by someone!{" "}
                  </p>
                </li>
                <li
                  class="blogscard7-description-area-of-main--text"
                  style={{ marginBottom: "1em" }}
                >
                  <span style={{ fontWeight: "bold" }}>Locals are more sociable: </span>
                  If you’re visiting a city during festival season, I think you’ll find the whole city 
                  (regardless of who’s attending the festival and who’s not) a bit more friendly. When 
                  Berlin hosts its annual film festival, the whole city comes alive with red carpets. 
                  It’s a festival that even the locals love and I think it shows. The city comes alive
                   for those two weeks in winter and everyone is just that much more happy and friendly.
                </li>
                <li class="blogscard7-description-area-of-main--text">
                  <span style={{ fontWeight: "bold" }}>
                  They’re dirty. But that’s okay:{" "}
                  </span>
                  I like to consider myself a pretty hygienic person. I keep things clean and I don’t 
                  particularly enjoy the sensation of being dirty. And yet at festivals, I sometimes relish 
                  it! Spending 12 straight hours outside, sweating in the sun, drinking almost exclusively
                   beer and eating fried foods — limited showers and increasingly dirty port-a-potties; 
                   I don’t care.
                   I love it. It’s my one chance to let loose and get a bit dirty (and boy do I get dirty!).
                </li>
              </ul>

              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard7">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog7/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard7">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog7/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>

              <h2 class="blogscard7-description-area-of-main-headings">
              King’s day: Amsterdam’s all day citywide street festival (Netherlands) 
              </h2>

              <p class="blogscard7-description-area-of-main--text">
              A daylong festival in the capital of the Netherlands, it celebrates the birthday of King 
              Willem-Alexander. Locals, expats and tourists dress in orange from head to toe to honour 
              the royal Dutch family. The family visits a different municipality or town each year. Over
               600 000 to a million people head to Amsterdam for King’s day each year! The events usually
                start the night before King’s day (King’s night). They then continue till the eve of 
                King’s day. The canals are full of different coloured boats (with party on board) all
                 day or even week long. You can make your pick from at least 8 King’s day dance parties
                  in the city centre of Amsterdam! King’s day also hosts the biggest flea markets in the
                   world. Everyone, young and old, trade 2nd 
              hand goods out of hand. Go here to see more of what’s on on King’s day this year.{" "}
              </p>

              

              <div class="description_of_card_of_blogscard7_bottom_area">
                <div class="description_of_card_of_blogscard7_bottom_area_previous">
                  <h3>
                    <i class="fas fa-reply" aria-hidden="true"></i>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard7_bottom_area_next">
                  <h3>
                    <i class="fa fa-mail-forward" aria-hidden="true"></i>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost7;
