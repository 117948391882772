import React, {Component} from 'react';
import {
    Carousel,
    button,
  } from "react-bootstrap";
 import Grd from '../Grd/grid';
class Slider extends React.Component {
 
    constructor(props) {
        super(props);
         this.state = { pictures: [] };
         this.onDrop = this.onDrop.bind(this);
    }
   
    onDrop(picture) {
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }
 
    render() {
        return (
            <div >
                <Carousel className="ss">
  <Carousel.Item>
    <img
      className="blockx"
      src="IMAGES/slide1.png"
      alt="First slide"
    />

    <Carousel.Caption>
      <h3 className="carousel-text">WOW FOR RIDE</h3>
      <p>Simplify your life with comfortable and secure drive.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="blocky"
      src="IMAGES/slide2.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3 className="carousel-text">WOW FOR EARNING</h3>
      <p>Start earning today from our active riders.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="blockz"
      src="IMAGES/slide3.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3 className="carousel-text">WOW FOR SCHOOL</h3>
      <p>Set your School’s pick and drop with WOW.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="blockz"
      src="IMAGES/slide4.jpg"
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3 className="carousel-text">WOW FOR EMERGENCY</h3>
      <p>Tackle an emergency or a hurdle with WOW provided assistance and guidance.</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>


<Grd/>

            </div>
             
        );
    }
}
export default Slider;