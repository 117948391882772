import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { compose, withProps } from "recompose";
// import GoogleMapReact from 'google-map-react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  
  Col
} from "react-bootstrap";


 

  

// const responsive = {
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 3,
//     slidesToSlide: 1, // optional, default to 1.
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 3,
//     slidesToSlide: 1, // optional, default to 1.
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//     slidesToSlide: 1, // optional, default to 1.
//   },
// };

// const MyMapComponent = compose(
//     withProps({
//       googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
//       loadingElement: <div style={{ height: `100%`,  }} />,
//       containerElement: <div style={{ height: `100%` }} />,
//       mapElement: <div style={{ height: `690px`, width:'100%' }} />,
//     }),
//     withScriptjs,
//     withGoogleMap
//   )((props) =>
//     <GoogleMap
//       defaultZoom={8}
//       defaultCenter={{ lat: -34.397, lng: 150.644 }}
//     >
//       {props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} onClick={props.onMarkerClick} />}
//     </GoogleMap>
//   )
  


// const handleApiLoaded  = ({ text }) => <div>{text}</div>;
const AnyReactComponent = ({ text }) => <div>{text}</div>;
class Rider extends Component {

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

    state = {
        isMarkerShown: false,
      }
    
      componentDidMount() {
        this.delayedShowMarker()
      }
    
      delayedShowMarker = () => {
        setTimeout(() => {
          this.setState({ isMarkerShown: true })
        }, 3000)
      }
    
      handleMarkerClick = () => {
        this.setState({ isMarkerShown: false })
        this.delayedShowMarker()
      }
    
    // static defaultProps = {
    //     center: {
    //       lat: 59.95,
    //       lng: 30.33
    //     },
    //     zoom: 11
    //   };
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString().trimLeft };

    this.setState({
      date: date
    });
  }


 start=()=>{
   alert("Surprise")
 }
  render() {
    return (
      
        <div>
            <div className="logo-header">
            <center>
            <img onClick={this.start} className="ride-logo-header" src="IMAGES/wowlogo.png"></img>

            </center>
            </div>


<Container>


    
<Row>
  
    <Col xs={12} md={6} sm={7} lg={7}>
    
    
    <p><h6 className="rider-header-text">
        STATUS <span className="rider-header-text-inside"> ON WAY</span></h6></p>
        


        <div className="map-in-mobile-view-in-ride-details" >
        {/* <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBskZebOSqEXS279X2m_fH5dkbAOqY2p5U' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          />
        </GoogleMapReact> */}
      </div>

      

       {/* <Container>
        <div style={{ height: '100vh', width: '100%', marginBottom:'30px' }} className="map-content">
        <MyMapComponent
        isMarkerShown={this.state.isMarkerShown}
        onMarkerClick={this.handleMarkerClick}
      />
      </div> 
        
      </Container> */}

      
    </Col>
    






    <Col xs={12} md={6} sm={5} lg={5}>

    
    <h6 className="rider-header-text">
        TRIP DETAILS</h6>
        
        <br></br>
        
        
        <Row >
            <Col className="trip-details-column-flex" xs={1} md={1} sm={1} lg={1}>

            <img className="icon-below-trip-details" src="IMAGES/rideicon.png"/>

            </Col>
            

            <Col className="" xs={11} md={11} sm={11} lg={11} >

            <div class="ride-header-data-flex">


    <div class="ride-name">DAVID </div>
    </div>

    
        <div className="pick-up-context">PICK UP <span className="date-ride-tracking" >
        
          {this.state.date}
      </span></div>

      <div className="pick-up-contexts">Al Futtaim Motor Toyota- </div>
      <div className="pick-up-contexts">Al Futtaim Motor Toyota-Al Kheeran Dubai </div>

      <div className="pick-up-context-dropoff">DROPOFF <span className="date-ride-tracking">
        
          {this.state.date}
      </span></div>

      <div className="pick-up-contexts">WOW, Electronic </div>
      <div className="pick-up-contexts">Transport Services, 34th Floor Burj Al Salam<br></br>Tower</div>
   
     
    
  

  



    
            </Col>

            

        
            
        </Row>





        



        <h6 className="rider-header-text" >
        LEADER INFORMATION</h6>

        <Row >
            <Col className="trip-details-column-flex" xs={1} md={2} sm={1} lg={1}>

            <img className="icon-below-leader-details" src="IMAGES/iconleader.png"/>

            </Col>
            

            <Col className="" xs={11} md={10} sm={11} lg={11} >

            <div class="ride-header-data-flex">


    <div class="ride-leader-name-icons-context">LEADER<br></br> FAISAL REHMAN </div>
    </div>

 </Col>



 <Col className="leader-details-column-flex" xs={1} md={1} sm={1} lg={1} >

            <img className="icon-below-leader-details-car" src="IMAGES/iconcar.png"/>

            </Col>
            

            <Col className="" xs={11} md={11} sm={11} lg={11} style={{marginTop:'10px'}} >

            <div class="ride-header-data-flex">


<div class="ride-leader-name-icons-context Vehicles-in-rider-details">VEHICLE<br></br> LEXUS (ES350-LS460)<p className="car-number-written-in-ride-details"> F 12345 </p> </div>
</div>




           

 </Col>

 <Col className="leader-details-column-flex" xs={1} md={1} sm={1} lg={1}>

            <img className="icon-below-leader-details" src="IMAGES/ridemobile.png"/>

            </Col>
            

            <Col className="" xs={6} md={5} sm={5} lg={6} >

            <div class="ride-header-data-flex">


<div class="ride-leader-name-icons-context phone-no-in-rider-details">PHONE<br></br> +92 321 7666530 </div>
</div>

           
 </Col>


 <Col className="" xs={5} md={6} sm={6} lg={5} >

 <img  className="icon-below-leader-details icons-on-right-of-phone-ride-details" src="IMAGES/iconmsg.png"/>
 <img className="icon-below-leader-details icons-on-right-of-phone-ride-details" src="IMAGES/iconcall.png"/>


</Col>

            

        
            
        </Row>
       
    </Col>


</Row>




        
        


        








</Container>

</div>


    );
  }
}
export default Rider;