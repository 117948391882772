import React, { Component } from "react";
import Joi from "joi-browser";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink, Link } from "react-router-dom";
import "./styles2.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Modal,
  FormGroup,
  FormControl,
  Form,
  Accordion,
  Col,
  Row,
  Card,
  InputGroup,
  Container,
} from "react-bootstrap";

import * as Actions from "./store/actions/CustomerSignUp";
import { compose } from "redux";
import { connect } from "react-redux";
import reducer from "./store/actions/reducers";
import withReducer from "../store/WithReducer";
import store from "../store";

class RentalCars_FormCustomerDetails extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      email: "",
      password: "",
      error: {},
      type: "password",
      passFlag: false,
      fname: "",
      lname: "",
      ename: "",
      phone: "",
      pass1: "",
      pass2: "",
      address: "",
      DOB: "",
      dateType: "text",
      termsConditions: false,
      termsSubmitFlag: false,
      passFlag: false,
      passSubmitFlag: false,
      termsFlag: true,
      termsSubmitFlag: false,
      captcha: null,
      buttonFlag: false,
      loaderFlag: false,
      errorFlag: false,
      errorMassage15: "",
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    if (localStorage.adminToken1) {
      this.props.history.push("./admin");
    } /* else{
      this.props.history.push('./adminsignin') 
    } */
    /* if(localStorage.adminToken1){
    this.props.history.push('./adminsignin')
  }
} */
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "string.email":
              err.message = "email mast be A Valid Email";
              break;
            default:
              err.message = "email can not be Empty";
              break;
          }
        });
        return errors;
      }),
    fname: Joi.string()
      .min(3)
      .max(20)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "first name between 3 and 20";
              break;
          }
        });
        return errors;
      }),
    lname: Joi.string()
      .min(3)
      .max(20)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Last name between 3 and 20";
            break;
          }
        });
        return errors;
      }),
      ename: Joi.string()
      .min(5)
      .max(22)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "the e name of this text should between 5 and 25";
            break;
          }
        });
        return errors;
      }),
    phone: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "MObile Number can not be Empty";
              break;
          }
        });
        return errors;
      }),

    pass1: Joi.string()
      .min(8)
      .max(15)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "password  between 8 and 15 ";
              break;
          }
        });
        return errors;
      }),

    address: Joi.string()
      .min(4)
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Address can't be empty [4 min] ";
              break;
          }
        });
        return errors;
      }),
    DOB: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            default:
              err.message = "Date of birth can not be Empity";
              break;
          }
        });
        return errors;
      }),
  };
  changeHandler = (event) => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };

  handleChange = (value) => {
    this.setState({
      country: value,
    });
  };

  validationProperty = (event) => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      fname: this.state.fname,
      lname: this.state.lname,
      ename: this.state.ename,
      phone: this.state.phone,
      email: this.state.email,
      pass1: this.state.pass1,
      DOB: this.state.DOB,
      address: this.state.address,
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitHandler = (event) => {
    event.preventDefault();
    const error = this.validate();
    if (error) {
      this.setState({
        error: error || {},
      });
      this.setState({ passSubmitFlag: true });
    } else {
      if (this.state.passFlag) {
        if (this.state.termsFlag) {
          this.setState({ termsSubmitFlag: true });
          /* 
                            termsSubmitFlag
                            termsFlag
                            */
        } else {
          this.setState({ loaderFlag: true,errorFlag: true });
          let obj = {
            password: this.state.pass1,
            email: this.state.email,
            firstName: this.state.fname,
            lastName: this.state.lname,
            endName: this.state.ename,
            phoneNumber: "+" + this.state.phone,
            address: this.state.address,
            dob: this.state.DOB,
          };
          this.props.dispatch(Actions.customerSignUp(obj));

         /*  setTimeout(() => {
            this.setState({
              email: "",
              password: "",
              DOB: "",
              address: "",
              fname: "",
              lname: "",
              phone: "",
              termsConditions: false,
              passSubmitFlag: false,
              termsFlag: true,
              pass1: "",
              pass2: "",
            });
          }, 1000); */
        }
      } else {
        this.setState({ passSubmitFlag: true });
      }
    }
  };
  handleSent = () => {
    this.setState({ show: false });
  };
  handleHidePass = () => {
    this.setState({ passFlag: true, type: "text" });
  };
  handleShowPass = () => {
    this.setState({ passFlag: false, type: "password" });
  };
  handleConditions = () => {
    if (this.state.termsFlag) {
      this.setState({ termsFlag: false });
    } else {
      this.setState({ termsFlag: true });
    }
  };
  handleConfirmPass = (e) => {
    let pass2 = e.target.value;
    this.setState({ pass2: pass2 });
    if (
      pass2.match(this.state.pass1) &&
      pass2.toString().length === this.state.pass1.toString().length
    ) {
      this.setState({ passFlag: true });
    } else {
      this.setState({ passFlag: false, passSubmitFlag: true });
    }
  };
  onChangeCaptha = (value) => {
    if (value === null) {
      this.setState({ captcha: null, buttonFlag: true });
    } else {
      this.setState({ captcha: value, buttonFlag: false });
    }
  };
  // componentDidMount() {
  //   if (!toast.isActive(this.props.toastId.current)) {
  //     this.props.toastId.current = toast.error(
  //       "Signup first to get access to our premium services",
  //       {
  //         position: "top-right",
  //         toastId: "logined",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       }
  //     );
  //   }
  // }
  componentWillReceiveProps(nextProps) {
    let resNumber1 = nextProps.customerSignUpReducer;
    if (!resNumber1.isLoading) {
   
      if (resNumber1.errMsg === null && !(resNumber1.errMsg === undefined)) {
       
        this.setState({ show: true, loaderFlag: false });
      } else {
        if (this.state.errorFlag) {
          this.setState({
            show: false,
            loaderFlag: false,
            errorFlag: false,
            errorMassage15: resNumber1.errMsg,
          });
          // if (!toast.isActive(this.props.toastId.current)) {
          //   this.props.toastId.current = toast.error(
          //     "Something went Wrong OR Your Email is Already Exists please Sign In",
          //     {
          //       position: "top-right",
          //       toastId: "logined",
          //       autoClose: 5000,
          //       hideProgressBar: false,
          //       closeOnClick: true,
          //       pauseOnHover: true,
          //       draggable: true,
          //       progress: undefined,
          //     }
          //   );
          // }

          /*   this.props.history.push('/signin') */
          /* this.props.history.push('/signin') */
        }
      }
    }
  }
  render() {
    return (
      <Container>
        <br/><br/>
        <h1>Booking Form Provide Your Details</h1>
      <br/><br/>
      <form onSubmit={this.submitHandler} className="contactForm">
        <div className="row">
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
              <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAq5JREFUeNrcWk1WwjAQDn3u1RPADWDhnvo8AHgC4sZ1vUGPUJa6sZyAstcnXesCbgA3gBPUGZz6SqUlP9NSnPfmiTDNfF8ymUyStpIkEbby9f7iwp9UO6DtAtM16Ap0jnpz9zi39d0yJUCgJegQ9NLQ/xY0Ag1NyWgTIOA+aF/wSozt6hJRJgDAMTQC0IGoVmagHhBZsREA8BgmoUWomISWBBLRMUNHATz2+rRG8IJ8Tcm3+QhAA9jrI3FamcBISO0RaAh4lBFhUSdAQ9cE8FkSgVII0YSdimbKfX5i7xGgVLmoecLqZqdeNsXmQyhgBh+Tcman4OAcoBWWa5Eag15DT7mo+Jm+45ABYd3JReYHn8nBA4Deyxrw/wZXV3CM4fnK4MOnwvFnDhCjD44yAMAOjyyMEdNI32LdlIaQZOp9j8lGRWR2DgwZGlyqFGBks2Twt8PsUPhwZJ55RbaFGQmxO+lkYJBNRbZlwkrgqiLbowQ6XI1VZFsmHadkA64rXSpFVHZ2XSafbUfwis9koyzcBLDslSW9L7nLdG4CKK8A1D8A3mcqIyonULTayiocVUFgWwDWo9/YCayZ2sJ2njC1HToOoe86ZMPms/X59ozLet+yxwMAqJVdaE54lmVM7FjWJQje1QVPI5LW9DZhNbclgOAXpg/Ts64VATpMNemFiQ34HImJyehnNzSRQQMBYzIxaSvKptHQsOdYxLCt8JcAhZHO8QfnUYlJm3F6j5A/lVDd2PchDSbidOL/WYmJ0Uw0X2bZWxynjuWeuUzxCmshOjGQDSYg8ycfTkHNMm4g+PGhGqvwhqZBFxzpoim1yml6YNJk8Ef3A/Tg+MRhUzon//81a2Zi92paJ9BHTwW88gjkRsMV5/iqQQERjM/zetmjhEyquO+t7XWbbwEGADa1Kk6pgk9eAAAAAElFTkSuQmCC" /> */}
              <input
                placeholder="First Name"
                value={this.state.fname}
                name="fname"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  height: "35px",
                  color:'black',
                }}
                type="text"
              />

              {this.state.error.fname && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.fname}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            {/* <div className="formInput form-control field-design2"> */}
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAq5JREFUeNrcWk1WwjAQDn3u1RPADWDhnvo8AHgC4sZ1vUGPUJa6sZyAstcnXesCbgA3gBPUGZz6SqUlP9NSnPfmiTDNfF8ymUyStpIkEbby9f7iwp9UO6DtAtM16Ap0jnpz9zi39d0yJUCgJegQ9NLQ/xY0Ag1NyWgTIOA+aF/wSozt6hJRJgDAMTQC0IGoVmagHhBZsREA8BgmoUWomISWBBLRMUNHATz2+rRG8IJ8Tcm3+QhAA9jrI3FamcBISO0RaAh4lBFhUSdAQ9cE8FkSgVII0YSdimbKfX5i7xGgVLmoecLqZqdeNsXmQyhgBh+Tcman4OAcoBWWa5Eag15DT7mo+Jm+45ABYd3JReYHn8nBA4Deyxrw/wZXV3CM4fnK4MOnwvFnDhCjD44yAMAOjyyMEdNI32LdlIaQZOp9j8lGRWR2DgwZGlyqFGBks2Twt8PsUPhwZJ55RbaFGQmxO+lkYJBNRbZlwkrgqiLbowQ6XI1VZFsmHadkA64rXSpFVHZ2XSafbUfwis9koyzcBLDslSW9L7nLdG4CKK8A1D8A3mcqIyonULTayiocVUFgWwDWo9/YCayZ2sJ2njC1HToOoe86ZMPms/X59ozLet+yxwMAqJVdaE54lmVM7FjWJQje1QVPI5LW9DZhNbclgOAXpg/Ts64VATpMNemFiQ34HImJyehnNzSRQQMBYzIxaSvKptHQsOdYxLCt8JcAhZHO8QfnUYlJm3F6j5A/lVDd2PchDSbidOL/WYmJ0Uw0X2bZWxynjuWeuUzxCmshOjGQDSYg8ycfTkHNMm4g+PGhGqvwhqZBFxzpoim1yml6YNJk8Ef3A/Tg+MRhUzon//81a2Zi92paJ9BHTwW88gjkRsMV5/iqQQERjM/zetmjhEyquO+t7XWbbwEGADa1Kk6pgk9eAAAAAElFTkSuQmCC" />
              <input
                placeholder="Last Name"
                value={this.state.lname}
                name="lname"
                onChange={this.changeHandler}
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="text"
              /> */}

<InputGroup>
    <InputGroup.Prepend>
      <InputGroup.Text id="basic-addon1"> 
      <img src="IMAGES/001-01.png" />
      {/* <img style={{width:'40%'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAq5JREFUeNrcWk1WwjAQDn3u1RPADWDhnvo8AHgC4sZ1vUGPUJa6sZyAstcnXesCbgA3gBPUGZz6SqUlP9NSnPfmiTDNfF8ymUyStpIkEbby9f7iwp9UO6DtAtM16Ap0jnpz9zi39d0yJUCgJegQ9NLQ/xY0Ag1NyWgTIOA+aF/wSozt6hJRJgDAMTQC0IGoVmagHhBZsREA8BgmoUWomISWBBLRMUNHATz2+rRG8IJ8Tcm3+QhAA9jrI3FamcBISO0RaAh4lBFhUSdAQ9cE8FkSgVII0YSdimbKfX5i7xGgVLmoecLqZqdeNsXmQyhgBh+Tcman4OAcoBWWa5Eag15DT7mo+Jm+45ABYd3JReYHn8nBA4Deyxrw/wZXV3CM4fnK4MOnwvFnDhCjD44yAMAOjyyMEdNI32LdlIaQZOp9j8lGRWR2DgwZGlyqFGBks2Twt8PsUPhwZJ55RbaFGQmxO+lkYJBNRbZlwkrgqiLbowQ6XI1VZFsmHadkA64rXSpFVHZ2XSafbUfwis9koyzcBLDslSW9L7nLdG4CKK8A1D8A3mcqIyonULTayiocVUFgWwDWo9/YCayZ2sJ2njC1HToOoe86ZMPms/X59ozLet+yxwMAqJVdaE54lmVM7FjWJQje1QVPI5LW9DZhNbclgOAXpg/Ts64VATpMNemFiQ34HImJyehnNzSRQQMBYzIxaSvKptHQsOdYxLCt8JcAhZHO8QfnUYlJm3F6j5A/lVDd2PchDSbidOL/WYmJ0Uw0X2bZWxynjuWeuUzxCmshOjGQDSYg8ycfTkHNMm4g+PGhGqvwhqZBFxzpoim1yml6YNJk8Ef3A/Tg+MRhUzon//81a2Zi92paJ9BHTwW88gjkRsMV5/iqQQERjM/zetmjhEyquO+t7XWbbwEGADa1Kk6pgk9eAAAAAElFTkSuQmCC" /> */}
      </InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl
      placeholder="Username"
      aria-label="Username"
      aria-describedby="basic-addon1"
      value={this.state.lname}
      name="lname"
      onChange={this.changeHandler}
      className=""
      style={{
        height: "50px",
      }}
      type="text"
    />
  </InputGroup>
  {this.state.error.lname && (
                <p style={{ fontWeight: "550", marginTop: "0px", color:'red', fontSize:'12px', textTransform:'uppercase' }}>
                  {this.state.error.lname}
                </p>
              )}

              
            {/* </div> */}
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
            <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtxJREFUeNrkWkty2kAQHSjtTU4APkG0yB5cOQDiBFY22ZojkBNELJONxQmAvVMW62SBb4BOEHMC0k3epJSxJEs9+lHuqi4K1UxPv2G6+/Wg3ul0Urby68f3CX1oHZEOM4bGpAfSiPXDx8+R7do9KQA47ZN6pFfC9Y+kG9JQCqY0ADi+IB2ramXHdssCKQyAHOejEZBOVb2yJZ0TkEORwf2CzvMx2TfgvMIae6xpD4AM8a6vLc65RHitNdaWAyADIX3cqfbkDj6UB4CJt6p9uc0D0c85Nl1wPgkiKJSFEDxr1U2ZUXbaZAJAqtw3HLBlC5+bTLHmEQo67LzOTkFqDKDCTlX3ZQpfX/wCC3U5svgPABBVwW2uwThVDhu9rmCdsf4VHDzwLQhYCIqsOLiQxQYZ458x5gbfR1hbsnk8L9JHyBMY2JIz+izqXoDFTXw31TXGD2BjK1j/7HPv58M3NvAoMPAOGSFZ8D69ksk4Dc5J7xPPVnj2W+DDTT+xc6WODu3cc0q1vn8lDV8Zzp+rLGztBH5MpAC6IhMHgSSVLy0DGDk5DXgRiVoGMHQsDTxW5EhPOrGvLlyaBMDx8nSpAFYmi+w6gNioGVz2uRF5XweAuGKbO1CGJ6iHnnZcx0b1NRGrUNjRgHbdBV/xauyvDw5yedW7w034ALa/1hhbZzZaVzGa1uz8XwC4TD2WLeEpwWob8GUpzZF911loU7aEo3HxLEHECHJPQGk2yY4sFAQaz/FpF2zIoL6HCgVTz3P+3QuRIWkwxxaZbCQkkzvdDTpGpy8hZ0NLRmt/K4GGPBL2pk3LNvkvjkkl5oKM1KTonjqdC+HO0e8wAN/86+kFmcPt77KDzi/Nm+lMNkoD56DAXZEVfCpOp0GBVx1x3hf1A5i4bPnY+FYNDX66WcPZideaZR2b0h0ZgsdtqE7wGm5awKZeZ7yZVw0ygPjq0l72yAGjNY+gVf66zR8BBgBDVhFWqKOsNgAAAABJRU5ErkJggg==" /> */}
              <input
                placeholder="Date of birth"
                value={this.state.DOB}
                name="DOB"
                onChange={this.changeHandler}
                min="1900-01-01"
                max={`${new Date().getFullYear() - 10}-12-31`}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type={this.state.dateType}
                onFocus={() => this.setState({ dateType: "date" })}
              />

              {this.state.error.DOB && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.DOB}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-12">
            <div className="formInput15">
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                onlyCountries={[
                  "ae",
                  "pk",
                  "us",
                  "gb",
                  "bd",
                  "in",
                  "eg",
                  "kz",
                  "ir",
                  "sa",
                  "ph",
                  "om",
                  "kw",
                ]}
                masks={{
                  ae: ".. ... ....",
                  at: "... .......",
                  us: "... ... ...",
                  gb: ".... ... ..",
                  bd: "... .... ...",
                  in: "... .... ...",
                  eg: "... .... ...",
                  kz: "... .... ...",
                  ir: "... .... ...",
                  sa: "... .... ..",
                  ph: "... .... ...",
                  om: "... .... .",
                  kw: "... .... ...",
                  pk: "... ......."
                }}
                country={"ae"}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone: phone })}
              />

              {this.state.phone === "" ? (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.phone}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
            <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAq5JREFUeNrcWk1WwjAQDn3u1RPADWDhnvo8AHgC4sZ1vUGPUJa6sZyAstcnXesCbgA3gBPUGZz6SqUlP9NSnPfmiTDNfF8ymUyStpIkEbby9f7iwp9UO6DtAtM16Ap0jnpz9zi39d0yJUCgJegQ9NLQ/xY0Ag1NyWgTIOA+aF/wSozt6hJRJgDAMTQC0IGoVmagHhBZsREA8BgmoUWomISWBBLRMUNHATz2+rRG8IJ8Tcm3+QhAA9jrI3FamcBISO0RaAh4lBFhUSdAQ9cE8FkSgVII0YSdimbKfX5i7xGgVLmoecLqZqdeNsXmQyhgBh+Tcman4OAcoBWWa5Eag15DT7mo+Jm+45ABYd3JReYHn8nBA4Deyxrw/wZXV3CM4fnK4MOnwvFnDhCjD44yAMAOjyyMEdNI32LdlIaQZOp9j8lGRWR2DgwZGlyqFGBks2Twt8PsUPhwZJ55RbaFGQmxO+lkYJBNRbZlwkrgqiLbowQ6XI1VZFsmHadkA64rXSpFVHZ2XSafbUfwis9koyzcBLDslSW9L7nLdG4CKK8A1D8A3mcqIyonULTayiocVUFgWwDWo9/YCayZ2sJ2njC1HToOoe86ZMPms/X59ozLet+yxwMAqJVdaE54lmVM7FjWJQje1QVPI5LW9DZhNbclgOAXpg/Ts64VATpMNemFiQ34HImJyehnNzSRQQMBYzIxaSvKptHQsOdYxLCt8JcAhZHO8QfnUYlJm3F6j5A/lVDd2PchDSbidOL/WYmJ0Uw0X2bZWxynjuWeuUzxCmshOjGQDSYg8ycfTkHNMm4g+PGhGqvwhqZBFxzpoim1yml6YNJk8Ef3A/Tg+MRhUzon//81a2Zi92paJ9BHTwW88gjkRsMV5/iqQQERjM/zetmjhEyquO+t7XWbbwEGADa1Kk6pgk9eAAAAAElFTkSuQmCC" /> */}
              <input
                placeholder="Email"
                value={this.state.email}
                name="email"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="email"
              />

              {this.state.error.email && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.email}
                </p>
              )}
            </div>
          </div>

          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
            <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtxJREFUeNrkWkty2kAQHSjtTU4APkG0yB5cOQDiBFY22ZojkBNELJONxQmAvVMW62SBb4BOEHMC0k3epJSxJEs9+lHuqi4K1UxPv2G6+/Wg3ul0Urby68f3CX1oHZEOM4bGpAfSiPXDx8+R7do9KQA47ZN6pFfC9Y+kG9JQCqY0ADi+IB2ramXHdssCKQyAHOejEZBOVb2yJZ0TkEORwf2CzvMx2TfgvMIae6xpD4AM8a6vLc65RHitNdaWAyADIX3cqfbkDj6UB4CJt6p9uc0D0c85Nl1wPgkiKJSFEDxr1U2ZUXbaZAJAqtw3HLBlC5+bTLHmEQo67LzOTkFqDKDCTlX3ZQpfX/wCC3U5svgPABBVwW2uwThVDhu9rmCdsf4VHDzwLQhYCIqsOLiQxQYZ458x5gbfR1hbsnk8L9JHyBMY2JIz+izqXoDFTXw31TXGD2BjK1j/7HPv58M3NvAoMPAOGSFZ8D69ksk4Dc5J7xPPVnj2W+DDTT+xc6WODu3cc0q1vn8lDV8Zzp+rLGztBH5MpAC6IhMHgSSVLy0DGDk5DXgRiVoGMHQsDTxW5EhPOrGvLlyaBMDx8nSpAFYmi+w6gNioGVz2uRF5XweAuGKbO1CGJ6iHnnZcx0b1NRGrUNjRgHbdBV/xauyvDw5yedW7w034ALa/1hhbZzZaVzGa1uz8XwC4TD2WLeEpwWob8GUpzZF911loU7aEo3HxLEHECHJPQGk2yY4sFAQaz/FpF2zIoL6HCgVTz3P+3QuRIWkwxxaZbCQkkzvdDTpGpy8hZ0NLRmt/K4GGPBL2pk3LNvkvjkkl5oKM1KTonjqdC+HO0e8wAN/86+kFmcPt77KDzi/Nm+lMNkoD56DAXZEVfCpOp0GBVx1x3hf1A5i4bPnY+FYNDX66WcPZideaZR2b0h0ZgsdtqE7wGm5awKZeZ7yZVw0ygPjq0l72yAGjNY+gVf66zR8BBgBDVhFWqKOsNgAAAABJRU5ErkJggg==" /> */}
              <input
                placeholder="Address"
                value={this.state.address}
                name="address"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="text"
              />

              {this.state.error.address && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.address}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
            <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAfRJREFUeNrsmt1tgzAQxyHqO2xQNkgi9b1UHSBskDz1tekG3aA896VhgrBApeS9UpMNkg3IBPR/1VVCCFw+bLAVn3RyBBjup/Od76y4eZ47suTr8z3AELHeVzxyhu6g6d3jUyrjm64MADY8hi5aTCOYGCDxqAAwfo3hrccrjuQxgJwGB4DxGwxLCSvhAg0BcWg7cdLD+FiS8SQexQYvRfUA+FCI4dmRKwSRDuWBTYMAfYHOsSxcUvx+gCb/zJtyTKmLAXxgheFD8EgCg1eC+TNOpV4dPOYHKj0QdTWehAM15MCtkltARioBFoJM0sj9DCHK/zMlAOz+2riAYVmL14kAQlUe8AX3WuVwhj32TV0TR5502UkznQBGEQtgAa4dwJXZkVkPXCPAjaBs8LlsXoxsI5Xg67oyReSBVAPjHe760lZBzEXbt2arZV7VM086FG1jiW+zkAUYMo1KkEuhyZkJmngtAajTCv9yN+8pdBIxNWEJXYrGF9rHyJQYOFTtmnx4uzcBwO94TxuAKZ+dlnf3yJQY+K2jimdIbPzWpH3AKy2XTNF31G1kCNpdMbBNAziWYMgDZ5MADg2vaQtwGgpAVSkRIPO8lq+ZBLB0BhLbD1gARQCZhrY2Pxfi44u9Rsbv6/6GIFpCVIAlGhifiJqhHwEGAOXfoSgII1gEAAAAAElFTkSuQmCC" /> */}
              <input
              placeholder="design input enter name"
              value={this.state.ename}
              name="ename"
              onChange={this.changeHandler}
              style={{
                border: "none",
                background: "none",
                border: "none",
                height: "35px",
                color:'black',
              }}
              type="text"
              />

              {this.state.error.ename && (
                <p style={{ fontWeight:'550', marginTop:'10px' }}>
                  {this.state.error.ename}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
            <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAfRJREFUeNrsmt1tgzAQxyHqO2xQNkgi9b1UHSBskDz1tekG3aA896VhgrBApeS9UpMNkg3IBPR/1VVCCFw+bLAVn3RyBBjup/Od76y4eZ47suTr8z3AELHeVzxyhu6g6d3jUyrjm64MADY8hi5aTCOYGCDxqAAwfo3hrccrjuQxgJwGB4DxGwxLCSvhAg0BcWg7cdLD+FiS8SQexQYvRfUA+FCI4dmRKwSRDuWBTYMAfYHOsSxcUvx+gCb/zJtyTKmLAXxgheFD8EgCg1eC+TNOpV4dPOYHKj0QdTWehAM15MCtkltARioBFoJM0sj9DCHK/zMlAOz+2riAYVmL14kAQlUe8AX3WuVwhj32TV0TR5502UkznQBGEQtgAa4dwJXZkVkPXCPAjaBs8LlsXoxsI5Xg67oyReSBVAPjHe760lZBzEXbt2arZV7VM086FG1jiW+zkAUYMo1KkEuhyZkJmngtAajTCv9yN+8pdBIxNWEJXYrGF9rHyJQYOFTtmnx4uzcBwO94TxuAKZ+dlnf3yJQY+K2jimdIbPzWpH3AKy2XTNF31G1kCNpdMbBNAziWYMgDZ5MADg2vaQtwGgpAVSkRIPO8lq+ZBLB0BhLbD1gARQCZhrY2Pxfi44u9Rsbv6/6GIFpCVIAlGhifiJqhHwEGAOXfoSgII1gEAAAAAElFTkSuQmCC" /> */}
              <input
                placeholder="Enter your password"
                value={this.state.pass1}
                name="pass1"
                onChange={this.changeHandler}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="password"
              />

              {this.state.error.pass1 && (
                <p style={{ fontWeight: "550", marginTop: "8px" }}>
                  {this.state.error.pass1}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput form-control field-design2">
            <img src="IMAGES/001-01.png" />
              {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAfRJREFUeNrsmt1tgzAQxyHqO2xQNkgi9b1UHSBskDz1tekG3aA896VhgrBApeS9UpMNkg3IBPR/1VVCCFw+bLAVn3RyBBjup/Od76y4eZ47suTr8z3AELHeVzxyhu6g6d3jUyrjm64MADY8hi5aTCOYGCDxqAAwfo3hrccrjuQxgJwGB4DxGwxLCSvhAg0BcWg7cdLD+FiS8SQexQYvRfUA+FCI4dmRKwSRDuWBTYMAfYHOsSxcUvx+gCb/zJtyTKmLAXxgheFD8EgCg1eC+TNOpV4dPOYHKj0QdTWehAM15MCtkltARioBFoJM0sj9DCHK/zMlAOz+2riAYVmL14kAQlUe8AX3WuVwhj32TV0TR5502UkznQBGEQtgAa4dwJXZkVkPXCPAjaBs8LlsXoxsI5Xg67oyReSBVAPjHe760lZBzEXbt2arZV7VM086FG1jiW+zkAUYMo1KkEuhyZkJmngtAajTCv9yN+8pdBIxNWEJXYrGF9rHyJQYOFTtmnx4uzcBwO94TxuAKZ+dlnf3yJQY+K2jimdIbPzWpH3AKy2XTNF31G1kCNpdMbBNAziWYMgDZ5MADg2vaQtwGgpAVSkRIPO8lq+ZBLB0BhLbD1gARQCZhrY2Pxfi44u9Rsbv6/6GIFpCVIAlGhifiJqhHwEGAOXfoSgII1gEAAAAAElFTkSuQmCC" /> */}
              <input
                placeholder="Confirm your password"
                value={this.state.pass2}
                name="pass2"
                onChange={this.handleConfirmPass}
                /*  id="emailField" */
                className=""
                style={{
                  border: "none",
                  background: "none",
                  border: "none",
                  height: "35px",
                }}
                type="password"
              />
              {this.state.passSubmitFlag ? (
                this.state.passFlag ? (
                  ""
                ) : (
                  <p
                    style={{
                      fontWeight: "550",
                      marginTop: "8px",
                      color: "red",
                    }}
                  >
                    Password do not match
                  </p>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          {/* errorMassage15 */}
          <div
            className="col-12"
            style={{ marginTop: "0px", marginBottom: "10px" }}
          >
            <p style={{ color: "white" }}> {this.state.errorMassage15}</p>
          </div>
          <div className="col-12" style={{ marginTop: "0px" }}>
            <div className="signon-bottom-area">
              {/* <div>
                <div className="checkbow-style-area" style={{ color: "white" }}>
                  {" "}
                  <span>
                    {" "}
                    <input
                      class="styled-checkbox-signup"
                      name="termsConditions"
                      onChange={this.handleConditions}
                      id="styled-checkbox-term"
                      type="checkbox"
                      value="valueterm"
                    />
                    <label for="styled-checkbox-term"></label>
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "15px",
                      marginLeft: "10px",
                      marginTop: "-10px",
                      color:'#666666'
                    }}
                  >
                    By Creating an account you agree to
                  </span>
                </div>
                <p
                  style={{
                    color: "#666666",
                    textDecoration: "underline",
                    marginLeft: "30px",
                  }}
                >
                  <a
                    style={{ color: "#fff", textDecoration: "underline", color:'#666666' }}
                    exact
                    to="/termsconditions"
                  >
                    {" "}
                    Terms & Conditions
                  </a>
                </p>
                {this.state.termsSubmitFlag ? (
                  this.state.termsFlag ? (
                    <p
                      style={{
                        fontWeight: "550",
                        marginTop: "8px",
                        color: "red",
                      }}
                    >
                      You must accept the terms & Conditions
                    </p>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div> */}
              <div></div>
            </div>
            <div className="signon-bottom-area1">
              <div className="button-loaader-area-cutomer-signup">
                {this.state.loaderFlag ? (
                  <div style={{ marginRight: "10px" }} className="loader"></div>
                ) : (
                  ""
                )}
                {/* <button type="submit">
                  Sign Up
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="md"
          centered
        >
          <Modal.Body style={{ padding: "0px" }}>
            <div className="signup-verify-email-popup">
              <div className="signup-verify-email-popup-top-area">
                <center>
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtxJREFUeNrkWkty2kAQHSjtTU4APkG0yB5cOQDiBFY22ZojkBNELJONxQmAvVMW62SBb4BOEHMC0k3epJSxJEs9+lHuqi4K1UxPv2G6+/Wg3ul0Urby68f3CX1oHZEOM4bGpAfSiPXDx8+R7do9KQA47ZN6pFfC9Y+kG9JQCqY0ADi+IB2ramXHdssCKQyAHOejEZBOVb2yJZ0TkEORwf2CzvMx2TfgvMIae6xpD4AM8a6vLc65RHitNdaWAyADIX3cqfbkDj6UB4CJt6p9uc0D0c85Nl1wPgkiKJSFEDxr1U2ZUXbaZAJAqtw3HLBlC5+bTLHmEQo67LzOTkFqDKDCTlX3ZQpfX/wCC3U5svgPABBVwW2uwThVDhu9rmCdsf4VHDzwLQhYCIqsOLiQxQYZ458x5gbfR1hbsnk8L9JHyBMY2JIz+izqXoDFTXw31TXGD2BjK1j/7HPv58M3NvAoMPAOGSFZ8D69ksk4Dc5J7xPPVnj2W+DDTT+xc6WODu3cc0q1vn8lDV8Zzp+rLGztBH5MpAC6IhMHgSSVLy0DGDk5DXgRiVoGMHQsDTxW5EhPOrGvLlyaBMDx8nSpAFYmi+w6gNioGVz2uRF5XweAuGKbO1CGJ6iHnnZcx0b1NRGrUNjRgHbdBV/xauyvDw5yedW7w034ALa/1hhbZzZaVzGa1uz8XwC4TD2WLeEpwWob8GUpzZF911loU7aEo3HxLEHECHJPQGk2yY4sFAQaz/FpF2zIoL6HCgVTz3P+3QuRIWkwxxaZbCQkkzvdDTpGpy8hZ0NLRmt/K4GGPBL2pk3LNvkvjkkl5oKM1KTonjqdC+HO0e8wAN/86+kFmcPt77KDzi/Nm+lMNkoD56DAXZEVfCpOp0GBVx1x3hf1A5i4bPnY+FYNDX66WcPZideaZR2b0h0ZgsdtqE7wGm5awKZeZ7yZVw0ygPjq0l72yAGjNY+gVf66zR8BBgBDVhFWqKOsNgAAAABJRU5ErkJggg==" />
                </center>
              </div>
              <div className="signup-verify-email-popup-bottom-area">
                <center>
                  <h3>Congratulations!</h3>
                  <p>
                    Your account is successfully created. Please verify your
                    email to processed.
                  </p>
                </center>
                <hr />
                <center>
                  {" "}
                  <button
                    className="button-verify-email-address"
                    style={{ color: "black" }}
                    onClick={() => this.props.history.push("/signin")}
                  >
                    OK
                  </button>
                </center>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </form>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerSignUpReducer: state.CustomerSignUP.customerSignUpReducer,
  };
};
// export default RentalCars_FormCustomerDetails;
export default compose(
  withReducer("CustomerSignUP", reducer),
  connect(mapStateToProps),
  withRouter
)(RentalCars_FormCustomerDetails);
