import React, { Component, useEffect } from "react";
import Carousel from 'react-multi-carousel';
import {Link} from "react-router-dom";
import 'react-multi-carousel/lib/styles.css';
import { 
TwitterTimelineEmbed, 
TwitterShareButton, 
TwitterFollowButton, 
TwitterHashtagButton, 
TwitterMentionButton, 
TwitterTweetEmbed, 
TwitterMomentShare, 
TwitterDMButton, 
TwitterVideoEmbed, 
TwitterOnAirButton }
from 'react-twitter-embed';

import { Visible, Hidden, setConfiguration } from 'react-grid-system';

import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  
  Col
} from "react-bootstrap";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};






class News extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { date: new Date().toLocaleString()};
  }
  
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date
    });
  }


  emiratesairline =()=>{
    document.getElementById("emirates").style.display = "block";
    document.getElementById("tripadvisor").style.display = "none";
    document.getElementById("Travellingman").style.display = "none";
    document.getElementById("smartdubai").style.display = "none";
    document.getElementById("dubaipolice").style.display = "none";
    document.getElementById("natgeotravel").style.display = "none";
    document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "none";
    document.getElementById("europespaceagency").style.display = "none";
    
    document.getElementById("mySidenav-newsroom").style.width = "0px";
  }


Tripadvisor  =()=>{
    document.getElementById("emirates").style.display = "none";
    document.getElementById("tripadvisor").style.display = "block";
    document.getElementById("Travellingman").style.display = "none";
    document.getElementById("smartdubai").style.display = "none";
    document.getElementById("dubaipolice").style.display = "none";
    document.getElementById("natgeotravel").style.display = "none";
    document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "none";
    document.getElementById("europespaceagency").style.display = "none";

    document.getElementById("mySidenav-newsroom").style.width = "0px";
}


TravellingMan  =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "block";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}

Smartdubai = () => {
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "block";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";
  
  document.getElementById("mySidenav-newsroom").style.width = "0px";
}

DubaiPolice = () => {
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "block";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "none";
    document.getElementById("europespaceagency").style.display = "none";
  
    document.getElementById("mySidenav-newsroom").style.width = "0px";
}

NatGeoTravel = () => {
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "block";
  document.getElementById("hamadbin").style.display = "none";
    document.getElementById("princesalman").style.display = "none";
    document.getElementById("kaptaan").style.display = "none";
    document.getElementById("abcnews").style.display = "none";
    document.getElementById("huawei").style.display = "none";
    document.getElementById("trip").style.display = "none";
    document.getElementById("muslim").style.display = "none";
    document.getElementById("globaltime").style.display = "none";
    document.getElementById("atp").style.display = "none";
    document.getElementById("pga").style.display = "none";
    document.getElementById("tourdefrance").style.display = "none";
    document.getElementById("helloqatar").style.display = "none";
    document.getElementById("travel").style.display = "none";
    document.getElementById("travelleisure").style.display = "none";
    document.getElementById("travelstate").style.display = "none";
    document.getElementById("travelmagazine").style.display = "none";
    document.getElementById("travelweekly").style.display = "none";
    document.getElementById("wanderlust").style.display = "none";
    document.getElementById("lonelyplanet").style.display = "none";
    document.getElementById("beijingreview").style.display = "none";
    document.getElementById("telegraphtravel").style.display = "none";
    document.getElementById("condenast").style.display = "none";
    document.getElementById("forbes").style.display = "none";
    document.getElementById("condenast2").style.display = "none";
    document.getElementById("frommer").style.display = "none";
    document.getElementById("bbctravel").style.display = "none";
    document.getElementById("nationalgeographic").style.display = "none";
    document.getElementById("travelweekly2").style.display = "none";
    document.getElementById("guardiantravel").style.display = "none";
    document.getElementById("bradtguides").style.display = "none";
    document.getElementById("reuters").style.display = "none";
    document.getElementById("teslaroadtrip").style.display = "none";
    document.getElementById("culturetrip").style.display = "none";
    document.getElementById("holidaytripping").style.display = "none";
    document.getElementById("tripbuddies").style.display = "none";
    document.getElementById("frenchalps").style.display = "none";
    document.getElementById("baliholtrip").style.display = "none";
    document.getElementById("shinetrip").style.display = "none";
    document.getElementById("tripspurholiday").style.display = "none";
    document.getElementById("lakegirl").style.display = "none";
    document.getElementById("davetravel").style.display = "none";
    document.getElementById("spaceportuae").style.display = "none";
    document.getElementById("europespaceagency").style.display = "none";
  
    document.getElementById("mySidenav-newsroom").style.width = "0px";
}

Hamdanbin =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "block";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


MohammedbinSalman =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "block";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


ImranKhan =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "block";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


ABCNews =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "block";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


HuaweiEurope =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "block";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



Tripcom =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "block";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



MuslimWorldLeague =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "block";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


GlobalTimes =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "block";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


ATPTour =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "block";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


PGATour =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "block";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



TourdeFrance =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "block";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



HelloQatar =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "block";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




TRAVEL =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "block";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}





TravelLeisure =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "block";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}





TravelStateDept =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "block";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




TheTravelMagazine =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "block";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




TravelWeekly =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "block";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




Wanderlustmagazine =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "block";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




LonelyPlanet =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "block";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




BeijingReview =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "block";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



TelegraphTravel =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "block";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


CondéNastTraveler =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "block";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



Forbes =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "block";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




CondéNastTraveller2 =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "block";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



Frommer =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "block";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



BBCTravel =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "block";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




NationalGeographicTraveller =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "block";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



TravelWeekly2 =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "block";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




GuardianTravel =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "block";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




BradtGuides =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "block";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




Reuters =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "block";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




TeslaRoadTrip =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "block";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



CultureTrip =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "block";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




HolidayTripping =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "block";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



HooreyHoliday =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "block";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



TriptoFrenchAlps =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "block";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




BaliholidayTrip =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "block";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



shinediaTrip =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "block";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}




TripspurHolidays =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "block";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}



Lakegirl =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "block";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}


DaveTravels =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "block";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}





SpacePortae =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "block";
  document.getElementById("europespaceagency").style.display = "none";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}





EuropeanSpaceAgency =()=>{
  document.getElementById("emirates").style.display = "none";
  document.getElementById("tripadvisor").style.display = "none";
  document.getElementById("Travellingman").style.display = "none";
  document.getElementById("smartdubai").style.display = "none";
  document.getElementById("dubaipolice").style.display = "none";
  document.getElementById("natgeotravel").style.display = "none";
  document.getElementById("hamadbin").style.display = "none";
  document.getElementById("princesalman").style.display = "none";
  document.getElementById("kaptaan").style.display = "none";
  document.getElementById("abcnews").style.display = "none";
  document.getElementById("huawei").style.display = "none";
  document.getElementById("trip").style.display = "none";
  document.getElementById("muslim").style.display = "none";
  document.getElementById("globaltime").style.display = "none";
  document.getElementById("atp").style.display = "none";
  document.getElementById("pga").style.display = "none";
  document.getElementById("tourdefrance").style.display = "none";
  document.getElementById("helloqatar").style.display = "none";
  document.getElementById("travel").style.display = "none";
  document.getElementById("travelleisure").style.display = "none";
  document.getElementById("travelstate").style.display = "none";
  document.getElementById("travelmagazine").style.display = "none";
  document.getElementById("travelweekly").style.display = "none";
  document.getElementById("wanderlust").style.display = "none";
  document.getElementById("lonelyplanet").style.display = "none";
  document.getElementById("beijingreview").style.display = "none";
  document.getElementById("telegraphtravel").style.display = "none";
  document.getElementById("condenast").style.display = "none";
  document.getElementById("forbes").style.display = "none";
  document.getElementById("condenast2").style.display = "none";
  document.getElementById("frommer").style.display = "none";
  document.getElementById("bbctravel").style.display = "none";
  document.getElementById("nationalgeographic").style.display = "none";
  document.getElementById("travelweekly2").style.display = "none";
  document.getElementById("guardiantravel").style.display = "none";
  document.getElementById("bradtguides").style.display = "none";
  document.getElementById("reuters").style.display = "none";
  document.getElementById("teslaroadtrip").style.display = "none";
  document.getElementById("culturetrip").style.display = "none";
  document.getElementById("holidaytripping").style.display = "none";
  document.getElementById("tripbuddies").style.display = "none";
  document.getElementById("frenchalps").style.display = "none";
  document.getElementById("baliholtrip").style.display = "none";
  document.getElementById("shinetrip").style.display = "none";
  document.getElementById("tripspurholiday").style.display = "none";
  document.getElementById("lakegirl").style.display = "none";
  document.getElementById("davetravel").style.display = "none";
  document.getElementById("spaceportuae").style.display = "none";
  document.getElementById("europespaceagency").style.display = "block";

  document.getElementById("mySidenav-newsroom").style.width = "0px";
}






openNavnewsroom=() => {
  document.getElementById("mySidenav-newsroom").style.width = "300px";
  document.getElementById("close-button-newsroom").style.display = "";
  
}


closeNavnewsroom = () => {
  document.getElementById("mySidenav-newsroom").style.width = "0px";
  document.getElementById("close-button-newsroom").style.display = "none";
}








  render() {
    const scrollTonews = () => window.scrollTo({ top: 0});
    return (

        <div>



<div className="news-room-top-content" >
              <div className="news-room-container-top">
                <div className="news-room-top-content-area">
              
                <Row className="news-room-grid">
                  <Col xl={8} md={8} sm={12} xs={12} lg={8}>
                  <div className="news-room12">
                 <h2 style={{color:'#ffffff',paddingLeft:'3%',textAlign:'left' }} className="news-room-banner-top-heading" >News Room</h2>
                 <h3 className="text-in-h3-news-room" style={{color:'#ffffff',paddingLeft:'3%',textAlign:'left',fontSize:'20px' }}>WITH LATEST TECHNOLOGICAL TRENDS</h3>
                  <h5 style={{color:'#ffffff',paddingLeft:'3%',fontSize:'17px'  }} className="news-room-banner-top-co">WOW Provides you with latest news about Tour Travels & Company</h5> 
                 </div>
                  </Col>
                  <Col xl={4} md={4}  lg={4} className="simplify-news-room-text-col-tab">
                  <Hidden sm xs>  
                  </Hidden>
                  </Col>
                </Row>
                </div>
              </div>
              </div>















<Container>
            <center>
              <h3 className="news_room">News Room</h3>
              <div className="news_room-heading-text">
              Here you can see latest news of WOW Worldwide Holiday.
              </div>
            </center>
            </Container>





























<Container>

    
<center>
    <h6 className="news-room-header-text">
        RECENT NEWS</h6>
        </center>
        <br></br>

        



<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1500}
  keyBoardControl={true}
  customTransition="all 1s linear"
  transitionDuration={1200}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  pause={true}

>
  
 
  <div className="card-news-room" >
  <img className="card-img-top-news-room-dubai"
  src="IMAGES/city-images/newsroom/header-image-dubai-small.jpg"
  alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">Wow tips for first time travellers to Dubai</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <Link to="/newscard1"
    onClick={scrollTonews}
    className="carousel-inside-link-card-news-room">Read Full News</Link>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  




<div className="card-news-room" >
  <img className="card-img-top-news-room-dubai"
  src="IMAGES/city-images/newsroom/card2-small.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">Coronavirus: Safest Beaches in Europe in 2020</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <Link to="/newscard2"
    onClick={scrollTonews} className="carousel-inside-link-card-news-room">Read Full News</Link>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>



<div className="card-news-room" >
  <img className="card-img-top-news-room-dubai" 
  src="IMAGES/city-images/newsroom/card3-2-small.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">WOW Coronavirus Tips If You Still Have to Travel Domestically</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN TO ADAPT THE LATEST TECHNOLOGY
    & Travel Safely  </p>
    <Link to="/newscard3"
    onClick={scrollTonews} className="carousel-inside-link-card-news-room">Read Full News</Link>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

<div className="card-news-room" >
  <img className="card-img-top-news-room-dubai"
  src="IMAGES/city-images/newsroom/card4-small.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">WOW: Ways to Display Travel Keepsakes</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S PLAN HEADED TO DISPLAY TRAVEL KEEPSAKES. </p>
    <Link to="/newscard4"
    onClick={scrollTonews} className="carousel-inside-link-card-news-room">Read Full News</Link>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>



<div className="card-news-room" >
  <img className="card-img-top-news-room-dubai"
  src="IMAGES/city-images/newsroom/card5-small.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">Wow: 10 fun facts you didn’t know about Dubai</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <Link to="/newscard5"
    onClick={scrollTonews} className="carousel-inside-link-card-news-room">Read Full News</Link>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>



<div className="card-news-room" >
  <img className="card-img-top-news-room-dubai"
  src="IMAGES/city-images/newsroom/card6-small.jpg" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">10 best weekend trips from London & UK travel restrictions</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <Link to="/newscard6"
    onClick={scrollTonews} className="carousel-inside-link-card-news-room">Read Full News</Link>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>




  
</Carousel>;




        

  

        
<br/><br/><br/>























<center>
    <h6 className="news-room-header-press-media">
    PRESS MEDIA</h6>


    <div className="news-room-header-press-media-text">
    Top reputed media broadcasting channels of UAE and online news portals have 
    corporate with us and published our press releases to
     represent the WOW on UAE public platforms.
    </div>
        </center>
        <br></br>

        



<Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  centerMode={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1500}
  keyBoardControl={true}
  customTransition="all 1s linear"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
  pause={true}
  

>
  
 
  <div className="card-news-room" >
  <img className="card-img-top-carousel-new" src="IMAGES/city-images/newsroom/press/1mcarbianbusiness.png"
   alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">Arabian Business/ Industries</h5>
    <p className="card-text-news-room">Ride-hailing App WOW begins operations in the UAE </p>
    <a href="https://www.arabianbusiness.com/transport/440855-ride-hailing-app-wow-begins-operations-in-the-uae"
     target="blank"
     className="carousel-inside-link-card-news-room">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

  




<div className="card-news-room" >
  <img className="card-img-top-carousel-new" 
  src="IMAGES/city-images/newsroom/press/2mczawaya.png" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">ZAWYA</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <a href="https://www.zawya.com/mena/en/press-releases/story/WOW_set_to_hit_UAE_roads-ZAWYA20200220161315/" 
    target="blank"
    className="carousel-inside-link-card-news-room">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>



<div className="card-news-room" >
  <img className="card-img-top-carousel-new" 
  src="IMAGES/city-images/newsroom/press/3mckhaleejtimes.png" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">Khaleej Times</h5>
    <p className="card-text-news-room">WOW launches its soft release on 22 February 2020 </p>
    <a href="https://www.khaleejtimes.com/business/local/businesses-thrilled-to-resume-work" 
    target="blank"
    className="carousel-inside-link-card-news-room">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>

<div className="card-news-room" >
  <img className="card-img-top-carousel-new" 
  src="IMAGES/city-images/newsroom/press/5mcalbyan.png" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">AlBayan</h5>
    <p className="card-text-news-room">Arabic Press Media Release regarding the inauguration of WOW </p>
    <a href="https://www.albayan.ae/economy/local-market/2020-02-21-1.3783412"
    target="blank"
     className="carousel-inside-link-card-news-room">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>



<div className="card-news-room" >
  <img className="card-img-top-carousel-new" 
  src="IMAGES/city-images/newsroom/press/pressreader.png" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">Press Reader</h5>
    <p className="card-text-news-room">WOW will kick start services from Dubai, UAE </p>
    <a href="https://www.pressreader.com/bahrain/gulf-today/20200221/281956019807957"
    target="blank"
    className="carousel-inside-link-card-news-room">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>



<div className="card-news-room" >
  <img className="card-img-top-carousel-new" src="IMAGES/city-images/newsroom/press/pressreader.png" alt="Card image cap"/>
  <div className="card-body">
    <h5 className="card-title-news-room">SELF DRIVING CARS FOR YOUR-RIDE</h5>
    <p className="card-text-news-room">HERE TO THE WOW'S FUTURE PLAN HEADED TO ADAPT THE LATEST TECHNOLOGY </p>
    <a href="https://www.pressreader.com/bahrain/gulf-today/20200221/281956019807957"
    target="blank"
    className="carousel-inside-link-card-news-room">Read Full News></a>
    
    
  
    <footer>
     <div class="date-card-news-room">
        <p>  {this.state.date}</p>
      </div>

     </footer>
  </div>
</div>




  
</Carousel>;



















<center>
    <h6 className="news-room-header-press-media">
    Social MEDIA News</h6>


    <div className="news-room-header-press-media-text">
    Top reputed media broadcasting channels of UAE and online news portals have 
    corporate with us and published our press releases to
     represent the WOW on UAE public platforms.
    </div>
        </center>
  



<Row style={{marginTop:'5rem'}}>
<Col xl={4} lg={4} md={5} sm={4} className="muitab-in-pc-view-news-room">

<div
                      className="MuiTabs-root-news-room jss9-news-room MuiTabs-vertical-news-room"
                      aria-label="Vertical tabs example"
                    >
                      <div
                        className="MuiTabs-scrollable-news-room"
                        style={{
                          width: "99px",
                          height: "99px",
                          position: "absolute",
                          top: "-9999px",
                          overflow: "scroll",
                        }}
                      ></div>

                      <div
                        className="MuiTabs-scroller-news-room MuiTabs-scrollable-news-room"
                        style={{ marginBottom: "0px" }}
                      >
                        <div
                          className="MuiTabs-flexContainer-news-room
                          MuiTabs-flexContainerVertical-news-room"
                          role="tablist"
                        >


<h3 class="sidebarTitle">News</h3>





                          <button
                            onClick={this.emiratesairline}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                Emirates Airline
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.Tripadvisor}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Tripadvisor
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>

                          <button
                            onClick={this.TravellingMan}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                              Travelling Man
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>

                          <button
                            onClick={this.Smartdubai}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                               Smart Dubai
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.DubaiPolice}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                 Dubai Police
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.NatGeoTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                              Nat Geo Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.Hamdanbin}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Hamdan bin Mohammed
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.MohammedbinSalman}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Mohammed bin Salman
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.ImranKhan}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Imran Khan
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.ABCNews}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              ABC News
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HuaweiEurope}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Huawei Europe
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.Tripcom}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Trip.com
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.MuslimWorldLeague}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Muslim World League
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.GlobalTimes}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Global Times
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.ATPTour}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              ATP Tour
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.PGATour}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              PGA TOUR
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TourdeFrance}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Tour de France™
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HelloQatar}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Hello Qatar
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.TRAVEL}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              TRAVEL
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.TravelLeisure}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel + Leisure
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.TravelStateDept}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel-State Dept
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TheTravelMagazine}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              The Travel Magazine
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.TravelWeekly}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel Weekly
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.Wanderlustmagazine}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Wanderlust magazine
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.LonelyPlanet}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Lonely Planet
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.BeijingReview}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Beijing Review
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TelegraphTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Telegraph Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.CondéNastTraveler}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Condé Nast Traveler
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.Forbes}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Forbes
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.CondéNastTraveller2}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Condé Nast Traveller
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.Frommer}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Frommer's
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.BBCTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              BBC Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.NationalGeographicTraveller}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              National Geographic Traveller
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TravelWeekly2}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel Weekly
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.GuardianTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Guardian Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                          <button
                            onClick={this.BradtGuides}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Bradt Guides
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                         





                          <button
                            onClick={this.Reuters}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Reuters
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                          <button
                            onClick={this.TeslaRoadTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              TeslaRoadTrip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                          <button
                            onClick={this.CultureTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Culture Trip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HolidayTripping}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Holiday Tripping
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HooreyHoliday}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Hoorey Holiday
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TriptoFrenchAlps}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Trip to French Alps
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.BaliholidayTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Bali holiday Trip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                         


                          <button
                            onClick={this.shinediaTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              shine india Trip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.TripspurHolidays}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Tripspur-Holidays
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.Lakegirl}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Lakegirl
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.DaveTravels}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Dave Travels
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.SpacePortae}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              SpacePort.ae
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.EuropeanSpaceAgency}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              European Space Agency
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>

                          </div>

                          </div>
                          </div>



</Col>






<Container className="sidenav-newsroom-mobile-view newsroom-menu-button-border">
                          <div id="mySidenav-newsroom" className="sidenav-newsroom" >
  <h3 class="sidebarTitle">News                          
  <a href="javascript:void(0)"
   className="closebtn-newsroom" 
   id="close-button-newsroom"
   onClick={this.closeNavnewsroom}>  &times;</a>
   </h3>





<button
                            onClick={this.emiratesairline}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                Emirates Airline
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.Tripadvisor}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Tripadvisor
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>

                          <button
                            onClick={this.TravellingMan}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                              Travelling Man
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>

                          <button
                            onClick={this.Smartdubai}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                               Smart Dubai
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.DubaiPolice}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                 Dubai Police
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.NatGeoTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                              Nat Geo Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.Hamdanbin}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Hamdan bin Mohammed
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.MohammedbinSalman}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Mohammed bin Salman
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.ImranKhan}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Imran Khan
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.ABCNews}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              ABC News
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HuaweiEurope}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Huawei Europe
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.Tripcom}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Trip.com
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.MuslimWorldLeague}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Muslim World League
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.GlobalTimes}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Global Times
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.ATPTour}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              ATP Tour
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.PGATour}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              PGA TOUR
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TourdeFrance}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Tour de France™
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HelloQatar}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Hello Qatar
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.TRAVEL}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              TRAVEL
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.TravelLeisure}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel + Leisure
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.TravelStateDept}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel-State Dept
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TheTravelMagazine}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              The Travel Magazine
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.TravelWeekly}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel Weekly
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.Wanderlustmagazine}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Wanderlust magazine
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.LonelyPlanet}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Lonely Planet
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.BeijingReview}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Beijing Review
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TelegraphTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Telegraph Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.CondéNastTraveler}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Condé Nast Traveler
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.Forbes}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Forbes
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.CondéNastTraveller2}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Condé Nast Traveller
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.Frommer}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Frommer's
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.BBCTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              BBC Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.NationalGeographicTraveller}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              National Geographic Traveller
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TravelWeekly2}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Travel Weekly
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.GuardianTravel}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Guardian Travel
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                          <button
                            onClick={this.BradtGuides}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Bradt Guides
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                         





                          <button
                            onClick={this.Reuters}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Reuters
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                          <button
                            onClick={this.TeslaRoadTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              TeslaRoadTrip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                          <button
                            onClick={this.CultureTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Culture Trip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HolidayTripping}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Holiday Tripping
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.HooreyHoliday}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Hoorey Holiday
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.TriptoFrenchAlps}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Trip to French Alps
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.BaliholidayTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Bali holiday Trip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>





                         


                          <button
                            onClick={this.shinediaTrip}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              shine india Trip
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.TripspurHolidays}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Tripspur-Holidays
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>



                          <button
                            onClick={this.Lakegirl}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Lakegirl
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.DaveTravels}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              Dave Travels
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>




                          <button
                            onClick={this.SpacePortae}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              SpacePort.ae
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>


                          <button
                            onClick={this.EuropeanSpaceAgency}
                            activeClassName="active-privacy-policy"
                            className="MuiButtonBase-root-news-room on-focus-button-news-room MuiTab-root-news-room MuiTab-textColorInherit-news-room news-room-tab-name Mui-selected"
                            tabindex="0"
                            type="button"
                            role="tab"
                            aria-selected="true"
                            id="vertical-tab-0"
                            aria-controls="vertical-tabpanel-0"
                          >
                            <span class="MuiTab-wrapper-news-room">
                              <span>
                                
                              European Space Agency
                              </span>{" "}
                            </span>
                            <span class="MuiTouchRipple-root-news-room"></span>
                          </button>
                        
</div>

{/* <h2>Animated Sidenav Example</h2> */}
<span
className="newsroom-menu-button"
 onClick={this.openNavnewsroom}
 >&#9776; News Menu</span>
                          </Container>





































































    <Col xl={8} lg={8} md={7} id="emirates">
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="emirates"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

                        </Col>


                        <Col xl={8} lg={8} md={8} id="tripadvisor" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TripAdvisor"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

                        </Col>




                        <Col xl={8} lg={8} md={7} id="Travellingman" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TravellingManUK"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>


                        </Col>



                        <Col xl={8} lg={8} md={7} id="smartdubai" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="SmartDubai"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="dubaipolice" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="DubaiPoliceHQ"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>

<Col xl={8} lg={8} md={7} id="natgeotravel" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="NatGeoTravel"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="hamadbin" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="HamdanMohammed"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="princesalman" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Prince___Salman"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="kaptaan" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="ImranKhanPTI"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="abcnews" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="ABC"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


 <Col xl={8} lg={8} md={7} id="huawei" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Huawei_Europe"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>





<Col xl={8} lg={8} md={7} id="trip" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Trip"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="muslim" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="MWLOrg_en"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="globaltime" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="globaltimesnews"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="atp" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="atptour"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="pga" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="pgatour"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="tourdefrance" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="LeTour"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="helloqatar" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="HelloQtar"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="travel" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="travel"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="travelleisure" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TravelLeisure"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="travelstate" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TravelGov"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="travelmagazine" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TravelMagazine"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="travelweekly" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="travelweekly"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="wanderlust" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="wanderlustmag"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="lonelyplanet" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="lonelyplanet"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>




<Col xl={8} lg={8} md={7} id="beijingreview" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="BeijingReview"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="telegraphtravel" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TelegraphTravel"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="condenast" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="CNTraveler"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="forbes" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Forbes"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="condenast2" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="cntraveler"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="frommer" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Frommers"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="bbctravel" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="BBC_Travel"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>




<Col xl={8} lg={8} md={7} id="nationalgeographic" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="NatGeoTravelUK"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="travelweekly2" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TWtravelnews"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="guardiantravel" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="GuardianTravel"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="bradtguides" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="BradtGuides"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="reuters" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Reuters"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="teslaroadtrip" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TeslaRoadTrip"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="culturetrip" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="CultureTrip"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="holidaytripping" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="HolidayTripping"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="tripbuddies" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="trip_buddies"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="frenchalps" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="MSUAlpsHoliday"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="baliholtrip" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="bali_holtrip"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="shinetrip" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="holiday_tour1"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="tripspurholiday" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="TripspurHoliday"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>


<Col xl={8} lg={8} md={7} id="lakegirl" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="Lakegir66922382"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>




<Col xl={8} lg={8} md={7} id="davetravel" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="davetravelthing"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="spaceportuae" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="SpacePortUAE"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>



<Col xl={8} lg={8} md={7} id="europespaceagency" style={{display:'none'}}>
    <div className="centerContent">
<div className="selfCenter spaceBetween standardWidth">
<TwitterTimelineEmbed
  sourceType="timeline"
  screenName="esa"
  theme="light"
  noHeader
  noFooter
  options={{height: 2480}}
/>
</div>
</div>

</Col>









</Row>




</Container>


{/* 
<Col lg={5}>
<a class="twitter-timeline" data-width="1024" data-theme="dark" href="https://twitter.com/DXBMediaOffice?ref_src=twsrc%5Etfw">Tweets by DXBMediaOffice</a>

</Col> */}























 
</div>


    );
  }
}
export default News;