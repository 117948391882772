import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Figure,
  Col,
} from "react-bootstrap";
import { Button, ButtonGroup } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../HolidayAirportTaxi/AirportTaxi.scss";
import { Rate } from "antd";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

class Rental_Cars_Search extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      show: true,
      //   showHide47passengerscards: true,
      //   showHide47passengerscars1: false,
      //   showHide47passengerscars2: false,
      HideCarsSectionFilterPage: false,
      cars_5: true,
      cars_4: [
        {
          name: "Audi",
          country: "UAE, Dubai",
          currency: "Dirham",
          price: 128.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/2-WOW-Family/4-GMC.png",
          time: "2h 30min",
        },
        {
          name: "BMW",
          country: "Pakistan",
          currency: "Dirham",
          price: 158.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/2-Audi-Q7.png",
          time: "2h 30min",
        },
        {
          name: "Chevrolet",
          country: "USA",
          currency: "Dirham",
          price: 228.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/1 WOW-Luxury-Cars.png",
          time: "2h 30min",
        },
        {
          name: "Citroen",
          country: "Pakistan",
          currency: "Dirham",
          price: 138.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/16-Porsche-(CAYENNE).png",
          time: "2h 30min",
        },
        {
          name: "Hyundai",
          country: "Pakistan",
          currency: "Dirham",
          price: 432.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/5-Cadillac-(ESCALADE).png",
          time: "2h 30min",
        },
        {
          name: "Mercedes-Benz",
          country: "UAE, Dubai",
          currency: "Dirham",
          price: 128.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/8-Land-Rover-(Range-Rover-VOGUE-SPORT).png",
          time: "2h 30min",
        },
        {
          name: "Renault",
          country: "UAE, Dubai",
          currency: "Dirham",
          price: 128.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/3-Bentley-Bentayga.png",
          time: "2h 30min",
        },
        {
          name: "Seat",
          country: "UK",
          currency: "Dirham",
          price: 128.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/10-(NAVIGATOR).png",
          time: "2h 30min",
        },
        {
          name: "Dodge",
          country: "USA",
          currency: "Dirham",
          price: 128.67,
          img:
            "IMAGES/city-images/rental-cars/wow-car-categories/7-WOW-luxury/11-Lexus-(LX-570).png",
          time: "2h 30min",
        },
      ],
      numberOfitemsShown: 1,
    };
    // this.hideComponent = this.hideComponent.bind(this);
    // this.showMore = this.showMore.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "HideCarsSectionFilterPage":
        this.setState({
          cars_5: !this.state.cars_5,
        });
        break;
      //   case "showHide47passengerscardsimg":
      //     this.setState({
      //       showHide47passengerscardsimg: !this.state.showHide47passengerscardsimg,
      //     });
      default:
      // null;
    }
  }

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { cars_4, cars_5 } = this.state;
    return (
      <div>
        <div>
          <center>
            <Button
              variant="outlined"
              style={{ margin: "1rem 0" }}
              color="primary"
              onClick={() => this.hideComponent("HideCarsSectionFilterPage")}
              className={
                "" +
                (this.state.HideCarsSectionFilterPage ? "selected-button" : "")
              }
            >
              Hide Taxis Sections
            </Button>
          </center>

          {cars_5 && (
            <Container>
              {this.state.cars_4.map((car4) => {
                const { name, country, time, price, img } = car4;
                return (
                  <div
                    style={{
                      borderBottom: "1px solid black",
                      cursor: "pointer",
                    }}
                  >
                    <Container>
                      <div
                        id="hotellist_inner"
                        className="wider_image"
                        role="region"
                        aria-label="Search results"
                        data-et-view="  OQLOLOLOXaTFKGHCcZQHKKATXT:1  OQLOLOLOMSVSRQbVKKMadMUWe:1 OQLOLOLOdbLEZdfNaT:1 OQLOLOLOVCJVIQNcGZdMbHGQWKHC:2     OLGPNceIWDAOdbIVXVEHXT:4    OLGPNceIWDAOdbIVXVEHXT:6      OLGPNceIWDAOdbIVXVEHINET:1   OLGPNceIWDAOdbIVXVEHINET:4   OLGPNceIWDAOdbIVXVEHINET:5   OLGPNceIWDAOdbIVXVEHINET:8  "
                      >
                        <div
                          data-et-view=""
                          data-et-click="customGoal:OQLOLOLOdbLEZdfNaT:1 customGoal:OQLOLOLOdbLEZdfNaT:3 "
                          className="sr_item  sr_item_new sr_item_default sr_property_block sr_card_no_hover  sr_flex_layout        sr_item--highlighted   with_dates     "
                          data-hotelid="1097107"
                          data-class="5"
                          data-score="8.4"
                          data-highlightedhotel="1097107"
                        >
                          <div
                            className="sr_item_photo sr_card_photo_wrapper add-wow-red-tag"
                            id="hotel_1097107"
                          >
                            <div
                              className="add-red-tag 
                                  add-red-tag--ribbon 
                                  all-inclusive-ribbon 
                                  js_icon_over_photo
                                  bfast-included-ribbon"
                              data-et-view=" OLGHeTEFDZSJLZZCYWebFfKcVeRe:1 OLGHeTEFDZSJLZZCYWebFfKcVeRe:5 "
                            >
                              <div className="add-red-tag__content">
                                <span className="add-red-tag__amount add-red-tag__amount_all_inclusive add-red-tag__amount--small">
                                  Best Services
                                  <span className="add-red-tag__symbol"></span>
                                </span>
                              </div>
                            </div>
                            <a
                              className=" sr_item_photo_link sr_hotel_preview_track  "
                              rel="noopener"
                              tabindex="-1"
                              focusable="false"
                              aria-hidden="true"
                            >
                              {/* <img
                                    className="hotel_image"
                                    src="https://cf.bstatic.com/xdata/images/hotel/square200/183067061.webp?k=cb84b55cc2ff46207844efe69bddbfb9c8663c3937756b67a6ba216f006e044f&amp;o="
                                    data-highres="https://cf.bstatic.com/xdata/images/hotel/square600/183067061.webp?k=cb84b55cc2ff46207844efe69bddbfb9c8663c3937756b67a6ba216f006e044f&amp;o="
                                    alt="Faisalabad Serena Hotel"
                                    data-google-track="Click/sr_item_main_photo/0"
                                    width="200"
                                    height="200"
                                  /> */}
                              <img src={img} style={{ width: "100%" }} />
                              <span className="invisible_spoken">
                                Opens in new window
                              </span>
                            </a>
                          </div>
                          <div className="sr_item_content sr_item_content_slider_wrapper ">
                            <div className="sr_property_block_main_row">
                              <div className="sr_item_main_block">
                                <div className="wow-hotel__title-wrap">
                                  <h3 className="wow-hotel__title  ">
                                    <a
                                      className="hotel_name_link url"
                                      rel="noopener"
                                    >
                                      <span
                                        className="wow-hotel__name"
                                        data-et-click="    customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1    "
                                      >
                                        {name}
                                        {/* Faisalabad Serena Hotel */}
                                      </span>
                                      <span className="invisible_spoken">
                                        Opens in new window
                                      </span>
                                    </a>
                                  </h3>
                                  <span className="wow-hotel__title-badges">
                                    <i
                                      className="wow-icon-wrapper wow-icon-stars star_track"
                                      title="5-star hotel"
                                    >
                                      <Rate
                                        defaultValue={3}
                                        character={({ index }) => {
                                          return customIcons[index + 1];
                                        }}
                                      />
                                    </i>
                                  </span>
                                </div>
                                <div className="sr_card_address_line">
                                  <a
                                    className="bui-link"
                                    data-google-track="Click/Action: sr_map_link_used"
                                    data-coords="73.083575963974,31.4271238764644"
                                    target="_blank"
                                    data-map-caption=""
                                    rel="noopener"
                                    data-et-click="customGoal:YdXfdKNKNKHUScFAYTZTEeNGTODXKe:3"
                                  >
                                    {country}
                                    {/* <span className="sr_card_address_line__item">
                                          <span className="sr_card_address_line__dot-separator"></span>
                                          Show on map
                                        </span> */}
                                  </a>
                                  <span className="sr_card_address_line__dot-separator"></span>
                                </div>
                              </div>
                              <div className="sr_item_review_block">
                                <div className="reviewFloater reviewFloaterBadge__container">
                                  <div className="wow-review-score">
                                    <a className="wow-review-score__link">
                                      <div className="bui-review-score c-score bui-review-score--end">
                                        {" "}
                                        <div
                                          className="bui-review-score__badge"
                                          aria-label="Scored 8.4 "
                                        >
                                          {" "}
                                          8.4{" "}
                                        </div>{" "}
                                        <div className="bui-review-score__content">
                                          {" "}
                                          <div className="bui-review-score__title">
                                            {" "}
                                            Very Good{" "}
                                          </div>{" "}
                                          <div className="bui-review-score__text">
                                            {" "}
                                            157 reviews{" "}
                                          </div>{" "}
                                        </div>{" "}
                                      </div>
                                      <span className="invisible_spoken">
                                        Opens in new window
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="wow-badges__row">
                              <div className="wow-property-highlights">
                                <span data-et-view="OLWQREDRETJUTGCdNJBcSTKe:1 OLWQREDRETJUTGCdNJBcSTKe:2"></span>
                                <div className="bui-spacer--small wow-badge-block">
                                  <svg
                                    className="wow-icon -streamline-hospital_shield"
                                    fill="#333333"
                                    height="16"
                                    width="16"
                                    viewBox="0 0 24 24"
                                    role="presentation"
                                    aria-hidden="true"
                                    focusable="false"
                                  >
                                    <path d="M18 9a1.5 1.5 0 0 0-1.5-1.5h-2.25l.75.75V6a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 9 6v2.25l.75-.75H7.5A1.5 1.5 0 0 0 6 9v3a1.5 1.5 0 0 0 1.5 1.5h2.25L9 12.75V15a1.5 1.5 0 0 0 1.5 1.5h3A1.5 1.5 0 0 0 15 15v-2.25l-.75.75h2.25A1.5 1.5 0 0 0 18 12V9zm-1.5 0v3h-2.25a.75.75 0 0 0-.75.75V15h-3v-2.25a.75.75 0 0 0-.75-.75H7.5V9h2.25a.75.75 0 0 0 .75-.75V6h3v2.25c0 .414.336.75.75.75h2.25zm-15-5.077v7.614c0 5.237 3.225 9.933 8.113 11.813l1.041.4a3.75 3.75 0 0 0 2.692 0l1.041-.4A12.657 12.657 0 0 0 22.5 11.537V3.923a2.234 2.234 0 0 0-1.306-2.044A22.463 22.463 0 0 0 11.993 0C8.846-.03 5.715.61 2.818 1.874A2.24 2.24 0 0 0 1.5 3.928zM3 3.918a.736.736 0 0 1 .43-.675A20.937 20.937 0 0 1 11.993 1.5a20.969 20.969 0 0 1 8.589 1.748c.252.116.42.38.418.67v7.619c0 4.616-2.843 8.756-7.151 10.413l-1.041.4c-.52.2-1.096.2-1.616 0l-1.041-.4A11.157 11.157 0 0 1 3 11.537V3.923z"></path>
                                  </svg>
                                  <span>
                                    Extra health &amp; safety measures taken
                                  </span>
                                </div>
                              </div>
                              <div className="genius-benefits-badges"></div>
                            </div>
                            <div className="sr_rooms_table_block clearfix sr_card_rooms_container">
                              <div className="room_details ">
                                <div
                                  className="featuredRooms sr_room_table sr_room_table_flex sr_rt_wider_urgency_msg sr_card_room_info__container  nohead sr_rms_tbl_bdr "
                                  cellspacing="0"
                                  role="presentation"
                                >
                                  <div>
                                    <div
                                      className="roomrow roomrow_flex entire_row_clickable"
                                      data-link="/hotel/pk/faisalabad-serena.en-us.html?aid=808942&amp;label=europeanbd-hotelspage-sb-click-topbox&amp;sid=cc98ca06f46cb4162221e8cfc1ff0d7b&amp;all_sr_blocks=109710712_274304051_0_1_0&amp;checkin=2020-08-31&amp;checkout=2020-09-01&amp;dest_id=-2759985&amp;dest_type=city&amp;group_adults=2&amp;group_children=0&amp;hapos=1&amp;highlighted_blocks=109710712_274304051_0_1_0&amp;hpos=1&amp;no_rooms=1&amp;sr_order=popularity&amp;sr_pri_blocks=109710712_274304051_0_1_0__9000&amp;srepoch=1598857501&amp;srpvid=580431ceafba01dc&amp;ucfs=1&amp;from=searchresults;show_room=109710712#RD109710712"
                                      data-target="_blank"
                                      tabindex="0"
                                      data-et-click="   customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1  "
                                    >
                                      <div className="roomName roomName_flex --wider unit-info">
                                        <div className="roomNameInner">
                                          <div className="room_link">
                                            <span
                                              style={{ color: "#000" }}
                                              role="link"
                                              tabindex="0"
                                            >
                                              <strong>Passengers</strong>
                                              &nbsp;–&nbsp;
                                              <div
                                                className="c-occupancy-icons "
                                                style={{
                                                  fontSize: "10px",
                                                  color: "#000000",
                                                }}
                                              >
                                                <span
                                                  className="c-occupancy-icons__adults "
                                                  aria-hidden="true"
                                                >
                                                  <i className="fas fa-user"></i>
                                                  <i className="fas fa-user"></i>
                                                </span>
                                                <span className="bui-u-wow-only">
                                                  ...
                                                </span>
                                              </div>
                                            </span>
                                          </div>
                                          <span className="room_info">
                                            <div data-et-view="NAFLeOeJAETbUBPTVdCTEPMWQIXe:1"></div>
                                            <i class="fas fa-fan"></i> Air
                                            conditioning
                                            <br />
                                            <i class="fas fa-cog fa-spin"></i>{" "}
                                            Automatic Transmission
                                            <div className="c-beds-configuration c-beds-configuration--inline">
                                              4 Seats
                                            </div>
                                          </span>
                                          <div className="sr_card_room_policies__container">
                                            <sup className="sr_room_reinforcement-risk-text">
                                              Risk Free: You can cancel later,
                                              so lock in this great price today!
                                            </sup>
                                          </div>
                                          <div className="js_sr_persuation_msg"></div>
                                        </div>
                                      </div>
                                      <div
                                        className="roomPrice roomPrice_flex  sr_discount "
                                        colspan="2"
                                      >
                                        <div className="prco-wrapper bui-price-display prco-wow-default-assembly-wrapper prc-d-wow-wrapper">
                                          <div className="prco-ltr-right-align-helper">
                                            <div className="bui-price-display__label prco-inline-block-maker-helper">
                                              2 Doors, 4 Bags
                                            </div>
                                          </div>
                                          <div className="prco-ltr-right-align-helper">
                                            <div className="prco-inline-block-maker-helper">
                                              <div
                                                className="bui-price-display__value prco-text-nowrap-helper prco-inline-block-maker-helper"
                                                aria-hidden="true"
                                                data-et-mouseenter="customGoal:AdeKbCcBUfQUaSHbZFVXOJUNQKFcFXZYCaJFSSZRe:2"
                                              >
                                                PKR&nbsp;{price}
                                              </div>
                                              <span className="bui-u-wow-only">
                                                Price PKR&nbsp;{price}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="prco-ltr-right-align-helper">
                                            <div
                                              className="prd-taxes-and-fees-under-price prco-inline-block-maker-helper blockuid-"
                                              data-excl-charges-raw="2397.60106974966"
                                              data-cur-stage="2"
                                            >
                                              {" "}
                                              +PKR&nbsp;2,398 taxes and charges{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          data-component="earn-credits"
                                          className="c-earn-credits "
                                          data-earn-credits-raw=""
                                          data-prevent-propagation=""
                                        ></div>
                                        <div>
                                          <div>
                                            <div
                                              colspan="4"
                                              className="roomFooter"
                                            >
                                              <div
                                                className="wow-cta-button-row"
                                                data-et-click="    customGoal:OQLOLOLOXaTFKGHCcZQHKKATXT:1   "
                                              >
                                                <a
                                                  className="txp-cta bui-button bui-button--primary sr_cta_button"
                                                  data-et-click="customGoal:OQLOLOLOMSVSRQbVKKMadMUWe:1 customGoal:OQLOLOLOMSVSRQbVKKMadMUWe:3"
                                                  data-click-store-id="wow-compset-1097107"
                                                >
                                                  <Link
                                                  to="/RentalCarsBookingDetailsForm"
                                                    style={{ color: "white" }}
                                                    className="bui-button__text"
                                                  >
                                                    Book Holiday
                                                  </Link>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                );
              })}
            </Container>
          )}
        </div>
      </div>
    );
  }
}
export default Rental_Cars_Search;
