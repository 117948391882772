import React, { Component } from "react";
import { Carousel, WithStyles } from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../Wowholidaycards/wowholidaycards.scss";
import { Link } from "react-router-dom";

import {
  Form,
  Card,
  Button,
  Row,
  Container,
  NavDropdown,
  DropdownButton,
  Dropdown,
  Navbar,
  Nav,
  Header,
  FormLabel,
  FormControl,
  Col,
  View,
} from "react-bootstrap";

// const responsive = {
//   desktop: {
//     breakpoint: {
//       max: 3000,
//       min: 1024,
//     },
//     items: 3,
//     partialVisibilityGutter: 40,
//   },
//   mobile: {
//     breakpoint: {
//       max: 464,
//       min: 0,
//     },
//     items: 1,
//     partialVisibilityGutter: 30,
//   },
//   tablet: {
//     breakpoint: {
//       max: 1024,
//       min: 464,
//     },
//     items: 2,
//     partialVisibilityGutter: 30,
//   },
// };

class Multicarouselplaces extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isHidden: false };
    this.state = { date: new Date().toLocaleString() };
  }
  // componentWillMount(){

  // }
  // componentDidMount(){

  // }
  getDate() {
    var date = { currentTime: new Date().toLocaleString() };

    this.setState({
      date: date,
    });
  }

  scrolltoup = () => {
    const scrollingup = document.querySelector("#scrollingup");

    scrollingup.addEventListener("click", function () {
      // window.scrollTo(0 , 0);

      window.scrollTo({
        top: 0,
        left: 0,
        behaviour: "smooth",
      });

      // $("html, body").animate({scrollTo: 0}, "slow");
    });
  };

  render() {
    return (
      <div>
        <h1>asdasd</h1>

        <Container>
          {/* <Carousel
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className="car"
            containerClass="container"
            dotListClass="custom-dot-list-style"
            draggable
            focusOnSelect={false}
            infinite={false}
            // responsive={responsive}
            itemClass="carousel-item-padding-40-px"
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            showDots={false}
            sliderClass=""
            slidesToSlide={2}
            swipeable
          >
            <div className="box-around-image-2">
              <Link>
                <img
                  className="images-in-carousel-in-wow-holiday-2"
                  src="IMAGES/city-images/holiday-cards/places/atlantis.jpg"
                ></img>
                <div className="centered-text-cards-locations-holidaycards-2">
                  Atlantis the Palm
                </div>
                <div className="new-cards-wow-holiday-places">
                  <i
                    className="fa fa-map-marker-alt holiday-cards-hot-places-icons"
                    aria-hidden="true"
                  ></i>
                  <div className="country-name-holiday-cards-places">
                    Dubai, UAE
                  </div>
                </div>
              </Link>
            </div>

            <div className="box-around-image-2">
              <Link>
                <img
                  className="images-in-carousel-in-wow-holiday-2"
                  src="IMAGES/city-images/holiday-cards/places/atlantis.jpg"
                ></img>
                <div className="centered-text-cards-locations-holidaycards-2">
                  Atlantis the Palm
                </div>
                <div className="new-cards-wow-holiday-places">
                  <i
                    className="fa fa-map-marker-alt holiday-cards-hot-places-icons"
                    aria-hidden="true"
                  ></i>
                  <div className="country-name-holiday-cards-places">
                    Dubai, UAE
                  </div>
                </div>
              </Link>
            </div>

            <div className="box-around-image-2">
              <Link>
                <img
                  className="images-in-carousel-in-wow-holiday-2"
                  src="IMAGES/city-images/holiday-cards/places/atlantis.jpg"
                ></img>
                <div className="centered-text-cards-locations-holidaycards-2">
                  Atlantis the Palm
                </div>
                <div className="new-cards-wow-holiday-places">
                  <i
                    className="fa fa-map-marker-alt holiday-cards-hot-places-icons"
                    aria-hidden="true"
                  ></i>
                  <div className="country-name-holiday-cards-places">
                    Dubai, UAE
                  </div>
                </div>
              </Link>
            </div>

            <div className="box-around-image-2">
              <Link>
                <img
                  className="images-in-carousel-in-wow-holiday-2"
                  src="IMAGES/city-images/holiday-cards/places/atlantis.jpg"
                ></img>
                <div className="centered-text-cards-locations-holidaycards-2">
                  Atlantis the Palm
                </div>
                <div className="new-cards-wow-holiday-places">
                  <i
                    className="fa fa-map-marker-alt holiday-cards-hot-places-icons"
                    aria-hidden="true"
                  ></i>
                  <div className="country-name-holiday-cards-places">
                    Dubai, UAE
                  </div>
                </div>
              </Link>
            </div>
          </Carousel> */}
        </Container>
        <br />
      </div>
    );
  }
}
export default Multicarouselplaces;
