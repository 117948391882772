import React, { Component, useEffect } from "react";
import '../Lecustomisedservices/lecustomisedservies.scss';
import "react-multi-carousel/lib/styles.css";

import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Visible, Hidden, setConfiguration } from "react-grid-system";

import {
  Container,
  Row,
  DropdownButton,
  Dropdown,
  Col,
  Button,
  Media
} from "react-bootstrap";



class ComponentToPrint extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      toggle: true,
    };
  }
  
  render() {
    return (
      <div>


        <div>
          <Container>
            <Row>
              <Col xl={12} lg={12} md={12}>
                <div className="invoice-le-view_area-customisedservices" style={{overflow:'auto'}}>
                
                 

                  <div className="invoice-customisedservices-div-body-le">
                    <div className="invoice-customisedservices-body-inside">





<div className="accountant-heading-customisedservices">
    Acountant Panel
</div>
        
<div className="customisedservices-heading-area">
    <div className="customisedservices-heading">
Customised Services
    </div>


    <div className="customisedservices-heading-area-filter-button">
    <DropdownButton id="dropdown-basic-button"
    variant='light' title="Filters">
  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
</DropdownButton>
    </div>
</div>

                   

{/*                    
                      <div className="customisedservices-template__divider--full-width">
                        <div className="le-divider-top-customisedservices"></div>
                      </div> */}








<div class="description_of_card_of_customisedservices">
 <div class="description_of_card_of_customisedservices_area">



<Col xl={12} md={12} lg={12}>
                      <div class="customisedservices-template__items-table">
                        <table class="le-table-customisedservices">

                          <thead class="le-table-customisedservices__header">
                            <tr class="le-table-customisedservices__row">
                              <th class="le-table-customisedservices__cell--amount" colspan="2">Name</th>


<th class="le-table-customisedservices__cell--amount class-table-particular-customisedservices"
 colspan="4">Description</th>

<th class="le-table-customisedservices__cell--amount" colspan="2">Price</th>

<th class="le-table-customisedservices__cell--amount" colspan="2">Actions</th>




            </tr>
            </thead>

            
            
              <tr class="le-table-customisedservices__row">
              <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices-col1">
                  <em class="le-text--emphasized-customisedservices">Basic</em>
                  </div>
                  </td>

                  <td class="le-table-customisedservices__cell" colspan="4">
                <div class="no-items-message-customisedservices-col2">
                  <em class="le-text--emphasized-customisedservices">In our basic plan you will get
                  premier class legal advice and opinions on your legal issues.</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices">AED137.00</em>
                  </div>
                  </td>

                 


                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices">
                      <span><i className="fa fa-eye"></i> &nbsp;&nbsp;
                      <i className="fa fa-eye"></i></span></em>
                  </div>
                  </td>
                  
                  
                  </tr>





                  <tr class="le-table-customisedservices__row">
              <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices-col1">
                  <em class="le-text--emphasized-customisedservices">Gold</em>
                  </div>
                  </td>

                  <td class="le-table-customisedservices__cell" colspan="4">
                <div class="no-items-message-customisedservices-col2">
                  <em class="le-text--emphasized-customisedservices">From viewing to drafting contacts
                and agreements, including organizing the relationship with the contacting parties.</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices">AED1222,00</em>
                  </div>
                  </td>



                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices"><span><i className="fa fa-eye"></i>
                   &nbsp;&nbsp;
                      <i className="fa fa-eye"></i></span></em>
                  </div>
                  </td>
                  
                  
                  </tr>







                  <tr class="le-table-customisedservices__row">
              <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices-col1">
                  <em class="le-text--emphasized-customisedservices">Open a File</em>
                  </div>
                  </td>

                  <td class="le-table-customisedservices__cell" colspan="4">
                <div class="no-items-message-customisedservices-col2">
                  <em class="le-text--emphasized-customisedservices">Charged when a new file is open.</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices">AED100.00</em>
                  </div>
                  </td>

                


                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices"><span><i className="fa fa-eye"></i> 
                  &nbsp;&nbsp;
                      <i className="fa fa-eye"></i></span></em>
                  </div>
                  </td>
                  
                  
                  </tr>




                  <tr class="le-table-customisedservices__row">
              <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices-col1">
                  <em class="le-text--emphasized-customisedservices">Silver</em>
                  </div>
                  </td>

                  <td class="le-table-customisedservices__cell" colspan="4">
                <div class="no-items-message-customisedservices-col2">
                  <em class="le-text--emphasized-customisedservices">In our silver plan, we will work on 
                  your judicial notices while talking all the necessary measures possible.</em>
                  </div>
                  </td>
                  
                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices">AED100.00</em>
                  </div>
                  </td>

                


                  <td class="le-table-customisedservices__cell" colspan="2">
                <div class="no-items-message-customisedservices">
                  <em class="le-text--emphasized-customisedservices"><span><i className="fa fa-eye"></i>
                   &nbsp;&nbsp;
                       <i className="fa fa-eye"></i></span></em>
                  </div>
                  </td>
                  
                  
                  </tr>
                  
                  </table>
                  </div>
                  </Col>





                  





              





        </div>
        </div>








                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>




        









      </div>
    );
  }
}

class LEcustomisedservices extends React.Component {
  render() {
    return (
      <div>
        <Container>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <Button 
            style={{borderRadius:'20px'}}
            variant="outline-dark">Print this out!</Button>;
          }}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={el => (this.componentRef = el)} />
        </Container>
      </div>
    );
  }
}

export default LEcustomisedservices;
