import React, { Component } from "react";


class Emaildisapproval extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }
  componentWillMount(){

  }
  componentDidMount(){
      
  }
  render() {
    return (

    

    
<div >

<div class="disapproval-email-wow-header">
	<center>
		<img class="wow-image-in-disapproval-email"   src="IMAGES/wowboard.png"/>
	</center></div>
  
    
 

     
<div class="container">

      <div class="text-written-in-disapproval-email">
		<p class="text" >
			Email Disapproval
		</p>
		<p class="text" >
			Dear Muhammad Tahir,
		</p>

        <p class="text" >
			Thank You for showing your interest in becoming a leader of <br/>
			WOW Electronic Transport Services.
		</p>


		<p class="text" >
			This is to disapproval you that your application was recieved and reviewed. <br/>
             Unfortunately, your profile and document dosen't meet the <br/>
             criteria of WOW. Sadly, your account has not been approved WOW <br/>
            by WOW Security Team.For further info,please contact<br/>
             Helpline and Support.
		</p>
		<br/>
		<p class="text" >
			For further info contact info@wowets.com<br/>
			WOW helpline +971 45 217500 <br/>
			Regards, <br/>
			WOW &nbsp; ETS.
		</p>
	
		<p   class="text-thank-you" >
			THANK YOU 
		</p>

	</div></div>
	<br/>

	<div class="container">

	<div class="statement-header-line-in-disapproval-email"> </div>

    <p class="text-below-disapproval-footer-page" >
			The Electronic Transport Service was delivered exclusively by the 
            Leader. WOW affords entree to the platform where riders are coordinated with
            automonous Leaders who offer  <br/>
            those Electronic Transport Services openly to the riders. This is a 
            duty statement in admiration of WOW Electronic Services and the VAT aggregate
            redirected narrates only to WOW Electronic Service stipend where the VAT is 
            appropriate. If you have any queries, we are pleased to assist you.<br/><br>
            </br>
            Have a nice day. <br/>
            WOWETS team.

		</p>
       




	<div class="disapproval-email-footer-content">
  <div class="infoCons-in-disapproval-email">
     

       {/* <!-- row  --> */}


      <div class="horizontalRow-in-disapproval-email">

        <div class="disapproval-email-ones disapproval-email-inside-texts"><div class="itemCons-in-disapproval-email">
             <div class="iconCon-in-disapproval-email">
              <a> <img class="footer-baseline-image-disapproval-email" src="IMAGES/calllogo.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-disapproval-email">
               Phone: +971 45 217500
             </span>
            </div></div>


        <div class="disapproval-email-twos disapproval-email-inside-texts"><div class="itemCons-in-disapproval-email">
             <div class="iconCon-in-disapproval-email">
             <a> <img  class="footer-baseline-image-disapproval-email" src="IMAGES/msg.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-disapproval-email">
               info@wowets.com
             </span>
            </div></div>

        <div class="disapproval-email-threes disapproval-email-inside-texts"><div class="itemCons-in-disapproval-email">
             <div class="iconCon-in-disapproval-email">
             <a> <img class="footer-baseline-image-disapproval-email" src="IMAGES/browser.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-disapproval-email">
               wowets.com
             </span>
            </div></div>


        <div class="disapproval-email-four disapproval-email-inside-texts"><div class="itemCons-in-disapproval-email">
             <div class="iconCon-in-disapproval-email">
             <a> <img class="footer-baseline-image-disapproval-email" src="IMAGES/location.png" alt=""/></a>
             </div>
             <span class="iconDescss-in-disapproval-email">
               WOW Electronic Transport Services, <br/>
                34th floor Burj Al Salam Tower, Trade <br/>
                Centrel, Sheikh Zayed Road Dubai, UAE
             </span>
            </div></div>
          
        </div>
      </div>
      </div>  </div>





</div>

      


      
  


    




    

       
        
       

    
   
   

    


 
     
    
    
    );
  }
}
export default Emaildisapproval;