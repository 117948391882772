import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Visible, Hidden, setConfiguration } from "react-grid-system";
import { Form, Container, Card, Row, Slider, Col } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  Button,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost12/blogpost12.scss";

class Blogpost12 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
      show: true,
    };
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>
        <div className="td-post-header-holder-blogcard12 td-image-gradient-blogcard12">
          <div className="td-post-featured-image-blogcard12">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard12"
              src="IMAGES/city-images/wow-blog/additional-cards/blog12/bg-image.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard12">
              <ul className="td-category-blogcard12">
                <li className="entry-category-blogcard12">
                  <a target="blank" href="https://www.wowets.com/touristland">
                    Safe & Warm Welcome
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard12">
                Wow: Travel hacks & tips
              </h1>

              <h4 className="entry-title-two-blogcard12">
                Safe and Warm Welcome as First Tourists Land in Dubai
              </h4>

              <div className="td-module-meta-info-blogcard12">
                <span className="td-post-date-blogcard12">
                  <time
                    className="entry-date-blogcard12 updated td-module-date-blogcard12"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    25 JULY 2020
                  </time>
                </span>

                <div className="td-post-views-blogcard12">
                  <i className="fa fa-eye view-icon-views-blogcard12"></i>
                  <span className="td-nr-views-10968">32239</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard12">
            <div className="heading-secondary-uk-section-wow-blogscard12 ">
              Travel hacks & tips
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard12-description-area-of-main--text-above">
            As the clock struck 12 and the date changes to July 7, the first set
            of tourists landed in Dubai at the Dubai International Airport.
            <br />
            It was announced that airports in Dubai will welcome tourists from
            the 7th of July. Now as the day comes, Dubai, one of the most famous
            tourist attractions in the world has officially reopened tourism.
            Dubai is among the most visited cities in the world, there is a huge
            number of people who travel to Dubai every year.
            <br />
            Dubai welcomed a record of 16.73 million tourists last year in 2019,
            an improvement over the previous two years driven by rapidly
            increasing Chinese, Russian, and Omani visitors. The number of
            international visitors grew by 5.1 percent last year.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard12-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog12/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard12-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog12/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard12-description-area-of-main--text-above">
            Reflecting the gesture of its famous hospitality even during these
            times, Dubai found unique ways to welcome travelers with special
            stickers on passports saying: ‘A warm welcome to your second home.’
            These little steps always keep the spirits high and Dubai is good
            doing these kinds of stuff. Dubai being the shopping paradise of the
            world that brags of its iconic landmarks and magnificent malls are
            good enough to attract the families and making their holiday a
            special one.
          </p>

          <p class="blogscard12-description-area-of-main--text-above">
            The World Travel and Tourism Council (WTTC) gave Dubai the Safe
            Travel stamp a few days ago, validating its approach towards the
            pandemic and the strict sterilization steps the city has taken. A
            recent Covid-19 negative certificate of the person willing to enter
            will be required at Dubai airports. Without any valid proof that the
            tourist has not been carrying Coronavirus, he won’t be able to
            enter. And the travelers must have travel insurance with Covid-19
            declaring that they would bear all the expenses of treatment and
            isolation if needed.
          </p>
        </Container>
        <br />

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogcard12">
            <div className="heading-secondary-section-wow-blogcard12 ">
              Incredible Stays to Make Your Working From “Home”, What you should
              Know:
            </div>
          </div>
        </Container>

        <Container>
          <div
            id="accordion-5ed01b058660a"
            className="accordion indicator-plus accordion-white mb-3"
            role="tablist"
          >
            <div className="card-blogcard12 bar">
              <div className="card-blogcard12 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-0"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard12-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-0"
                  >
                    <span role="heading" aria-level="0">
                      WOW Welcomes Tourists
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-0"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-0"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard12">
                    <Row>
                      <Col lg={6}>
                        <p style={{ padding: "3rem" }}>
                          WOW welcomes the tourists coming from all over the
                          world to explore this wonderful city. WOW is set to
                          give the high-class ride-sharing services to the
                          citizens as well as the foreigners. We emphasize
                          travelers to take the best riding services in the city
                          with the experience they never have experienced
                          before.
                          <ul className="collapse-section-blogpost">
                            <li>
                              Dubai is a lot friendlier than any other city..
                              Dont worry about it and just book.. Youll love it.
                            </li>

                            <li>
                              Dubai is a bustling centre of excellence; and in
                              addition to being the commercial capital and
                              tourism centre of the region
                            </li>
                          </ul>
                        </p>
                      </Col>

                      <Col lg={6}>
                        <div className="wow-news-card1-work-page-top-image-blogscard9-aboveimage">
                          <img
                            style={{ width: "100%" }}
                            src="IMAGES/city-images/wow-blog/additional-cards/blog12/collapse1.png"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard12 bar">
              <div className="card-blogcard12 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-1"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard12-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-1"
                  >
                    <span role="heading" aria-level="0">
                      What is the best time to travel Dubai?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-1"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-1"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard12">
                    <p style={{ marginBottom: "1rem" }}>
                      You can pay a visit to Dubai throughout the year. But
                      tourists generally visit Dubai from September to April
                      when climate is breezy and healthy. Dubai is one of the
                      safest cities in the world. However, make sure to obey the
                      rules and regulation. UAE is a Muslim country, so
                      spiritual sensitivities must be taken into concern.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard12 bar">
              <div className="card-blogcard12 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-2"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard12-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-2"
                  >
                    <span role="heading" aria-level="0">
                      What are the must do experiences in Dubai?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-2"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-2"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard12">
                    <p>
                      There are plenty things to do in Dubai, but if you are
                      here for a short period of time then you must experience
                      Desert Safari, Dinner cruise in the evening, Burj
                      Khalifa’s top view and Dubai City tour. These experiences
                      are not worth missing on your Dubai tour Package.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard12 bar">
              <div className="card-blogcard12 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-5"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard12-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-5"
                  >
                    <span role="heading" aria-level="0">
                      Is Dubai safe to travel to for tourists?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-5"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-5"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard12">
                    <p>
                      Absolutely yes – Dubai has rapidly become one of the most
                      popular go-to destinations for holidays and travellers and
                      has a multitude of attractions, sight-seeing, activities
                      and historical spots to explore. In fact, Dubai is one of
                      safest cities in arguably THE safest country in the Middle
                      East. Thanks to stricter laws, crime is kept to a minimum
                      and you shouldn’t be surprised to learn that many tourists
                      feel very safe during their stay in Dubai.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="gradient-back-travel-blogscard12">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard12-grid-text">
                  <div className="travel-blogscard12-page-araea-sub-headings110">
                    Travel with WOW
                  </div>

                  <div className="travel-blogscard12-page-page-text">
                    WOW Electronic Transport Services (WOW) is emerging as a new
                    'Leader' in the commutation industry as it intends to hit
                    the highest levels of security and reliability by serving
                    the most advanced, reliable, and secures rides in one of the
                    best cities of the world. WOW has a lot of options for you
                    to choose from in the cars. Choose whatever suits your needs
                    and budget. WOW is using the latest technology that will
                    help customers to choose the best option out of multiple
                    riding categories.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog12/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard12">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog12/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard12-grid-text">
                  <div className="travel-blogscard12-page-araea-sub-headings110">
                    Travel with Us
                  </div>

                  <div className="travel-blogscard12-page-page-text">
                    WOW Electronic Transport Services (WOW) is emerging as a new
                    'Leader' in the commutation industry as it intends to hit
                    the highest levels of security and reliability by serving
                    the most advanced, reliable, and secures rides in one of the
                    best cities of the world. WOW has a lot of options for you
                    to choose from in the cars. Choose whatever suits your needs
                    and budget. WOW is using the latest technology that will
                    help customers to choose the best option out of more than 12
                    multiple categories including the following: -
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard12">
            <div className="heading-secondary-help-community-section-wow-blogscard12 ">
              Dubai Travel Hacks
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard12">
            <div class="description_of_card_of_blogscard12_area">
              <h2 class="blogscard12-description-area-of-main-headings">
                Grab a Go Dubai Card with no second thoughts …
              </h2>
              <p class="blogscard12-description-area-of-main--text">
                The Go Dubai Card is the smartest investment you can make upon
                reaching Dubai. This card allows you to save on the admission
                rates to many of Dubai’s top attractions. The card currently
                includes access to the top of Burj Khalifa, Dubai sightseeing
                tours, and many other activities that would otherwise cost you
                dearly.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog12/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog12/help-tips-grid2.jpg" />
                </Col>
              </Row>
              <h2 class="blogscard12-description-area-of-main-headings">
                Best Time To Visit Dubai.....
              </h2>
              <p class="blogscard12-description-area-of-main--text">
                There are a number of factors that need to be considered before
                deciding on a time to visit Dubai. If there is one thing that is
                not ideal in Dubai, it is the weather. Summer in Dubai spans
                from May to September and sees extremely hot weather. Winter
                sees better weather but also experiences a sharp spike in the
                tourist footfall. If you had to pin it down to specific months
                of the year, February and November are the best times to visit
                Dubai. However, it must be noted that Dubai is never completely
                devoid of tourists.
              </p>
              <h2 class="blogscard12-description-area-of-main-headings">
                Know the Dubai Airport well....
              </h2>
              <p class="blogscard12-description-area-of-main--text">
                Fittingly, the Dubai International Airport is huge in every
                sense of the term. It will take you quite some time to get from
                one part of the airport to the other. Do not plan any activities
                immediately after arriving. Also, leave for the airport well in
                advance to board your flight.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard12">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog12/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard12">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog12/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <h2 className="blogscard12-description-area-of-main-headings">
                Dubai's not as conservative as you think...
              </h2>
              <p className="blogscard12-description-area-of-main--text">
                You may have heard horrific stories of foreigners being locked
                up for PDA, public inebriation and homosexuality. All that said,
                Dubai is way more liberal than most Islamic countries. There are
                no concrete laws in Dubai that forbid anybody from wearing any
                kind of clothes but being a country with conservative values, it
                is preferable and respectful to wear clothes that cover the
                shoulders and knees. The nightlife is quite happening and you'll
                find expats and tourists drinking and canoodling in the many
                bars and clubs in the city. There's even a healthy gay community
                in Dubai! As long as you steer clear of cultural
                insensitivities, you're good to go in Dubai.
              </p>
              <h2 className="blogscard12-description-area-of-main-headings">
                TO ASCEND THE WORLD’S TALLEST BUILDING.....
              </h2>
              <p className="blogscard12-description-area-of-main--text">
                A true symbol of Dubai’s wondrous modern architecture, the Burj
                Khalifa is the world’s tallest building and a globally iconic
                structure. There are two observation decks to peer out from,
                found on level 124 and 148, the latter sitting at 1,821 feet and
                providing incredible panoramic views. Why not take things a step
                further by dining in the world’s highest restaurant on the level
                122?
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard12">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog12/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard12">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog12/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <h2 class="blogscard12-description-area-of-main-headings">
                FOR THE MAN-MADE ISLANDS AND BEACHES...
              </h2>
              <p class="blogscard12-description-area-of-main--text">
                Dubai’s man-made islands, or artificial archipelagos as they’ve
                also been coined, are another example of the metropolis’
                grandeur. The Palm Islands, or Palm Jumeirah, are built into the
                shape of a palm tree and are listed as one of the only man-made
                structures visible from space. Primarily home to luxury resorts
                and mega mansions, one of the best ways to explore these islands
                is via a yacht trip.{" "}
              </p>
              <p class="blogscard12-description-area-of-main--text">
                We found this out because my neighbour is an artist and spends
                his time between North Wales and Dubai. Check him out! –
                dollarsandart. From stylish art galleries in the Financial
                Centre to more ‘hip’ art joints found along Alserkal Avenue,
                artists and collectors are becoming increasingly drawn to Dubai.
              </p>
              <div class="description_of_card_of_blogscard12_bottom_area">
                <div class="description_of_card_of_blogscard12_bottom_area_previous">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fas fa-reply" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard12_bottom_area_next">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fa fa-mail-forward" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost12;
