import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Form,
  Container,
  Card,
  Row,
  Slider,
  Button,
  Col,
} from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  useRouteMatch,
  Link,
  browserhistory,
} from "react-router-dom";
import "../Wowholidayblogpost14/blogpost14.scss";

class Blogpost14 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      date: new Date().toLocaleString(),
      visible: false,
      value: "random text",
      show: true,
      isOpen: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  //  componentDidMount() {
  //   $("button").click(function() {
  //     $("h8").toggleClass("red");
  //   });
  //  }

  openModal() {
    this.setState({ isOpen: true });
  }

  onFormSubmit() {
    console.log("Form Submitted");
  }

  closeNavnewsroom = () => {
    document.getElementById("mySidenav-newsroom").style.width = "0px";
    document.getElementById("close-button-newsroom").style.display = "none";
  };

  render() {
    const scrollToblogforward = () => window.scrollTo({ top: 0 });
    const { showHideFName, showHideLName } = this.state;
    return (
      <div>
        <div className="td-post-header-holder-blogcard14 td-image-gradient-blogcard14">
          <div className="td-post-featured-image-blogcard14">
            <img
              width="1068"
              height="580"
              className="entry-thumb td-animation-stack-type0-2-blogcard14"
              src="IMAGES/city-images/wow-blog/additional-cards/blog14/bg-image2.jpg"
            />
          </div>
          <center>
            <header className="td-post-title-blogcard14">
              <ul className="td-category-blogcard14">
                <li className="entry-category-blogcard14">
                  <a
                    target="blank"
                    href="https://www.gov.uk/foreign-travel-advice/united-arab-emirates/entry-requirements#:~:text=Before%20visitors%20fly%20to%20Dubai,the%20COVID%2D19%20DXB%20App."
                  >
                    Dubai Travel Rules
                  </a>
                </li>
              </ul>

              <h1 className="entry-title-blogcard14">
                WOW: Travel hacks and tips
              </h1>

              <h4 className="entry-title-two-blogcard14">
                Dubai Travel rules and regulations, you need to know and follow
              </h4>

              <div className="td-module-meta-info-blogcard14">
                <span className="td-post-date-blogcard14">
                  <time
                    className="entry-date-blogcard14 updated td-module-date-blogcard14"
                    datetime="2018-11-07T10:59:17+00:00"
                  >
                    23 july 2020 / Dubai
                  </time>
                </span>

                <div className="td-post-views-blogcard14">
                  <i className="fa fa-eye view-icon-views-blogcard14"></i>
                  <span className="td-nr-views-10968">93239</span>
                </div>
              </div>
            </header>
          </center>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard14">
            <div className="heading_secondary_uk_section_wow_blogscard14">
              WOW Travel hacks and tips
            </div>
          </div>
        </Container>

        <Container>
          <p class="blogscard14-description-area-of-main--text-above">
            Dubai administration is very strict about the traffic rules, no one
            can break these rules. If someone does intentionally or by mistake,
            one will have to pay huge fines as penalties. Well, these rules are
            definitely for the safety of citizens of Dubai. The RTA (Roads &
            Transport Authority) Dubai keeps updating the traffic rules as the
            safety of the people is the priority of the authority.
            <br /> Whether you are a newbie or an experienced driver you must
            follow these laws in Dubai to avoid unexpected fines.
          </p>

          <Row style={{ marginBottom: "2rem" }}>
            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard14-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog14/image-above1.jpg"
                />
              </div>
            </Col>

            <Col xs={12} xl={6} lg={6} md={6} sm={12}>
              <div className="wow-news-card1-work-page-top-image-blogscard14-aboveimage">
                <img
                  style={{ width: "100%" }}
                  src="IMAGES/city-images/wow-blog/additional-cards/blog14/image-above2.jpg"
                />
              </div>
            </Col>
          </Row>

          <p class="blogscard14-description-area-of-main--text-above">
            All passengers travelling to or connecting through Dubai must have a
            negative COVID-19 PCR test certificate to be accepted on the flight.
            The certificate must be for a polymerase chain reaction (PCR) swab
            test taken a maximum of 96 hours before departure. Other test
            certificates including antibody tests and home testing kits are not
            accepted in Dubai. Bring an official, printed certificate – SMS and
            digital certificates are not accepted. Check this list of authorised
            laboratories around the world to find out where to get your COVID-19
            PCR test.
          </p>
        </Container>
        <br />

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogcard14">
            <div className="heading-secondary-section-wow-blogcard14 ">
              Dubai Travel Rules, Guide and Rules to stay in Dubai:
            </div>
          </div>
        </Container>

        <Container>
          <div
            id="accordion-5ed01b058660a"
            className="accordion indicator-plus accordion-white mb-3"
            role="tablist"
          >
            <div className="card-blogcard14 bar">
              <div className="card-blogcard14 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-0"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard14-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-0"
                  >
                    <span role="heading" aria-level="0">
                      Entry rules in response to coronavirus (COVID-19)
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-0"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-0"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard14">
                    <h4>Entry to the United Arab Emirates other than Dubai</h4>
                    <p>
                      Some foreign residents, including British Nationals, are
                      being allowed to return to the UAE based on criteria set
                      by the UAE authorities. Visit and tourist visas are not
                      currently being issued by the Abu Dhabi authorities at Abu
                      Dhabi airport. Visitors can travel to Abu Dhabi by road
                      but must have had a COVID-19 PCR test within 48 hours All
                      arrivals in Abu Dhabi must isolate for 14 days on arrival.
                    </p>

                    <h4>Dubai residents returning to or leaving Dubai</h4>
                    <p>
                      The government of Dubai announced new procedures on 21
                      June for returning residents. Dubai residents overseas
                      will be able to return to the Dubai from 22 June and Dubai
                      residents within Dubai will be able to travel overseas
                      from 23 June. Further information about what returning
                      residents need to do before they travel and what will
                      happen on arrival in Dubai is available on the Government
                      of Dubai website including information about testing on
                      arrival and quarantine.
                    </p>

                    <h4>Quarantine requirements</h4>
                    <p>
                      Stringent entry restrictions remain in place upon arrival
                      at all airports in the UAE which may include, but are not
                      limited to, a Health Authority COVID-19 PCR test on
                      arrival, mandatory quarantine period, usually via
                      self-isolation at a private address if suitable or a
                      hotel, for at least 14 days, and follow up testing before
                      you are able to complete quarantine. Once any quarantine
                      period is complete you must continue to comply with all
                      measures put in place by the UAE authorities to prevent
                      the spread of coronavirus.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard14 bar">
              <div className="card-blogcard14 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-1"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard14-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-1"
                  >
                    <span role="heading" aria-level="0">
                      Travelling to Dubai...
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-1"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-1"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard14">
                    <ul className="collapse-section-blogpost">
                      <li>
                        All passengers arriving in Dubai from any destination,
                        including passengers connecting in Dubai, must have a
                        printed negative COVID-19 PCR test certificate to be
                        accepted on the flight. The test must be taken a maximum
                        of 96 hours before departure. Bring an official printed
                        certificate – SMS and digital certificates are not
                        accepted. Children under the age of 12 and passengers
                        who have a moderate or severe disability are exempt from
                        the test requirement.
                      </li>

                      <li>
                        The UAE government has specified designated
                        laboratories. If you are flying from India, Pakistan and
                        Bangladesh, you must get your certificate from one of
                        these labs. If you are flying from any other country,
                        you can either use the recommended laboratories in the
                        same document or any trusted and certified laboratories
                        in your country of origin.
                      </li>

                      <li>
                        You may need to take another COVID-19 PCR test on
                        arrival. If you take a test at the airport, you must
                        remain in your hotel or residence until you receive the
                        test result. If the test result is positive, you will be
                        required to undergo isolation and follow the Dubai
                        Health Authority guidelines.
                      </li>

                      <li>
                        All travellers to Dubai, including passengers with a
                        connecting flight, must complete this health declaration
                        form and give it to the staff at check-in. This form
                        will also be available at check-in.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard14 bar">
              <div className="card-blogcard14 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-2"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard14-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-2"
                  >
                    <span role="heading" aria-level="0">
                      Travel documents
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-2"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-2"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard14">
                    <p>
                      The travel regulations in each destination are changing
                      frequently. Please check our Travel Requirements page for
                      the most recent information before you travel. Customers
                      are required to come to the airport with the appropriate
                      travel documents for their destination.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-blogcard14 bar">
              <div className="card-blogcard14 card-accordion ">
                <div
                  className="card-header collapsed"
                  id="accordion-5ed01b058660a-card-0"
                  data-target="#accordion-5ed01b058660a-collapse-5"
                  data-toggle="collapse"
                  role="tab"
                  aria-expanded="false"
                >
                  <button
                    className="card-title btn btn-link blogcard14-button-collapsible"
                    data-controls="accordion-5ed01b058660a-collapse-5"
                  >
                    <span role="heading" aria-level="0">
                      What tourist attractions are in Dubai?
                    </span>
                  </button>
                </div>
                <div
                  aria-labelledby="accordion-5ed01b058660a-card-5"
                  className="d-print-block collapse"
                  collapsed=""
                  id="accordion-5ed01b058660a-collapse-5"
                  role="tabpanel"
                >
                  <div className="card-body-blogcard14">
                    <p>
                      There are SO many, and all suit different ages and
                      lifestyles. From the Burj Khalifa, to The Beach in JBR, to
                      the IMG Worlds of Adventure theme park, to City Walk’s HUB
                      ZERO as well as Burj Al Arab, Souk Madinat, desert
                      safaris, sand dune buggy rides, to the Dubai Canal and
                      Dubai Marina… There’s SO much to see and do during your
                      time in Dubai, with options that suit all budgets. In
                      fact, why don’t you take a look at deal websites like
                      Cobone that have plenty of activity deals to help save you
                      some Dirhams during your stay here?
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="gradient-back-travel-blogscard14">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard14-grid-text">
                  <div className="travel-blogscard14-page-araea-sub-headings110">
                    Visitors to Dubai
                  </div>

                  <div className="travel-blogscard14-page-page-text">
                    New procedures for visitors to Dubai were announced by the
                    Government of Dubai on 21 June. Visitors are able to travel
                    to Dubai from 7 July. Further information about what
                    visitors need to do before they travel and what will happen
                    on arrival in Dubai is available on the Government of Dubai
                    website including information about testing on arrival and
                    quarantine.
                  </div>
                </div>
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog14/grid1.jpg" />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="gradient-back-travel-blogscard14">
          <Container>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <img src="IMAGES/city-images/wow-blog/additional-cards/blog13/grid2.jpg" />
              </Col>

              <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="padding-in-travel-blogscard14-grid-text">
                  <div className="travel-blogscard14-page-araea-sub-headings110">
                    Getting around in Dubai
                  </div>

                  <div className="travel-blogscard14-page-page-text">
                    Connect seamlessly from the airport to your meeting using
                    Dubai’s state-of-the-art transport system. You’ll find a
                    string of public taxis awaiting your arrival and, being a
                    relatively small city, you can expect to be at your hotel or
                    remote office in under 30 minutes. They accept most major
                    credit cards, and will soon be wifi-enabled for passengers
                    to stay connected on the go. You can alternatively use apps
                    such as Uber or local-based Careem to hire a taxi.
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <div className="u-center-text u-margin-bottom-second-heading-wow-blogscard14">
            <div className="heading-secondary-help-community-section-wow-blogscard14 ">
              Welcome To Dubai
            </div>
          </div>
        </Container>

        <Container>
          <div class="description_of_card_of_blogscard14">
            <div class="description_of_card_of_blogscard14_area">
              <h2 class="blogscard14-description-area-of-main-headings">
                Carpooling is illegal …
              </h2>
              <p class="blogscard14-description-area-of-main--text">
                Many countries allow the practice of carpooling or ridesharing,
                while in Dubai it is illegal. No one can practice carpooling
                unless one has a permit. This step is taken to restrict the use
                of unauthorized taxis and drivers who can be dangerous for
                travelers. Sharing your vehicle with others without having a
                permit can lead to a massive fine of AED 3000 with 24 black
                points which will be a nightmare for a driver. Not just that the
                vehicle will face a 30-day confiscation.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog14/help-tips-grid1.jpg" />
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <img src="IMAGES/city-images/wow-blog/additional-cards/blog14/help-tips-grid2.jpg" />
                </Col>
              </Row>
              <h2 class="blogscard14-description-area-of-main-headings">
                Fasten your seatbelts.....
              </h2>
              <p class="blogscard14-description-area-of-main--text">
                Fastening the seatbelt is one of the basic traffic laws that is
                exercised all over the world. Dubai is no different from the
                rest of the world. Drivers here must fasten the seatbelts, not
                just drivers all the passengers including adults and children
                whether they are on the front side or the backside they must
                abide by this law. Failure to comply will result in an AED 400
                fine for each passenger not wearing a seatbelt. Not just the
                fine the driver will receive 4 black points as well.
              </p>
              <h2 class="blogscard14-description-area-of-main-headings">
                Living and working in the UAE....
              </h2>
              <p class="blogscard14-description-area-of-main--text">
                If you have entered Dubai on a visit visa and you wish to work
                then you should get a probationary work permit, valid for up to
                3 months, from the Ministry of Labour. If you don’t get this
                permit and are caught working while on a visit visa, then you
                risk being jailed or fined and deported. For further
                information, including on how to stay in the UAE for longer than
                60 days, visit the websites of the UAE Consulate in London, the
                Naturalisation and Residency Department – Dubai or the UAE
                Federal E-Government Portal.
              </p>
              <p class="blogscard14-description-area-of-main--text">
                If you apply for a residence visa, you will have to take a blood
                test. Those testing positive for HIV or hepatitis are detained
                and then deported. There is no appeal process. UAE employers may
                ask foreign employees to deposit their passports with the
                company as part of the terms and conditions of employment. While
                this is not an unusual practice, it is illegal under UAE labour
                law. You should cancel your work visa before leaving the country
                permanently. If you don’t do this, you risk being reported as an
                absconder and could be arrested if you return to the UAE, even
                if you are in transit to another country. Failure to repay debts
                or resolve any outstanding cases against you may also result in
                your arrest on return or transit through the UAE.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard14">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog14/img1.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard14">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog14/img2.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <h2 className="blogscard14-description-area-of-main-headings">
                Don’t use a cellphone while driving...
              </h2>
              <p className="blogscard14-description-area-of-main--text">
                One of the main causes of accidents and mishaps while driving on
                the road is due to drivers use cellphones and get distracted,
                not having the full attention to the road ahead can lead to a
                disaster. Using phones is totally prohibited so avoid doing
                this. You may receive a penalty of AED 800 and 4 black points if
                found using a cellphone while driving in Dubai.
              </p>
              <h2 className="blogscard14-description-area-of-main-headings">
                Tinting too much your windows.....
              </h2>
              <p className="blogscard14-description-area-of-main--text">
                Tinting windows sure keep you safe from the ultraviolet rays,
                however, tinting too much your vehicle windows, can lead to a
                fine. Drivers having registered cars can tint their windows up
                to 50% except for the front screen. If someone wants to tint
                more than 50 percent, he needs to have a special approval by the
                RTA or Dubai police for medical reasons only. Tinting more than
                50% will lead you to pay fine of AED 1500, fine charges can be
                increased according to the percentage you tint the windows.
              </p>
              <Row style={{ marginBottom: "2rem" }}>
                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard14">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog14/img3.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>

                <Col xs={12} xl={6} lg={6} md={6} sm={12}>
                  <div className="wow-news-card1-work-page-top-image-blogscard14">
                    <img
                      src="IMAGES/city-images/wow-blog/additional-cards/blog14/img4.jpg"
                      style={{ width: "100%" }}
                    />
                  </div>
                </Col>
              </Row>
              <h2 class="blogscard14-description-area-of-main-headings">
                Visitors to Dubai...
              </h2>
              <p class="blogscard14-description-area-of-main--text">
                New procedures for visitors to Dubai were announced by the
                Government of Dubai on 21 June. Visitors are able to travel to
                Dubai from 7 July. Further information about what visitors need
                to do before they travel and what will happen on arrival in
                Dubai is available on the Government of Dubai website including
                information about testing on arrival and quarantine.{" "}
              </p>
              <p class="blogscard14-description-area-of-main--text">
                Before visitors fly to Dubai, they will also be required to
                complete a Health Declaration Form and a Quarantine Form. Both
                forms need to be printed, completed and handed over to Dubai
                Health Authority staff on arrival. Visitors to Dubai will also
                have to register their details on the COVID-19 DXB App.
              </p>
              <div class="description_of_card_of_blogscard14_bottom_area">
                <div class="description_of_card_of_blogscard14_bottom_area_previous">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fas fa-reply" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5> WHO WILL DRIVE YOUR JOURNEY</h5>
                  <p>Previouse Article</p>
                </div>
                <div class="description_of_card_of_blogscard14_bottom_area_next">
                  <h3>
                    <Link onClick={scrollToblogforward} to="/holidayblogs">
                      <i class="fa fa-mail-forward" aria-hidden="true"></i>
                    </Link>
                  </h3>
                  <h5>WOW IS FINALLY HERE!</h5>
                  <p>Next Article</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
export default Blogpost14;
